import { ApolloError } from '@apollo/client';
import { FormFieldProps } from '@netfront/ui-library';
import { IHandleCreateTranscriptParams, IHandleUpdateTranscriptParams, useCreateTranscript, useToast, useUpdateTranscript } from 'hooks';
import { ITranscript } from 'interfaces';



const useUpsertTranscript = ({ onCreate, onUpdate }: { onCreate: ({ transcript}: { transcript: ITranscript }) => void; onUpdate: ({ transcript}: { transcript: ITranscript }) => void }) => {

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleCreateTranscript, isLoading: isCreateTranscriptLoading = false } = useCreateTranscript({
    onCompleted: ({ transcript }) => {
      handleToastSuccess({
        message: 'Transcript successfully created',
      });
      onCreate({ transcript });
    },
    onError: handleGetError,
  });

  const { handleUpdateTranscript, isLoading: isUpdateTranscriptLoading = false } = useUpdateTranscript({
    onCompleted: ({ transcript }) => {
      handleToastSuccess({
        message: 'Transcript successfully updated',
      });

      onUpdate({ transcript });
    },
    onError: handleGetError,
  });

  const handleUpsertTranscript = ({ request, transcriptId }: { request: FormFieldProps; transcriptId?: number;  }) => {

    if (!transcriptId) {
      handleCreateTranscript({
        request,
      } as IHandleCreateTranscriptParams);

    } else {
      handleUpdateTranscript({
        transcriptId,
        request,
      } as IHandleUpdateTranscriptParams);
    }
  };

  return {
    isLoading: isCreateTranscriptLoading || isUpdateTranscriptLoading,
    handleUpsertTranscript,
  };
};
export { useUpsertTranscript };
