import { gql } from '@apollo/client';

const UPDATE_ASSET_MUTATION = gql`
  mutation UpdateAsset(
    $assetId: String!
    $alt: String
    $contentType: String
    $description: String
    $fileName: String
    $fileSizeInBytes: Int
    $tagIds: [Int]
    $title: String
    $xAxisFocus: Int
    $yAxisFocus: Int
    $imageSize: EImageSize
    $isAnimatedImage: Boolean
  ) {
    asset {
      updateAsset(
        asset: {
          alt: $alt
          assetId: $assetId
          contentType: $contentType
          description: $description
          fileName: $fileName
          fileSizeInBytes: $fileSizeInBytes
          tagIds: $tagIds
          title: $title
          xAxisFocus: $xAxisFocus
          yAxisFocus: $yAxisFocus
          isAnimatedImage: $isAnimatedImage
        }
      ) {
        alt
        assetId
        contentType
        description
        fileName
        isFavorite
        presignedUrl(size: $imageSize)
        signedUrl
        tagList {
          id
          name
        }
        title
        user {
          firstName
          lastName
        }
        xAxisFocus
        yAxisFocus
      }
    }
  }
`;

export { UPDATE_ASSET_MUTATION };
