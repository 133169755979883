import React, { ReactNode, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { DBAssetTypeType, IDBAsset, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, SidebarButtons, FormFieldProps, Spacing, Spinner, useControlledForm, SearchFilter, IBreadcrumbItem, ImageRadioButtonGroup, VideoRadioButtonGroup, VideoRadioButton, AudioRadioButtonGroup, AudioRadioButtonItemProps, DocumentRadioButtonGroup, DocumentRadioButtonItem, FlexContainer, Button } from '@netfront/ui-library';
import { ContentBuilderTabWrapper, SidebarContainer } from 'components';
import { useSearchPaginatedAssets, useToast } from 'hooks';
import capitalize from 'lodash.capitalize';
import last from 'lodash.last';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { IAssetLibaryItem, ChooseAssetFromLibraryProps } from './ChooseAssetFromLibrary.interfaces';


const Wrapper = ({additionalBreadcrumbs = [], children, hasContainer = false} : { additionalBreadcrumbs?: IBreadcrumbItem[]; children: ReactNode; hasContainer?: boolean }) => {
  if (hasContainer) return (
    <ContentBuilderTabWrapper additionalBreadcrumbs={additionalBreadcrumbs} hasSnippetTypeBreadcrumb={false}>
      {children}
    </ContentBuilderTabWrapper>
  );

  return <SidebarContainer>{children}</SidebarContainer>;
}

const ChooseAssetFromLibrary = ({ onCancel, onSave, projectId, assetType, additionalBreadcrumbs = [], hasContainer = false }: ChooseAssetFromLibraryProps) => {
  const { handleToastError } = useToast();
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({
    assetId: '',
    search: '',
  });
  const [assetOptions, setAssetOptions] = useState<IAssetLibaryItem[]>([]);
  const [allAssets, setAllAssets] = useState<IDBAsset[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const pageSize = 15;
  const [totalAssets, setTotalAssets] = useState<number>(0);
  const [isAssetsLoading, setIsAssetsLoading] = useState<boolean>(true);

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset, getValues, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        assetId: yup.string().required('Asset'),
      }),
    ),
  });


  const {
    handleFetchMorePaginatedAssets,
    handleSearchPaginatedAssets,
    isLoading: isSearchPaginatedAssetsLoading = false,
  } = useSearchPaginatedAssets({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ assetConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);
      const assets = edges.map(({ node }) => node);
      setAllAssets(assets);


      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      setIsLoadMoreDisabled(assets.length >= totalCount || totalCount <= pageSize);
      setTotalAssets(totalCount);

      setAssetOptions(assets.map(({ assetId, title, fileName, presignedUrl, contentType}) => ({
        id: assetId,
        value: assetId,
        labelText: title ?? fileName,
        src: String(presignedUrl),
        contentType,
        posterSrc: '',
      })));

      if (isAssetsLoading) setIsAssetsLoading(false);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleSave = (item: FormFieldProps) => {
    const { assetId } = item;
    const selectedAsset = allAssets.find(({assetId: id }) => id === assetId);
    if (selectedAsset) onSave({ asset: selectedAsset });
    reset();
  };

  const handleSearch = () => {
    const { search } = getValues();
    handleSearchPaginatedAssets({
      filter: search ?? '',
      first: pageSize,
      projectId: String(projectId),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
    });
  };

  const handlePaginate = () => {
    setIsAssetsLoading(true);
    void handleFetchMorePaginatedAssets({
      after: lastCursor,
      first: pageSize,
      projectId: String(projectId),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
    });
  };


  useEffect(() => {
    if (!projectId) return;
    handleSearchPaginatedAssets({
      first: pageSize,
      projectId: String(projectId),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
    });

    setDefaultValues({
      assetId: '',
      search: '',
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetType, projectId]);

  const isLoading = isSearchPaginatedAssetsLoading || isAssetsLoading;


  return (
    <Wrapper additionalBreadcrumbs={additionalBreadcrumbs} hasContainer={hasContainer}>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        <Spinner isLoading={isLoading} />
        <Spacing size="small">
          <Controller
            control={control as Control<FormFieldProps>}
            name="search"
            render={({ field }) => (
              <SearchFilter
                id="id_search_library"
                isDisabled={false}
                labelText="Library filter"
                name={field.name}
                placeholder="Search your library"
                type="text"
                value={field.value}
                isFullWidth
                isLabelHidden
                onChange={field.onChange}
                onClear={() => {
                  setValue('search', '')
                  handleSearch();
                }}
                onSearch={handleSearch}
              />
            )}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="assetId"
            render={({ field, fieldState }) => (
              <>
                { assetType === 'image' && (
                  <ImageRadioButtonGroup
                    errorMessage={getFormFieldErrorMessage(fieldState)} 
                    id="id_image_button_group" 
                    items={assetOptions}
                    label={`Select ${capitalize(assetType.toLowerCase())} asset`}
                    selectedValue={field.value}
                    {...field}
                    onChange={field.onChange}
                  />
                )}

                { assetType === 'video' && (
                  <VideoRadioButtonGroup
                    errorMessage={getFormFieldErrorMessage(fieldState)} 
                    id="id_video_button_group" 
                    items={assetOptions as VideoRadioButton[]}
                    label={`Select ${capitalize(assetType.toLowerCase())} asset`}
                    selectedValue={field.value}
                    {...field}
                    onChange={field.onChange}
                  />
                )}
                
                { assetType === 'audio' && (
                  <AudioRadioButtonGroup
                    errorMessage={getFormFieldErrorMessage(fieldState)} 
                    id="id_audio_button_group" 
                    items={assetOptions as AudioRadioButtonItemProps[]}
                    label={`Select ${capitalize(assetType.toLowerCase())} asset`}
                    selectedValue={field.value}
                    {...field}
                    onChange={field.onChange}
                  />
                )}
                
                { assetType === 'document' && (
                  <DocumentRadioButtonGroup
                    errorMessage={getFormFieldErrorMessage(fieldState)} 
                    id="id_document_button_group" 
                    items={assetOptions as DocumentRadioButtonItem[]}
                    label={`Select ${capitalize(assetType.toLowerCase())} asset`}
                    selectedValue={field.value}
                    {...field}
                    onChange={field.onChange}
                  />
                )}
              </>
            )}
          />
        </Spacing>
        {totalAssets > pageSize && (
          <Spacing size="2x-large">
            <FlexContainer justifyContent="center">
              <Button
                isDisabled={isLoadMoreDisabled}
                size="xs"
                text="load more"
                onClick={handlePaginate}
              />
            </FlexContainer>
        </Spacing>
        )}
          
        <SidebarButtons
          onCancel={onCancel}
          onSaveButtonType="submit"
        />


      </ControlledForm>
    </Wrapper>
  );
};

export { ChooseAssetFromLibrary };
