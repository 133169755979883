import { FormFieldProps } from "@netfront/ui-library";
import { IDBCommunity } from "interfaces";

const getCommunityDefaultValues = ({ community } : { community?: IDBCommunity; }): FormFieldProps => {
  const {
    title,
    description,
    privacy,
    status,
    bannerImage,
    profileImage,
    key,
  } = community ?? {};

  const { assetId: bannerId, presignedUrl: bannerPresignedUrl = '' } = bannerImage ?? {};
  const { assetId: imageId, presignedUrl: imagePresignedUrl = '' } = profileImage ?? {};


  return {
    key: key ?? '',
    title: title ?? '',
    description: description ?? '',
    privacy: privacy ?? '',
    status: status ?? '',
    bannerPresignedUrl: bannerPresignedUrl,
    imagePresignedUrl: imagePresignedUrl,
    bannerId,
    imageId,
  }
};



export { getCommunityDefaultValues };
