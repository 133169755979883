import { gql } from '@apollo/client';

const BULK_TOGGLE_ASSETS_PUBLIC = gql`
  mutation bulkToggleAssetsPublic(
    $assetIds: [String]!
    # $isPublic: Boolean
  ) {
    asset {
      toggleAssetsPublic(
        assetIds: $assetIds
        # isPublic: $isPublic
      )
    }
  }
`;

export { BULK_TOGGLE_ASSETS_PUBLIC };
