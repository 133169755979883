import { useCapuchinLazyQuery } from 'hooks';

import { EXPORT_USER_SESSIONS } from './useGetCustomEventsReport.graphql';
import {
  IGetCustomEventsReportQueryGraphQLResponse,
  IGetCustomEventsReportQueryVariables,
  IHandleGetCustomEventsReportParams,
  IUseGetCustomEventsReport,
  IUseGetCustomEventsReportOptions,
} from './useGetCustomEventsReport.interfaces';

const useGetCustomEventsReport = (options?: IUseGetCustomEventsReportOptions): IUseGetCustomEventsReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetCustomEventsReportOptions);

  const [executeGetCustomEventsReport, { loading: isLoading }] = useCapuchinLazyQuery<
    IGetCustomEventsReportQueryGraphQLResponse,
    IGetCustomEventsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ reportQueries: { customEvents } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: customEvents,
        });
      },
      onError,
    },
    query: query ?? EXPORT_USER_SESSIONS,
    token,
  });


  const handleGetCustomEventsReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
  }: IHandleGetCustomEventsReportParams) => {
    await executeGetCustomEventsReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetCustomEventsReport,
    isLoading,
  };
};

export { useGetCustomEventsReport };
