import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Input, SidebarButtons, Spacing } from '@netfront/ui-library';
import { SidebarContainer, Uploader } from 'components';
import { Controller } from 'react-hook-form';


import { CharacterGeneralTabProps } from './CharacterGeneralTab.interfaces';

const CharacterGeneralTab = ({
  control,
  isLoading,
  onCancel,
  onDrop,
  onDelete,
  onDeleteAsset,
}: CharacterGeneralTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <SidebarContainer>
      <Spacing size="2x-large">
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_character_name"
              labelText="Name"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing size="2x-large">
        <Controller
          control={control}
          name="image"
          render={({ field }) => (
            <Uploader
              assetType="image"
              initialUrl={field.value}
              isRequired={false}
              labelText="Image"
              name={field.name}
              isLabelSideBySide
              onDelete={onDeleteAsset}
              onDrop={onDrop}
            />
          )}
        />
      </Spacing>
      <SidebarButtons
        buttonSize="xs"
        isDeleteButtonDisabled={isLoading}
        isSaveButtonDisabled={isLoading}
        onCancel={onCancel}
        onDelete={onDelete}
        onSaveButtonType="submit"
      />
    </SidebarContainer>
  );
};

export { CharacterGeneralTab };
