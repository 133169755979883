/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { IDBTopic } from "interfaces";

const getTopicDefaultValues = ({ topic  }: { topic?: IDBTopic; }): FormFieldProps => {
  const {
    title,
    status,
    topicImage,
  } = topic ?? {};

  const { assetId, presignedUrl } = topicImage ?? {};

  return {
    title: title || '',
    status: status || 'UNPUBLISHED',
    assetId,
    presignedUrl: presignedUrl,
  }
};

export { getTopicDefaultValues };
