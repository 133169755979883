import React, { useState } from 'react';

import { Dialog, SidebarDialog, Spinner } from '@netfront/ui-library';
import { CartoonPageSidebarView, SidebarContainer } from 'components';
import { useToast, useUpdatePageStatus } from 'hooks';
import { IPageSidebarDetails } from 'interfaces';

import { CartoonSlidesOverview } from './Components';
import { UpsertCartoonPageSlidesTabProps } from './UpsertCartoonPageSlidesTab.interfaces';



const UpsertCartoonPageSlidesTab = ({ slides = [], pageId, contentGroupId, onUpdate, projectId }: UpsertCartoonPageSlidesTabProps) => {

  const { handleToastError } = useToast();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isPageSidebarOpen, setIsPageSidebarOpen] = useState<boolean>(false);
  const [pageSidebarDetails, setPageSidebarDetails] = useState<IPageSidebarDetails>();
  const [deleteSlideId, setDeleteSlideId] = useState<number>();

  const { handleUpdatePageStatus, isLoading: isUpdatePageStatusLoading = false } = useUpdatePageStatus({
    onCompleted: () => {
      setPageSidebarDetails(undefined);
      setDeleteSlideId(undefined);
      setIsDeleteDialogOpen(false);
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleUpsertSlide = (slideId?: number ) => {

    const slide = slides.find(({id}) => id === slideId);

    setIsPageSidebarOpen(true);
    setPageSidebarDetails({
      pageId: slide?.id,
      sort: slide?.sort,
      contentGroupId,
      parentId: pageId,
      cartoonType: 'slide',
    } as IPageSidebarDetails);
  };

  const closePageSidebar = () => {
    setIsPageSidebarOpen(false);
    setPageSidebarDetails(undefined);
  }

  const deleteSlide = (slideId: number) => {
    setDeleteSlideId(slideId);
  };


  const cancelDelete = () => {
    setDeleteSlideId(undefined);
    setIsDeleteDialogOpen(false);
  };

  return (
    <SidebarContainer>
      <CartoonSlidesOverview 
        slides={slides}
        onDeleteClick={deleteSlide}
        onEditClick={handleUpsertSlide}
      />
      <Spinner isLoading={isUpdatePageStatusLoading} />

      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete slide"
        isNarrow
        onCancel={cancelDelete}
        onClose={cancelDelete}
        onConfirm={() => {
          if (!deleteSlideId) return;

          if (!deleteSlideId) return;
          handleUpdatePageStatus({
            contentPageId: Number(deleteSlideId),
            status: 'DELETED',
          });
        }}
      />

      <SidebarDialog
        isOpen={isPageSidebarOpen}
        hasCloseButton
        isPositionedAtTop
        onClose={closePageSidebar}
      >
        <>
          {pageSidebarDetails && (
            <CartoonPageSidebarView 
              closePageSidebar={closePageSidebar}
              contentType="CARTOON"
              handleCreatePage={onUpdate}
              handleDeletePage={onUpdate}
              handleRefreshPage={onUpdate}
              handleUpdatePage={onUpdate}
              idPrefix="cartoon_tab"
              isPageSidebarOpen={isPageSidebarOpen}
              pageSidebarDetails={pageSidebarDetails}
              projectId={projectId}
              setPageSidebarDetails={setPageSidebarDetails}
              isFromCartoonTab
            />
          )}
        </>
      </SidebarDialog>
      
    </SidebarContainer>
  );
};

export { UpsertCartoonPageSlidesTab };
