import 'backpack.css';

import '@netfront/ekardo-content-library/dist/esm/css/index.css';
import '@netfront/ui-library/dist/esm/css/index.css';
import '@netfront/ui-library/dist/css/global.css';
import '@netfront/ui-library/dist/css/helpers.css';
import '@netfront/ui-library/dist/css/typography.css';

import '../styles/components.css';
import '../styles/fonts.css';
import '../styles/globals.css';
import '../styles/theme.css';

import { useEffect, useState } from 'react';

import { useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { useUser } from '@netfront/gelada-identity-library';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { CookiesProvider } from 'react-cookie';
import { Toaster } from 'react-hot-toast';

import { AppDetailsProvider, CachingEntitiesProvider, DashboardProvider, PermissionProvider } from '../context';

function App({ Component, pageProps }: AppProps) {
  const googleAnalyticsMeasurementId = String(process.env.REACT_APP_GOOGLE_ANALYTICS);
  const isGoogleAnalyticsDebugMode = String(process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE).toUpperCase() === 'Y';

  const { trackPageView, trackUser } = useGoogleAnalytics();
  const { events } = useRouter();
  const { getUser } = useUser();

  const [gtagConfigParams, setGtagConfigParams] = useState<ReturnType<typeof omitBy>>();

  const user = getUser();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackPageView(url);
      trackUser();
    };

    // When the component is mounted, subscribe to router changes
    // and log those page views
    events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, user]);

  useEffect(() => {
    if (gtagConfigParams || !user) {
      return;
    }

    setGtagConfigParams(() =>
      omitBy(
        {
          debug_mode: isGoogleAnalyticsDebugMode ? true : undefined,
          user_id: String(user.id),
        },
        isNil,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtagConfigParams, user]);

  return (
    <div id="root">
      {gtagConfigParams && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            gtag('config', '${googleAnalyticsMeasurementId}'${
              Object.keys(gtagConfigParams).length ? `, ${JSON.stringify(gtagConfigParams)}` : ''
            });
          `,
          }}
        />
      )}

      <CachingEntitiesProvider>
        <DashboardProvider>
          <CookiesProvider>
            <PermissionProvider>
              <AppDetailsProvider>
                <Toaster
                  position="top-center"
                  reverseOrder={false}
                  toastOptions={{
                    duration: 3000,
                  }}
                />
                <Component {...pageProps} />
              </AppDetailsProvider>
            </PermissionProvider>
          </CookiesProvider>
        </DashboardProvider>
      </CachingEntitiesProvider>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
