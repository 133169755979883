import { gql } from '@apollo/client';

const CREATE_RADIO_CONFIGURATION = gql`
  mutation createRadioButtonQuesConfigRequest(
    $allowUserGeneratedResponses: Boolean
    $canModify: Boolean!
    $description: String!
    $projectId: String!
    $title: String!
    $style: EQuestionSelectionStyle
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { description: $description, projectId: $projectId }
        questionConfigurationRadio: {
          allowUserGeneratedResponses: $allowUserGeneratedResponses
          canModify: $canModify
          questionResponseSet: { title: $title }
          style: $style
        }
      ) {
        id
        ... on Radio {
          id
          responseSetID
          responseSet {
            id
            availableResponses {
              id
              label
              questionResponseSetId
              value
            }
          }
        }
      }
    }
  }
`;


export { CREATE_RADIO_CONFIGURATION };
