import { gql } from '@apollo/client';

const CREATE_APP_COMMENT_MUTATION = gql`
  mutation createAppComment($inputType: RateAppInputType!) {
    app {
      rateApp(inputType: $inputType) {
        id
      }
    }
  }
`;

export { CREATE_APP_COMMENT_MUTATION };
