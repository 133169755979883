import { BlastIcon, ChatIcon, EightOClockIcon, ElevenOClockIcon, FactCheckIcon, FiveOClockIcon, FourOClockIcon, IconComponentType, IconRadioItemProps, InputIcon, InteractiveRegionsIcon, LabelIcon, NineOClockIcon, NoTailIcon, OneOClockIcon, OvalIcon, ParaIcon, QuizIcon, RectangleIcon, SevenOClockIcon, SixOClockIcon, SpeechTailIcon, StopAndThinkIcon, TenOClockIcon, ThoughtIcon, ThoughtTailIcon, ThreeOClockIcon, TitleIcon, TwelveOClockIcon, TwoOClockIcon } from "@netfront/ui-library";

const TRANSCRIPT_SHAPE_TYPES: { [key: string]: IconComponentType }= {
  'OVAL': OvalIcon,
  'BLAST': BlastIcon,
  'THINK': ThoughtIcon,
  'PARA': ParaIcon,
  'CHAT_TO': ChatIcon,
  'INPUT': InputIcon,
  'QUIZ': QuizIcon,
  'REGIONS': InteractiveRegionsIcon,
  'TITLE': TitleIcon,
  'CHAT_FROM': ChatIcon,
  'RECTANGLE': RectangleIcon,
  'STOP_AND_THINK': StopAndThinkIcon,
  'FACT_CHECK': FactCheckIcon,
  'LABEL': LabelIcon,
  'STOP_AND_THINK_NO_INPUT': StopAndThinkIcon,
};

const TRANSCRIPT_TAIL_TYPES: { [key: string]: IconComponentType} = {
  'NONE': NoTailIcon,
  'DEFAULT': SpeechTailIcon,
  'THINK': ThoughtTailIcon
};

const tailPositionOptions: IconRadioItemProps[] = [
  {
    icon: OneOClockIcon,
    label: "1 o'clock",
    value: '1'
  },
  {
    icon: TwoOClockIcon,
    label: "2 o'clock",
    value: '2'
  },
  {
    icon: ThreeOClockIcon,
    label: "3 o'clock",
    value: '3'
  },
  {
    icon: FourOClockIcon,
    label: "4 o'clock",
    value: '4'
  },
  {
    icon: FiveOClockIcon,
    label: "5 o'clock",
    value: '5'
  },
  {
    icon: SixOClockIcon,
    label: "6 o'clock",
    value: '6'
  },
  {
    icon: SevenOClockIcon,
    label: "7 o'clock",
    value: '7'
  },
  {
    icon: EightOClockIcon,
    label: "8 o'clock",
    value: '8'
  },
  {
    icon: NineOClockIcon,
    label: "9 o'clock",
    value: '9'
  },
  {
    icon: TenOClockIcon,
    label: "10 o'clock",
    value: '10'
  },
  {
    icon: ElevenOClockIcon,
    label: "11 o'clock",
    value: '11'
  },
  {
    icon: TwelveOClockIcon,
    label: "12 o'clock",
    value: '12'
  },
]


export { TRANSCRIPT_SHAPE_TYPES, TRANSCRIPT_TAIL_TYPES, tailPositionOptions };