import { gql } from '@apollo/client';

const BULK_UPDATE_ASSET_TAGS_MUTATION = gql`
  mutation bulkUpdateAssetTags($assetIds: [String]!, $tagIds: [Int]!) {
    asset {
      setTagsOnAssets(assetIds: $assetIds, tagIds: $tagIds)
    }
  }
`;

export { BULK_UPDATE_ASSET_TAGS_MUTATION };
