import { gql } from '@apollo/client';

const DELETE_APP_COMPONENT = gql`
  mutation deleteAppComponent($inputType: DeleteComponentInputType!) {
    app {
      appVersionMutation {
        appVersionComponentMutation {
          deleteComponent(inputType: $inputType)
        }
      }
    }
  }
`;


export { DELETE_APP_COMPONENT };
