import React, { ChangeEvent, FormEvent, useState } from 'react';

import {
  Dialog,
  Input,
  SidebarButtons,
  Spacing,
  Spinner,
  Table,
  Textarea,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { IGlossaryListItem } from 'interfaces';

import { GlossaryItemGeneralTabProps } from './GlossaryItemGeneralTab.interfaces';

import { useCreateGlossaryItem, useDeleteGlossaryItem, useToast, useUpdateGlossaryItem } from '../../../../hooks';


const GlossaryItemGeneralTab = ({
  glossaryItem,
  onClose,
  onUpdate,
  projectId,
}: GlossaryItemGeneralTabProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [currentGlossaryItem, setCurrentGlossaryItem] = useState<IGlossaryListItem | undefined>(glossaryItem);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const {
    id,
    description = '',
    title = '',
  } = currentGlossaryItem ?? {};

  const { handleCreateGlossaryItem, isLoading: isCreateGlossaryItemLoading = false } = useCreateGlossaryItem({
    onCompleted: () => {
      handleToastSuccess({
        message: `Glossary item successfully created`,
      });
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateGlossaryItem, isLoading: isUpdateGlossaryItemLoading = false } = useUpdateGlossaryItem({
    onCompleted: () => {
      handleToastSuccess({
        message: `Glossary item successfully updated`,
      });
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteGlossaryItem, isLoading: isDeleteGlossaryItemLoading = false } = useDeleteGlossaryItem({
    onCompleted: () => {
      handleToastSuccess({
        message: `Glossary item successfully deleted`,
      });
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });



  const handleUpdateInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = event;

    setCurrentGlossaryItem({
      ...currentGlossaryItem ?? {},
      [name]: value,
    } as IGlossaryListItem
    )
  };

  const handleCreate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleCreateGlossaryItem({
      description,
      projectId,
      title,
    });
  };

  const handleUpdate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleUpdateGlossaryItem({
      description,
      id: Number(id),
      title,
    });
  };

  const handleDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDeleteGlossaryItem({
      id: Number(id),
    });
  }
  const isLoading = isCreateGlossaryItemLoading || isUpdateGlossaryItemLoading || isDeleteGlossaryItemLoading;


  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
      <form onSubmit={glossaryItem ? handleUpdate: handleCreate}>
        <Spacing>
          <Input
            id="title"
            labelText="Title"
            maxLength={100}
            name="title"
            tooltipText="Title of the term"
            type="text"
            value={title}
            isLabelSideBySide
            isRequired
            onChange={handleUpdateInput}
          />
        </Spacing>

        <Spacing>
          <Textarea
            id="description"
            labelText="Description"
            maxLength={500}
            name="description"
            tooltipText="Description of the term"
            value={description}
            isLabelSideBySide
            isRequired
            onChange={handleUpdateInput}
          />
        </Spacing>

        <Table
          columns={[
            {
              accessor: 'title',
              Cell: ({ value }: { value: string }) => <div>{value}</div>,
              Header: () => <div>Title</div>,
            },
            {
              accessor: 'description',
              Cell: ({ value }: { value: string }) => <div>{value}</div>,
              Header: <div>Description</div>,
            },
          ]}
          data={[
            {
              id: 'administration',
              title: 'Administration',
              description: 'The action of consuming a drug (for example, snorting or smoking).',
            },
            {
              id: 'anxiety',
              title: 'Anxiety disorder',
              description: 'A mental disorder characterised by significant levels of anxiety.',

            },
            {
              id: 'withdrawals',
              title: 'Withdrawals',
              description: 'The process of stopping to take an addictive drug.',
            }
          ]}
        />

        {glossaryItem && (
          <Dialog
            isOpen={isDeleteDialogOpen}
            title={`Delete ${String(glossaryItem.title)}?`}
            onCancel={() => setIsDeleteDialogOpen(false)}
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={handleConfirmDelete}
          />
        )}

        <SidebarButtons
          buttonSize="xs"
          onCancel={onClose}
          onDelete={glossaryItem ? handleDelete: undefined}
          onSaveButtonType="submit"
        />
      </form>
    </SidebarContainer>
  );
};

export { GlossaryItemGeneralTab };
