import React, { ReactNode, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { IContentGroup } from '@netfront/ekardo-content-library';
import { ControlledForm, SidebarButtons, FormFieldProps, Spacing, Spinner, useControlledForm, IBreadcrumbItem, SelectWithSearch, ISearchList } from '@netfront/ui-library';
import { ContentBuilderTabWrapper, SidebarContainer } from 'components';
import { useGetContentGroups, useToast } from 'hooks';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';


import { ChooseContentGroupFromLibraryProps } from './ChooseContentGroupFromLibrary.interfaces';


const Wrapper = ({additionalBreadcrumbs = [], children, hasContainer = false} : { additionalBreadcrumbs?: IBreadcrumbItem[]; children: ReactNode; hasContainer?: boolean }) => {
  if (hasContainer) return (
    <ContentBuilderTabWrapper additionalBreadcrumbs={additionalBreadcrumbs} hasSnippetTypeBreadcrumb={false}>
      {children}
    </ContentBuilderTabWrapper>
  );

  return <SidebarContainer>{children}</SidebarContainer>;
}

const ChooseContentGroupFromLibrary = ({ onCancel, onSave, projectId, contentGroupType, additionalBreadcrumbs = [], hasContainer = false }: ChooseContentGroupFromLibraryProps) => {
  const { handleToastError } = useToast();
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({
    contentGroupId: '',
  });
  const [allContentGroups, setAllContentGroups] = useState<IContentGroup[]>([]);

  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [responseTitle, setResponseTitle] = useState<string>();
  const [searchSuggestions, setSearchSuggestions] = useState<ISearchList[]>([]);

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        contentGroupId: yup.number().required('Content group'),
      }),
    ),
  });


  const { handleGetContentGroups, isLoading: isGetContentGroupsLoading = false } = useGetContentGroups({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentGroups }) => {

      setDefaultValues({
        contentGroupId: '',
      });

      setAllContentGroups(contentGroups);

      const suggestions = contentGroups.map(({ id, title }) => ({
        id,
        label: String(title),
      }));
      setSearchSuggestions(suggestions);

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleSave = (item: FormFieldProps) => {
    const { contentGroupId } = item;
    const selectedContentGroup = allContentGroups.find(({ id }) => id === contentGroupId);
    if (selectedContentGroup) onSave({ contentGroup: selectedContentGroup });
    reset();
  };

  const handleSearchItemClick = (selectedId: string | number) => {
    setValue('contentGroupId', Number(selectedId));
    setResponseTitle(searchSuggestions.find(({ id }) => id === selectedId)?.label ?? '')
  };



  useEffect(() => {
    if (!projectId) return;
    handleGetContentGroups({
      projectId: String(projectId),
      type: [contentGroupType],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentGroupType, projectId]);


  return (
    <Wrapper additionalBreadcrumbs={additionalBreadcrumbs} hasContainer={hasContainer}>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        <Spinner isLoading={isGetContentGroupsLoading} />
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="contentGroupId"
            render={({ fieldState }) => (
              <SelectWithSearch
                buttonText={`Search existing ${contentGroupType.toLowerCase()}s`}
                countText={`${contentGroupType.toLowerCase()}s`}
                defaultValue={responseTitle}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_content_group"
                isDisplaySearchContent={isSearchContentVisible}
                labelText="Search"
                searchList={searchSuggestions}
                isAvatarVisible
                isLabelSideBySide
                isRequired
                onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
                onSearchItemClick={handleSearchItemClick}
              />
            )}
          />
        </Spacing>

        <SidebarButtons
          onCancel={onCancel}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </Wrapper>
  );
};

export { ChooseContentGroupFromLibrary };
