/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getContentStyleDetails, getFormattedContentAnimation, getFormattedContentEvent, getFormattedContentStyles, getUpsertContentEventStatus, getQuickAddEventValues } from "components";

const getButtonSnippetDefaultValues = ({ snippet } : { snippet?: IContentSnippet;}): FormFieldProps => {
  const {
    title,
    contentEvent,
    visible: isVisible = true,
    backgroundColor,
    buttonBackgroundColor,
    borderColor,
    fontColor,
    hoverColor,
    hyperlink,
    innerText,
    page,
    target,
    style: buttonStyle,
    // isSensitive = false,
  } = snippet ?? {};

  const { id, title: pageTitle = '', contentGroup } = page ?? {};

  const { title: contentGroupTitle = '' } = contentGroup ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);
  const styleDetails = getContentStyleDetails({ entity: snippet });

  return {
    title: title || 'Button snippet',
    buttonBackgroundColor: buttonBackgroundColor || '#4bc2ca',
    backgroundColor: backgroundColor || '#4bc2ca',
    borderColor: borderColor || '#4bc2ca',
    fontColor: fontColor || '#fff',
    hoverColor: hoverColor || '#4bc2ca',
    hyperlink: hyperlink ? decodeURIComponent(hyperlink) : '',
    innerText: innerText || '',
    pageId: id,
    isInternal: Boolean(id),
    initialLinkedPageTitle: pageTitle && contentGroupTitle ? `${contentGroupTitle} - ${pageTitle}` : undefined,
    target: target ? target : '_self',
    buttonStyle: buttonStyle || 'MEDIUM',
    isVisible,
    isSensitive: false,
    isCreateNew: false,
    ...eventDetails,
    ...animationDetails,
    ...styleDetails,
  };
};

const getButtonSnippetCommonVariables = ({ eventAssetId, item }: { eventAssetId?: IDBAsset['assetId']; item: FormFieldProps; }) => {
  const {
    title = 'Button snippet',
    isVisible,
    buttonBackgroundColor,
    backgroundColor,
    borderColor,
    fontColor,
    hoverColor,
    hyperlink,
    innerText,
    pageId,
    target,
    buttonStyle,
    isInternal = false,
    // isSensitive,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);
  const { baseVariables, customTheme } = getFormattedContentStyles({ item });

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
      // isSensitive,
      ...baseVariables,
    },
    buttonSnippet: {
      backgroundColor,
      borderColor,
      buttonBackgroundColor,
      fontColor,
      hoverColor,
      hyperlink: hyperlink && !isInternal ? encodeURIComponent(String(hyperlink)): '',
      innerText,
      pageId,
      style: buttonStyle,
      target,
    },
    type: 'button',
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    customTheme,
  };

  return sharedVariables;

};

const setQuickAddButtonValues = (values: FormFieldProps): FormFieldProps => {
  return {
    ...getQuickAddEventValues(values),
    title: 'Button snippet',
    innerText: '',
    hyperlink: '',
    pageId: undefined,
    isInternal: false,
  };
};

export { getButtonSnippetDefaultValues, getButtonSnippetCommonVariables, setQuickAddButtonValues };
