import { useContext, useState, useEffect } from 'react';

import { useProtectedRoute } from '@netfront/gelada-identity-library';
import { ITab, Select, GeneralTabIcon } from '@netfront/ui-library';
import last from 'lodash.last';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { TOPICS_TABLE_COLUMNS } from './TopicsPage.constants';
import { getTopicsTableData } from './TopicsPage.helpers';
import { ITopicsTableData } from './TopicsPage.interfaces';

import { TablePageTemplate, TopicsSidebarGeneralView } from '../../../../components';
import { CachingEntitiesContext, DashboardContext } from '../../../../context';
import { useGetPaginatedTopics, useToast } from '../../../../hooks';
import { IDBTopic } from '../../../../interfaces';

const TopicsPage = () => {
  const { isAuthenticated } = useProtectedRoute();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const { project } = useContext(CachingEntitiesContext);
  const { dashboardLink } = useContext(DashboardContext);

  const [allTopics, setAllTopics] = useState<IDBTopic[]>([]);
  const [filter, setFilter] = useState<string>();
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [selectedTopicId, setSelectedTopicId] = useState<number>();
  const [topicsTableData, setTopicsTableData] = useState<ITopicsTableData[]>([]);
  const [status, setStatus] = useState<IDBTopic['status'] | undefined>();
  const [lastCursor, setLastCursor] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [totalTopics, setTotalTopics] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const {
    handleFetchMorePaginatedTopics,
    handleGetPaginatedTopics,
    isLoading: isGetPaginatedTopicsLoading = false,
  } = useGetPaginatedTopics({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ topicConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const topicEdges = edges.map(({ node }) => node);

      setAllTopics(topicEdges);
      setIsLoadMoreDisabled(topicEdges.length >= totalCount || totalCount <= pageSize);
      setTotalTopics(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });

  const handleAddNewTopicClick = () => {
    setSelectedTopicId(undefined);
    setIsSideBarOpen(true);
  };

  const handleCreatedTopic = () => {

    handleSideBarClose();

    handleGetPaginatedTopics({
      first: pageSize,
      projectId: String(projectId),
      status: status ? status : null,
      filter,
    });
  };

  const handleDeletedTopic = () => {

    handleSideBarClose();

    handleGetPaginatedTopics({
      first: pageSize,
      projectId: String(projectId),
      status: status ? status : null,
      filter,
    });
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);
  };

  const handleSideBarClose = () => {
    setIsSideBarOpen(false);
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handleUpdatedTopic = () => {
    handleSideBarClose();

    handleGetPaginatedTopics({
      first: pageSize,
      projectId: String(projectId),
      status: status ? status : null,
      filter,
    });
  };

  const tabs: ITab[] = [
    {
      icon: GeneralTabIcon,
      id: 'id_general_tab',
      label: 'General',
      view: () => (
        <TopicsSidebarGeneralView
          projectId={String(projectId)}
          selectedTopicId={selectedTopicId}
          onClose={handleSideBarClose}
          onCreated={handleCreatedTopic}
          onDeleted={handleDeletedTopic}
          onUpdated={handleUpdatedTopic}
        />
      ),
    },
  ];

  useEffect(() => {
    if (!(isAuthenticated && projectId)) {
      return;
    }

    handleGetPaginatedTopics({
      first: pageSize,
      projectId: String(projectId),
      status: status ? status : null,
      filter,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, projectId, status, pageSize, filter]);


  useEffect(() => {
    setTopicsTableData(
      getTopicsTableData({
        topics: allTopics,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedTopicId(id);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTopics]);

  

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;

    setProjectName(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name]);

  return (
    <TablePageTemplate<ITopicsTableData>
      activePage="social"
      activeSubPage="topics"
      additionalBreadcrumbItems={[
        {
          key: '1',
          content: <Link href={`${String(dashboardLink)}/social`}><span>Social</span></Link>,
        },
        {
          key: '2',
          content: 'Topics',
        },
      ]}
      childrenMiddle={
        <Select
          hasPadding={false}
          id="id_topic_status_filter"
          labelDefaultText="All statuses"
          labelText="Status"
          name="status"
          options={[
            {
              id: 'ACTIVE',
              name: 'Active',
              value: 'ACTIVE'
            },
            {
              id: 'UNPUBLISHED',
              name: 'Unpublished',
              value: 'UNPUBLISHED'
            },
            {
              id: 'DELETED',
              name: 'Deleted',
              value: 'DELETED'
            },
          ]}
          value={status}
          isLabelHidden
          onChange={({ target: { value}}) => setStatus(value as IDBTopic['status'])}
        />
      }
      columns={TOPICS_TABLE_COLUMNS}
      data={topicsTableData}
      defaultActiveTabId="id_general_tab"
      description={`Topics social content for ${projectName} project`}
      handleAddNewClick={handleAddNewTopicClick}
      handleOnPageSizeChange={handlePageSizeChange}
      handleOnPaginate={async () => {
        await handleFetchMorePaginatedTopics({
          after: lastCursor,
          first: pageSize,
          filter,
          projectId: String(projectId),
          status: status ? status : null,
        });
      }}
      handleSearch={handleFilterSearch}
      informationBoxMessage={`Manage ${projectName} social topics`}
      isLoading={isGetPaginatedTopicsLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      isSideBarOpen={isSideBarOpen}
      pageSize={pageSize}
      pageTitle={projectName}
      tableType="topics"
      tabs={tabs}
      title={`${projectName} Topics dashboard`}
      toggleIsSideBarOpen={handleSideBarClose}
      totalItems={totalTopics}
    />
  );
};

export { TopicsPage };
