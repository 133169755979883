import { forwardRef } from 'react';

import { ContentBuilderNavigation, ContentBuilderNavigationRef } from '@netfront/ui-library';
import { ContentBuilderToolbar } from 'components';
import { useAppHeaderHeight } from 'utils';

import { ContentBuilderTemplatePageProps } from './ContentBuilderTemplatePage.types';

import { PageLayout } from '../../PageLayout';

const ContentBuilderTemplatePage = forwardRef<ContentBuilderNavigationRef, ContentBuilderTemplatePageProps>(
  ({
  acceptsMap,
  additionalClassNames,
  additionalToolbarItems,
  description,
  pageTitle,
  children,
  activePageId,
  dashboardLink,
  settingItems,
  selectOptions,
  navigationItems,
  selectedValue, 
  pageDropdownOptions,
  onAddClick,
  onSelectChange,
  isShowExpandedHighlight = false,
  hasBulkActions = true,
  hasHighlightOnHoverSiblingChild = true,
  hasScrollToContainer = true,
  isFullyExpandedOnLoad = true,
  isShowPageHighlight= false,
  contentGroupId,
  contentType,
  handleTestMode,
  isTestMode = false,
  onDrop,
}, ref) => {

  const headerHeight = useAppHeaderHeight();

  return (
    <PageLayout
      additionalClassNames={additionalClassNames}
      meta={{ description: description }}
      title={pageTitle}
      hasPrivateLayout
    >
      <div className="c-content-builder-template-page"> 
        <ContentBuilderToolbar 
          additionalToolbarItems={additionalToolbarItems} 
          contentGroupId={contentGroupId} 
          contentType={contentType} 
          dashboardLink={dashboardLink} 
          handleTestMode={handleTestMode} 
          isTestMode={isTestMode} 
        />
        <ContentBuilderNavigation
          ref={ref}
          acceptsMap={acceptsMap}
          activePageId={activePageId}
          hasBulkActions={hasBulkActions}
          hasHighlightOnHoverSiblingChild={hasHighlightOnHoverSiblingChild}
          hasScrollToContainer={hasScrollToContainer}
          isFullyExpandedOnLoad={isFullyExpandedOnLoad}
          navigationItems={navigationItems}
          offsetPositioning={headerHeight + 3.25}
          pageDropdownOptions={pageDropdownOptions}
          selectedValue={String(selectedValue)}
          selectOptions={selectOptions}
          settingsDropdownOptions={settingItems}
          showExpandedHighlight={isShowExpandedHighlight}
          showPageHighlight={isShowPageHighlight}
          isDraggable
          onAddClick={onAddClick}
          onDrop={onDrop}
          onSelectChange={onSelectChange}
        >
          <div style={{width: '100%'}}>{children}</div>
        </ContentBuilderNavigation>

    </div>
    </PageLayout>
  );
}
);

ContentBuilderTemplatePage.displayName = 'ContentBuilderTemplatePage';

export { ContentBuilderTemplatePage };
