import { gql } from '@apollo/client';



const GET_TRANSCRIPTS = gql`
  query getTranscripts(
    $entityId: Int!
  ) {
    contentPage {
      getContentPage(contentPageId: $entityId) {
        bubbleTexts {
          text
          angle
          x
          y
          w
          shape
          tailType
          isTailVisible
          assetId
          id
          hasShadow
          characterId
          character {
            id
            name
            projectId
            __typename
            image {
              presignedUrl
            }
          }
        }
      }
    }
  }
`;

export { GET_TRANSCRIPTS };
