import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UNLINK_ENTITY_FROM_APP } from './useUnlinkEntityFromApp.graphql';
import {
  IUnlinkEntityFromAppMutationGraphQLResponse,
  IUnlinkEntityFromAppMutationVariables,
  IHandleUnlinkEntityFromAppParams,
  IUseUnlinkEntityFromAppOptions,
  IUseUnlinkEntityFromApp,
} from './useUnlinkEntityFromApp.interfaces';

const useUnlinkEntityFromApp = (options?: IUseUnlinkEntityFromAppOptions): IUseUnlinkEntityFromApp => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUnlinkEntityFromAppOptions);

  const [executeUnlinkEntityFromApp, { loading: isLoading }] = useEkardoMutation<
    IUnlinkEntityFromAppMutationGraphQLResponse,
    IUnlinkEntityFromAppMutationVariables
  >({
    mutation: mutation ?? UNLINK_ENTITY_FROM_APP,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: { removeAuthorizedEntity: isCompleted },
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUnlinkEntityFromApp = ({ inputType }: IHandleUnlinkEntityFromAppParams) => {
    void executeUnlinkEntityFromApp({
      variables: {
        inputType,
      },
    });
  };

  return {
    handleUnlinkEntityFromApp,
    isLoading,
  };
};

export { useUnlinkEntityFromApp };
