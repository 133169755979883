import React, { useEffect, useState } from 'react';

import { ISummaryFeedbackConfiguration } from '@netfront/ekardo-content-library';
import { Spacing, InformationBox, Table, ButtonIconOnly, FlexContainer, SidebarButtons, InputFieldWrapper, CaretDownIcon, BinIcon, PlusIcon } from '@netfront/ui-library';
import { QuestionPreview, SidebarContainer } from 'components';

import { FeedbackOverviewProps } from './FeedbackOverview.interfaces';

import { RESPONSE_TYPE_ICON } from '../../UpsertSummarySnippetFeedbackTab.constants';


const FeedbackOverview = ({ onEditClick, onDeleteClick, feedbackItems, onDelete, onClose, watch, initialQuestionId }: FeedbackOverviewProps) => {
  const [items, setItems] = useState<ISummaryFeedbackConfiguration[]>([]);
  const [contentSnippetQuestionId, setContentSnippetQuestionId] = useState<number>();

  useEffect(() => {
    setItems(feedbackItems);
  }, [feedbackItems]);

  useEffect(() => {
    // We need to do this in order to stop watch from re-rendering everything from within the parent container on every change
    const subscription = watch((value) => {
      const { contentSnippetQuestionId: updatedContentSnippetQuestionId } = value;
      if (updatedContentSnippetQuestionId !== contentSnippetQuestionId) {
        setContentSnippetQuestionId(Number(updatedContentSnippetQuestionId));
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

  useEffect(() => {
    if (!initialQuestionId) return;
    setContentSnippetQuestionId(initialQuestionId);
  }, [initialQuestionId])

  return (
    <SidebarContainer>
      <Spacing>
        <InformationBox message="Get feedback based on user responses on the question configured below." />
      </Spacing>

      <Spacing>
        <QuestionPreview questionId={Number(contentSnippetQuestionId)} />
      </Spacing>

      {items.length > 0 && (
        <Spacing>
          <Table
            additionalClassNames="c-communities-table"
            columns={[
              {
                accessor: 'type',
                Cell: ({ value }: { value: string }) => <span>{value}</span>,
                Header: () => <div>Type</div>,
                width: '20%',
              },
              {
                accessor: 'feedback',
                Cell: ({ value }: { value?: string}) => <span>{value}</span>,
                Header: () => <div>Feedback</div>,
                width: '75%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleDelete,
                    handleEdit,
                    id,
                  }
                }: {
                  value: {
                    handleDelete: (id: number) => void
                    handleEdit: (id: number) => void;
                    id: number;
                  }
                }) => (
                  <FlexContainer gap="2x-small" justifyContent="end">
                    <ButtonIconOnly
                      additionalClassNames=""
                      icon={BinIcon}
                      isIconBorderVisible={false}
                      text="Delete feedback"
                      onClick={() => handleDelete(id)}
                    />
                    <ButtonIconOnly
                      additionalClassNames="h-rotate-right"
                      icon={CaretDownIcon}
                      isIconBorderVisible={false}
                      text="Edit feedback"
                      onClick={() => handleEdit(id)}
                    />
                  </FlexContainer>
                ),
              },
            ]}
            data={items.map((item) => ({
              id: item.contentSnippetSummaryId,
              type: RESPONSE_TYPE_ICON[String(item.__typename)],
              feedback: item.label,
              settingsButtonData: {
                id: item.contentSnippetSummaryId,
                handleEdit: onEditClick,
                handleDelete: onDeleteClick,
              },
            }))}
          />
        </Spacing>
      )}
      {items.length === 0 && (
        <Spacing>
          <InputFieldWrapper
            id="id_add_feedback"
            label="Add feedback"
            labelType="span"
            type="custom"
            isLabelSideBySide
          >
            <FlexContainer justifyContent="flex-end" isFullWidth>
              <ButtonIconOnly icon={PlusIcon} text="Add feedback" onClick={() => onEditClick()} />
            </FlexContainer>
          </InputFieldWrapper>
        </Spacing>
      )}
      <SidebarButtons
        onCancel={onClose}
        onDelete={onDelete}
        onSaveButtonType="submit"
      />
    </SidebarContainer>
  );
};


export { FeedbackOverview };
