import { gql } from '@apollo/client';

const CREATE_NEW_APP_VERSION = gql`
  mutation createAppVersionRequest($inputType: CreateNewVersionInputType!) {
    app {
      appVersionMutation {
        createAppVersion(
          inputType: $inputType
        ) {
          id
          appId
        }
      }
    }
  }
`;
export { CREATE_NEW_APP_VERSION };
