import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IOption, Select } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { getReportDropdownOptions, ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { IGetUserFlowsOnCompletedResponse, useGetAllModulePagesCompletedReport, useGetUserFlows, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { ALL_MODULE_PAGE_COLUMNS } from './AllModulePagesCompleted.constants';
import { getAllModulePageCompletedTableData } from './AllModulePagesCompleted.helpers';
import { AllModulePagesCompletedCsvRowData, IAllModulePageCompletedItem, IAllModulePageCompletedTableData } from './AllModulePagesCompleted.interfaces';

import { DROPDOWN } from '../../Reporting.constants';


const AllModulePagesCompleted = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [allModulePageItems, setAllModulePageItems] = useState<IAllModulePageCompletedItem[]>([]);
  const [modulePageItems, setModulePageItems] = useState<IAllModulePageCompletedItem[]>();
  const [allModulePageCompletedTableData, setAllModulePageCompletedTableData] = useState<IAllModulePageCompletedTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>();
  const [userFlowId, setUserFlowId] = useState<number>();
  const [userFlowOptions, setUserFlowOptions] = useState<IOption[]>([]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const { handleGetUserFlows, isLoading: isGetUserFlowsLoading = false } = useGetUserFlows({
    onCompleted: (data: IGetUserFlowsOnCompletedResponse) => {
        const { userflows: returnedUserFlows } = data;

        setUserFlowOptions(
          getReportDropdownOptions(
            returnedUserFlows,
            [{ key: 'title' }],
            'id',
          )
        );
    }
  });
  
  const {
    handleGetAllModulePagesCompletedReport,
    isLoading: isGetAllModulePagesLoading = false,
  } = useGetAllModulePagesCompletedReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<AllModulePagesCompletedCsvRowData>(String(res.data), {
            header: true,
          skipEmptyLines: true,
          });

          setTotalCount(data.length);

          const formattedItems = data.map((row: AllModulePagesCompletedCsvRowData) => ({
            id: Number(row['User id']),
            groupName: String(row['Group name']),
            completedOn: String(row['Completed']),
            completedOnUtc: String(row['Completed (utc)']),
            moduleTitle: String(row['Module title']),
            startedOn: String(row['Started']),
            startedOnUtc: String(row['Started (utc)']),
            timeToComplete: String(row['Time to complete']),
            pageTitle: String(row['Page title']),
            userType: String(row['User type']),
          }));

          setAllModulePageItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setModulePageItems(paginatedItems);
          setLastItemIndex(pageSize);

        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    if (!userFlowId) {
      handleToastCustomError({
        message: 'Please select a userflow',
      });
      return;
    }

    void handleGetAllModulePagesCompletedReport({
      dateFrom: dateRange && dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange && dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      userFlowId,
      fileType: 'CSV',
    });
  };


  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setModulePageItems(allModulePageItems.slice(0, selectedPageSize));
  };

  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setModulePageItems(allModulePageItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!modulePageItems) {
      return;
    }

    setAllModulePageCompletedTableData(
      getAllModulePageCompletedTableData({
        items: modulePageItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modulePageItems]);

  useEffect(() => {
    if (!projectId) return;
    void handleGetUserFlows({ projectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetUserFlowsLoading || isGetAllModulePagesLoading;

  return (
    <ReportTable<IAllModulePageCompletedTableData>
      activePath={['reporting', 'reporting-content', 'export-all-module-page-completions']}
      additionalBreadcrumbItems={[ {
        key: '2',
        content: 'All module completions',
      }]}
      additionalFilters={(
        <Select
          additionalClassNames="c-asset-sort-select"
          hasPadding={false}
          id="id_userflow_select"
          labelDefaultText={DROPDOWN.DEFAULT_OPTION_LABEL.USER_FLOW}
          labelText="Userflow"
          name="userflow_select"
          options={userFlowOptions}
          value={String(userFlowId)}
          isLabelHidden
          onChange={({ target: { value }}) => setUserFlowId(Number(value))}
        />
      )}
      columns={ALL_MODULE_PAGE_COLUMNS}
      csvUrl={csvUrl}
      data={allModulePageCompletedTableData}
      description={`All module completions reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage="This page exports all module page completions based on the selected filters"
      isGenerateButtonDisabled={!userFlowId}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { AllModulePagesCompleted };
