/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { defaultLatitude, defaultLongitude, defaultZoom, getContentAnimationDetails, getContentEventDetails, getContentStyleDetails, getFormattedContentAnimation, getFormattedContentEvent, getFormattedContentStyles, getQuickAddEventValues, getUpsertContentEventStatus } from "components";

const getMapSnippetDefaultValues = async ({ snippet } : { snippet?: IContentSnippet;}): Promise<FormFieldProps> => {

  const {
    title,
    contentEvent,
    draggable: isDraggable = false,
    longitude,
    latitude,
    grayscale: isGrayscale = false,
    zoom,
    visible: isVisible = true,
    // isSensitive = false,
  } = snippet ?? {};

  let address = '';

  if (snippet) {
    const key = process.env.REACT_APP_GOOGLE_LOCATION_API_KEY;

    await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${Number(latitude)},${Number(longitude)}&key=${String(key)}`)
    .then(response => response.json())
    .then(data => {
      if (data.status === 'OK') {
        address = data.results[0].formatted_address ?? '';
        
      } else {
        console.error('Geocoding failed:', data.status);
      }
    })
    .catch(error => {
      console.error('Error fetching geocoding data:', error);
    });
  }

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);
  const styleDetails = getContentStyleDetails({ entity: snippet });


  return {
    title: title ?? 'Map snippet',
    address,
    isVisible,
    isDraggable,
    longitude: longitude || defaultLongitude,
    latitude: latitude || defaultLatitude,
    isGrayscale,
    zoom: zoom || defaultZoom,
    isSensitive: false,
    isCreateNew: false,
    ...eventDetails,
    ...animationDetails,
    ...styleDetails,
  };
};

const getMapSnippetCommonVariables = ({ eventAssetId, item }: { eventAssetId?: IDBAsset['assetId']; item: FormFieldProps; }) => {
  const {
    title = 'Map snippet',
    isVisible,
    isDraggable,
    longitude,
    latitude,
    isGrayscale,
    zoom,
    // isSensitive,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);
  const { baseVariables, customTheme } = getFormattedContentStyles({ item });

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
      // isSensitive,
      ...baseVariables
    },
    mapSnippet: {
      draggable: isDraggable,
      longitude,
      latitude,
      grayscale: isGrayscale,
      zoom,
    },
    type: 'map',
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    customTheme,
  };

  return sharedVariables;

};

const setQuickAddMapValues = (values: FormFieldProps): FormFieldProps => {
  return {
    ...getQuickAddEventValues(values),
    title: 'Map snippet',
    longitude: undefined,
    latitude: undefined,
    address: '',
  };
};

export { getMapSnippetDefaultValues, getMapSnippetCommonVariables, setQuickAddMapValues };
