import { gql } from '@apollo/client';

const LINK_ENTITY_TO_APP = gql`
  mutation addAppLink($inputType: AddAuhorizedEntitiesInputType!) {
    app {
      addAuhorizedEntities(
        inputType: $inputType
      )
    }
  }
`;

export { LINK_ENTITY_TO_APP };
