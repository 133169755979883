import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentSection } from '@netfront/ekardo-content-library';
import { getUpsertContentEventVariables } from 'components';
import { ICreateSectionMutationParams, IUpdateSectionMutationParams, useAttachSectionCssStyle, useCreateContentEvent, useCreateSection, useToast, useUpdateSection } from 'hooks';

import { ISectionConfig } from './useUpsertSection.interfaces';


const useUpsertSection = ({ onCreate, onUpdate }: { onCreate: () => void; onUpdate: (returnedSection: IContentSection) => void }) => {
  const sectionVariables = useRef<{value :ISectionConfig | undefined}>({value: undefined});

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const getSectionVariables = ({contentPageId, sectionGroupId, sectionId, type }: {contentPageId?: number; sectionGroupId?: number; sectionId?: number; type: 'create' | 'update'; }): ICreateSectionMutationParams | IUpdateSectionMutationParams => {

    const { section = {}, contentEvent = {}, shouldCreateEvent = false, shouldUpdateEvent = false, customTheme = {} } = sectionVariables.current.value ?? {};

    const { containers = [], sort, contentLayoutId, title, isVisible, backgroundImage, backgroundColor, borderColor, style } = section;

    const { contentEntity, contentEventId } = contentEvent;

    const { shouldAttachCustomTheme, shouldDetachCustomTheme, styleId } = customTheme;

    const variableMap = {
      create: {
        contentSection: {
          contentPageId: Number(contentPageId),
          sectionGroupId: Number(sectionGroupId),
          contentLayoutId,
          sort,
          containers,
          title,
          visible: isVisible,
          backgroundImage,
          backgroundColor,
          borderColor,
          style,
        }
      } as ICreateSectionMutationParams,
      update: {
        contentSection: {
          contentLayoutId,
          sectionId: Number(sectionId),
          sort,
          title,
          visible: isVisible,
          backgroundImage,
          backgroundColor,
          borderColor,
          style,
        },
        shouldAttachCustomTheme,
        shouldDetachCustomTheme,
        styleId: styleId ?? 0,
        contentSectionId: Number(sectionId),
        shouldCreateEvent,
        shouldUpdateEvent,
        updateContentEvent: shouldUpdateEvent ? {
          contentEventId: Number(contentEventId),
          ...getUpsertContentEventVariables(contentEvent, 'update'),
        }: undefined ,
        createContentEvent: shouldCreateEvent ? {
          entityId: Number(sectionId),
          contentEntity,
          ...getUpsertContentEventVariables(contentEvent, 'create'),
        }: undefined
      } as IUpdateSectionMutationParams
    };

    return variableMap[type];
  };

  const { handleCreateContentEvent, isLoading: isCreateContentEventLoading = false } = useCreateContentEvent({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Section successfully created',
      });

      onCreate();

    },
    onError: handleGetError,
  });

  const { handleAttachSectionCssStyle, isLoading: isAttachSectionCssStyleLoading = false } = useAttachSectionCssStyle({
    onError: handleGetError,
  });

  const { handleCreateSection, isLoading: isCreateSectionLoading = false } = useCreateSection({
    onCompleted: ({ section : { id }}) => {

      const { shouldCreateEvent = false, contentEvent = {}, customTheme = {} } = sectionVariables.current.value ?? {};

      const { shouldAttachCustomTheme = false, styleId } = customTheme;

      handleToastSuccess({
        message: 'Section successfully created',
      });

      if (!shouldCreateEvent && !shouldAttachCustomTheme) {
        
        onCreate();
      } else {

        if (shouldAttachCustomTheme) {
          handleAttachSectionCssStyle({
            contentSectionId: id,
            styleId,
          });
        }

        if (shouldCreateEvent) {
          const { contentEntity } = contentEvent;
          handleCreateContentEvent({
            contentEvent: {
              entityId: id,
              contentEntity,
              ...getUpsertContentEventVariables(contentEvent, 'create'),
            }
          });
        }
      }

    },
    onError: handleGetError,
  });

  const { handleUpdateSection, isLoading: isUpdateSectionLoading = false } = useUpdateSection({
    onCompleted: ({ section: returnedSection }) => {
      handleToastSuccess({
        message: 'Section successfully updated',
      });
      onUpdate(returnedSection);
    },
    onError: handleGetError,
  });

  const handleUpsertSection = ({ containerId, contentPageId, sectionId, variables }: {containerId: number; contentPageId: number; sectionId?: number; variables: ISectionConfig; }) => {
    sectionVariables.current.value = variables;

    if (!sectionId) {

      const createSectionVariables = getSectionVariables({
        contentPageId: Number(contentPageId),
        sectionGroupId: Number(containerId),
        type: 'create',
      }) as ICreateSectionMutationParams;

      handleCreateSection({
        ...createSectionVariables
      });

    } else {
      const updateSectionVariables = getSectionVariables({
        sectionId: Number(sectionId),
        type: 'update',
      }) as IUpdateSectionMutationParams;

      handleUpdateSection({
        ...updateSectionVariables,
      });
    }
  };

  return {
    isLoading: isCreateSectionLoading || isUpdateSectionLoading || isCreateContentEventLoading || isAttachSectionCssStyleLoading,
    handleUpsertSection,
  };
};
export { useUpsertSection };
