import React, { ChangeEvent, useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Input, IRadioButtonItem, RadioButtonGroup, Select, SingleDatePicker, Spacing, Spinner, ToggleSwitch } from '@netfront/ui-library';
import { SidebarContainer, Uploader } from 'components';
import { useGetContentPageTypes, useToast } from 'hooks';
import { Controller } from 'react-hook-form';
import { toKebabCase } from 'utils';

import { UpsertPageGeneralTabProps } from './UpsertPageGeneralTab.interfaces';



const UpsertPageGeneralTab = ({ contentType, control, onDeleteAsset, onDrop, setValue, projectId, hasImageUpload = true, cartoonType, pageId, initialTitle = '', getValues }: UpsertPageGeneralTabProps) => {

  const { handleToastError } = useToast();
  const [pageTypes, setPageTypes] = useState<IRadioButtonItem[]>([]);
  const [isSyncUrlToTitle, setIsSyncUrlToTitle] = useState<boolean>(false);

  const { handleGetContentPageTypes, isLoading: isGetContentPageTypesLoading = false } = useGetContentPageTypes({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentPageTypes }) => {

      setPageTypes(contentPageTypes.map(({ id, name }) => ({
        id: String(id),
        labelText: name,
        value: id
      })));

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const handleSyncUrlToTitle = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: isChecked },
    } = event;
    setIsSyncUrlToTitle(isChecked);

    const { pageTitle = '' } = getValues();

    if (isChecked) {
      if (initialTitle !== pageTitle) {
        setValue('url', toKebabCase(String(pageTitle)))
      }
    } else {
      setValue('url', toKebabCase(String(initialTitle)))
    }
  }

  useEffect(() => {
    void handleGetContentPageTypes({
      projectId,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType, projectId])

  return (
    <SidebarContainer>
      <Spinner isLoading={isGetContentPageTypesLoading} />
      <Spacing>
        <Controller
          control={control}
          name="pageTitle"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_page_title"
              labelText="Title"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
              onChange={(event) => {
                const { target: { value }} = event;
                field.onChange(event);
                if (isSyncUrlToTitle || !pageId) setValue('url', toKebabCase(value))
              }}
            />
          )}
        />
      </Spacing>
      { pageId && (
        <Spacing size="2x-large">
          <ToggleSwitch
            additionalClassNames="c-asset-toggle"
            id="id_is_sync_url_to_title"
            isChecked={isSyncUrlToTitle}
            labelText="Sync url to title"
            isLabelSideBySide
            onChange={handleSyncUrlToTitle}
          />
        </Spacing>
      )}
      
      <Spacing>
        <Controller
          control={control}
          name="url"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_page_url"
              labelText="Url"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      {contentType === 'EVENTS' && (
        <Spacing>
          <Controller
            control={control}
            name="releaseDate"
            render={({ field, fieldState }) => (
              <SingleDatePicker 
                dateInputProps={{
                  labelText: 'Select date',
                  errorMessage: getFormFieldErrorMessage(fieldState),
                  id: 'id_release_date',
                  name: field.name,
                  type: 'text',
                  isLabelSideBySide: true,
                }}
                {...field}
                selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
                onSelected={(dates) => setValue('minDate', dates[0])} 
              />
            )}
          />
        </Spacing>
      )}

      {contentType === 'CARTOON' && cartoonType === 'slide' && (
        <>
          <Spacing>
            <Controller
              control={control}
              name="isAnimatedImage"
              render={({ field }) => (
                <ToggleSwitch
                  additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
                  id="id_is_animated_image"
                  isChecked={field.value}
                  labelText="Is image animated?"
                  isInline
                  isLabelSideBySide
                  onChange={field.onChange}
                />
              )}
            />
          </Spacing>
          <Spacing>
            <Controller
              control={control}
              name="minimumExposureTime"
              render={({ field, fieldState }) => (
                <Input
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_minimum_time_exposure"
                  labelText="Minimum exposure time"
                  tooltipPosition="end"
                  tooltipText="The minimum time of exposure that a slide will remain on the screen"
                  type="text"
                  isLabelSideBySide
                  {...field}
                />
              )}
            />
          </Spacing>
        </>
      )}

      {contentType === 'ACTIVITY' && (
        <>
          <Spacing>
            <Controller
              control={control}
              name="isActivity"
              render={({ field }) => (
                <ToggleSwitch
                  additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
                  id="id_is_activity"
                  isChecked={field.value}
                  labelText="Activity?"
                  tooltipText="What mode should this page display on"
                  isInline
                  isLabelSideBySide
                  onChange={field.onChange}
                />
              )}
            />
          </Spacing>
          <Spacing>
            <Controller
              control={control}
              name="isAssessment"
              render={({ field }) => (
                <ToggleSwitch
                  additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
                  id="id_is_assessmenet"
                  isChecked={field.value}
                  labelText="Assessment?"
                  tooltipText="What mode should this page display on"
                  isInline
                  isLabelSideBySide
                  onChange={field.onChange}
                />
              )}
            />
          </Spacing>
        </>
      )}

      <Spacing>
        <Controller
          control={control}
          name="status"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_page_status"
              labelText="Status"
              options={[
                {
                  id: 'PUBLISHED',
                  value: 'PUBLISHED',
                  name: 'Published',
                },
                {
                  id: 'UNPUBLISHED',
                  value: 'UNPUBLISHED',
                  name: 'Unpublished',
                }
              ]}
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

      {pageTypes.length > 0 && (
        <Spacing>
          <Controller
            control={control}
            name="contentPageType"
            render={({ field }) => (
              <RadioButtonGroup
                items={pageTypes}
                selectedValue={String(field.value)}
                title="Page type"
                {...field}
              />
            )}
          />
        </Spacing>
      )}

      {hasImageUpload && (
        <Spacing>
        <Controller
          control={control}
          name="imagePresignedUrl"
          render={({ field }) => (
            <Uploader
              assetType="image"
              initialUrl={field.value}
              isRequired={false}
              labelText="Image"
              name={field.name}
              isLabelSideBySide
              onDelete={onDeleteAsset}
              onDrop={onDrop}
            />
          )}
        />
      </Spacing>
      )}
      
      <Spacing>
        <Controller
          control={control}
          name="isSensitive"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_sensitive_content"
              isChecked={field.value}
              labelText="Sensitive"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

    </SidebarContainer>
  );
};

export { UpsertPageGeneralTab };
