import { ReactNode, useContext } from 'react';

import { AvatarBreadcrumbInformationBoxTemplate, ContentBuilderNavigation, PageContainer } from '@netfront/ui-library';
import { AppDetailsContext } from 'context';
import Link from 'next/link';
import { useAppHeaderHeight } from 'utils';

import { AppTemplatePageProps } from './AppTemplatePage.types';

import { PageLayout } from '../../PageLayout';

const AppTemplatePage = ({
  activePageId,
  additionalClassNames = 'c-app-template-page',
  breadcrumbItems,
  children,
  dashboardLink,
  dashboardLinkText,
  description,
  informationBoxMessage,
  isLoading,
  logoUrl,
  pageTitle,
  size,
  title,
  versionId,
  addOnClick,
  settingsDropdownOptions
}: AppTemplatePageProps) => {
  const { getNavigationItems } = useContext(AppDetailsContext);
  const headerHeight = useAppHeaderHeight();

  return (
    <PageLayout
      additionalClassNames={additionalClassNames}
      meta={{ description: description }}
      title={pageTitle}
      hasPrivateLayout
    >
      <PageContainer>
        <ContentBuilderNavigation
          activePageId={activePageId}
          dashboardLink={dashboardLink}
          dashboardLinkText={dashboardLinkText}
          dashboardLinkWrapper={({ children: linkChildren }: { children: ReactNode }) => <Link href={dashboardLink}>{linkChildren}</Link>}
          hasBulkActions={false}
          navigationItems={getNavigationItems(versionId)}
          offsetPositioning={headerHeight}
          settingsDropdownOptions={settingsDropdownOptions}
          hasHighlightOnHoverSiblingChild
          isFullyExpandedOnLoad
          showPageHighlight
          onAddClick={addOnClick ? () => addOnClick(): undefined}
          // onSelectChange={({target : { value }}: { target: { value: string }}) => handleSelectPage(value)}
        >
          <AvatarBreadcrumbInformationBoxTemplate
            breadcrumbItems={breadcrumbItems}
            isLoading={isLoading}
            logoUrl={logoUrl}
            message={informationBoxMessage}
            size={size}
            title={title}
          />
          {children}
        </ContentBuilderNavigation>
      </PageContainer>
    </PageLayout>
  );
};

export { AppTemplatePage };
