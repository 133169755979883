import React, { useState } from 'react';

import {
  Checkbox,
  FlexContainer,
  Input,
  InputFieldWrapper,
  Select,
  SidebarButtons,
  Spacing,
} from '@netfront/ui-library';


import { DAYS } from './UpsertDirectoryOperatingHours.constants';
import { UpsertDirectoryOperatingHoursProps } from './UpsertDirectoryOperatingHours.interfaces';



const UpsertDirectoryOperatingHours = ({ onCancel, onCreate, onUpdate, operatingHoursItem, onDelete }: UpsertDirectoryOperatingHoursProps) => {
  const [dayStart, setDayStart] = useState(operatingHoursItem?.dayStart ?? 'Mon');
  const [dayEnd, setDayEnd] = useState(operatingHoursItem?.dayEnd ?? 'Mon');
  const [timeStart, setTimeStart] = useState(operatingHoursItem?.timeStart ?? '00:00');
  const [timeEnd, setTimeEnd] = useState(operatingHoursItem?.timeEnd ?? '00:00');
  const [isSingleDay, setIsSingleDay] = useState(operatingHoursItem?.isSingleDay ?? true);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleUpdate = () => {
    const config = {
      id: String(operatingHoursItem?.id),
      dayStart,
      dayEnd: isSingleDay ? dayEnd : undefined,
      timeStart,
      timeEnd,
      isSingleDay,
    };

    onUpdate(config);
  };

  const handleSave = () => {
    if (dayStart === '' || (!isSingleDay && dayEnd === '') || timeStart === '' || timeEnd === '') return;
    const config = {
      id: operatingHoursItem?.id ?? `id_operating_hours_${(Math.random() + 1).toString(36).substring(7)}`,
      dayStart,
      dayEnd: !isSingleDay ? dayEnd : undefined,
      timeStart,
      timeEnd,
      isSingleDay,
    };

    onCreate(config);
  };
  return (
    <>
      <Spacing>
        <InputFieldWrapper id={''} label="Configuration" tooltipText="This option allows you to configure consecutive days who share the same open and close times." isLabelSideBySide >
          <FlexContainer justifyContent="flex-end" isFullWidth>
            <Checkbox
              id="id_is_single_day"
              isChecked={isSingleDay}
              labelText="Configure by day?"
              name="isSingleDay"
              value=""
              onChange={(e) => {
                const { checked: isChecked } = e.target;
                setIsSingleDay(isChecked);
                setIsDisabled(timeStart === '' || timeEnd === '' || (!isChecked && dayEnd === '') || dayStart === '');
              }}
            />
          </FlexContainer>
        </InputFieldWrapper>
      </Spacing>
      <Spacing>
        <Select
          id="id_start_day"
          labelText="Start day"
          name="dayStart"
          options={DAYS}
          value={dayStart}
          isLabelSideBySide
          onChange={(e) => {
            const { value } = e.target;
            setDayStart(value);
            setIsDisabled(timeStart === '' || timeEnd === '' || (!isSingleDay && dayEnd === '') || value === '');
          }}
        />
      </Spacing>
      <Spacing>
        {!isSingleDay && (
          <Select
            id="id_end_day"
            labelText="End day"
            name="dayEnd"
            options={DAYS}
            value={dayEnd}
            isLabelSideBySide
            onChange={(e) => {
              const { value } = e.target;
              setDayEnd(value);
              setIsDisabled(timeStart === '' || timeEnd === '' || value === '' || dayStart === '');
            }}
          />
        )}
      </Spacing>
      <Spacing>
        <Input
          id="id_time_start"
          labelText="Start time"
          name="timeStart"
          type="time"
          value={timeStart}
          isLabelSideBySide
          isRequired
          onChange={(e) => {
            const { value } = e.target;
            setTimeStart(value);
            setIsDisabled(value === '' || timeEnd === '' || (!isSingleDay && dayEnd === '') || dayStart === '');
          }}
        />

      </Spacing>
      <Spacing>
        <Input
          id="id_time_end"
          labelText="End time"
          name="timeEnd"
          type="time"
          value={timeEnd}
          isLabelSideBySide
          isRequired
          onChange={(e) => {
            const { value } = e.target;
            setTimeEnd(value);
            setIsDisabled(timeStart === '' || value === '' || (!isSingleDay && dayEnd === '') || dayStart === '');
          }}
        />
      </Spacing>
      <SidebarButtons
        isSaveButtonDisabled={isDisabled}
        onCancel={onCancel}
        onDelete={operatingHoursItem ? () => onDelete(operatingHoursItem.id) : undefined}
        onSave={operatingHoursItem ? handleUpdate : handleSave}
      />
    </>
  );
};


export { UpsertDirectoryOperatingHours };


