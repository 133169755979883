import React, { useEffect, useState } from 'react';

import { IContentPage } from '@netfront/ekardo-content-library';
import { Spacing, Table, ButtonIconOnly, FlexContainer, InputFieldWrapper, BinIcon, CaretDownIcon, PlusIcon } from '@netfront/ui-library';

import { CartoonSlidesOverviewProps } from './CartoonSlidesOverview.interfaces';

const CartoonSlidesOverview = ({ onEditClick, onDeleteClick, slides = [] }: CartoonSlidesOverviewProps) => {
  const [items, setItems] = useState<IContentPage[]>([]);

  useEffect(() => {
    setItems(slides);
  }, [slides]);

  return (
    <>

      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              {
                accessor: 'image',
                Cell: ({ value }: { value: string }) => <span>{value && <img alt="cartoon slide" src={value} />}</span>,
                Header: () => <div>Image</div>,
                width: '10%',
              },
              {
                accessor: 'title',
                Cell: ({ value }: { value: string }) => <span>{value}</span>,
                Header: () => <div>Title</div>,
                width: '55%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleDelete,
                    handleEdit,
                    id,
                  }
                }: {
                  value: {
                    handleDelete: (id: number) => void;
                    handleEdit: (id: number) => void;
                    id: number;
                  }
                }) => (
                  <FlexContainer gap="2x-small" justifyContent="end">
                    <ButtonIconOnly
                      additionalClassNames=""
                      icon={BinIcon}
                      isIconBorderVisible={false}
                      text="Delete slide"
                      onClick={() => handleDelete(id)}
                    />
                    <ButtonIconOnly
                      additionalClassNames="h-rotate-right"
                      icon={CaretDownIcon}
                      isIconBorderVisible={false}
                      text="Edit slide"
                      onClick={() => handleEdit(id)}
                    />
                  </FlexContainer>
                ),
              },
            ]}
            data={items.map((item) => {
              const { asset, title, id } = item;

              const { presignedUrl = '' } = asset ?? {};
              return {
                image: presignedUrl,
                title,
                id,
                settingsButtonData: {
                  id,
                  handleEdit: onEditClick,
                  handleDelete: onDeleteClick,
                },
              };
            })}
          />
        </Spacing>
      )}

      <Spacing>
        <InputFieldWrapper
          id="id_add_slide"
          label="Add slide"
          labelType="span"
          type="custom"
          isLabelSideBySide
        >
          <FlexContainer justifyContent="flex-end" isFullWidth>
            <ButtonIconOnly icon={PlusIcon} text="Add slide" onClick={() => onEditClick(undefined)} />
          </FlexContainer>
        </InputFieldWrapper>
      </Spacing>
    </>
  );
};


export { CartoonSlidesOverview };
