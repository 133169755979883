import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_CONTAINER_SORT } from './useUpdateContainerSort.graphql';
import {
  IUpdateContainerSortMutationGraphQLResponse,
  IUpdateContainerSortMutationVariables,
  IUseUpdateContainerSortOptions,
  IUseUpdateContainerSort,
  IUpdateContainerSortMutationParams,
} from './useUpdateContainerSort.interfaces';

const useUpdateContainerSort = (options?: IUseUpdateContainerSortOptions): IUseUpdateContainerSort => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateContainerSortOptions);

  const [executeUpdateContainerSort, { loading: isLoading }] = useEkardoMutation<IUpdateContainerSortMutationGraphQLResponse, IUpdateContainerSortMutationVariables>({
    mutation: mutation ?? UPDATE_CONTAINER_SORT,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSection: { sortContainer: container },
        } = data;

        onCompleted({
          container,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateContainerSort = async ({
    containerId,
    sectionId,
    sort,
  }: IUpdateContainerSortMutationParams) => {
    await executeUpdateContainerSort({
      variables: {
        containerId,
        sectionId,
        sort,
      }
    });
  };

  return {
    handleUpdateContainerSort,
    isLoading,
  };
};

export { useUpdateContainerSort };
