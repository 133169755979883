import React, { useEffect, useRef, useState } from 'react';

import { IContentPage, IDBTranscript } from '@netfront/ekardo-content-library';


const BubbleTexts = ({ transcripts = []}: { transcripts?: IDBTranscript[]; }) => {

  const baseStyles = {
    display: 'block',
    fill: '#fff',
    left: '50%',
    top: '50%',
    width: 'calc(100% + 2rem)',
  };

  return (
    <>
      {transcripts.map((transcript) => {
        const { id, text, w: width, x: xAxis, y: yAxis } = transcript;
        return (
          <div key={id} style={{
            ...baseStyles, 
            position: 'absolute',
            backgroundColor: 'white',
            width: width ? `${width}%` : 'calc(100% + 2rem)',
            left: xAxis ? `${xAxis}%`: '50%',
            top: yAxis ? `${yAxis}%`: '50%',
          }}>
            <p>{text}</p>
          </div>
        );
      })}
    </>
  )
}

const CartoonSlide = ({ contentPage, isAutoPlay = false, isEditMode = false }: { contentPage: IContentPage; isAutoPlay?: boolean; isEditMode?: boolean; }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [currentPage, setCurrentPage] = useState<IContentPage>();
  const [hasImageLoaded, setHasImageLoaded] = useState<boolean>(false);


  const { asset, audio, bubbleTexts,  } = currentPage ?? {};
  const { presignedUrl: imageUrl = ''} = asset ?? {};
  const { presignedUrl: audioUrl } = audio ?? {};

  useEffect(() => {
    setHasImageLoaded(false);
    setCurrentPage(contentPage);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPage]);


  useEffect(() => {
    if (!audioRef.current) return;

    const handleSrcChange = () => {
      if (!audioRef.current) return;
      audioRef.current.pause(); // Pause the audio
      audioRef.current.currentTime = 0; // Reset the audio time
      audioRef.current.load(); // Load the new audio source
    };

    handleSrcChange();

  }, [audioUrl]);

  useEffect(() => {
    const imgElement = imgRef.current;
    if (imgElement && imgElement.complete) {
      // If the image is already cached and loaded
      setHasImageLoaded(true);
    }
  }, [imageUrl]);

  return (
    <div 
      className="c-cartoon-slide"
      style={{
        opacity: hasImageLoaded ? 1 : 0,
        transition: hasImageLoaded ? 'opacity 0.2s ease-out-in': 'opacity 0.15s ease-in-out',
      }}
    >
      <img 
        ref={imgRef}
        alt="slide"
        className="c-cartoon-slide__image" 
        id="cartoon-image"
        src={imageUrl}
        
        onLoad={() => setHasImageLoaded(true)}
      />

      {hasImageLoaded && <BubbleTexts transcripts={bubbleTexts} />}


      {audio && hasImageLoaded && (
        <audio ref={audioRef} autoPlay={isAutoPlay} className={!isEditMode ? 'h-hide-visually': ''} controls>
          <source src={audioUrl} type="audio/mpeg" />
        </audio>
      )}
    </div>
  );
};


const CartoonPlayer = ({ contentPages }: { contentPages: IContentPage[] }) => {
  const [currentContentPages, setCurrentContentPages] = useState<IContentPage[]>([]);


  useEffect(() => {
    setCurrentContentPages(contentPages);
  }, [contentPages]);


  if (currentContentPages.length === 0) return <p>No pages</p>;


  return (<p>Hello there</p>);
};

const CartoonContentPage = ({ contentPage, contentPages = [], isEditMode = false }: { contentPage: IContentPage; contentPages: IContentPage[]; isEditMode?: boolean } ) => {

  if (isEditMode) return (
    <CartoonSlide contentPage={contentPage} isEditMode />
  );

  return (
    <CartoonPlayer contentPages={contentPages} />
  );
};


export { CartoonContentPage };
