
import { CREATE_TOPIC_ASSET_MUTATION } from './useCreateTopicAsset.graphql';
import {
  ICreateTopicAssetMutationGraphQLResponse,
  ICreateTopicAssetMutationVariables,
  IHandleCreateTopicAssetParams,
  IUseCreateTopicAssetOptions,
  IUseCreateTopicAsset,
} from './useCreateTopicAsset.interfaces';

import { useBonoboMutation } from '../../useBonoboMutation';

const useCreateTopicAsset = (options?: IUseCreateTopicAssetOptions): IUseCreateTopicAsset => {
  const { mutation, onCompleted, onCompletedAsync, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseCreateTopicAssetOptions);

  const [executeCreateTopicAsset, { loading: isLoading }] = useBonoboMutation<
    ICreateTopicAssetMutationGraphQLResponse,
    ICreateTopicAssetMutationVariables
  >({
    mutation: mutation ?? CREATE_TOPIC_ASSET_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {


        const {
          asset: { createTopicAsset: { signedUrl } },
        } = data;

        if (onCompleted) {
          onCompleted({ signedUrl });
        }

        if (onCompletedAsync) {
          void onCompletedAsync({ signedUrl });
        }
        
      },
      onError,
    },
    token,
  });

  const handleCreateTopicAsset = async ({ 
    alt,
    contentType,
    fileName,
    fileSizeInBytes,
    topicId,
    type,
   }: IHandleCreateTopicAssetParams) => {
    await executeCreateTopicAsset({
      variables: {
        alt,
        contentType,
        fileName,
        fileSizeInBytes,
        topicId,
        type,
      },
    });
  };

  return {
    handleCreateTopicAsset,
    isLoading,
  };
};

export { useCreateTopicAsset };
