/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getQuickAddEventValues, getUpsertContentEventStatus, getContentStyleDetails, getFormattedContentStyles } from "components";
import DOMPurify from 'dompurify';

import { IQuestionSnippetConfig } from "./UpsertQuestionSnippetSidebarView.interfaces";

import { responseTypeToUnderScoredConst } from '../../../../../constants';

const sanitizeQuestionTitle = (questionText: string): string => {
  let sanitisedText: string =  DOMPurify.sanitize(questionText, { ALLOWED_TAGS: []});

  sanitisedText = sanitisedText.replace(/&nbsp;/g, ' ');

  return sanitisedText;
};

const getQuestionSnippetDefaultValues = ({ snippet, questionType } : { questionType?: string; snippet?: IContentSnippet; }): FormFieldProps => {
  const { title, contentEvent, question, configuration, behavior, majorNumber, identifier, visible: isVisible = true } = snippet ?? {};

  const {
    __typename: type,
    allowUserGeneratedResponses: isAllowUserGeneratedResponses = false,
    minLength,
    maxLength,
    regex,
    maxCheckedResponse,
    minValue,
    maxValue,
    defaultValue,
    id: configId,
    minDate,
    maxDate,
    defaultDate,
    responseSet,
    step,
    style,
  } = configuration ?? {};

  const { id: responseSetId, title: responseSetTitle, availableResponses = []} = responseSet ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);
  const styleDetails = getContentStyleDetails({ 
    entity: snippet,
    additionalParameters: {
      entityDisplayStyle: ['Radio', 'Checkbox'].includes(!snippet ? String(questionType) : String(type)) ? style || 'DEFAULT': undefined,
    }
  });

  return {
    title: title ?? '',
    question: question ?? '',
    behavior,
    resType: responseTypeToUnderScoredConst[!snippet ? String(questionType) : String(type)],
    majorNumber,
    identifier,
    isVisible,
    allowUserGeneratedResponses: isAllowUserGeneratedResponses,
    minLength: minLength ?? 1,
    maxLength: maxLength ?? 1000,
    regex: regex ?? '',
    maxCheckedResponse,
    minValue: minValue ?? 0,
    maxValue: maxValue ?? 100,
    defaultValue: defaultValue ?? 0,
    sliderMinValue: minValue ?? 0,
    sliderMaxValue: minValue ?? 10,
    sliderStep: step ?? 1,
    sliderDefaultValue: defaultValue ?? 5,
    sliderTicks: availableResponses,
    configId,
    minDate,
    maxDate,
    defaultDate,
    responseSetId,
    responseSetTitle,
    maxResponse: 10,
    minResponse: 3,
    isCreateForm: false,
    isCreateNew: false,

    ...eventDetails,
    ...animationDetails,
    ...styleDetails,
  };
};

const getQuestionSnippetCommonVariables = ({eventAssetId, item }: {eventAssetId?: IDBAsset['assetId']; item: FormFieldProps}): IQuestionSnippetConfig => {
  const {
    question,
    majorNumber,
    behavior,
    configId,
    isVisible,
    identifier,

    // config
    resType,
    allowUserGeneratedResponses,
    maxLength,
    minLength,
    regex,
    maxCheckedResponse,
    minValue,
    maxValue,
    defaultValue,
    minDate,
    maxDate,
    defaultDate,
    sliderMinValue,
    sliderMaxValue,
    sliderDefaultValue,
    sliderStep,
    sliderTicks,
    maxResponse,
    minResponse,
    responseSetId,
    responseSetTitle,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);
  const { baseVariables, customTheme, configurationStyle } = getFormattedContentStyles({ item });



  const sharedVariables = {
    baseSnippet: {
      entityTitle: sanitizeQuestionTitle(String(question)),
      visible: isVisible,
      ...baseVariables,
    },
    questionSnippet: {
      majorNumber,
      behavior,
      minorNumber: '',
      question,
      configId,
      identifier,
    },
    configuration: {
      resType,
      allowUserGeneratedResponses,
      maxLength,
      minLength,
      regex,
      maxCheckedResponse,
      minValue,
      maxValue,
      defaultValue,
      minDate,
      maxDate,
      defaultDate,
      sliderMinValue,
      sliderMaxValue,
      sliderDefaultValue,
      sliderStep,
      sliderTicks,
      maxResponse,
      minResponse,
      responseSetId,
      responseSetTitle,
      ...configurationStyle,
    },
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    type: 'question',
    customTheme,
  };

  return sharedVariables;
};


const setQuickAddQuestionValues = (values: FormFieldProps): FormFieldProps => {
  return {
    ...getQuickAddEventValues(values),
    title: 'Question snippet',
    question: '',
  };
};

export { getQuestionSnippetDefaultValues, getQuestionSnippetCommonVariables, setQuickAddQuestionValues };
