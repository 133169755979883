import { useContext } from 'react';

import { CardListPageTemplate as NetfrontCardListPageTemplate } from '@netfront/ui-library';
import { useAppHeaderHeight } from 'utils';

import { DashboardContext, formatDashboardItems } from 'context/DashboardContext';

import { CardListTemplatePageProps } from './CardListTemplatePage.types';

import { PageLayout } from '../../PageLayout';

const CardListTemplatePage =({
  addNewOnClick,
  additionalClassNames,
  breadcrumbItems,
  description,
  informationBoxMessage,
  isLoading = false,
  logoUrl,
  pageTitle,
  projectName,
  lists,
  listType = '',
  activePage,
  activeSubPage,
  pageSize = 10,
  totalItems = 0,
  handleOnPaginate,
  handleOnPageSizeChange,
  childrenMiddle,
  isPaginationDisabled = false,
  onSearch,
  searchPlaceholder = '',
  additionalToolbarItems,
  hasViewSwitch = true,
  isSideBarOpen = false,
  activePath,
  bulkActions,
  onSelectRows,
  bulkActionPlaceHolder,
  legend,
  cardListName,
  defaultView = 'grid'
}: CardListTemplatePageProps) => {

  const { dashboardItems, dashboardLink } = useContext(DashboardContext);
  const headerHeight = useAppHeaderHeight();

  return (
    <PageLayout
      additionalClassNames={additionalClassNames}
      meta={{ description: description }}
      title={pageTitle}
      hasPrivateLayout
    >
      {projectName && (
        <>
          <NetfrontCardListPageTemplate
            activePage={activePage}
            activePath={activePath}
            activeSubPage={activeSubPage}
            additionalToolbarItems={additionalToolbarItems}
            addNewOnClick={addNewOnClick}
            avatarTitle={projectName}
            breadcrumbItems={breadcrumbItems}
            bulkActionPlaceHolder={bulkActionPlaceHolder}
            bulkActions={bulkActions}
            cardListName={cardListName}
            childrenMiddle={childrenMiddle}
            dashboardLink={dashboardLink}
            defaultView={defaultView}
            emptyStateImageUrl="/images/empty-state.webp"
            handleOnPageSizeChange={handleOnPageSizeChange}
            handleOnPaginate={handleOnPaginate}
            hasLeftSidebar={true}
            hasViewSwitch={hasViewSwitch}
            informationBoxMessage={informationBoxMessage}
            isLoading={isLoading}
            isPaginationDisabled={isPaginationDisabled}
            isSideBarOpen={isSideBarOpen}
            legend={legend}
            lists={lists}
            listType={listType}
            logoUrl={logoUrl}
            navigationItems={formatDashboardItems(dashboardItems)}
            offsetPositioning={headerHeight}
            pageSize={pageSize}
            pageTitle={pageTitle}
            searchPlaceholder={searchPlaceholder}
            size={logoUrl ? 'small': 'medium'}
            totalItems={totalItems}
            onSearch={onSearch}
            onSelectRows={onSelectRows}
          />
        </>
      )}
    </PageLayout>
  );
};

export { CardListTemplatePage };
