import { useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { 
  useCreateCommunity, 
  useCreateCommunityAsset as useCreateImageAsset, 
  useCreateCommunityAsset as useCreateBannerAsset, 
  useDeleteCommunityAsset as useDeleteImageAsset, 
  useDeleteCommunityAsset as useDeleteBannerAsset, 
  useToast, 
  useUpdateCommunity 
} from 'hooks';
import { IDBCommunity } from 'interfaces';
import { pushImageToAws } from 'utils';


const useUpsertCommunity = ({ onUpdate, projectId }: { onUpdate: (returnedTopic: IDBCommunity) => void; projectId: string }) => {
  const uploadedImageRef = useRef<{value : File | undefined}>({value: undefined});
  const hasUploadedImage = useRef<{value : boolean}>({value: false});
  
  const uploadedBannerRef = useRef<{value : File | undefined}>({value: undefined});
  const hasUploadedBanner = useRef<{value : boolean}>({value: false});
  const returnedCommunityRef = useRef<{value : IDBCommunity | undefined}>({value: undefined});
  const communityIdRef = useRef<{value : number | undefined}>({value: undefined});

  const [isBannerAssetUploadToAwsLoading, setIsBannerAssetUploadToAwsLoading] = useState<boolean>(false);
  const [isImageAssetUploadToAwsLoading, setIsImageAssetUploadToAwsLoading] = useState<boolean>(false);

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleCleanUpAndReturn = () => {
    handleToastSuccess({
      message: `Community successfully ${communityIdRef.current.value ? 'updated': 'created'}`,
    });

    onUpdate(returnedCommunityRef.current.value ?? {} as IDBCommunity);
    uploadedImageRef.current.value = undefined;
    hasUploadedImage.current.value = false;
    uploadedBannerRef.current.value = undefined;
    hasUploadedBanner.current.value = false;
    returnedCommunityRef.current.value = undefined;
    communityIdRef.current.value = undefined;
  };

  const waitForApiReturn = () => {
    if (
        // both banner and image have been uploaded and returned from api
        (uploadedImageRef.current.value && hasUploadedImage.current.value && uploadedBannerRef.current.value && hasUploadedBanner.current.value)
      ||
        // no image uploaded and banner asset has been uploaded & returned from the api
        (!uploadedImageRef.current.value && uploadedBannerRef.current.value && hasUploadedBanner.current.value)
      || 
        // no banner uploaded and image asset has been uploaded & returned from the api
        (!uploadedBannerRef.current.value && uploadedImageRef.current.value && hasUploadedImage.current.value)
    ) {
      handleCleanUpAndReturn();
    } else {
      return;
    }
  };


  const { handleCreateCommunityAsset: handleCreateCommunityImageAsset, isLoading: isCreateCommunityImageAssetLoading = false } =
    useCreateImageAsset({
      onCompletedAsync: async (data) => {
        const { signedUrl } = data;
        setIsImageAssetUploadToAwsLoading(true);
        await pushImageToAws(signedUrl, uploadedImageRef.current.value, () => {
          hasUploadedImage.current.value = true;
          setIsImageAssetUploadToAwsLoading(false);

          if (!uploadedBannerRef.current.value) {
            handleCleanUpAndReturn();
          } else {

            waitForApiReturn();
          }
          
        });
      },
      onError: handleGetError,
      projectId: String(projectId),
    });

  const { handleCreateCommunityAsset: handleCreateCommunityBannerAsset, isLoading: isCreateCommunityBannerAssetLoading = false } =
    useCreateBannerAsset({
      onCompletedAsync: async (data) => {
        const { signedUrl } = data;
        setIsBannerAssetUploadToAwsLoading(true);
        await pushImageToAws(signedUrl, uploadedBannerRef.current.value, () => {
          hasUploadedBanner.current.value = true;
          setIsBannerAssetUploadToAwsLoading(false);

          if (!uploadedImageRef.current.value) {
            handleCleanUpAndReturn();
          } else {

            waitForApiReturn();
          }
        });
      },
      onError: handleGetError,
      projectId: String(projectId),
    });


  const handleUploadAssets = (communityId: number) => {
    if (uploadedBannerRef.current.value) {
      const { type, name, size } = uploadedBannerRef.current.value;
      handleCreateCommunityBannerAsset({
        asset: {
          communityId,
          contentType: type,
          fileName: name,
          fileSizeInBytes: size,
          type: 'IMAGE',
          usage: 'BANNER',
          alt: 'Banner image',
        },
      });
    }

    if (uploadedImageRef.current.value) {
      const { type, name, size } = uploadedImageRef.current.value;
      handleCreateCommunityImageAsset({
        asset: {
          communityId,
          contentType: type,
          fileName: name,
          fileSizeInBytes: size,
          type: 'IMAGE',
          usage: 'PROFILE',
          alt: 'Profile image',
        },
      })
    }

  };
  

  const { handleDeleteCommunityAsset: handleDeleteImageAsset } = useDeleteImageAsset({
    projectId,
  });

  const { handleDeleteCommunityAsset: handleDeleteBannerAsset } = useDeleteBannerAsset({
    projectId,
  });

  const { handleCreateCommunity, isLoading: isCreateCommunityLoading = false } = useCreateCommunity({
    onCompleted: ({ communityConnection }) => {
      returnedCommunityRef.current.value = communityConnection;

      if (uploadedBannerRef.current.value || uploadedImageRef.current.value) {
        handleUploadAssets(communityConnection.id);
      } else {
        handleCleanUpAndReturn();
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId,
  });


  const { handleUpdateCommunity, isLoading: isUpdateCommunityLoading = false } = useUpdateCommunity({
    onCompleted: ({ communityConnection }) => {
      returnedCommunityRef.current.value = communityConnection;
      if (uploadedBannerRef.current.value || uploadedImageRef.current.value) {
        handleUploadAssets(communityConnection.id);
      } else {
        handleCleanUpAndReturn();
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId,
  });


  const handleUpsertCommunity = ({ 
    communityId,
    deletedBannerId,
    deletedImageId,
    description,
    privacy,
    status,
    title,
    uploadedBanner,
    uploadedImage,

  }: {
    communityId?: number;
    deletedBannerId?: string;
    deletedImageId?: string;
    description: string;
    privacy: string;
    status: string;
    title: string;
    uploadedBanner?: File;
    uploadedImage?: File;
   }) => {
    uploadedImageRef.current.value = uploadedImage;
    uploadedBannerRef.current.value = uploadedBanner;
    communityIdRef.current.value = communityId;

    if (deletedBannerId) {
      handleDeleteBannerAsset({
        assetId: deletedBannerId,
      });
    }

    if (deletedImageId) {
      handleDeleteImageAsset({
        assetId: deletedImageId,
      });
    }

    if (!communityId) {
      void handleCreateCommunity({
        inputType: {
          description,
          privacy: privacy as IDBCommunity['privacy'],
          status: status as IDBCommunity['status'],
          title,
        }
      });
    } else {
      void handleUpdateCommunity({
        inputType: {
          description,
          id: communityId,
          privacy: privacy as IDBCommunity['privacy'],
          status: status as IDBCommunity['status'],
          title,
        }
      });
    }
  };

  return {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    isLoading: isCreateCommunityLoading || isUpdateCommunityLoading || isCreateCommunityImageAssetLoading || isCreateCommunityBannerAssetLoading || isBannerAssetUploadToAwsLoading || isImageAssetUploadToAwsLoading,
    handleUpsertCommunity,
  };
};
export { useUpsertCommunity };