import React, { useContext, useEffect, useState } from 'react';

import { DBContentGroupTypeType } from '@netfront/ekardo-content-library';
import { useBackofficeSiteUrls, useDomain } from '@netfront/gelada-identity-library';
import { DashboardContext } from 'context';
import Link from 'next/link';
import { useRouter } from 'next/router';


const ContentBuilderBetaToggle = ({ contentGroupId, contentType }: { contentGroupId?: number; contentType?: DBContentGroupTypeType;  }) => {
  const { dashboardLink } = useContext(DashboardContext);
  const { query, asPath } = useRouter();
  const { isDomainReady } = useDomain();
  const { organisationId: queryOrganisationId, projectId: queryProjectId } = query;

  const [legacyContentGroupUrl, setLegacyContentGroupUrl] = useState<string>('');

  const { getDashboardUrl: getLegacyDashboardUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });


  useEffect(() => {
    if (!(contentType && contentGroupId && queryProjectId && queryOrganisationId && isDomainReady)) return;
    setLegacyContentGroupUrl(`${getLegacyDashboardUrl()}/${String(queryOrganisationId)}/${String(queryProjectId)}/group/${contentType.toLowerCase()}/${contentGroupId}/content`);
  }, [dashboardLink, getLegacyDashboardUrl, contentType, contentGroupId, queryOrganisationId, queryProjectId, isDomainReady]);


  return (
    <div className="c-content-builder-beta-toggle">
      <a className="c-content-builder__link" href={legacyContentGroupUrl}>Legacy</a>
      <Link className="c-content-builder__link is-active" href={asPath} passHref>
        <span className="">Beta</span>
      </Link>
    </div>
  );
};

export { ContentBuilderBetaToggle };
