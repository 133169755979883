import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getQuickAddEventValues, getUpsertContentEventStatus, getContentStyleDetails, getFormattedContentStyles } from "components";

const getFormSnippetDefaultValues = ({ snippet } : { snippet?: IContentSnippet;}): FormFieldProps => {
  const {
    title,
    contentEvent,
    form,
    visible: isVisible,
    isSensitive,
  } = snippet ?? {};

  const {
    id,
    description = '',
    url = '',
    status,
    sort,
    formType,
    title: formTitle,
    // hasQuestionNumbers = false,
  } = form ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);
  const styleDetails = getContentStyleDetails({ entity: snippet });
  

  return {
    title: title ?? 'Form snippet',
    id,
    isVisible: isVisible ?? true,
    isSensitive: isSensitive ?? false,
    isCreateNew: false,
    description,
    url,
    sort,
    formType: formType ?? 'LIST',
    formTitle: formTitle ?? '',
    isActivated: status === 'PUBLISHED',
    hasQuestionNumbers: false,
    ...eventDetails,
    ...animationDetails,
    ...styleDetails,
    view: snippet ? 'general' : 'selector',
    hasChosenFromLibrary: false,
  };
};

const getFormSnippetCommonVariables = ({ item, contentSnippetFormId, eventAssetId } : { contentSnippetFormId?: number; eventAssetId?: IDBAsset['assetId']; item: FormFieldProps; }) => {

  const {
    title = 'Form snippet',
    id,
    isVisible,
    // isSensitive = false,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);
  const { baseVariables, customTheme } = getFormattedContentStyles({ item });

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
      // isSensitive,
      ...baseVariables,
    },
    formSnippet: {
      contentSnippetFormId: contentSnippetFormId ?? id,
    },
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    type: 'form',
    customTheme,
  };

  return sharedVariables;

};

const setQuickAddFormValues = (values: FormFieldProps): FormFieldProps => {
  return {
    ...getQuickAddEventValues(values),
    title: 'Form snippet',
    text: '',
    id: undefined,
    formTitle: '',
    description: '',
    view: 'selector',
    url: '',
  };
};

export { getFormSnippetDefaultValues, getFormSnippetCommonVariables, setQuickAddFormValues };
