import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetCommentLogsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { COMMENT_LOGS_COLUMNS } from './CommentLogsReportPage.constants';
import { getCommentLogsTableData } from './CommentLogsReportPage.helpers';
import { CommentLogsCsvRowData, ICommentLogsItem, ICommentLogsTableData } from './CommentLogsReportPage.interfaces';



const CommentLogsReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [commentLogsItems, setCommentLogsItems] = useState<ICommentLogsItem[]>();
  const [commentLogsTableData, setCommentLogsTableData] = useState<ICommentLogsTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>();
  const [csvUrl, setCsvUrl] = useState<string>();


  const {
    handleGetCommentLogsReport,
    isLoading: isGetCommentLogsLoading = false,
  } = useGetCommentLogsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<CommentLogsCsvRowData>(String(res.data), {
            header: true,
            skipEmptyLines: true,
          });

          setCommentLogsItems(data.map((row: CommentLogsCsvRowData) => ({
            id: Number(row['Comment id']),
            authorId: row['Author id'] ? String(row['Author id']): '-',
            groupName: row['Group name'] ? String(row['Group name']) : '-',
            comment: row['Comment'] ? String(row['Comment']) : '-',
            commentDate: row['Comment date'] ? String(row['Comment date']) : '-',
            commentDateUtc: row['Comment date (utc)'] ? String(row['Comment date (utc)']) : '-',
            userType: row['User type'] ? String(row['User type']): '-',
            action: row['Action'] ? String(row['Action']): '-',
          })));
        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });

  useEffect(() => {
    if (!commentLogsItems) {
      return;
    }

    setCommentLogsTableData(
      getCommentLogsTableData({
        items: commentLogsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentLogsItems]);

  const generateReport = () => {
    void handleGetCommentLogsReport({
      dateFrom: dateRange && dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange && dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: 'CSV'
    });
  };

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetCommentLogsLoading;

  return (
    <ReportTable<ICommentLogsTableData>
      activePath={['reporting', 'reporting-social', 'export-comment-logs']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Comment logs',
      }]}
      columns={COMMENT_LOGS_COLUMNS}
      csvUrl={csvUrl}
      data={commentLogsTableData}
      
      description={`Comment log reports for ${String(projectName)}`}
      informationBoxMessage={'This page exports comment logs based on the selected filters'}
      isLoading={isLoading}
      logoUrl={project?.logo?.presignedUrl}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { CommentLogsReportPage };
