import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_TRANSCRIPT_MUTATION } from './useCreateTranscript.graphql';
import {
  ICreateTranscriptMutationGraphQLResponse,
  ICreateTranscriptMutationVariables,
  IHandleCreateTranscriptParams,
  IUseCreateTranscriptOptions,
  IUseCreateTranscript,
} from './useCreateTranscript.interfaces';

const useCreateTranscript = (options?: IUseCreateTranscriptOptions): IUseCreateTranscript => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateTranscriptOptions);

  const [executeCreateTranscript, { loading: isLoading }] = useEkardoMutation<ICreateTranscriptMutationGraphQLResponse, ICreateTranscriptMutationVariables>({
    mutation: mutation ?? CREATE_TRANSCRIPT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          bubbleText: {
            add: transcript,
          },
        } = data;

        void onCompleted({
          transcript
        });
      },
      onError,
    },
    token,
  });

  const handleCreateTranscript = async ({ request }: IHandleCreateTranscriptParams) => {
    await executeCreateTranscript({
      variables: {
        request,
      },
    });
  };

  return {
    handleCreateTranscript,
    isLoading,
  };
};

export { useCreateTranscript };
