import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetGroupsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { GROUPS_COLUMNS } from './GroupsPage.constants';
import { getGroupsTableData } from './GroupsPage.helpers';
import { GroupsCsvRowData, IGroupsItem, IGroupsTableData } from './GroupsPage.interfaces';

const GroupsPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { handleToastError, handleToastCustomError } = useToast();
  const { query: { projectId: queryProjectId } } = useRouter();

  const [projectName, setProjectName] = useState<string>();
  const [groupsItems, setGroupsItems] = useState<IGroupsItem[]>();
  const [allGroupsItems, setAllGroupsItems] = useState<IGroupsItem[]>([]);
  const [groupsTableData, setGroupsTableData] = useState<IGroupsTableData[]>([]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [projectId, setProjectId] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const {
    handleGetGroupsReport,
    isLoading: isGetGroupsLoading = false,
  } = useGetGroupsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<GroupsCsvRowData>(String(res.data), {
            header: true,
          skipEmptyLines: true,
          });

          const formattedItems = data.map((row: GroupsCsvRowData) => ({
            id: Number(row['Group Id']),
            type: String(row['Type']),
            groupName: String(row['Group name']),
            groupUrl: String(row['Group url']),
            addressLine1: String(row['Address 1']),
            addressLine2: String(row['Address 2']),
            city: String(row['City']),
            state: String(row['State']),
            postCode: String(row['Postcode']),
            countryCode: String(row['Country code']),
            phoneNumber: String(row['Phone number']),
            detailUrl: String(row['Detail url']),
            creationDateUtc: String(row['Creation date (utc)']),

          }));
          
          setTotalCount(data.length);
          setAllGroupsItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setGroupsItems(paginatedItems);
          setLastItemIndex(pageSize);

        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    if (!projectId) {
      handleToastCustomError({
        message: 'whoops! something went wrong',
      });
      return;
    }

    void handleGetGroupsReport({
      fileType: 'CSV',
      projectId,
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setGroupsItems(allGroupsItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setGroupsItems(allGroupsItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!groupsItems) {
      return;
    }

    setGroupsTableData(
      getGroupsTableData({
        items: groupsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsItems]);


  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    if (!queryProjectId) return;
    setProjectId(queryProjectId as string);
  }, [queryProjectId])

  const isLoading = isGetGroupsLoading;

  return (
    <ReportTable<IGroupsTableData>
      activePath={['reporting', 'reporting-users', 'export-groups']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Groups',
      }]}
      columns={GROUPS_COLUMNS}
      csvUrl={csvUrl}
      data={groupsTableData}
      description={`Group reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports groups'}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onGenerate={generateReport}
    />
  );
};

export { GroupsPage };
