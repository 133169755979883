import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, CustomRating, EmptyState, FilteredTable, FlexContainer, Spinner } from '@netfront/ui-library';
import { AppTemplatePage } from 'components';
import { AppDetailsContext, DashboardContext } from 'context';
import { useCreateNewAppVersion, useSubmitAppForApproval, useToast } from 'hooks';
import { IDBAppVersion } from 'interfaces';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { APP_VERSION_TABLE_COLUMNS } from './AppVersionsPage.constants';
import { getAppVersionTableData, getGreatestVersion, incrementVersion, sortVersionsAscending } from './appVersionsPage.helpers';
import { IAppVersionTableData } from './AppVersionsPage.interfaces';


const AppVersionsPage = () => {
  const { handleToastError, handleToastSuccess } = useToast();
  const { push } = useRouter();
  const { appDetails, isLoading = false, onUpdateApp, appPageBaseUrl } = useContext(AppDetailsContext);
  const { dashboardLink } = useContext(DashboardContext);

  const [appVersionTableData, setAppVersionTableData] = useState<IAppVersionTableData[]>([]);

  const { title = '', logo,  averageRateValue = 0, versions = [], identifier } = appDetails ?? {};
  const { presignedUrl} = logo ?? {}; 

  const handleError = (error: ApolloError) => {
    handleToastError({
      error,
    });
  };

  const { handleSubmitAppForApproval, isLoading: isSubmitAppForApprovalLoading = false } = useSubmitAppForApproval({
    onCompleted: () => {
      handleToastSuccess({
        message: 'App successfully submitted for approval.',
      });
      onUpdateApp();
    },
    onError: handleError,
  });

  const { handleCreateNewAppVersion, isLoading: isCreateNewAppVersionLoading = false } = useCreateNewAppVersion({
    onCompleted: () => {
      handleToastSuccess({
        message: 'New version successfully created.',
      });
      onUpdateApp();
    },
    onError: handleError,
  });

  

  const handleCreateNewVersion = () => {
    const latestVersion = getGreatestVersion(versions);

    handleCreateNewAppVersion({
      version: latestVersion ? incrementVersion(String(latestVersion)): '1.0.0.0',
      type: 'BETA',
      identifier: String(identifier),
    });
  };

  const onViewCodeOnClick = (version: IDBAppVersion) => {
    const { id: versionId, components = [] } = version;
    const [ firstComponent ] = components;
    let componentId = '';
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (firstComponent) {
      componentId = String(firstComponent.id);
    }

    const url = `${appPageBaseUrl}/${versionId}/components${componentId ? `/${String(componentId)}`: ''}`;
    push(url).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };
  
  const onSubmitForApprovalOnClick = (selectedVersion: IDBAppVersion) => {
    const { version } = selectedVersion;
    handleSubmitAppForApproval({
      inputType: {
        identifier: String(identifier),
        version,
      },
    })
  };

  useEffect(() => {
    if (versions.length === 0) {
      return;
    }

    setAppVersionTableData(
      getAppVersionTableData({
        appVersionItems: sortVersionsAscending(versions) ,
        onViewCodeOnClick,
        onSubmitForApprovalOnClick,

      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versions]);


  return (
    <AppTemplatePage 
      activePageId="versions"
      breadcrumbItems={[
        {
          key: '0',
          content: <Link href={`${String(dashboardLink)}/library/apps/marketplace`}>Market place</Link>,
        },
        {
          key: '1',
          content: 'App versions',
        },
      ]}
      dashboardLink={`${String(dashboardLink)}/library/apps/marketplace`}
      dashboardLinkText="Marketplace"
      description={`App versions for ${title}`}
      informationBoxMessage={
        <FlexContainer justifyContent="space-between">
          <span>App versions for <strong>{title}</strong></span>
          <FlexContainer><strong>Overall rating</strong>: <CustomRating iconCount={1} name="overall-rating" rateValue={averageRateValue > 0 ? 1 : 0} isReadOnly/> {averageRateValue}</FlexContainer>
        </FlexContainer>
      }
      isLoading={isLoading || isSubmitAppForApprovalLoading || isCreateNewAppVersionLoading}
      logoUrl={presignedUrl}
      pageTitle="App versions" 
      size={presignedUrl ? 'small' : 'medium'} 
      title={String(title)}
    >
      <div style={{width: '100%'}}>
        <Spinner isLoading={isLoading} />
        <FilteredTable<IAppVersionTableData>
          childrenEnd={
            <Button
              size="xs"
              text="Create new version"
              onClick={handleCreateNewVersion}
            />
          }
          childrenStart={<></>}
          columns={APP_VERSION_TABLE_COLUMNS} 
          data={appVersionTableData} 
          emptyStateComponent={(
            <EmptyState
              buttonText="Create new version"
              imageUrl="/images/empty-state.webp"
              text={!isLoading ? `You have no app versions. Press create new version below to get started` : ''}
              title={isLoading ? `Loading versions` : `No app versions`}
              onClick={handleCreateNewVersion}
            />
          )}
          isLoading={isLoading || isSubmitAppForApprovalLoading || isCreateNewAppVersionLoading}
        />
      </div>
    </AppTemplatePage>
  );
};

export { AppVersionsPage };
