import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_APP_COMMENT_MUTATION } from './useUpdateAppComment.graphql';
import {
  IUpdateAppCommentMutationGraphQLResponse,
  IUpdateAppCommentMutationVariables,
  IHandleUpdateAppCommentParams,
  IUseUpdateAppCommentOptions,
  IUseUpdateAppComment,
} from './useUpdateAppComment.interfaces';

const useUpdateAppComment = (options?: IUseUpdateAppCommentOptions): IUseUpdateAppComment => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateAppCommentOptions);

  const [executeUpdateAppComment, { loading: isLoading }] = useEkardoMutation<
  IUpdateAppCommentMutationGraphQLResponse,
  IUpdateAppCommentMutationVariables
  >({
    mutation: mutation ?? UPDATE_APP_COMMENT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            updateRate: isCompleted,
          },
        } = data;
        
        void onCompleted({ isCompleted });
      },
      onError,
    },
    token,
  });

  const handleUpdateAppComment = ({
    rateId,
    rate,
    comment,
    title,
  }: IHandleUpdateAppCommentParams) => {
    void executeUpdateAppComment({
      variables: {
        inputType: {
          rateId,
          rate,
          comment,
          title,
        },
        rateId,
      },
    });
  };

  return {
    handleUpdateAppComment,
    isLoading,
  };
};

export { useUpdateAppComment };
