import { gql } from '@apollo/client';


const GET_CONTENT_SECTION_DETAILS = gql`
  query getSectionDetails($contentSectionId: Int!) {
    contentSection {
      getContentSection(contentSectionId: $contentSectionId) {
        id
        contentPageId
        contentLayoutId
        sort
        backgroundColor
        visible
        borderColor
        title
        cssStyle {
          id
          name
        }
        background {
          assetId
          contentType
          s3Key
          presignedUrl
        }
        sectionContainers {
          id
        }
        style
        contentEvent {
          id
          animation
          animationDirection
          entityId
          event
          eventDirection
          eventTitle
          infinite
          millisecondDelay
          millisecondSpeed
          asset {
            assetId
            contentType
            s3Key
            presignedUrl
          }
          backgroundColor
          borderColor
          titleColor
          style
          triggerStyle
          iconHorizontalAlignment
          isDefaultOpen
        }
      }
    }
  }
`;

export { GET_CONTENT_SECTION_DETAILS };
