import { gql } from '@apollo/client';

const GET_TRANSCRIPT_TAIL_TYPES = gql`
  query getTails {
    __type(name: "EBubbleTail") {
      enumValues {
        name
      }
    }
  }
`;
export { GET_TRANSCRIPT_TAIL_TYPES };
