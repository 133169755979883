import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_CONTENT_GROUP_STATUS } from './useUpdateContentGroupStatus.graphql';
import {
  IUpdateContentGroupStatusMutationGraphQLResponse,
  IUpdateContentGroupStatusMutationVariables,
  IHandleUpdateContentGroupStatusParams,
  IUseUpdateContentGroupStatusOptions,
  IUseUpdateContentGroupStatus,
} from './useUpdateContentGroupStatus.interfaces';

const useUpdateContentGroupStatus = (options?: IUseUpdateContentGroupStatusOptions): IUseUpdateContentGroupStatus => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateContentGroupStatusOptions);

  const [executeContentGroup, { loading: isLoading }] = useEkardoMutation<
  IUpdateContentGroupStatusMutationGraphQLResponse,
  IUpdateContentGroupStatusMutationVariables
  >({
    mutation: mutation ?? UPDATE_CONTENT_GROUP_STATUS,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: { updateContentGroupStatus: contentGroup },
        } = data;

        void onCompleted({
          contentGroup,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateContentGroupStatus = ({ contentGroupId, status }: IHandleUpdateContentGroupStatusParams) => {
    void executeContentGroup({
      variables: {
        contentGroupId,
        status,
      },
    });
  };

  return {
    handleUpdateContentGroupStatus,
    isLoading,
  };
};

export { useUpdateContentGroupStatus };
