import { gql } from '@apollo/client';


const GET_CONTENT_CONTAINER_DETAILS = gql`
  query getContainerDetails($containerId: Int!) {
    container {
      get(containerId: $containerId) {
        id
        sort
        identifier
        sectionId
        backgroundColor
        borderColor
        title
        visible
        cssStyle {
          id
          name
        }
        contentEventId
        contentEvent {
          animation
          animationDirection
          entityId
          event
          eventDirection
          eventTitle
          infinite
          millisecondDelay
          millisecondSpeed
          asset {
            assetId
            contentType
            s3Key
            presignedUrl
          }
          backgroundColor
          borderColor
          titleColor
          style
          triggerStyle
          iconHorizontalAlignment
          isDefaultOpen
        }
      }
    }
  }
`;

export { GET_CONTENT_CONTAINER_DETAILS };
