
import { GET_TOPICS_PER_PROJECT_QUERY } from './useGetTopicsPerProject.graphql';
import {
  IGetTopicsPerProjectQueryGraphQLResponse,
  IGetTopicsPerProjectQueryVariables,
  IHandleGetTopicsPerProjectParams,
  IUseGetTopicsPerProject,
  IUseGetTopicsPerProjectOptions,
} from './useGetTopicsPerProject.interfaces';

import { useBonoboLazyQuery } from '../../useBonoboLazyQuery';

const useGetTopicsPerProject = (options?: IUseGetTopicsPerProjectOptions): IUseGetTopicsPerProject => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: bonoboProjectId } = options ?? ({} as IUseGetTopicsPerProjectOptions);

  const [executeGetTopicsPerProject, { loading: isLoading }] = useBonoboLazyQuery<
    IGetTopicsPerProjectQueryGraphQLResponse,
    IGetTopicsPerProjectQueryVariables
  >({
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          topic: { getTopicsPerProject: topicsPerProjectConnection },
        } = data;

        void onCompleted({
          topicsPerProjectConnection,
        });
      },
      onError,
    },
    query: query ?? GET_TOPICS_PER_PROJECT_QUERY,
    token,
  });

  const handleGetTopicsPerProject = ({ projectId, status = null }: IHandleGetTopicsPerProjectParams) => {
    void executeGetTopicsPerProject({
      variables: {
        projectId,
        status,
      },
    });
  };

  return {
    handleGetTopicsPerProject,
    isLoading,
  };
};

export { useGetTopicsPerProject };
