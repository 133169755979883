import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IAssetTag, IDBAsset } from '@netfront/common-library';
import { ControlledForm, Dialog, DialogButtons, FormFieldProps, Spacing, Spinner, ToggleSwitch, useControlledForm } from '@netfront/ui-library';
import { TagsSelector } from 'components';
import { useToast, useBulkDeleteAssets, useBulkUpdateAssetTags, useBulkToggleAssetsPublic } from 'hooks';
import pluralize from 'pluralize';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { ASSET_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP, ASSET_BULK_ACTION_DIALOG_TITLE_MAP } from './AssetBulkActionDialog.constants';
import { AssetBulkActionDialogProps } from './AssetBulkActionDialog.types';


const AssetBulkActionDialog = ({
  bulkActionType,
  isOpen = false,
  onSave,
  handleCloseDialog,
  selectedAssets = [],
  projectId,
}: AssetBulkActionDialogProps) => {
  const { handleToastSuccess, handleToastError } = useToast();

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});

  const { control, handleSubmit, reset, setValue} = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        assetIds: yup.array().required().min(1, 'Please provide at least one asset'),
      }),
    ),
  });


  const { handleBulkDeleteAssets, isLoading: isBulkDeleteAssetsLoading = false } =
  useBulkDeleteAssets({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) {
        return;
      }

      reset();
      onSave();

      handleToastSuccess({
        message: 'Assets successfully deleted',
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleBulkUpdateAssetTags, isLoading: isBulkUpdateUpdateAssetTagsLoading = false } = useBulkUpdateAssetTags({
    onCompleted: () => {
      handleToastSuccess({
        message: `Assets successfully updated`,
      });
      reset();
      onSave();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });
  
  const { handleBulkToggleAssetsPublic, isLoading: isBulkToggleAssetsPublicLoading = false } = useBulkToggleAssetsPublic({
    onCompleted: () => {
      handleToastSuccess({
        message: `Assets successfully updated`,
      });
      reset();
      onSave();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const updateTags = (item: FormFieldProps) => {
    const { assetIds, tags = []} = item;
    
    handleBulkUpdateAssetTags({
      assetIds,
      tagIds: (tags as IAssetTag[]).map(({ id }) => Number(id)),
    });
  };



  const deleteAssets = (item: FormFieldProps) => {
    const { assetIds } = item;
    handleBulkDeleteAssets({
      assetIds,
    });
  };

  const updatePublicity = (item: FormFieldProps) => {
    const { assetIds, isPublic = false } = item;
    handleBulkToggleAssetsPublic({
      assetIds,
      isPublic,
    });
  };

  const saveMap: { [key: string]: (item: FormFieldProps) => void } = {
    updateTags: updateTags,
    delete: deleteAssets,
    toggleIsPublic: updatePublicity,
  };


  const handleSave = (item: FormFieldProps) => {
    const bulkHandler = saveMap[bulkActionType];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    bulkHandler(item);
  }

  useEffect(() => {
    setDefaultValues({
      assetIds: selectedAssets,
      tags: [],
      isPublic: false,
    });

  }, [selectedAssets]);

  const isLoading = isBulkUpdateUpdateAssetTagsLoading || isBulkDeleteAssetsLoading || isBulkToggleAssetsPublicLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      
        <Dialog
          isOpen={isOpen}
          title={ASSET_BULK_ACTION_DIALOG_TITLE_MAP[bulkActionType]}
          onClose={handleCloseDialog}
        >
          <ControlledForm
            callBack={(item: FormFieldProps) => {
              handleSave(item);
            }}
            handleSubmit={handleSubmit}
            isStopPropagation
          >
            <p>{ASSET_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[bulkActionType]} {selectedAssets.length} {pluralize('asset', selectedAssets.length)}</p>
            {bulkActionType === 'updateTags' && (
              <Spacing size="x-large">
                <TagsSelector
                  initialTagList={[]}
                  projectId={projectId}
                  onUpdateSelectedTags={(tags: IDBAsset['tagList']) => setValue('tags', tags)}
                />
              </Spacing>
            )}

            {bulkActionType === 'toggleIsPublic' && (
              <Spacing size="x-large">
              <Controller
                control={control as Control<FormFieldProps>}
                name="isPublic"
                render={({ field }) => (
                  <ToggleSwitch
                    additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
                    id="id_bulk_toggle_is_public"
                    isChecked={field.value}
                    labelText="Is public"
                    name={field.name}
                    isInline
                    isLabelSideBySide
                    onChange={field.onChange}
                  />
                )}
              />
            </Spacing>

            )}
            <DialogButtons 
              submitButtonText={ASSET_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[bulkActionType]}
              onCancel={handleCloseDialog}
              onSaveButtonType="submit"
            />

          </ControlledForm>
      </Dialog>
    </>
  );
};

export { AssetBulkActionDialog };
