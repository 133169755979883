import React, { useState, useEffect } from 'react';

import { Breadcrumbs, HomeIcon } from '@netfront/ui-library';

import { ConditionQuestionDetails } from '../ConditionQuestionTypes';
import { IActionCondition } from '../UpsertAction/UpsertAction.interfaces';

import { UpsertConditionProps } from './UpsertCondition.interfaces';

const UpsertCondition = ({ onUpdate, onCancel, onDelete, selectedCondition }: UpsertConditionProps) => {
  const [currentCondition, setCurrentCondition] = useState<IActionCondition>({} as IActionCondition);

  useEffect(() => {
    if (!selectedCondition) return;

    setCurrentCondition({
      ...selectedCondition,
      minTimeSpan: selectedCondition.minTimeSpan ?? 0,
      maxTimeSpan: selectedCondition.maxTimeSpan ?? 0,
    });
  }, [selectedCondition]);

  return (
    <>
      <Breadcrumbs
        itemDivider="chevron"
        items={[
          {
            key: 'conditions',
            onClick: onCancel,
            content: 'Conditions',
            icon: HomeIcon,
          },
          {
            key: 'upsert-condition',
            content: <span className="c-breadcrumb-item__link">{selectedCondition ? 'Edit' : 'Add'} Condition</span> ,
          }
        ]}
        isContained
      />
      <ConditionQuestionDetails selectedCondition={currentCondition} onCancel={onCancel} onDelete={onDelete} onUpdate={onUpdate} />
    </>
  );
};

export { UpsertCondition };
