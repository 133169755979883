import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_TRANSCRIPT_MUTATION } from './useUpdateTranscript.graphql';
import {
  IUpdateTranscriptMutationGraphQLResponse,
  IUpdateTranscriptMutationVariables,
  IHandleUpdateTranscriptParams,
  IUseUpdateTranscriptOptions,
  IUseUpdateTranscript,
} from './useUpdateTranscript.interfaces';

const useUpdateTranscript = (options?: IUseUpdateTranscriptOptions): IUseUpdateTranscript => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateTranscriptOptions);

  const [executeUpdateTranscript, { loading: isLoading }] = useEkardoMutation<IUpdateTranscriptMutationGraphQLResponse, IUpdateTranscriptMutationVariables>({
    mutation: mutation ?? UPDATE_TRANSCRIPT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          bubbleText: {
            update: transcript,
          },
        } = data;

        void onCompleted({ transcript });
      },
      onError,
    },
    token,
  });

  const handleUpdateTranscript = async ({ request, transcriptId }: IHandleUpdateTranscriptParams) => {
    await executeUpdateTranscript({
      variables: {
        request,
        transcriptId,
      },
    });
  };

  return {
    handleUpdateTranscript,
    isLoading,
  };
};

export { useUpdateTranscript };
