import { gql } from '@apollo/client';

const SEARCH_PAGINATED_APPS_QUERY = gql`
  query getApps(
    $sorting: EAppSorting
    $after: String
    $first: Int
    $title: String
    $projectId: String
    $status: [EAppStatus]!
  ) {
    app {
      searchApps(
        sorting: $sorting
        after: $after
        first: $first
        title: $title
        projectId: $projectId
        status: $status
      ) {
        totalCount
        pageInfo {
          endCursor
        }
        edges {
          cursor
          node {
            id
            description
            identifier
            isPublished
            title
            # logo {
            #  presignedUrl
            # }
            logoId
            averageRateValue
          }
        }
      }
    }
  }
`;

export { SEARCH_PAGINATED_APPS_QUERY };
