import React from 'react';

import { RegionPickerProps } from './RegionPicker.interfaces';


const RegionPicker = ({ imageUrl, onClick, children, isDisabled = false, onImageLoad }: RegionPickerProps) => {

  const handleImageClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Get the image element
    const imageElement = e.currentTarget;

    // Get the image's bounding rectangle
    const rect = imageElement.getBoundingClientRect();

    // Calculate the click position relative to the image
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    // Convert the pixel position to a percentage
    const xPercent = Math.round((offsetX / rect.width) * 100);
    const yPercent = Math.round((offsetY / rect.height) * 100);

    onClick(
      {
        left: xPercent,
        top: yPercent,
      }
    )
  };

  return (
      <div
        className={`c-region-picker ${isDisabled ? 'is-disabled': ''}`}
        onClick={!isDisabled ? handleImageClick: undefined}
      >
        <img alt="region picker area" className="c-region-picker__image" src={imageUrl} onLoad={onImageLoad}/>
        {children}
      </div>
  );
};


export { RegionPicker };
