import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { AssetType, FormFieldProps } from "@netfront/ui-library";
import { getAssetDefaultValues, getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getQuickAddEventValues, getUpsertContentEventStatus, resetAssetValues, getContentStyleDetails, getFormattedContentStyles } from "components";
import { ISnippetConfig } from "hooks";
import capitalize from "lodash.capitalize";

const getAssetSnippetDefaultValues = ({ assetType, snippet } : { assetType: AssetType; snippet?: IContentSnippet;}): FormFieldProps => {
  const {
    title,
    contentEvent,
    asset,
    visible: isVisible = true,
    isSensitive = false,
  } = snippet ?? {};

  const assetDetails = getAssetDefaultValues({
    assetType,
    asset,
    assetIdKey: 'initialAssetId',
    title: title ?? `${capitalize(assetType)} snippet`,
    isSensitive: false,
  });
  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);
  const styleDetails = getContentStyleDetails({ entity: snippet });

  return {
    title: title ?? `${capitalize(assetType)} snippet`,
    isVisible,
    isSensitive,
    type: assetType,
    isAuthorVisible: false,
    isAutoPlay: false,
    isDownloadAvailable: false,
    code: '',
    embedProvider: '',
    isCreateNew: false,
    ...eventDetails,
    ...animationDetails,
    // asset details
    ...assetDetails,
    ...styleDetails,
    view: snippet ? 'upload': 'selector',
  };
};

const getAssetSnippetCommonVariables = ({
  assetId,
  assetType,
  eventAssetId,
  item,
} : {
  assetId? : IDBAsset['assetId'];
  assetType: AssetType;
  eventAssetId?: IDBAsset['assetId'];
  item: FormFieldProps;
}): ISnippetConfig => {

  const {
    title = 'Asset snippet',
    isVisible,
    code,
    embedProvider,
    // isSensitive = false,
    isAuthorVisible,
    isAutoPlay,
    // isDownloadAvailable,
    type,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);
  const { baseVariables, customTheme } = getFormattedContentStyles({ item });

  let snippetSpecificDetails = {};

  if (type === 'video') {
    snippetSpecificDetails = {
      videoSnippet: {
        videoId: String(assetId),
        startAt: 0,
      },
    };
  }

  if (type === 'audio') {
    snippetSpecificDetails = {
      audioSnippet: {
        audioId: String(assetId),
        duration: 0,
        startAt: 0,
      },
    };
  }

  if (type === 'image') {
    snippetSpecificDetails = {
      imageSnippet: {
        imageId: String(assetId),
        height: 50,
        width: 50,
      },
    };
  }

  if (type === 'document') {
    snippetSpecificDetails = {
      documentSnippet: {
        documentId: String(assetId),
      },
    };
  }

  if (type === 'embed') {
    snippetSpecificDetails = {
      embedSnippet: {
        code,
        embedProvider,
        title,
        type: assetType,
        isAuthorVisible,
        isAutoPlay,
      },
    }
  }

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
      // isSensitive,
      ...baseVariables,
    },
    ...snippetSpecificDetails,
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    ...item,
    type,
    customTheme,
  };

  return sharedVariables;

};

const setQuickAddAssetValues = (values: FormFieldProps, assetType: AssetType): FormFieldProps => {
  return {
    ...getQuickAddEventValues(values),
    ...resetAssetValues(values, 'initialAssetId'),
    title: `${capitalize(assetType)} snippet`,
    view: 'selector',
    code: '',
    embedProvider: '',
    isAuthorVisible: false,
    isAutoPlay: false,
    isDownloadAvailable: false,
  };
};

export { getAssetSnippetDefaultValues, getAssetSnippetCommonVariables, setQuickAddAssetValues };
