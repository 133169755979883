import React, { useRef, useState } from 'react';

import { BinIcon, ButtonIconOnly, EditIcon, FlexContainer, Input, Spacing, Spinner, TickIcon } from '@netfront/ui-library';
import { useCreateAsset, useDeleteAsset, useToast } from 'hooks';
import { pushImageToAws } from 'utils';


import { Uploader } from 'components/Uploader';

import { ResponseSetItemProps } from './ResponseSetItem.interfaces';


const ResponseSetItem = ({
  item,
  index,
  handleDeleteResponseItem,
  handleUpsertResponseItem,
  isLabelHidden = false,
  projectId,
  hasUploadImages = false,
}: ResponseSetItemProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [localLabel, setLocalLabel] = useState<string>(item.label);
  const [localValue, setLocalValue] = useState<string>(item.value);
  const [isEditMode, setIsEditMode] = useState<boolean>(item.isEditableOnLoad);
  const [isAssetUploadToAwsLoading, setIsAssetUploadToAwsLoading] = useState<boolean>(false);
  const droppedFileRef = useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const { assetId } = item;

  const { handleDeleteAsset } = useDeleteAsset({
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleCreateAsset, isLoading: isCreateAssetLoading = false } = useCreateAsset({
    onCompletedAsync: async (data) => {

      const { signedUrl } = data;

      setIsAssetUploadToAwsLoading(true);

      await pushImageToAws(signedUrl, droppedFileRef.current.value, () => {
        handleToastSuccess({ message: 'Image successfully uploaded'});
        droppedFileRef.current.value = undefined;
        handleUpsertResponseItem({
          ...item,
          label: localLabel,
          value: localValue,
          assetId: data.assetId,
          asset: data,
        });

        setIsEditMode(false);

        setIsAssetUploadToAwsLoading(false);
      });

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleSaveItem = () => {
    if (droppedFileRef.current.value) {
      const {
        name = '',
        type = '',
        size = 0,
      } = droppedFileRef.current.value;

      handleCreateAsset({
        projectId,
        fileName: name,
        contentType: type,
        description: localLabel,
        alt: localLabel,
        imageSize: 'SMALL',
        tagList: [],
        type: 'IMAGE',
        fileSizeInBytes: size,
        title: localLabel,
      });
    } else {

      if (hasDeletedOriginalImageRef.current.value && assetId) {
        handleDeleteAsset({
          assetId: assetId,
        });
      }
      handleUpsertResponseItem({
        ...item,
        label: localLabel,
        value: localValue,
        assetId: hasDeletedOriginalImageRef.current.value ? undefined: assetId,
        asset: hasDeletedOriginalImageRef.current.value ? undefined: item.asset,
      });
      setIsEditMode(false);
    }
  };

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };

  return (
    <>
      <Spinner isLoading={isCreateAssetLoading || isAssetUploadToAwsLoading} />
      <Spacing>
        <FlexContainer key={index} alignItems="flex-end" gap="x-small">
          <div style={{ flexGrow: 1 }}>
            <Input
              hasPadding={false}
              id={`id_response_label_${item.id}`}
              isDisabled={!isEditMode}
              isLabelHidden={isLabelHidden}
              labelText="Label"
              name="response_label"
              placeholder="Add a label here"
              type="text"
              value={localLabel}
              isRequired
              onChange={({ target: { value } }) => {
                setLocalLabel(value);
              }}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <Input
              hasPadding={false}
              id={`id_response_value_${item.id}`}
              isDisabled={!isEditMode}
              isLabelHidden={isLabelHidden}
              labelText="Value"
              name="response_value"
              type="text"
              value={localValue}
              isRequired
              onChange={({ target: { value } }) => {
                setLocalValue(value);
              }}
            />
          </div>
          <FlexContainer gap="2x-small">
            <ButtonIconOnly icon={BinIcon} text="Add Response" onClick={() => handleDeleteResponseItem(item.id)} />
            {isEditMode
              ? <ButtonIconOnly icon={TickIcon} text="Add Response" onClick={handleSaveItem} />
              : <ButtonIconOnly icon={EditIcon} text="Add Response" onClick={() => setIsEditMode(true)} />
            }
          </FlexContainer>
        </FlexContainer>
        {hasUploadImages && ((!isEditMode && item.asset?.presignedUrl) || isEditMode) && (
          <Uploader
            assetType="image"
            initialUrl={item.asset?.presignedUrl}
            labelText="Upload image"
            onDelete={handleRemoveAsset}
            onDrop={handleDropFile}
          />
        )}
      </Spacing>
    </>
  );
};

export { ResponseSetItem };
