import { gql } from '@apollo/client';

const UPDATE_PAGE = gql`
  mutation UpdateContentPage(
    $contentPage: UpdateContentPageInput!
    $shouldDetachCustomTheme: Boolean!,
    $shouldAttachCustomTheme: Boolean!,
    $contentPageId: Int!
    $styleId: Int!
  ) {
    contentPage {
      updateContentPage(contentPage: $contentPage) {
        id
        seoTitle
        seoDescription
        releaseDate
        contentPageTypeId
        title
        url
        status
        mode
        contentPageConfigurationNavigation {
          enableBackButton
          enableNextButton
          backButtonToolTip
          nextButtonToolTip
        }
        minimumExposureTime
        audio {
          assetId
          presignedUrl
        }
        tags {
          id
          name
          sort
        }
        asset {
          assetId
          presignedUrl
          isAnimatedImage
        }
        contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
          asset {
            assetId
            presignedUrl
            contentType
            fileName
            fileSizeInBytes
            isAnimatedImage
            presignedUrl
            s3Key
            type
          }
          contentPageConfigurationNavigation {
            enableBackButton
            enableNextButton
            backButtonToolTip
            nextButtonToolTip
          }
          bubbleTexts {
            angle
            character {
              id
              image {
                presignedUrl
                contentType
                s3Key
              }
              name
              projectId
              imageId
            }
            characterId
            id
            isTailVisible
            hasShadow
            shape
            tailType
            sort
            text
            w
            x
            y
          }
          audio {
            assetId
            presignedUrl
            contentType
            fileName
            fileSizeInBytes
            presignedUrl
            s3Key
            type
          }
          id
          minimumExposureTime
          sort
          url
          title
          parentId
        }
      }
    }
    
    cssStyle {
      detach(contentPageId: $contentPageId)
        @include(if: $shouldDetachCustomTheme)

      attachStyleToContentPage(contentPageId: $contentPageId, styleId: $styleId)
        @include(if: $shouldAttachCustomTheme)
    }
  }
`;

export { UPDATE_PAGE };
