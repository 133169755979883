import { ReactNode } from 'react';

import { ContentBuilderNavigationItemProps, DataIcon, ListViewIcon, UserFlowIcon } from '@netfront/ui-library';
import Link from 'next/link';

import { GetAppDetailsSidebarNavigationItemsProps } from './AppDetailsContext.interfaces';

const getAppDetailsSidebarNavigationItems = ({ appDetails, versionId, baseUrl }: GetAppDetailsSidebarNavigationItemsProps): ContentBuilderNavigationItemProps[] => {
  const { versions = [] } = appDetails;

  return [
    {
      icon: ListViewIcon,
      label: 'Details',
      id: 'details',
      isDisabled: false,
      type: 'details',
      hasAddButton: false,
      hasBulkAction: false,
      subItems: [],
      wrapperComponent: ({ children }: { children: ReactNode }) => <Link href={`${baseUrl}/details`}>{children}</Link>,
      conditionalSettingItems: {
        'id_edit_component': false,
        'id_add_new_component': false,
        'id_delete_item': false,
      },
    },
    {
      icon: UserFlowIcon,
      label: 'Versions',
      id: 'versions',
      isDisabled: false,
      type: 'versions',
      hasAddButton: false,
      hasBulkAction: false,
      subItems: versions.map((appVersion) => {
        const { version, id, components = [] } = appVersion;
        return {
          icon: DataIcon,
          label: `V${version}`,
          id: `version-${String(id)}`,
          isDisabled: false,
          type: 'version',
          hasAddButton: id === versionId,
          subItems: id === versionId 
          ? components.map((component) => {
              const { title, id: componentId } = component;
              return {
                icon: ListViewIcon,
                label: title,
                id: `component-${String(componentId)}`,
                isDisabled: false,
                type: 'component',
                hasAddButton: true,
                wrapperComponent: ({ children }: { children: ReactNode }) => <Link href={`${baseUrl}/${id}/components/${componentId}`}>{children}</Link>,
                conditionalSettingItems: {
                  'id_edit_component': true,
                  'id_add_new_component': true,
                  'id_delete_item': true,
                },
              } as ContentBuilderNavigationItemProps
            })
          : undefined,
          wrapperComponent: ({ children }: { children: ReactNode }) => <Link href={`${baseUrl}/${id}/components`}>{children}</Link>,
          conditionalSettingItems: {
            'id_edit_component': false,
            'id_add_new_component': false,
            'id_delete_item': false,
          },
        } as ContentBuilderNavigationItemProps
      }),
      wrapperComponent: ({ children }: { children: ReactNode }) => <Link href={`${baseUrl}/versions`}>{children}</Link>,
      conditionalSettingItems: {
        'id_edit_component': false,
        'id_add_new_component': false,
        'id_delete_item': false,
      },
    }
  ] as ContentBuilderNavigationItemProps[];
};



export { getAppDetailsSidebarNavigationItems };
