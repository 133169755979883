import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_PAGE } from './useCreatePage.graphql';
import {
  ICreatePageMutationGraphQLResponse,
  ICreatePageMutationVariables,
  IUseCreatePageOptions,
  IUseCreatePage,
  ICreatePageMutationParams,
} from './useCreatePage.interfaces';

const useCreatePage = (options?: IUseCreatePageOptions): IUseCreatePage => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreatePageOptions);

  const [executeCreatePage, { loading: isLoading }] = useEkardoMutation<ICreatePageMutationGraphQLResponse, ICreatePageMutationVariables>({
    mutation: mutation ?? CREATE_PAGE,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { createContentPage: contentPage },
        } = data;

        onCompleted({
          contentPage,
        });
      },
      onError,
    },
    token,
  });

  const handleCreatePage = async ({
    assetId,
    contentGroupId,
    parentId,
    sensitiveContent,
    seoDescription,
    seoTitle,
    tags,
    title,
    url,
    // releaseDate,
    // minimumExposureTime,
    // mode,
    contentPageType,
    status,
    audioId,
    // contentPageConfigurationNavigation
  }: ICreatePageMutationParams) => {
    await executeCreatePage({
      variables: {
        contentPage: {
          assetId,
          audioId,
          contentGroupId,
          // contentPageConfigurationNavigation,
          parentId,
          sensitiveContent,
          seoDescription,
          seoTitle,
          tags,
          title,
          url,
          // releaseDate,
          // minimumExposureTime,
          // mode,
          contentPageType,
          status,
        }
      }
    });
  };

  return {
    handleCreatePage,
    isLoading,
  };
};

export { useCreatePage };
