import React, { ChangeEvent, useEffect, useState, useRef } from 'react';

import {
  ButtonIconOnly,
  CaretIcon,
  ColorPicker,
  ColorPickerPreview,
  Dialog,
  Input,
  Label,
  NavigationButton,
  RgbaColor,
  RotateLeftIcon,
  RotateRightIcon,
  SidebarButtons,
  Spacing,
  ToggleSwitch,
  Tooltip,
  TooltipIcon,
} from '@netfront/ui-library';
import AvatarEditor from 'react-avatar-editor';

import { imageSidebarEditImageViewConstants } from './ImageSidebarEditImageView.constants';
import { ImageSidebarEditImageViewProps } from './ImageSidebarEditImageView.interfaces';

interface CustomImage {
  backgroundColorRgba: RgbaColor;
  backgroundColorString: string;
  borderRadius: 0;
  color: number[];
  height: number;
  isAllowScale: boolean;
  isDisableCanvasRotation: boolean;
  isTransparent: boolean;
  rotate: number;
  scale: number;
  url: string;
  width: number;
}

const ImageSidebarEditImageView = ({ image, onToggleEditImageTab }: ImageSidebarEditImageViewProps) => {
  const { imageDefaultBackgroundColorString, imageDefaultBackgroundColorRgba, imageDefaultColor } = imageSidebarEditImageViewConstants;

  const editorRef = useRef<AvatarEditor>(null);

  const [customImage, setCustomImage] = useState<CustomImage>({
    backgroundColorString: imageDefaultBackgroundColorString,
    backgroundColorRgba: imageDefaultBackgroundColorRgba,
    borderRadius: 0,
    color: imageDefaultColor,
    isAllowScale: false,
    isDisableCanvasRotation: false,
    isTransparent: false,
    height: 0,
    rotate: 0,
    scale: 1,
    url: String(image?.presignedUrl),
    width: 0,
  });
  const [isColorPicker, setIsColorPicker] = useState<boolean>(false);
  const [isPreviewDialogVisible, setIsPreviewDialogVisible] = useState(false);

  const {
    backgroundColorString,
    backgroundColorRgba,
    borderRadius,
    color,
    isAllowScale,
    isDisableCanvasRotation,
    isTransparent,
    height,
    rotate,
    scale,
    width } =
    customImage;

  const handleChangeCustomImage = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    setCustomImage((prev) => {
      return {
        ...prev,
        [name]: Number(value),
      };
    });
  };

  const handleClickRotateLeft = () => {
    const adjustedRotate = rotate % 90;

    setCustomImage((prev) => {
      return {
        ...prev,
        rotate: (prev.rotate - adjustedRotate - 90) % 360,
      };
    });
  };

  const handleClickRotateRight = () => {
    const adjustedRotate = rotate % 90;

    setCustomImage((prev) => {
      return {
        ...prev,
        rotate: (prev.rotate - adjustedRotate + 90) % 360,
      };
    });
  };

  const handleColorChange = (newColor: RgbaColor) => {
    const { r, g, b, a = 0 } = newColor;
    const updatedColorRgba = `rgba(${Number(r)}, ${Number(g)}, ${Number(b)}, ${Number(a)})`;

    setCustomImage((prev) => {
      return {
        ...prev,
        backgroundColorString: updatedColorRgba,
        backgroundColorRgba: newColor,
        color: isTransparent ? [r, g, b, a] : imageDefaultColor,
      };
    });
  };

  const handleDisableCanvasRotation = () => {
    setCustomImage((prev) => {
      return {
        ...prev,
        isDisableCanvasRotation: !isDisableCanvasRotation,
        rotate: !isDisableCanvasRotation ? 0 : prev.rotate,
      };
    });
  };

  const handleToggleAllowScale = () => {
    setCustomImage((prev) => {
      return {
        ...prev,
        isAllowScale: !isAllowScale,
        scale: isAllowScale ? 1 : prev.scale,
      };
    });
  };

  const handleToggleIsTransparent = () => {
    setCustomImage((prev) => {
      return {
        ...prev,
        isTransparent: !isTransparent,
        backgroundColorString: isTransparent ? imageDefaultBackgroundColorString : prev.backgroundColorString,
        backgroundColorRgba: isTransparent ? imageDefaultBackgroundColorRgba : prev.backgroundColorRgba,
        color: isTransparent ? imageDefaultColor : prev.color,
      };
    });
  };

  const handleSaveCustomImage = () => {
    setIsPreviewDialogVisible(true);
  };

  useEffect(() => {
    if (editorRef.current === null) {
      return;
    }

    if (!isPreviewDialogVisible) {
      return;
    }

    // TODO: Stuck fixing the issue
    // const url = editorRef.current.getImageScaledToCanvas().toDataURL();

    setCustomImage((prev) => {
      return {
        ...prev,
        url: customImage.url,
      };
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviewDialogVisible]);

  return (
    <div className="c-image-sidebar-edit-image-view">
      <Spacing size="2x-large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <NavigationButton
            additionalClassNames="c-action__back-to-action"
            direction="previous"
            icon={CaretIcon}
            rotationCssSuffix="90"
            text="General"
            onClick={onToggleEditImageTab}
          />

          <Tooltip
            additionalClassNames="c-sidebar-section__tooltip"
            bubbleTheme="dark"
            icon={TooltipIcon}
            placement="left"
            text="Configure the image"
          />
        </section>
      </Spacing>

      <Spacing size="x-large">
        <div className="c-avatar-editor">
          <AvatarEditor
            ref={editorRef}
            backgroundColor={isTransparent ? backgroundColorString : imageDefaultBackgroundColorString}
            borderRadius={borderRadius}
            color={isTransparent ? color : imageDefaultColor}
            height={500}
            image={image?.presignedUrl as unknown as File}
            rotate={rotate}
            scale={scale}
            width={500}
          />
        </div>
      </Spacing>

      <Spacing size="2x-large">
        <Label forId="scale" labelText="Zoom" tooltipText="Configure the image scale"/>
        <input
          className="c-input-range"
          id="scale"
          max="2"
          min={!isAllowScale ? '1' : '0.25'}
          name="scale"
          step={0.05}
          type="range"
          value={scale}
          onChange={handleChangeCustomImage}
        />

      </Spacing>

      <Spacing size="3x-large">
        <section className="c-sidebar-section">
          <ToggleSwitch id="isAllowScale" isChecked={isAllowScale} labelText="Allow scale < 1" onChange={handleToggleAllowScale} />
        </section>
      </Spacing>

      <Spacing size="2x-large">
        <Label forId="border-radius" labelText="Border radius" tooltipText="Configure the image border radius"/>
        <input
          className="c-input-range"
          id="border-radius"
          max="250"
          min="0"
          name="borderRadius"
          step={1}
          type="range"
          value={borderRadius}
          onChange={handleChangeCustomImage}
        />
      </Spacing>

      <Spacing size="2x-large">
        <ToggleSwitch
          id="isTransparentImage"
          isChecked={isTransparent}
          labelText="Transparent image"
          tooltipText="Decrease the zoom out level to below 1"
          onChange={handleToggleIsTransparent}
        />
      </Spacing>

      {isTransparent && (
        <Spacing size="2x-large">
          <div className="c-image-sidebar-edit-image-view__transparent-image">
            <Label forId="background-color" labelText="Background color" />

            <Spacing size="x-small">
              <ColorPickerPreview
                selectedColor={backgroundColorString}
                onClick={() => setIsColorPicker(!isColorPicker)}
              />
            </Spacing>
            {isColorPicker && <ColorPicker additionalClassNames="" color={backgroundColorRgba} type="rgba" onRgbaChange={handleColorChange} />}
          </div>
        </Spacing>
      )}

      <Spacing size="2x-large">
        <Input id="width" labelText="Image width" name="width" tooltipText="Configure the image width" type="number" value={width} onChange={handleChangeCustomImage}/>
      </Spacing>

      <Spacing size="2x-large">
        <Input
          id="height"
          labelText="Image height"
          name="height"
          tooltipText="Configure the image height"
          type="number"
          value={height}
          onChange={handleChangeCustomImage}
        />

      </Spacing>

      <Spacing size="2x-large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <Label forId="height" labelText="Rotate" />

          <div className="c-sidebar-section__rotate">
            <ButtonIconOnly
              additionalClassNames="c-rotate-icon c-rotate-icon--left"
              icon={RotateLeftIcon}
              text="Rotate left"
              onClick={handleClickRotateLeft}
            />
            <ButtonIconOnly
              additionalClassNames="c-rotate-icon c-rotate-icon--right"
              icon={RotateRightIcon}
              text="Rotate right"
              onClick={handleClickRotateRight}
            />

            <Tooltip
              additionalClassNames="c-sidebar-section__tooltip"
              bubbleTheme="dark"
              icon={TooltipIcon}
              placement="left"
              text="Configure the image rotate left and right"
            />
          </div>
        </section>
      </Spacing>

      <Spacing size="3x-large">
        <ToggleSwitch
          id="isDisableCanvasRotation"
          isChecked={isDisableCanvasRotation}
          labelText="Disable canvas rotation"
          tooltipText="Disable the rotation setting"
          onChange={handleDisableCanvasRotation}
        />

      </Spacing>

      <Spacing size="2x-large">
        <Label forId="rotate" labelText="Rotation" tooltipText="Configure the image rotate"/>
        <input
          className="c-input-range"
          disabled={isDisableCanvasRotation}
          id="rotate"
          max="360"
          min="0"
          name="rotate"
          step={1}
          style={{ cursor: isDisableCanvasRotation ? 'not-allowed' : 'unset' }}
          type="range"
          value={isDisableCanvasRotation ? 0 : rotate}
          onChange={handleChangeCustomImage}
        />
      </Spacing>

      <Dialog
        additionalClassNames="c-preview-image-dialog"
        isOpen={isPreviewDialogVisible}
        title="Preview image"
        onClose={() => setIsPreviewDialogVisible(false)}
      >
        <img alt="Edited picture" src={customImage.url} />
      </Dialog>

      <SidebarButtons onSave={handleSaveCustomImage} />
    </div>
  );
};

export { ImageSidebarEditImageView };
