import { gql } from '@apollo/client';


const GET_USER_PROGRESS_TRACKINGS = gql`
  query getUserProgressTracking($userIds: [Int]!) {
    user {
      getUsers(userIds: $userIds) {
        firstName
        lastName
        email
        id
        created
        userFlowProgress {
          percentageCompleted
          userFlowName
          progress {
            firstTracking{
              percentageCompleted
            }
            name
            stepType
            status
            userFlowStepId
            trackings {
              started
              completed
              percentageCompleted
            }
          }
        }
      }
    }
  }
`;
export { GET_USER_PROGRESS_TRACKINGS };
