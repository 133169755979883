import { IDBAppComponentFile } from "interfaces";

const convertToFile = (content: string, type: string, fileName = 'foo') => {
  const typeNameMap: {[key: string]: string} = {
    js: 'text/javascript',
    css: 'text/css',
    html: 'text/html',
  };

  const newFile = new File([content], `${fileName}.${type}`, {
    type: typeNameMap[type],
  });
  return newFile;
};

const readTextFile = async (file: string) => {
  try {
    const response = await fetch(file);
    if (!response.ok) {
      throw new Error('Failed to fetch file');
    }
    return await response.text();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return '';
  }
};

const handleGetFileText = async (file?: IDBAppComponentFile): Promise<string> => {
  if (!file) return '';
  
  const {
    location: { presignedUrl },
  } = file;

  try {
    const fileText = await readTextFile(String(presignedUrl));
    return fileText;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return '';
  }
};

export { convertToFile, readTextFile, handleGetFileText }; 