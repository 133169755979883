import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_APP_COMPONENT_DETAILS } from './useGetAppComponentDetails.graphql';
import {
  IGetAppComponentDetailsQueryGraphQLResponse,
  IGetAppComponentDetailsQueryVariables,
  IHandleGetAppComponentDetailsParams,
  IUseGetAppComponentDetails,
  IUseGetAppComponentDetailsOptions,
} from './useGetAppComponentDetails.interfaces';

const useGetAppComponentDetails = (options?: IUseGetAppComponentDetailsOptions): IUseGetAppComponentDetails => {
  const { fetchPolicy, onCompleted, onCompletedAsync, onError, query, token } = options ?? ({} as IUseGetAppComponentDetailsOptions);

  const [executeGetAppComponentDetails, { loading: isLoading }] = useEkardoLazyQuery<IGetAppComponentDetailsQueryGraphQLResponse, IGetAppComponentDetailsQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        const {
          app: {
            getAppComponentDetails: componentDetails
          }
        } = data;

        if (onCompleted) {
          void onCompleted({ componentDetails });
        }

        if (onCompletedAsync) {
          void onCompletedAsync({ componentDetails });
        }
      },
      onError,
    },
    query: query ?? GET_APP_COMPONENT_DETAILS,
    token,
  });

  const handleGetAppComponentDetails = ({ appComponentId }: IHandleGetAppComponentDetailsParams) => {
    void executeGetAppComponentDetails({
      variables: {
        inputType: {
          appComponentId,
        },
      },
    });
  };

  return {
    handleGetAppComponentDetails,
    isLoading,
  };
};

export { useGetAppComponentDetails };
