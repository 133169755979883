import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_APP_COMMENT_MUTATION } from './useCreateAppComment.graphql';
import {
  ICreateAppCommentMutationGraphQLResponse,
  ICreateAppCommentMutationVariables,
  IHandleCreateAppCommentParams,
  IUseCreateAppCommentOptions,
  IUseCreateAppComment,
} from './useCreateAppComment.interfaces';

const useCreateAppComment = (options?: IUseCreateAppCommentOptions): IUseCreateAppComment => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateAppCommentOptions);

  const [executeCreateAppComment, { loading: isLoading }] = useEkardoMutation<
  ICreateAppCommentMutationGraphQLResponse,
  ICreateAppCommentMutationVariables
  >({
    mutation: mutation ?? CREATE_APP_COMMENT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            rateApp: comment,
          },
        } = data;
        
        void onCompleted({ comment });
      },
      onError,
    },
    token,
  });

  const handleCreateAppComment = ({
    appIdentifier,
    rate,
    comment,
    title,
  }: IHandleCreateAppCommentParams) => {
    void executeCreateAppComment({
      variables: {
        inputType: {
          appIdentifier,
          rate,
          comment,
          title,
        },
      },
    });
  };

  return {
    handleCreateAppComment,
    isLoading,
  };
};

export { useCreateAppComment };
