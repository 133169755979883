
import { INotificationsTableData, INotificationsTableDataParams } from "./NotificationsReportPage.interfaces";


const getNotificationsTableData = ({ items, onSettingsButtonClick }: INotificationsTableDataParams): INotificationsTableData[] => {
  return items.map(({
    events = '',
    id,
    receiver = '',
    sendDate = '',
    sendDateUtc = '',
    sentOn = '',
    sentOnUtc = '',
    status = '',
    subject = '',
    templateName = '',
    type = '',
    userId,
   }) => {
    return {
      events,
      id,
      receiver,
      sendDate,
      sendDateUtc,
      sentOn,
      sentOnUtc,
      status,
      subject,
      templateName,
      type,
      userId,
      settingsButtonData: {
        item: {
          events,
          id,
          receiver,
          sendDate,
          sendDateUtc,
          sentOn,
          sentOnUtc,
          status,
          subject,
          templateName,
          type,
          userId,
        },
        onClick: onSettingsButtonClick,
      } 
    };
  });
};

export { getNotificationsTableData };
