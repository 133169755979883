import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { SidebarButtons, Spinner, GeneralTabIcon} from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { ReportTable, SidebarContainer } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetNotification, useGetNotificationsReport, useToast } from 'hooks';
import { INotificationReport } from 'interfaces';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { NOTIFICATIONS_COLUMNS } from './NotificationsReportPage.constants';
import { getNotificationsTableData } from './NotificationsReportPage.helpers';
import { NotificationsCsvRowData, INotificationsItem, INotificationsTableData } from './NotificationsReportPage.interfaces';



const NotificationsReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [notificationsItems, setNotificationsItems] = useState<INotificationsItem[]>();
  const [allNotificationsItems, setAllNotificationsItems] = useState<INotificationsItem[]>([]);
  const [notificationsTableData, setNotificationsTableData] = useState<INotificationsTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>();
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState<string>();
  const [notification, setNotification] = useState<INotificationReport>();


  const { handleGetNotification, isLoading: isGetNotificationLoading = false } = useGetNotification({ 
    onCompleted:  ({ notification: returnedNotification }) => {
      setNotification(returnedNotification);
    }
  });

  const {
    handleGetNotificationsReport,
    isLoading: isGetNotificationsLoading = false,
  } = useGetNotificationsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<NotificationsCsvRowData>(String(res.data), {
            header: true,
            skipEmptyLines: true,
          });

          const formattedItems = data.map((row: NotificationsCsvRowData) => ({
            id: String(row['Id']),
            userId: Number(row['User id']),
            receiver: String(row['Receiver']),
            subject: String(row['Subject']),
            templateName: String(row['Template name']),
            type: String(row['Type']),
            sendDate: String(row['Sending date']),
            sendDateUtc: String(row['Sending date (utc)']),
            sentOn: String(row['Sent on']),
            sentOnUtc: String(row['Sent on (utc)']),
            status: String(row['Status']),
            events: String(row['Events']),
          }));
          
          setTotalCount(data.length);
          setAllNotificationsItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setNotificationsItems(paginatedItems);
          setLastItemIndex(pageSize);

        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setSelectedNotificationId(undefined);
    setNotification(undefined);
  };


  const generateReport = () => {
    void handleGetNotificationsReport({
      dateFrom: dateRange && dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange && dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: 'CSV',
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setNotificationsItems(allNotificationsItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setNotificationsItems(allNotificationsItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!notificationsItems) {
      return;
    }

    setNotificationsTableData(
      getNotificationsTableData({
        items: notificationsItems,
        onSettingsButtonClick: (item) => {
          setIsSideBarOpen(true);
          setSelectedNotificationId(String(item.id));
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    if (!selectedNotificationId) return;

    handleGetNotification({
      notificationId: selectedNotificationId
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotificationId]);

  const isLoading = isGetNotificationsLoading;

  return (
    <ReportTable<INotificationsTableData>
      activePath={['reporting', 'reporting-notifications', 'export-notifications']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Notifications',
      }]}
      columns={NOTIFICATIONS_COLUMNS}
      csvUrl={csvUrl}
      data={notificationsTableData}
      description={`Notifications reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports notifications based on the selected filters'}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      isSideBarOpen={isSideBarOpen}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      tabs={[{
        icon: GeneralTabIcon,
        id: 'id_general_tab',
        label: 'General',
        view: () => (
          <>
            
              <SidebarContainer>
                <Spinner isLoading={isGetNotificationLoading} />
                {/* <Textarea
                  id="id_notification_report_message"
                  labelText="message"
                  name="notification_report_message"
                  value=""
                  isDisabled
                  onChange={() => undefined}
                /> */}
                {notification && (
                  <div dangerouslySetInnerHTML={{ __html: notification.content }} />
                )}
                <SidebarButtons 
                  onCancel={handleCloseSidebar}
                />
              </SidebarContainer>
           
          </>
        ),
      }]}
      title={`${String(projectName)} reporting`}
      toggleIsSideBarOpen={handleCloseSidebar}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { NotificationsReportPage };
