const displayStyleOptions = [
  {
    id: 0,
    value: 'DEFAULT',
    label: 'Default',
  },
  {
    id: 1,
    value: 'CARD',
    label: 'Cards',
  },
];

export { displayStyleOptions };