import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { ATTACH_CSS_STYLE_TO_PAGE } from './useAttachPageCssStyle.graphql';
import {
  IAttachPageCssStyleMutationGraphQLResponse,
  IAttachPageCssStyleMutationVariables,
  IHandleAttachPageCssStyleParams,
  IUseAttachPageCssStyleOptions,
  IUseAttachPageCssStyle,
} from './useAttachPageCssStyle.interfaces';

const useAttachPageCssStyle = (options?: IUseAttachPageCssStyleOptions): IUseAttachPageCssStyle => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseAttachPageCssStyleOptions);

  const [executeAttachPageCssStyle, { loading: isLoading }] = useEkardoMutation<IAttachPageCssStyleMutationGraphQLResponse, IAttachPageCssStyleMutationVariables>({
    mutation: mutation ?? ATTACH_CSS_STYLE_TO_PAGE,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          cssStyle: { attachStyleToContentPage: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleAttachPageCssStyle = async ({
    contentPageId,
    styleId,
  }: IHandleAttachPageCssStyleParams) => {
    await executeAttachPageCssStyle({
      variables: {
        contentPageId,
        styleId,
      }
    });
  };

  return {
    handleAttachPageCssStyle,
    isLoading,
  };
};

export { useAttachPageCssStyle };
