
import { GeneralTabIcon, SideBar, SidebarButtons, TabSet } from '@netfront/ui-library';
import { ProgressTrackingUserTab } from 'components';

import { ProgressTrackingUserDetailsSidebarViewProps } from './ProgressTrackingUserDetailsSidebarView.interfaces';

export const ProgressTrackingUserDetailsSidebarView = ({ onClose, user, isSidebarOpen = false }: ProgressTrackingUserDetailsSidebarViewProps) => {

  return (
    <SideBar isSideBarOpen={isSidebarOpen} isCloseButtonVisible onClose={onClose}>
      <TabSet
        defaultActiveTabId="id_general_tab"
        tabs={[
          {
            id: 'id_general_tab',
            label: 'General',
            view: () => user ? <ProgressTrackingUserTab user={user} />: <></>,
            icon: GeneralTabIcon,
          },
        ]}
      />
      <SidebarButtons
        buttonSize="xs"
        onCancel={onClose}
      />
    </SideBar>
  );
};
