import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_APP_COMPONENT } from './useDeleteAppComponent.graphql';
import {
  IDeleteAppComponentMutationGraphQLResponse,
  IDeleteAppComponentMutationVariables,
  IHandleDeleteAppComponentParams,
  IUseDeleteAppComponentOptions,
  IUseDeleteAppComponent,
} from './useDeleteAppComponent.interfaces';

const useDeleteAppComponent = (options?: IUseDeleteAppComponentOptions): IUseDeleteAppComponent => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteAppComponentOptions);

  const [executeDeleteAppComponent, { loading: isLoading }] = useEkardoMutation<
    IDeleteAppComponentMutationGraphQLResponse,
    IDeleteAppComponentMutationVariables
  >({
    mutation: mutation ?? DELETE_APP_COMPONENT,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            appVersionMutation: {
              appVersionComponentMutation: {
                deleteComponent: isCompleted
              }
            }
          }
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteAppComponent = ({ componentId }: IHandleDeleteAppComponentParams) => {
    void executeDeleteAppComponent({
      variables: {
        inputType: {
          componentId,
        },
      },
    });
  };

  return {
    handleDeleteAppComponent,
    isLoading,
  };
};

export { useDeleteAppComponent };
