import { gql } from '@apollo/client';


const GET_CSS_STYLE_OPTIONS = gql`
  query($projectId: String!) {
    cssStyle {
      getPerProject(projectId: $projectId) {
        id
        name
      }
    }
  }
`;

export { GET_CSS_STYLE_OPTIONS };
