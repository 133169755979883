/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IContentPage, IContentSection, IContentSectionContainer, IContentSnippet, ISectionGroup } from "@netfront/ekardo-content-library";
import { ContentBuilderNavigationItemProps, IOption , FolderIcon, SectionIcon } from "@netfront/ui-library";
import { IEntityType } from "interfaces";

import { SnippetTypeIcon } from "./ContentBuilderContext.constants";
import { FormatContentSectionsProps } from "./ContentBuilderContext.interfaces";

import { SnippetTypeConst, SnippetTypeFriendlyText } from "../../constants";


const formatContentSectionGroupsToNavigationItems = ({
  sectionGroups,
  formId,
  formSnippetId,
  isFormsMode = false,
  isInForm = false
}: FormatContentSectionsProps): ContentBuilderNavigationItemProps[] => {
  const formattedSectionGroups: ContentBuilderNavigationItemProps[] = [...sectionGroups].sort((a, b) => a.sort - b.sort).map((sectionGroup) => {
    const sections: ContentBuilderNavigationItemProps[] = [...sectionGroup.contentSections].sort((a, b) => a.sort - b.sort).map((contentSection) => {
      
      const sectionContainers: ContentBuilderNavigationItemProps[] = [...contentSection.sectionContainers].sort((a, b) => a.sort - b.sort).map((contentSectionContainer) => {
        const snippets : ContentBuilderNavigationItemProps[] = [...contentSectionContainer.snippets].sort((a, b) => a.sort - b.sort).map((contentSnippet) => {
          const { title, __typename: type, form } = contentSnippet;

          const { id } = form ?? {};

          return {
            icon: SnippetTypeIcon[type],
            label: title || `${String(SnippetTypeFriendlyText[contentSnippet.__typename])} Snippet`,
            // label: `Snippet - ${String(contentSnippet.id)} contentPageId - ${String(contentPageId)}`,
            id: String(contentSnippet.id),
            isDisabled: (isFormsMode && formId !== formSnippetId && formId !== id),
            type: 'targetSnippet',
            hasAddButton: (!isInForm && !isFormsMode) || (isFormsMode && (formId === formSnippetId || formId === id)),
            hasBulkAction: (!isInForm && !isFormsMode) || (isFormsMode && (formId === formSnippetId || formId === id)),
            subItems: type === SnippetTypeConst.FORM
              ? formatContentSectionGroupsToNavigationItems({
                  sectionGroups: form?.contentPages[0].sectionGroups ?? [],
                  isInForm: true,
                  isFormsMode: isFormsMode,
                  formId,
                  formSnippetId: id,
                })
              : undefined,
            conditionalSettingItems: {
              'id_edit_item': !isInForm || (isFormsMode && formId === formSnippetId),
              'id_add_new_item': false,
              'id_entity_actions': !isInForm || (isFormsMode && formId === formSnippetId),
              'id_duplicate_item': false,
              'id_enter_form_builder_item': !isInForm && type === SnippetTypeConst.FORM && !isFormsMode,
              'id_leave_form_builder_item': !isInForm && type === SnippetTypeConst.FORM && isFormsMode,
              'id_validation_item': false,
              'id_visibility_item': !isInForm && type === SnippetTypeConst.FORM && isFormsMode,
              'id_delete_item': !isInForm || (isFormsMode && formId === formSnippetId),
            },
          };
        });

        return {
          icon: FolderIcon,
          label: contentSectionContainer.title || 'Container',
          // label: `Container - ${String(contentSectionContainer.id)}`,
          id: String(contentSectionContainer.id),
          type: 'container',
          isDisabled: (isFormsMode && formId !== formSnippetId),
          subItems: snippets,
          hasAddButton: (!isInForm || (isFormsMode && formId === formSnippetId)) && contentSection.sectionContainers.length < 4,
          hasBulkAction: !isInForm,
          conditionalSettingItems: {
            'id_edit_item': !isInForm || (isFormsMode && formId === formSnippetId),
            'id_add_new_item': !isInForm || (isFormsMode && formId === formSnippetId),
            'id_entity_actions': !isInForm || (isFormsMode && formId === formSnippetId),
            'id_duplicate_item': false,
            'id_enter_form_builder_item': false,
            'id_leave_form_builder_item': false,
            'id_validation_item': false,
            'id_visibility_item': !isInForm || (isFormsMode && formId === formSnippetId),
            'id_delete_item': !isInForm || (isFormsMode && formId === formSnippetId),
          },
        };
      });

      return {
        icon: SectionIcon,
        label: contentSection.title || 'Section',
        // label: `Section - ${String(contentSection.id)}`,
        type: 'section',
        id: String(contentSection.id),
        isDisabled: (isFormsMode && formId !== formSnippetId),
        subItems: sectionContainers,
        hasAddButton: (!isInForm || (isFormsMode && formId === formSnippetId)),
        hasBulkAction: (!isInForm || (isFormsMode && formId === formSnippetId)),
        conditionalSettingItems: {
          'id_edit_item': !isInForm || (isFormsMode && formId === formSnippetId),
          'id_add_new_item': (!isInForm || (isFormsMode && formId === formSnippetId)) && contentSection.sectionContainers.length < 4,
          'id_entity_actions': !isInForm || (isFormsMode && formId === formSnippetId),
          'id_duplicate_item': false,
          'id_enter_form_builder_item': false,
          'id_leave_form_builder_item': false,
          'id_validation_item': false,
          'id_visibility_item': !isInForm || (isFormsMode && formId === formSnippetId),
          'id_delete_item': !isInForm || (isFormsMode && formId === formSnippetId),
        }

      };
    });

    return {
      icon: SectionIcon,
      label: 'Section group',
      // label: `Section - ${String(contentSection.id)}`,
      type: 'sectionGroup',
      id: String(sectionGroup.id),
      isDisabled: (isFormsMode && formId !== formSnippetId),
      subItems: sections,
      // hasAddButton: (!isInForm || (isFormsMode && formId === formSnippetId)),
      // conditionalSettingItems: {
      //   'id_edit_item': !isInForm || (isFormsMode && formId === formSnippetId),
      //   'id_add_new_item': (!isInForm || (isFormsMode && formId === formSnippetId)),
      //   'id_entity_actions': !isInForm || (isFormsMode && formId === formSnippetId),
      //   'id_duplicate_item': false,
      //   'id_enter_form_builder_item': false,
      //   'id_leave_form_builder_item': false,
      //   'id_validation_item': false,
      //   'id_visibility_item': !isInForm || (isFormsMode && formId === formSnippetId),
      //   'id_delete_item': !isInForm || (isFormsMode && formId === formSnippetId),
      // }

      hasAddButton: false,
      hasBulkAction: false,
      conditionalSettingItems: {
        'id_edit_item': false,
        'id_add_new_item': (!isInForm || (isFormsMode && formId === formSnippetId)),
        'id_entity_actions': false,
        'id_duplicate_item': false,
        'id_enter_form_builder_item': false,
        'id_leave_form_builder_item': false,
        'id_validation_item': false,
        'id_visibility_item': false,
        'id_delete_item': false,
      }
    };
  });

  return formattedSectionGroups;
};

const findParentIdByIdAndType = ({sectionGroups, id, type, pageId }: { id: number; pageId: number; sectionGroups: ISectionGroup[]; type: IEntityType; } ): number | null | undefined => {

  if (type === 'targetSnippet') {
    for (const sectionGroup of sectionGroups) {
      for (const section of sectionGroup.contentSections) {
        for (const container of section.sectionContainers) {
          for (const snippet of container.snippets) {
            if (snippet.id === id) {
              // Return the id of the sectionContainer
              return container.id;
            }
          }
        }
      }
    }

  } else if (type === 'container') {
    for (const sectionGroup of sectionGroups) {
      for (const section of sectionGroup.contentSections) {
        const returnedContainer = section.sectionContainers.find(container => container.id === id);
        if (returnedContainer) {
          // Return the sectionId of the sectionContainer
          return returnedContainer.sectionId;
        }
      }
    }
  } else if (type === 'section') {

    for (const sectionGroup of sectionGroups) {
      const returnSection = sectionGroup.contentSections.find(section => section.id === id);
      if (returnSection) {
        // Return the sectionId of the sectionContainer
        return sectionGroup.id;
      }
    }


  } else if (type === 'sectionGroup') {
    return pageId;
  }

  return null;
};

const getContainerById = (sectionGroups: ISectionGroup[], containerId: number): IContentSectionContainer | undefined => {
  for (const sectionGroup of sectionGroups) {
    for (const section of sectionGroup.contentSections) {
      const returnedContainer = section.sectionContainers.find(container => container.id === containerId);
      if (returnedContainer) {
        return returnedContainer;
      }
    }
  };

  return undefined;
};


const getSnippetById = (sectionGroups: ISectionGroup[], snippetId: number): IContentSnippet | undefined => {
  for (const sectionGroup of sectionGroups) {
    for (const section of sectionGroup.contentSections) {
      for (const container of section.sectionContainers) {
        const returnedSnippet = container.snippets.find(snippet => snippet.id === snippetId);
        if (returnedSnippet) {
          return returnedSnippet;
        }
      }
    }
  };

  return undefined;
};

const getSectionById = (sectionGroups: ISectionGroup[], sectionId: number): IContentSection | undefined => {
  for (const sectionGroup of sectionGroups) {
    const returnedSection = sectionGroup.contentSections.find(section => section.id === sectionId);
      if (returnedSection) {
        return returnedSection;
      }
  }

  return undefined;

}

const getSectionGroupById = (sectionGroups: ISectionGroup[], sectionGroupId: number): ISectionGroup | undefined => sectionGroups.find((sectionGroup) => sectionGroup.id === sectionGroupId);

const getUpdatedLayoutId = (currentContentLayoutId: number, type = 'add'): number => {
  if (currentContentLayoutId === 10) {
    if (type === 'add') return 10;
    return 7;
  }

  if ([7, 8, 9].includes(currentContentLayoutId)) {
    if (type === 'add') return 10;
    return 2;
  }

  if ([2, 3, 4, 5, 6].includes(currentContentLayoutId)) {
    if (type === 'add') return 7;
    return 1;
  }

  if (currentContentLayoutId === 1) {
    if (type === 'add') return 2;
    return 1;
  }

  return 1;
}

const formatContentPages = (contentPages: IContentPage[], depth = 0): IOption[] => {
  let formattedPages: IOption[] = [];

  for (const page of contentPages) {
    const { url, title, contentPageChildren = [] } = page;

    const nameWithIndent = `${'\u00A0\u00A0\u00A0'.repeat(depth)}${title}`;

    // Add the formatted page to the array
    formattedPages.push({
      id: url,
      value: url,
      name: nameWithIndent,
    });

    // If there are child pages, recursively format them and add them to formattedPages
    if (contentPageChildren.length > 0) {
      const childPages = formatContentPages(contentPageChildren, depth + 1);
      formattedPages = [...formattedPages, ...childPages];
    }
  }

  return formattedPages;
};


const getContentPageByUrl = ({ contentPages, url }: { contentPages: IContentPage[]; url?: string }): IContentPage | undefined => {
  for (const contentPage of contentPages) {
    const { contentPageChildren = [], url: contentPageUrl } = contentPage;

    if (url === contentPageUrl) {
      return contentPage;
    }

    const result = getContentPageByUrl({ contentPages: contentPageChildren, url });
    if (result) {
      return result;
    }
  }
  return undefined;
};



export { getContentPageByUrl, formatContentPages, formatContentSectionGroupsToNavigationItems, findParentIdByIdAndType, getContainerById, getSectionById, getSnippetById, getSectionGroupById, getUpdatedLayoutId }
