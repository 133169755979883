import { gql } from '@apollo/client';

const GET_APP_DETAILS_QUERY = gql`
  query GET_APP_DETAILS($appId: Int!) {
    app {
      getAppDetails(inputType: { appId: $appId }) {
        description
        id
        identifier
        title
        averageRateValue
        rates {
          comment
          # user {
          #   firstname
          #   lastname
          # }
          id
          rate
          title
        }
        versions {
          id
          version
          appId
          uploadedDateTime
          status
          publishStatus
          usageCount
          components {
            id
            files {
              fileType
              id
              locationId
              appComponentId
            }
            title
          }
        }

        users {
          userId
          status
        }
      }
    }
  }
`;

export { GET_APP_DETAILS_QUERY };
