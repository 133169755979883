/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { ITranscriptAnswer } from "components";
import { TranscriptShapeType, ITranscript } from "interfaces";
import { generateRandomId } from "utils";


const MULTI_RESPONSE_DELIMITER = '_::?::_';
const CORRECT_RESPONSE_DELIMITER = '_::C::_';
const PARTS_DELIMITER = '_::N::_';
const QUESTION_DELIMITER = '_::_';
const RESPONSE_OPTIONS_DELIMITER = '_::Q::_';
const INFORMATION_DELIMITER = '_::I::_';


const getTranscriptQuizDefaultValues = ({ shape, text }: { shape?: TranscriptShapeType;  text: string }): FormFieldProps => {
  if (shape !== 'QUIZ' || !text) return {}; 

  const [questionResponse, correctAnswer, correctResponseText, incorrectResponseText, answerStyleConfig] = text.split(PARTS_DELIMITER);

  const responseOptions = questionResponse.split(RESPONSE_OPTIONS_DELIMITER);

  const [questionConfig] = responseOptions;

  const [questionNumber, questionText] = questionConfig.split(QUESTION_DELIMITER);
  
  const formattedQuestionNumber = questionNumber.replace(INFORMATION_DELIMITER, '').replace(MULTI_RESPONSE_DELIMITER, '');

  const isInformation = questionConfig.includes(INFORMATION_DELIMITER);

  const isMultiResponse = questionConfig.includes(MULTI_RESPONSE_DELIMITER);

  const answerStyle = answerStyleConfig ? JSON.parse(answerStyleConfig.replace(/'/g, '"')) : undefined;

  const { x, y, w } = answerStyle ?? {};

  const answers = responseOptions.slice(1).map((option) => {
    const isForceCorrect = option.includes(CORRECT_RESPONSE_DELIMITER);
    const textWithoutSymbol = option.replace(CORRECT_RESPONSE_DELIMITER, '');
    return {
      tempId: generateRandomId(),
      isForceCorrect,
      text: textWithoutSymbol,
    };
  });

  const selectedAnswer = answers[parseInt(correctAnswer, 10) - 1]?.tempId;

  return {
    text: questionText,
    answers,
    isInformation,
    isMultiResponse,
    correctAnswer: selectedAnswer,
    questionNumber: parseInt(formattedQuestionNumber, 10),
    correctResponseText,
    incorrectResponseText,
    answersWidth: w,
    answersX: x,
    answersY: y,
    isCreateNew: false,
  };
};


const getTranscriptDefaultValues = (transcript?: ITranscript): FormFieldProps => {
  const {
    text,
    w: width,
    x: xAxis,
    y: yAxis,
    angle,
    shape,
    tailType,
    characterId,
    hasShadow = true,
  } = transcript ?? {};

  const shapeMap: { [key: string]: string} = {
    'STOP_AND_THINK_NO_INPUT': 'STOP_AND_THINK',
    'CHAT_TO': 'CHAT',
    'CHAT_FROM': 'CHAT',
  };

  const chatTailTypeMap:  { [key: string]: string} = {
    'CHAT_TO': 'sent',
    'CHAT_FROM': 'received',
  };

  return {
    text,
    width,
    xAxis,
    yAxis,
    angle,
    shape: shape && ['STOP_AND_THINK_NO_INPUT', 'CHAT_TO', 'CHAT_FROM'].includes(shape) ? shapeMap[String(shape)]: shape,
    hasInput: shape === 'STOP_AND_THINK',
    chatTailType: shape && ['CHAT_TO', 'CHAT_FROM'].includes(shape) ? chatTailTypeMap[String(shape)]: 'sent',
    tailType,
    characterId,
    hasShadow: hasShadow || true,
    ...getTranscriptQuizDefaultValues({text: text || '', shape})
  };
};

const formatQuizConfiguration = ({ item }: { item: FormFieldProps }): FormFieldProps => {
  const { 
    text,
    shape,
    isInformation = false,
    isMultiResponse = false,
    answers = [],
    questionNumber,
    correctAnswer,
    correctResponseText,
    incorrectResponseText,
    answersWidth,
    answersX,
    answersY,
  } = item;
  if (shape !== 'QUIZ') return {}; 

  let formattedConfiguration = `${isInformation ? INFORMATION_DELIMITER : ''}${isMultiResponse ? MULTI_RESPONSE_DELIMITER : ''}${questionNumber ? `${String(questionNumber)}`: ''}${QUESTION_DELIMITER}${String(text)}`;

  (answers as ITranscriptAnswer[]).forEach(({ text: answerText, isForceCorrect = false}) => {
    formattedConfiguration += `${RESPONSE_OPTIONS_DELIMITER}${isMultiResponse && isForceCorrect ? CORRECT_RESPONSE_DELIMITER: ''}${String(answerText)}`
  });

  const correctAnswerIndex = (answers as ITranscriptAnswer[]).findIndex(({ tempId }) => tempId === correctAnswer) || 0;

  formattedConfiguration += `${PARTS_DELIMITER}${String(correctAnswerIndex + 1)}`

  formattedConfiguration += `${PARTS_DELIMITER}${String(correctResponseText)}`
  
  formattedConfiguration += `${PARTS_DELIMITER}${String(incorrectResponseText)}`

  if (answersWidth && answersX && answersY) {
    formattedConfiguration += `${PARTS_DELIMITER}{'x': ${String(answersX)}, 'y': ${String(answersY)}, 'w': ${String(answersWidth)}}`;
  }

  return {
    text: formattedConfiguration,
  };
};



const getTranscriptVariables = (item: FormFieldProps): FormFieldProps => {
  const {
    text,
    shape,
    angle,
    width: w,
    xAxis: x,
    yAxis: y,
    tailType,
    characterId,
    hasShadow,
    hasInput,
    chatTailType,
  } = item;
  
  let formattedShape = shape;

  if (shape === 'CHAT') {
    if (chatTailType === 'sent') {
      formattedShape = 'CHAT_TO';
    } else {
      formattedShape = 'CHAT_FROM'
    }
  }

  if (shape === 'STOP_AND_THINK' && !hasInput) {
    formattedShape = 'STOP_AND_THINK_NO_INPUT';
  }

  return {
    text,
    shape: formattedShape,
    angle: angle ?? 0,
    w,
    x,
    y,
    tailType: tailType ?? 'NONE',
    characterId: characterId ? Number(characterId) : undefined,
    hasShadow,
    ...formatQuizConfiguration({ item }),
  }
};


const setQuickAddTranscriptValues = (values: FormFieldProps): FormFieldProps => {
  return {
    ...values,
    text: '',
  };
};


export { getTranscriptDefaultValues, getTranscriptVariables, setQuickAddTranscriptValues };
