
const CONTENT_BUILDER_BULK_ACTION_DIALOG_TITLE_MAP = Object.freeze({
  delete: 'Bulk delete entities',
  updateVisibility: 'Bulk toggle visibility',
  updateValidation: 'Bulk toggle validation',
});

const CONTENT_BUILDER_BULK_ACTION_DIALOG_INDIVIDUAL_ENTITY_TITLE_MAP = Object.freeze({
  delete: 'Delete entity',
  updateVisibility: 'Toggle visibility',
  updateValidation: 'Toggle validation',
});

const CONTENT_BUILDER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP = Object.freeze({
  delete: 'Delete',
  updateVisibility: 'Save',
  updateValidation: 'Save',
});

export { CONTENT_BUILDER_BULK_ACTION_DIALOG_TITLE_MAP, CONTENT_BUILDER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP, CONTENT_BUILDER_BULK_ACTION_DIALOG_INDIVIDUAL_ENTITY_TITLE_MAP };
