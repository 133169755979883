/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

import { FormFieldProps } from "@netfront/ui-library";
import { IApp } from "interfaces";


const getAppDefaultValues = (app?: IApp): FormFieldProps => {
  const { 
    logo,
    title,
    description,
    logoId,
  } = app ?? {};

  const { presignedUrl } = logo ?? {};

  return {
    description: description || '',
    title: title || '',
    logoId: logoId || '',
    presignedUrl: presignedUrl || '',
  };

};

export { getAppDefaultValues };