import { gql } from '@apollo/client';

const UPDATE_APP_COMMENT_MUTATION = gql`
  mutation updateAppComment(
    $inputType: UpdateRateAppInputType!,
    $rateId: Int!
  ) {
    app {
      updateRate(
        inputType: $inputType,
        rateId: $rateId
      )
    }
  }
`;

export { UPDATE_APP_COMMENT_MUTATION };
