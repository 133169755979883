import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_APP_MUTATION } from './useUpdateApp.graphql';
import {
  IUpdateAppMutationGraphQLResponse,
  IUpdateAppMutationVariables,
  IHandleUpdateAppParams,
  IUseUpdateAppOptions,
  IUseUpdateApp,
} from './useUpdateApp.interfaces';

const useUpdateApp = (options?: IUseUpdateAppOptions): IUseUpdateApp => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateAppOptions);

  const [executeUpdateApp, { loading: isLoading }] = useEkardoMutation<
  IUpdateAppMutationGraphQLResponse,
  IUpdateAppMutationVariables
  >({
    mutation: mutation ?? UPDATE_APP_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            updateAppDetails: app,
          },
        } = data;

        onCompleted({ app });
      },
      onError,
    },
    token,
  });

  const handleUpdateApp = ({ inputType }: IHandleUpdateAppParams) => {
    void executeUpdateApp({
      variables: {
        inputType
      },
    });
  };

  return {
    handleUpdateApp,
    isLoading,
  };
};

export { useUpdateApp };
