import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_ALL_PENDING_APP_VERSIONS } from './useGetPendingAppApprovals.graphql';
import { IGetPendingAppApprovalsQueryGraphQLResponse, IUseGetPendingAppApprovals, IUseGetPendingAppApprovalsOptions } from './useGetPendingAppApprovals.interfaces';

const useGetPendingAppApprovals = (options?: IUseGetPendingAppApprovalsOptions): IUseGetPendingAppApprovals => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetPendingAppApprovalsOptions);
  const [executeGetPendingAppApprovals, { loading: isLoading }] = useEkardoLazyQuery<
  IGetPendingAppApprovalsQueryGraphQLResponse
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            getPendingAppVersions: apps,
          },
        } = data;

        onCompleted({
          apps,
        });
      },
      onError,
    },
    query: query ?? GET_ALL_PENDING_APP_VERSIONS,
    token,
  });

  const handleGetPendingAppApprovals = () => {
    void executeGetPendingAppApprovals();
  };

  return {
    handleGetPendingAppApprovals,
    isLoading,
  };
};

export { useGetPendingAppApprovals };
