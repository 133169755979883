import { Button, ContentIcon, CsvIcon, DateRangePicker, FlexContainer, ITableRequiredParameters } from '@netfront/ui-library';
import { TablePageTemplate } from 'components';

import { ReportSelector } from './Components';
import { ReportTableProps } from './ReportTable.interfaces';


const ReportTable = <T extends ITableRequiredParameters>({
  activePath,
  additionalFilters,
  additionalBreadcrumbItems,
  children,
  columns,
  csvUrl,
  data = [],
  dateRange,
  description,
  handleOnPageSizeChange,
  handleOnPaginate,
  isGenerateButtonDisabled = false,
  isLoading = false,
  isLoadMoreDisabled = false,
  informationBoxMessage,
  logoUrl,
  onGenerate,
  onDateSelect,
  pageSize,
  pageTitle,
  isSideBarOpen = false,
  toggleIsSideBarOpen,
  tabs = [],
  title,
  totalCount,
}: ReportTableProps<T>) => {

  return (
    <TablePageTemplate<T>
      activePath={activePath}
      additionalBreadcrumbItems={[{
          key: '1',
          content: 'Reporting',
        }, 
        ...additionalBreadcrumbItems,
      ]}
      childrenEnd={
        onGenerate ?
        <FlexContainer gap="2x-small">
          <Button
            icon={ContentIcon}
            isDisabled={isGenerateButtonDisabled}
            size="xs"
            text="Display"
            onClick={onGenerate}
          />
          {csvUrl && (
            <Button
              additionalClassNames="c-report-download-button"
              icon={CsvIcon}
              linkButton={{
                linkComponent: 'a',
                id: `id_csv_download`,
                url: csvUrl,
                target: '_blank'
              }}
              size="small"
              text="Download"
              isIconOnly
            />
          )}
        </FlexContainer>
      : undefined }
      childrenMiddle={
        onDateSelect ? (
        <FlexContainer gap="medium">
          <div className="c-report-datepicker">
            <DateRangePicker
              dateInputProps={{
                labelText: 'Select date range',
                id: 'id_date_selector',
                name: 'date_range_selector',
                type: 'text',
                isLabelHidden: true,
                hasPadding: false,
              }}
              selectedDates={dateRange}
              onSelected={(dates: Date[]) => {
                onDateSelect(dates);
              }}
            />
          </div>
          {additionalFilters}
        </FlexContainer>
      ): undefined}
      columns={columns}
      data={data}
      description={description}
      handleOnPageSizeChange={handleOnPageSizeChange}
      handleOnPaginate={handleOnPaginate}
      informationBoxMessage={informationBoxMessage}
      isLoading={isLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      isSideBarOpen={isSideBarOpen}
      logoUrl={logoUrl}
      pageSize={pageSize}
      pageTitle={pageTitle}
      searchPlaceholder="Search"
      size={logoUrl ? 'small': 'medium'}
      tableType="data"
      tabs={tabs}
      title={title}
      toggleIsSideBarOpen={toggleIsSideBarOpen}
      topLevelChildren={<ReportSelector activePath={activePath}/>}
      totalItems={totalCount}
    >
      {children}
    </TablePageTemplate>
  );
};

export { ReportTable };
