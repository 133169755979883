import { useContext, useEffect, useState } from 'react';

import { EmptyState, IBreadcrumbItem, SearchFilter, Spacing, Toolbar } from '@netfront/ui-library';
import { BasicPageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import Link from 'next/link';

import { getFilteredReportOptions, getFormattedReportOptions, getReportTypeName } from '../Reporting.helpers';
import { IReportOption } from '../Reporting.interfaces';

import { ReportOptionAccordion, ReportOptionSelector } from './Components';



const ReportingDashboardPage = ({ reportType }: { reportType?: string }) => {
  const { project } = useContext(CachingEntitiesContext);

  const [projectName, setProjectName] = useState<string>(project?.name ?? '');
  const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbItem[]>([]);
  const [allReportOptions, setAllReportOptions] = useState<IReportOption[]>([]);
  const [filteredReportOptions, setFilteredReportOptions] = useState<IReportOption[]>([]);
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  

  const onChange = ({ target: { value } }: {target: { value: string }}) => {
    setSearchFilter(value);
  }

  const handleClearSearch = () => {
    setSearchFilter('');
    setFilteredReportOptions(getFilteredReportOptions({ reportOptions: allReportOptions, value: '' }));
  }

  const handleSearch = () => {
    setFilteredReportOptions(getFilteredReportOptions({ reportOptions: allReportOptions, value: searchFilter }));
  };

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name, organisationId, id, hasMobileApplication, services } = project;

    const url = `/dashboard/${String(organisationId)}/${String(id)}`;
    setBaseUrl(url);
    setProjectName(name);
    
    const defaultBreadcrumbs = [
      {
        key: '0',
        content: <Link href={url}><span>Dashboard</span></Link>,
      },
      {
        key: '1',
        content: <Link href={`${url}/reporting`}><span>Reporting</span></Link>,
      },
    ];

    if (reportType) {
      defaultBreadcrumbs.push({
        key: '1',
        content: <span>{getReportTypeName(reportType)}</span>,
      });
    }

    setBreadcrumbItems(defaultBreadcrumbs);
    const options = getFormattedReportOptions({ services, hasMobileApplication, projectId: id });
    setAllReportOptions(options);
    setFilteredReportOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name, project?.organisationId, project?.id, reportType]);

  const options = filteredReportOptions.filter(({ id }) => !reportType || reportType === id);

  const isEmpty = options.length === 0;

  return (
    <BasicPageTemplate
      activePage="reporting"
      activeSubPage={reportType}
      breadcrumbItems={breadcrumbItems}
      description={`Reporting dashboard for ${projectName || 'the project'}`}
      informationBoxMessage={`View reports based on type`}
      logoUrl={project?.logo?.presignedUrl}
      pageTitle={`${projectName ? projectName + ' reporting' : 'Reporting'} dashboard`}
      projectName={projectName}
    >
      <Spacing>
        <Toolbar
          childrenStart={
            <SearchFilter
              id="id_search_filter"
              labelText="search"
              name="new_search"
              placeholder="Search"
              type="text"
              value={searchFilter}
              isLabelHidden
              onChange={onChange}
              onClear={handleClearSearch}
              onSearch={handleSearch}
            />
          }
        />
      </Spacing>
      <Spacing>
        <Toolbar
          childrenStart={
            <ReportOptionSelector baseUrl={baseUrl} reportOptions={allReportOptions} selectedOption={reportType} />
          }
        />
      </Spacing>

      <div className="c-report-dashboard-container">
        {!isEmpty ? (
          <>
            {options.map(({ id, icon, name, subItems = [] }) => (
              <ReportOptionAccordion key={id} icon={icon} id={id} label={name} >
                <ul>
                  {subItems.map(({ id: route, name: subItemName}) => <li key={route} className="c-report-dashboard__item"><Link  href={`${baseUrl}/reporting/${id}/${route}`}><span>{subItemName}</span></Link></li>)}
                </ul>
              </ReportOptionAccordion>
            ))}
              </>
        ): (
          <EmptyState
            imageUrl="/images/content-landing.svg"
            size="small"
            text="There are no reports that match that criteria"
            title="No reports!"
          />
        )}
        
      </div>
    </BasicPageTemplate>
  )
};

export { ReportingDashboardPage };
