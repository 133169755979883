import { useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentGroup, IDBGroupingContentGroup } from '@netfront/ekardo-content-library';
import { Dialog, GeneralTabIcon, IOption, RelatedTabIcon, SideBarTabSet, Spinner } from '@netfront/ui-library';
import { ContentGroupAdvancedTab, ContentGroupGeneralTab, ContentGroupTagsTab } from 'components';
import { useCreateAsset, useCreateContentGroup, useDeleteContentGroup, useGetContentGroupings, useToast, useUpdateContentGroup, useUpdateContentGroupStatus } from 'hooks';
import { pushImageToAws } from 'utils';


import { ContentGroupListSidebarViewProps } from './ContentGroupListSidebarView.interfaces';


const ContentGroupListSidebarView = ({
  selectedContentGroup,
  contentGroupType,
  handleSideBarClose,
  isSideBarOpen = false,
  onUpdate,
  projectId,
}: ContentGroupListSidebarViewProps) => {
  const { handleToastError, handleToastSuccess } = useToast();
  const currentContentGroupRef = useRef<{value: IContentGroup}>({ value: {} as IContentGroup });
  const droppedFileRef = useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedFileRef = useRef<{value: boolean}>({ value: false });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isAssetUploadToAwsLoading, setIsAssetUploadToAwsLoading] = useState<boolean>(false);
  const [contentGroupings, setContentGroupings] = useState<IOption[]>([]);


  const { handleCreateAsset, isLoading: isCreateAssetLoading = false } = useCreateAsset({
    onCompletedAsync: async (data) => {

      const { assetId: iconAssetId, signedUrl } = data;

      setIsAssetUploadToAwsLoading(true);

      await pushImageToAws(signedUrl, droppedFileRef.current.value, () => {
        const {
          id,
          title,
          description,
          url,
          contentType,
          estimatedTime,
          releaseDate,
          tags = [],
          sort,
        } = currentContentGroupRef.current.value;


        setIsAssetUploadToAwsLoading(false);
        handleUpdateContentGroup({
          contentGroup: {
            contentGroupId: id,
            contentType,
            description,
            estimatedTime,
            iconAssetId: String(iconAssetId),
            title,
            tagIds: ['RESOURCE', 'NEWS'].includes(contentGroupType) ? tags.map(({ id: tagId }: { id: number; }) => tagId) : undefined,
            releaseDate: releaseDate ? new Date(releaseDate) : undefined,
            sort,
            url,
          }
        });

      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });



  const { handleGetContentGroupings, isLoading: isGetContentGroupingsLoading = false} = useGetContentGroupings({
    fetchPolicy: 'cache-first',
    onCompleted: ({ contentGroupings: returnedContentGroupings }) => {
      setContentGroupings(returnedContentGroupings.map(({id, name}) => ({
        id,
        name,
        value: id,
      })))
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });



  const { handleDeleteContentGroup, isLoading: isDeleteContentGroupLoading = false } = useDeleteContentGroup({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);


      handleToastSuccess({ message: 'Content group successfully deleted'});

      onUpdate({updatedModule: selectedContentGroup});
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleCreateContentGroup, isLoading: isCreateContentGroupLoading = false } = useCreateContentGroup({
    onCompleted: ({ contentGroup }) => {
      handleToastSuccess({ message: 'Content group successfully created'});

      onUpdate({updatedModule: contentGroup});
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateContentGroup, isLoading: isUpdateContentGroupLoading = false } = useUpdateContentGroup({
    onCompleted: () => {
      const { status, url } = selectedContentGroup ?? {};
      const { status: updatedStatus, url: updatedUrl } = currentContentGroupRef.current.value;

      if (status !== updatedStatus) {

        handleUpdateContentGroupStatus({
          contentGroupId: Number(selectedContentGroup?.id),
          status: updatedStatus,
        })

      } else {
        handleToastSuccess({ message: 'Content group successfully updated'});
        onUpdate({
          updatedUrl: url !== updatedUrl ? updatedUrl : undefined
        });
      }

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleUpdateContentGroupStatus, isLoading: isUpdateContentGroupStatusLoading = false } = useUpdateContentGroupStatus({
    onCompleted: ({ contentGroup: returnedContentGroup }) => {
      handleToastSuccess({ message: 'Content group successfully updated'});
      onUpdate({ updatedUrl: returnedContentGroup.url });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleUpdateInput = ({name, value}: {name: string; value?: string }) => {
    currentContentGroupRef.current.value = {
      ...currentContentGroupRef.current.value,
      [name]: value,
    } as IContentGroup;
  };

  const handleUpdateGrouping = (selectedGrouping: IDBGroupingContentGroup) => {
    currentContentGroupRef.current.value = {
      ...currentContentGroupRef.current.value,
      groupingContentGroup: selectedGrouping
    } as IContentGroup;
  };

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileRef.current.value = uploadedFile;
  }

  const handleDeleteFile = () => {
    hasDeletedFileRef.current.value = true;
  }

  const handleUpdateSelectedTags = (tags: IContentGroup['tags'] = []) => {
    currentContentGroupRef.current.value = {
      ...currentContentGroupRef.current.value,
      tags,
    } as IContentGroup;
  }



  const handleSaveContentGroup = () => {
    const {
      id,
      title,
      description,
      url,
      contentType,
      iconAssetId,
      estimatedTime,
      releaseDate,
      tags = [],
      sort,
    } = currentContentGroupRef.current.value;

    const {
      name = '',
      type = '',
      size = 0,

    } = droppedFileRef.current.value ?? {};

    if (!selectedContentGroup) {
      if (!title) return;
      handleCreateContentGroup({
        contentGroup: {
          projectId,
          title,
          description,
          type: contentGroupType,
          url,
          contentType,
        }
      });
    } else {
      if (droppedFileRef.current.value) {
        // user has uploaded a thumbnail image - we need to create the asset before updating so that we get the new asset id
        handleCreateAsset({
          alt: name,
          contentType: type,
          description,
          fileName: name,
          fileSizeInBytes: size,
          imageSize: 'SMALL',
          title: name,
          projectId,
          tagList: [],
          type: 'IMAGE'
        });
      } else {
        handleUpdateContentGroup({
          contentGroup: {
            contentGroupId: id,
            contentType,
            description,
            estimatedTime,
            iconAssetId: hasDeletedFileRef.current.value ? undefined : iconAssetId,
            title,
            tagIds: ['RESOURCE', 'NEWS'].includes(contentGroupType) ? tags.map(({ id: tagId }: { id: number; }) => tagId) : undefined,
            releaseDate: releaseDate ? new Date(releaseDate) : undefined,
            sort,
            url,
          }
        });
      }

    }
  };

  useEffect(() => {

    currentContentGroupRef.current.value = selectedContentGroup ?? {} as IContentGroup;

    droppedFileRef.current.value = undefined;
    hasDeletedFileRef.current.value = false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContentGroup, isSideBarOpen]);

  useEffect(() => {
    if (!selectedContentGroup) return;

    handleGetContentGroupings({
      projectId,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContentGroup]);

  const isLoading =  isCreateContentGroupLoading || isGetContentGroupingsLoading || isCreateAssetLoading || isAssetUploadToAwsLoading || isUpdateContentGroupLoading || isDeleteContentGroupLoading || isUpdateContentGroupStatusLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete ${contentGroupType}: ${String(selectedContentGroup?.title)}?`}
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedContentGroup?.id) {
            return;
          }

          handleDeleteContentGroup({
            contentGroupId: selectedContentGroup.id,
          });
        }}
      />

      <SideBarTabSet
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={handleSideBarClose}
        hasViewPadding={false}
        hideSideBarButtons={true}
        isSideBarOpen={isSideBarOpen}
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () =>
              <ContentGroupGeneralTab
                contentGroup={selectedContentGroup}
                contentGroupings={contentGroupings}
                isLoading={isLoading}
                onCancel={handleSideBarClose}
                onDelete={selectedContentGroup ? () => setIsDeleteDialogOpen(true) : undefined}
                onSave={handleSaveContentGroup}
                onUpdateGrouping={handleUpdateGrouping}
                onUpdateInput={handleUpdateInput}
              /> ,
          },
          {
            icon: RelatedTabIcon,
            id: 'id_tag_tab',
            label: 'Tags',
            isHidden: selectedContentGroup ? !['RESOURCE', 'NEWS'].includes(contentGroupType): true,
            view: () =>
              <ContentGroupTagsTab
                initialTagList={selectedContentGroup?.tags ?? []}
                projectId={projectId}
                onCancel={handleSideBarClose}
                onDelete={selectedContentGroup ? () => setIsDeleteDialogOpen(true) : undefined}
                onSave={handleSaveContentGroup}
                onUpdateSelectedTags={handleUpdateSelectedTags}
              />,
          },
          {
            icon: RelatedTabIcon,
            id: 'id_advanced_tab',
            label: 'Advanced',
            isHidden: selectedContentGroup ? !['LEARNING', 'CARTOON', 'QUESTIONNAIRE'].includes(contentGroupType): true,
            view: () =>
              <ContentGroupAdvancedTab
                contentGroup={selectedContentGroup}
                isLoading={isLoading}
                onCancel={handleSideBarClose}
                onDelete={selectedContentGroup ? () => setIsDeleteDialogOpen(true) : undefined}
                onDeleteAsset={handleDeleteFile}
                onDrop={handleDropFile}
                onSave={handleSaveContentGroup}
                onUpdateInput={handleUpdateInput}
              />,
          },

        ]}

      />
    </>
  );
};

export { ContentGroupListSidebarView };
