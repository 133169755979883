import { useContext, useEffect, useState } from 'react';

import { BinIcon, Button, CardList, CoverLink, CustomRating, Dialog, EmptyState, FlexContainer, NavigationCard, PlusIcon, SettingsButton, SettingsIcon, Spacing, Spinner, Toolbar } from '@netfront/ui-library';
import { AppComponentSidebarView, AppTemplatePage } from 'components';
import { AppDetailsContext, DashboardContext } from 'context';
import { useDeleteAppComponent, useGetAppVersionDetails, useToast } from 'hooks';
import { IDBAppComponent, IDBAppVersion } from 'interfaces';
import Link from 'next/link';
import { useRouter } from 'next/router';


const AppComponentsDashboardPage = () => {

  const { appPageBaseUrl, appDetails, isLoading: isAppLoading = false, onUpdateApp } = useContext(AppDetailsContext);
  const { query } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();
  const { versionId: queryVersionId } = query;
  const { dashboardLink } = useContext(DashboardContext);
  const [versionId, setVersionId] = useState<number>();
  const [componentUrl, setComponentUrl] = useState<string>('');
  const [isUpsertComponentSidebarOpen, setIsUpsertComponentSidebarOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [selectedComponent, setSelectedComponent] = useState<IDBAppComponent>();
  const [allComponents, setAllComponents] = useState<IDBAppComponent[]>([]);
  const [currentAppVersion, setCurrentAppVersion] = useState<IDBAppVersion>();
  const [deleteComponentId, setDeleteComponentId] = useState<number>();

  const { title = '', logo, averageRateValue = 0, identifier} = appDetails ?? {};
  const { version: versionName } = currentAppVersion ?? {};
  const { presignedUrl} = logo ?? {}; 

  const { handleGetAppVersionDetails, isLoading: isGetAppVersionDetailsLoading = false } = useGetAppVersionDetails({
    fetchPolicy: 'no-cache',
    onCompleted: ({ version }) => {
      const { components = []} = version;
      setCurrentAppVersion(version);
      setAllComponents(components);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteAppComponent, isLoading: isDeleteAppComponentLoading = false } = useDeleteAppComponent({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      setDeleteComponentId(undefined);
      handleGetAppVersionDetails({
        versionId: Number(versionId),
      });
      handleToastSuccess({ message: 'Component successfully deleted'});
      onUpdateApp();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleUpsertComponent = (component?: IDBAppComponent) => {
    setSelectedComponent(component);
    setIsUpsertComponentSidebarOpen(true);
  };

  const handleCancel = () => {
    setSelectedComponent(undefined);
    setIsUpsertComponentSidebarOpen(false);
  };

  const handleUpdate = () => {
    setSelectedComponent(undefined);
    setIsUpsertComponentSidebarOpen(false);
    onUpdateApp();
    handleGetAppVersionDetails({
      versionId: Number(versionId),
    });
  }

  useEffect(() => {
    if (!queryVersionId) return;
    setVersionId(Number(queryVersionId));
    handleGetAppVersionDetails({
      versionId: Number(queryVersionId),
    })
    setComponentUrl(`${appPageBaseUrl}/${Number(queryVersionId)}/components`);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVersionId]);

  useEffect(() => {
    if (!currentAppVersion) return;
    const { status } = currentAppVersion;
    setIsReadOnly(status !== 'DRAFT');
  }, [currentAppVersion]);

  const isLoading = isGetAppVersionDetailsLoading || isAppLoading || isDeleteAppComponentLoading;

  return (
    <AppTemplatePage 
      activePageId={`versions${versionId ? `--version-${versionId}` : ''}`}
      addOnClick={!isReadOnly ? () =>  handleUpsertComponent(undefined): undefined}
      breadcrumbItems={[
        {
          key: '0',
          content: <Link href={`${String(dashboardLink)}/library/apps/marketplace`}>Market place</Link>,
        },
        {
          key: '1',
          content: 'App details',
        },
      ]}
      dashboardLink={`${String(dashboardLink)}/library/apps/marketplace`}
      dashboardLinkText="Marketplace"
      description={`App version components for ${title}: ${String(versionName)}`}
      informationBoxMessage={
        <FlexContainer justifyContent="space-between">
          <span>App version components for <strong>{title}</strong>: {String(versionName)}</span>
          <FlexContainer><strong>Overall rating</strong>: {appDetails ? <CustomRating iconCount={1} name="overall-rating" rateValue={averageRateValue > 0 ? 1 : 0} isReadOnly/>: <>{averageRateValue}</>}</FlexContainer>
        </FlexContainer>
      }
      isLoading={isLoading} 
      logoUrl={presignedUrl}
      pageTitle="App version components"
      settingsDropdownOptions={[
        {
          id: 'id_edit_component',
          icon: SettingsIcon,
          label: 'Edit',
          isBulkOption: false,
          isHidden: false,
          isVisibleByTypeArray: ['component'],
          onClick:  !isReadOnly ?  ({ id: entityId }) =>  {
            if (!entityId) return; 
            const [, componentId] = String(entityId).split('-');
            handleUpsertComponent(allComponents.find(({ id }) => id === Number(componentId)))
          } : undefined,
        },
        {
          id: 'id_add_new_component',
          icon: PlusIcon,
          label: 'New component',
          isVisibleByTypeArray: ['component'],
          onClick: !isReadOnly ? () =>  handleUpsertComponent(undefined): undefined,
        },
        {
          id: 'id_delete_item',
          icon: BinIcon,
          label: 'Delete',
          isVisibleByTypeArray: ['component'],
          isHidden: false,
          onClick: !isReadOnly ?  ({ id: entityId }) =>  {
            if (!entityId) return; 
            const [, componentId] = String(entityId).split('-');
            setIsDeleteDialogOpen(true);
            setDeleteComponentId(Number(componentId));
          } : undefined,
        },
      ]}
      size={presignedUrl ? 'small' : 'medium'} 
      title={String(title)} 
      versionId={versionId}
    >
      <div style={{width: '100%'}}>
        <Spinner isLoading={isLoading} />
        <Spacing size="2x-large">
          <Toolbar
            childrenEnd={
              !isReadOnly ? (
                <Button
                  icon={PlusIcon}
                  size="xs"
                  text="Add new component"
                  variant="primary-inverse"
                  onClick={() => handleUpsertComponent(undefined)}
                />
              ): undefined
            }
            childrenStart={<></>}
          />
        </Spacing>
        {isLoading ? (
          <CardList
            additionalClassNames="c-card-list-page"
            isLoading={isLoading}
            items={[]}
          />
        ): (
          <>
            {allComponents.length === 0 ? (
              <EmptyState
                buttonText="New component"
                imageUrl="/images/content-landing.svg"
                size="large"
                text={isReadOnly ? 'This version is not in a draft state, therefore you cannot modify this. Please create a new version' : 'An app is comprised of components. Click the plus button to create your first component.'}
                title="App components"
                onClick={!isReadOnly ? () =>  handleUpsertComponent(undefined): undefined} />
            ): (
              <CardList
                additionalClassNames="c-card-list-page"
                isLoading={isLoading}
                items={allComponents.map((component) => {
                  const { id, title: componentTitle} = component;
                  return (
                    <NavigationCard 
                      key={id}
                      coverLink={(
                        <CoverLink
                          href={`${componentUrl}/${id}`}
                          supportiveText={`View ${componentTitle}`}
                          wrapperComponent={({children}) => <Link href={`${componentUrl}/${id}`} legacyBehavior>{children}</Link>}
                        />
                      )}
                      settingsButton={!isReadOnly ? <SettingsButton supportiveText="Edit component" onClick={() => handleUpsertComponent(component)} />: undefined}
                      title={componentTitle}
                    />
                  );
                })}
              />
            )}
            </>
          )}
        
        
      </div>
      {identifier && versionName && (
        <AppComponentSidebarView 
          appIdentifier={identifier}
          appVersion={versionName}
          isSideBarOpen={isUpsertComponentSidebarOpen} 
          selectedComponent={selectedComponent}
          onCancel={handleCancel} 
          onUpdate={handleUpdate} 
        />
      )}
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete Component?"
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!deleteComponentId) {
            return;
          }

          handleDeleteAppComponent({
            componentId: deleteComponentId,
          });
        }}
      />
      
    </AppTemplatePage>
  );
};

export { AppComponentsDashboardPage };
