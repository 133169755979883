import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_APP_MUTATION } from './useCreateApp.graphql';
import {
  ICreateAppMutationGraphQLResponse,
  ICreateAppMutationVariables,
  IHandleCreateAppParams,
  IUseCreateAppOptions,
  IUseCreateApp,
} from './useCreateApp.interfaces';

const useCreateApp = (options?: IUseCreateAppOptions): IUseCreateApp => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateAppOptions);

  const [executeCreateApp, { loading: isLoading }] = useEkardoMutation<
  ICreateAppMutationGraphQLResponse,
  ICreateAppMutationVariables
  >({
    mutation: mutation ?? CREATE_APP_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            submitApp: app,
          },
        } = data;

        onCompleted({ app });
      },
      onError,
    },
    token,
  });

  const handleCreateApp = ({ inputType }: IHandleCreateAppParams) => {
    void executeCreateApp({
      variables: {
        inputType
      },
    });
  };

  return {
    handleCreateApp,
    isLoading,
  };
};

export { useCreateApp };
