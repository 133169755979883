import React from 'react';

import { Spacing, Select } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { SkipToPageSelectorProps } from './SkipToPageSelector.interfaces';


const SkipToPageSelector = ({ contentPages = [], control, currentPageId, isDisabled = false }: SkipToPageSelectorProps) => {
  return (
    <>
      <Spacing>
        <Controller
          control={control}
          name="targetContentPageId"
          render={({ field }) =>  (
            <Select
              id="id_target_page"
              isDisabled={isDisabled}
              labelText="Target page"
              options={contentPages.filter(({ id }) => id !== currentPageId).map(({ title = '', url, id }) => (
                {
                  id,
                  name: title || url,
                  value: id,
                }
              ))}
              tooltipPosition="start"
              tooltipText="Select which page to skip to if the conditions pass"
              isLabelSideBySide
              {...field}
            />
          )}
        />

      </Spacing>

    </>
  );
};

export { SkipToPageSelector };
