import { gql } from '@apollo/client';

const CREATE_APP_MUTATION = gql`
  mutation createApp($inputType: CreateAppInputType!) {
    app {
      submitApp(
        inputType: $inputType
      ) {
        id
        description
        identifier
        title
        # logo {
        #  presignedUrl
        # }
        logoId
        averageRateValue
      }
    }
  }
`;

export { CREATE_APP_MUTATION };
