import { gql } from '@apollo/client';

const DELETE_TRANSCRIPT_MUTATION = gql`
  mutation DeleteTranscript($transcriptId: Int!) {
    bubbleText {
      remove(bubbleTextId: $transcriptId)
    }
  }
`;

export { DELETE_TRANSCRIPT_MUTATION };
