import React, { useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, Dialog, SidebarButtons, FormFieldProps, Input, Label, Preloader, Spacing, Textarea, useControlledForm } from '@netfront/ui-library';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { handleCalculateCaptionTimes } from '../AssetSidebarCaptions.helpers';

import { assetCaptionsFormViewConstants } from './AssetCaptionsFormView.constants';
import { getAssetCaptionDefaultValues } from './AssetCaptionsFormView.helpers';
import { AssetCaptionsFormViewProps } from './AssetCaptionsFormView.interfaces';

import { useCreateAssetCaption, useDeleteAssetCaption, useToast, useUpdateAssetCaption } from '../../../../../../../hooks';

const AssetCaptionsFormView = ({ assetId, selectedCaption, onSave, onCancel }: AssetCaptionsFormViewProps) => {
  const { descriptionCharacterMaxLength } = assetCaptionsFormViewConstants;

  const { handleToastError, handleToastSuccess } = useToast();
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();


  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [from, setFrom] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [text, setText] = useState<string>('');

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        from: yup.number().required('From'),
        to: yup.number().required('From'),
      }),
    ),
  });

  const { handleCreateAssetCaption: executeCreateAssetCaption, isLoading: isCreateAssetCaptionLoading = false } = useCreateAssetCaption({
    onCompleted: () => {
      onCancel();
      reset();
      onSave();

      handleToastSuccess({
        message: 'Caption created successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteAssetCaption: executeDeleteAssetCaption, isLoading: isDeleteAssetCaptionLoading = false } = useDeleteAssetCaption({
    onCompleted: () => {

      setIsDeleteDialogOpen(false);

      onCancel();
      reset();
      onSave();

      handleToastSuccess({
        message: 'AssetCaption deleted successfully',
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error: error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateAssetCaption: executeUpdateAssetCaption, isLoading: isUpdateAssetCaptionLoading = false } = useUpdateAssetCaption({
    onCompleted: () => {
      onCancel();
      reset();
      onSave();

      handleToastSuccess({
        message: 'Caption updated successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleCreateAssetCaption = (item: FormFieldProps) => {
    const { to: createTo, from: createFrom, text: createText} = item;

    executeCreateAssetCaption({
      assetId: String(assetId),
      from: createFrom,
      to: createTo,
      text: createText,
    });
  };

  const handleDeleteAssetCaption = () => {
    if (!selectedCaption) {
      return;
    }

    executeDeleteAssetCaption({
      id: selectedCaption.id,
    });
  };

  const handleUpdateAssetCaption = (item: FormFieldProps) => {
    const { id: captionId, to: updateTo, from: updateFrom, text: updateText} = item;

    executeUpdateAssetCaption({
      id: captionId,
      from: updateFrom,
      to: updateTo,
      text: updateText,
    });
  };

  const handleSave = (item: FormFieldProps) => {
    if (selectedCaption) {
      handleUpdateAssetCaption(item);
    } else {
      handleCreateAssetCaption(item)
    }
  };

  useEffect(() => {
    setDefaultValues(getAssetCaptionDefaultValues(selectedCaption));
    setText(selectedCaption?.text ?? '');
    setTo(selectedCaption?.to ?? 0);
    setFrom(selectedCaption?.from ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCaption]);

  const isLoading = isCreateAssetCaptionLoading || isDeleteAssetCaptionLoading || isUpdateAssetCaptionLoading;

  return (
    <div className="c-caption-form-view">
      <Preloader isLoading={isLoading} />

      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item)
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        <Spacing size="large">
          <Label
            additionalClassNames="c-caption-form-view_form__times"
            forId=""
            labelText={`${handleCalculateCaptionTimes(Number(from))} - ${handleCalculateCaptionTimes(Number(to))}`}
            tooltipText="Configure the asset caption times in seconds"
          />

          <Spacing>
            <Controller
              control={control as Control<FormFieldProps>}
              name="from"
              render={({ field, fieldState }) => (
                <Input
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_caption_from"
                  labelText="Time from"
                  placeholder="Start time in seconds"
                  type="number"
                  isRequired
                  {...field}
                  onChange={(event) => {
                    const { target: { value }} = event;
                    setFrom(Number(value));
                    field.onChange(event);
                  }}
                />
              )}
            />
            <Controller
              control={control as Control<FormFieldProps>}
              name="to"
              render={({ field, fieldState }) => (
                <Input
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_caption_to"
                  labelText="Time to"
                  placeholder="Completion time in seconds"
                  type="number"
                  isRequired
                  {...field}
                  onChange={(event) => {
                    const { target: { value }} = event;
                    setTo(Number(value));
                    field.onChange(event);
                  }}
                />
              )}
            />

          </Spacing>
        </Spacing>

        <Spacing size="large">
          <Controller
            control={control as Control<FormFieldProps>}
            name="text"
            render={({ field, fieldState }) => (
              <Textarea
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_text"
                labelText="Description (optional)"
                maxLength={descriptionCharacterMaxLength}
                placeholder="Add your description here"
                tooltipText="Configure the asset caption description"
                {...field}
                onChange={(event) => {
                  const { target: { value } } = event;
                  field.onChange(event);
                  setText(value);
                }}
              />
            )}
          />
        </Spacing>

        { selectedCaption && <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete caption: ${text}?`}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDeleteAssetCaption}
        />}

        <SidebarButtons
          onCancel={onCancel}
          onDelete={selectedCaption ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </div>
  );
};

export { AssetCaptionsFormView };
