import { gql } from '@apollo/client';

const UPDATE_APP_COMPONENT_DETAILS_MUTATION = gql`
  mutation createMyApp($inputType: UpdateComponentTitleInputType!, $fileInputType: SaveFilesIntoComponentInputType!) {
    app {
      appVersionMutation {
        appVersionComponentMutation {
          updateComponentTitle(inputType: $inputType)
          saveFilesIntoComponent(inputType: $fileInputType)
        }
      }
    }
  }
`;

export { UPDATE_APP_COMPONENT_DETAILS_MUTATION };
