import { useEffect, useState } from 'react';

import { BinIcon, ButtonIconOnly, InformationBox, PlusIcon, SidebarButtons, Spinner, WarningIcon } from '@netfront/ui-library';
import cx from 'classnames';
import { AddGroupAccess, SidebarContainer } from 'components';
import {
  IGetGroupAccessOnCompletedResponse,
  useDeleteGroupAccess,
  useGetGroupAccess,
  useToast,
} from 'hooks';
import { IUserFlowGroupAccess } from 'interfaces';


import { IUserFlowGroupAccessSidebarViewProps } from './UserFlowGroupAccessSidebarView.interfaces';
import styles from './UserFlowGroupAccessSidebarView.module.css';

export const UserFlowGroupAccessSidebarView = ({ onClose, groupId, projectId }: IUserFlowGroupAccessSidebarViewProps) => {

  const { handleToastSuccess } = useToast();

  const [isNewAccessVisible, setIsNewAccessVisible] = useState<boolean>(false);
  const [groupAccesses, setGroupAccesses] = useState<IUserFlowGroupAccess[]>([]);

  const onGetGroupAccessCompleted = (data: IGetGroupAccessOnCompletedResponse) => {
    const { groupAccess } = data;
    setGroupAccesses(groupAccess);
  };

  const onDeleteGroupAccessCompleted = () => {
    void handleGetGroupAccess({ groupId });
  };

  const { handleGetGroupAccess, isLoading: isGroupAccessLoading = false } = useGetGroupAccess({
    onCompleted: onGetGroupAccessCompleted,
  });

  const { handleDeleteGroupAccess } = useDeleteGroupAccess({ onCompleted: onDeleteGroupAccessCompleted });

  useEffect(() => {
    void handleGetGroupAccess({ groupId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isGroupAccessLoading;

  const onAddGroupAdd = () => {
    handleToastSuccess({ message: 'Access added successfully' });
    void handleGetGroupAccess({ groupId });
    setIsNewAccessVisible(false);
  };

  const onDeleteGroupAccess = (userFlowId: number) => {
    void handleDeleteGroupAccess({ groupId, userFlowId: userFlowId });
    handleToastSuccess({ message: 'Access removed successfully' });
  };

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      {isNewAccessVisible && (
        <AddGroupAccess groupId={groupId} projectId={projectId} onAdd={onAddGroupAdd} onClose={() => setIsNewAccessVisible(false)} />
      )}
      {!isLoading && !isNewAccessVisible && (
        <>
          <ul className={cx(styles['c-group-access-items'])}>
            {groupAccesses.length === 0 && <InformationBox icon={WarningIcon} message="The group has no access set-up"/>}
            {groupAccesses.map(({ userFlow, userFlowId }) => (
              <li key={userFlowId} className={cx(styles['c-group-access-item'])} title="Search list item">
                <div className={cx(styles['c-group-access-item__content'])}>
                  <span className={cx(styles['c-group-access-item__label'])}>{`${userFlow ? userFlow.title : ''}`}</span>
                </div>
                <ButtonIconOnly
                  additionalClassNames="border-none"
                  icon={BinIcon}
                  text="close sidebar"
                  onClick={() => {
                    onDeleteGroupAccess(userFlowId);
                  }}
                />
              </li>
            ))}
          </ul>
          <section className="c--items-add">
            <ButtonIconOnly
              additionalClassNames="border-none"
              icon={PlusIcon}
              text="close sidebar"
              onClick={() => setIsNewAccessVisible(true)}
            />
          </section>
          <SidebarButtons
            onCancel={onClose}
          />
        </>
      )}
    </SidebarContainer>
  );
};
