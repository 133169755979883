import { useEffect, useState } from 'react';

import { getAccessTokenCookie, useJwtDecoder } from '@netfront/common-library';

export const useUserIsAdmin = () => {
  const ADMINISTRATOR = 'Administrator';

  const [isAdmin, setIsAdmin] = useState(false);
  const { getDecodedJwt } = useJwtDecoder();
  useEffect(() => {
    const accessToken = getAccessTokenCookie();

    if (!accessToken) {
      return;
    }
    const decoded = getDecodedJwt(String(accessToken));

    const { role } = decoded as { role: string };

    setIsAdmin(role === ADMINISTRATOR);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isAdmin };
};
