import { gql } from '@apollo/client';

const EXPORT_USER_SESSIONS = gql`
  query ExportCustomEvent($ianaTimeZone: String!, $projectId: String!, $dateFrom: DateTime, $dateTo: DateTime, $fileType: FileType) {
    reportQueries {
      customEvents(projectId: $projectId, dateTo: $dateTo, dateFrom: $dateFrom, ianaTimeZone: $ianaTimeZone, fileType: $fileType) {
        contentType
        presignedUrl
        s3Key
      }
    }
  }
`;

export { EXPORT_USER_SESSIONS };
