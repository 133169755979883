
import { ApolloError } from '@apollo/client';
import { useCreateApp, useToast, useUpdateApp } from 'hooks';
import { IDBApp } from 'interfaces';



const useUpsertApp = ({ onCompleted }: { onCompleted: ({ app } : { app: IDBApp} ) => void }) => {



  const { handleToastError } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };


  const { handleUpdateApp, isLoading: isUpdateAppLoading = false } = useUpdateApp({
    onCompleted,
    onError: handleGetError,
  });

  const { handleCreateApp, isLoading: isCreateAppLoading = false } = useCreateApp({
    onCompleted,
    onError: handleGetError,
  });

  const handleUpsertApp = ({
    appId,
    description,
    identifier,
    logoId,
    title,
  }: {
    appId?: string;
    description: string;
    identifier: string;
    logoId?: string;
    title: string;
  }) => {
    if (!appId) {
      handleCreateApp({
        inputType: {
          title,
          applicationDescription: description,
          identifier,
          // logoId,
        }
      });
    } else {

      handleUpdateApp({
        inputType: {
          appIdentifier: appId,
          applicationDescription: description,
          title,
          logoId,
        }
      });
    }
  };

  return {
    isLoading: isCreateAppLoading || isUpdateAppLoading,
    handleUpsertApp,
  };
};
export { useUpsertApp };
