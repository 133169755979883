import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_CONTAINER } from './useCreateContainer.graphql';
import {
  ICreateContainerMutationGraphQLResponse,
  ICreateContainerMutationVariables,
  IUseCreateContainerOptions,
  IUseCreateContainer,
  ICreateContainerMutationParams,
} from './useCreateContainer.interfaces';

const useCreateContainer = (options?: IUseCreateContainerOptions): IUseCreateContainer => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateContainerOptions);

  const [executeCreateContainer, { loading: isLoading }] = useEkardoMutation<ICreateContainerMutationGraphQLResponse, ICreateContainerMutationVariables>({
    mutation: mutation ?? CREATE_CONTAINER,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSection: { addSectionContainer: section },
        } = data;

        onCompleted({
          section,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateContainer = async ({
    sectionId,
    contentLayoutId,
    title,
    sort,
    isVisible,
    backgroundColor,
    borderColor,
  }: ICreateContainerMutationParams) => {
    await executeCreateContainer({
      variables: {
        sectionContainer: {
          sectionId,
          contentLayoutId,
          title,
          isVisible,
          sort,
          backgroundColor,
          borderColor,
        }
      }
    });
  };

  return {
    handleCreateContainer,
    isLoading,
  };
};

export { useCreateContainer };
