import React from 'react';

import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { ICommunitiesTableData } from './CommunitiesPage.interfaces';

const COMMUNITIES_PAGE_SIZE = 10;

const COMMUNITIES_TABLE_COLUMNS: ReadonlyArray<Column<ICommunitiesTableData>> = [
  {
    accessor: 'title',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Community name</div>,
    width: '25%',
  },
  {
    accessor: 'description',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Description</div>,
    width: '50%'
  },
  {
    accessor: 'privacy',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Privacy</div>,
    width: '10%'
  },
  {
    accessor: 'status',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Status</div>,
    width: '10%'
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="Update community" onClick={() => onClick(id)} />,
    width: '5%',
  },
];

export { COMMUNITIES_TABLE_COLUMNS, COMMUNITIES_PAGE_SIZE };
