import { useEffect, useState } from 'react';


import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, CustomRating, Dialog, FormFieldProps, GeneralTabIcon, Input, InputFieldWrapper, SidebarButtons, SideBarTabSet,  Spacing,  Spinner, Textarea, useControlledForm } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import {  useCreateAppComment, useDeleteAppComment, useToast, useUpdateAppComment } from 'hooks';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';


import { getAppCommentsDefaultValues } from './AppCommentsSidebarView.helpers';
import { AppCommentsSidebarViewProps } from './AppCommentsSidebarView.interfaces';


const AppCommentsSidebarView = ({
  isSideBarOpen = false,
  onCancel,
  onUpdate,
  appIdentifier,
  selectedComment,
}: AppCommentsSidebarViewProps) => {

  const { handleToastError, handleToastSuccess } = useToast();
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({ name: '', image: ''});

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        comment: yup.string().label('Comment').required(),
        rate: yup.number().label('Rating').required(),
      }),
    ),
  });

  const handleError = (error: ApolloError) => {
    handleToastError({
      error,
    });
  };

  const { handleDeleteAppComment, isLoading: isDeleteAppCommentLoading = false } = useDeleteAppComment({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      handleToastSuccess({ message: 'Comment successfully deleted'});
      reset();
      onUpdate();
    },
    onError: handleError,
  });


  const { handleCreateAppComment, isLoading: isCreateAppCommentLoading = false } = useCreateAppComment({
    onCompleted: () => {
      handleToastSuccess({ message: 'Comment successfully added'});
      reset();
      onUpdate();
    },
    onError: handleError,
  });

  const { handleUpdateAppComment, isLoading: isUpdateAppCommentLoading = false } = useUpdateAppComment({
    onCompleted: () => {
      handleToastSuccess({ message: 'Comment successfully updated'});
      reset();
      onUpdate();

    },
    onError: handleError,
  });


  const handleOnCancel = () => {
    reset();
    onCancel();
  };

  const handleSave = (item: FormFieldProps) => {
    const { rate, title, comment } = item;

    if (!selectedComment) {
      handleCreateAppComment({
        appIdentifier,
        rate,
        title,
        comment,
      });
    } else {
      handleUpdateAppComment({
        rateId: selectedComment.id,
        rate,
        title,
        comment,
      });
    }
  };

  useEffect(() => {
    setDefaultValues(getAppCommentsDefaultValues(selectedComment));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComment, isSideBarOpen]);

  const isLoading = isDeleteAppCommentLoading || isCreateAppCommentLoading || isUpdateAppCommentLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete comment?"
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedComment) {
            return;
          }

          handleDeleteAppComment({
            rateId: selectedComment.id,
          });
        }}
      />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
      >


        <SideBarTabSet
          defaultActiveTabId="id_comment_general_tab"
          handleOpenCloseSideBar={handleOnCancel}
          hasViewPadding={false}
          hideSideBarButtons={true}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_comment_general_tab',
              label: 'General',
              view: () =>
                <SidebarContainer>
                  <Spacing>
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="rate"
                      render={({ field, fieldState }) => (
                        <InputFieldWrapper
                          id="id_app_rating"
                          label="Rate"
                          labelFontWeight="light"
                          labelType="span"
                          message={{success: '', error: getFormFieldErrorMessage(fieldState)}}
                          type="input"
                          isLabelSideBySide
                          isRequired
                          >
                            <CustomRating {...field} rateValue={field.value} onClick={field.onChange}/>
                          </InputFieldWrapper>
                      )}
                    />
                  </Spacing>
                  <Spacing>
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="title"
                      render={({ field, fieldState }) => (
                        <Input
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_app_comment_title"
                          labelText="Title"
                          type="text"
                          isLabelSideBySide
                          isRequired
                          {...field}
                        />
                      )}
                    />
                  </Spacing>
                  <Spacing>
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="comment"
                      render={({ field, fieldState }) => (
                        <Textarea
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_app_comment"
                          labelText="Commnent"
                          isLabelSideBySide
                          {...field}
                        />
                      )}
                    />
                  </Spacing>
                  <SidebarButtons
                    buttonSize="xs"
                    isDeleteButtonDisabled={isLoading}
                    isSaveButtonDisabled={isLoading}
                    onCancel={handleOnCancel}
                    onDelete={() => setIsDeleteDialogOpen(true)}
                    onSaveButtonType="submit"
                  />
                </SidebarContainer>
            },
          ]}

        />
      </ControlledForm>
    </>
  );
};

export { AppCommentsSidebarView };
