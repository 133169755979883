import { IAssetTag } from "@netfront/common-library";
import { IContentPage } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getFormattedContentStyles } from "components";

const getPageDefaultValues = ({ page } : { page?: IContentPage; }): FormFieldProps => {
  const {
    seoDescription,
    seoTitle,
    title: pageTitle,
    url,
    status,
    tags,
    asset,
    cssStyle,
    releaseDate,
    contentPageTypeId,
    minimumExposureTime,
    mode = [],
    relatedPage = [],
    contentPageConfigurationNavigation,
    sensitiveContent: isSensitive,
  } = page ?? {};

  const { assetId, presignedUrl, isAnimatedImage} = asset ?? {};

  const { 
    enableBackButton: isEnableBackButton = false, 
    enableNextButton: isEnableNextButton = false,
    backButtonToolTip,
    nextButtonToolTip,
  } = contentPageConfigurationNavigation ?? {};


  const { id: styleId } = cssStyle ?? {};

  return {
    pageTitle: pageTitle ?? 'Page',
    seoTitle: seoTitle ?? '',
    seoDescription: seoDescription ?? '',
    url: url ?? '',
    imagePresignedUrl: presignedUrl ?? '',
    tags: tags ?? [],
    status: status ?? 'PUBLISHED',
    entityStyleId: styleId,
    imageAssetId: assetId ?? '',
    isSensitive,
    isAnimatedImage: isAnimatedImage ?? false,
    releaseDate: releaseDate ?? '',
    minimumExposureTime: minimumExposureTime ?? '',
    contentPageType: contentPageTypeId ?? '',
    isAssessment: mode.includes('ASSESSMENT'),
    isActivity: mode.includes('ACTIVITY'),
    isEnableBackButton,
    isEnableNextButton,
    backButtonToolTip: backButtonToolTip ?? '',
    nextButtonToolTip: nextButtonToolTip ?? '',
    relatedPage,
  }
};

const getPageCommonVariables = ({ item, parentId, uploadedAssetId, sort }: {  item: FormFieldProps; parentId?: number; sort?: number; uploadedAssetId?: string; }) => {

  const {
    pageTitle = 'Page',
    assetId,
    seoTitle,
    seoDescription,
    status,
    tags = [],
    url,
    isSensitive = false,
    releaseDate,
    minimumExposureTime,
    contentPageType,
    isAssessment,
    isActivity,
    isEnableBackButton = false,
    isEnableNextButton = false,
    backButtonToolTip,
    nextButtonToolTip,
  } = item;

  const { customTheme } = getFormattedContentStyles({ item });

  const mode = [];

  if (isAssessment) mode.push('ASSESSMENT');
  if (isActivity) mode.push('ACTIVITY');

  const sharedVariables = {
    title: pageTitle,
    assetId: uploadedAssetId ?? assetId,
    parentId,
    seoDescription,
    seoTitle,
    sensitiveContent: isSensitive,
    sort,
    url,
    status,
    tags: (tags as IAssetTag[]).map(({ id }) => Number(id)),
    customTheme,
    releaseDate: releaseDate ? new Date(String(releaseDate)): undefined,
    minimumExposureTime: Number(minimumExposureTime),
    contentPageType: contentPageType ? Number(contentPageType): undefined,
    mode,
    contentPageConfigurationNavigation: {
      backButtonToolTip: isEnableBackButton ? backButtonToolTip : '',
      nextButtonToolTip: isEnableNextButton ? nextButtonToolTip : '',
      enableBackButton: isEnableBackButton, 
      enableNextButton: isEnableNextButton,
    }
  };

  return sharedVariables;

}




export { getPageDefaultValues, getPageCommonVariables };
