import { gql } from '@apollo/client';

const UNLINK_ENTITY_FROM_APP = gql`
  mutation unlinkEntityFromApp($inputType: RemoveAuthorizedEntityInputType!) {
    app {
      removeAuthorizedEntity(
        inputType: $inputType
      )
    }
  }
`;


export { UNLINK_ENTITY_FROM_APP };
