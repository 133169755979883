import { gql } from '@apollo/client';

const SORT_SECTION = gql`
  mutation sortSection(
    $contentSectionId: Int!
    $sort: Int!
    #$sectionGroupId: Int!,
  ) {
    
    contentSection {
      sortSection(
        contentSectionId: $contentSectionId,
        sort: $sort,
        #sectionGroupId: $sectionGroupId,
      ) {
        id
        sort
        
      }
    }
  }
`;

export { SORT_SECTION };
