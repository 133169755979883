import { ButtonIconDropdownMenu, EyeShowIcon, TickIcon } from '@netfront/ui-library';
import { StatusPill } from 'components';
import capitalize from 'lodash.capitalize';
import { Column } from 'react-table';

import { IAppVersionTableData } from './AppVersionsPage.interfaces';



const APP_VERSION_TABLE_COLUMNS: ReadonlyArray<Column<IAppVersionTableData>> = [

  {
    accessor: 'version',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Version</div>,
  },
  {
    accessor: 'components',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Components</div>,
  },
  {
    accessor: 'uploadedDateTime',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{new Date(value).toDateString()}</div>,
    Header: () => <div className="text-left w-full">Creation date</div>,
  },
  {
    accessor: 'status',
    Cell: ({ value }) => {
      const colorMap: { [key: string]: string} = {
        'DRAFT': 'hollow-grey',
        'PENDING': 'hollow-red'
      }
      return (
        <div className="text-left w-full word-break-all">
          <StatusPill color={['DRAFT', 'PENDING'].includes(value) ? colorMap[String(value)] : ''} isHighlighted={value === 'ACCEPTED'} status={value === 'ACCEPTED' ? 'Published': capitalize(value.toLowerCase()) }/>
        </div>
      );
    },
    Header: () => <div className="text-left w-full">Status</div>,
  },
  {
    accessor: 'usageCount',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Linked content snippets</div>,
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { item, onViewCodeOnClick, onSubmitForApprovalOnClick } }) => {
      const { status, components = []} = item;
      return <ButtonIconDropdownMenu
        id={`id_dropdown_menu_${item.id}`}
        listItems={[
          {
            id: 'id_view_code',
            icon: EyeShowIcon,
            label: 'View code',
            onClick: () => onViewCodeOnClick(item),
            isHidden: false,
          },
          {
            id: 'id_submit_for_approval',
            icon: TickIcon,
            label: 'Submit for approval',
            onClick: () => onSubmitForApprovalOnClick(item),
            isHidden: status !== 'DRAFT' || components.length === 0,
          },
        ]}
        supportiveText="Open settings"
        triggerType="icon"
      />
    },
    Header: () => <span className="h-hide-visually">Settings</span>,
    width: '5%',
  },
];

export { APP_VERSION_TABLE_COLUMNS };
