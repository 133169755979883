import { gql } from '@apollo/client';

const GET_APP_COMPONENT_DETAILS = gql`
  query getAppComponentDetailRequest($inputType: GetAppComponentDetailsInputType!) {
    app {
      getAppComponentDetails(inputType: $inputType) {
        id
        appVersionId
        title
        files {
          appComponentId
          locationId
          fileType
          id
          location {
            presignedUrl
          }
        }
      }
    }
  }
`;

export { GET_APP_COMPONENT_DETAILS };
