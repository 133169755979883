import { gql } from '@apollo/client';
import { SectionGroupsFragment } from 'fragments';

import { CONTENT_PAGE_CHILDREN_FRAGMENT } from 'fragments/contentPages/contentPageChildren';


const GET_CONTENT_PAGES = gql`
  query getContentPages($url: String!, $projectId: String!) {
    contentGroup {
      getContentGroupByUrl(url: $url, projectId: $projectId) {
        id
        title
        url
        type
        firstPage {
          id
          url
        }
        contentPages(topLevelOnly: true, status: [PUBLISHED, UNPUBLISHED]) {
          id
          title
          url
          sort
          audio {
            presignedUrl
          }
          asset {
            assetId
            presignedUrl
          }
          bubbleTexts {
            angle
            character {
              id
              image {
                presignedUrl
                contentType
                s3Key
              }
              name
              projectId
            }
            characterId
            id
            isTailVisible
            hasShadow
            shape
            tailType
            sort
            text
            w
            x
            y
          }
          contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
            ...ContentPageChildrenFragment
            contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
              ...ContentPageChildrenFragment
              contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
                ...ContentPageChildrenFragment
                contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
                  ...ContentPageChildrenFragment
                  contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
                    ...ContentPageChildrenFragment
                    contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
                      ...ContentPageChildrenFragment
                      contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
                        ...ContentPageChildrenFragment
                      }
                    }
                  }
                }
              }
            }
          }
          sectionGroups {
            ...SectionGroupsFragment
          }
        }
      }
    }
  }

  ${SectionGroupsFragment}
  ${CONTENT_PAGE_CHILDREN_FRAGMENT}

`;

export { GET_CONTENT_PAGES };
