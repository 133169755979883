import { gql } from '@apollo/client';

const ATTACH_CSS_STYLE_TO_CONTAINER = gql`
  mutation($containerId: Int!, $styleId: Int!) {
    cssStyle {
      attachStyleToContainer(containerId: $containerId, styleId: $styleId)
    }
  }
`;


export { ATTACH_CSS_STYLE_TO_CONTAINER };
