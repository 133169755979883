import { AppIcon, AudioTabIcon, AvatarIcon, ButtonIcon, ClusterIcon, CodeIcon, EmbedIcon, FormIcon, HollowImageIcon, HollowVideoIcon, IconComponentType, ImageSliderIcon, ListViewIcon, MapIcon, QuestionnaireIcon, QuoteIcon, SliderIcon, SpacerIcon, TableIcon, TabsIcon, TextBoxIcon, TooltipIcon } from "@netfront/ui-library";

export const NetfrontServices = {
  BONOBO: 'BONOBO',
  BONOBO_MANAGEMENT: 'BONOBO_MANAGEMENT',
  EKARDO: 'EKARDO',
  HOWLER: 'HOWLER',
  MURIQUI: 'MURIQUI',
  VERVET: 'VERVET',
  CAPUCHIN: 'CAPUCHIN',
};

export const SnippetTypeIcon: {[key: string]: IconComponentType} = {
  ContentSnippetAppType: AppIcon,
  ContentSnippetAudioType: AudioTabIcon,
  ContentSnippetButtonType: ButtonIcon,
  ContentSnippetClusterResultType: ClusterIcon,
  ContentSnippetCodeType: CodeIcon,
  ContentSnippetDocumentType: FormIcon,
  ContentSnippetEmbedType: EmbedIcon,
  ContentSnippetFormType: SpacerIcon,
  ContentSnippetImageType: HollowImageIcon,
  ContentSnippetImageMapType: ImageSliderIcon,
  ContentSnippetMapType: MapIcon,
  ContentSnippetQuestionType: QuestionnaireIcon,
  ContentSnippetQuoteType: QuoteIcon,
  Reveal: TooltipIcon,
  ContentSnippetSliderType: ImageSliderIcon,
  ContentSnippetSpacerType: SliderIcon,
  Table: TableIcon,
  Tabs: TabsIcon,
  ContentSnippetTextType: TextBoxIcon,
  ContentSnippetVideoType: HollowVideoIcon,
  ContentSnippetSummaryType: ListViewIcon,
  ContentSnippetAvatarType: AvatarIcon,
};
