import { useContext, useEffect, useState } from 'react';

import { IContentPage, IContentSection, IContentSectionContainer, IContentSnippet, IDBContentGroup, IDBContentPage, ISectionGroup } from '@netfront/ekardo-content-library';
import { ContentBuilderNavigationItemProps, DroppedData, IOption } from '@netfront/ui-library';
import { DashboardContext } from 'context';
import { useDeleteContainer, useDeleteSection, useDeleteSectionGroup, useDeleteSnippet, useGetContentPage, useGetContentPages, useSortContainer, useSortSection, useSortSectionGroup, useSortSnippet, useToast } from 'hooks';
import { IDeleteEntity, IEntityType, IPageSidebarDetails } from 'interfaces';
import { useRouter } from 'next/router';

import ContentBuilderContext from './ContentBuilderContext';
import { findParentIdByIdAndType, formatContentPages, formatContentSectionGroupsToNavigationItems, getContainerById, getContentPageByUrl, getSectionById, getSectionGroupById, getSnippetById, getUpdatedLayoutId } from './ContentBuilderContext.helpers';
import { ContentBuilderContextProps, IActionsSidebarDetails, ISnippetSidebarDetails, IDeleteEntityDetails, ISectionSidebarDetails, IContainerSidebarDetails, IBulkActionDetails, IBulkActionConfig, ISectionGroupSidebarDetails, IBulkActionEntity } from './ContentBuilderContext.interfaces';

import { SnippetTypeConst } from "../../constants";

export function ContentBuilderProvider({ contentType, contentGroupUrl, children, pageUrl, isForm = false }: ContentBuilderContextProps) {
  const { handleToastSuccess, handleToastError, handleToastCustomError } = useToast();
  const { query, push } = useRouter();
  const { projectId: queryProjectId } = query;

  const { dashboardLink } = useContext(DashboardContext);

  // Base
  const [pageOptions, setPageOptions] = useState<IOption[]>([]);
  const [navigationItems, setNavigationItems] = useState<ContentBuilderNavigationItemProps[]>([]);
  const [isFormsMode, setIsFormsMode] = useState<boolean>(false);
  const [isEmptyPage, setIsEmptyPage] = useState<boolean>(false);
  const [firstSectionGroupId, setFirstSectionGroupId] = useState<number>();
  const [contentSectionGroups, setContentSectionGroups] = useState<ISectionGroup[]>([]);
  const [projectId, setProjectId] = useState<string>();
  const [pageId, setPageId] = useState<number>();
  const [formsPageId, setFormsPageId] = useState<number>();
  const [contentGroupId, setContentGroupId] = useState<number>();
  const [formId, setFormId] = useState<number>();
  const [currentFormSnippetId, setCurrentFormSnippetId] = useState<number>();
  const [formContentSectionGroups, setFormContentSectionGroups] = useState<ISectionGroup[]>([]);
  const [contentPage, setContentPage] = useState<IContentPage>();
  const [formContentPage, setFormContentPage] = useState<IContentPage>();
  const [contentPages, setContentPages] = useState<IContentPage[]>([]);
  const [baseUrl, setBaseUrl] = useState<string>('');

  // Shared
  const [deleteEntityDetails, setDeleteEntityDetails] = useState<IDeleteEntityDetails>();

  // Bulk actions
  const [bulkActionDetails, setBulkActionDetails] = useState<IBulkActionDetails>();

  // Actions
  const [actionsSidebarDetails, setActionsSidebarDetails] = useState<IActionsSidebarDetails>({} as IActionsSidebarDetails);
  const [isActionsSidebarOpen, setIsActionsSidebarOpen] = useState<boolean>(false);

  // Page
  const [currentContentPage, setCurrentContentPage] = useState<IContentPage>({} as IContentPage);
  const [pageSidebarDetails, setPageSidebarDetails] = useState<IPageSidebarDetails>({} as IPageSidebarDetails);
  const [isPageSidebarOpen, setIsPageSidebarOpen] = useState<boolean>(false);

  // Snippet
  const [snippetSidebarDetails, setSnippetSidebarDetails] = useState<ISnippetSidebarDetails>({} as ISnippetSidebarDetails);
  const [isSnippetSidebarOpen, setIsSnippetSidebarOpen] = useState<boolean>(false);

  // Section group
  const [sectionGroupSidebarDetails, setSectionGroupSidebarDetails] = useState<ISectionGroupSidebarDetails>({} as ISectionGroupSidebarDetails);
  const [isSectionGroupSidebarOpen, setIsSectionGroupSidebarOpen] = useState<boolean>(false);

  // Section
  const [sectionSidebarDetails, setSectionSidebarDetails] = useState<ISectionSidebarDetails>({} as ISectionSidebarDetails);
  const [isSectionSidebarOpen, setIsSectionSidebarOpen] = useState<boolean>(false);

  // Container
  const [containerSidebarDetails, setContainerSidebarDetails] = useState<IContainerSidebarDetails>({} as IContainerSidebarDetails);
  const [isContainerSidebarOpen, setIsContainerSidebarOpen] = useState<boolean>(false);

  const contentPageId = isFormsMode ? formsPageId: pageId;
  const localisedContentSectionGroups = isFormsMode ? formContentSectionGroups : contentSectionGroups;

  const { handleGetContentPages, isLoading: isGetContentPagesLoading = false } = useGetContentPages({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentGroup }) => {
      const { contentPages: returnedContentPages } = contentGroup;
      setContentGroupId(contentGroup.id);

      const currentPage = getContentPageByUrl({contentPages: returnedContentPages, url: pageUrl});

      const [ firstPage ] = returnedContentPages;

      setPageId(currentPage ? currentPage.id : firstPage.id);

      const formattedPages = formatContentPages(returnedContentPages);

      setContentPages(returnedContentPages);

      setPageOptions(formattedPages);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteSnippet, isLoading: isDeleteSnippetLoading = false } = useDeleteSnippet({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) return;

      closeDeleteEntityDialog();
      closeSnippetSidebar();

      handleToastSuccess({
        message: 'Snippet successfully deleted',
      });

      void handleGetContentPage({
        contentPageId: Number(pageId)
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteSection, isLoading: isDeleteSectionLoading = false } = useDeleteSection({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) return;


      closeDeleteEntityDialog();
      closeSectionSidebar();

      handleToastSuccess({
        message: 'Section successfully deleted',
      });

      void handleGetContentPage({
        contentPageId: Number(pageId)
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteSectionGroup, isLoading: isDeleteSectionGroupLoading = false } = useDeleteSectionGroup({
    onCompleted: () => {

      closeDeleteEntityDialog();
      closeContainerSidebar();

      handleToastSuccess({
        message: 'Section successfully deleted',
      });

      void handleGetContentPage({
        contentPageId: Number(pageId)
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteContainer, isLoading: isDeleteContainerLoading = false } = useDeleteContainer({
    onCompleted: () => {

      closeDeleteEntityDialog();
      closeContainerSidebar();

      handleToastSuccess({
        message: 'Section successfully deleted',
      });

      void handleGetContentPage({
        contentPageId: Number(pageId)
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleSortSectionGroup, isLoading: isSortSectionGroupLoading = false } = useSortSectionGroup({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Section group successfully moved',
      });

      void handleGetContentPage({
        contentPageId: Number(pageId)
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleSortSection, isLoading: isSortSectionLoading = false } = useSortSection({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Section successfully moved',
      });

      void handleGetContentPage({
        contentPageId: Number(pageId)
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleSortSnippet, isLoading: isSortSnippetLoading = false } = useSortSnippet({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Snippet successfully moved',
      });

      void handleGetContentPage({
        contentPageId: Number(pageId)
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleSortContainer, isLoading: isSortContainerLoading = false } = useSortContainer({
    onCompleted: () => {
      void handleGetContentPage({
        contentPageId: Number(pageId)
      });
    },
  });

  const { handleGetContentPage, isLoading: isGetContentPageLoading = false } = useGetContentPage({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentPage: returnedContentPage }) => {
      setCurrentContentPage(returnedContentPage);
      const { sectionGroups } = returnedContentPage;

      if (sectionGroups.length === 1) {
        const [ firstSectionGroup ] = sectionGroups;
        const { contentSections, id } = firstSectionGroup;
        if (contentSections.length === 0) {
          setFirstSectionGroupId(id);
          setIsEmptyPage(true);
        } else {
          setIsEmptyPage(false);
          setFirstSectionGroupId(undefined);
        }
      }

      if (isFormsMode && currentFormSnippetId) {
        const snippet = getSnippetById(sectionGroups, currentFormSnippetId);
        const { form } = snippet ?? {};
        const { contentPages: formContentPages } = form ?? {};
        const [ formPage ] = formContentPages ?? [];
        const { sectionGroups: snippetFormContentSectionGroups } = formPage;

        setFormContentPage(formPage);
        setFormContentSectionGroups(snippetFormContentSectionGroups as ISectionGroup[]);
      }
      
      setContentPage(returnedContentPage);
      setContentSectionGroups(sectionGroups);
      setNavigationItems(formatContentSectionGroupsToNavigationItems({ sectionGroups, formId, isFormsMode }));

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  // Page

  const closePageSidebar = () => {
    setIsPageSidebarOpen(false);
    setPageSidebarDetails({} as IPageSidebarDetails);
  };

  const openPageSidebar = (id?: number) => {
    const isCurrentPage = currentContentPage.id === id;
    setIsPageSidebarOpen(true);
    if (isCurrentPage) {
      setPageSidebarDetails({
        pageId: id,
        sort: currentContentPage.sort,
        contentGroupId,
      } as IPageSidebarDetails);
    } else {
      setPageSidebarDetails({
        contentGroupId,
        pageId: undefined,
        sort: undefined,
      });
    }
  };

  const handleUpdatePage = (returnedContentPage: IContentPage) => {
    push(`${baseUrl}/${returnedContentPage.url}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };

  const handleCreatePage = (returnedContentPage: IContentPage) => {
    push(`${baseUrl}/${returnedContentPage.url}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };

  const handleDeletePage = () => {
    const { pageId: currentPageId } = pageSidebarDetails;
    const filteredOptions = pageOptions.filter(({id: optionId }) => optionId !== currentPageId);
    const [firstPage] = filteredOptions;
    push(`${baseUrl}/${String(firstPage.value)}`).catch((error) =>
      handleToastError({
        error,
      }),
    );

  };


  // Actions
  const getEntityContentEventDetails = (entityId: number, type: IEntityType) => {
    let contentEventId = undefined;
    let hasContentEvent = false;

    if (type === 'section') {
      const section = getSectionById(localisedContentSectionGroups, entityId);
      const { contentEvent } = section ?? {};
      const { id } = contentEvent ?? {};
      contentEventId = id;
      hasContentEvent = Boolean(id);
    }
    if (type === 'container') {
      const container = getContainerById(localisedContentSectionGroups, entityId);
      const { contentEvent } = container ?? {};
      const { id } = contentEvent ?? {};
      contentEventId = id;
      hasContentEvent = Boolean(id);
    }

    if (type === 'targetSnippet') {
      const snippet = getSnippetById(localisedContentSectionGroups, entityId);
      const { contentEventId: snippetContentEventId } = snippet ?? {};
      contentEventId = snippetContentEventId;
      hasContentEvent = Boolean(snippetContentEventId);
    }

    return {
      contentEventId,
      hasContentEvent,
    }
  };

  const openActionsSidebar = (entityId: number, type: IEntityType) => {
    const { contentEventId, hasContentEvent } = getEntityContentEventDetails(entityId, type);
    const actionDetails: IActionsSidebarDetails = {
      type,
      contentEventId,
      hasContentEvent,
      id: entityId,
    }
    setActionsSidebarDetails(actionDetails);
    setIsActionsSidebarOpen(true);
  };

  const closeActionsSidebar = () => {
    setActionsSidebarDetails({} as IActionsSidebarDetails);
    setIsActionsSidebarOpen(false);
  };

  // Snippet
  const openSnippetSidebar = (config: ISnippetSidebarDetails) => {
    setSnippetSidebarDetails(config);
    setIsSnippetSidebarOpen(true);
  };

  const updateSnippetSidebarDetails = (config: ISnippetSidebarDetails) => {
    setSnippetSidebarDetails(config);
  };

  const closeSnippetSidebar = () => {
    setSnippetSidebarDetails({} as ISnippetSidebarDetails);
    setIsSnippetSidebarOpen(false);
  };

  const handleUpdateSnippet = (updatedSnippet: IContentSnippet) => {
    const updatedSectionGroups = contentSectionGroups.map((sectionGroup) => ({
      ...sectionGroup,
      contentSections: sectionGroup.contentSections.map((section) => ({
        ...section,
        sectionContainers: section.sectionContainers.map((container) => ({
          ...container,
          snippets: container.snippets.map((snippet) =>
            snippet.id === updatedSnippet.id ? updatedSnippet : snippet
          )
        }))
      }))
    }));
    setContentSectionGroups(updatedSectionGroups);
    setNavigationItems(formatContentSectionGroupsToNavigationItems({ sectionGroups: updatedSectionGroups, formId, isFormsMode }));
    void handleGetContentPage({
      contentPageId: Number(pageId)
    });
  };

  // Section
  const openSectionSidebar = (config: ISectionSidebarDetails) => {
    setSectionSidebarDetails(config);
    setIsSectionSidebarOpen(true);
  };

  const closeSectionSidebar = () => {
    setSectionSidebarDetails({} as ISectionSidebarDetails);
    setIsSectionSidebarOpen(false);
  };

  const handleUpdateSection = (updatedSection: IContentSection) => {
    const updatedSectionGroups = contentSectionGroups.map((sectionGroup) => ({
      ...sectionGroup,
      contentSections: sectionGroup.contentSections.map((section) => section.id === updatedSection.id ? updatedSection : section)
    }));

    setContentSectionGroups(updatedSectionGroups);
    setNavigationItems(formatContentSectionGroupsToNavigationItems({sectionGroups: updatedSectionGroups, formId, isFormsMode}));
    void handleGetContentPage({
      contentPageId: Number(pageId)
    });
  };

  // Container
  const openContainerSidebar = (config: IContainerSidebarDetails) => {
    setContainerSidebarDetails(config);
    setIsContainerSidebarOpen(true);
  };

  const closeContainerSidebar = () => {
    setContainerSidebarDetails({} as IContainerSidebarDetails);
    setIsContainerSidebarOpen(false);
  };

  const handleUpdateContainer = (updatedContainer: IContentSectionContainer) => {
    const updatedSectionGroups = contentSectionGroups.map((sectionGroup) => ({
      ...sectionGroup,
      contentSections: sectionGroup.contentSections.map((section) => ({
        ...section,
        sectionContainers: section.sectionContainers.map((container) => container.id === updatedContainer.id ? updatedContainer : container)
      }))
    }));


    setContentSectionGroups(updatedSectionGroups);
    setNavigationItems(formatContentSectionGroupsToNavigationItems({ sectionGroups: updatedSectionGroups, formId, isFormsMode }));
    void handleGetContentPage({
      contentPageId: Number(pageId)
    });
  };

  // Section groups
  const openSectionGroupSidebar = (config: ISectionGroupSidebarDetails) => {
    setSectionGroupSidebarDetails(config);
    setIsSectionGroupSidebarOpen(true);
  };

  const closeSectionGroupSidebar = () => {
    setSectionGroupSidebarDetails({} as ISectionGroupSidebarDetails);
    setIsSectionGroupSidebarOpen(false);
  };

  const handleUpdateSectionGroup = (updatedSectionGroup: ISectionGroup) => {
    const updatedSectionGroups = contentSectionGroups.map((sectionGroup) => sectionGroup.id === updatedSectionGroup.id ? updatedSectionGroup : sectionGroup)
    setContentSectionGroups(updatedSectionGroups);
    setNavigationItems(formatContentSectionGroupsToNavigationItems({ sectionGroups: updatedSectionGroups, formId, isFormsMode }));
  };

  // Shared
  const addItemToEntity = (entityId: number, type: IEntityType) => {
    if (type === 'section') {
      const section = getSectionById(localisedContentSectionGroups, entityId);
      const containerNumber = section?.sectionContainers.length ?? 0 ;
      openContainerSidebar({
        containerId: entityId,
        sort: containerNumber === 0 ? 0 : containerNumber + 1,
        containerNumber: containerNumber + 1,
        contentLayoutId: Number(section?.contentLayoutId),
      });
    };

    if (type === 'container') {
      const container = getContainerById(localisedContentSectionGroups, entityId);
      const snippetCount = container?.snippets.length ?? 0 ;

      openSnippetSidebar({
        containerId: entityId,
        sort: snippetCount === 0 ? 0 : snippetCount + 1,
      });
    };

    if (type === 'sectionGroup') {
      const sectionGroup = getSectionGroupById(localisedContentSectionGroups, entityId);
      const sectionsCount = sectionGroup?.contentSections.length ?? 0 ;

      openSectionSidebar({
        containerId: entityId,
        sort: sectionsCount === 0 ? 0 : sectionsCount + 1,
      });
    };
  };


  const openCreateEntitySidebar = (entityId: number, type: IEntityType) => {
    let entityType = type;
    let isFormSnippet = false;
    let parentId = findParentIdByIdAndType({
      id: entityId,
      type,
      sectionGroups: localisedContentSectionGroups,
      pageId: Number(contentPageId),
    });

    if (isFormsMode && entityType === 'targetSnippet') {
      // To allow the plus button to add a section to the formSnippet form contentPage instead of a new snippet
      const snippet = getSnippetById(contentSectionGroups, entityId);
      const { __typename: snippetType } = snippet ?? {};

      if (snippetType === SnippetTypeConst.FORM) {
        isFormSnippet = true;
        entityType = 'section'
        parentId = findParentIdByIdAndType({
          id: entityId,
          type,
          sectionGroups: contentSectionGroups,
          pageId: Number(pageId),
        });
      }
    }

    if (!parentId) return;

    if (entityType === 'targetSnippet') {
      const snippet = getSnippetById(isFormSnippet ? contentSectionGroups : localisedContentSectionGroups, entityId);

      openSnippetSidebar({
        containerId: parentId,
        sort: Number((snippet?.sort ?? 0) + 1),
      });
    };

    if (entityType === 'section') {
      const section = getSectionById(localisedContentSectionGroups, entityId);
      openSectionSidebar({
        containerId: parentId,
        sort: Number((section?.sort ?? 0) + 1)
      });
    };

    if (entityType === 'container') {
      const section = getSectionById(localisedContentSectionGroups, parentId);
      const container = getContainerById(localisedContentSectionGroups, entityId);
      const containerNumber = section?.sectionContainers.length ?? 0 ;
      openContainerSidebar({
        containerId: parentId,
        sort: Number((container?.sort ?? 0) + 1),
        containerNumber: containerNumber + 1,
        contentLayoutId: Number(section?.contentLayoutId),
      });
    };

    if (entityType === 'sectionGroup') {
      const sectionGroup = getSectionGroupById(localisedContentSectionGroups, entityId);
      openSectionGroupSidebar({
        containerId: parentId,
        sort: Number((sectionGroup?.sort ?? 0) + 1)
      });
    };

  };

  const openUpdateEntitySidebar = (entityId: number, type: IEntityType) => {
    let isFormSnippet = false;
    let parentId = findParentIdByIdAndType({
      id: entityId,
      type,
      sectionGroups: localisedContentSectionGroups,
      pageId: Number(contentPageId),
    });

    if (isFormsMode && type === 'targetSnippet') {
      // To allow the edit button to edit formSnippet
      const snippet = getSnippetById(contentSectionGroups, entityId);
      const { __typename: snippetType } = snippet ?? {};

      if (snippetType === SnippetTypeConst.FORM) {
        isFormSnippet = true;
        parentId = findParentIdByIdAndType({
          id: entityId,
          type,
          sectionGroups: contentSectionGroups,
          pageId: Number(pageId),
        });
      }
    }

    if (!parentId) return;

    if (type === 'targetSnippet') {
      const snippet = getSnippetById(isFormSnippet ? contentSectionGroups : localisedContentSectionGroups, entityId);
      openSnippetSidebar({
        containerId: parentId,
        snippetId: entityId,
        sort: Number(snippet?.sort ?? 0),
      });
    }

    if (type === 'section') {
      const section = getSectionById(localisedContentSectionGroups, entityId);
      openSectionSidebar({
        containerId: parentId,
        sort: Number(section?.sort ?? 0),
        sectionId: entityId,
      });
    }

    if (type === 'container') {
      const section = getSectionById(localisedContentSectionGroups, parentId);
      openContainerSidebar({
        containerId: parentId,
        id: entityId,
        contentLayoutId: Number(section?.contentLayoutId),
      });
    }

    if (type === 'sectionGroup') {
      const sectionGroup = getSectionGroupById(localisedContentSectionGroups, entityId);
      openSectionGroupSidebar({
        containerId: parentId,
        sort: Number(sectionGroup?.sort ?? 0),
        sectionGroupId: entityId,
      });
    }
  };

  const handleCreateEntity = () => {
    void handleGetContentPage({
      contentPageId: Number(pageId)
    });
  };


  const openDeleteEntityDialog = ({ id: entityId, type }: IDeleteEntity) => {
    setDeleteEntityDetails({
      entityId,
      type,
      isOpen: true
    });
  };

  const closeDeleteEntityDialog = () => {
    setDeleteEntityDetails(undefined);
  };

  const handleDeleteEntity = ({ id: entityId, type }: IDeleteEntity) => {

    if (type === 'targetSnippet') {
      handleDeleteSnippet({
        contentSnippetId: entityId,
      });
    }

    if (type === 'section') {
      handleDeleteSection({
        contentSectionId: entityId
      });
    }

    if (type === 'sectionGroup') {
      handleDeleteSectionGroup({
        id: entityId
      });
    }

    if (type === 'container') {
      const parentId = findParentIdByIdAndType({
        id: entityId,
        type: 'container',
        sectionGroups: localisedContentSectionGroups,
        pageId: Number(contentPageId),

      });

      const section = getSectionById(localisedContentSectionGroups, Number(parentId));

      handleDeleteContainer({
        containerId: entityId,
        // TODO: @ash check whether we need to send the current layout id or an updated on
        // contentLayoutId: getUpdatedLayoutId(section?.contentLayoutId),
        contentLayoutId: section?.contentLayoutId,
        contentSectionId: Number(parentId)

      });
    }
  };

  // Bulk actions

  const getEntityVisibility = (entity: IBulkActionEntity) => {

    let isVisible = false;
    let isFormSnippet = false;

    const { type, id } = entity;

    if (isFormsMode && type === 'SNIPPET') {
      // To allow the edit button to edit formSnippet
      const snippet = getSnippetById(contentSectionGroups, Number(id));
      const { __typename: snippetType } = snippet ?? {};

      if (snippetType === SnippetTypeConst.FORM) {
        isFormSnippet = true;
      }
    }

    if (type === 'SNIPPET') {
      const snippet = getSnippetById(isFormSnippet ? contentSectionGroups : localisedContentSectionGroups, Number(id));
      const { visible: isEntityVisible = false } = snippet ?? {};

      isVisible = isEntityVisible;
    }

    if (type === 'SECTION') {
      const section = getSectionById(localisedContentSectionGroups,  Number(id));

      const { visible: isEntityVisible = false } = section ?? {};

      isVisible = isEntityVisible;
    }

    if (type === 'CONTAINER') {
      const container = getContainerById(localisedContentSectionGroups, Number(id));
      const { visible: isEntityVisible = false } = container ?? {};

      isVisible = isEntityVisible;
    }

    if (type === 'SECTION_GROUP') {
      const sectionGroup = getSectionGroupById(localisedContentSectionGroups, Number(id));
      const { visible: isEntityVisible = false } = sectionGroup ?? {};

      isVisible = isEntityVisible;
    }

    return isVisible;
  };

  const openBulkActionDialog = (config: IBulkActionConfig) => {
    const { isIndividualEntity = false, selectedEntities} = config;
    let isVisible = false;
    if (isIndividualEntity) {
      const [ firstEntity ] = selectedEntities;
      isVisible = getEntityVisibility(firstEntity);
    }

    setBulkActionDetails({
      ...config,
      isOpen: true,
      isVisible,
    });
  };

  const closeBulkActionDialog = () => {
    setBulkActionDetails(undefined);
  };

  const handleBulkAction = () => {
    void handleGetContentPage({
      contentPageId: Number(pageId),
    });
  };

  // Forms mode
  const handleEnterFormsMode = (entityId: number) => {
    const snippet = getSnippetById(contentSectionGroups, entityId);
    const { form } = snippet ?? {};
    const { id, contentPages: formContentPages } = form ?? {};
    const [ formPage ] = formContentPages ?? [];
    const {id: snippetContentPageId, sectionGroups: snippetFormContentSectionGroups } = formPage;

    setFormContentPage(formPage);
    setCurrentFormSnippetId(entityId);
    setFormId(id);
    setIsFormsMode(true);
    setFormsPageId(snippetContentPageId);
    setFormContentSectionGroups(snippetFormContentSectionGroups as ISectionGroup[]);
    setNavigationItems(formatContentSectionGroupsToNavigationItems({ sectionGroups: contentSectionGroups, formId: id, isFormsMode: true }));
  };

  const handleLeaveFormsMode = () => {
    setFormId(undefined);
    setFormsPageId(undefined);
    setIsFormsMode(false);
    setFormContentSectionGroups([]);
    setFormContentPage(undefined);
    setNavigationItems(formatContentSectionGroupsToNavigationItems({ sectionGroups: contentSectionGroups }));
  };


  const handleUpdateFormSnippet = (updatedSnippet: IContentSnippet) => {
    const { __typename: type} = updatedSnippet;

    if (type === SnippetTypeConst.FORM) handleUpdateSnippet(updatedSnippet);

    const formSnippet = getSnippetById(contentSectionGroups, Number(currentFormSnippetId)) ?? {} as IContentSnippet;

    const updatedSectionGroups = formContentSectionGroups.map((sectionGroup) => ({
      ...sectionGroup,
      contentSections: sectionGroup.contentSections.map((section) => ({
        ...section,
        sectionContainers: section.sectionContainers.map((container) => ({
          ...container,
          snippets: container.snippets.map((snippet) =>
            snippet.id === updatedSnippet.id ? updatedSnippet : snippet
          )
        }))
      }))
    }));


    setFormContentSectionGroups(updatedSectionGroups);

    const updatedFormSnippet: IContentSnippet = {
      ...formSnippet,
      form: {
        ...formSnippet.form ?? {},
        contentPages: [
          {
            ...formSnippet.form?.contentPages[0] ?? {},
            sectionGroups: updatedSectionGroups,
          }
        ] as IDBContentPage[]
      } as IDBContentGroup
    };

    handleUpdateSnippet(updatedFormSnippet);
  };

  const handleUpdateFormSectionGroup = (updatedSectionGroup: ISectionGroup) => {
    const formSnippet = getSnippetById(contentSectionGroups, Number(currentFormSnippetId)) ?? {} as IContentSnippet;

    const updatedSectionGroups = formContentSectionGroups.map((sectionGroup) => sectionGroup.id === updatedSectionGroup.id ? updatedSectionGroup : sectionGroup);

    setFormContentSectionGroups(updatedSectionGroups);

    const updatedFormSnippet: IContentSnippet = {
      ...formSnippet,
      form: {
        ...formSnippet.form ?? {},
        contentPages: [
          {
            ...formSnippet.form?.contentPages[0] ?? {},
            sectionGroups: updatedSectionGroups,
          }
        ] as IDBContentPage[]
      } as IDBContentGroup
    };

    handleUpdateSnippet(updatedFormSnippet);

  };

  const handleUpdateFormSection = (updatedSection: IContentSection) => {
    const formSnippet = getSnippetById(contentSectionGroups, Number(currentFormSnippetId)) ?? {} as IContentSnippet;

    const updatedSectionGroups = formContentSectionGroups.map((sectionGroup) => ({
      ...sectionGroup,
      contentSections: sectionGroup.contentSections.map((section) => section.id === updatedSection.id ? updatedSection : section),
    }))

    setFormContentSectionGroups(updatedSectionGroups);

    const updatedFormSnippet: IContentSnippet = {
      ...formSnippet,
      form: {
        ...formSnippet.form ?? {},
        contentPages: [
          {
            ...formSnippet.form?.contentPages[0] ?? {},
            sectionGroups: updatedSectionGroups,
          }
        ] as IDBContentPage[]
      } as IDBContentGroup
    };

    handleUpdateSnippet(updatedFormSnippet);

  };

  const handleUpdateFormContainer = (updatedContainer: IContentSectionContainer) => {
    const formSnippet = getSnippetById(contentSectionGroups, Number(currentFormSnippetId)) ?? {} as IContentSnippet;

    const updatedSectionGroups = formContentSectionGroups.map((sectionGroup) => ({
      ...sectionGroup,
      contentSections: sectionGroup.contentSections.map((section) => ({
        ...section,
        sectionContainers: section.sectionContainers.map((container) => container.id === updatedContainer.id ? updatedContainer : container)
      }))
    }));

    setFormContentSectionGroups(updatedSectionGroups);

    const updatedFormSnippet: IContentSnippet = {
      ...formSnippet,
      form: {
        ...formSnippet.form ?? {},
        contentPages: [
          {
            ...formSnippet.form?.contentPages[0] ?? {},
            sectionGroups: updatedSectionGroups,
          }
        ] as IDBContentPage[]
      } as IDBContentGroup
    };

    handleUpdateSnippet(updatedFormSnippet);
  };


  // Exit edit mode
  const exitEditMode = () => {
    push(`${baseUrl}/${pageUrl}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };

  //  DnD 
  const onDrop = (data: DroppedData) => {
    const { draggedItem, target, updatedIndex} = data;

    const { type: draggedItemType, id: draggedItemId } = draggedItem;
    const { type: targetItemType, id: targetItemId } = target;

    if (
      (draggedItemType === 'targetSnippet' && targetItemType !== 'container') ||
      (draggedItemType === 'container' && targetItemType !== 'section') ||
      (draggedItemType === 'section' &&  targetItemType !== 'sectionGroup') ||
      (draggedItemType === 'sectionGroup' &&  targetItemType !== 'root')
    ) {
      handleToastCustomError({
        message: "whoops! You can't move this entity here",
      });

      return;
    }

    // move section group
    if (draggedItemType === 'sectionGroup' && targetItemType === 'root') {
      handleSortSectionGroup({
        id: Number(draggedItemId),
        sort: updatedIndex,
      });
    }


    // move section
    if (draggedItemType === 'section' && targetItemType === 'sectionGroup') {
      handleSortSection({
        contentSectionId: Number(draggedItemId),
        sectionGroupId: Number(targetItemId),
        sort: updatedIndex
      })

    }

    // move container
    if (draggedItemType === 'container' && targetItemType === 'section') {
      
      const section = getSectionById(localisedContentSectionGroups, Number(targetItemId));
      const { sectionContainers = [], contentLayoutId } = section ?? {};

      const isInSameSection = sectionContainers.some(({id: containerId }) => containerId === Number(draggedItemId));

      if (sectionContainers.length === 4 && !isInSameSection) {
        handleToastCustomError({
          message: "whoops! You can't move this container here, There is a limit of 4 containers per section",
        });
      }

      handleSortContainer({
        containerId: Number(draggedItemId),
        sectionId: Number(targetItemId),
        contentLayoutId: isInSameSection ? getUpdatedLayoutId(Number(contentLayoutId), 'add'): Number(contentLayoutId),
        isInSameSection,
        section: section ?? {} as IContentSection,
        sort: updatedIndex,
      });

    }

    // move snippet
    if (draggedItemType === 'targetSnippet' && targetItemType === 'container') {
      const snippet = getSnippetById(localisedContentSectionGroups, Number(draggedItemId));
      const { visible: isVisible = false } = snippet ?? {};
      handleSortSnippet({
        snippetId: Number(draggedItemId),
        containerId: Number(targetItemId),
        sort: updatedIndex,
        visible: isVisible,
      });
    }
  };

  useEffect(() => {
    if (!(contentGroupUrl && projectId)) return;

    handleGetContentPages({
      url: contentGroupUrl,
      projectId,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentGroupUrl, projectId, pageUrl]);

  useEffect(() => {
    if (!pageId) return;

    void handleGetContentPage({
      contentPageId: pageId
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  useEffect(() => {
    if (!queryProjectId) return;

    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    if (!(dashboardLink && contentGroupUrl)) return;
    setBaseUrl(`${dashboardLink}/alpha-content/${isForm ? 'form-builder': 'builder'}/${contentType.toLowerCase()}/${contentGroupUrl}`);
  }, [contentType, contentGroupUrl, dashboardLink, isForm])

  const isLoading =
    isGetContentPagesLoading ||
    isDeleteSnippetLoading ||
    isDeleteSectionLoading ||
    isDeleteContainerLoading ||
    isGetContentPageLoading ||
    isDeleteSectionGroupLoading ||
    isSortContainerLoading ||
    isSortSnippetLoading || 
    isSortSectionLoading ||
    isSortSectionGroupLoading;

  return (
    <ContentBuilderContext.Provider
      value={{
        // Actions
        actionsSidebarDetails,
        closeActionsSidebar,
        isActionsSidebarOpen,
        openActionsSidebar,

        // Base
        sectionGroups: contentSectionGroups,
        contentType,
        isLoading,
        contentGroupUrl,
        contentGroupId: isFormsMode ? formId: contentGroupId,
        isFormsMode,
        isFormBuilder: isForm,
        navigationItems,
        pageId: contentPageId,
        pageOptions,
        pageUrl,
        projectId,
        contentPage: isFormsMode ? formContentPage : contentPage,
        baseUrl,
        exitEditMode,
        contentPages,

        // Empty state
        isEmptyPage,
        firstSectionGroupId,

        // Bulk actions
        bulkActionDetails,
        closeBulkActionDialog,
        openBulkActionDialog,
        handleBulkAction,

        // Page
        openPageSidebar,
        closePageSidebar,
        isPageSidebarOpen,
        handleCreatePage,
        handleDeletePage,
        handleUpdatePage,
        pageSidebarDetails,

        // Section group
        closeSectionGroupSidebar,
        handleUpdateSectionGroup: isFormsMode ? handleUpdateFormSectionGroup : handleUpdateSectionGroup,
        isSectionGroupSidebarOpen,
        sectionGroupSidebarDetails,

        // Container
        closeContainerSidebar,
        handleUpdateContainer: isFormsMode ? handleUpdateFormContainer : handleUpdateContainer,
        isContainerSidebarOpen,
        containerSidebarDetails,

        // Section
        closeSectionSidebar,
        handleUpdateSection: isFormsMode ? handleUpdateFormSection : handleUpdateSection,
        isSectionSidebarOpen,
        sectionSidebarDetails,

        // Shared
        addItemToEntity,
        closeDeleteEntityDialog,
        deleteEntityDetails,
        handleCreateEntity,
        handleDeleteEntity,
        openCreateEntitySidebar,
        openDeleteEntityDialog,
        openUpdateEntitySidebar,

        // Snippet
        closeSnippetSidebar,
        handleUpdateSnippet: isFormsMode ? handleUpdateFormSnippet : handleUpdateSnippet,
        isSnippetSidebarOpen,
        snippetSidebarDetails,
        updateSnippetSidebarDetails,

        // Forms mode
        handleEnterFormsMode,
        handleLeaveFormsMode,

        // DnD
        onDrop,
      }}
    >
      {children}
    </ContentBuilderContext.Provider>
  );
}
