

import { useBonoboLazyQuery } from 'hooks';

import { EXPORT_REPORTED_POSTS } from './useGetReportedPostsReport.graphql';
import {
  IGetReportedPostsReportQueryGraphQLResponse,
  IGetReportedPostsReportQueryVariables,
  IHandleGetReportedPostsReportParams,
  IUseGetReportedPostsReport,
  IUseGetReportedPostsReportOptions,
} from './useGetReportedPostsReport.interfaces';

const useGetReportedPostsReport = (options?: IUseGetReportedPostsReportOptions): IUseGetReportedPostsReport => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: bonoboProjectId } = options ?? ({} as IUseGetReportedPostsReportOptions);

  const [executeGetReportedPostsReport, { loading: isLoading }] = useBonoboLazyQuery<
    IGetReportedPostsReportQueryGraphQLResponse,
    IGetReportedPostsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportReportPosts } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportReportPosts,
        });
      },
      onError,
    },
    query: query ?? EXPORT_REPORTED_POSTS,
    token,
  });


  const handleGetReportedPostsReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
  }: IHandleGetReportedPostsReportParams) => {
    await executeGetReportedPostsReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetReportedPostsReport,
    isLoading,
  };
};

export { useGetReportedPostsReport };
