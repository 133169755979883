import React, { useState, useRef, useEffect, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { ControlledForm, FormFieldProps, SidebarButtons, Spinner, TabSet, TabSetImperativeHandleRef, useControlledForm, SingleCheckbox, GeneralTabIcon, UsageIcon, FilmIcon, StyleTabIcon } from '@netfront/ui-library';
import { AnimationTab, EventTab, StyleTab, UpsertAssetSnippetGeneralTab, animationTabValidation, eventTabValidation, handleSelectAsset, hasAssetBeenUpdated } from 'components';
import { CachingEntitiesContext } from 'context';
import { 
  useUpsertAssetWrapper as useUpsertEventAssetWrapper, 
  useUpsertAssetWrapper, 
  useUpsertSnippet 
} from 'hooks';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getAssetSnippetCommonVariables, getAssetSnippetDefaultValues, setQuickAddAssetValues } from './UpsertAssetSnippetSidebarView.helpers';
import { UpsertAssetSnippetSidebarViewProps } from './UpsertAssetSnippetSidebarView.interfaces';


const UpsertAssetSnippetSidebarView = ({ containerId, onClose, onCreate, onDelete, onUpdate, snippet, sort, assetType, isLoading: isSnippetLoading = false, handleUpdateSortValue }: UpsertAssetSnippetSidebarViewProps) => {

  const { project } = useContext(CachingEntitiesContext);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const uploadedFileEventAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalEventImageRef = useRef<{value: boolean }>({ value: false });
  const uploadedFileAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);
  const [projectId, setProjectId] = useState<string>('');

  const { control, handleSubmit, reset, setValue, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        code: yup.string().when(['initialAssetId'], {
          is: (initialAssetId: string ) => !(Boolean(initialAssetId) || Boolean(uploadedFileAssetRef.current.value)),
          then: yup.string().label('Code').required()
        }),
        initialAssetId: yup.string().when(['code'], {
          is: (code: string) => !code && !uploadedFileAssetRef.current.value,
          then: yup.string().label('Video file').required()
        }),
        ...eventTabValidation,
        ...animationTabValidation,
      }, [['initialAssetId', 'code']]),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();
  const { handleUpsertAsset: handleUpsertEventAsset, isLoading: isUpsertEventAssetLoading = false } = useUpsertEventAssetWrapper();

  const { handleUpsertSnippet, isLoading: isUpsertSnippetLoading = false } = useUpsertSnippet({
    onCreate: () => {
      const { isCreateNew = false } = getValues();

      if (isCreateNew) {
        uploadedFileEventAssetRef.current.value = undefined;
        hasDeletedOriginalEventImageRef.current.value = false;
        uploadedFileAssetRef.current.value = undefined;
        handleUpdateSortValue(sort + 1);
        setDefaultValues(setQuickAddAssetValues(getValues(), assetType));
      } else {
        reset();
      }
      onCreate(isCreateNew as boolean);
    },
    onUpdate: (returnedSnippet) => {
      reset();
      onUpdate(returnedSnippet);
    }
  });


  const handleSelectAssetFromLibrary = ({ asset }: {asset: IDBAsset }) => {
    handleSelectAsset({asset, assetIdKey: 'initialAssetId', setValue });
    uploadedFileAssetRef.current.value = undefined;
  };

  const handleEventDropFile = (uploadedFile?: File) => {
    uploadedFileEventAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalEventImageRef.current.value = true;
  };


  const handleDropAssetFile = (uploadedFile?: File) => {
    uploadedFileAssetRef.current.value = uploadedFile;
  };

  const handleSave = (item: FormFieldProps) => {

    // event asset update
    handleUpsertEventAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(project?.id),
      uploadedFile: uploadedFileEventAssetRef.current.value,
      deletedFileId: hasDeletedOriginalEventImageRef.current.value && defaultValues.assetId ? defaultValues.assetId : undefined,
      callBack: (eventAsset?: IDBAsset) => {

        // snippet asset update
        handleUpsertAsset({
          assetType,
          isCreate: Boolean(uploadedFileAssetRef.current.value),
          uploadedFile: uploadedFileAssetRef.current.value,
          projectId: String(project?.id),
          assetIdKey: 'initialAssetId',
          hasPublicToggle: true,
          isPublic: item.isPublic,
          isInitiallyPublic: defaultValues.isPublic,
          updatedAsset: hasAssetBeenUpdated({ assetIdKey: 'initialAssetId', initialValues: defaultValues, currentValues: item }) ? item : undefined,
          item,
          callBack: (asset?: IDBAsset) => {
            const commonVariables = getAssetSnippetCommonVariables({
              assetType,
              assetId: asset ? String(asset.assetId) : item.initialAssetId,
              eventAssetId: eventAsset ? String(eventAsset.assetId): undefined,
              item,
            });

            handleUpsertSnippet({
              containerId,
              snippetId: snippet? Number(snippet.id): undefined,
              sort,
              variables: commonVariables,
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    if (!project) return;

    setProjectId(project.id);
  }, [project]);

  useEffect(() => {
    setDefaultValues(getAssetSnippetDefaultValues({ snippet, assetType }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet]);

  const isLoading =
    isUpsertSnippetLoading ||
    isUpsertEventAssetLoading ||
    isUpsertAssetLoading ||
    isSnippetLoading;
  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
        <TabSet
          ref={tabsetRef}
          defaultActiveTabId="id_general_tab"
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => isLoading ? <></> : (
                <UpsertAssetSnippetGeneralTab
                  assetType={assetType}
                  control={control as Control<FormFieldProps>}
                  defaultValues={defaultValues}
                  initialView={defaultValues.view}
                  isCreate={!snippet}
                  projectId={projectId}
                  setValue={setValue}
                  onChooseFromLibrary={handleSelectAssetFromLibrary}
                  onDrop={handleDropAssetFile}
                  />
              ),
            },
            {
              icon: UsageIcon,
              id: 'id_event_tab',
              label: 'Event',
              view: () => isLoading ? <></> : (
                <EventTab
                  control={control as Control<FormFieldProps>}
                  initialEvent={defaultValues.event}
                  onDeleteAsset={handleRemoveAsset}
                  onDrop={handleEventDropFile}
                />
              ),
            },
            {
              icon: FilmIcon,
              id: 'id_animation_tab',
              label: 'Animation',
              view: () => isLoading ? <></> : (
                <AnimationTab
                  animationType={defaultValues.animation ?? ''}
                  control={control as Control<FormFieldProps>}
                />
              ),
            },
            {
              icon: StyleTabIcon,
              id: 'id_style_tab',
              label: 'Style',
              view: () => isLoading ? <></> : (
                <StyleTab
                  control={control as Control<FormFieldProps>}
                  entityType="targetSnippet"
                />
              ),
            },
          ]}
        />
        <SidebarButtons
          additionalButton={!snippet ? (
            <Controller
              control={control as Control<FormFieldProps>}
              name="isCreateNew"
              render={({ field }) => (
                <SingleCheckbox
                  hasPadding={false}
                  id="id_is_create_new"
                  isChecked={field.value}
                  labelText="Create another snippet"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          ) : undefined
          }
          onCancel={onClose}
          onDelete={onDelete}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </>
  );
};


export { UpsertAssetSnippetSidebarView };
