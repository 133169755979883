import { gql } from '@apollo/client';

const GET_APP_VERSION_DETAILS = gql`
  query getMyAppVersionDetailsRequest($inputType: GetAppVersionDetailsInputType!) {
    app {
      getAppVersionDetails(inputType: $inputType) {
        version
        id
        appId
        status
        publishStatus
        app {
          apiKey
          id
          identifier
          title
          logoId
          # logo {
          #   s3Key
          #   contentType
          # }
        }
        components {
          files {
            location {
              fileName
              uploaded
              assetId
            }
            id
            fileType
            locationId
          }
          id
          title
        }
      }
    }
  }
`;

export { GET_APP_VERSION_DETAILS };
