import { gql } from '@apollo/client';
import { AppFragment, AudioFragment, ButtonFragment, CodeFragment, DocumentFragment, ContentSnippetSliderFragment, EmbedFragment, FormFragment, ImageFragment, MapFragment, QuestionFragment, QuoteFragment, RegionsFragment, SliderFragment, SpacerFragment, SummaryFragment, TextFragment, VideoFragment } from 'fragments';


const GET_CONTENT_SNIPPET_DETAILS = gql`
  query getSnippetDetails($contentSnippetId: Int!) {
    contentSnippet {
      getContentSnippet(contentSnippetId: $contentSnippetId) {
        id
        sort
        visible
        title
        cssStyle {
          id
          name
        }
        backgroundColor
        borderColor
        ... on ContentSnippetAvatarType {
          avatar {
            configuration {
              key
              value
            }
            imageUrl
          }
        }
        contentEventId
        contentEvent {
          animation
          animationDirection
          entityId
          event
          eventDirection
          eventTitle
          infinite
          millisecondDelay
          millisecondSpeed
          asset {
            assetId
            contentType
            s3Key
            presignedUrl
          }
          backgroundColor
          borderColor
          titleColor
          style
          triggerStyle
          iconHorizontalAlignment
          isDefaultOpen
        }
        borderColor
        backgroundColor
        type: __typename
        __typename
        ...app
        ...audio
        ...button
        ...code
        ...document
        ...embed
        ...form
        ...image
        ...map
        ...question
        ...quote
        ...regions
        ...slider
        ...spacer
        ...summary
        ...text
        ...video
      }
    }
  }
  ${AppFragment}
  ${AudioFragment}
  ${ButtonFragment}
  ${CodeFragment}
  ${ContentSnippetSliderFragment}
  ${DocumentFragment}
  ${EmbedFragment}
  ${FormFragment}
  ${ImageFragment}
  ${MapFragment}
  ${QuestionFragment}
  ${QuoteFragment}
  ${RegionsFragment}
  ${SliderFragment}
  ${SpacerFragment}
  ${SummaryFragment}
  ${TextFragment}
  ${VideoFragment}
`;

export { GET_CONTENT_SNIPPET_DETAILS };
