import { IContentPage } from "@netfront/ekardo-content-library";

const flattenCartoonContentPages = (contentPages: IContentPage[]) => {
  return contentPages.reduce((acc, contentPage) => {
    // Add the parent item
    acc.push(contentPage);

    const { contentPageChildren = []} = contentPage;

    // Add the child items if they exist
    if (contentPageChildren.length > 0) {
      contentPageChildren.forEach(child => {
        acc.push(child);
      });
    }

    return acc;
  }, [] as IContentPage[]);
};

export { flattenCartoonContentPages };