/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { IDBAppRating } from "interfaces";

const getAppCommentsDefaultValues = (selectedComment?: IDBAppRating): FormFieldProps => {
  const {
    title,
    rate,
    comment,
  } = selectedComment ?? {};

  return {
    title: title || '',
    rate: rate || 0,
    comment: comment || '',
  };
};


export { getAppCommentsDefaultValues };