import { ChangeEvent, useState } from 'react';

import { IContentGroup } from '@netfront/ekardo-content-library';
import {
  ImageUpload,
  Input,
  SidebarButtons,
  SingleDatePicker,
  Spacing,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';

import { ContentGroupAdvancedTabProps } from './ContentGroupAdvancedTab.interfaces';

const ContentGroupAdvancedTab = ({
  contentGroup,
  onUpdateInput,
  onSave,
  onCancel,
  onDelete,
  onDrop,
  onDeleteAsset,
  isLoading = false,
}: ContentGroupAdvancedTabProps) => {

  const [currentContentGroup, setCurrentContentGroup] = useState<IContentGroup | undefined>(contentGroup);

  const {
    estimatedTime = '',
    releaseDate,
    iconAsset,
  } = currentContentGroup ?? {};

  const { presignedUrl } = iconAsset ?? {};




  const handleUpdateInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = event;

    onUpdateInput({name, value});

    setCurrentContentGroup(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IContentGroup),
    );
  };

  const handleDropFile = (uploadedFile?: File) => {
    onDrop(uploadedFile);
  };

  return (
    <SidebarContainer>
      <Spacing size="2x-large">
        <Input
          id="id_estimated_time"
          isLoading={isLoading}
          labelText="Estimated time (minutes)"
          name="estimatedTime"
          type="number"
          value={estimatedTime}
          isLabelSideBySide
          onChange={handleUpdateInput}
        />
      </Spacing>

      <Spacing size="2x-large">
        <SingleDatePicker 
          config={{
            maxDate: new Date(2030, 0, 1),
            minDate: new Date(2020, 0, 1),
          }}
          dateInputProps={{
            labelText: 'Release Date',
            id: 'id_release_date',
            name: 'releaseDate',
            type: 'text',
            isLabelSideBySide: true,
          }}
          selectedDates={releaseDate ? [new Date(releaseDate)] : undefined} 
          onSelected={(dates: Date[]) => {
            onUpdateInput({name: 'releaseDate', value: dates[0] ? dates[0].toISOString() : undefined});

            setCurrentContentGroup(
              (currentState) =>
                ({
                  ...currentState,
                  releaseDate: dates[0] ? dates[0].toISOString() : undefined,
                } as IContentGroup),
            );
          }} 
        />
      </Spacing>

      <Spacing size="2x-large">
        <ImageUpload
          id="id_image_upload"
          initialUrl={presignedUrl}
          isLoading={isLoading}
          labelText="Thumbnail"
          name="upload_image"
          isLabelSideBySide
          onDelete={onDeleteAsset}
          onDrop={handleDropFile}
        />
      </Spacing>

      <SidebarButtons
        buttonSize="xs"
        isDeleteButtonDisabled={isLoading}
        isSaveButtonDisabled={isLoading}
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={onSave}
        onSaveButtonType="button"
      />

    </SidebarContainer>
  );
};

export { ContentGroupAdvancedTab };
