import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_APP_COMPONENT_MUTATION } from './useUpdateAppComponent.graphql';
import {
  IUpdateAppComponentMutationGraphQLResponse,
  IUpdateAppComponentMutationVariables,
  IHandleUpdateAppComponentParams,
  IUseUpdateAppComponentOptions,
  IUseUpdateAppComponent,
} from './useUpdateAppComponent.interfaces';

const useUpdateAppComponent = (options?: IUseUpdateAppComponentOptions): IUseUpdateAppComponent => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateAppComponentOptions);

  const [executeUpdateAppComponent, { loading: isLoading }] = useEkardoMutation<
  IUpdateAppComponentMutationGraphQLResponse,
  IUpdateAppComponentMutationVariables
  >({
    mutation: mutation ?? UPDATE_APP_COMPONENT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            appVersionMutation: {
              appVersionComponentMutation: {
                updateComponentTitle: isCompleted
              }
            }
          }
        } = data;
        
        void onCompleted({ isCompleted });
      },
      onError,
    },
    token,
  });

  const handleUpdateAppComponent = ({
    componentId,
    title,
  }: IHandleUpdateAppComponentParams) => {
    void executeUpdateAppComponent({
      variables: {
        inputType: {
          componentId,
          title,
        },
      },
    });
  };

  return {
    handleUpdateAppComponent,
    isLoading,
  };
};

export { useUpdateAppComponent };
