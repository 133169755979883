import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_APP_VERSION_DETAILS } from './useGetAppVersionDetails.graphql';
import {
  IGetAppVersionDetailsQueryGraphQLResponse,
  IGetAppVersionDetailsQueryVariables,
  IHandleGetAppVersionDetailsParams,
  IUseGetAppVersionDetails,
  IUseGetAppVersionDetailsOptions,
} from './useGetAppVersionDetails.interfaces';

const useGetAppVersionDetails = (options?: IUseGetAppVersionDetailsOptions): IUseGetAppVersionDetails => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetAppVersionDetailsOptions);

  const [executeGetAppVersionDetails, { loading: isLoading }] = useEkardoLazyQuery<IGetAppVersionDetailsQueryGraphQLResponse, IGetAppVersionDetailsQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            getAppVersionDetails: version
          }
        } = data;

        void onCompleted({ version });
      },
      onError,
    },
    query: query ?? GET_APP_VERSION_DETAILS,
    token,
  });

  const handleGetAppVersionDetails = ({ versionId }: IHandleGetAppVersionDetailsParams) => {
    void executeGetAppVersionDetails({
      variables: {
        inputType: {
          versionId,
        },
      },
    });
  };

  return {
    handleGetAppVersionDetails,
    isLoading,
  };
};

export { useGetAppVersionDetails };
