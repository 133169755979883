import React, { useState, useRef, useEffect, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { ControlledForm, FilmIcon, FormFieldProps, GeneralTabIcon, SidebarButtons, SingleCheckbox, Spinner, StyleTabIcon, TabSet, TabSetImperativeHandleRef, UsageIcon, useControlledForm } from '@netfront/ui-library';
import { AnimationTab, EventTab, UpsertQuestionSnippetGeneralTab, animationTabValidation, eventTabValidation, StyleTab } from 'components';
import { CachingEntitiesContext } from 'context';
import { useCreateFormSnippetWrapper, useUpsertAssetWrapper, useUpsertQuestionSnippet } from 'hooks';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getQuestionSnippetCommonVariables, getQuestionSnippetDefaultValues, setQuickAddQuestionValues } from './UpsertQuestionSnippetSidebarView.helpers';
import { UpsertQuestionSnippetSidebarViewProps } from './UpsertQuestionSnippetSidebarView.interfaces';

import { responseTypeToUnderScoredConst } from '../../../../../constants';

const UpsertQuestionSnippetSidebarView = ({ containerId, onClose, onCreate, onDelete, onUpdate, snippet, sort, isLoading: isSnippetLoading = false, questionType, handleUpdateSortValue }: UpsertQuestionSnippetSidebarViewProps) => {

  const { project } = useContext(CachingEntitiesContext);

  const [projectId, setProjectId] = useState<string>('');
  const [responseType, setResponseType] = useState<string>('');
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const droppedFileEventAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { control, handleSubmit, reset, setValue, getValues, watch } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        question: yup.string().label('Question').required(),
        behavior: yup.string().label('Behavior').required(),
        resType: yup.string().label('Response type').required(),
        maxCheckedResponse: yup.number().when(['resType'], {
          is: (resType: string) => resType === responseTypeToUnderScoredConst.Checkbox,
          then: yup.number().label('Max checked responses').required()
        }),
        responseSetId: yup.number().when(['resType'], {
          is: (resType: string) => [
            responseTypeToUnderScoredConst.DropDownList,
            responseTypeToUnderScoredConst.Match,
            responseTypeToUnderScoredConst.Radio,
            responseTypeToUnderScoredConst.Checkbox,
            responseTypeToUnderScoredConst.Slider,
          ].includes(resType),
          then: yup.number().label('Response set').required()
        }),
        minResponse: yup.number().when(['resType'], {
          is: (resType: string) => resType === responseTypeToUnderScoredConst.MultiResponseText,
          then: yup.number().label('Minimum responses').required()
        }),
        maxResponse: yup.number().when(['resType'], {
          is: (resType: string) => resType === responseTypeToUnderScoredConst.MultiResponseText,
          then: yup.number().label('Maximum responses').required()
        }),
        maxValue: yup.number().when(['resType'], {
          is: (resType: string) => resType === responseTypeToUnderScoredConst.Number,
          then: yup.number().label('Max value').required()
        }),
        minValue: yup.number().when(['resType'], {
          is: (resType: string) => resType === responseTypeToUnderScoredConst.Number,
          then: yup.number().label('Min value').required()
        }),
        maxLength: yup.number().when(['resType'], {
          is: (resType: string) => [
            responseTypeToUnderScoredConst.SingleText,
            responseTypeToUnderScoredConst.MultiText,
          ].includes(resType),
          then: yup.number().label('Max length').required()
        }),
        minLength: yup.number().when(['resType'], {
          is: (resType: string) => [
            responseTypeToUnderScoredConst.SingleText,
            responseTypeToUnderScoredConst.MultiText,
          ].includes(resType),
          then: yup.number().label('Min length').required()
        }),
        ...eventTabValidation,
        ...animationTabValidation,
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };


  const { handleUpsertQuestionSnippet, isLoading: isUpsertQuestionSnippetLoading = false } = useUpsertQuestionSnippet({
    projectId,
    onCreate: () => {
      const { isCreateNew = false } = getValues();

      if (isCreateNew) {
        droppedFileEventAssetRef.current.value = undefined;
        hasDeletedOriginalImageRef.current.value = false;
        handleUpdateSortValue(sort + 1);
        setDefaultValues(setQuickAddQuestionValues(getValues()));
      } else {
        reset();
      }
      onCreate(isCreateNew as boolean);
    },
    onUpdate: (returnedSnippet) => {
      reset();
      onUpdate(returnedSnippet);
    }
  });

  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();

  const { handleCreateFormSnippetWrapper, isLoading: isUpsertFormLoading = false } = useCreateFormSnippetWrapper();

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileEventAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };

  const handleSave = (item: FormFieldProps) => {
    const { assetId, isCreateForm = false, isVisible = false } = item;

    handleCreateFormSnippetWrapper({
      item: {
        containerId,
        projectId: String(project?.id),
        sort,
        isVisible,
      },
      isCreateForm,
      callBack: (returnedContainerId?: number) => {
        handleUpsertAsset({
          assetType: 'image',
          isCreate: true,
          projectId: String(project?.id),
          uploadedFile: droppedFileEventAssetRef.current.value,
          deletedFileId: hasDeletedOriginalImageRef.current.value && assetId ? assetId : undefined,
          callBack: (asset?: IDBAsset) => {
            handleUpsertQuestionSnippet({
              containerId: returnedContainerId ?? containerId,
              snippet,
              variables: getQuestionSnippetCommonVariables({item, eventAssetId: asset ? String(asset.assetId): undefined}),
              sort: returnedContainerId ? 0: sort,
            });
          }
        });
      },
    });

  };

  useEffect(() => {
    setDefaultValues(getQuestionSnippetDefaultValues({ snippet, questionType }));
    if (snippet) {
      setResponseType(responseTypeToUnderScoredConst[String(snippet.configuration?.__typename)]);
    } else {
      setResponseType(questionType ? responseTypeToUnderScoredConst[questionType]: '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet, questionType]);

  useEffect(() => {
    if (!project) return;
    setProjectId(project.id);
  }, [project]);

  const isLoading =
    isUpsertQuestionSnippetLoading ||
    isUpsertAssetLoading ||
    isSnippetLoading ||
    isUpsertFormLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
        <TabSet
          ref={tabsetRef}
          defaultActiveTabId="id_general_tab"
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => isLoading ? <></> : (
                <UpsertQuestionSnippetGeneralTab
                  control={control as Control<FormFieldProps>}
                  isNewQuestion={Boolean(!snippet)}
                  responseType={responseType}
                  setValue={setValue}
                />
              ),
            },
            {
              icon: UsageIcon,
              id: 'id_event_tab',
              label: 'Event',
              view: () => isLoading ? <></> : (
                <EventTab
                  control={control as Control<FormFieldProps>}
                  initialEvent={defaultValues.event}
                  onDeleteAsset={handleRemoveAsset}
                  onDrop={handleDropFile}
                />
              ),
            },
            {
              icon: FilmIcon,
              id: 'id_animation_tab',
              label: 'Animation',
              view: () => isLoading ? <></> : (
                <AnimationTab
                  animationType={defaultValues.animation ?? ''}
                  control={control as Control<FormFieldProps>}
                />
              ),
            },
            {
              icon: StyleTabIcon,
              id: 'id_style_tab',
              label: 'Style',
              view: () => isLoading ? <></> : (
                <StyleTab
                  control={control as Control<FormFieldProps>}
                  entityType="targetSnippet"
                  initialQuestionType={defaultValues.resType}
                  watch={watch}
                />
              ),
            },
          ]}
        />
        <SidebarButtons
          additionalButton={!snippet ? (
            <Controller
              control={control as Control<FormFieldProps>}
              name="isCreateNew"
              render={({ field }) => (
                <SingleCheckbox
                  hasPadding={false}
                  id="id_is_create_new"
                  isChecked={field.value}
                  labelText="Create another snippet"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          ) : undefined
          }
          onCancel={onClose}
          onDelete={onDelete}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </>
  );
};


export { UpsertQuestionSnippetSidebarView };
