import React, { useEffect, useState } from 'react';

import { IDBAsset } from '@netfront/common-library';
import { Breadcrumbs, HollowDownloadIcon, HomeIcon, IconRadioGroup, LibraryIcon, SidebarDialog, Spacing } from '@netfront/ui-library';
import { AssetGeneralTab, ChooseAssetFromLibrary, SidebarContainer } from 'components';
import capitalize from 'lodash.capitalize';
import { Controller } from 'react-hook-form';


import { UpsertPageAudioTabProps } from './UpsertPageAudioTab.interfaces';


const UpsertPageAudioTab = ({
  defaultValues,
  setValue,
  control,
  onDrop,
  onChooseFromLibrary,
  initialView = 'selector',
  isCreate = false,
  projectId,
}: UpsertPageAudioTabProps) => {

  const [view, setView] = useState<string>('selector');
  const [isChooseFromLibraryDialogOpen, setIsChooseFromLibraryDialogOpen] = useState<boolean>(false);
  const [hasChosenFromLibrary, setHasChosenFromLibrary] = useState<boolean>(false);

  const handleSelectAssetFromLibrary = ({ asset }: { asset: IDBAsset }) => {
    setView('upload');
    setIsChooseFromLibraryDialogOpen(false);
    setHasChosenFromLibrary(true);
    onChooseFromLibrary({ asset });
  };

  const cancelChooseFromLibrary = () => {
    setView('selector');
    setHasChosenFromLibrary(false);
    setIsChooseFromLibraryDialogOpen(false);
  };

  useEffect(() => {
    setView(initialView);
  }, [initialView])

  useEffect(() => {
    if (view !== 'library') return;
    setIsChooseFromLibraryDialogOpen(true);
  }, [view]);


  return (
    <>
      { view === 'selector' && (
        <SidebarContainer>
          <Controller
            control={control}
            name="view"
            render={({ field }) => (
              <IconRadioGroup
                id="new_snippet_type_other"
                items={[
                  {
                    icon: HollowDownloadIcon,
                    label: 'Upload',
                    value: 'upload',
                  },
                  {
                    icon: LibraryIcon,
                    label: `Audio library`,
                    value: 'library',
                  },
                ]}
                label="Type"
                name={field.name}
                selectedValue={view}
                hasLabels
                isLabelHidden
                isOutlined
                onChange={(value) => {
                  field.onChange(value);
                  setView(value);
                }}
              />
            )}
          />
        </SidebarContainer>
      )}
      { view === 'upload' && (
        <>
          <SidebarContainer hasPaddingEnds={false}>
            <Spacing size="2x-small">
              <Breadcrumbs
                itemDivider="chevron"
                items={[
                  {
                    icon: HomeIcon,
                    key: "audio",
                    onClick: () => {
                      setView('selector');
                      setHasChosenFromLibrary(false);
                    },
                    content: 'Audio',
                  },
                  {
                    key: 'view',
                    content: <span className="c-breadcrumb-item__link">{hasChosenFromLibrary ? 'Audio Library' : capitalize(view)}</span>
                  }
                ]}
                isContained
              />
            </Spacing>
          </SidebarContainer>
          <AssetGeneralTab
            asset={defaultValues.asset}
            assetId={defaultValues.assetId ?? ''}
            assetType="audio"
            control={control}
            initialFileName={defaultValues.fileName ?? ''}
            initialIsDownloadAvailable={defaultValues.isDownloadAvailable ?? ''}
            isInContentBuilder={!isCreate || hasChosenFromLibrary}
            setValue={setValue}
            onDrop={onDrop}
          />
        </>
      )}

      {view === 'library' && (
        <SidebarDialog
          isOpen={isChooseFromLibraryDialogOpen}
          onClose={cancelChooseFromLibrary}
        >
          {isChooseFromLibraryDialogOpen && (
            <>
              <SidebarContainer hasPaddingEnds={false}>
                <Spacing size="2x-small">
                  <Breadcrumbs
                    itemDivider="chevron"
                    items={[
                      {
                        icon: HomeIcon,
                        key: "audio",
                        onClick: () => {
                          setView('selector');
                          setHasChosenFromLibrary(false);
                        },
                        content: 'Audio',
                      },
                      {
                        key: 'view',
                        content: <span className="c-breadcrumb-item__link">{hasChosenFromLibrary ? 'Audio Library' : capitalize(view)}</span>
                      }
                    ]}
                    isContained
                  />
                </Spacing>
              </SidebarContainer>
              <ChooseAssetFromLibrary
                assetType="audio"
                projectId={projectId}
                onCancel={cancelChooseFromLibrary}
                onSave={handleSelectAssetFromLibrary}
              />
            </>

          )}
        </SidebarDialog>
      )}
    </>
  );
};

export { UpsertPageAudioTab };
