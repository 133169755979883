import { gql } from '@apollo/client';

const GET_ALL_PENDING_APP_VERSIONS = gql`
  query GET_ALL_APPS {
    app {
      getPendingAppVersions {
        app {
          id
          description
          identifier
          title
          averageRateValue
        }
        id
        appId
        version
      }
    }
  }
`;


export { GET_ALL_PENDING_APP_VERSIONS };
