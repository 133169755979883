import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { DBAssetTypeType, IAssetTag, IDBAsset } from '@netfront/common-library';
import { CaptionsIcon, ControlledForm, Dialog, FormFieldProps, GeneralTabIcon, RelatedTabIcon, SideBarTabSet, Spinner, TabSetImperativeHandleRef, UsageIcon, useControlledForm } from '@netfront/ui-library';
import { AssetGeneralTab, AssetTagsTab , AssetSidebarCaptionsView, AssetSidebarUsageView} from 'components';
import { useCreateAsset, useDeleteAsset, useToast, useToggleAssetPublic, useUpdateAsset } from 'hooks';
import capitalize from 'lodash.capitalize';
import { Control, FieldErrors } from 'react-hook-form';
import { pushImageToAws } from 'utils';
import * as yup from 'yup';


import { getAssetDefaultValues } from './AssetListSidebarView.helpers';
import { AssetListSidebarViewProps } from './AssetListSidebarView.interfaces';


const AssetListSidebarView = ({
  asset,
  assetType,
  handleSideBarClose,
  isSideBarOpen = false,
  onCreate,
  onDelete,
  onUpdate,
  organisationKey,
  projectId,
}: AssetListSidebarViewProps) => {
  const { handleToastError, handleToastSuccess } = useToast();
  const droppedFileRef = useRef<{value: File | undefined}>({ value: undefined });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isAssetUploadToAwsLoading, setIsAssetUploadToAwsLoading] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);


  const { control, handleSubmit, reset, setValue, getValues} = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required().label('Title'),
        alt: yup.string().when([], {
          is: () => assetType === 'image',
          then: yup.string().label('Alt text').required()
        }),
        presignedUrl: yup.string().when([], {
          is: (presignedUrl: string) => !presignedUrl && !droppedFileRef.current.value,
          then: yup.string().label(`${capitalize(assetType)} file`).required()
        }),
      }),
    ),
  });


  const { handleDeleteAsset, isLoading: isDeleteAssetLoading = false } = useDeleteAsset({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      reset();

      if (!asset) {
        return;
      }

      handleToastSuccess({ message: `${capitalize(assetType)} deleted successfully`});

      onDelete(asset.assetId);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleToggleAssetPublic, isLoading: isToggleAssetPublicLoading = false } = useToggleAssetPublic({
    onCompleted: (data ) => {
      onUpdate(data);
      handleToastSuccess({ message: `${capitalize(assetType)} updated successfully`});
      reset();
      setIsAssetUploadToAwsLoading(false);
    }, 
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleCreateAsset, isLoading: isCreateAssetLoading = false } = useCreateAsset({
    onCompletedAsync: async (data) => {

      const { signedUrl, assetId } = data;

      setIsAssetUploadToAwsLoading(true);

      await pushImageToAws(signedUrl, droppedFileRef.current.value, () => {
        const { isPublic = false } = getValues();

        if (isPublic) {
          handleToggleAssetPublic({
            assetId,
          });
        } else {
          handleToastSuccess({ message: `${capitalize(assetType)} created successfully`});
          onCreate(data);
          reset();
          setIsAssetUploadToAwsLoading(false);
        }
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateAsset, isLoading: isUpdateAssetLoading = false } = useUpdateAsset({
    onCompletedAsync: async (data) => {
      const { signedUrl, assetId } = data;

      if (!droppedFileRef.current.value) {
        const { isPublic = false } = getValues();

        if (isPublic !== defaultValues.isPublic) {
          handleToggleAssetPublic({
            assetId,
          });
        } else {
          handleToastSuccess({ message: `${capitalize(assetType)} updated successfully`});
          reset();
          onUpdate(data);
        }

        return;
      }

      setIsAssetUploadToAwsLoading(true);

      await pushImageToAws(String(signedUrl), droppedFileRef.current.value, () => {
        const { isPublic = false } = getValues();

        if (isPublic !== defaultValues.isPublic) {
          handleToggleAssetPublic({
            assetId,
          });
        } else {
          onUpdate(data);
          handleToastSuccess({ message: `${capitalize(assetType)} updated successfully`});
          setIsAssetUploadToAwsLoading(false);
        }
        
      });

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileRef.current.value = uploadedFile;
  };

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const handleSaveAsset = (item: FormFieldProps) => {
    const {
      assetId = '',
      description,
      alt = '',
      title,
      tagList = [],
      contentType,
      fileName = '',
    } = item;

    const {
      name = '',
      type = '',
      size = 0,
    } = droppedFileRef.current.value ?? {};

    if (!asset) {
      if (!droppedFileRef.current.value || !title) return;
      handleCreateAsset({
        projectId,
        fileName: name,
        contentType: type,
        description,
        alt,
        tagList: (tagList as IAssetTag[]).map(({ name: tagName }: { name: string}) => tagName),
        imageSize: assetType === 'image' ? 'SMALL' : undefined,
        type: assetType.toUpperCase() as DBAssetTypeType, // 'AUDIO' | 'DOCUMENT' | 'VIDEO' | 'IMAGE',
        fileSizeInBytes: size,
        title,
      });
    } else {
      handleUpdateAsset({
        assetId: String(assetId),
        alt,
        contentType: droppedFileRef.current.value ? type : contentType,
        description,
        fileName: droppedFileRef.current.value ? name : String(fileName),
        fileSizeInBytes: droppedFileRef.current.value ? size : undefined,
        imageSize: assetType === 'image' ? 'SMALL' : undefined,
        tagIds: (tagList as IAssetTag[]).map(({id}: {id: number}) => id),
        title,
      });
    }
  };

  useEffect(() => {
    setDefaultValues(getAssetDefaultValues({ asset, assetType }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, isSideBarOpen]);

  const isLoading = isAssetUploadToAwsLoading || isDeleteAssetLoading || isUpdateAssetLoading || isCreateAssetLoading || isToggleAssetPublicLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete ${assetType}: ${asset?.title ? String(asset.title) : String(asset?.fileName)}?`}
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!asset?.assetId) {
            return;
          }

          handleDeleteAsset({
            assetId: asset.assetId,
          });
        }}
      />

      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSaveAsset(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >

        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={handleSideBarClose}
          hasViewPadding={false}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () =>
                <AssetGeneralTab
                  asset={asset}
                  assetId={defaultValues.assetId ?? ''}
                  assetType={assetType}
                  control={control as Control<FormFieldProps>}
                  initialFileName={defaultValues.fileName ?? ''}
                  initialIsDownloadAvailable={defaultValues.isDownloadAvailable ?? ''}
                  setValue={setValue}
                  onDrop={handleDropFile}
                /> ,
            },
            {
              icon: RelatedTabIcon,
              id: 'id_tag_tab',
              label: 'Tags',
              view: () =>
                <AssetTagsTab
                  initialTagList={defaultValues.tagList}
                  projectId={projectId}
                  onUpdateSelectedTags={(tags: IDBAsset['tagList']) => setValue('tagList', tags)}
                />,
            },
            {
              icon: CaptionsIcon,
              id: 'id_captions_tab',
              label: 'Captions',
              isHidden: !['audio', 'video'].includes(assetType),
              view: () =>
                <AssetSidebarCaptionsView
                  assetId={defaultValues.assetId}
                  assetType={assetType}
                />,
            },
            {
              icon: UsageIcon,
              id: 'id_usage_tab',
              label: 'Usage',
              isHidden: !asset,
              view: () => (
                <AssetSidebarUsageView
                  assetId={defaultValues.assetId}
                  assetType={assetType}
                  organisationKey={organisationKey}
                  projectId={String(projectId)}

                />
              ),
            },
          ]}
          onDelete={asset ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"

        />
      </ControlledForm>
    </>
  );
};

export { AssetListSidebarView };
