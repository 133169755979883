
import { DELETE_COMMUNITY_ASSET } from './useDeleteCommunityAsset.graphql';
import {
  IDeleteCommunityAssetMutationGraphQLResponse,
  IDeleteCommunityAssetMutationVariables,
  IHandleDeleteCommunityAssetParams,
  IUseDeleteCommunityAssetOptions,
  IUseDeleteCommunityAsset,
} from './useDeleteCommunityAsset.interfaces';

import { useBonoboMutation } from '../../useBonoboMutation';

const useDeleteCommunityAsset = (options?: IUseDeleteCommunityAssetOptions): IUseDeleteCommunityAsset => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseDeleteCommunityAssetOptions);

  const [executeDeleteCommunityAsset, { loading: isLoading }] = useBonoboMutation<
    IDeleteCommunityAssetMutationGraphQLResponse,
    IDeleteCommunityAssetMutationVariables
  >({
    mutation: mutation ?? DELETE_COMMUNITY_ASSET,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { deleteAsset: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteCommunityAsset = ({ assetId }: IHandleDeleteCommunityAssetParams) => {
    void executeDeleteCommunityAsset({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleDeleteCommunityAsset,
    isLoading,
  };
};

export { useDeleteCommunityAsset };
