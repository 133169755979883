import { gql } from '@apollo/client';
import { AppFragment, AudioFragment, ButtonFragment, CodeFragment, DocumentFragment, ContentSnippetSliderFragment, EmbedFragment, FormFragment, ImageFragment, MapFragment, QuestionFragment, QuoteFragment, RegionsFragment, SliderFragment, SpacerFragment, SummaryFragment, TextFragment, VideoFragment } from 'fragments';



const GET_CONTENT_PAGE_QUERY = gql`
  query getContentPage(
    $contentPageId: Int!
  ) {
    contentPage {
      getContentPage(contentPageId: $contentPageId) {
        id
        sort
        audio {
          presignedUrl
        }
        contentPageChildren {
          asset {
            assetId
            presignedUrl
            bubbleTexts {
              angle
              character {
                id
                image {
                  presignedUrl
                  contentType
                  s3Key
                }
                name
                projectId
              }
              characterId
              id
              isTailVisible
              hasShadow
              shape
              tailType
              sort
              text
              w
              x
              y
            }
            contentType
            fileName
            fileSizeInBytes
            isAnimatedImage
            presignedUrl
            s3Key
            type
          }
          audio {
            assetId
            presignedUrl
            contentType
            fileName
            fileSizeInBytes
            presignedUrl
            s3Key
            type
          }
          id
          minimumExposureTime
          sort
          url
        }
        sectionGroups {
          id
          sort
          visible
          contentSections {
            id
            contentPageId
            contentLayoutId
            sort
            backgroundColor
            visible
            title
            borderColor
            cssStyle {
              id
              name
            }
            background {
              assetId
              contentType
              s3Key
            }
            contentEventId
            style
            contentEvent {
              id
              animation
              animationDirection
              entityId
              event
              eventDirection
              eventTitle
              infinite
              millisecondDelay
              millisecondSpeed
              asset {
                alt
                assetId
                contentType
                description
                fileName
                isFavorite
                isPublic
                tagList {
                  id
                  name
                }
                presignedUrl
                projectId
                publicS3Key
                publicUrl
                s3Key
                title
                transcript
                type
                uploaded
                user {
                  firstName
                  lastName
                }
                xAxisFocus
                yAxisFocus
              }
              backgroundColor
              borderColor
              titleColor
              style
              triggerStyle
              iconHorizontalAlignment
              isDefaultOpen
            }
            sectionContainers {
              id
              sort
              identifier
              sectionId
              backgroundColor
              borderColor
              title
              visible
              cssStyle {
                id
                name
              }
              contentEventId
              contentEvent {
                animation
                animationDirection
                entityId
                event
                eventDirection
                eventTitle
                infinite
                millisecondDelay
                millisecondSpeed
                asset {
                  alt
                  assetId
                  contentType
                  description
                  fileName
                  isFavorite
                  isPublic
                  tagList {
                    id
                    name
                  }
                  presignedUrl
                  projectId
                  publicS3Key
                  publicUrl
                  s3Key
                  title
                  transcript
                  type
                  uploaded
                  user {
                    firstName
                    lastName
                  }
                  xAxisFocus
                  yAxisFocus
                }
                backgroundColor
                borderColor
                titleColor
                style
                triggerStyle
                iconHorizontalAlignment
                isDefaultOpen
              }
              snippets {
                id
                sort
                visible
                cssStyle {
                  id
                  name
                }
                contentEventId
                contentEvent {
                  event
                }
                title
                ... on ContentSnippetAvatarType {
                  avatar {
                    configuration {
                      key
                      value
                    }
                    imageUrl
                  }
                }
                borderColor
                backgroundColor
                type: __typename
                __typename
                ...app
                ...audio
                ...button
                ...code
                ...document
                ...embed
                ...form
                ...image
                ...map
                ...question
                ...quote
                ...regions
                ...slider
                ...spacer
                ...summary
                ...text
                ...video
              }
            }
          }
        }
      }
    }
  }
  ${AppFragment}
  ${AudioFragment}
  ${ButtonFragment}
  ${CodeFragment}
  ${ContentSnippetSliderFragment}
  ${DocumentFragment}
  ${EmbedFragment}
  ${FormFragment}
  ${ImageFragment}
  ${MapFragment}
  ${QuestionFragment}
  ${QuoteFragment}
  ${RegionsFragment}
  ${SliderFragment}
  ${SpacerFragment}
  ${SummaryFragment}
  ${TextFragment}
  ${VideoFragment}

`;

export { GET_CONTENT_PAGE_QUERY };
