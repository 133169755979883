import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_APP_COMPONENT_DETAILS_MUTATION } from './useUpdateAppComponentDetails.graphql';
import {
  IUpdateAppComponentDetailsMutationGraphQLResponse,
  IUpdateAppComponentDetailsMutationVariables,
  IHandleUpdateAppComponentDetailsParams,
  IUseUpdateAppComponentDetailsOptions,
  IUseUpdateAppComponentDetails,
} from './useUpdateAppComponentDetails.interfaces';

const useUpdateAppComponentDetails = (options?: IUseUpdateAppComponentDetailsOptions): IUseUpdateAppComponentDetails => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateAppComponentDetailsOptions);

  const [executeUpdateAppComponentDetails, { loading: isLoading }] = useEkardoMutation<
  IUpdateAppComponentDetailsMutationGraphQLResponse,
  IUpdateAppComponentDetailsMutationVariables
  >({
    mutation: mutation ?? UPDATE_APP_COMPONENT_DETAILS_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            appVersionMutation: {
              appVersionComponentMutation: {
                saveFilesIntoComponent: isCompleted
              }
            }
          }
        } = data;
        
        void onCompleted({ isCompleted });
      },
      onError,
    },
    token,
  });

  const handleUpdateAppComponentDetails = ({
    componentId,
    files,
    title,
  }: IHandleUpdateAppComponentDetailsParams) => {
    void executeUpdateAppComponentDetails({
      variables: {
        inputType: {
          componentId,
          title,
        },
        fileInputType: {
          componentId,
          files,
        }
      },
    });
  };

  return {
    handleUpdateAppComponentDetails,
    isLoading,
  };
};

export { useUpdateAppComponentDetails };
