import { IQuestionAction } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { QUESTION_CONDITION_TYPE_MAP } from "components";
import { generateRandomId } from "utils";

const getActionDefaultValues = (actionDetails?: IQuestionAction): FormFieldProps => {
  const {
    title,
    conditions,
    __typename: type,
    targetContentPage
  } = actionDetails ?? {};

  const { id: targetContentPageId } = targetContentPage ?? {};

  return {
    actionType: type ?? '',
    title: title ?? '',
    conditions: conditions?.map((condition) => ({
      tempId: `${generateRandomId()}-${condition.id}`,
      type: QUESTION_CONDITION_TYPE_MAP[condition.__typename],
      ...condition,
    })) ?? [],
    deletedConditionIds: [],
    targetContentPageId: String(targetContentPageId ?? ''),
  };
};

export { getActionDefaultValues };
