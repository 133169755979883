import { gql } from '@apollo/client';

const UPDATE_TRANSCRIPT_MUTATION = gql`
  mutation UpdateTranscript($transcriptId: Int!, $request: BubbleTextInputType!) {
    bubbleText {
      update(bubbleTextId: $transcriptId, request: $request) {
        id
      }
    }
  }
`;

export { UPDATE_TRANSCRIPT_MUTATION };
