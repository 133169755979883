import { gql } from '@apollo/client';

const GET_TOPIC = gql`
  query GetTopic($topicId: Int!) {
    topic {
      getTopic(topicId: $topicId) {
        id
        title
        description
        status
        topicImage {
          assetId
          presignedUrl
        }
      }
    }
  }
`;

export { GET_TOPIC };
