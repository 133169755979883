import { gql } from '@apollo/client';

const UPDATE_QUESTION_CONFIGURATION = gql`
  mutation updateQuestionConfiguration(
    $questionConfigurationId: Int!
    $questionConfigurationCheckbox: QuestionConfigurationCheckboxInputType
    $questionConfigurationRadio: QuestionConfigurationRadioInputType
  ) {
    questionConfiguration {
      updateQuestionConfiguration(
        questionConfigurationId: $questionConfigurationId
        questionConfigurationCheckbox: $questionConfigurationCheckbox
        questionConfigurationRadio: $questionConfigurationRadio
      ) {
        id
      }
    }
  }
`;


export { UPDATE_QUESTION_CONFIGURATION };
