import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentSection } from '@netfront/ekardo-content-library';
import { useToast, useUpdateContainerSort, useUpdateSection } from 'hooks';


const useSortContainer = ({ onCompleted }: { onCompleted: () => void }) => {
  const containerIdRef = useRef<{value : number | undefined}>({value: undefined});
  const sectionIdRef = useRef<{value : number | undefined}>({value: undefined});
  const sortRef = useRef<{value : number | undefined}>({value: undefined});

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  
  const { handleUpdateContainerSort, isLoading: isUpdateContainerLoading = false } = useUpdateContainerSort({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Container successfully moved',
      });
      onCompleted();
    },
    onError: handleGetError,
  });


  const { handleUpdateSection, isLoading: isUpdateSectionLoading = false } = useUpdateSection({
    onCompleted: () => {
      
      handleUpdateContainerSort({
        containerId: Number(containerIdRef.current.value),
        sectionId: Number(sectionIdRef.current.value),
        sort: Number(sortRef.current.value),
      });
    },
    onError: handleGetError,
  });


  const handleSortContainer = ({ 
    containerId, 
    sectionId,
    contentLayoutId, 
    isInSameSection = false,
    section,
    sort,
  }: {
    containerId: number; 
    contentLayoutId: number;  
    isInSameSection?: boolean; 
    section: IContentSection;
    sectionId: number;
    sort: number;
  }) => {

    if (!isInSameSection) {

      containerIdRef.current.value = containerId;
      sectionIdRef.current.value = sectionId;
      sortRef.current.value = sort;

      const { 
        sort: sectionSort, 
        title = '', 
        visible: isVisible,
        background,
        backgroundColor,
        borderColor,
        style,
        cssStyle,
      } = section;
      const { assetId } = background ?? {};
      const { id } = cssStyle ?? {};
      
      handleUpdateSection({
        contentSection: {
          contentLayoutId,
          sectionId: Number(sectionId),
          sort: sectionSort,
          title,
          visible: isVisible,
          backgroundImage: assetId,
          backgroundColor,
          borderColor,
          style,
        },
        shouldAttachCustomTheme: false,
        shouldDetachCustomTheme: false,
        styleId: id ?? 0,
        contentSectionId: Number(sectionId),
        shouldCreateEvent: false,
        shouldUpdateEvent: false,
      });


    } else {
      handleUpdateContainerSort({
        containerId,
        sectionId,
        sort,
      });
    }
    
  };

  return {
    isLoading: isUpdateSectionLoading || isUpdateContainerLoading,
    handleSortContainer,
  };
};
export { useSortContainer };
