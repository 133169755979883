/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { ReactNode } from "react";

import { IContentPage } from "@netfront/ekardo-content-library";
import { ContentBuilderNavigationItemProps, SceneIcon, SlideIcon } from "@netfront/ui-library";
import Link from "next/link";

const formatContentPagesToNavigationItems = ({ baseUrl, contentPages, isCartoon, isForm = false }: {baseUrl: string; contentPages: IContentPage[]; isCartoon: boolean; isForm?: boolean }): ContentBuilderNavigationItemProps[] => {
  const formattedPages: ContentBuilderNavigationItemProps[] = contentPages.sort((a, b) => a.sort - b.sort).map((slide) => {
    const { title, id, contentPageChildren = [], url: slideUrl  } = slide;

    const type = contentPageChildren.length === 0 ? 'slide': 'scene';

    return {
      icon: contentPageChildren.length === 0 && isCartoon ? SlideIcon : SceneIcon,
      label: title,
      id: String(id),
      isDisabled: false,
      href: `${baseUrl}/${slideUrl}`,
      wrapperComponent: ({ children }: { children: ReactNode }) => <Link href={`${baseUrl}/${slideUrl}`} legacyBehavior>{children}</Link>,
      type: isCartoon ? type : 'page',
      hasAddButton: true,
      hasBulkAction: true,
      subItems: contentPageChildren.length > 0 ? formatContentPagesToNavigationItems({ baseUrl, contentPages: contentPageChildren, isCartoon }): [],
      conditionalSettingItems: {
        'id_settings_item': !isForm,
        'id_edit_item': true,
        'id_duplicate_item': !isForm,
        'id_add_new_item': !isForm,
        'id_delete_item': !isForm,
      },
    };
  });

  return formattedPages;
}


const findParentIdByChildId = ({ contentPages, id }: { contentPages: IContentPage[]; id: number }): number | undefined => {
  for (const contentPage of contentPages) {
    const { contentPageChildren = [], id: contentPageId } = contentPage;

    const child = contentPageChildren.find(({ id: childId }) => childId === id);
    if (child) return contentPageId;

    const parentId = findParentIdByChildId({ contentPages: contentPageChildren, id });
    if (parentId !== undefined) return parentId; // If found in nested children, return the parent ID
  }

  return undefined;
};

const getPageById = ({ contentPages, id }: { contentPages: IContentPage[]; id: number }): IContentPage | undefined => {
  for (const contentPage of contentPages) {
    const { contentPageChildren = [], id: contentPageId } = contentPage;

    if (id === contentPageId) return contentPage;

    const slide = getPageById({ contentPages: contentPageChildren, id });
    if (slide) return slide; 
  }

  return undefined;
};


const findPreviousPage = ({ contentPages, id }: { contentPages: IContentPage[]; id: number }): IContentPage | undefined => {
  let previousPage: IContentPage | undefined = undefined;
  let fallbackPage: IContentPage | undefined = undefined;

  const recursiveSearch = (pages: IContentPage[]): IContentPage | undefined => {
    for (const page of pages) {
      if (page.id === id) {
        return previousPage || fallbackPage || undefined;
      }

      if (!fallbackPage) fallbackPage = page;

      previousPage = page;

      const { contentPageChildren = [] } = page;
      const foundInChildren = recursiveSearch(contentPageChildren);
      
      if (foundInChildren) return foundInChildren;
    }

    return undefined;
  };

  return recursiveSearch(contentPages);
};


const getPageByUrl = ({ contentPages, url }: { contentPages: IContentPage[]; url?: string }): { activePageId: string; contentPage: IContentPage } | undefined => {
  for (const contentPage of contentPages) {
    const { contentPageChildren = [], url: contentPageUrl, id: contentPageId } = contentPage;

    if (url === contentPageUrl) {
      return {
        contentPage,
        activePageId: String(contentPageId),
      };
    }

    const result = getPageByUrl({ contentPages: contentPageChildren, url });
    if (result) {
      return {
        contentPage: result.contentPage,
        activePageId: `${String(contentPageId)}--${result.activePageId}`,
      };
    }
  }
  return undefined;
};







export { formatContentPagesToNavigationItems, findParentIdByChildId, getPageById, getPageByUrl, findPreviousPage };
