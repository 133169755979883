import { createContext } from 'react';


import { IContentPageContext } from './ContentPageContext.interfaces';


export const ContentPageContext = createContext<IContentPageContext>({
  // Base
  activePageId: '',
  baseUrl: '',
  contentType: undefined,
  contentGroupUrl: undefined,
  contentGroupId: undefined,
  isLoading: true,
  moduleOptions: [],
  navigationItems: [],
  pageUrl: undefined,
  projectId: undefined,
  contentPage: undefined,
  contentPages: [],
  isCartoon: false,
  // Empty state
  isEmptyPage: false,
  firstPageId: undefined,
  hasNoContent: false,


  // Page
  handleDeleteEntity: () => undefined,
  addPageToEntity: () => undefined,
  closePageSidebar: () => undefined,
  closeDeletePageDialog: () => undefined,
  handleCreatePage: () => undefined,
  handleDeletePage: () => undefined,
  handleUpdatePage: () => undefined,
  openCreatePageSidebar: () => undefined,
  openDeletePageDialog: () => undefined,
  openUpdatePageSidebar: () => undefined,
  enterEditMode: () => undefined, 
  duplicatePage: () => undefined,
  handleRefreshPage: () => undefined,
  isPageSidebarOpen: false,
  pageSidebarDetails: undefined,
  setPageSidebarDetails: () => undefined,

  // module
  handleOpenModuleSidebar: () => undefined,
  selectedModule: undefined,
  handleCloseModuleSidebar: () => undefined,
  onUpdateModule: () => undefined,
  isModuleSidebarOpen: false,
  handleSelectModule: () => undefined,
  handleDeleteModule: () => undefined,

  // test mode
  isTestMode: false,
  handleTestMode: () => undefined,

  // DnD
  onDrop: () => undefined,

});

// eslint-disable-next-line import/no-default-export
export default ContentPageContext;
