/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';

import { DashboardContext } from 'context';
import { useGetAppDetails, useToast } from 'hooks';
import { IApp } from 'interfaces';
import last from 'lodash.last';
import { useRouter } from 'next/router';

import AppDetailsContext from './AppDetailsContext';
import { getAppDetailsSidebarNavigationItems } from './AppDetailsContext.helpers';
import { AppDetailsContextProps } from './AppDetailsContext.interfaces';


export function AppDetailsProvider({ children }: AppDetailsContextProps) {
  const {  handleToastError } = useToast();
  const { query } = useRouter();

  const { appId: queryAppId } = query;
  const { dashboardLink } = useContext(DashboardContext);

  const [appId, setAppId] = useState<number>();
  const [baseUrl, setBaseUrl] = useState<string>('');

  const [appDetails, setAppDetails] = useState<IApp>();
  const [latestVersionUrl, setLatestVersionUrl] = useState<string>('');
  

  const { handleGetAppDetails, isLoading: isGetAppDetailsLoading = false } = useGetAppDetails({
    fetchPolicy: 'no-cache',
    onCompleted: ({ app }) => {
      setAppDetails(app);
      const latestVersion = last(app.versions);

      const { components = []} = latestVersion ?? {};

      const [ firstComponent ] = components;

      let componentId = '';

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (firstComponent) {
        componentId = String(firstComponent.id);
      }

      if (latestVersion)
        setLatestVersionUrl(`${String(dashboardLink)}/library/apps/${String(app.id)}/${String(latestVersion.id)}/components${ componentId ? `/${String(componentId)}`: ''}`)

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const getNavigationItems = (versionId?: number) => {
    if (!appDetails) return [];
    return getAppDetailsSidebarNavigationItems({appDetails, versionId, baseUrl});
  };

  const onUpdateApp = () => {
    handleGetAppDetails({
      appId: Number(appId),
    })
  };

  const getAppVersion = (versionId: number ) => {
    const { versions = []} = appDetails ?? {};

    return versions.find(({ id }) => id === versionId);
  };


  useEffect(() => {
    if (!(dashboardLink && appId)) return;

    setBaseUrl(`${String(dashboardLink)}/library/apps/${String(appId)}`);
  }, [dashboardLink, appId]);


  useEffect(() => {
    if (!appId) return;

    handleGetAppDetails({
      appId,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  useEffect(() => {
    if (!queryAppId) return;
    setAppId(Number(queryAppId));
  }, [queryAppId]);

  const isLoading = isGetAppDetailsLoading;
  return (
    <AppDetailsContext.Provider
      value={{
        appPageBaseUrl: baseUrl,
        appDetails,
        isLoading,
        getNavigationItems,
        latestVersionUrl,
        onUpdateApp,
        getAppVersion,
      }}
    >
      {children}
    </AppDetailsContext.Provider>
  );
};
