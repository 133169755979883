

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CHARACTERS_BY_PROJECT } from './useGetCharactersByProject.graphql';
import {
  IGetCharactersByProjectQueryGraphQLResponse,
  IGetCharactersByProjectQueryVariables,
  IHandleGetCharactersByProjectParams,
  IUseCharactersByProject,
  IUseCharactersByProjectOptions,
} from './useGetCharactersByProject.interfaces';



const useGetCharactersByProject = (options?: IUseCharactersByProjectOptions): IUseCharactersByProject => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseCharactersByProjectOptions);

  const [executeGetCharactersByProject, { loading: isLoading }] = useEkardoLazyQuery<
    IGetCharactersByProjectQueryGraphQLResponse,
    IGetCharactersByProjectQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          character: {
            getByProject : characters
          }
        } = data;


        onCompleted({
          characters,
        });
      },
      onError,
    },
    product,
    query: query ?? GET_CHARACTERS_BY_PROJECT,
    token,
  });

  const handleGetCharactersByProject = async ({
    projectId,
  }: IHandleGetCharactersByProjectParams) => {
    await executeGetCharactersByProject({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetCharactersByProject,
    isLoading,
  };
};

export { useGetCharactersByProject };
