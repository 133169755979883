import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_APP_COMMENT_MUTATION } from './useDeleteAppComment.graphql';
import {
  IDeleteAppCommentMutationGraphQLResponse,
  IDeleteAppCommentMutationVariables,
  IHandleDeleteAppCommentParams,
  IUseDeleteAppCommentOptions,
  IUseDeleteAppComment,
} from './useDeleteAppComment.interfaces';

const useDeleteAppComment = (options?: IUseDeleteAppCommentOptions): IUseDeleteAppComment => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteAppCommentOptions);

  const [executeDeleteAppComment, { loading: isLoading }] = useEkardoMutation<
    IDeleteAppCommentMutationGraphQLResponse,
    IDeleteAppCommentMutationVariables
  >({
    mutation: mutation ?? DELETE_APP_COMMENT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: { deleteRate: isCompleted },
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteAppComment = ({ rateId }: IHandleDeleteAppCommentParams) => {
    void executeDeleteAppComment({
      variables: {
        rateId,
      },
    });
  };

  return {
    handleDeleteAppComment,
    isLoading,
  };
};

export { useDeleteAppComment };
