

import { useTripleELazyQuery } from 'hooks';

import { EXPORT_LOG_ACCESS } from './useGetLogAccessReport.graphql';
import {
  IGetLogAccessReportQueryGraphQLResponse,
  IGetLogAccessReportQueryVariables,
  IHandleGetLogAccessReportParams,
  IUseGetLogAccessReport,
  IUseGetLogAccessReportOptions,
} from './useGetLogAccessReport.interfaces';

const useGetLogAccessReport = (options?: IUseGetLogAccessReportOptions): IUseGetLogAccessReport => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: tripleEProjectId } = options ?? ({} as IUseGetLogAccessReportOptions);

  const [executeGetLogAccessReport, { loading: isLoading }] = useTripleELazyQuery<
    IGetLogAccessReportQueryGraphQLResponse,
    IGetLogAccessReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      context: {
        headers: {
          project_id: tripleEProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportLogAccess } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportLogAccess,
        });
      },
      onError,
    },
    query: query ?? EXPORT_LOG_ACCESS,
    token,
  });


  const handleGetLogAccessReport = async ({
    dateFrom,
    dateTo,
  }: IHandleGetLogAccessReportParams) => {
    await executeGetLogAccessReport({
      variables: {
        dateFrom,
        dateTo,
      },
    });
  };

  return {
    handleGetLogAccessReport,
    isLoading,
  };
};

export { useGetLogAccessReport };
