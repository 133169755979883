import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { SORT_SECTION_GROUP } from './useSortSectionGroup.graphql';
import {
  ISortSectionGroupMutationGraphQLResponse,
  ISortSectionGroupMutationVariables,
  IUseSortSectionGroupOptions,
  IUseSortSectionGroup,
  ISortSectionGroupMutationParams,
} from './useSortSectionGroup.interfaces';

const useSortSectionGroup = (options?: IUseSortSectionGroupOptions): IUseSortSectionGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseSortSectionGroupOptions);

  const [executeSortSectionGroup, { loading: isLoading }] = useEkardoMutation<ISortSectionGroupMutationGraphQLResponse, ISortSectionGroupMutationVariables>({
    mutation: mutation ?? SORT_SECTION_GROUP,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          sectionGroup: { sort: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleSortSectionGroup = async ({
    id,
    sort,
  }: ISortSectionGroupMutationParams) => {
    await executeSortSectionGroup({
      variables: {
        id,
        sort,
      }
    });
  };

  return {
    handleSortSectionGroup,
    isLoading,
  };
};

export { useSortSectionGroup };
