
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Input, Select, Spacing, Textarea } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Controller } from 'react-hook-form';

import { CommunitySidebarGeneralTabProps } from './CommunitySidebarGeneralTab.interfaces';

const CommunitySidebarGeneralTab = ({ control, selectedCommunityId }: CommunitySidebarGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <SidebarContainer>

      <Controller
        control={control}
        name="title"
        render={({ field, fieldState }) => (
          <Spacing size="large">
            <Input 
              errorMessage={getFormFieldErrorMessage(fieldState)} 
              id="id_title"
              labelText="Title" 
              tooltipText="Community title" 
              type="text" 
              isLabelSideBySide 
              isRequired
              {...field} 
            />
          </Spacing>
        )}
      />

      {selectedCommunityId && (
        <Controller
          control={control}
          name="key"
          render={({ field }) => (
            <Spacing size="large">
              <Input 
                id="id_key" 
                labelText="Key" 
                tooltipText="Community key" 
                type="text" 
                isDisabled 
                isLabelSideBySide 
                isReadOnly 
                {...field} 
              />
            </Spacing>
          )}
        />
      )}

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => (
          <Spacing size="large">
            <Textarea
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_description"
              labelText="Description"
              maxLength={500}
              tooltipText="Community description"
              isLabelSideBySide
              isRequired
              {...field}
            />
          </Spacing>
        )}
      />

      <Controller
        control={control}
        name="privacy"
        render={({ field, fieldState }) => (
          <Spacing size="large">
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_privacy"
              labelText="Privacy"
              options={[
                {
                  name: 'Public',
                  value: 'PUBLIC',
                  id: 1,
                },
                {
                  name: 'Private',
                  value: 'PRIVATE',
                  id: 2,
                },
              ]}
              isLabelSideBySide
              isRequired
              {...field}
            />
          </Spacing>
        )}
      />
     
      <Controller
        control={control}
        name="status"
        render={({ field, fieldState }) => (
          <Spacing size="large">
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_status"
              labelText="Status"
              options={[
                {
                  name: 'Pending',
                  value: 'PENDING',
                  id: 1,
                },
                {
                  name: 'Published',
                  value: 'PUBLISHED',
                  id: 2,
                },
                {
                  name: 'Unpublished',
                  value: 'UNPUBLISHED',
                  id: 3,
                },
              ]}
              tooltipText="Only published communities will be available to post in"
              isLabelSideBySide
              isRequired
              {...field}
            />
          </Spacing>
        )}
      />


    </SidebarContainer>
  );
};

export { CommunitySidebarGeneralTab };
