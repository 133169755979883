import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { BULK_TOGGLE_ASSETS_PUBLIC } from './useBulkToggleAssetsPublic.graphql';
import {
  IBulkToggleAssetsPublicMutationGraphQLResponse,
  IBulkToggleAssetsPublicMutationVariables,
  IHandleBulkToggleAssetsPublicParams,
  IUseBulkToggleAssetsPublicOptions,
  IUseBulkToggleAssetsPublic,
} from './useBulkToggleAssetsPublic.interfaces';

const useBulkToggleAssetsPublic = (options?: IUseBulkToggleAssetsPublicOptions): IUseBulkToggleAssetsPublic => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseBulkToggleAssetsPublicOptions);

  const [executeBulkToggleAssetsPublic, { loading: isLoading }] = useEkardoMutation<
    IBulkToggleAssetsPublicMutationGraphQLResponse,
    IBulkToggleAssetsPublicMutationVariables
  >({
    mutation: mutation ?? BULK_TOGGLE_ASSETS_PUBLIC,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { toggleAssetsPublic: isCompleted },
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkToggleAssetsPublic = ({
    assetIds,
    isPublic,
  }: IHandleBulkToggleAssetsPublicParams) => {
    void executeBulkToggleAssetsPublic({
      variables: {
        assetIds,
        isPublic,
      },
    });
  };

  return {
    handleBulkToggleAssetsPublic,
    isLoading,
  };
};

export { useBulkToggleAssetsPublic };
