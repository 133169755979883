import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';


import { GET_TRANSCRIPT_TAIL_TYPES } from './useGetTranscriptTailTypes.graphql';
import { IGetTranscriptTailTypesQueryGraphQLResponse, IUseGetTranscriptTailTypes, IUseGetTranscriptTailTypesOptions } from './useGetTranscriptTailTypes.interfaces';


const useGetTranscriptTailTypes = (options?: IUseGetTranscriptTailTypesOptions): IUseGetTranscriptTailTypes => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetTranscriptTailTypesOptions);

  const [executeGetTranscriptTailTypes, { loading: isLoading }] = useEkardoLazyQuery<IGetTranscriptTailTypesQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          __type: { enumValues },
        } = data;

        void onCompleted({ enumValues });
      },
      onError,
    },
    query: query ?? GET_TRANSCRIPT_TAIL_TYPES,
    token,
  });

  const handleGetTranscriptTailTypes = () => {
    void executeGetTranscriptTailTypes();
  };

  return {
    handleGetTranscriptTailTypes,
    isLoading,
  };
};

export { useGetTranscriptTailTypes };
