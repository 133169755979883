import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_SNIPPET_SORT } from './useSortSnippet.graphql';
import {
  ISortSnippetMutationGraphQLResponse,
  ISortSnippetMutationVariables,
  IHandleSortSnippetParams,
  IUseSortSnippetOptions,
  IUseSortSnippet,
} from './useSortSnippet.interfaces';

const useSortSnippet = (options?: IUseSortSnippetOptions): IUseSortSnippet => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseSortSnippetOptions);

  const [executeSortSnippet, { loading: isLoading }] = useEkardoMutation<ISortSnippetMutationGraphQLResponse, ISortSnippetMutationVariables>({
    mutation: mutation ?? UPDATE_SNIPPET_SORT,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { updateContentSnippetBase: snippet },
        } = data;

        onCompleted({
          snippet,
        });
      },
      onError,
    },
    token,
  });

  const handleSortSnippet = async ({
    snippetId,
    containerId,
    sort,
    visible,
  }: IHandleSortSnippetParams) => {
    await executeSortSnippet({
      variables: {
        snippetId,
        containerId,
        sort,
        visible,
      }
    });
  };

  return {
    handleSortSnippet,
    isLoading,
  };
};

export { useSortSnippet };
