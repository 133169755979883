import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getQuickAddEventValues, getUpsertContentEventStatus, getContentStyleDetails, getFormattedContentStyles } from "components";

const getQuoteSnippetDefaultValues = ({ snippet } : { snippet?: IContentSnippet;}): FormFieldProps => {
  const {
    title,
    contentEvent,
    text,
    author,
    visible: isVisible = true,
    // isSensitive = false,
  } = snippet ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);
  const styleDetails = getContentStyleDetails({ entity: snippet });

  return {
    title: title ?? 'Quote snippet',
    text: text ?? '',
    author: author ?? '',
    isVisible,
    isSensitive: false,
    isCreateNew: false,
    ...eventDetails,
    ...animationDetails,
    ...styleDetails,
  };
};

const getQuoteSnippetCommonVariables = ({ eventAssetId, item }: { eventAssetId?: IDBAsset['assetId']; item: FormFieldProps; }) => {
  const {
    title = 'Quote snippet',
    text,
    author,
    isVisible,
    // isSensitive,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);
  const { baseVariables, customTheme } = getFormattedContentStyles({ item });

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
      // isSensitive,
      ...baseVariables,
    },
    quoteSnippet: {
      text,
      author
    },
    type: 'quote',
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    customTheme,
  };

  return sharedVariables;

};

const setQuickAddQuoteValues = (values: FormFieldProps): FormFieldProps => {
  return {
    ...getQuickAddEventValues(values),
    title: 'Quote snippet',
    text: '',
    author: '',
  };
};

export { getQuoteSnippetDefaultValues, getQuoteSnippetCommonVariables, setQuickAddQuoteValues };
