import React, { ChangeEvent, useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ToggleSwitch, ColorPicker, ColorPickerPreview, IOption, InformationBox, Input, InputFieldWrapper, Message, Select, Spacing, Spinner } from '@netfront/ui-library';
import { ContentBuilderTabWrapper, Uploader } from 'components';
import { useGetHorizontalAlignmentOptions, useToast } from 'hooks';
import { ITypeEnum } from 'interfaces';
import { Controller } from 'react-hook-form';

import { directionOptions, styleTypeOptions, triggerStyleTypeOptions, typeOptions } from './EventTab.constants';
import { EventTabProps } from './EventTab.interfaces';


const EventTab = ({ control, onDeleteAsset, onDrop, initialEvent, isSnippet = true }: EventTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { handleToastError } = useToast();
  const [horizontalOptions, setHorizontalOptions] = useState<IOption[]>([]);
  const [isBackgroundColorPickerOpen, setIsBackgroundColorPickerOpen] = useState<boolean>(false);
  const [isBorderColorPickerOpen, setIsBorderColorPickerOpen] = useState<boolean>(false);
  const [isTitleColorPickerOpen, setIsTitleColorPickerOpen] = useState<boolean>(false);
  const [hasHorizontalOptionsBeenFetched, setHasHorizontalOptionsBeenFetched] = useState<boolean>(false);
  const [style, setStyle] = useState<string>('');
  const [event, setEvent] = useState<string>('');

  const { handleGetHorizontalAlignmentOptions, isLoading: isGetHorizontalAlignmentOptionsLoading = false } = useGetHorizontalAlignmentOptions({
    fetchPolicy: 'no-cache',
    onCompleted: ({ options }: { options: ITypeEnum[]}) => {
      const formattedOptions: IOption[] = options.map((value, index) => ({
        id: index,
        value: value.name,
        name: value.name.toLowerCase().charAt(0).toUpperCase() + value.name.toLowerCase().slice(1),
      })) as IOption[];

      setHasHorizontalOptionsBeenFetched(true);
      setHorizontalOptions(formattedOptions);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (hasHorizontalOptionsBeenFetched) return;
    handleGetHorizontalAlignmentOptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasHorizontalOptionsBeenFetched]);

  useEffect(() => {
    if (!initialEvent) return;

    setEvent(initialEvent);
  }, [initialEvent])


  return (
    <ContentBuilderTabWrapper isSnippet={isSnippet}>
      <Spinner isLoading={isGetHorizontalAlignmentOptionsLoading} />
      <Spacing>
        <InformationBox message="Edit your event configuration." />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="event"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_event_type"
              labelText="Event"
              options={typeOptions}
              tooltipPosition="end"
              tooltipText="Define how content will displayed to the user"
              {...field}
              isLabelSideBySide
              onChange={(changeEvent: ChangeEvent<HTMLSelectElement>) => {
                const { target: { value }} = changeEvent;
                field.onChange(changeEvent);
                setEvent(value);
              }}
            />
          )}
        />
      </Spacing>

      { event && event !== 'NONE' && (
        <>
          <Spacing>
            <Controller
              control={control}
              name="style"
              render={({ field, fieldState }) => (
                <Select
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_event_trigger_type"
                  labelText="Trigger type"
                  options={styleTypeOptions}
                  tooltipPosition="end"
                  tooltipText="Define the display type for the trigger"
                  {...field}
                  isLabelSideBySide
                  onChange={(changeEvent: ChangeEvent<HTMLSelectElement>) => {
                    const { target: { value }} = changeEvent;
                    field.onChange(changeEvent);
                    setStyle(value);
                  }}
                />
              )}
            />
          </Spacing>

          { ['TEXT_AND_ICON', 'TEXT_ONLY'].includes(String(style)) && (
            <Spacing>
              <Controller
                control={control}
                name="eventTitle"
                render={({ field, fieldState }) => (
                  <Input
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id="id_event_title"
                    labelText="Label"
                    type="text"
                    isLabelSideBySide
                    {...field}
                  />
                )}
              />
          </Spacing>
          )}

          { event === 'REVEAL' && (
            <>
            <Spacing>
              <Controller
                control={control}
                name="iconHorizontalAlignment"
                render={({ field, fieldState }) => (
                  <Select
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id="id_event_alignment"
                    labelText="Alignment"
                    options={horizontalOptions}
                    tooltipPosition="end"
                    tooltipText="Horizontal alignment of the trigger"
                    isLabelSideBySide
                    {...field}
                  />
                )}
              />
            </Spacing>

            <Spacing>
              <Controller
                control={control}
                name="isDefaultOpen"
                render={({ field, fieldState }) => {
                  const errorMessage = getFormFieldErrorMessage(fieldState);
                  return (
                    
                    <>
                      <ToggleSwitch
                        additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
                        id="id_is_event_default_open"
                        isChecked={field.value}
                        labelText="Default to open?"
                        isInline
                        isLabelSideBySide
                        {...field}
                      />
                      {Boolean(errorMessage) && <Message id={`id_isDefaultOpen_error`} text={errorMessage} type="ERROR" />}
                    </>
                  );
                }}
              />
            </Spacing>
          </>
          )}


          <Spacing>
            <Controller
              control={control}
              name="eventDirection"
              render={({ field, fieldState }) => (
                <Select
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_event_direction"
                  labelText="Direction"
                  options={directionOptions}
                  tooltipPosition="end"
                  tooltipText="The location the trigger will end after the action is complete"
                  isLabelSideBySide
                  {...field}
                />
              )}
            />
          </Spacing>

          { event === 'REVEAL' && style !== 'ICON_ONLY' && (
            <Spacing>
              <Controller
                control={control}
                name="triggerStyle"
                render={({ field, fieldState }) => (
                  <Select
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id="id_event_trigger_style"
                    labelText="Trigger icon"
                    options={triggerStyleTypeOptions}
                    tooltipPosition="end"
                    tooltipText="Predefined trigger icon used with the label"
                    isLabelSideBySide
                    {...field}
                  />
                )}
              />
            </Spacing>
          )}

          <Spacing>
            <Controller
              control={control}
              name="borderColor"
              render={({ field, fieldState }) => (
                <InputFieldWrapper
                  id="id_border_colour"
                  label="Border colour"
                  message={{error: getFormFieldErrorMessage(fieldState), success: ''}}
                  type="custom"
                  isLabelSideBySide
                >
                  <Spacing size="x-small">
                    <ColorPickerPreview
                      selectedColor={field.value}
                      onClick={() => setIsBorderColorPickerOpen(!isBorderColorPickerOpen)} />
                  </Spacing>
                  {isBorderColorPickerOpen && <ColorPicker additionalClassNames="" color={field.value} type="hex" onHexChange={field.onChange} />}
                </InputFieldWrapper>
              )}
            />
          </Spacing>
          <Spacing>
            <Controller
              control={control}
              name="backgroundColor"
              render={({ field, fieldState }) => (
                <InputFieldWrapper
                  id="id_background_colour"
                  label="Background colour"
                  message={{error: getFormFieldErrorMessage(fieldState), success: ''}}
                  type="custom"
                  isLabelSideBySide
                >
                  <Spacing size="x-small">
                    <ColorPickerPreview
                      selectedColor={field.value}
                      onClick={() => setIsBackgroundColorPickerOpen(!isBackgroundColorPickerOpen)} />
                  </Spacing>
                  {isBackgroundColorPickerOpen && <ColorPicker additionalClassNames="" color={field.value} type="hex" onHexChange={field.onChange} />}
                </InputFieldWrapper>
              )}
            />
          </Spacing>
          <Spacing>
            <Controller
              control={control}
              name="titleColor"
              render={({ field, fieldState }) => (
                <InputFieldWrapper
                  id="id_title_colour"
                  label="Title colour"
                  message={{error: getFormFieldErrorMessage(fieldState), success: ''}}
                  type="custom"
                  isLabelSideBySide
                >
                  <Spacing size="x-small">
                    <ColorPickerPreview
                      selectedColor={field.value}
                      onClick={() => setIsTitleColorPickerOpen(!isTitleColorPickerOpen)} />
                  </Spacing>
                  {isTitleColorPickerOpen && <ColorPicker additionalClassNames="" color={field.value} type="hex" onHexChange={field.onChange} />}
                </InputFieldWrapper>
              )}
            />
          </Spacing>
          <Spacing >
            <Controller
              control={control}
              name="image"
              render={({ field }) => (
                <Uploader
                  assetType="image"
                  initialUrl={field.value}
                  isRequired={false}
                  labelText="Icon"
                  name={field.name}
                  tooltipText="Optional custom icon displayed next to the label"
                  isLabelSideBySide
                  onDelete={onDeleteAsset}
                  onDrop={onDrop}
                />
              )}
            />
          </Spacing>
        </>
      )}
    </ContentBuilderTabWrapper>
  );
};

export { EventTab };
