import { useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { ICreateTopicMutationVariables, IUpdateTopicMutationVariables, useCreateTopic, useCreateTopicAsset, useDeleteTopicAsset, useToast, useUpdateTopic } from 'hooks';
import { IDBTopic } from 'interfaces';
import { pushImageToAws } from 'utils';


const useUpsertTopic = ({ onCreate, onUpdate, projectId }: { onCreate: (returnedTopic: IDBTopic) => void; onUpdate: (returnedTopic: IDBTopic) => void; projectId: string }) => {
  const uploadedFileRef = useRef<{value : File | undefined}>({value: undefined});
  const deletedFileIdRef = useRef<{value : string | undefined}>({value: undefined});
  const returnedTopicRef = useRef<{value : IDBTopic | undefined}>({value: undefined});
  const topicIdRef = useRef<{value : number | undefined}>({value: undefined});
  const [isAssetUploadToAwsLoading, setIsAssetUploadToAwsLoading] = useState<boolean>(false);

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleCleanUp = () => {
    uploadedFileRef.current.value = undefined;
    deletedFileIdRef.current.value = undefined;
    returnedTopicRef.current.value = undefined;
    topicIdRef.current.value = undefined;
  };

  const { handleCreateTopicAsset, isLoading: isCreateTopicAssetLoading = false } = useCreateTopicAsset({
    onCompletedAsync: async (data) => {

      const { signedUrl } = data;

      setIsAssetUploadToAwsLoading(true);

      await pushImageToAws(signedUrl, uploadedFileRef.current.value, () => {
        
        
        if (topicIdRef.current.value) {
          handleToastSuccess({
            message: 'Topic successfully created',
          });
    
          onCreate(returnedTopicRef.current.value ?? {} as IDBTopic);
        } else {
          handleToastSuccess({
            message: 'Topic successfully updated',
          });
    
          onUpdate(returnedTopicRef.current.value ?? {} as IDBTopic);
        }

        handleCleanUp();
        setIsAssetUploadToAwsLoading(false);
      });
    },
    onError: handleGetError,
    projectId,
  });
  
  const { handleDeleteTopicAsset } = useDeleteTopicAsset({
    onError: handleGetError,
    projectId,
  });


  const { handleCreateTopic, isLoading: isCreateTopicLoading = false } = useCreateTopic({
    onCompleted: ({ topicsConnection }) => {
      returnedTopicRef.current.value = topicsConnection;

      if (uploadedFileRef.current.value) {
        const { type, name, size } = uploadedFileRef.current.value;
        void handleCreateTopicAsset({
          contentType: type,
          fileName: name,
          fileSizeInBytes: size,
          alt: name,
          topicId: topicsConnection.id,
          type: 'IMAGE'
        });
      } else {
        handleToastSuccess({
          message: 'Topic successfully created',
        });
        handleCleanUp();
        onCreate(topicsConnection);
        
      }

      
    },
    onError: handleGetError,
    projectId,
  });

  const { handleUpdateTopic, isLoading: isUpdateTopicLoading } = useUpdateTopic({
    onCompleted: ({ topicsConnection }) => {
      returnedTopicRef.current.value = topicsConnection;
      if (uploadedFileRef.current.value) {
        const { type, name, size } = uploadedFileRef.current.value;
        void handleCreateTopicAsset({
          contentType: type,
          fileName: name,
          fileSizeInBytes: size,
          alt: name,
          topicId: topicsConnection.id,
          type: 'IMAGE'
        });
      } else {
        handleToastSuccess({
          message: 'Topic successfully updated',
        });
  
        onUpdate(topicsConnection);
      }
    },
    onError: handleGetError,
    projectId,
  });

  const handleUpsertTopic = ({ 
    deletedFileId,
    status,
    title,
    topicId,
    uploadedFile,
  }: {
    deletedFileId?: string;
    status: string;
    title: string;
    topicId?: number;
    uploadedFile?: File;
   }) => {
    uploadedFileRef.current.value = uploadedFile;
    deletedFileIdRef.current.value = deletedFileId;
    topicIdRef.current.value = topicId;

    if (deletedFileId) {
      handleDeleteTopicAsset({
        assetId: deletedFileId,
      });
    }

    if (!topicId) {
      void handleCreateTopic({
        projectId,
        title,
        status: status as ICreateTopicMutationVariables['status'],
      });

    } else {
      void handleUpdateTopic({
        topicId,
        title,
        status: status as IUpdateTopicMutationVariables['status'],
      });
    }
  };

  return {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    isLoading: isCreateTopicLoading || isUpdateTopicLoading || isCreateTopicAssetLoading || isAssetUploadToAwsLoading,
    handleUpsertTopic,
  };
};
export { useUpsertTopic };
