import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, FormFieldProps, Input, SidebarButtons, Spacing, Spinner, Textarea, useControlledForm } from '@netfront/ui-library';
import { SidebarContainer, Uploader } from 'components';
import { useUpsertApp, useUpsertAssetWrapper } from 'hooks';
import { Control, Controller } from 'react-hook-form';
import { generateRandomId, toKebabCase } from 'utils';
import * as yup from 'yup';


import { getAppDefaultValues } from './AppGeneralTab.helpers';
import { AppGeneralTabProps } from './AppGeneralTab.interfaces';

const AppGeneralTab = ({
  onCancel,
  onUpdate,
  projectId,
  selectedApp,
}: AppGeneralTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const droppedFileRef = useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({ name: '', image: ''});

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        description: yup.string().label('Description').required(),
      }),
    ),
  });

  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();

  const { handleUpsertApp, isLoading: isUpsertAppLoading = false } = useUpsertApp({
    onCompleted: () => {
      reset();
      onUpdate()
    },
  });

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileRef.current.value = uploadedFile;
  }

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  }


  const handleSave = (item: FormFieldProps) => {
    const { logoId, title, description } = item;
    handleUpsertAsset({
      assetType: 'image',
      isCreate: true,
      projectId,
      uploadedFile: droppedFileRef.current.value,
      deletedFileId: hasDeletedOriginalImageRef.current.value && logoId ? logoId : undefined,
      callBack: (asset?: IDBAsset) => {

        handleUpsertApp({
          appId: selectedApp?.identifier,
          logoId: asset ? asset.assetId : logoId,
          title,
          description,
          identifier: selectedApp ? selectedApp.identifier : `${toKebabCase(String(title))}-${generateRandomId()}`
        });
      }
    });
  };

  useEffect(() => {

    setDefaultValues(getAppDefaultValues(selectedApp));

  }, [selectedApp])

  const isLoading = isUpsertAssetLoading || isUpsertAppLoading;

  return (

    <ControlledForm
      callBack={(item: FormFieldProps) => {
        handleSave(item);
      }}
      handleSubmit={handleSubmit}
    >
      <SidebarContainer>
        <Spinner isLoading={isLoading} /> 
        <Spacing size="2x-large">
          <Controller
            control={control as Control<FormFieldProps>}
            name="title"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_app_name"
                labelText="Name"
                type="text"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="description"
            render={({ field, fieldState }) => (
              <Textarea
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_app_description"
                labelText="Description"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing size="2x-large">
          <Controller
            control={control as Control<FormFieldProps>}
            name="presignedUrl"
            render={({ field }) => (
              <Uploader
                assetType="image"
                initialUrl={field.value}
                isRequired={false}
                labelText="Thumbnail"
                isLabelSideBySide
                onDelete={handleRemoveAsset}
                onDrop={handleDropFile}
                {...field}
              />
            )}
          />
        </Spacing>
        <SidebarButtons
          buttonSize="xs"
          isDeleteButtonDisabled={isLoading}
          isSaveButtonDisabled={isLoading}
          onCancel={onCancel}
          onSaveButtonType="submit"
        />
      </SidebarContainer>
    </ControlledForm>
  );
};

export { AppGeneralTab };
