import { gql } from '@apollo/client';

const SORT_SECTION_GROUP = gql`
  mutation sortSectionGroup(
    $id: Int!
    $sort: Int!
  ) {
    sectionGroup {
      sort(id: $id, sort: $sort)
    }
  }
`;

export { SORT_SECTION_GROUP };
