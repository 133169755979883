import { gql } from '@apollo/client';

const UPDATE_APP_COMPONENT_MUTATION = gql`
  mutation createMyApp($inputType: UpdateComponentTitleInputType!) {
    app {
      appVersionMutation {
        appVersionComponentMutation {
          updateComponentTitle(
            inputType: $inputType
          )
        }
      }
    }
  }
`;

export { UPDATE_APP_COMPONENT_MUTATION };
