const NetfrontServices = {
  BONOBO: 'BONOBO',
  BONOBO_MANAGEMENT: 'BONOBO_MANAGEMENT',
  EKARDO: 'EKARDO',
  HOWLER: 'HOWLER',
  MURIQUI: 'MURIQUI',
  VERVET: 'VERVET',
  CAPUCHIN: 'CAPUCHIN',
  TRIPLE_E: 'TRIPLE_E',
};

export { NetfrontServices };
