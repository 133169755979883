import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { ATTACH_CSS_STYLE_TO_SNIPPET } from './useAttachSnippetCssStyle.graphql';
import {
  IAttachSnippetCssStyleMutationGraphQLResponse,
  IAttachSnippetCssStyleMutationVariables,
  IHandleAttachSnippetCssStyleParams,
  IUseAttachSnippetCssStyleOptions,
  IUseAttachSnippetCssStyle,
} from './useAttachSnippetCssStyle.interfaces';

const useAttachSnippetCssStyle = (options?: IUseAttachSnippetCssStyleOptions): IUseAttachSnippetCssStyle => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseAttachSnippetCssStyleOptions);

  const [executeAttachSnippetCssStyle, { loading: isLoading }] = useEkardoMutation<IAttachSnippetCssStyleMutationGraphQLResponse, IAttachSnippetCssStyleMutationVariables>({
    mutation: mutation ?? ATTACH_CSS_STYLE_TO_SNIPPET,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          cssStyle: { attachStyleToContentSnippet: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleAttachSnippetCssStyle = async ({
    contentSnippetId,
    styleId,
  }: IHandleAttachSnippetCssStyleParams) => {
    await executeAttachSnippetCssStyle({
      variables: {
        contentSnippetId,
        styleId,
      }
    });
  };

  return {
    handleAttachSnippetCssStyle,
    isLoading,
  };
};

export { useAttachSnippetCssStyle };
