
import { Spacing } from '@netfront/ui-library';
import { SidebarContainer, Uploader } from 'components';
import { Controller } from 'react-hook-form';

import { CommunitySidebarThemeTabProps } from './CommunitySidebarThemeTab.interfaces';

const CommunitySidebarThemeTab = ({ 
  control, 
  onUploadImage,
  onUploadBanner,
  onRemoveImage,
  onRemoveBanner,
}: CommunitySidebarThemeTabProps) => {

  return (
    <SidebarContainer>
      <Spacing >
        <Controller
          control={control}
          name="imagePresignedUrl"
          render={({ field }) => (
            <Uploader
              assetType="image"
              initialUrl={field.value}
              isRequired={false}
              labelText="Profile image upload"
              name={field.name}
              tooltipText="Upload your profile image"
              isLabelSideBySide
              onDelete={onRemoveImage}
              onDrop={onUploadImage}
            />
          )}
        />
      </Spacing>
      <Spacing >
        <Controller
          control={control}
          name="bannerPresignedUrl"
          render={({ field }) => (
            <Uploader
              assetType="image"
              initialUrl={field.value}
              isRequired={false}
              labelText="Banner image upload"
              name={field.name}
              tooltipText="Upload your banner image"
              isLabelSideBySide
              onDelete={onRemoveBanner}
              onDrop={onUploadBanner}
            />
          )}
        />
      </Spacing>
    </SidebarContainer>
  );
};

export { CommunitySidebarThemeTab };
