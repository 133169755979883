import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import { IContentGroup, IDBGroupingContentGroup } from '@netfront/ekardo-content-library';
import {
  Button,
  FlexContainer,
  Input,
  InputFieldWrapper,
  Select,
  SidebarButtons,
  Spacing,
  Textarea,
  ToggleSwitch,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { DashboardContext } from 'context';
import { createSyntheticEvent, toKebabCase } from 'utils';

import { ContentGroupGeneralTabProps } from './ContentGroupGeneralTab.interfaces';


const ContentGroupGeneralTab = ({
  contentGroup,
  contentGroupings = [],
  onUpdateInput,
  onSave,
  onCancel,
  onDelete,
  onUpdateGrouping,
  isLoading = false,
}: ContentGroupGeneralTabProps) => {

  const { dashboardLink } = useContext(DashboardContext);

  const [contentPreviewUrl, setContentPreviewUrl] = useState<string>('');
  const [isSyncUrlToTitle, setIsSyncUrlToTitle] = useState<boolean>(false);
  const [currentContentGroup, setCurrentContentGroup] = useState<IContentGroup | undefined>(contentGroup);

  const {
    description = '',
    contentType = 'PRIVATE',
    title = '',
    url = '',
    groupingContentGroup,
    status = 'PUBLISHED',
  } = currentContentGroup ?? {};

  const {
    id: currentGrouping
  } = groupingContentGroup ?? {};



  const handleUpdateInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = event;

    onUpdateInput({ name, value });

    setCurrentContentGroup(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IContentGroup),
    );
  };

  const handleUpdateStatus = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: isChecked },
    } = event;

    onUpdateInput({name: 'status', value: isChecked ? 'PUBLISHED': 'UNPUBLISHED'});

    setCurrentContentGroup(
      (currentState) =>
        ({
          ...currentState,
          status: isChecked ? 'PUBLISHED': 'UNPUBLISHED',
        } as IContentGroup),
    );
  };


  const handleUpdateType = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: isChecked },
    } = event;

    onUpdateInput({name: 'contentType', value: isChecked ? 'PUBLIC': 'PRVIATE'});

    setCurrentContentGroup(
      (currentState) =>
        ({
          ...currentState,
          contentType: isChecked ? 'PUBLIC': 'PRVIATE',
        } as IContentGroup),
    );
  };

  const handleUpdateGrouping = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    const selectedGrouping = contentGroupings.find(({id: groupingId}) => groupingId === Number(value));

    if (selectedGrouping) {
      const { id, name } = selectedGrouping;

      onUpdateGrouping({id, name} as IDBGroupingContentGroup);

      setCurrentContentGroup(
        (currentState) =>
          ({
            ...currentState,
            groupingContentGroup: {id, name},
          } as IContentGroup),
      );
    }
  };

  const handleSyncUrlToTitle = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: isChecked },
    } = event;
    setIsSyncUrlToTitle(isChecked);

    if (isChecked) {
      const {
        title: currentTitle = ''
      } = currentContentGroup ?? {};
      
      const {
        title: existingTitle = ''
      } = contentGroup ?? {};

      if (existingTitle !== currentTitle) {
        handleUpdateInput(createSyntheticEvent('url', toKebabCase(currentTitle)));
      }
    }
  }


  useEffect(() => {
    if (!(dashboardLink && contentGroup)) return;
    setContentPreviewUrl(`${dashboardLink}/content/content-pdf/${contentGroup.url}`)


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardLink, contentGroup]);
  

  return (
    <SidebarContainer>
      <Spacing size="2x-large">
        <Input
          id="title"
          isLoading={isLoading}
          labelText="Title"
          name="title"
          placeholder="Add your title"
          tooltipText="Title of the content group"
          type="text"
          value={title}
          isLabelSideBySide
          isRequired
          onChange={(event) => {
            handleUpdateInput(event);
            const { target: { value }} = event;
            if (!contentGroup || isSyncUrlToTitle) {
              handleUpdateInput(createSyntheticEvent('url', toKebabCase(value)));
            }
          }}
        />
      </Spacing>

      {contentGroup && (
        <Spacing size="2x-large">
          <ToggleSwitch
            additionalClassNames="c-asset-toggle"
            id="id_is_sync_url_to_title"
            isChecked={isSyncUrlToTitle}
            labelText="Sync url to title"
            isLabelSideBySide
            onChange={handleSyncUrlToTitle}
          />
        </Spacing>
      )}
      

      <Spacing size="2x-large">
        <Textarea
          id="description"
          isLoading={isLoading}
          labelText="Description"
          name="description"
          placeholder="Add your description here"
          tooltipText="Description of the content group"
          value={description}
          isLabelSideBySide
          onChange={handleUpdateInput}
        />
      </Spacing>

      <Spacing size="2x-large">
        <Input
          id="url"
          isLoading={isLoading}
          labelText="Url"
          name="url"
          placeholder="Add your url"
          tooltipText="Url of the content group"
          type="text"
          value={url}
          isLabelSideBySide
          isRequired
          onChange={handleUpdateInput}
        />
      </Spacing>

      <Spacing size="2x-large">
        <ToggleSwitch
          additionalClassNames="c-asset-toggle"
          id="isPublic"
          isChecked={contentType === 'PUBLIC'}
          labelText="Public content"
          tooltipPosition="start"
          tooltipText="If turned on, a user can view the content with out needing to be signed in."
          isLabelSideBySide
          onChange={handleUpdateType}
        />
      </Spacing>

      <Spacing size="2x-large">
        <ToggleSwitch
          additionalClassNames="c-asset-toggle"
          id="id_status"
          isChecked={status === 'PUBLISHED'}
          labelText="Published"
          tooltipPosition="start"
          tooltipText="If the content is published it will show to users who have access to it. If you unpublish this content the content will not be accessible. "
          isLabelSideBySide
          onChange={handleUpdateStatus}
        />
      </Spacing>

      {contentGroup && (
        <>
          <Spacing size="2x-large">
            <InputFieldWrapper id="" label="Content preview" labelType="span" tooltipText="Preview the pages all together" isLabelSideBySide>
              <FlexContainer justifyContent="flex-end" isFullWidth>
                <Button
                  linkButton={{
                    id: 'id_preview_content_group',
                    linkComponent: 'a',
                    target: '_blank',
                    url: contentPreviewUrl,
                  }}
                  size="xs"
                  text="Preview"
                  variant="secondary"
                />
              </FlexContainer>
            </InputFieldWrapper>
          </Spacing>
        </>
      )}

      {contentGroup && contentGroupings.length > 0 && (
        <Spacing size="2x-large">
          <Select
            id="id_content_groupings"
            isLoading={isLoading}
            labelText="Grouping"
            name="content_groupings"
            options={contentGroupings}
            tooltipText="Add content group to a grouping"
            value={currentGrouping}
            isLabelHidden
            isLabelSideBySide
            onChange={handleUpdateGrouping}
          />
        </Spacing>
      )}


      <SidebarButtons
        buttonSize="xs"
        isDeleteButtonDisabled={isLoading}
        isSaveButtonDisabled={isLoading}
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={onSave}
        onSaveButtonType="button"
      />
    </SidebarContainer>
  );
};

export { ContentGroupGeneralTab };
