import React, { useState, useContext, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, FieldsetAccordionGroup, FormFieldProps, GeneralTabIcon, IconRadioGroup, InformationBox, Message, SearchFilter, SidebarButtons, Spacing, TabSet, useControlledForm } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { ContentBuilderContext } from 'context';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { snippetTypeDefaultOptions } from './SnippetTypeSelectorSidebarView.constants';
import { getSnippetType } from './SnippetTypeSelectorSidebarView.helpers';
import { ISnippetOption } from './SnippetTypeSelectorSidebarView.interfaces';

import { SnippetTypeConst } from '../../../../../constants';

const SnippetTypeSelectorSidebarView = ({ isLoading = false }: { isLoading?: boolean }) => {
  const { updateSnippetSidebarDetails, closeSnippetSidebar, snippetSidebarDetails, isSnippetSidebarOpen, isFormsMode, isFormBuilder } = useContext(ContentBuilderContext);
  const { containerId, sort } = snippetSidebarDetails ?? {};

  const [searchedSnippetOptions, setSearchedSnippetOptions] = useState<ISnippetOption[]>([]);
  const [snippetOptions, setSnippetOptions] = useState<ISnippetOption[]>([]);


  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset, setValue, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        type: yup.string().required().label('Type'),
      }),
    ),
  });

  const handleSearch = () => {
    const { search } = getValues();
    if (search === '') {
      setSearchedSnippetOptions(snippetOptions);
      setValue('type', '');
      return;
    }

    const filteredOptions = snippetOptions.filter(({ label }) => label.toLowerCase().includes(String(search).toLowerCase()));
    setSearchedSnippetOptions(filteredOptions);
    setValue('type', '');
  };

  const handleSelectType = (item: FormFieldProps) => {
    const { type } = item;
    const snippetType = getSnippetType(String(type));
    updateSnippetSidebarDetails({
      containerId: Number(containerId),
      sort: Number(sort),
      type: snippetType,
      questionType: snippetType === SnippetTypeConst.QUESTION ? type : undefined,
    });
    reset();
  };

  useEffect(() => {
    setDefaultValues({
      type: '',
      search: ''
    });
  }, [isSnippetSidebarOpen]);

  useEffect(() => {
    if (isFormsMode || isFormBuilder) {
      setSnippetOptions(snippetTypeDefaultOptions.filter(({ value }) => value !== SnippetTypeConst.FORM));
      setSearchedSnippetOptions(snippetTypeDefaultOptions.filter(({ value }) => value !== SnippetTypeConst.FORM));
    } else {
      setSnippetOptions(snippetTypeDefaultOptions);
      setSearchedSnippetOptions(snippetTypeDefaultOptions);
    }
  }, [isFormsMode, isFormBuilder]); 

  return (
    <div className="c-sidebar-view">
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSelectType(item);
        }}
        handleSubmit={handleSubmit}
      >
        <TabSet
          defaultActiveTabId="id_general_tab"
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => isLoading ? <></> :(
                <>
                  <SidebarContainer>
                    <Spacing>
                      <InformationBox message={<>Select your <strong>snippet</strong> type below</>} />
                    </Spacing>
                    <Spacing size="2x-small">
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="search"
                      render={({ field }) => (
                        <SearchFilter
                          id="id_search_snippet_types"
                          isDisabled={false}
                          labelText="Snippet filter"
                          name={field.name}
                          placeholder="Search snippets"
                          type="text"
                          value={field.value}
                          isFullWidth
                          onChange={field.onChange}
                          onClear={() => {
                            setValue('search', '')
                            handleSearch();
                          }}
                          onSearch={handleSearch}
                        />
                      )}
                    />
                    </Spacing>
                  </SidebarContainer>
                  <Controller
                    control={control as Control<FormFieldProps>}
                    name="type"
                    render={({ field, fieldState }) => {
                      const errorMessage = getFormFieldErrorMessage(fieldState);
                      return (
                        <>
                          <FieldsetAccordionGroup
                            data={[
                              {
                                id: 'id_general_snippet_accordion',
                                label: 'General',
                                isOpenOnLoad: !isFormsMode,
                                children: (
                                  <Spacing>
                                    <IconRadioGroup
                                      id="new_snippet_type_general"
                                      items={searchedSnippetOptions.filter(({type}) => type === 'general')}
                                      label="Type"
                                      name={field.name}
                                      selectedValue={field.value}
                                      hasLabels
                                      isLabelHidden
                                      isOutlined
                                      onChange={field.onChange}
                                    />
                                  </Spacing>
                                ),
                              },
                              {
                                id: 'id_forms_snippet_accordion',
                                label: 'Forms',
                                isOpenOnLoad: true,
                                children: (
                                  <Spacing>
                                    <IconRadioGroup
                                      id="new_snippet_type_forms"
                                      items={searchedSnippetOptions.filter(({type}) => type === 'forms')}
                                      label="Type"
                                      name={field.name}
                                      selectedValue={field.value}
                                      hasLabels
                                      isLabelHidden
                                      isOutlined
                                      onChange={field.onChange}
                                    />
                                  </Spacing>
                                ),
                              },
                              {
                                id: 'id_other_snippet_accordion',
                                label: 'Other',
                                isOpenOnLoad: !isFormsMode,
                                children: (
                                  <Spacing>
                                    <IconRadioGroup
                                      id="new_snippet_type_other"
                                      items={searchedSnippetOptions.filter(({type}) => type === 'other')}
                                      label="Type"
                                      name={field.name}
                                      selectedValue={field.value}
                                      hasLabels
                                      isLabelHidden
                                      isOutlined
                                      onChange={field.onChange}
                                    />
                                  </Spacing>
                                ),
                              },
                            ]}
                          />
                          {Boolean(errorMessage) && (
                            <SidebarContainer>
                              <Message id={`id_snippet_select_error`} text={errorMessage} type="ERROR" />
                            </SidebarContainer>
                          )}
                        </>
                      );
                    }}
                  />
                  <SidebarButtons
                    submitButtonText="Next"
                    onCancel={closeSnippetSidebar}
                    onSaveButtonType="submit"
                  />
                </>
              ),
            },
          ]}
        />
      </ControlledForm>
    </div>
  );
};


export { SnippetTypeSelectorSidebarView };
