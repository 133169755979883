
import { ApolloError } from '@apollo/client';
import { useAttachContainerCssStyle, useAttachPageCssStyle, useAttachSectionCssStyle, useAttachSnippetCssStyle, useToast } from 'hooks';
import { IEntityType } from 'interfaces';



const useAttachCssStyle = () => {
  const { handleToastError } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };
  

  const { handleAttachPageCssStyle, isLoading: isAttachPageCssStyleLoading = false } = useAttachPageCssStyle({
    onError: handleGetError,
  });

  const { handleAttachSectionCssStyle, isLoading: isAttachSectionCssStyleLoading = false } = useAttachSectionCssStyle({
    onError: handleGetError,
  });

  const { handleAttachContainerCssStyle, isLoading: isAttachContainerCssStyleLoading = false } = useAttachContainerCssStyle({
    onError: handleGetError,
  });

  const { handleAttachSnippetCssStyle, isLoading: isAttachSnippetCssStyleLoading = false } = useAttachSnippetCssStyle({
    onError: handleGetError,
  });

  const handleAttachCssStyle = ({
    entityId,
    styleId,
    type
  }: {
    entityId: number;
    styleId: number;
    type: IEntityType;
  }) => {
    if (type === 'targetSnippet') {
      handleAttachSnippetCssStyle({
        contentSnippetId: entityId,
        styleId,
      });
    }

    if (type === 'section') {
      handleAttachSectionCssStyle({
        contentSectionId: entityId,
        styleId,
      });
    }

    if (type === 'container') {
      handleAttachContainerCssStyle({
        containerId: entityId,
        styleId,
      });
    }

    if (type === 'page') {
      handleAttachPageCssStyle({
        contentPageId: entityId,
        styleId,
      });
    }
  };

  return {
    isLoading: isAttachSnippetCssStyleLoading || isAttachPageCssStyleLoading || isAttachSectionCssStyleLoading || isAttachContainerCssStyleLoading,
    handleAttachCssStyle,
  };
};
export { useAttachCssStyle };
