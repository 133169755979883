import React, { ChangeEvent, useEffect, useState } from 'react';

import { IDBAsset } from '@netfront/common-library';
import {
  BinIcon,
  ButtonIconOnly,
  CloseIcon,
  Dialog,
  DropzoneFileUpload,
  FileAssetIcon,
  FlexContainer,
  ImageUpload,
  Input,
  InputFieldWrapper,
  PlusIcon,
  Select,
  SidebarButtons,
  Spacing,
  Spinner,
  Textarea,
} from '@netfront/ui-library';
import axios from 'axios';
import { IEditableDirectory, SidebarContainer, getFormattedDirectory } from 'components';
import { useCreateAsset, useDeleteAsset, useDeleteAsset as useDeleteFileAsset, useCreateAsset as useCreateFileAsset, useToast, useAttachDirectoryFile, useDetachDirectoryFile } from 'hooks';


import { DirectoryTabProps } from './DirectoryGeneralTab.interfaces';

const DIRECTORY_STATUS_OPTIONS = [
  {
    id: 0,
    name: 'PENDING',
    value: 'PENDING',
  },
  {
    id: 1,
    name: 'PUBLISHED',
    value: 'PUBLISHED',
  },
  {
    id: 2,
    name: 'UNPUBLISHED',
    value: 'UNPUBLISHED',
  },
];



const DirectoryGeneralTab =
  ({
    projectId,
    directoryTypeOptions,
    isLoading = false,
    onChange,
    selectedDirectory,
    onDelete,
    onSave,
    onCancel,
    onDirectoryTypeChange,
  }: DirectoryTabProps) => {
    const { handleToastError } = useToast();

    const [editableDirectory, setEditableDirectory] = useState<IEditableDirectory>({} as IEditableDirectory);
    const [droppedFile, setDroppedFile] = useState<File>();
    const [droppedDocumentFile, setDroppedDocumentFile] = useState<File>();
    const [currentAssetId, setCurrentAssetId] = useState<string | undefined>(selectedDirectory?.asset?.assetId);
    const [uploadedAssetId, setUploadedAssetId] = useState<string>();
    const [assetList, setAssetList] = useState<IDBAsset[]>([]);
    const [isAddNewAttachment, setIsAddNewAttachment] = useState(false);
    const [selectedDirectoryFile, setSelectedDirectoryFile] = useState<string | null>(null);

    useEffect(() => {
      if (!selectedDirectory) return;
      setEditableDirectory(getFormattedDirectory(selectedDirectory));
    }, [selectedDirectory])

    const {
      description,
      id: directoryId,
      directoryTypeKey,
      files,
      email,
      phoneNumber,
      status,
      subTitle,
      title,
      url,
    } = editableDirectory;

    const { handleDeleteAsset, isLoading: isDeleteAssetLoading = false } = useDeleteAsset({
      onCompleted: (data) => {
        const { isCompleted } = data;

        if (isCompleted) {
          if (uploadedAssetId) {
            setCurrentAssetId(uploadedAssetId);
            setUploadedAssetId(undefined);
          } else {
            setCurrentAssetId(undefined);
            setUploadedAssetId(undefined);
            onChange?.('assetId', '');
          }
        }

      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });


    const { handleCreateAsset, isLoading: isCreateAssetLoading = false } = useCreateAsset({
      onCompletedAsync: async (data) => {
        const { signedUrl, assetId } = data;

        setUploadedAssetId(assetId);
        onChange?.('assetId', assetId);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await axios.put(String(signedUrl), droppedFile, { headers: { 'content-type': String(droppedFile?.type) } }).then(() => {
          // get new asset id onChange('assetId', [ASSET_ID]);
          if (currentAssetId) {
            handleDeleteAsset({
              assetId: currentAssetId
            })
          }
        });
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

    const { handleDeleteAsset: handleDeleteFileAsset, isLoading: isDeleteFileAssetLoading = false } = useDeleteFileAsset({
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

    const { handleCreateAsset: handleCreateFileAsset, isLoading: isCreateFileAssetLoading = false } = useCreateFileAsset({
      onCompletedAsync: async (data) => {
        const { signedUrl, assetId } = data;

        setUploadedAssetId(assetId);
        onChange?.('assetId', assetId);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await axios.put(String(signedUrl), droppedFile, { headers: { 'content-type': String(droppedFile?.type) } });

        setAssetList([
          ...assetList,
          data,
        ]);
        setIsAddNewAttachment(false);

        handleAttachDirectoryFile({
          id: Number(directoryId),
          assetId: assetId,
        });
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

    const { handleAttachDirectoryFile } = useAttachDirectoryFile({
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

    const { handleDetachDirectoryFile } = useDetachDirectoryFile({
      onCompleted: () => {
        handleDeleteFileAsset({
          assetId: String(selectedDirectoryFile)
        });

        setAssetList(assetList.filter((item) => item.assetId !== selectedDirectoryFile));
        setSelectedDirectoryFile(null);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });


    const onInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      const {
        target: { value, name },
      } = event;

      onChange?.(name, value);
      setEditableDirectory({
        ...editableDirectory,
        [name]: value,
      });
    };

    const handleImageUpload = (uploadedFile?: File) => {
      if (uploadedFile) setDroppedFile(uploadedFile);
    };

    const handleCloseDialog = () => {
      setSelectedDirectoryFile(null);
    };

    const handleFileDrop = (droppedFiles: File[]) => {
      setDroppedDocumentFile(droppedFiles[0]);
    };

    const handleConfirmDeleteFile = () => {
      handleDetachDirectoryFile({
        assetId: String(selectedDirectoryFile),
        id: Number(directoryId),
      });
    };


    useEffect(() => {
      if (!droppedFile) return;
      handleCreateAsset({
        alt: 'asset',
        contentType: droppedFile.type,
        fileName: droppedFile.name,
        fileSizeInBytes: droppedFile.size,
        tagList: [],
        projectId: String(projectId),
        title: droppedFile.name,
        type: 'DOCUMENT',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [droppedFile])

    useEffect(() => {
      if (!droppedDocumentFile) return;
      handleCreateFileAsset({
        alt: 'asset',
        contentType: droppedDocumentFile.type,
        fileName: droppedDocumentFile.name,
        fileSizeInBytes: droppedDocumentFile.size,
        tagList: [],
        projectId: String(projectId),
        title: droppedDocumentFile.name,
        type: 'DOCUMENT',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [droppedDocumentFile])

    useEffect(() => {
      if (!files) {
        return;
      }

      setAssetList(files);
    }, [files]);

    const isAssetFunctionLoading = isCreateFileAssetLoading || isDeleteFileAssetLoading || isCreateAssetLoading || isDeleteAssetLoading;
    return (
      <SidebarContainer>
        <Spinner isLoading={isAssetFunctionLoading} />
        {selectedDirectoryFile && (
          <Dialog
            confirmText="Delete"
            isOpen={true}
            title="Delete document"
            onCancel={handleCloseDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmDeleteFile}
          >
            <p>Are you sure you want to delete this document?</p>
          </Dialog>
        )}
        <fieldset>
          <legend className="h-hide-visually">General Directory details</legend>
          {selectedDirectory && (
            <Spacing>
              <Select
                id="status"
                isDisabled={Boolean(selectedDirectory)}
                isLoading={isLoading}
                labelText="Directory status"
                name="status"
                options={DIRECTORY_STATUS_OPTIONS}
                tooltipText="The status of the directory"
                value={status}
                isLabelSideBySide
                onChange={onInputChange}
              />
            </Spacing>
          )}
          <Spacing>
            <Select
              id="directory-type"
              isLoading={isLoading}
              labelText="Directory type"
              name="directoryTypeKey"
              options={directoryTypeOptions ?? []}
              tooltipText="The type of directory"
              value={directoryTypeKey}
              isLabelSideBySide
              isRequired
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                const { target : {
                  value,
                  options,
                  selectedIndex,
                }} = event;

                onDirectoryTypeChange(String(options[selectedIndex].id), value);
              }}

            />
          </Spacing>
          <Spacing>
            <Input id="title" isLoading={isLoading} labelText="Title"  name="title" tooltipText="The title displayed in the directory listing" type="text" value={title} isLabelSideBySide isRequired onChange={onInputChange} />
          </Spacing>
          <Spacing>
            <Input id="sub-title" isLoading={isLoading} labelText="Sub Title" name="subTitle" tooltipText="The subtitle displayed in the directory listing" type="text" value={subTitle} isLabelSideBySide onChange={onInputChange} />
          </Spacing>
          <Spacing>
            <Input id="link" isLoading={isLoading} labelText="Link" name="url" tooltipText="An optional external link to the directory website" type="text" value={url} isLabelSideBySide onChange={onInputChange} />
          </Spacing>
          <Spacing>
            <Input
              id="phone-number"
              isLoading={isLoading}
              labelText="Phone number"
              name="phoneNumber"
              tooltipText="An optional phone number for the directory"
              type="text"
              value={phoneNumber}
              isLabelSideBySide
              onChange={onInputChange}
            />
          </Spacing>
          <Spacing>

            <Input
              id="email"
              isLoading={isLoading}
              labelText="Email address"
              name="email"
              tooltipText="An optional email address for the directory"
              type="text"
              value={email}
              isLabelSideBySide
              onChange={onInputChange}
            />
          </Spacing>
          <Spacing>
            <Textarea
              id="description"
              isLoading={isLoading}
              labelText="Description"
              name="description"
              tooltipText="The description shown in the directory listing"
              value={description}
              isLabelSideBySide
              onChange={onInputChange}
            />
          </Spacing>
          <Spacing>
            <ImageUpload
              id="id_directory_logo"
              initialUrl={selectedDirectory?.asset?.presignedUrl}
              isLoading={isLoading}
              labelText="Logo"
              name="logoUrl"
              tooltipText="The logo displayed with the directory title and description"
              isLabelSideBySide
              onDrop={handleImageUpload}
            />
          </Spacing>
          {directoryId && (
            <Spacing>
              <InputFieldWrapper id="directory document" label="Document" labelType="span" tooltipText="Add, edit or remove a document" isLabelSideBySide >
                <Spacing>
                  {assetList.length ? (
                    <FlexContainer justifyContent="end">
                      <ButtonIconOnly
                        additionalClassNames="c-add-new-button"
                        icon={isAddNewAttachment ? CloseIcon : PlusIcon}
                        text="add new document"
                        onClick={() => setIsAddNewAttachment(!isAddNewAttachment)}
                      />
                    </FlexContainer>
                  ) : null}

                </Spacing>
                {isAddNewAttachment && (
                  <DropzoneFileUpload
                    additionalClassNames="c-dropzone-file-upload"
                    fileType=""
                    id="directory-document"
                    labelText="Document"
                    isLabelHidden
                    onDrop={(file) => handleFileDrop(file)}
                  />
                )}
                {assetList.length ? (
                  <ul className="c-list-container">
                    {assetList.map(({ assetId: assetItemId, fileName, presignedUrl }) => (
                      <li key={`id_${String(assetItemId)}`} style={{alignItems: 'center', display: 'flex', justifyContent: 'space-between', margin: '0'}}>

                        <a className="c-file-link" href={presignedUrl} rel="noreferrer" target="_blank">
                          <FileAssetIcon className="c-icon c-file-link__icon"/>
                          {fileName}
                        </a>
                        <ButtonIconOnly
                          icon={BinIcon}
                          text="Delete file"
                          onClick={() => setSelectedDirectoryFile(assetItemId)}
                        />
                      </li>

                    ))}
                  </ul>
                ) : (
                  <DropzoneFileUpload
                    additionalClassNames="c-dropzone-file-upload"
                    fileType=""
                    id="attachment-dropzone"
                    labelText="Document"
                    isLabelHidden
                    onDrop={(file) => handleFileDrop(file)}
                  />
                )}
              </InputFieldWrapper>
            </Spacing>
          )}
        </fieldset>
        <SidebarButtons
          onCancel={onCancel}
          onDelete={onDelete}
          onSave={onSave}
        />
      </SidebarContainer>
    );
  };


export { DirectoryGeneralTab };

{/*
*/}
