import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_TRANSCRIPT_SHAPE_TYPES } from './useGetTranscriptShapeTypes.graphql';
import {
  IGetTranscriptShapeTypesQueryGraphQLResponse,
  IUseGetTranscriptShapeTypes,
  IUseGetTranscriptShapeTypesOptions,
} from './useGetTranscriptShapeTypes.interfaces';

const useGetTranscriptShapeTypes = (options?: IUseGetTranscriptShapeTypesOptions): IUseGetTranscriptShapeTypes => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetTranscriptShapeTypesOptions);

  const [executeGetTranscriptShapeTypes, { loading: isLoading }] = useEkardoLazyQuery<IGetTranscriptShapeTypesQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          __type: { enumValues },
        } = data;

        void onCompleted({ enumValues });
      },
      onError,
    },
    query: query ?? GET_TRANSCRIPT_SHAPE_TYPES,
    token,
  });

  const handleGetTranscriptShapeTypes = () => {
    void executeGetTranscriptShapeTypes();
  };

  return {
    handleGetTranscriptShapeTypes,
    isLoading,
  };
};

export { useGetTranscriptShapeTypes };
