import React, { useContext, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { ControlledForm, Dialog, FilmIcon, FormFieldProps, GeneralTabIcon, SideBarTabSet, Spinner, StyleTabIcon, TabSetImperativeHandleRef, UsageIcon, useControlledForm } from '@netfront/ui-library';
import { AnimationTab, EventTab, StyleTab, UpsertContainerGeneralTab, animationTabValidation, eventTabValidation } from 'components';
import { ContentBuilderContext } from 'context';
import { useGetContainerDetails, useToast, useUpsertAssetWrapper, useUpsertContainer } from 'hooks';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getContainerCommonVariables, getContainerDefaultValues } from './ContainerSidebarView.helpers';


const ContainerSidebarView = () => {
  const { isContainerSidebarOpen, closeContainerSidebar, containerSidebarDetails, handleDeleteEntity, handleCreateEntity, handleUpdateContainer: handleUpdateEntity, projectId } = useContext(ContentBuilderContext);
  const { handleToastError } = useToast();


  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const droppedFileEventAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { containerId, id, sort, containerNumber, contentLayoutId } = containerSidebarDetails ?? {};

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        contentLayoutId: yup.number().required(),
        ...eventTabValidation,
        ...animationTabValidation,
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetContainerDetails, isLoading: isGetSectionDetailsLoading = false } = useGetContainerDetails({
    fetchPolicy: 'no-cache',
    onCompleted: ({ container: returnedContainer }) => {
      setDefaultValues(getContainerDefaultValues({ contentLayoutId: Number(contentLayoutId), container: returnedContainer }));
    },
    onError: handleGetError,
  });


  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();

  const { handleUpsertContainer, isLoading: isUpsertContainerLoading = false } = useUpsertContainer({
    onCreate: () => {
      reset();
      closeContainerSidebar();
      handleCreateEntity();
    },
    onUpdate: (returnedContainer) => {
      reset();
      closeContainerSidebar();
      handleUpdateEntity(returnedContainer);
    }
  });

  const handleDelete = () => {
    if (!id) return;
    setIsDeleteDialogOpen(false);
    handleDeleteEntity({
      type: 'container',
      id: Number(id)
    });
  };

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileEventAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };


  const handleSave = (item: FormFieldProps) => {
    const {
      contentLayoutId: saveContentLayoutId,
      assetId,
    } = item;

    handleUpsertAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(projectId),
      uploadedFile: droppedFileEventAssetRef.current.value,
      deletedFileId: hasDeletedOriginalImageRef.current.value && assetId ? assetId : undefined,
      callBack: (asset?: IDBAsset) => {
        const commonVariables = getContainerCommonVariables({
          containerId: Number(containerId),
          item,
          sort: Number(sort),
          eventAssetId: asset ? String(asset.assetId): undefined,
        });

        handleUpsertContainer({
          contentLayoutId: Number(saveContentLayoutId),
          containerId: id,
          variables: commonVariables,
        });
      }
    });
  };


  useEffect(() => {
    if (!id) {
      setDefaultValues(getContainerDefaultValues({}));
    } else {
      handleGetContainerDetails({
        containerId: id,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isLoading = isUpsertAssetLoading || isUpsertContainerLoading || isGetSectionDetailsLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >

      <SideBarTabSet
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={() => {
          closeContainerSidebar();
        }}
        hasViewPadding={false}
        isSideBarOpen={isContainerSidebarOpen}
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () => isContainerSidebarOpen ? (
              <UpsertContainerGeneralTab
                containerNumber={containerNumber}
                control={control as Control<FormFieldProps>}
              />
            ) : <></>,

          },
          {
            icon: UsageIcon,
            id: 'id_event_tab',
            label: 'Event',
            view: () => isContainerSidebarOpen ? (
              <EventTab
                control={control as Control<FormFieldProps>}
                initialEvent={defaultValues?.event}
                isSnippet={false}
                onDeleteAsset={handleRemoveAsset}
                onDrop={handleDropFile}
              />
            ) : <></>,
          },
          {
            icon: FilmIcon,
            id: 'id_animation_tab',
            label: 'Animation',
            view: () => isContainerSidebarOpen ? (
              <AnimationTab
                animationType={defaultValues?.animation ?? ''}
                control={control as Control<FormFieldProps>}
                isSnippet={false}
              />
            ) : <></>,
          },
          {
            icon: StyleTabIcon,
            id: 'id_style_tab',
            label: 'Style',
            view: () => isLoading ? <></> : (
              <StyleTab
                control={control as Control<FormFieldProps>}
                entityType="container"
              />
            ),
          },
        ]}
        onDelete={id ? () => setIsDeleteDialogOpen(true): undefined }
        onSaveButtonType="submit"
      />
      </ControlledForm>

      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete container?`}
        isNarrow
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};


export { ContainerSidebarView };
