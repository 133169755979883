import { gql } from '@apollo/client';

const ATTACH_CSS_STYLE_TO_PAGE = gql`
  mutation($contentPageId: Int!, $styleId: Int!) {
    cssStyle {
      attachStyleToContentPage(contentPageId: $contentPageId, styleId: $styleId)
    }
  }
`;

export { ATTACH_CSS_STYLE_TO_PAGE };
