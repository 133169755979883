import { gql } from '@apollo/client';

const GET_COMMUNITY = gql`
  query GetProjectCommunities(
   $communityId: Int
  ) {
    community {
      get(communityId: $communityId) {
        bannerImage {
          assetId
          presignedUrl
        }
        id
        description
        title
        privacy
        key
        profileImage {
          assetId
          presignedUrl
        }
        status
      }
    }
  }
`;

export { GET_COMMUNITY };
