/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { useEffect, useState } from 'react';

export const useAppHeaderHeight = (): number => {
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    // Function to update header height in rem
    const updateHeaderHeight = () => {
      const header = document.querySelector('.c-app-header') as HTMLElement | null;
      if (header) {
        const heightInPixels = header.offsetHeight;
        const heightInRem = heightInPixels / 16; // Assuming 1rem = 16px
        setHeaderHeight(heightInRem);
      }
    };

    // Initial call to set height
    updateHeaderHeight();

    // Create a ResizeObserver to monitor height changes
    const header = document.querySelector('.c-app-header') as HTMLElement | null;
    let observer: ResizeObserver | undefined;

    if (header) {
      observer = new ResizeObserver(() => {
        updateHeaderHeight();
      });
      observer.observe(header);
    }

    // Clean up observer on component unmount
    return () => {
      if (observer && header) {
        observer.unobserve(header);
      }
    };
  }, []);

  return headerHeight;
}
