import React, { useContext, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { ISectionGroup } from '@netfront/ekardo-content-library';
import { ControlledForm, Dialog, FilmIcon, FormFieldProps, GeneralTabIcon, SideBarTabSet, Spinner, TabSetImperativeHandleRef, UsageIcon, useControlledForm } from '@netfront/ui-library';
import { AnimationTab, EventTab, UpsertSectionGroupGeneralTab, animationTabValidation, eventTabValidation } from 'components';
import { ContentBuilderContext } from 'context';
import { useGetContentPage, useToast, useUpsertAssetWrapper, useUpsertSectionGroup } from 'hooks';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getSectionGroupCommonVariables, getSectionGroupDefaultValues } from './SectionGroupSidebarView.helpers';



const SectionGroupSidebarView = () => {
  const { isSectionGroupSidebarOpen, closeSectionGroupSidebar, sectionGroupSidebarDetails, handleDeleteEntity, handleCreateEntity, handleUpdateSectionGroup: handleUpdateEntity, projectId } = useContext(ContentBuilderContext);
  const { handleToastError } = useToast();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const droppedFileEventAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { sectionGroupId, containerId, sort } = sectionGroupSidebarDetails ?? {};

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        ...eventTabValidation,
        ...animationTabValidation,
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  // const { handleGetSectionGroupDetails, isLoading: isGetSectionGroupDetailsLoading = false } = useGetSectionGroupDetails({
  //   fetchPolicy: 'no-cache',
  //   onCompleted: ({ sectionGroup: returnedSectionGroup }) => {
  //     setDefaultValues(getSectionGroupDefaultValues({ sectionGroup: returnedSectionGroup }));
  //   },
  //   onError: handleGetError,
  // });

  const { handleUpsertSectionGroup, isLoading: isUpsertSectionLoading = false } = useUpsertSectionGroup({
    onCreate: () => {
      reset();
      closeSectionGroupSidebar();
      handleCreateEntity();
    },
    onUpdate: (returnedSectionGroup) => {
      reset();
      closeSectionGroupSidebar();
      handleUpdateEntity(returnedSectionGroup);
    }
  });

  // Temporary until the correct endpoint is set up
  const { handleGetContentPage, isLoading: isGetContentPageLoading = false } = useGetContentPage({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentPage }) => {

      const { sectionGroups } = contentPage;

      const sectionGroup = sectionGroups.filter(({id}) => id === sectionGroupId) as unknown as ISectionGroup;

      setDefaultValues(getSectionGroupDefaultValues({ sectionGroup }));
    },
    onError: handleGetError,
  });

  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();

  const handleDelete = () => {
    if (!sectionGroupId) return;
    handleDeleteEntity({
      type: 'section',
      id: Number(sectionGroupId)
    });
  };

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileEventAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };

  const handleSave = (item: FormFieldProps) => {

    const { assetId } = item;

    handleUpsertAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(projectId),
      uploadedFile: droppedFileEventAssetRef.current.value,
      deletedFileId: hasDeletedOriginalImageRef.current.value && assetId ? assetId : undefined,
      callBack: (asset?: IDBAsset) => {
        const commonVariables = getSectionGroupCommonVariables({
          asset,
          eventAssetId: asset ? String(asset.assetId): undefined,
          sort: Number(sort),
          item,
        });

        handleUpsertSectionGroup({
          contentPageId: Number(containerId),
          sectionGroupId,
          variables: commonVariables,
        });
      }
    });
  };


  useEffect(() => {
    if (!sectionGroupId) {
      setDefaultValues(getSectionGroupDefaultValues({}));
    } else {
      return;
      // handleGetSectionGroupDetails({
      //   id: sectionGroupId,
      // });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionGroupId]);

  // Temporary until the correct endpoint is set up
  useEffect(() => {
    if (!(sectionGroupId && containerId)) return;
    void handleGetContentPage({
      contentPageId: containerId
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionGroupId, containerId]);

  const isLoading = isUpsertAssetLoading || isUpsertSectionLoading || isGetContentPageLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >


      <SideBarTabSet
        ref={tabsetRef}
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={() => {
          closeSectionGroupSidebar();
        }}
        hasViewPadding={false}
        isSideBarOpen={isSectionGroupSidebarOpen}
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () => isSectionGroupSidebarOpen ? (
              <UpsertSectionGroupGeneralTab
                control={control as Control<FormFieldProps>}
              />
            ) : <></>,
          },
          {
            icon: UsageIcon,
            id: 'id_event_tab',
            label: 'Event',
            view: () => isSectionGroupSidebarOpen ? (
              <EventTab
                control={control as Control<FormFieldProps>}
                initialEvent={defaultValues.event}
                isSnippet={false}
                onDeleteAsset={handleRemoveAsset}
                onDrop={handleDropFile}
              />
            ) : <></>,
          },
          {
            icon: FilmIcon,
            id: 'id_animation_tab',
            label: 'Animation',
            view: () => isSectionGroupSidebarOpen ? (
              <AnimationTab
                animationType={defaultValues.animation ?? ''}
                control={control as Control<FormFieldProps>}
                isSnippet={false}
              />
            ) : <></>,
          },
        ]}
        onDelete={sectionGroupId ? () => setIsDeleteDialogOpen(true): undefined }
        onSaveButtonType="submit"
      />
      </ControlledForm>

      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete section?`}
        isNarrow
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};


export { SectionGroupSidebarView };
