import React, { useState, useRef, useEffect, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { ControlledForm, FilmIcon, FormFieldProps, GeneralTabIcon, SidebarButtons, SingleCheckbox, Spinner, StyleTabIcon, TabSet, TabSetImperativeHandleRef, UsageIcon, useControlledForm } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { AnimationTab, EventTab, StyleTab, UpsertCodeSnippetGeneralTab, animationTabValidation, eventTabValidation } from 'components';
import { CachingEntitiesContext } from 'context';
import { useDeleteCodeAsset, useGetCodeAsset, useToast, useCreateCodeAsset, useUpsertAssetWrapper, useUpsertSnippet } from 'hooks';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { pushImageToAws, isValidURL } from 'utils';
import * as yup from 'yup';

import { getCodeSnippetCommonVariables, getCodeSnippetDefaultValues, setQuickAddCodeValues } from './UpsertCodeSnippetSidebarView.helpers';
import { UpsertCodeSnippetSidebarViewProps } from './UpsertCodeSnippetSidebarView.interfaces';


const UpsertCodeSnippetSidebarView = ({ containerId, onClose, onCreate, onDelete, onUpdate, snippet, sort, isLoading: isSnippetLoading = false, handleUpdateSortValue }: UpsertCodeSnippetSidebarViewProps) => {

  const { project } = useContext(CachingEntitiesContext);
  const { handleToastError, handleToastCustomError } = useToast();

  const htmlFileRef = useRef<{ value: File | null }>({ value: null });
  const createdEventAssetIdRef = useRef<{ value: string| null}>({ value: null });

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const droppedFileEventAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const snippetRef = useRef<{ value: FormFieldProps}>({value: {} as FormFieldProps});
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { control, handleSubmit, reset, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        htmlContent: yup.string().required(),
        ...eventTabValidation,
        ...animationTabValidation,
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleUpsertAsset: handleUpsertEventAsset, isLoading: isUpsertEventAssetLoading = false } = useUpsertAssetWrapper();


  const { handleUpsertSnippet, isLoading: isUpsertSnippetLoading = false } = useUpsertSnippet({
    onCreate: () => {
      const { isCreateNew = false } = getValues();

      if (isCreateNew) {
        droppedFileEventAssetRef.current.value = undefined;
        createdEventAssetIdRef.current.value = null;
        hasDeletedOriginalImageRef.current.value = false;
        handleUpdateSortValue(sort + 1);
        setDefaultValues(setQuickAddCodeValues(getValues()));
      } else {
        reset();
      }
      onCreate(isCreateNew as boolean);
    },
    onUpdate: (returnedSnippet) => {
      reset();
      onUpdate(returnedSnippet);
    }
  });


  const { handleGetCodeAsset, isLoading: isGetCodeAssetLoading = false } = useGetCodeAsset({
    onCompleted: ({ returnedAsset: { presignedUrl } }) => {

      if (!presignedUrl) return;
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {
          setDefaultValues(getCodeSnippetDefaultValues({snippet, htmlContent: res.data as string}));
        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: handleGetError,
  });


  const { handleDeleteCodeAsset, isLoading: isDeleteCodeAssetLoading = false } = useDeleteCodeAsset({
    onError: handleGetError,
  });

  const { handleCreateCodeAsset, isLoading: isCreateCodeAssetLoading = false } = useCreateCodeAsset({
    onCompletedAsync: async  ({ returnedAsset: { signedUrl, asset } }) => {

      await pushImageToAws(signedUrl, htmlFileRef.current.value as File, () => {
        const commonVariables = getCodeSnippetCommonVariables({
          codeSnippetAsset: asset,
          item: snippetRef.current.value,
          eventAssetId: String(createdEventAssetIdRef.current.value)
        });

        handleUpsertSnippet({
          containerId,
          snippetId: snippet? Number(snippet.id): undefined,
          sort,
          variables: commonVariables,
        });


        if (defaultValues.initialHtmlAssetId) {
          handleDeleteCodeAsset({
            assetId: defaultValues.initialHtmlAssetId,
          });
        }
      }).catch((error) => {
        handleToastError({
          error,
        });
      });
    },
    onError: handleGetError,
  });

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileEventAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };

  const hasValidSource = (content: string) => {
    const contentWrapper = document.createElement('div');
    contentWrapper.innerHTML = content;
    const contentIframe = contentWrapper.querySelector('iframe');

    return contentIframe == null ? true : isValidURL(contentIframe.src);
  };

  const handleSave = (item: FormFieldProps) => {
    snippetRef.current.value = item;

    const { htmlContent } = item;
    if (!hasValidSource(String(htmlContent))) {
      return;
    }

    handleUpsertEventAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(project?.id),
      uploadedFile: droppedFileEventAssetRef.current.value,
      deletedFileId: hasDeletedOriginalImageRef.current.value && defaultValues.assetId ? defaultValues.assetId : undefined,
      callBack: (asset?: IDBAsset) => {

        createdEventAssetIdRef.current.value = asset ? String(asset.assetId): null;

        const fileHtml = new File([String(htmlContent)], 'code.json', {
          type: 'application/json',
          lastModified: new Date(0).getTime(),
        });

        htmlFileRef.current.value = fileHtml;

        void handleCreateCodeAsset({
          projectId: String(project?.id),
          type: 'CODE',
          contentType: fileHtml.type,
          fileName: fileHtml.name,
          fileSizeInBytes: fileHtml.size,
          alt: 'asset',
        });
      }
    });
  };

  useEffect(() => {
    if (!snippet) {

      setDefaultValues(getCodeSnippetDefaultValues({}));
    } else {
      void handleGetCodeAsset({
        assetId: snippet.location.assetId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet]);

  const isLoading =
    isUpsertSnippetLoading ||
    isGetCodeAssetLoading ||
    isCreateCodeAssetLoading ||
    isDeleteCodeAssetLoading ||
    isUpsertEventAssetLoading ||
    isSnippetLoading;
  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
      <TabSet
        ref={tabsetRef}
        defaultActiveTabId="id_general_tab"
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () => isLoading ? <></> : (
              <UpsertCodeSnippetGeneralTab
                control={control as Control<FormFieldProps>}
              />
            ),
          },
          {
            icon: UsageIcon,
            id: 'id_event_tab',
            label: 'Event',
            view: () => isLoading ? <></> : (
              <EventTab
                control={control as Control<FormFieldProps>}
                initialEvent={defaultValues.event}
                onDeleteAsset={handleRemoveAsset}
                onDrop={handleDropFile}
              />
            ),
          },
          {
            icon: FilmIcon,
            id: 'id_animation_tab',
            label: 'Animation',
            view: () => isLoading ? <></> : (
              <AnimationTab
                animationType={defaultValues.animation ?? ''}
                control={control as Control<FormFieldProps>}
              />
            ),
          },
          {
            icon: StyleTabIcon,
            id: 'id_style_tab',
            label: 'Style',
            view: () => isLoading ? <></> : (
              <StyleTab
                control={control as Control<FormFieldProps>}
                entityType="targetSnippet"
              />
            ),
          },
        ]}
      />
      <SidebarButtons
        additionalButton={!snippet ? (
          <Controller
            control={control as Control<FormFieldProps>}
            name="isCreateNew"
            render={({ field }) => (
              <SingleCheckbox
                hasPadding={false}
                id="id_is_create_new"
                isChecked={field.value}
                labelText="Create another snippet"
                name={field.name}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        ) : undefined
        }
        onCancel={onClose}
        onDelete={onDelete}
        onSaveButtonType="submit"
      />
      </ControlledForm>
    </>
  );
};


export { UpsertCodeSnippetSidebarView };
