import { CopyIcon, SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IDiscountsTableData } from './DiscountsPage.interfaces';

const DISCOUNTS_TABLE_COLUMNS: ReadonlyArray<Column<IDiscountsTableData>> = [
  {
    accessor: 'description',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Title</div>,
  },
  {
    accessor: 'code',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Discount code</div>,
  },
  {
    accessor: 'status',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Status</div>,
  },
  {
    accessor: 'startDate',
    Cell: ({ value }: { value?: number | string | Date }) => <span>{String(value)}</span>,
    Header: () => <div>Start date</div>,
  },
  {
    accessor: 'expiryDate',
    Cell: ({ value }: { value?: number | string | Date }) => <span>{String(value)}</span>,
    Header: () => <div>Expiry date</div>,
  },
  {
    accessor: 'copyCodeData',
    Cell: ({ value: { code, onClick } }) => (
      <button className="copy-code-button" onClick={() => onClick(code)}>
        <CopyIcon className="c-icon copy-code-button__icon" />
      </button>
    ),
    Header: () => <div>Copy code</div>,
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="Update discount" onClick={() => onClick(id)} />,
  },
];

export { DISCOUNTS_TABLE_COLUMNS };
