import React, { useContext, useEffect } from 'react';

import { CMSContentPage, ContentPageProvider, IContentPage, useContentPageContext } from '@netfront/ekardo-content-library';
import { BinIcon, ConditionIcon, EnterIcon, SettingsIcon } from '@netfront/ui-library';
import { ContentBuilderContext } from 'context';
import { IEntityType } from 'interfaces';

const ContentBuilderPreviewInner = ({ contentPage, isEditMode = true }: { contentPage: IContentPage; isEditMode?: boolean } ) => {
  const { openUpdateEntitySidebar, openDeleteEntityDialog, addItemToEntity, openActionsSidebar } = useContext(ContentBuilderContext);
  const { dispatch } = useContentPageContext();


  useEffect(() => {
    dispatch({
      payload: {
        contentPage,
      },
      type: 'loadContentPage',
    });


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPage]);

  return (
    <>
      <CMSContentPage
        editModeCallbacks={{
          addItemToEntityCallback: ({ id: selectedEntityId, type: selectedEntityType }) => addItemToEntity(Number(selectedEntityId), selectedEntityType as IEntityType),
          dropdownOptions: [
            {
              icon: SettingsIcon,
              label: 'Edit',
              isHidden: false,
              onClick: ({ id: entityId, type: entityType }) => openUpdateEntitySidebar(Number(entityId), entityType as IEntityType)
            },
            {
              id: 'id_add_new_item',
              icon: EnterIcon,
              label: 'Add new content',
              isVisibleByTypeArray: ['section', 'container', 'sectionGroup'],
              onClick: ({ id: selectedEntityId, type: selectedEntityType }) => addItemToEntity(Number(selectedEntityId), selectedEntityType as IEntityType),
            },
            {
              id: 'id_entity_actions',
              icon: ConditionIcon,
              label: 'Actions',
              isHidden: false,
              onClick: ({ id: selectedEntityId, type: selectedEntityType }) => openActionsSidebar(Number(selectedEntityId), selectedEntityType as IEntityType),
            },
            {
              icon: BinIcon,
              label: 'Delete',
              isHidden: false,
              onClick: ({ id: entityId, type }) => openDeleteEntityDialog({ id: Number(entityId), type: type as IEntityType })
            },

          ]
        }}
        isEditMode={isEditMode}
      />
    </>
  );
};

const ContentBuilderPreview = ({ contentPage, isEditMode = true }: { contentPage: IContentPage; isEditMode?: boolean }) => {
  return (
    <ContentPageProvider>
      <ContentBuilderPreviewInner contentPage={contentPage} isEditMode={isEditMode} />
    </ContentPageProvider>
  );
};

export { ContentBuilderPreview };
