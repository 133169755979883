import React, { useEffect, useState } from 'react';

import { Spacing, Table, ButtonIconOnly, FlexContainer, InputFieldWrapper, BinIcon, CaretDownIcon, PlusIcon } from '@netfront/ui-library';

import { ConditionsOverviewProps } from '../ConditionTemplateSelector/ConditionsOverview.interfaces';
import { IActionCondition } from '../UpsertAction/UpsertAction.interfaces';


const ConditionsOverview = ({ onEditClick, onDeleteClick, conditionItems }: ConditionsOverviewProps) => {
  const [items, setItems] = useState<IActionCondition[]>([]);

  useEffect(() => {
    setItems(conditionItems ?? []);
  }, [conditionItems]);
  return (
    <>
      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              {
                accessor: 'title',
                Cell: ({ value }: {value: string }) => <span>{value}</span>,
                Header: () => <div>Conditions</div>,
                width: '95%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleEdit,
                    handleDelete,
                    tempId
                  } }: {
                  value : {
                    handleDelete: (tempId: string) => void;
                    handleEdit: (tempId: string) => void;
                    tempId: string
                  }
                }) => (
                  <FlexContainer gap="2x-small" justifyContent="end">
                    <ButtonIconOnly
                      additionalClassNames=""
                      icon={BinIcon}
                      isIconBorderVisible={false}
                      text="Delete condition"
                      onClick={() => handleDelete(tempId)}
                    />
                    <ButtonIconOnly
                      additionalClassNames="h-rotate-right"
                      icon={CaretDownIcon}
                      isIconBorderVisible={false}
                      text="Edit condition"
                      onClick={() => handleEdit(tempId)}
                    />
                  </FlexContainer>
                ),
              },
            ]}
            data={items.map((item) => ({
              id: item.tempId,
              title: item.title,
              settingsButtonData: {
                tempId: item.tempId,
                handleEdit: onEditClick,
                handleDelete: onDeleteClick,
              },
            }))}
          />
        </Spacing>
      )}

      <Spacing>
        <InputFieldWrapper
          id="id_add_condition"
          label="Add condition"
          labelType="span"
          type="custom"
          isLabelSideBySide
        >
          <FlexContainer justifyContent="flex-end" isFullWidth>
            <ButtonIconOnly icon={PlusIcon} text="Add condition" onClick={() => onEditClick(undefined)} />
          </FlexContainer>
        </InputFieldWrapper>
      </Spacing>

    </>
  );
};

export { ConditionsOverview };
