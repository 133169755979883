import { gql } from '@apollo/client';

const CREATE_TRANSCRIPT_MUTATION = gql`
  mutation CreateTranscript($request: BubbleTextInputType!) {
    bubbleText {
      add(request: $request) {
        text
        angle
        x
        y
        w
        shape
        tailType
        isTailVisible
        assetId
        id
        hasShadow
        characterId
        character {
          id
          name
          projectId
          __typename
          image {
            presignedUrl
          }
        }
      }
    }
  }
`;

export { CREATE_TRANSCRIPT_MUTATION };
