import React, { useEffect, useState } from 'react';

import { IContentGroup } from '@netfront/ekardo-content-library';
import { IconRadioGroup, IBreadcrumbItem, SidebarDialog, CodeIcon, LibraryIcon } from '@netfront/ui-library';
import { ChooseContentGroupFromLibrary, ContentBuilderTabWrapper, FormsGeneralTab, handleCancelSelectContentGroup } from 'components';
import { Controller } from 'react-hook-form';

import { UpsertFormSnippetGeneralTabProps } from './UpsertFormSnippetGeneralTab.interfaces';


const UpsertFormSnippetGeneralTab = ({
  control,
  setValue,
  onChooseFromLibrary,
  initialView = 'selector',
  isCreate = false,
  projectId,
}: UpsertFormSnippetGeneralTabProps) => {

  const [view, setView] = useState<string>('selector');
  const [isChooseFromLibraryDialogOpen, setIsChooseFromLibraryDialogOpen] = useState<boolean>(false);
  const [hasChosenFromLibrary, setHasChosenFromLibrary] = useState<boolean>(false);
  const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbItem[]>([]);

  const handleSelectFormFromLibrary = ({ contentGroup }: { contentGroup: IContentGroup }) => {
    setView('general');
    setIsChooseFromLibraryDialogOpen(false);
    setHasChosenFromLibrary(true);
    onChooseFromLibrary({ contentGroup });
  };

  const cancelChooseFromLibrary = () => {
    setView('selector');
    setHasChosenFromLibrary(false);
    handleCancelSelectContentGroup({ setValue });
    setIsChooseFromLibraryDialogOpen(false);
  };

  useEffect(() => {
    setView(initialView);
  }, [initialView])

  useEffect(() => {
    if (view !== 'library') return;
    setIsChooseFromLibraryDialogOpen(true);
  }, [view]);

  useEffect(() => {
    if (!isCreate) return;
    const items: IBreadcrumbItem[] = [
      {
        key: 'form-block',
        onClick: view !== 'selector' ? () => {
          setView('selector');
          setHasChosenFromLibrary(false);
        }: undefined,
        content: view === 'selector' ? <span className="c-breadcrumb-item__link">Form block</span>: 'Form block',
      },
    ]
    if (view !== 'selector') {
      items.push({
        key: 'view',
        content: <span className="c-breadcrumb-item__link">{hasChosenFromLibrary ? 'Library' : 'Create'}</span>
      });
    }
    setBreadcrumbItems(items);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, hasChosenFromLibrary]);

  return (
    <ContentBuilderTabWrapper
      additionalBreadcrumbs={isCreate ? breadcrumbItems: undefined}
      hasSnippetTypeBreadcrumb={false}
    >
      { view === 'selector' && (
        <Controller
          control={control}
          name="view"
          render={({ field }) => (
            <IconRadioGroup
              id="id_select_form_type"
              items={[
                {
                  icon: CodeIcon,
                  label: 'Create new',
                  value: 'general',
                },
                {
                  icon: LibraryIcon,
                  label: `Form library`,
                  value: 'library',
                },
              ]}
              label="Type"
              name={field.name}
              selectedValue={view}
              hasLabels
              isLabelHidden
              isOutlined
              onChange={(value) => {
                field.onChange(value);
                setView(value);
                if (value === 'embed') setValue('type', 'embed');
              }}
            />
          )}
        />

      )}
      { view === 'general' && (
        <FormsGeneralTab
          control={control}
          isReadOnly={isCreate && hasChosenFromLibrary}
          isInContentBuilder
        />
      )}

      {view === 'library' && (
        <SidebarDialog
          isOpen={isChooseFromLibraryDialogOpen}
          onClose={cancelChooseFromLibrary}
        >
          {isChooseFromLibraryDialogOpen && (
            <ChooseContentGroupFromLibrary
              additionalBreadcrumbs={breadcrumbItems}
              contentGroupType="FORM"
              projectId={projectId}
              hasContainer
              onCancel={cancelChooseFromLibrary}
              onSave={handleSelectFormFromLibrary}
            />
          )}
        </SidebarDialog>
      )}
    </ContentBuilderTabWrapper>
  );
};

export { UpsertFormSnippetGeneralTab };
