import { useEffect, useState } from 'react';

import { IDropDownListOption } from '@netfront/ekardo-content-library';
import { IDBGroup, IDBUser, useGetPaginatedGroups } from '@netfront/gelada-identity-library';
import {
  Button,
  ButtonIconOnly,
  CheckboxGroup,
  CloseIcon,
  FlexContainer,
  ICheckboxItem,
  InformationBox,
  InputFieldWrapper,
  PlusIcon,
  SelectWithSearch,
  Spacing,
} from '@netfront/ui-library';
import { useGetGroupMemberships, useToast } from 'hooks';
import { useRouter } from 'next/router';

import { SidebarContainer } from 'components/SidebarContainer';

import { IUsersDetails } from './InviteNotificationGroupSidebarView.interface';

export const InviteNotificationGroupSidebarView = ({ users, onUpdate, isReadOnly }: IUsersDetails) => {
  const {
    query: { projectId: queryProjectId }
  } = useRouter();
  const { handleToastError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [allGroups, setAllGroups] = useState<IDBGroup[]>([]);
  const [memberships, setMemberships] = useState<IDBUser[]>([]);
  const [selectedMemberships, setSelectedMemberships] = useState<IDBUser[]>(users);
  const [isCommunitySearchContentVisible, setSearchIsCommunityContentVisible] = useState<boolean>(false);

  useEffect(() => {
    onUpdate(selectedMemberships);
    setMemberships([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  const getGroupOptions = (): IDropDownListOption[] => {
    return allGroups.map(({ id, name }) => ({
      id: String(id),
      label: name,
      value: String(id),
    }));
  };

  const getSelectedMembershipsOptions = (): ICheckboxItem[] => {
    return selectedMemberships.map((membership) => {
      const { firstname, lastname, credential, id } = membership as unknown as {
        credential: { email: string };
        firstname: string;
        id: number;
        lastname: string;
      };
      const { email } = credential;
      return {
        id: String(id),
        labelText: `${String(firstname)} ${String(lastname)} (${String(email)})`,
        value: String(id),
      };
    });
  };

  const getMembershipsOptions = (): ICheckboxItem[] => {
    return memberships
      .filter((r) => !selectedMemberships.find((s) => s.id == r.id))
      .map((membership) => {
        const { firstname, lastname, credential, id } = membership as unknown as {
          credential: { email: string };
          firstname: string;
          id: number;
          lastname: string;
        };
        const { email } = credential;
        return {
          id: String(id),
          labelText: `${String(firstname)} ${String(lastname)} (${String(email)})`,
          value: String(id),
        };
      });
  };

  const { handleGetGroupMemberships } = useGetGroupMemberships({
    onCompleted(data) {
      const { memberships: currentMemberships } = data;
      setMemberships(currentMemberships.map((r) => r.user) as IDBUser[]);
    },
  });

  const { handleGetPaginatedGroups } = useGetPaginatedGroups({
    fetchPolicy: 'network-only',
    onCompleted: ({ groupConnection: { edges } }) => {
      const groups = edges.map(({ node }) => node);
      setAllGroups(groups);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleGetPaginatedGroups({
      first: 200,
      projectId: String(projectId),
      status: 'ACTIVE',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const onUserUnselected = (selectedItems: string[]) => {
    setSelectedMemberships((oldArray) => oldArray.filter((r) => selectedItems.find((d) => Number(d) === r.id)));
  };

  const onUserSelected = (selectedItems: string[]) => {
    selectedItems.map((e) => {
      const membership = memberships.find((r) => r.id === Number(e));
      if (!membership || selectedMemberships.find((r) => r.id == membership.id)) {
        return;
      }
      setSelectedMemberships((oldArray) => [...oldArray, membership]);
    });
  };

  const onSelectAll = () => {
    memberships
      .filter((r) => !selectedMemberships.find((s) => s.id == r.id))
      .map((membership) => {
        setSelectedMemberships((oldArray) => [...oldArray, membership]);
      });
  };

  const onUnselectAll = () => {
    setSelectedMemberships([]);
  };

  return (
    <SidebarContainer>
      {!isReadOnly && (
        <FlexContainer justifyContent="end">
          <ButtonIconOnly
            additionalClassNames="border-none"
            icon={isEditMode ? CloseIcon : PlusIcon}
            text="close sidebar"
            onClick={() => setIsEditMode(!isEditMode)}
          />
        </FlexContainer>
      )}
      {!isEditMode && selectedMemberships.length > 0 && (
        <Spacing>
          <InputFieldWrapper id="users" label={`Selected users (${selectedMemberships.length})`} type="select" isLabelSideBySide isRequired>
            <>
              <br />
              <div className="notification-groups-user-selection">
                <ul className="notification-group-selected-users-list">
                  {selectedMemberships.map((r) => (
                    <li key={`${r.id}`}>
                      <p>
                        {r.firstName} {r.lastName} ({r.credential.email})
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          </InputFieldWrapper>
        </Spacing>
      )}
      {isEditMode && (
        <>
          <Spacing size="x-large">
            <SelectWithSearch
              buttonText="Available groups" 
              countText="groups"
              id="groups"
              isDisplaySearchContent={isCommunitySearchContentVisible}
              labelText="Groups"
              searchList={getGroupOptions()}
              isLabelSideBySide
              isRequired
              onDisplaySearchContent={() => setSearchIsCommunityContentVisible(!isCommunitySearchContentVisible)}
              onSearchItemClick={(id) => {
                void handleGetGroupMemberships({ groupId: Number(id) });
              }}
            />
          </Spacing>
          {selectedMemberships.length === 0 && memberships.length === 0 && (
            <Spacing>
              <InformationBox message="Not any user selected. Select a group and choose the users that you want to notify." />
            </Spacing>
          )}
          {memberships.length > 0 && (
            <Spacing>
              <InputFieldWrapper id="users" label={`Add new users (${memberships.filter((r) => !selectedMemberships.find((s) => s.id == r.id)).length})`} type="custom" isLabelSideBySide isRequired>
                <>
                  <br />
                  <Button text="Select all" variant="secondary" onClick={onSelectAll} />
                  <div className="notification-groups-user-selection">
                    <CheckboxGroup
                      items={getMembershipsOptions()}
                      legendText=""
                      name="userSelection"
                      values={selectedMemberships.map((r) => String(r.id))}
                      onChange={onUserSelected}
                    />
                  </div>
                </>
              </InputFieldWrapper>
            </Spacing>
          )}
          {selectedMemberships.length > 0 && (
            <Spacing>
              <InputFieldWrapper id="users" label={`Selected users (${selectedMemberships.length})`} type="custom" isLabelSideBySide isRequired>
                <>
                  <br />
                  <Button text="Unselect all" variant="secondary" onClick={onUnselectAll} />
                  <div className="notification-groups-user-selection">
                    <CheckboxGroup
                      items={getSelectedMembershipsOptions()}
                      legendText=""
                      name="userSelection"
                      values={getSelectedMembershipsOptions().map((r) => r.value)}
                      onChange={onUserUnselected}
                    />
                  </div>
                </>
              </InputFieldWrapper>
            </Spacing>
          )}
          {Boolean(isEditMode) && (
            <Spacing>
              <Button size="xs" text="Update" variant="secondary" onClick={() => onUpdate(selectedMemberships)} />
            </Spacing>
          )}
        </>
      )}
    </SidebarContainer>
  );
};
