import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_QUESTION_CONFIGURATION } from './useUpdateQuestionConfiguration.graphql';
import {
  IUpdateQuestionConfigurationMutationGraphQLResponse,
  IUpdateQuestionConfigurationMutationVariables,
  IHandleUpdateQuestionConfigurationParams,
  IUseUpdateQuestionConfigurationOptions,
  IUseUpdateQuestionConfiguration,
} from './useUpdateQuestionConfiguration.interfaces';

const useUpdateQuestionConfiguration = (options?: IUseUpdateQuestionConfigurationOptions): IUseUpdateQuestionConfiguration => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateQuestionConfigurationOptions);

  const [executeUpdateQuestionConfiguration, { loading: isLoading }] = useEkardoMutation<IUpdateQuestionConfigurationMutationGraphQLResponse, IUpdateQuestionConfigurationMutationVariables>({
    mutation: mutation ?? UPDATE_QUESTION_CONFIGURATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { updateQuestionConfiguration: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateQuestionConfiguration = async ({
    questionConfigurationId,
    questionConfigurationCheckbox,
    questionConfigurationRadio,
  }: IHandleUpdateQuestionConfigurationParams) => {
    await executeUpdateQuestionConfiguration({
      variables: {
        questionConfigurationId,
        questionConfigurationCheckbox,
        questionConfigurationRadio,
      }
    });
  };

  return {
    handleUpdateQuestionConfiguration,
    isLoading,
  };
};

export { useUpdateQuestionConfiguration };
