import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { LINK_ENTITY_TO_APP } from './useLinkEntitiesToApp.graphql';
import {
  ILinkEntitiesToAppMutationGraphQLResponse,
  ILinkEntitiesToAppMutationVariables,
  IHandleLinkEntitiesToAppParams,
  IUseLinkEntitiesToAppOptions,
  IUseLinkEntitiesToApp,
} from './useLinkEntitiesToApp.interfaces';

const useLinkEntitiesToApp = (options?: IUseLinkEntitiesToAppOptions): IUseLinkEntitiesToApp => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseLinkEntitiesToAppOptions);

  const [executeLinkEntitiesToApp, { loading: isLoading }] = useEkardoMutation<
    ILinkEntitiesToAppMutationGraphQLResponse,
    ILinkEntitiesToAppMutationVariables
  >({
    mutation: mutation ?? LINK_ENTITY_TO_APP,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: { addAuhorizedEntities: isCompleted },
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleLinkEntitiesToApp = ({ inputType }: IHandleLinkEntitiesToAppParams) => {
    void executeLinkEntitiesToApp({
      variables: {
        inputType,
      },
    });
  };

  return {
    handleLinkEntitiesToApp,
    isLoading,
  };
};

export { useLinkEntitiesToApp };
