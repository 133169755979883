import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentPage } from '@netfront/ekardo-content-library';
import { FormFieldProps } from '@netfront/ui-library';
import { ICreatePageMutationParams, IUpdatePageMutationParams, useAttachPageCssStyle, useCreatePage, useToast, useUpdatePage } from 'hooks';



const useUpsertPage = ({ onCreate, onUpdate }: { onCreate: (returnedContentPage: IContentPage) => void; onUpdate: (returnedContentPage: IContentPage) => void }) => {
  const pageVariables = useRef<{value: FormFieldProps | undefined}>({value: undefined});
  const contentPageRef = useRef<{value: IContentPage | undefined}>({value: undefined});

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const getPageVariables = ({contentGroupId, contentPageId, type }: {contentGroupId?: number; contentPageId?: number; type: 'create' | 'update'; }): ICreatePageMutationParams | IUpdatePageMutationParams => {

    const { 
      assetId, 
      sensitiveContent, 
      seoDescription, 
      tags, 
      title, 
      url, 
      sort, 
      parentId, 
      seoTitle,
      releaseDate,
      minimumExposureTime,
      mode,
      contentPageType, 
      status,
      audioId,
      contentPageConfigurationNavigation,
      customTheme = {},
    } = pageVariables.current.value ?? {};

    const {
      styleId,
      shouldAttachCustomTheme,
      shouldDetachCustomTheme,
    } = customTheme; 

    const variableMap = {
      create: {
        assetId,
        audioId,
        contentGroupId: Number(contentGroupId),
        parentId,
        sensitiveContent,
        seoDescription,
        seoTitle,
        tags,
        title,
        url,
        // releaseDate,
        // minimumExposureTime,
        // mode,
        contentPageType,
        // contentPageConfigurationNavigation,
        status,
      } as ICreatePageMutationParams,
      update: {
        assetId,
        // audioId,
        contentPageId,
        parentId,
        sensitiveContent,
        seoDescription,
        seoTitle,
        sort: Number(sort),
        tags,
        title,
        url,
        styleId: styleId ?? 0,
        shouldAttachCustomTheme,
        shouldDetachCustomTheme,
        releaseDate,
        minimumExposureTime,
        mode,
        contentPageType,
        // status,
        contentPageConfigurationNavigation: {
          contentPageId,
          ...contentPageConfigurationNavigation,
        }
      } as IUpdatePageMutationParams
    };

    return variableMap[type];
  };

  const { handleAttachPageCssStyle, isLoading: isAttachPageCssStyleLoading = false } = useAttachPageCssStyle({
    onError: handleGetError,
    onCompleted: () => {
      handleToastSuccess({
        message: 'Page successfully created',
      });

      if (contentPageRef.current.value)
        onCreate(contentPageRef.current.value);
    }
  });



  const { handleCreatePage, isLoading: isCreatePageLoading = false } = useCreatePage({
    onCompleted: ({ contentPage: returnedContentPage }) => {
      const { customTheme = {} } = pageVariables.current.value ?? {};
      const { shouldAttachCustomTheme = false, styleId } = customTheme;

      if (shouldAttachCustomTheme) {
        contentPageRef.current.value = returnedContentPage;
        handleAttachPageCssStyle({
          contentPageId: returnedContentPage.id,
          styleId,
        });

      } else {
        handleToastSuccess({
          message: 'Page successfully created',
        });
        onCreate(returnedContentPage);
      }
      
    },
    onError: handleGetError,
  });

  const { handleUpdatePage, isLoading: isUpdatePageLoading = false } = useUpdatePage({
    onCompleted: ({ contentPage: returnedContentPage }) => {
      handleToastSuccess({
        message: 'Page successfully updated',
      });
      onUpdate(returnedContentPage);
    },
    onError: handleGetError,
  });

  const handleUpsertPage = ({ contentGroupId, contentPageId, variables }: {contentGroupId?: number; contentPageId?: number; variables: FormFieldProps; }) => {
    pageVariables.current.value = variables;

    if (!contentPageId) {

      const createPageVariables = getPageVariables({
        contentPageId: Number(contentPageId),
        contentGroupId: Number(contentGroupId),
        type: 'create',
      }) as ICreatePageMutationParams;

      handleCreatePage({
        ...createPageVariables
      });

    } else {
      const updatePageVariables = getPageVariables({
        contentPageId: Number(contentPageId),
        type: 'update',
      }) as IUpdatePageMutationParams;

      handleUpdatePage({
        ...updatePageVariables,
      });
    }
  };

  return {
    isLoading: isCreatePageLoading || isUpdatePageLoading || isAttachPageCssStyleLoading,
    handleUpsertPage,
  };
};
export { useUpsertPage };
