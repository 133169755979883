import { gql } from '@apollo/client';

const CREATE_CONTENT_GROUP = gql`
  mutation CreateContentGroup($contentGroup: CreateContentGroupInput!) {
    contentGroup {
      createContentGroup(
        contentGroup: $contentGroup
      ) {
        id
        url
      }
    }
  }
`;

export { CREATE_CONTENT_GROUP };
