import { InformationBox, SingleDatePicker, Spacing, ToggleSwitch } from '@netfront/ui-library';
import { SidebarContainer } from 'components';

import { IScheduleViewProps } from './ScheduleSidebarView.interfaces';

export const ScheduleSidebarView = ({ isSchedule, date, onDateUpdate, onIsScheduleUpdate, isReadOnly }: IScheduleViewProps) => {
  return (
    <SidebarContainer>
      <Spacing size="small">
        <ToggleSwitch
          id="schedule"
          isChecked={isSchedule}
          isDisabled={isReadOnly}
          labelText="Schedule"
          isLabelSideBySide
          onChange={() => onIsScheduleUpdate(!isSchedule)}
        />
      </Spacing>
      {isSchedule && (
        <Spacing size="x-large">
          <SingleDatePicker 
            config={{
              maxDate: new Date(),
              minDate: new Date(),
            }} 
            dateInputProps={{
              labelText: 'Select date',
              id: 'id_selectDate',
              name: 'selectDate',
              type: 'text',
              isLabelSideBySide: true,
            }}
            selectedDates={date ? [date] : undefined} 
            onSelected={(dates: Date[]) => {
              if (dates[0] && !isReadOnly) {
                onDateUpdate(dates[0])
              }
            }} 
          />
        </Spacing>
      )}
      {!isSchedule && (
        <Spacing>
          <InformationBox message="The notifications will be sent straight away." />
        </Spacing>
      )}
    </SidebarContainer>
  );
};
