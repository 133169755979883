import React, { useEffect, useState } from 'react';

import { HomeIcon, SidebarDialog } from '@netfront/ui-library';
import { ITranscriptAnswer } from 'components';

import { TranscriptAnswersOverview } from '../TranscriptAnswersOverview';
import { UpsertTranscriptQuizAnswer } from '../UpsertTranscriptQuizAnswer';

import { TranscriptQuizAnswersProps } from './TranscriptQuizAnswers.interfaces';




const TranscriptQuizAnswers = ({ 
  initialTranscriptAnswers = [], 
  watch,
  setValue,
  isCreateTranscript,
  backToTranscriptListOnClick,
  getValues,
 }: TranscriptQuizAnswersProps) => {
  const [answers, setAnswers] = useState<ITranscriptAnswer[]>([]);
  const [isUpsertAnswerSidebarOpen, setIsUpsertAnswerSidebarOpen] = useState<boolean>(false);
  const [selectedAnswer, setSelectedAnswer] = useState<ITranscriptAnswer>();
  const [isMultiResponse, setIsMultiResponse] = useState<boolean>();


  const handleUpsertTranscriptAnswer = (transcriptAnswerId?: string) => {
    const { isMultiResponse: isCurrentMultiResponse } = getValues();

    setIsMultiResponse(isCurrentMultiResponse as boolean);
    const answer = answers.find(({ tempId }) => tempId === transcriptAnswerId);
    setIsUpsertAnswerSidebarOpen(true);
    setSelectedAnswer(answer);
  };

  const cancelUpsertTranscriptAnswer = () => {
    setIsUpsertAnswerSidebarOpen(false);
    setSelectedAnswer(undefined);
  };

  const deleteTranscriptAnswer = (transcriptAnswerId: string) => {
    const { correctAnswer } = getValues();
    const updatedAnswers = answers.filter(({ tempId }) => tempId !== transcriptAnswerId);
    setValue('answers', updatedAnswers);
    if (correctAnswer === transcriptAnswerId) {
      setValue('correctAnswer', '');
    }
    cancelUpsertTranscriptAnswer();
  };

  const onUpsertTranscriptAnswer = (upsertedAnswer: ITranscriptAnswer) => {
    if (selectedAnswer) {
      const updatedAnswers = answers.map((answer) => {
        if (answer.tempId === upsertedAnswer.tempId) return upsertedAnswer;
        return answer;
      });
      setValue('answers', updatedAnswers);
    } else {
      setValue('answers', [...answers, upsertedAnswer]);
    }
    cancelUpsertTranscriptAnswer();
  };


  useEffect(() => {
    // We need to do this in order to stop watch from re-rendering everything from within the parent container on every change
    const subscription = watch((value) => {
      const { answers: updatedAnswers = [] } = value;
      if (updatedAnswers !== answers) {
        setAnswers(updatedAnswers as ITranscriptAnswer[]);
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

  useEffect(() => {
    setAnswers(initialTranscriptAnswers);
  }, [initialTranscriptAnswers])

  return (
    <>
      <TranscriptAnswersOverview
        transcriptAnswerItems={answers}
        onDeleteClick={deleteTranscriptAnswer}
        onEditClick={handleUpsertTranscriptAnswer}
      />
      <SidebarDialog
        isOpen={isUpsertAnswerSidebarOpen}
        onClose={cancelUpsertTranscriptAnswer}
      >
        {isUpsertAnswerSidebarOpen && (
          <UpsertTranscriptQuizAnswer
            breadcrumbItems={[
              {
                key: 'transcript-lister',
                onClick: backToTranscriptListOnClick,
                icon: HomeIcon,
                content: 'Transcript list',
              },
              {
                key: 'upsert-transcript',
                onClick: cancelUpsertTranscriptAnswer,
                content: `${isCreateTranscript ? 'Create' : 'Edit'} transcript`
              },
              {
                key: 'upsert-transcript-answer',
                content: <span className="c-breadcrumb-item__link">{selectedAnswer ? 'Edit' : 'Create'} answer</span>
              }
            ]}
            isMultiResponse={isMultiResponse}
            selectedAnswer={selectedAnswer}
            onCancel={cancelUpsertTranscriptAnswer}
            onDelete={deleteTranscriptAnswer}
            onUpdate={onUpsertTranscriptAnswer}
          />
        )}
      </SidebarDialog>
    </>
  );
};


export { TranscriptQuizAnswers };
