import { gql } from '@apollo/client';

const UPDATE_CONTENT_GROUP_STATUS = gql`
  mutation UpdateContentGroup(
    $contentGroupId: Int!
    $status: EContentGroupStatus!
  ) {
    contentGroup {
      updateContentGroupStatus(id: $contentGroupId, status: $status) {
        id
        url
      }
    }
  }
`;

export { UPDATE_CONTENT_GROUP_STATUS };
