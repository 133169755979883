import { IAssetTag } from "@netfront/common-library";
import { IContentPage } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getAssetDefaultValues, getFormattedContentStyles } from "components";
import { ContentPageType } from "interfaces";
import capitalize from "lodash.capitalize";

const getCartoonPageDefaultValues = ({ page, cartoonType } : { cartoonType?: ContentPageType; page?: IContentPage; }): FormFieldProps => {
  const {
    seoDescription,
    seoTitle,
    title: pageTitle,
    url,
    status,
    tags,
    asset,
    releaseDate,
    contentPageTypeId,
    minimumExposureTime,
    mode = [],
    audio,
    relatedPage = [],
    contentPageConfigurationNavigation,
    sensitiveContent: isSensitive,
    cssStyle,
  } = page ?? {};

  const { assetId, presignedUrl, isAnimatedImage} = asset ?? {};

  const { 
    enableBackButton: isEnableBackButton = false, 
    enableNextButton: isEnableNextButton = false,
    backButtonToolTip,
    nextButtonToolTip,
  } = contentPageConfigurationNavigation ?? {};

  const audioAssetDetails = getAssetDefaultValues({
    assetType: 'audio',
    asset: audio,
    assetIdKey: 'audioAssetId',
  });

  const { id: styleId } = cssStyle ?? {};

  return {
    pageTitle: pageTitle ?? capitalize(cartoonType),
    seoTitle: seoTitle ?? '',
    seoDescription: seoDescription ?? '',
    url: url ?? '',
    imagePresignedUrl: presignedUrl ?? '',
    entityStyleId: styleId,
    tags: tags ?? [],
    status: status ?? 'PUBLISHED',
    imageAssetId: assetId ?? '',
    isSensitive,
    isAnimatedImage: isAnimatedImage ?? false,
    releaseDate: releaseDate ?? '',
    minimumExposureTime: minimumExposureTime ?? '',
    contentPageType: contentPageTypeId ?? '',
    isAssessment: mode.includes('ASSESSMENT'),
    isActivity: mode.includes('ACTIVITY'),
    isEnableBackButton,
    isEnableNextButton,
    backButtonToolTip: backButtonToolTip ?? '',
    nextButtonToolTip: nextButtonToolTip ?? '',
    relatedPage,
    ...audioAssetDetails,
    view: audio ? 'upload': 'selector',
  }
};

const getCartoonPageCommonVariables = ({ 
  item,
  parentId,
  uploadedAssetId,
  sort,
  uploadedAudioAssetId,
}: {  
  item: FormFieldProps; 
  parentId?: number; 
  sort?: number; 
  uploadedAssetId?: string; 
  uploadedAudioAssetId?: string; 
}) => {

  const {
    pageTitle = 'Page',
    assetId,
    seoTitle,
    seoDescription,
    status,
    tags = [],
    url,
    isSensitive = false,
    releaseDate,
    minimumExposureTime,
    contentPageType,
    isAssessment,
    isActivity,
    audioAssetId,
    isEnableBackButton = false,
    isEnableNextButton = false,
    backButtonToolTip,
    nextButtonToolTip,
  } = item;

  const { customTheme } = getFormattedContentStyles({ item });

  const mode = [];

  if (isAssessment) mode.push('ASSESSMENT');
  if (isActivity) mode.push('ACTIVITY');

  const sharedVariables = {
    title: pageTitle,
    assetId: uploadedAssetId ?? assetId,
    audioId: uploadedAudioAssetId ?? audioAssetId,
    parentId,
    seoDescription,
    seoTitle,
    sensitiveContent: isSensitive,
    sort,
    url,
    status,
    customTheme,
    tags: (tags as IAssetTag[]).map(({ id }) => Number(id)),
    releaseDate: releaseDate ? new Date(String(releaseDate)): undefined,
    minimumExposureTime: Number(minimumExposureTime),
    contentPageType: contentPageType ?  Number(contentPageType): undefined,
    mode,
    contentPageConfigurationNavigation: {
      backButtonToolTip: isEnableBackButton ? backButtonToolTip : '',
      nextButtonToolTip: isEnableNextButton ? nextButtonToolTip : '',
      enableBackButton: isEnableBackButton, 
      enableNextButton: isEnableNextButton,
    }
  };

  return sharedVariables;

}




export { getCartoonPageDefaultValues, getCartoonPageCommonVariables };
