import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import {
  ControlledForm,
  Dialog,
  FormFieldProps,
  Input,
  SidebarButtons,
  Spacing,
  Spinner,
  useControlledForm,
} from '@netfront/ui-library';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { SidebarContainer } from 'components/SidebarContainer';

import { TagsGeneralTabProps } from './TagsGeneralTab.interfaces';

import { useCreateTag, useDeleteTag, useToast, useUpdateTag } from '../../../../hooks';


const TagsGeneralTab = ({
  tagItem,
  onClose,
  onUpdate,
  projectId,
}: TagsGeneralTabProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({
    assetId: '',
    search: '',
  });

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Name'),
      }),
    ),
  });

  const { handleCreateTag, isLoading: isCreateTagLoading = false } = useCreateTag({
    onCompleted: () => {
      reset();
      onUpdate();
      handleToastSuccess({ message: 'Tag created successfully' });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateTag, isLoading: isUpdateTagLoading = false } = useUpdateTag({
    onCompleted: () => {
      reset();
      onUpdate();
      handleToastSuccess({ message: 'Tag updated successfully' });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteTag, isLoading: isDeleteTagLoading = false } = useDeleteTag({
    onCompleted: () => {
      reset();
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleSave = (item: FormFieldProps) => {
    const { id, name} = item;

    if (id) {
      handleUpdateTag({
        value: name,
        tagId: Number(id),
      });
    } else {
      handleCreateTag({
        tagName: name,
        projectId,
      });
    }
  };


  const handleDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    const { id } = defaultValues;
    handleDeleteTag({
      tagId: Number(id),
    });
  };


  useEffect(() => {

    if (!tagItem) {
      setDefaultValues({
        id: undefined,
        name: '',
      });
    } else {
      const { id, name } = tagItem;
      setDefaultValues({
        id,
        name,
      });
    }

  }, [tagItem])
  const isLoading = isCreateTagLoading || isDeleteTagLoading || isUpdateTagLoading;


  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
        <ControlledForm
          callBack={(item: FormFieldProps) => {
            handleSave(item);
          }}
          handleSubmit={handleSubmit}
          isStopPropagation
        >
          <Spacing>
            <Controller
              control={control as Control<FormFieldProps>}
              name="name"
              render={({ field, fieldState }) => (
                <Input
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="name"
                  labelText="Name"
                  maxLength={100}
                  tooltipText="Name of the tag"
                  type="text"
                  isLabelSideBySide
                  isRequired
                  {...field}
                />
              )} 
            />
          </Spacing>

          <SidebarButtons
            buttonSize="xs"
            onCancel={onClose}
            onDelete={tagItem ? handleDelete: undefined}
            onSaveButtonType="submit"
          />
        </ControlledForm>
      {tagItem && (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete ${String(tagItem.name)}?`}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleConfirmDelete}
        />
      )}
    </SidebarContainer>
  );
};

export { TagsGeneralTab };
