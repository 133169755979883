import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { BULK_UPDATE_ASSET_TAGS_MUTATION } from './useBulkUpdateAssetTags.graphql';
import {
  IBulkUpdateAssetTagsMutationGraphQLResponse,
  IBulkUpdateAssetTagsMutationVariables,
  IHandleBulkUpdateAssetTagsParams,
  IUseBulkUpdateAssetTagsOptions,
  IUseBulkUpdateAssetTags,
} from './useBulkUpdateAssetTags.interfaces';

const useBulkUpdateAssetTags = (options?: IUseBulkUpdateAssetTagsOptions): IUseBulkUpdateAssetTags => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseBulkUpdateAssetTagsOptions);

  const [executeBulkUpdateAssetTags, { loading: isLoading }] = useEkardoMutation<
    IBulkUpdateAssetTagsMutationGraphQLResponse,
    IBulkUpdateAssetTagsMutationVariables
  >({
    mutation: mutation ?? BULK_UPDATE_ASSET_TAGS_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { setTagsOnAssets: isCompleted },
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkUpdateAssetTags = ({
    assetIds,
    tagIds,
  }: IHandleBulkUpdateAssetTagsParams) => {
    void executeBulkUpdateAssetTags({
      variables: {
        assetIds,
        tagIds,
      },
    });
  };

  return {
    handleBulkUpdateAssetTags,
    isLoading,
  };
};

export { useBulkUpdateAssetTags };
