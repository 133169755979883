import React, { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Dialog, IconRadioItemProps, SidebarDialog, Spinner } from '@netfront/ui-library';
import { ContentBuilderTabWrapper } from 'components';
import { useDeleteTranscript, useGetTranscripts, useGetTranscriptShapeTypes, useGetTranscriptTailTypes, useToast } from 'hooks';
import { ITranscript } from 'interfaces';
import capitalize from 'lodash.capitalize';

import { TranscriptsOverview, UpsertTranscript } from './Components';
import { TRANSCRIPT_SHAPE_TYPES, TRANSCRIPT_TAIL_TYPES } from './TranscriptTab.constants';
import { getTranscriptTypeLabel } from './TranscriptTab.helpers';
import { TranscriptTabProps } from './TranscriptTab.interfaces';


const TranscriptTab = ({ entityId, type, assetId, projectId, handleRefreshPage, imageUrl }: TranscriptTabProps) => {
  const { handleToastError } = useToast();

  const [transcriptItems, setTranscriptItems] = useState<ITranscript[]>([]);
  const [isUpsertTranscriptSidebarOpen, setIsUpsertTranscriptSidebarOpen] = useState<boolean>(false);
  const [selectedTranscript, setSelectedTranscript] = useState<ITranscript>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [deleteTranscriptId, setDeleteTranscriptId] = useState<number>();
  const [shapeTypes, setShapeTypes] = useState<IconRadioItemProps[]>([]);
  const [tailTypes, setTailTypes] = useState<IconRadioItemProps[]>([]);

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetTranscripts, isLoading: isGetTranscriptsLoading = false } = useGetTranscripts({
    fetchPolicy: 'no-cache',
    onCompleted: ({ bubbleTexts  }) => {

      setTranscriptItems(bubbleTexts);

    },
    onError: handleGetError,
  });


  const { handleGetTranscriptShapeTypes, isLoading: isGetTranscriptShapeTypesLoading = false } = useGetTranscriptShapeTypes({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ enumValues }) => {
      const shapeOrder = ['OVAL', 'RECTANGLE', 'THINK', 'BLAST', 'PARA', 'FACT_CHECK' , 'INPUT', 'QUIZ', 'LABEL', 'TITLE', 'STOP_AND_THINK', 'CHAT_TO'];

      setShapeTypes(
        // we remove STOP_AND_THINK_NO_INPUT && CHAT_FROM because we have extra logic to determine the variations
        [...enumValues].filter((value) => !['REGIONS', 'STOP_AND_THINK_NO_INPUT', 'CHAT_FROM'].includes(value.name))
        .sort((a, b) => {
          return shapeOrder.indexOf(a.name) - shapeOrder.indexOf(b.name);
        })
        .map((value) => {
          const { name } = value;
          return {
            icon: TRANSCRIPT_SHAPE_TYPES[name],
            label: getTranscriptTypeLabel(name),
            value: name === 'CHAT_TO' ? 'CHAT' : name,
          };
        }),
      );
    },
    onError: handleGetError,
  });

  const { handleGetTranscriptTailTypes, isLoading: isGetTranscriptTailTypesLoading = false } = useGetTranscriptTailTypes({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ enumValues }) => {

      const tailOrder = ['NONE', 'DEFAULT', 'THINK'];

      setTailTypes(
        [...enumValues].sort((a, b) => {
          return tailOrder.indexOf(a.name) - tailOrder.indexOf(b.name);
        }).map((value) => {
          const { name } = value;
          return {
            icon: TRANSCRIPT_TAIL_TYPES[name],
            label: capitalize(String(name).toLowerCase()),
            value: name,
          };
        }),
      );
    },
    onError: handleGetError,
  });

  const { handleDeleteTranscript, isLoading: isDeleteTranscriptLoading = false } = useDeleteTranscript({
    onCompleted: () => {

      setDeleteTranscriptId(undefined);
      setIsDeleteDialogOpen(false);
      onUpsertTranscript();

    },
    onError: handleGetError,
  });

  const cancelUpsertTranscript = () => {
    setIsUpsertTranscriptSidebarOpen(false);
    setSelectedTranscript(undefined);
  };

  const onUpsertTranscript = () => {
    cancelUpsertTranscript();
    void handleGetTranscripts({
      entityId,
      type,
    });
    handleRefreshPage?.();

  };

  const handleUpsertTranscript = (transcriptId?: number) => {
    const transcript = transcriptItems.find(({ id }) => id === transcriptId);
    setIsUpsertTranscriptSidebarOpen(true);
    setSelectedTranscript(transcript);
  };

  const deleteTranscript = (transcriptId: number) => {
    setDeleteTranscriptId(transcriptId);
    setIsDeleteDialogOpen(true);
  };

  const cancelDelete = () => {
    setDeleteTranscriptId(undefined);
    setIsDeleteDialogOpen(false);
  };


  useEffect(() => {
    void handleGetTranscripts({
      entityId,
      type,
    });

    handleGetTranscriptTailTypes();
    handleGetTranscriptShapeTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);


  const isLoading = isGetTranscriptsLoading || isDeleteTranscriptLoading || isGetTranscriptShapeTypesLoading || isGetTranscriptTailTypesLoading;
  
  return (
    <ContentBuilderTabWrapper isSnippet={type === 'targetSnippet'}>
      <TranscriptsOverview 
        transcriptItems={transcriptItems}
        onDeleteClick={deleteTranscript}
        onEditClick={handleUpsertTranscript}
      />
      <Spinner isLoading={isLoading} />

      <SidebarDialog
        isOpen={isUpsertTranscriptSidebarOpen}
        onClose={cancelUpsertTranscript}
      >
        {isUpsertTranscriptSidebarOpen && (
          <UpsertTranscript
            assetId={assetId}
            imageUrl={imageUrl}
            projectId={projectId}
            selectedTranscript={selectedTranscript}
            shapeTypes={shapeTypes}
            tailTypes={tailTypes}
            transcriptItems={transcriptItems}
            onCancel={cancelUpsertTranscript}
            onDelete={deleteTranscript}
            onUpdate={onUpsertTranscript}
          />
        )}
      </SidebarDialog>
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete transcript"
        isNarrow
        onCancel={cancelDelete}
        onClose={cancelDelete}
        onConfirm={() => {
          if (!deleteTranscriptId) return;

          handleDeleteTranscript({
            transcriptId: deleteTranscriptId
          });
        }}
      />
    </ContentBuilderTabWrapper>
  );
};

export { TranscriptTab };
