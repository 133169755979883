import { FormEvent, useState } from 'react';

import {
  ButtonIconOnly,
  CloseIcon,
  FlexContainer,
  InputFieldWrapper,
  SidebarButtons,
  Spacing,
  Spinner,
  Table,
  XlsxIcon,
} from '@netfront/ui-library';
import { SidebarContainer, Uploader } from 'components';
import ExcelJS from 'exceljs';


import { GlossaryItemBulkAddTabProps, IBulkUploadGlossaryItem } from './GlossaryItemBulkAddTab.interfaces';

import { useCreateBulkGlossaryItems, useToast } from '../../../../hooks';

const GlossaryItemBulkAddTab = ({ projectId, onClose, onUpdate }: GlossaryItemBulkAddTabProps) => {

  const { handleToastError, handleToastSuccess, handleToastCustomError } = useToast();

  const [newGlossaryItems, setNewGlossaryItems] = useState<IBulkUploadGlossaryItem[]>([]);


  const { handleCreateBulkGlossaryItems, isLoading: isCreateBulkGlossaryItemsLoading = false } = useCreateBulkGlossaryItems({
    onCompleted: () => {
      handleToastSuccess({
        message: `Glossary items successfully created`,
      });
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleCreateBulkInvitations = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (newGlossaryItems.find((r) => r.title === '' || r.description === '')) {
      handleToastCustomError({ message: 'All the excel fields must be mapped-out' });
      return;
    }


    void handleCreateBulkGlossaryItems({
      projectId,
      items: newGlossaryItems.map(({title, description}) => {
        return {
          title,
          description,
        }
      }),
    });
  };

  const handleReadExcel = (input: File) => {
    const reader = new FileReader();
  
    reader.onload = async (event) => {
      const { target } = event;
  
      if (!target?.result) return;
  
      const buffer = target.result as ArrayBuffer;
  
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);
  
      const [ sheet ] = workbook.worksheets;
  
      const rows: IBulkUploadGlossaryItem[] = [];
      sheet.eachRow((row, rowIndex) => {
        if (rowIndex === 1) return; // Skip header row
        const values = (row.values as (string | null | undefined)[]);
        const [title, description] = values.slice(1);
        rows.push({ 
          title: title ?? '', 
          description: description ?? '',
          id: (Math.random() + 1).toString(36).substring(7),
        });
      });
    
      setNewGlossaryItems(rows);
    };
  
    reader.readAsArrayBuffer(input);
  };


  const handleClearGlossaryItems = () => {
    setNewGlossaryItems([]);
  };

  const handleFileDrop = (uploadedFile: File | undefined) => {
    if (uploadedFile) handleReadExcel(uploadedFile);
  };

  const isLoading = isCreateBulkGlossaryItemsLoading;

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />

      <form
        onSubmit={handleCreateBulkInvitations}
      >


        <Spacing>
          {Boolean(newGlossaryItems.length) && (
            <InputFieldWrapper id="" label="Uploaded items" labelType="span" isLabelSideBySide>
              <FlexContainer justifyContent="flex-end" isFullWidth>
                <ButtonIconOnly
                  icon={CloseIcon}
                  text="close sidebar"
                  onClick={handleClearGlossaryItems}
                />
              </FlexContainer>
            </InputFieldWrapper>
          )}
          {Boolean(newGlossaryItems.length) && (
            <>
              <Table
                columns={[
                  {
                    accessor: 'title',
                    Cell: ({ value }: { value: string }) => <div>{value}</div>,
                    Header: () => <div>Title</div>,
                  },
                  {
                    accessor: 'description',
                    Cell: ({ value }: { value: string }) => <div>{value}</div>,
                    Header: <div>Description</div>,
                  },
                ]}
                data={newGlossaryItems}
              />
            </>
          )}
        </Spacing>

        <Spacing size="2x-large">
          <InputFieldWrapper id="" label="Download template" labelType="span" tooltipText="Download our predefined excel template to add your glossary items to" isLabelSideBySide >
            <FlexContainer justifyContent="flex-end" isFullWidth>
              <a
                href="https://ekardo.s3.ap-southeast-2.amazonaws.com/file-templates/glossary_item_template.xlsx"
                id="download-template"
                download
              >
                <XlsxIcon className="c-icon c-icon--large" />
              </a>
            </FlexContainer>
          </InputFieldWrapper>
        </Spacing>

        <Uploader
          assetType={'document'}
          labelText={'Upload region'}
          tooltipText={'Upload multiple glossary items at once by using an excel document'}
          isLabelSideBySide
          onDelete={handleClearGlossaryItems}
          onDrop={handleFileDrop}
        />

        <SidebarButtons
          buttonSize="xs"
          isSaveButtonDisabled={Boolean(!newGlossaryItems.length)}
          submitButtonText="Save"
          onCancel={onClose}
          onSaveButtonType="submit"
        />

      </form>
    </SidebarContainer>
  );
};

export { GlossaryItemBulkAddTab };
