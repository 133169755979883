import { gql } from '@apollo/client';



const GET_CHARACTERS_BY_PROJECT = gql`
  query getCharacters($projectId: String!) {
    character {
      getByProject(projectId: $projectId) {
        id
        imageId
        name
        image {
          presignedUrl
        }
      }
    }
  }
`;


export { GET_CHARACTERS_BY_PROJECT };
