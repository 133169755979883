import { gql } from '@apollo/client';

const ATTACH_CSS_STYLE_TO_SNIPPET = gql`
  mutation($contentSnippetId: Int!, $styleId: Int!) {
    cssStyle {
      attachStyleToContentSnippet(
        contentSnippetId: $contentSnippetId
        styleId: $styleId
      )
    }
  }
`;


export { ATTACH_CSS_STYLE_TO_SNIPPET };
