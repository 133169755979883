
import { ILoginsTableData, ILoginsTableDataParams } from "./LoginsPage.interfaces";


const getLoginsTableData = ({ items }: ILoginsTableDataParams): ILoginsTableData[] => {
  return items.map(({
    email = '',
    firstName = '',
    groupName = '',
    id,
    lastName = '',
    loginDate = '',
    accessType = '',
   }) => {
    return {
      email,
      firstName,
      groupName,
      id,
      lastName,
      loginDate,
      accessType,
    };
  });
};

export { getLoginsTableData };
