

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_PAGE_TYPES_QUERY } from './useGetContentPageTypes.graphql';
import {
  IGetContentPageTypesQueryGraphQLResponse,
  IGetContentPageTypesQueryVariables,
  IHandleGetContentPageTypesParams,
  IUseContentPageTypes,
  IUseContentPageTypesOptions,
} from './useGetContentPageTypes.interfaces';



const useGetContentPageTypes = (options?: IUseContentPageTypesOptions): IUseContentPageTypes => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseContentPageTypesOptions);

  const [executeGetContentPageTypes, { loading: isLoading }] = useEkardoLazyQuery<
    IGetContentPageTypesQueryGraphQLResponse,
    IGetContentPageTypesQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { getContentPageTypes: contentPageTypes },
        } = data;


        onCompleted({
          contentPageTypes,
        });
      },
      onError,
    },
    product,
    query: query ?? GET_CONTENT_PAGE_TYPES_QUERY,
    token,
  });

  const handleGetContentPageTypes = async ({
    projectId,
  }: IHandleGetContentPageTypesParams) => {
    await executeGetContentPageTypes({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetContentPageTypes,
    isLoading,
  };
};

export { useGetContentPageTypes };
