import React, { useEffect, useRef, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import {
  CopyButton,
  Input,
  InputFieldWrapper,
  Message,
  Spacing,
  Spinner,
  Textarea,
  ToggleSwitch,
} from '@netfront/ui-library';
import { SidebarContainer, Uploader } from 'components';
import { saveAs } from 'file-saver';
import { Controller } from 'react-hook-form';

import { documentSidebarGeneralViewConstants } from './AssetGeneralTab.constants';
import { AssetGeneralTabProps } from './AssetGeneralTab.interfaces';

import {
  useGetAsset,
  useToast,
} from '../../../../hooks';


const AssetGeneralTab = ({
  assetType,
  asset,
  control,
  assetId = '',
  initialFileName = '',
  initialIsDownloadAvailable = false,
  isInContentBuilder = false,
  setValue,
  onDrop,
}: AssetGeneralTabProps) => {
  const { handleToastError, handleToastSuccess } = useToast();
  const copyPublicLink = useRef<HTMLSpanElement>(null);
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const {
    descriptionMaxLength,
  } = documentSidebarGeneralViewConstants;

  const [fileName, setFileName] = useState<string>('');
  const [isDownloadAvailable, setIsDownloadAvailable] = useState<boolean>(false);

  const { handleGetAsset: executeGetAsset, isLoading: isGetAssetLoading = false } = useGetAsset({
    onCompleted: ({ presignedUrl: downloadDocumentUrl }) => {
      saveAs(String(downloadDocumentUrl), fileName);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleDropFile = (uploadedFile?: File) => {

    const { name = '' } = uploadedFile ?? {};

    setFileName(String(name));
    onDrop(uploadedFile);
  };

  const handleGetAsset = () => {
    if (!assetId) {
      return;
    }

    executeGetAsset({
      assetId,
    });
  };


  const handleRemoveUploadedAsset = () => {
    setValue('presignedUrl', '');
    setFileName('');
  };

  useEffect(() => {
    setIsDownloadAvailable(initialIsDownloadAvailable);
  }, [initialIsDownloadAvailable]);

  useEffect(() => {
    setFileName(initialFileName);
  }, [initialFileName]);

  const isLoading = isGetAssetLoading;


  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
      
      <Spacing>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_asset_title"
              labelText="Title"
              placeholder="Add your title"
              tooltipText="Title of the document"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
      {!isInContentBuilder && (
        <>
          {assetType !== 'image' && (
            <Spacing>
              <Controller
                control={control}
                name="description"
                render={({ field, fieldState }) => (
                  <Textarea
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id="description"
                    isDisabled={isInContentBuilder}
                    labelText="Description"
                    maxLength={descriptionMaxLength}
                    placeholder="Add your description here"
                    tooltipText="Description of the document"
                    isLabelSideBySide
                    {...field}
                  />
                )}
              />
            </Spacing>
          )}

          {assetType === 'image' && (
            <>
              <Spacing>
                <Controller
                  control={control}
                  name="alt"
                  render={({ field, fieldState }) => (
                    <Input
                      errorMessage={getFormFieldErrorMessage(fieldState)}
                      id="id_alt_text"
                      isDisabled={isInContentBuilder}
                      labelText="Alt text"
                      placeholder="Add alt text for your image"
                      tooltipText="Alt text of the image"
                      type="text"
                      isLabelSideBySide
                      isRequired
                      {...field}
                    />
                  )}
                />
              </Spacing>
            </>
          )}
        </>
      )}


      <Controller
        control={control}
        name="presignedUrl"
        render={({ field, fieldState }) => {
          const errorMessage = getFormFieldErrorMessage(fieldState);
          return (
            <>
              <Uploader
                asset={asset}
                assetType={assetType}
                handleDownload={asset && isDownloadAvailable ? handleGetAsset: undefined}
                hasRemoveButton={!isInContentBuilder}
                initialUrl={field.value}
                name={field.name}
                isLabelSideBySide
                onDelete={handleRemoveUploadedAsset}
                onDrop={handleDropFile}
              />
              {Boolean(errorMessage) && <Message id={`id_audio_file_error`} text={errorMessage} type="ERROR" />}
            </>
          );
        }}
      />
      <Spacing>
        <Controller
          control={control}
          name="isPublic"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames="c-sidebar-toggle"
              id="id_is_public_asset"
              inlineLabelPosition="start"
              isChecked={field.value}
              labelText="Make public"
              tooltipPosition="start"
              tooltipText="Make document available to everyone"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Controller
        control={control}
        name="publicUrl"
        render={({ field }) => (
          <>
            {field.value && (
              <Spacing>
                <div className="c-document-sidebar-general-view__public-toggle">
                  <InputFieldWrapper id="copy" label="Public link" labelType="span" isLabelSideBySide>
                    <div className="c-document-sidebar-general-view__public-toggle-link">
                      <span ref={copyPublicLink}>{field.value}</span>
                      <CopyButton
                        copyElementRef={copyPublicLink}
                        supportiveText="Copy public link"
                        onCopyCompleted={() => handleToastSuccess({ message: 'Public link copied' })}
                        />
                    </div>
                  </InputFieldWrapper>
                </div>
              </Spacing>
            )}
          </>
        )}
      />
      <Spacing>
        <Controller
          control={control}
          name="isSensitive"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames="c-sidebar-toggle"
              id="id_is_sensitive_content"
              inlineLabelPosition="start"
              isChecked={field.value}
              isDisabled={isInContentBuilder}
              labelText="Sensitive"
              tooltipPosition="start"
              tooltipText="Determine whether the asset is sensitive, e.g contains strong language or culturally offensive"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

      {asset && !isInContentBuilder && (
        <>
          <Spacing>
            <Controller
              control={control}
              name="isDownloadAvailable"
              render={({ field }) => (
                <ToggleSwitch
                  additionalClassNames="c-sidebar-toggle"
                  id="id_is_download_available"
                  inlineLabelPosition="start"
                  isChecked={field.value}
                  isDisabled={isInContentBuilder}
                  labelText="Available to download"
                  tooltipPosition="start"
                  tooltipText="Downloadable document"
                  isInline
                  isLabelSideBySide
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    setIsDownloadAvailable(!isDownloadAvailable);
                  }}
                />
              )}
            />
          </Spacing>

          <Spacing>
            <Controller
              control={control}
              name="author"
              render={({ field, fieldState }) => (
                <Input
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_author"
                  labelText="Author"
                  placeholder="Add author here"
                  tooltipText="Author of the document"
                  type="text"
                  isDisabled
                  isLabelSideBySide
                  {...field}
                />
              )}
            />
          </Spacing>
        </>
      )}
    </SidebarContainer>
  );
};

export { AssetGeneralTab };
