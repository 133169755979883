import { useContext, useState, useEffect } from 'react';

import { GeneralTabIcon, IOption, ISearchList, ITab, SelectWithSearch } from '@netfront/ui-library';
import { LanguageFiltersBulkActionDialog, TablePageTemplate , LanguageFilterSidebarBulkUploadView, LanguageFilterSidebarGeneralView } from 'components';
import { CachingEntitiesContext, DashboardContext } from 'context';
import { useGetPaginatedLanguageFilters, useToast } from 'hooks';
import { DBLanguageFilterType, ILanguageFilter } from 'interfaces';
import last from 'lodash.last';
import Link from 'next/link';
import { useRouter } from 'next/router';


import { LANGUAGE_FILTER_TABLE_COLUMNS, LANGUAGE_FILTER_PAGE_SIZE } from './LanguageFilterPage.constants';
import { getLanguageFilterTableData } from './LanguageFilterPage.helpers';
import { ILanguageFilterTableData } from './LanguageFilterPage.interfaces';



const LanguageFilterPage = () => {
  const { query: { projectId: queryProjectId } } = useRouter();
  const { dashboardLink } = useContext(DashboardContext);
  const { project } = useContext(CachingEntitiesContext);
  const { handleToastError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [allLanguageFilters, setAllLanguageFilters] = useState<ILanguageFilter[]>();
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [languageFilterTableData, setLanguageFilterTableData] = useState<ILanguageFilterTableData[]>();
  const [selectedLanguageFilter, setSelectedLanguageFilter] = useState<ILanguageFilter>();
  const [pageSize, setPageSize] = useState<number>(LANGUAGE_FILTER_PAGE_SIZE);
  const [totalLanguageFilters, setTotalLanguageFilters] = useState<number>(0);
  const [selectedTypeName, setSelectedTypeName] = useState<string>('Strict');
  const [isFilterTypeSelectorOpen, setIsFilterTypeSelectorOpen] = useState<boolean>(false);
  const [languageFilterType, setLanguageFilterType] = useState<DBLanguageFilterType>('STRICT');

  // bulk actions
  const [selectedRows, setSelectedRows] = useState<ILanguageFilter[]>([]);
  const [bulkActionType, setBulkActionType] = useState<string>('add');
  const [isBulkActionDialogOpen, setIsBulkActionDialogOpen] = useState<boolean>(false);

  const languageFilterTypes: IOption[] = [
    {
      id: 'STRICT',
      name: 'Strict',
      value: 'STRICT',
    },
    {
      id: 'MULTIPLE_WAYS',
      name: 'Multiple ways',
      value: 'MULTIPLE_WAYS',
    },
    {
      id: 'TRICKED',
      name: 'Tricked',
      value: 'TRICKED',
    },
  ];

  const {
    handleFetchMorePaginatedLanguageFilters,
    handleGetPaginatedLanguageFilters,
    isLoading: isGetPaginatedUsersLoading = false,
  } = useGetPaginatedLanguageFilters({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ LanguageFilterConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const languageFiltersEdges = edges.map(({ node }) => node);

      setSelectedRows([]);
      setAllLanguageFilters(languageFiltersEdges);
      setIsLoadMoreDisabled(languageFiltersEdges.length >= totalCount || totalCount <= pageSize);
      setTotalLanguageFilters(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });

  const handleFilterSearch = (value: string) => {
    handleGetPaginatedLanguageFilters({
      content: value,
      first: pageSize,
      projectId: String(projectId),
    });

    setFilter(value);
  };

  const handleSelectedRows = (selectedIds: string[]) => {
    setSelectedRows(allLanguageFilters?.filter((item) => selectedIds.includes(String(item.id))) ?? []);
  }

  const onUpdateLanguageFilter = () => {
    handleSideBarClose();
    void handleGetPaginatedLanguageFilters({
      first: pageSize,
      content: filter,
      projectId: String(projectId),
      type: languageFilterType,
    });
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handleSideBarClose = () => {
    setIsSideBarOpen(false);
    setSelectedLanguageFilter(undefined);
  };

  const handleSwitchFilterType = (id: number | string) => {
    setLanguageFilterType(languageFilterTypes.find((filterType) => filterType.id === id)?.value as DBLanguageFilterType);
    setSelectedTypeName(languageFilterTypes.find((filterType) => filterType.id === id)?.name ?? '');
  };



  // bulk actions
  const handleOpenBulkActionDialog = (type: string) => {
    setBulkActionType(type);
    setIsBulkActionDialogOpen(true);
  };

  const handleCloseBulkActionDialog = () => {
    setBulkActionType('');
    setIsBulkActionDialogOpen(false);
  };

  const handleBulkActionSave = () => {
    handleCloseBulkActionDialog();
    void handleGetPaginatedLanguageFilters({
      first: pageSize,
      content: filter,
      projectId: String(projectId),
      type: languageFilterType,
    });
  }

  useEffect(() => {
    if (!projectId) {
      return;
    }

    handleGetPaginatedLanguageFilters({
      first: pageSize,
      content: filter,
      projectId: String(projectId),
      type: languageFilterType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId, languageFilterType]);

  useEffect(() => {
    if (!allLanguageFilters) {
      return;
    }

    setLanguageFilterTableData(
      getLanguageFilterTableData({
        languageFilters: allLanguageFilters,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedLanguageFilter(allLanguageFilters.find(({ id: userId }) => id === userId));
        },
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLanguageFilters]);


  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;

    setProjectName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const tabs: ITab[] = [
    {
      icon: GeneralTabIcon,
      id: 'id_general_tab',
      label: 'General',
      view: () => (
        <LanguageFilterSidebarGeneralView projectId={String(projectId)} selectedLanguageFilter={selectedLanguageFilter} onClose={handleSideBarClose} onUpdate={onUpdateLanguageFilter}/>
      ),
    },
    {
      icon: GeneralTabIcon,
      id: 'id_bulk_upload_tab',
      label: 'Bulk upload',
      isHidden: Boolean(selectedLanguageFilter),
      view: () => <LanguageFilterSidebarBulkUploadView projectId={String(projectId)} onClose={handleSideBarClose} onUpdate={onUpdateLanguageFilter}/>,
    },
  ];


  const isLoading = isGetPaginatedUsersLoading;

  return (
    <>
      <TablePageTemplate<ILanguageFilterTableData>
        activePage="social"
        activeSubPage="language-filters"
        additionalBreadcrumbItems={[
          {
            key: '1',
            content: <Link href={`${String(dashboardLink)}/social`}><span>Social</span></Link>,
          },
          {
            key: '2',
            content: 'Language filters',
          },
        ]}
        bulkActions={[
          { id: 0, label: 'Update type', action: () => handleOpenBulkActionDialog('updateType') },
          { id: 1, label: 'Delete', action: () => handleOpenBulkActionDialog('delete') },
        ]}
        childrenMiddle={
          <SelectWithSearch
            defaultValue={selectedTypeName}
            hasPadding={false}
            id="id_filter_type_selector"
            isDisplaySearchContent={isFilterTypeSelectorOpen}
            isLoading={isLoading}
            labelText="Select filter type"
            searchList={
              languageFilterTypes.map((filterType) => {
                return {
                  id: filterType.id,
                  label: filterType.name,
                  size: 'x-small',
                } as ISearchList;
              })
            }
            isAvatarVisible
            isLabelHidden
            onDisplaySearchContent={() => setIsFilterTypeSelectorOpen(!isFilterTypeSelectorOpen)}
            onSearchItemClick={handleSwitchFilterType}
          />
        }
        columns={LANGUAGE_FILTER_TABLE_COLUMNS}
        data={languageFilterTableData ?? []}
        defaultActiveTabId="id_general_tab"
        description={`Moderation social content for ${projectName} project`}
        handleAddNewClick={() => setIsSideBarOpen(true)}
        handleOnPageSizeChange={handlePageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedLanguageFilters({
            after: lastCursor,
            first: pageSize,
            content: filter,
            projectId: String(projectId),
            type: languageFilterType,
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage="The language filter is used to detect and filter out posts using negative words and slurs. To ensure word matching is accurate, you can use symbols to help with word matching. ‘?' will match any single character where '*’ will include any characters after it is used. Example 1: hate? will filter hate and hates. Example 2: suicid* will handle suicide, suicidality. "
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        isSideBarOpen={isSideBarOpen}
        pageSize={pageSize}
        pageTitle={`${projectName} community language filter dashboard`}
        tableType="language filters"
        tabs={tabs}
        title={`${projectName} community language filters`}
        toggleIsSideBarOpen={handleSideBarClose}
        totalItems={totalLanguageFilters}
        onSelectRows={handleSelectedRows}
      />
      {projectId && (
        <LanguageFiltersBulkActionDialog
          bulkActionType={bulkActionType}
          handleCloseDialog={handleCloseBulkActionDialog}
          isOpen={isBulkActionDialogOpen}
          selectedLanguageFilters={selectedRows}
          onSave={handleBulkActionSave}
        />
      )}

    </>
  );
};

export { LanguageFilterPage };
