import React, { useEffect, useState } from 'react';

import { ButtonIconOnly, PlusIcon, SettingsButton, SidebarButtons, Spacing } from '@netfront/ui-library';
import { getFormattedDirectory, SidebarContainer } from 'components';
import { IDirectoryOperatingHours } from 'interfaces';


import { UpsertDirectoryOperatingHours } from '../UpsertDirectoryOperatingHours';

import { DirectoryOperatingHoursTabProps } from './DirectoryOperatingHoursTab.interfaces';



const DirectoryOperatingHoursTab = ({ onUpdateOperatingHours, selectedDirectory, onCancel, onDelete, onSave }: DirectoryOperatingHoursTabProps) => {
  const [isUpsertOperatingHoursMode, setIsUpsertOperatingHoursMode] = useState<boolean>(false);
  const [selectedOperatingHours, setSelectedOperatingHours] = useState<IDirectoryOperatingHours>();
  const [operatingHours, setOperatingHours] = useState<IDirectoryOperatingHours[]>([])

  useEffect(() => {
    if (!selectedDirectory) return;

    const { operatingHours: currentOperatingHours } = getFormattedDirectory(selectedDirectory)
    setOperatingHours(currentOperatingHours);
  }, [selectedDirectory])


  const handleCloseUpsertOperatingHours = () => {
    setIsUpsertOperatingHoursMode(false);
    setSelectedOperatingHours(undefined);
  };

  const handleUpsertOperatingHours = (id?: string) => {
    setIsUpsertOperatingHoursMode(true);
    setSelectedOperatingHours(operatingHours.find((item) => item.id === id));
  };

  const handleCreateOperatingHours = (newOperatingHours: IDirectoryOperatingHours) => {
    const updatedOperatingHours = [...operatingHours, newOperatingHours];
    onUpdateOperatingHours(updatedOperatingHours);
    setOperatingHours(updatedOperatingHours);
    handleCloseUpsertOperatingHours();
  };

  const handleUpdateOperatingHours = (updatedOperatingHours: IDirectoryOperatingHours) => {

    const adjustedOperatingHours = operatingHours.map((item) => {
      if (item.id === updatedOperatingHours.id) return updatedOperatingHours;
      return item;
    });
    onUpdateOperatingHours(adjustedOperatingHours);
    setOperatingHours(adjustedOperatingHours);
    handleCloseUpsertOperatingHours();
  };

  const handleDeleteOperatingHours = (id: string) => {
    const updatedOperatingHours = operatingHours.filter((item) => item.id !== id);
    onUpdateOperatingHours(updatedOperatingHours);
    setOperatingHours(updatedOperatingHours);
    handleCloseUpsertOperatingHours();
  };

  return (
    <SidebarContainer>
      {isUpsertOperatingHoursMode ? (
        <UpsertDirectoryOperatingHours
          operatingHoursItem={selectedOperatingHours}
          onCancel={handleCloseUpsertOperatingHours}
          onCreate={handleCreateOperatingHours}
          onDelete={handleDeleteOperatingHours}
          onUpdate={handleUpdateOperatingHours}
        />
      ) : (
        <>
          <Spacing>
            <h3 className="c-label">Operating hours</h3>
            <ol className="c-operating-hours">
              {operatingHours.map((item) => {
                const { id, isSingleDay = true, dayStart, dayEnd = '', timeStart, timeEnd } = item;
                return (
                  <li key={id} className="c-operating-hours__item">
                    <div className="c-operating-hours__details">
                      <p className="c-operating-hours__text">
                        {dayStart}
                        {!isSingleDay ? ` - ${dayEnd}` : ''}
                      </p>
                      <p className="c-operating-hours__text">
                        {timeStart} - {timeEnd}
                      </p>
                    </div>
                    <SettingsButton supportiveText="Edit operating hours" onClick={() => handleUpsertOperatingHours(id)} />
                  </li>
                );
              })}
            </ol>
            <div className="c-operating-hours-container">
              <ButtonIconOnly icon={PlusIcon} text="Add response" onClick={() => handleUpsertOperatingHours()} />
            </div>
          </Spacing>
          <SidebarButtons
            onCancel={onCancel}
            onDelete={onDelete}
            onSave={onSave}
          />
        </>
      )}
    </SidebarContainer>
  );
};


export { DirectoryOperatingHoursTab };

{/*
*/}
