import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetLoginsTrialsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { LOGINS_TRIALS_COLUMNS } from './LoginsTrialsPage.constants';
import { getLoginsTrialsTableData } from './LoginsTrialsPage.helpers';
import { LoginsTrialsCsvRowData, ILoginsTrialsItem, ILoginsTrialsTableData } from './LoginsTrialsPage.interfaces';



const LoginsTrialsPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [loginsTrialsItems, setLoginsTrialsItems] = useState<ILoginsTrialsItem[]>();
  const [allLoginsTrialsItems, setAllLoginsTrialsItems] = useState<ILoginsTrialsItem[]>([]);
  const [loginsTrialsTableData, setLoginsTrialsTableData] = useState<ILoginsTrialsTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>();
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const {
    handleGetLoginsTrialsReport,
    isLoading: isGetLoginsTrialsLoading = false,
  } = useGetLoginsTrialsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<LoginsTrialsCsvRowData>(String(res.data), {
            header: true,
          skipEmptyLines: true,
          });

          const formattedItems = data.map((row: LoginsTrialsCsvRowData) => ({
            id: Number(row['User id']),
            email: String(row['Email']),
            firstName: String(row['First name']),
            lastName: String(row['Last name']),
            groupName: String(row['Group name']),
            loginDate: String(row['Login date']),
          }));
          
          setTotalCount(data.length);
          setAllLoginsTrialsItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setLoginsTrialsItems(paginatedItems);
          setLastItemIndex(pageSize);

        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    if (!(dateRange && projectId)) {
      return;
    }

    if (!dateRange[1] || !dateRange[0]) return;

    void handleGetLoginsTrialsReport({
      dateFrom: dateRange[0],
      dateTo: dateRange[1],
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fileType: 'CSV',
      projectId,
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setLoginsTrialsItems(allLoginsTrialsItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setLoginsTrialsItems(allLoginsTrialsItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!loginsTrialsItems) {
      return;
    }

    setLoginsTrialsTableData(
      getLoginsTrialsTableData({
        items: loginsTrialsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginsTrialsItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetLoginsTrialsLoading;

  return (
    <ReportTable<ILoginsTrialsTableData>
      activePath={['reporting', 'reporting-users', 'export-logins-trials']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Logins',
      }]}
      columns={LOGINS_TRIALS_COLUMNS}
      csvUrl={csvUrl}
      data={loginsTrialsTableData}
      description={`Login reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports logins based on the selected filters'}
      isGenerateButtonDisabled={!dateRange || !dateRange[0] || !dateRange[1]}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { LoginsTrialsPage };
