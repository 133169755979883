import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getQuickAddEventValues, getUpsertContentEventStatus, getContentStyleDetails, getFormattedContentStyles } from "components";

const getSummarySnippetDefaultValues = ({ snippet } : { snippet?: IContentSnippet;}): FormFieldProps => {
  const { 
    title, 
    contentEvent, 
    contentSnippetQuestionId, 
    contentSnippetFormId, 
    userFlowStepId, 
    visible: isVisible = true,
    // isSensitive = false, 
  } = snippet ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);
  const styleDetails = getContentStyleDetails({ entity: snippet });

  return {
    title: title ?? 'Summary snippet',
    contentSnippetFormId,
    contentSnippetQuestionId,
    questionFormId: snippet ? `${String(snippet.contentSnippetFormId)}-${String(snippet.contentSnippetQuestionId)}` : undefined,
    userFlowStepId,
    isVisible,
    isSensitive: false,
    isCreateNew: false,
    ...eventDetails,
    ...animationDetails,
    ...styleDetails,
  }
};

const getSummarySnippetCommonVariables = ({ eventAssetId, item }: {eventAssetId?: IDBAsset['assetId']; item: FormFieldProps}) => {

  const {
    title = 'Summary snippet',
    contentSnippetFormId,
    contentSnippetQuestionId,
    userFlowStepId,
    isVisible,
    // isSensitive,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);
  const { baseVariables, customTheme } = getFormattedContentStyles({ item });

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
      // isSensitive,
      ...baseVariables,
    },
    summarySnippet: {
      contentSnippetFormId,
      contentSnippetQuestionId,
      userFlowStepId,
    },
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    type: 'summary',
    customTheme,
  };

  return sharedVariables;

};

const setQuickAddSummaryValues = (values: FormFieldProps): FormFieldProps => {
  return {
    ...getQuickAddEventValues(values),
    title: 'Summary snippet',
    contentSnippetFormId: undefined,
    contentSnippetQuestionId: undefined,
    questionFormId: undefined,
    userFlowStepId: undefined
  };
};

export { getSummarySnippetDefaultValues, getSummarySnippetCommonVariables, setQuickAddSummaryValues };
