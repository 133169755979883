import React, { useState, useEffect } from 'react';

import { useToggle } from '@netfront/common-library';
import { IDirectory, IDropDownListOption } from '@netfront/ekardo-content-library';
import { CircleAddIcon, GeneralTabIcon, IOption, SelectWithSearch } from '@netfront/ui-library';
import { DirectoryTypeGeneralTab, TablePageTemplate , LibraryDirectorySideBarView } from 'components';
import { useToast } from 'hooks';
import { useRouter } from 'next/router';


import { useGetDirectories } from 'hooks/directory/useGetDirectories';
import { useGetDirectoryTypes } from 'hooks/directory/useGetDirectoryTypes';

import { LIBRARY_DIRECTORY_TABLE_COLUMNS } from './LibraryDirectoryPage.constants';
import { getLibraryDirectoryTableData } from './LibraryDirectoryPage.helpers';
import { ILibraryDirectoryTableData } from './LibraryDirectoryPage.interfaces';



const LibraryDirectoryPage = () => {
  const { query: { projectId: queryProjectId } } = useRouter();
  const { isToggled: isSideBarOpen, toggle: toggleIsSideBarOpen } = useToggle();
  const { handleToastError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  // table data state
  const [formattedDirectories, setFormattedDirectories] = useState<ILibraryDirectoryTableData[]>([]);
  const [directoryTypeId, setDirectoryTypeId] = useState<number>();
  const [addedNewDirectoryTypeId, setAddedNewDirectoryTypeId] = useState<number>();
  const [directoryTypeOptionsDropdownList, setDirectoryTypeOptionsDropdownList] = useState<IDropDownListOption[]>([]);
  const [directoryTypeOptionsOptionList, setDirectoryTypeOptionsOptionList] = useState<IOption[]>([]);
  const [totalDirectories, setTotalDirectories] = useState<number>(0);

  // pagination, search state
  const [pageSize, setPageSize] = useState<number>(10);
  const [isSearchContentVisible, setIsSearchContentVisible] = useState<boolean>(false);
  const [selectedGroupLabel, setSelectedGroupLabel] = useState<string>('');
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [selectedDirectory, setSelectedDirectory] = useState<IDirectory>();
  const [hasNoDirectoryType, setHasNoDirectoryType] = useState<boolean>(false);
  const [isAddDirectoryTypePanelOpen, setIsAddDirectoryTypePanelOpen] = useState<boolean>(false);
  const [hasAddedNewDirectoryType, setHasAddedNewDirectoryType] = useState<boolean>(false);


  // loading state
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [hasApiReturned, setHasApiReturned] = useState<{isDirectoriesLoaded: boolean; isDirectoryTypesLoaded: boolean; }>({
    isDirectoriesLoaded: false,
    isDirectoryTypesLoaded: false,
  });


  // table page apis
  const { handleGetDirectories, isLoading: isGetDirectoriesLoading = false } = useGetDirectories({
    onCompleted: ({ directories: returnedDirectories }) => {
      setFormattedDirectories(getLibraryDirectoryTableData({
        directories: returnedDirectories,
        onSettingsButtonClick: handleSettingsButtonClick
      }));
      setTotalDirectories(returnedDirectories.length);
      if (!hasLoaded) setHasApiReturned({
        ...hasApiReturned,
        isDirectoriesLoaded: true
      });
    },
  });

  // directory types api
  const { handleGetDirectoryTypes, isLoading: isGetDirectoryTypesLoading = false } = useGetDirectoryTypes({
    onCompleted: ({ directoryTypes }) => {
      if (!directoryTypes.length) {
        if (!hasLoaded) setHasApiReturned({
          ...hasApiReturned,
          isDirectoryTypesLoaded: true,
          isDirectoriesLoaded: true,
        });
        setHasNoDirectoryType(true);
        return;
      }

      const [firstDirectoryType] = directoryTypes;

      const selectedDirectoryType = addedNewDirectoryTypeId ? directoryTypes.find((type) => type.id === addedNewDirectoryTypeId) : firstDirectoryType;

      setSelectedGroupLabel(String(selectedDirectoryType?.name));

      const directoryOptionsDropdownFormat = directoryTypes.map(({ name, id }) => ({
        id: String(id),
        label: name,
        value: String(id),
      }));

      const directoryOptionsOptionFormat = directoryTypes.map(({ name, id, key }) => ({
        id: String(id),
        name,
        value: key ? String(key) : String(id),
      }));

      setDirectoryTypeOptionsOptionList(directoryOptionsOptionFormat);
      setHasNoDirectoryType(false);

      setDirectoryTypeId(selectedDirectoryType?.id);
      setDirectoryTypeOptionsDropdownList(directoryOptionsDropdownFormat);
      if (!hasLoaded) setHasApiReturned({
        ...hasApiReturned,
        isDirectoryTypesLoaded: true
      });
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const onCreateDirectoryType = (id: number) => {
    setHasAddedNewDirectoryType(true);
    setIsAddDirectoryTypePanelOpen(false);
    setAddedNewDirectoryTypeId(id);
  }


  // table functions
  const handleDirectoryTypeSearchItemClick = (selectedId: string | number) => {
    setDirectoryTypeId(Number(selectedId));
  }

  const handleSearch = (val: string) => {
    setSearchInputValue(val);
  }

  const handleShowCreateDirectorySideBar = () => {
    setSelectedDirectory(undefined);
    toggleIsSideBarOpen();
  };

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };

  const handleDirectoryUpdate = () => {
    toggleIsSideBarOpen();
    handleGetDirectories({
      directoryTypeId: Number(directoryTypeId),
      projectId: String(projectId),
      title: searchInputValue,
    });
  };

  const handleSettingsButtonClick = (directory: IDirectory) => {
    setSelectedDirectory(directory);
    toggleIsSideBarOpen();
  };


  useEffect(() => {
    if (!projectId) {
      return;
    }

    handleGetDirectoryTypes({
      projectId: String(projectId),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  useEffect(() => {
    if (!hasAddedNewDirectoryType) {
      return;
    }

    handleGetDirectoryTypes({
      projectId: String(projectId),
    });
    setHasAddedNewDirectoryType(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAddedNewDirectoryType]);


  useEffect(() => {
    if (!(directoryTypeId && directoryTypeOptionsDropdownList.length && projectId)) {
      return;
    }

    handleGetDirectories({
      directoryTypeId,
      projectId: String(projectId),
      title: searchInputValue,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryTypeId, directoryTypeOptionsDropdownList, projectId, searchInputValue]);

  // handle loading state to stop flickering
  useEffect(() => {
    const { isDirectoriesLoaded = false, isDirectoryTypesLoaded = false } = hasApiReturned;

    if (isDirectoriesLoaded && isDirectoryTypesLoaded) setHasLoaded(true);

  }, [hasApiReturned]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <>
      <TablePageTemplate<ILibraryDirectoryTableData>
        activePage="library"
        activeSubPage="directory"
        childrenMiddle={
          <SelectWithSearch
            additionalClassNames="c-select-with-search__users-table"
            buttonText="All directory types"
            countText="directory types"
            customButtonIcon={CircleAddIcon}
            customButtonText="Add new directory type"
            defaultValue={selectedGroupLabel}
            hasPadding={false}
            id="id_select_directory_type"
            isDisplaySearchContent={isSearchContentVisible}
            labelText="Select directory"
            searchList={directoryTypeOptionsDropdownList}
            totalCount={directoryTypeOptionsDropdownList.length}
            isAvatarVisible
            isLabelHidden
            onCustomButtonClick={() => setIsAddDirectoryTypePanelOpen(true)}
            onDisplaySearchContent={() => setIsSearchContentVisible(!isSearchContentVisible)}
            onSearchItemClick={handleDirectoryTypeSearchItemClick}
          />
        }
        columns={LIBRARY_DIRECTORY_TABLE_COLUMNS}
        data={formattedDirectories}
        defaultActiveTabId="id_general_tab"
        description={"Manage the project directory"}
        handleAddNewClick={hasNoDirectoryType ? () => setIsAddDirectoryTypePanelOpen(true) : handleShowCreateDirectorySideBar}
        handleOnPageSizeChange={handleOnPageSizeChange}
        handleSearch={handleSearch}
        hideSideBarButtons={true}
        informationBoxMessage={"Manage the project directory"}
        isLoading={!hasLoaded || isGetDirectoryTypesLoading || isGetDirectoriesLoading}
        isPaginationDisabled={true}
        isSideBarOpen={isAddDirectoryTypePanelOpen}
        pageSize={pageSize}
        pageTitle="Manage the project directory"
        tableType={hasNoDirectoryType ? 'Directory types': 'Directories'}
        tabs={[{
          icon: GeneralTabIcon,
          id: 'id_general_tab',
          label: 'General',
          view: () => (
            <DirectoryTypeGeneralTab
              // currentDirectoryTypeOptions={directoryTypeOptionsOptionList}
              projectId={String(projectId)}
              onClose={() => setIsAddDirectoryTypePanelOpen(!isAddDirectoryTypePanelOpen)}
              onSave={onCreateDirectoryType}
            />
          )
        }]}
        title="project name"
        toggleIsSideBarOpen={() => setIsAddDirectoryTypePanelOpen(!isAddDirectoryTypePanelOpen)}
        totalItems={totalDirectories}
      />

      <LibraryDirectorySideBarView
        directoryTypeOptions={directoryTypeOptionsOptionList}
        isSideBarOpen={isSideBarOpen}
        projectId={String(projectId)}
        selectedDirectory={selectedDirectory}
        onClose={toggleIsSideBarOpen}
        onUpdate={handleDirectoryUpdate}
      />
    </>
  );
};

export { LibraryDirectoryPage };
