import React, { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IDBAsset } from '@netfront/common-library';
import {
  ITagSelectorTag,
  Spacing,
  Spinner,
  TagListSelector,
} from '@netfront/ui-library';
import { ICreatedTagsMap } from 'components';

import { SidebarContainer } from 'components/SidebarContainer';

import { AssetTagsTabProps } from './AssetTagsTab.interfaces';

import {
  useCreateTag,
  useDeleteTag,
  useSearchPaginatedTags,
  useToast,
} from '../../../../hooks';


const AssetTagsTab = ({
  projectId,
  initialTagList = [],
  onUpdateSelectedTags,
}: AssetTagsTabProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [existingTagList, setExistingTagList] = useState<ITagSelectorTag[]>();
  const [createdTags, setCreatedTags] = useState<ICreatedTagsMap[]>([]);
  const [allTags, setAllTags] = useState<IDBAsset['tagList']>([]);
  const [currentlySelectedTags, setCurrentlySelectedTags] = useState<IDBAsset['tagList']>([]);
  const [newCurrentTagId, setCurrentTagId] = useState<string>();

  const { handleCreateTag: executeCreateTag, isLoading: isCreateTagLoading = false } = useCreateTag({
    onCompleted: ({ id, name }) => {

      setCreatedTags([
        ...createdTags,
        {
          id,
          newId: String(newCurrentTagId)
        }
      ]);

      setCurrentTagId(undefined);

      const updatedTagList = [
        ...allTags ?? [],
        {
          id,
          name,
        },
      ] as IDBAsset['tagList'];
      setAllTags(updatedTagList);
      onUpdateSelectedTags([
        ...currentlySelectedTags ?? [],
        {
          id,
          name,
          projectId,
          sort: 1
        }
      ]);

      handleToastSuccess({ message: 'Tag created successfully' });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteTag: executeDeleteTag, isLoading: isDeleteTagLoading = false } = useDeleteTag({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        const createdTag = createdTags.find(({ newId }) => newId === newCurrentTagId);

        setCreatedTags(createdTags.filter(({newId}) => newId !== newCurrentTagId));
        const updatedTags = currentlySelectedTags?.filter(({ id }) => id !== createdTag?.id);

        setCurrentlySelectedTags(updatedTags)
        onUpdateSelectedTags(updatedTags);
        handleToastSuccess({ message: 'Tag deleted successfully' });
      }

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleSearchPaginatedTags: executeSearchPaginatedTags, isLoading: isSearchPaginatedTagsLoading = false } = useSearchPaginatedTags(
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ edges }) => {
        const tags = edges.flatMap(({ node }) => (node ? node : []));
        const formattedTags = tags.map(({id, name}: { id: number; name: string}) => {
          return {
            id: String(id),
            name: String(name),
          };
        });

        setExistingTagList(formattedTags);
        setAllTags(tags);
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
  );

  const handleCreateTag = (value: string, id: string) => {
    if (!value) return;
    setCurrentTagId(id);
    executeCreateTag({
      projectId,
      tagName: value,
    });
  };

  const handleDeleteTag = (id: string) => {
    const mappedId = createdTags.find(({newId}) => id === newId)?.id;

    if (mappedId) {
      setCurrentTagId(id);
      executeDeleteTag({
        tagId: Number(mappedId)
      });
    }
  }

  const handleSelectTags = (existingTags: string[], newTags: string[]) => {
    const existingSelectedTags: IDBAsset['tagList'] = allTags?.filter((tag) => existingTags.includes(String(tag.id))) ?? [];
    const newSelectedCreatedTag: IDBAsset['tagList'] = createdTags.reduce((acc = [], tag) => {
      if (newTags.includes(tag.newId)) {
        const savedTag = allTags?.find(({ id }) => id === tag.id );

        if (savedTag) acc.push(savedTag);
      }
      return acc;
    }, [] as IDBAsset['tagList']) ?? [];

    const newSelectedTags = [
      ...existingSelectedTags,
      ...newSelectedCreatedTag
    ];

    setCurrentlySelectedTags(newSelectedTags);
    onUpdateSelectedTags(newSelectedTags);
  }


  useEffect(() => {
    if (!projectId) return;
    executeSearchPaginatedTags({
      projectId,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentlySelectedTags(initialTagList);
  }, [initialTagList])

  const isLoading = isCreateTagLoading || isSearchPaginatedTagsLoading || isDeleteTagLoading;


  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
      <Spacing>
        <TagListSelector
          initialSelectedTags={currentlySelectedTags?.map(({id}) => String(id))}
          tagList={existingTagList}
          isLabelSideBySide
          onCreateTag={handleCreateTag}
          onDeleteTag={handleDeleteTag}
          onSelect={handleSelectTags}
        />
      </Spacing>
    </SidebarContainer>
  );
};

export { AssetTagsTab };
