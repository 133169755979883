import { useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { DBAssetTypeType, IAssetTag, IDBAsset } from '@netfront/common-library';
import { FormFieldProps } from '@netfront/ui-library';
import { ICreateAssetOnCompletedResponse, IUpdateAssetOnCompletedResponse, useCreateAsset, useDeleteAsset, useToast, useToggleAssetPublic, useUpdateAsset } from 'hooks';
import { pushImageToAws } from 'utils';



const useUpsertAssetWrapper = () => {
  const uploadedFileRef = useRef<{value: File | undefined}>({value: undefined });
  const deletedAssetIdRef = useRef<{value: string | undefined}>({ value: undefined });
  const dynamicCallBackRef = useRef<((asset?: IDBAsset) => void) | null>(null);

  const publicAssetDetails = useRef<{value: { hasPublicToggle: boolean; isInitiallyPublic: boolean; isPublic: boolean; }}>({value: {
    hasPublicToggle: false,
    isInitiallyPublic: false,
    isPublic: false,
  } });

  const [isAssetUploadToAwsLoading, setIsAssetUploadToAwsLoading] = useState<boolean>(false);

  const { handleToastError } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleDeleteAsset } = useDeleteAsset({
    onError: handleGetError,
  });

  const cleanUpAndReturn = (data?: ICreateAssetOnCompletedResponse | IUpdateAssetOnCompletedResponse) => {
    if (dynamicCallBackRef.current) {
      dynamicCallBackRef.current(data);
    }

    uploadedFileRef.current.value = undefined;
    deletedAssetIdRef.current.value = undefined;
    dynamicCallBackRef.current = null;
    publicAssetDetails.current.value = {
      hasPublicToggle: false,
      isInitiallyPublic: false,
      isPublic: false,
    }
  };

  const handleUpsertAssetResponse = async ({data, isCreate = false, signedUrl }: { data?: ICreateAssetOnCompletedResponse | IUpdateAssetOnCompletedResponse; isCreate?: boolean; signedUrl: string,} ) => {
    if (uploadedFileRef.current.value) {
      setIsAssetUploadToAwsLoading(true);

      await pushImageToAws(signedUrl, uploadedFileRef.current.value, () => {
        setIsAssetUploadToAwsLoading(false);

        const { assetId } = data ?? {};

        const {
          hasPublicToggle,
          isPublic,
          isInitiallyPublic,
        } = publicAssetDetails.current.value;

        if (deletedAssetIdRef.current.value) {
          handleDeleteAsset({
            assetId: deletedAssetIdRef.current.value
          });
        };
  
        if (hasPublicToggle && ((!isCreate && isPublic !== isInitiallyPublic)) || (isCreate && isPublic)) {
          handleToggleAssetPublic({
            assetId: String(assetId),
          })
        } else {
          cleanUpAndReturn(data);
        }
      });
    } else {
      const { assetId } = data ?? {};

      const {
        hasPublicToggle,
        isPublic,
        isInitiallyPublic,
      } = publicAssetDetails.current.value;

      if (hasPublicToggle && ((!isCreate && isPublic !== isInitiallyPublic)) || (isCreate && isPublic)) {
        handleToggleAssetPublic({
          assetId: String(assetId),
        })
      } else {
        cleanUpAndReturn();
      }
    }
  };

  const { handleToggleAssetPublic } = useToggleAssetPublic({
    onCompleted: (data) => {
      cleanUpAndReturn(data);
    },
    onError: handleGetError,
  });

  const { handleUpdateAsset, isLoading: isUpdateAssetLoading = false } = useUpdateAsset({
    onCompletedAsync: async (data) => {
      const { signedUrl } = data;

      await handleUpsertAssetResponse({ signedUrl: String(signedUrl), data });

    },
    onError: handleGetError,
  });

  const { handleCreateAsset, isLoading: isCreateAssetLoading = false } = useCreateAsset({
    onCompletedAsync: async (data) => {
      const { signedUrl } = data;
      await handleUpsertAssetResponse({signedUrl, data, isCreate: true});
    },
    onError: handleGetError,
  });

  const handleUpsertAsset = ({
    assetIdKey = 'assetId',
    assetType,
    uploadedFile,
    deletedFileId,
    callBack,
    item,
    projectId,
    isCreate = false,
    isAnimatedImage = false,
    hasPublicToggle = false,
    isPublic = false,
    isInitiallyPublic = false,
    updatedAsset,
  }: {
    assetIdKey?: string;
    assetType: string;
    callBack: (asset?: IDBAsset) => void;
    deletedFileId?: string;
    hasPublicToggle?: boolean;
    isAnimatedImage?: boolean;
    isCreate?: boolean;
    isInitiallyPublic?: boolean;
    isPublic?: boolean;
    item?: FormFieldProps;
    projectId: string;
    updatedAsset?: FormFieldProps;
    uploadedFile?: File;
 
  }) => {
    publicAssetDetails.current.value = {
      hasPublicToggle,
      isPublic,
      isInitiallyPublic,
    };

    dynamicCallBackRef.current = callBack;

    if (isCreate && uploadedFile) {
      uploadedFileRef.current.value = uploadedFile;
      deletedAssetIdRef.current.value = deletedFileId;
      
      
      const { name: imageName, type, size } = uploadedFile;
      const { tagList = [] } = item ?? {}

      handleCreateAsset({
        alt: imageName,
        contentType: type,
        description: imageName,
        fileName: imageName,
        fileSizeInBytes: size,
        imageSize: assetType === 'image' ? 'SMALL' : undefined,
        title: imageName,
        projectId: projectId,
        tagList: (tagList as IAssetTag[]).map(({ name: tagName }: { name: string}) => tagName),
        type: assetType.toUpperCase() as DBAssetTypeType,
        isAnimatedImage,
      });
    } else {

      if (updatedAsset) {
        const { contentType, fileName = '', tagList = [], alt, description = '', title, [`${assetIdKey}`]: assetId } = updatedAsset;
        const { type = '', name = '', size = 0 } = uploadedFile ?? {};

        handleUpdateAsset({
          assetId,
          alt,
          contentType: uploadedFile ? type : contentType,
          description,
          fileName: uploadedFile ? name : String(fileName),
          fileSizeInBytes: uploadedFile ? size : undefined,
          imageSize: assetType === 'image' ? 'SMALL' : undefined,
          tagIds: (tagList as IAssetTag[]).map(({id}: {id: number}) => id),
          title,
          isAnimatedImage,
        });
      }

      callBack();

      if (deletedFileId) {
        handleDeleteAsset({
          assetId: deletedFileId,
        });
      }
    }
  };

  return {
    isLoading: isCreateAssetLoading || isAssetUploadToAwsLoading || isUpdateAssetLoading,
    handleUpsertAsset,
  };
};
export { useUpsertAssetWrapper };
