import { gql } from '@apollo/client';

const UPDATE_PAGE_SORT = gql`
  mutation updatePageSort($contentPageId: Int!, $parentId: Int, $sort: Int!) {
    contentPage {
      orderContentPage(
        contentPage: {
          contentPageId: $contentPageId
          parentId: $parentId
          sort: $sort
        }
      ) {
        id
      }
    }
  }
`;

export { UPDATE_PAGE_SORT };
