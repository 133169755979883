import { gql } from '@apollo/client';



const GET_CONTENT_PAGE_DETAILS_QUERY = gql`
  query getContentPage(
    $contentPageId: Int!
  ) {
    contentPage {
      getContentPage(contentPageId: $contentPageId) {
        id
        seoTitle
        seoDescription
        releaseDate
        contentPageTypeId
        title
        url
        status
        mode
        contentPageConfigurationNavigation {
          enableBackButton
          enableNextButton
          backButtonToolTip
          nextButtonToolTip
        }
        minimumExposureTime
        audio {
          assetId
          presignedUrl
          contentType
          fileName
          fileSizeInBytes
          isAnimatedImage
          presignedUrl
          s3Key
          type
          title
          isPublic
        }
        tags {
          id
          name
          sort
        }
        asset {
          assetId
          presignedUrl
          isAnimatedImage
        }
        contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
          asset {
            assetId
            presignedUrl
            contentType
            fileName
            fileSizeInBytes
            isAnimatedImage
            presignedUrl
            s3Key
            type
          }
          contentPageConfigurationNavigation {
            enableBackButton
            enableNextButton
            backButtonToolTip
            nextButtonToolTip
          }
          bubbleTexts {
            angle
            character {
              id
              image {
                presignedUrl
                contentType
                s3Key
              }
              name
              projectId
              imageId
            }
            characterId
            id
            isTailVisible
            hasShadow
            shape
            tailType
            sort
            text
            w
            x
            y
          }
          audio {
            assetId
            presignedUrl
            contentType
            fileName
            fileSizeInBytes
            isAnimatedImage
            presignedUrl
            s3Key
            type
            title
            isPublic
          }
          id
          minimumExposureTime
          sort
          url
          title
          parentId
        }
      }
    }
  }
`;

export { GET_CONTENT_PAGE_DETAILS_QUERY };
