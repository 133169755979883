/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IDBAsset } from '@netfront/common-library';
import { IContentSection, IContentSectionContainer, IContentSnippet } from '@netfront/ekardo-content-library';
import { FormFieldProps } from '@netfront/ui-library';


const getContentStyleDetails = ({ entity, additionalParameters}: { additionalParameters?: FormFieldProps; entity?: IContentSection | IContentSectionContainer | IContentSnippet }): FormFieldProps => {
  const {
    cssStyle,
    borderColor: entityBorderColor,
    backgroundColor: entityBackgroundColor,
  } = entity ?? {};

  const { id: entityStyleId } = cssStyle ?? {};

  return {
    initialStyleId: entityStyleId,
    entityStyleId,
    entityBorderColor: entityBorderColor || '',
    entityBackgroundColor: entityBackgroundColor || '',
    ...additionalParameters ?? {},
  };
};

const getFormattedContentStyles = ({ item, assetId }: { assetId?: IDBAsset['assetId']; item: FormFieldProps; }) => {
  const {
    entityStyleId: styleId,
    entityBorderColor: borderColor,
    entityBackgroundColor: backgroundColor,
    entityLinkColor: linkColor,
    entityListIconColor: listIconColor,
    initialStyleId,
    entityDisplayStyle: displayStyle,
    isFullStretch = false,
  } = item;

  const shouldAttachCustomTheme = Boolean(styleId) && initialStyleId !== styleId;
  const shouldDetachCustomTheme = !styleId && Boolean(initialStyleId);

  return {
    baseVariables: {
      borderColor,
      backgroundColor,
    },
    textSnippet: {
      linkColor,
      listIconColor,
    },
    customTheme: {
      styleId,
      shouldAttachCustomTheme,
      shouldDetachCustomTheme,
    },
    configurationStyle: {
      displayStyle,
    },
    section: {
      backgroundImage: assetId,
      style: isFullStretch ? 'STRETCHED': 'NORMAL'
    }
  };
};



export { getContentStyleDetails, getFormattedContentStyles };
