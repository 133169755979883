import React, { useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { ControlledForm, Dialog, FormFieldProps, GeneralTabIcon, NavigationTabIcon, RelatedTabIcon, SeoTabIcon, SideBarTabSet, Spinner, StyleTabIcon, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { AssetTagsTab, UpsertPageGeneralTab, UpsertPageSeoTab, UpsertPageNavigationTab, SidebarContainer, StyleTab } from 'components';
import { useUpdatePageStatus, useGetContentPageDetails, useToast, useUpsertAssetWrapper, useUpsertPage } from 'hooks';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getPageCommonVariables, getPageDefaultValues } from './PageSidebarView.helpers';
import { IPageSidebarProps } from './PageSidebarView.interfaces';


const PageSidebarView = ({ 
  projectId,
  isPageSidebarOpen = false,
  pageSidebarDetails,
  handleDeletePage,
  handleUpdatePage,
  handleCreatePage,
  closePageSidebar,
  contentType,
}: IPageSidebarProps) => {
  
  const { handleToastError } = useToast();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const droppedFileAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { pageId, sort, contentGroupId, parentId } = pageSidebarDetails ?? {};

  const { control, handleSubmit, reset, setValue, getValues} = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        pageTitle: yup.string().label('Title').required(),
        url: yup.string().label('Url').required(),
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetContentPageDetails, isLoading: isGetContentPageDetailsLoading = false } = useGetContentPageDetails({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentPage }) => {

      setDefaultValues(getPageDefaultValues({ page: contentPage }));

    },
    onError: handleGetError,
  });

  const { handleUpsertPage, isLoading: isUpsertPageLoading = false } = useUpsertPage({
    onCreate: (returnedContentPage) => {
      reset();
      closePageSidebar();
      handleCreatePage(returnedContentPage);
    },
    onUpdate: (returnedContentPage) => {
      reset();
      closePageSidebar();
      handleUpdatePage(returnedContentPage);
    }
  });

  const { handleUpdatePageStatus, isLoading: isUpdatePageStatusLoading = false } = useUpdatePageStatus({
    onCompleted: () => {
      closePageSidebar();
      setIsDeleteDialogOpen(false);
      handleDeletePage(Number(pageId));
    },
    onError: handleGetError,
  });

  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };

  const handleDelete = () => {
    if (!pageId) return;
    handleUpdatePageStatus({
      contentPageId: Number(pageId),
      status: 'DELETED',
    });
  };

  const handleSave = (item: FormFieldProps) => {

    const { imageAssetId, isAnimatedImage = false } = item;

    handleUpsertAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(projectId),
      uploadedFile: droppedFileAssetRef.current.value,
      deletedFileId: hasDeletedOriginalImageRef.current.value && imageAssetId ? imageAssetId : undefined,
      isAnimatedImage,
      callBack: (imageAsset?: IDBAsset) => {

        const commonVariables = getPageCommonVariables({
          uploadedAssetId: imageAsset ? String(imageAsset.assetId): undefined,
          sort: Number(sort ?? 0),
          item,
          parentId,
        });

        handleUpsertPage({
          contentPageId: pageId,
          contentGroupId,
          variables: commonVariables,
        });
      }
    });
  };


  useEffect(() => {
    if (!pageId) {
      setDefaultValues(getPageDefaultValues({}));
    } else {
      void handleGetContentPageDetails({
        contentPageId: pageId
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, pageSidebarDetails]);

  const isLoading = isUpsertAssetLoading || isUpsertPageLoading || isGetContentPageDetailsLoading || isUpdatePageStatusLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >


      <SideBarTabSet
        ref={tabsetRef}
        defaultActiveTabId="id_general_tab"
        handleOpenCloseSideBar={() => {
          reset();
          closePageSidebar();
        }}
        hasViewPadding={false}
        isSideBarOpen={isPageSidebarOpen}
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () => isPageSidebarOpen ? (
              <UpsertPageGeneralTab
                contentType={contentType}
                control={control as Control<FormFieldProps>}
                getValues={getValues}
                initialTitle={defaultValues.pageTitle}
                pageId={pageId}
                projectId={projectId}
                setValue={setValue}
                onDeleteAsset={handleRemoveAsset}
                onDrop={handleDropFile}
              />
            ) : <></>,
          },
          {
            icon: SeoTabIcon,
            id: 'id_seo_tab',
            label: 'SEO',
            view: () => isPageSidebarOpen ? (
              <UpsertPageSeoTab
                control={control as Control<FormFieldProps>}
              />
            ) : <></>,
          },
          {
            icon: NavigationTabIcon,
            id: 'id_navigation_tab',
            label: 'Navigation',
            view: () => isPageSidebarOpen ? (
              <UpsertPageNavigationTab
                control={control as Control<FormFieldProps>}
              />
            ) : <></>,
          },
          {
            icon: RelatedTabIcon,
            id: 'id_tag_tab',
            label: 'Tags',
            view: () => isPageSidebarOpen ? (
              <SidebarContainer>
                <AssetTagsTab
                  initialTagList={defaultValues.tagList ?? []}
                  projectId={String(projectId)}
                  onUpdateSelectedTags={(tags: IDBAsset['tagList']) => setValue('tagList', tags)}
                />
              </SidebarContainer>
            ) : <></>,
          },
          {
            icon: StyleTabIcon,
            id: 'id_style_tab',
            label: 'Style',
            view: () => isPageSidebarOpen ? (
              <StyleTab
                control={control as Control<FormFieldProps>}
                entityType="page"
              />
            ): <></>,
          },
        ]}
        onDelete={pageId ? () => setIsDeleteDialogOpen(true): undefined }
        onSaveButtonType="submit"
      />
      </ControlledForm>

      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete page?`}
        isNarrow
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};


export { PageSidebarView };
