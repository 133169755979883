
import { ActionsIcon, CalendarIcon, FormIcon, HelpDocsIcon, ImageSliderIcon, InsightsIcon, InvertedCalendarIcon, LearningIcon, NotesTabIcon, QuestionnaireIcon, ResourceIcon, TranscriptTabIcon, UserIcon } from '@netfront/ui-library';

import { IProgressTrackingSubTableData, IProgressTrackingSubTableDataParams } from './ProgressTrackingSubTable.interfaces';

const getProgressTrackingSubTableData = ({ userFlowProgress }: IProgressTrackingSubTableDataParams): IProgressTrackingSubTableData[] => {
  return userFlowProgress.flatMap(({ userFlowId, progress, userFlowName }) => {
    const activities = progress.filter(({ stepType }) => stepType === 'ACTIVITY');
    const blogs = progress.filter(({ stepType }) => stepType === 'BLOG');
    const calendars = progress.filter(({ stepType }) => stepType === 'CALENDAR');
    const cartoons = progress.filter(({ stepType }) => stepType === 'CARTOON');
    const consents = progress.filter(({ stepType }) => stepType === 'CONSENT');
    const events = progress.filter(({ stepType }) => stepType === 'EVENTS');
    const forms = progress.filter(({ stepType }) => stepType === 'FORM');
    const helps = progress.filter(({ stepType }) => stepType === 'HELP');
    const imageMaps = progress.filter(({ stepType }) => stepType === 'IMAGE_MAP');
    const learnings = progress.filter(({ stepType }) => stepType === 'LEARNING');
    const news = progress.filter(({ stepType }) => stepType === 'NEWS');
    const pages = progress.filter(({ stepType }) => stepType === 'PAGE');
    const questionnaires = progress.filter(({ stepType }) => stepType === 'QUESTIONNAIRE');
    const resources = progress.filter(({ stepType }) => stepType === 'RESOURCE');
    const userFlows = progress.filter(({ stepType }) => stepType === 'USER_FLOW');

    const rows = [];
    
    if (activities.length > 0) {
      rows.push({
        id: `${userFlowId}-activities`,
        title: userFlowName,
        progress: activities,
        type: {
          helpText: 'Activity',
          icon: ActionsIcon,
        }
      });
    }

    if (blogs.length > 0) {
      rows.push({
        id: `${userFlowId}-blogs`,
        title: userFlowName,
        progress: blogs,
        type: {
          helpText: 'Blog',
          icon: ResourceIcon,
        }
      });
    }
    
    if (calendars.length > 0) {
      rows.push({
        id: `${userFlowId}-calendars`,
        title: userFlowName,
        progress: calendars,
        type: {
          helpText: 'Calendar',
          icon: InvertedCalendarIcon,
        },
      });
    }
   
    if (cartoons.length > 0) {
      rows.push({
        id: `${userFlowId}-cartoons`,
        title: userFlowName,
        progress: cartoons,
        type: {
          helpText: 'Cartoon',
          icon: UserIcon,
        }
      });
    }
    
    if (consents.length > 0) {
      rows.push({
        id: `${userFlowId}-consents`,
        title: userFlowName,
        progress: consents,
        type: {
          helpText: 'Consent',
          icon: NotesTabIcon,
        }
      });
    }
    
    if (events.length > 0) {
      rows.push({
        id: `${userFlowId}-events`,
        title: userFlowName,
        progress: events,
        type: {
          helpText: 'Event',
          icon: CalendarIcon,
        }
      });
    }
    
    if (forms.length > 0) {
      rows.push({
        id: `${userFlowId}-forms`,
        title: userFlowName,
        progress: forms,
        type: {
          helpText: 'Form',
          icon: FormIcon,
        }
      });
    }
    
    if (helps.length > 0) {
      rows.push({
        id: `${userFlowId}-helps`,
        title: userFlowName,
        progress: helps,
        type: {
          helpText: 'Help',
          icon: HelpDocsIcon,
        }
      });
    }
    
    if (imageMaps.length > 0) {
      rows.push({
        id: `${userFlowId}-image-maps`,
        title: userFlowName,
        progress: imageMaps,
        type: {
          helpText: 'Image map',
          icon: ImageSliderIcon,
        }
      });
    }
    
    if (learnings.length > 0) {
      rows.push({
        id: `${userFlowId}-learnings`,
        title: userFlowName,
        progress: learnings,
        type: {
          helpText: 'Learning',
          icon: LearningIcon,
        }
      });
    }
    
    if (news.length > 0) {
      rows.push({
        id: `${userFlowId}-news`,
        title: userFlowName,
        progress: news,
        type: {
          helpText: 'News',
          icon: TranscriptTabIcon,
        }
      });
    }
    
    if (pages.length > 0) {
      rows.push({
        id: `${userFlowId}-pages`,
        title: userFlowName,
        progress: pages,
        type: {
          helpText: 'Page',
          icon: FormIcon,
        }
      });
    }
    
    if (questionnaires.length > 0) {
      rows.push({
        id: `${userFlowId}-questionnaires`,
        title: userFlowName,
        progress: questionnaires,
        type: {
          helpText: 'Questionnaire',
          icon: QuestionnaireIcon,
        }
      });
    }
    
    if (resources.length > 0) {
      rows.push({
        id: `${userFlowId}-resources`,
        title: userFlowName,
        progress: resources,
        type: {
          helpText: 'Resource',
          icon: ResourceIcon,
        }
      });
    }
    
    if (userFlows.length > 0) {
      rows.push({
        id: `${userFlowId}-userFlows`,
        title: userFlowName,
        progress: userFlows,
        type: {
          helpText: 'User flow',
          icon: InsightsIcon,
        }
      });
    }

    return rows;
  });
};


export { getProgressTrackingSubTableData };
