import capitalize from "lodash.capitalize";

const getTranscriptTypeLabel = (name: string): string => {
  if (name === 'CHAT_TO') return 'Chat';
  if (name === 'STOP_AND_THINK') return 'Stop\\Think';
  if (name === 'THINK') return 'Thought';

  return capitalize(String(name).toLowerCase().replaceAll('_', ' '));
}


export { getTranscriptTypeLabel };