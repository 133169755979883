import { gql } from '@apollo/client';



const GET_CONTENT_PAGE_TYPES_QUERY = gql`
  query getPageTypes($projectId: String!) {
    contentPage {
      getContentPageTypes(projectId: $projectId) {
        id
        name
      }
    }
  }
`;

export { GET_CONTENT_PAGE_TYPES_QUERY };
