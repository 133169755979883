import { useHowlerLazyQuery } from 'hooks';

import { GET_NOTIFICATION } from './useGetNotification.graphql';
import {
  IGetNotificationQueryGraphQLResponse,
  IGetNotificationQueryVariables,
  IHandleGetNotificationParams,
  IUseGetNotification,
  IUseGetNotificationOptions,
} from './useGetNotification.interfaces';

const useGetNotification = (options?: IUseGetNotificationOptions): IUseGetNotification => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetNotificationOptions);

  const [executeGetNotification, { loading: isLoading }] = useHowlerLazyQuery<IGetNotificationQueryGraphQLResponse, IGetNotificationQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          notification: { get: notification },
        } = data;

        onCompleted({
          notification,
        });
      },
      onError,
    },
    query: query ?? GET_NOTIFICATION,
    token,
  });

  const handleGetNotification = ({ notificationId }: IHandleGetNotificationParams) => {
    void executeGetNotification({
      variables: {
        notificationId,
      },
    });
  };

  return {
    handleGetNotification,
    isLoading,
  };
};

export { useGetNotification };
