import { useEffect, useState } from 'react';


import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, Dialog, FormFieldProps, GeneralTabIcon, Input, SidebarButtons, SideBarTabSet,  Spacing,  Spinner, useControlledForm } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import {  useCreateAppComponent, useDeleteAppComponent, useToast, useUpdateAppComponent } from 'hooks';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';


import { getAppComponentDefaultValues } from './AppComponentSidebarView.helpers';
import { AppComponentSidebarViewProps } from './AppComponentSidebarView.interfaces';


const AppComponentSidebarView = ({
  isSideBarOpen = false,
  onCancel,
  onUpdate,
  appIdentifier,
  appVersion,
  selectedComponent,
}: AppComponentSidebarViewProps) => {

  const { handleToastError, handleToastSuccess } = useToast();
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({ name: '', image: ''});

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
      }),
    ),
  });

  const handleError = (error: ApolloError) => {
    handleToastError({
      error,
    });
  };

  const { handleDeleteAppComponent, isLoading: isDeleteAppComponentLoading = false } = useDeleteAppComponent({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      handleToastSuccess({ message: 'Component successfully deleted'});
      reset();
      onUpdate();
    },
    onError: handleError,
  });


  const { handleCreateAppComponent, isLoading: isCreateAppComponentLoading = false } = useCreateAppComponent({
    onCompleted: ({ component: { id }}) => {
      handleToastSuccess({ message: 'Component successfully added'});
      reset();
      onUpdate(id);
    },
    onError: handleError,
  });

  const { handleUpdateAppComponent, isLoading: isUpdateAppComponentLoading = false } = useUpdateAppComponent({
    onCompleted: () => {
      handleToastSuccess({ message: 'Component successfully updated'});
      reset();
      onUpdate();

    },
    onError: handleError,
  });


  const handleOnCancel = () => {
    reset();
    onCancel();
  };

  const handleSave = (item: FormFieldProps) => {
    const { title } = item;

    if (!selectedComponent) {
      handleCreateAppComponent({
        appIdentifier,
        appVersion,
        title,
      });
    } else {
      handleUpdateAppComponent({
        componentId: selectedComponent.id,
        title,
      });
    }
  };

  useEffect(() => {
    setDefaultValues(getAppComponentDefaultValues(selectedComponent));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponent, isSideBarOpen]);

  const isLoading = isDeleteAppComponentLoading || isCreateAppComponentLoading || isUpdateAppComponentLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete Component?"
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedComponent) {
            return;
          }

          handleDeleteAppComponent({
            componentId: selectedComponent.id,
          });
        }}
      />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
      >


        <SideBarTabSet
          defaultActiveTabId="id_component_general_tab"
          handleOpenCloseSideBar={handleOnCancel}
          hasViewPadding={false}
          hideSideBarButtons={true}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_component_general_tab',
              label: 'General',
              view: () =>
                <SidebarContainer>
                  <Spacing>
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="title"
                      render={({ field, fieldState }) => (
                        <Input
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_app_component_title"
                          labelText="Title"
                          type="text"
                          isLabelSideBySide
                          isRequired
                          {...field}
                        />
                      )}
                    />
                  </Spacing>
                  <SidebarButtons
                    buttonSize="xs"
                    isDeleteButtonDisabled={isLoading}
                    isSaveButtonDisabled={isLoading}
                    onCancel={handleOnCancel}
                    onDelete={() => setIsDeleteDialogOpen(true)}
                    onSaveButtonType="submit"
                  />
                </SidebarContainer>
            },
          ]}

        />
      </ControlledForm>
    </>
  );
};

export { AppComponentSidebarView };
