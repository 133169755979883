import React, { useEffect, useState, useContext } from 'react';

import {  useGetCustomFields } from '@netfront/gelada-identity-library';
import {
  Avatar,
  EnterIcon,
  FlexContainer,
  Input,
  NavigationButton,
  SingleDatePicker,
  Spacing,
  Spinner,
  ToggleSwitch,
} from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { ICustomField, IUpdatedCustomField, ProgressTrackingUserTabProps } from './ProgressTrackingUserTab.interfaces';

import { CachingEntitiesContext } from '../../../../context';
import { useToast } from '../../../../hooks';

const ProgressTrackingUserTab = ({ user }: ProgressTrackingUserTabProps) => {

  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const { project } = useContext(CachingEntitiesContext);
  const { services } = project ?? {};

  const [projectId, setProjectId] = useState<string>('');
  const [customFields, setCustomFields] = useState<IUpdatedCustomField[]>([]);
  const [isAdditionalDetailsVisible, setAreAdditionalDetailsVisible] = useState<boolean>(false);
  const [isSocialAccessActive, setIsSocialAccessActive] = useState<boolean>(false);




  const { handleGetCustomFields, isLoading: isGetCustomFieldsLoading = false } = useGetCustomFields({
    onCompleted: (data) => {
      const updatedCustomFields: IUpdatedCustomField[] = data.customFields.map((customField) => {
        const { id: customFieldId, __typename, name: customFieldName, responses = [] } = customField as unknown as ICustomField;

        const value =
          responses && responses.length ? responses[0].text ?? responses[0].value ?? responses[0].dateTime ?? responses[0].number : '';
        return {
          customFieldId,
          customFieldName,
          text: value,
          __typename,
        };
      });
      setCustomFields(updatedCustomFields);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });



  const handleSocialAccessChange = () => {
    setIsSocialAccessActive(!isSocialAccessActive);
  };

  const handleToggleAdditionalDetails = () => {
    setAreAdditionalDetailsVisible(!isAdditionalDetailsVisible);
  };


  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleGetCustomFields({
      projectId: String(projectId),
      scope: 'USER',
      userId: user.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isAdditionalDetailsVisible]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetCustomFieldsLoading;

  const {
    communityName,
    credential: { email = '', phoneNumber = '' },
    firstName,
    id: userId,
    lastName,
  } = user;

  const fullName = `${firstName} ${lastName}`.trim();

  return (
    <>
      <Spinner isLoading={isLoading} />

      {isAdditionalDetailsVisible ? (
        <>
          {customFields.length > 0 && (
            <>
              <Spacing size="x-large">
                <NavigationButton
                  additionalClassNames="c-sidebar-navigation-button"
                  direction="previous"
                  icon={EnterIcon}
                  rotationCssSuffix="180"
                  text="Back to general"
                  onClick={handleToggleAdditionalDetails}
                />
              </Spacing>

              <Input
                id="userId"
                labelText="User Id"
                name="userId"
                type="text"
                value={userId}
                isDisabled
                onChange={() => { return }}
              />

              {customFields.map((customField) => {
                const { customFieldId, customFieldName, text, __typename } = customField;
                return (
                  <section key={`custom-field-${customFieldId}`}>
                    {__typename === 'CustomFieldBooleanGraphType' && (
                      <ToggleSwitch
                        id={`custom-field-${customFieldId}`}
                        isChecked={String(text).toLowerCase() === 'true'}
                        labelText={customFieldName}
                        isDisabled
                        onChange={() => { return }}
                      />
                    )}
                    {__typename === 'CustomFieldDateGraphType' && (
                      <SingleDatePicker 
                        config={{
                          maxDate: new Date('01-01-2050'),
                        }}
                        dateInputProps={{
                          labelText: customFieldName,
                          id: `custom-field-${customFieldId}`,
                          name: `new-custom-field-${customFieldId}`,
                          type: 'text',
                          isDisabled: true,
                        }}
                        selectedDates={text ? [new Date(text)] : undefined}
                        isDisabled 
                        onSelected={() => { return }} 
                      />
                    )}
                    {__typename === 'CustomFieldTextGraphType' && (
                      <Input
                        id={`custom-field-text-${customFieldName}`}
                        labelText={customFieldName}
                        name={customFieldName}
                        type="text"
                        value={text}
                        isDisabled
                        onChange={() => { return }}
                      />
                    )}
                    {__typename === 'CustomFieldNumberGraphType' && (
                      <Input
                        id={`custom-field-number-${customFieldName}`}
                        labelText={customFieldName}
                        name={customFieldName}
                        type="number"
                        value={text}
                        isDisabled
                        onChange={() => { return }}
                      />
                    )}
                  </section>
                );
              })}

              {services?.includes('BONOBO') && (
                <Spacing size="2x-large">
                  <ToggleSwitch
                    id="socialAccess"
                    isChecked={isSocialAccessActive}
                    labelText="Forum | Social access"
                    isDisabled
                    onChange={handleSocialAccessChange}
                  />
                </Spacing>
              )}

            </>
          )}
        </>
      ) : (
        <>
          <Spacing size="large">
            <FlexContainer justifyContent="space-between">
              <Avatar title={fullName} />

              <ToggleSwitch
                additionalClassNames="c-user-status-toggle"
                id="user-status"
                isChecked={user.status === 'ACTIVE'}
                labelText="Activated"
                isDisabled
                isInline
                onChange={() => { return}}
              />
            </FlexContainer>
          </Spacing>

          <Input  id="first-name" labelText="First name" name="firstName" type="text" value={firstName} isDisabled onChange={() => { return}} />

          <Input id="last-name" labelText="Last name" name="lastName" type="text" value={lastName} isDisabled onChange={() => { return}} />

          <Input id="email" labelText="Email" name="email" type="text" value={email} isDisabled onChange={() => { return}} />

          <Input id="phone" labelText="Phone" name="phoneNumber" type="text" value={phoneNumber} isDisabled onChange={() => { return}} />

          <Input
            id="display-name"
            labelText="Display name"
            name="communityName"
            tooltipText="Displayed user name"
            type="text"
            value={communityName}
            isDisabled
            onChange={() => { return}}
          />


          {Boolean(customFields.length) && customFields.length > 0 && (
            <FlexContainer justifyContent="center" isFullWidth>
              <button
                className="c-progress-tracking-user__additional-details"
                type="button"
                onClick={handleToggleAdditionalDetails}
              >
                Additional details
              </button>
            </FlexContainer>
          )}
        </>
      )}
    </>
  );
};

export { ProgressTrackingUserTab };
