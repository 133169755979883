import { gql } from '@apollo/client';
import { AppFragment, AudioFragment, ButtonFragment, CodeFragment, DocumentFragment, ContentSnippetSliderFragment, EmbedFragment, FormFragment, ImageFragment, MapFragment, QuestionFragment, QuoteFragment, RegionsFragment, SliderFragment, SpacerFragment, SummaryFragment, TextFragment, VideoFragment } from 'fragments';

const UPDATE_CONTAINER = gql`
  mutation UpdateContentContainer(
    # $contentContainer: UpdateContentContainerInput!,
    $backgroundColor: String,
    $borderColor: String,
    $containerId: Int!,
    $sectionId: Int!,
    $title: String!,
    $visible: Boolean!

    $shouldCreateEvent: Boolean!,
    $createContentEvent: CreateContentEventInput,
    $shouldUpdateEvent: Boolean!,
    $updateContentEvent: UpdateContentEventInput,

    $styleId: Int!,
    $shouldAttachCustomTheme: Boolean!,
    $shouldDetachCustomTheme: Boolean!,

  ) {
    contentEvent {
      createEvent(contentEvent: $createContentEvent)
        @include(if: $shouldCreateEvent) {
        id
      }
      updateEvent(contentEvent: $updateContentEvent)
        @include(if: $shouldUpdateEvent) {
        id
      }
    }

    cssStyle {
      detach(containerId: $containerId)
        @include(if: $shouldDetachCustomTheme)

      attachStyleToContainer(containerId: $containerId, styleId: $styleId)
        @include(if: $shouldAttachCustomTheme)
    }
    contentSection {
      updateContainer(
      #  contentContainer: $contentContainer
      backgroundColor: $backgroundColor,
      borderColor: $borderColor,
      containerId: $containerId,
      sectionId: $sectionId,
      title: $title,
      visible: $visible,
      ) {
        id
        sort
        identifier
        sectionId
        backgroundColor
        borderColor
        cssStyle {
          id
          name
        }
        title
        contentEventId
        contentEvent {
          animation
          animationDirection
          entityId
          event
          eventDirection
          eventTitle
          infinite
          millisecondDelay
          millisecondSpeed
          asset {
            assetId
            contentType
            s3Key
          }
          backgroundColor
          borderColor
          titleColor
          style
          triggerStyle
          iconHorizontalAlignment
          isDefaultOpen
        }
        snippets {
          id
          sort
          visible
          cssStyle {
            id
            name
          }
          ... on ContentSnippetAvatarType {
            avatar {
              configuration {
                key
                value
              }
              imageUrl
            }
          }
          borderColor
          backgroundColor
          type: __typename
          __typename
          ...app
          ...audio
          ...button
          ...code
          ...document
          ...embed
          ...form
          ...image
          ...map
          ...question
          ...quote
          ...regions
          ...slider
          ...spacer
          ...summary
          ...text
          ...video
        }
      }
    }
  }

  ${AppFragment}
  ${AudioFragment}
  ${ButtonFragment}
  ${CodeFragment}
  ${ContentSnippetSliderFragment}
  ${DocumentFragment}
  ${EmbedFragment}
  ${FormFragment}
  ${ImageFragment}
  ${MapFragment}
  ${QuestionFragment}
  ${QuoteFragment}
  ${RegionsFragment}
  ${SliderFragment}
  ${SpacerFragment}
  ${SummaryFragment}
  ${TextFragment}
  ${VideoFragment}

`;

export { UPDATE_CONTAINER };
