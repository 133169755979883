import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_PAGE_SORT } from './useUpdatePageSort.graphql';
import {
  IUpdatePageSortMutationGraphQLResponse,
  IUpdatePageSortMutationVariables,
  IUseUpdatePageSortOptions,
  IUseUpdatePageSort,
  IUpdatePageSortMutationParams,
} from './useUpdatePageSort.interfaces';

const useUpdatePageSort = (options?: IUseUpdatePageSortOptions): IUseUpdatePageSort => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdatePageSortOptions);

  const [executeUpdatePageSort, { loading: isLoading }] = useEkardoMutation<IUpdatePageSortMutationGraphQLResponse, IUpdatePageSortMutationVariables>({
    mutation: mutation ?? UPDATE_PAGE_SORT,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { orderContentPage: contentPage },
        } = data;

        onCompleted({
          contentPage,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdatePageSort = async ({
    contentPageId,
    parentId,
    sort,
  }: IUpdatePageSortMutationParams) => {
    await executeUpdatePageSort({
      variables: {
        contentPageId,
        parentId,
        sort,
      }
    });
  };

  return {
    handleUpdatePageSort,
    isLoading,
  };
};

export { useUpdatePageSort };
