import React, { useEffect, useState } from 'react';


import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, FormFieldProps, ControlledForm, SidebarButtons, Select, useControlledForm, FieldsetAccordionGroup, Spinner, Breadcrumbs, Message, IconRadioGroup, ToggleSwitch, SingleCheckbox, HomeIcon } from '@netfront/ui-library';
import { SidebarContainer, tailPositionOptions, TextEditor } from 'components';
import { useUpsertTranscript } from 'hooks';
import { ITranscript } from 'interfaces';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { createSyntheticEvent } from 'utils';
import * as yup from 'yup';


import { TranscriptCharacterPicker } from '../TranscriptCharacterPicker';
import { TranscriptPositionPicker } from '../TranscriptPositionPicker';
import { TranscriptQuizAnswers } from '../TranscriptQuizAnswers';
import { TranscriptQuizConfiguration } from '../TranscriptQuizConfiguration';

import { getTranscriptDefaultValues, getTranscriptVariables, setQuickAddTranscriptValues } from './UpsertTranscript.helpers';
import { UpsertTranscriptProps } from './UpsertTranscript.interfaces';



const UpsertTranscript = ({ 
  onUpdate,
  onCancel,
  onDelete,
  selectedTranscript,
  assetId,
  shapeTypes = [],
  tailTypes = [],
  projectId,
  imageUrl,
  transcriptItems = [],
}: UpsertTranscriptProps) => {
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [transcriptShape, setTranscriptShape] = useState<string>('');
  const [currentTranscriptItems, setCurrentTranscriptItems] = useState<ITranscript[]>([]);

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { reset, control, handleSubmit, watch, setValue, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        text: yup.string().label('Transcript text').required(),
        xAxis: yup.number().label('X axis').required(),
        yAxis: yup.number().label('Y axis').required(),
        width: yup.number().label('Width').required(),
        shape: yup.string().label('Shape').required(),
        tailType: yup.string().label('Tail'),
        angle: yup.string().when(['tailType'], {
          is: (tailType: string) => ['DEFAULT', 'THINK'].includes(tailType),
          then: yup.string().label('Angle').required(),
        }),
        correctResponseText: yup.string().when(['shape'], {
          is: (shape: string) => shape === 'QUIZ',
          then: yup.string().label('Correct response').required(),
        }),
        incorrectResponseText: yup.string().when(['shape'], {
          is: (shape: string) => shape === 'QUIZ',
          then: yup.string().label('Incorrect response').required(),
        }),
        answers: yup.array().when(['shape'], {
          is: (shape: string) => shape === 'QUIZ',
          then: yup.array().required().min(1, 'Please provide at least one answer'),
        }),
        correctAnswer: yup.string().when(['isMultiResponse', 'shape'], {
          is: (isMultiResponse: boolean, shape: string) => shape === 'QUIZ' && !isMultiResponse,
          then: yup.string().label('Correct answer').required()
        }) 
      }),
    ),
  });

  const { handleUpsertTranscript, isLoading = false} = useUpsertTranscript({
    onCreate: ({ transcript }) => {
      const { isCreateNew = false } = getValues();

      if (isCreateNew) {
        setDefaultValues(setQuickAddTranscriptValues(getValues()));
        setCurrentTranscriptItems([
          ...currentTranscriptItems,
          transcript,
        ]);
      } else {
        reset();
        onUpdate();
      }
    },
    onUpdate: () => {
      reset();
      onUpdate();
    }
  });

  const handleCancel = () => {
    const { isCreateNew = false } = getValues();

    if (isCreateNew) {
      onUpdate();
    } else {
      onCancel();
    }
  };

  const handleSaveTranscript = (item: FormFieldProps) => {
    handleUpsertTranscript({
      transcriptId: selectedTranscript?.id,
      request: {
        assetId,
        ...getTranscriptVariables(item)
      },
    });
  };

  useEffect(() => {
    setDefaultValues(getTranscriptDefaultValues(selectedTranscript));
    setTranscriptShape(selectedTranscript?.shape  ?? '');
  }, [selectedTranscript]);

  useEffect(() => {
    setCurrentTranscriptItems(transcriptItems);
  }, [transcriptItems])

  return (
    <div>
      <SidebarContainer hasPaddingEnds={false}>
        <Spacing size="2x-small">
          <Breadcrumbs
            itemDivider="chevron"
            items={[
              {
                key: 'transcript-lister',
                onClick: handleCancel,
                icon: HomeIcon,
                content: 'Transcript list',
              },
              {
                key: 'upsert-transcript',
                content: <span className="c-breadcrumb-item__link">{selectedTranscript ? 'Edit' : 'Create'} transcript</span>
              }
            ]}
            isContained
          />
        </Spacing>
      </SidebarContainer>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSaveTranscript(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          // eslint-disable-next-line no-console
          console.log({errs});
        }}
      >
        <FieldsetAccordionGroup
        data={[
          {
            id: 'id_transcript_general',
            label: 'General',
            isOpenOnLoad: true,
            children: (
              <>
                <Spacing>
                  <Controller
                    control={control as Control<FormFieldProps>}
                    name="text"
                    render={({ field, fieldState }) => (
                      <TextEditor
                        errorMessage={getFormFieldErrorMessage(fieldState)}
                        id="id_transcript_text"
                        initialValue={defaultValues?.text ?? ''}
                        label="Transcript"
                        minHeight={175}
                        isRequired
                        onInputChange={(content: string) => {
                          field.onChange(createSyntheticEvent('text', content))
                        }}
                      />
                    )}
                  />
                </Spacing>
                <Spacing>
                  <Controller
                    control={control as Control<FormFieldProps>}
                    name="shape"
                    render={({ field, fieldState }) => {
                      const errorMessage = getFormFieldErrorMessage(fieldState)
                      return (
                        <>
                          <IconRadioGroup
                            id="id_transcript_shape"
                            items={shapeTypes}
                            label="Shape"
                            name={field.name}
                            selectedValue={field.value}
                            hasLabels
                            isLabelSideBySide
                            isOutlined
                            isRequired
                            onChange={(value) => {
                              field.onChange(value);
                              setTranscriptShape(value);
                              if (!['OVAL', 'BLAST', 'QUIZ', 'RECTANGLE', 'THINK'].includes(value)) {
                                setValue('tailType', 'NONE');
                                setValue('angle', '1');
                              }
                            }}
                          />
                          {Boolean(errorMessage) && (
                            <SidebarContainer>
                              <Message id={`id_transcript_shape_error`} text={errorMessage} type="ERROR" />
                            </SidebarContainer>
                          )}
                        </>
                      );
                    }}
                  />
                </Spacing>
                
                {['OVAL', 'BLAST', 'QUIZ', 'RECTANGLE', 'THINK'].includes(transcriptShape) && (
                  <>
                    <Spacing>
                      <Controller
                        control={control as Control<FormFieldProps>}
                        name="tailType"
                        render={({ field, fieldState }) => {
                          const errorMessage = getFormFieldErrorMessage(fieldState)
                          return (
                            <>
                              <IconRadioGroup
                                id="id_transcript_tail"
                                items={tailTypes}
                                label="Tail"
                                name={field.name}
                                selectedValue={field.value}
                                hasLabels
                                isLabelSideBySide
                                isOutlined
                                onChange={field.onChange}
                              />
                              {Boolean(errorMessage) && (
                                <SidebarContainer>
                                  <Message id={`id_transcript_tail_error`} text={errorMessage} type="ERROR" />
                                </SidebarContainer>
                              )}
                            </>
                          );
                        }}
                      />
                    </Spacing>
                    <Spacing>
                      <Controller
                        control={control as Control<FormFieldProps>}
                        name="angle"
                        render={({ field, fieldState }) => {
                          const errorMessage = getFormFieldErrorMessage(fieldState)
                          return (
                            <>
                              <IconRadioGroup
                                id="id_transcript_tail_position"
                                items={tailPositionOptions}
                                label="Position"
                                name={field.name}
                                selectedValue={field.value}
                                hasLabels
                                isLabelSideBySide
                                isOutlined
                                onChange={field.onChange}
                              />
                              {Boolean(errorMessage) && (
                                <SidebarContainer>
                                  <Message id={`id_transcript_tail_position_error`} text={errorMessage} type="ERROR" />
                                </SidebarContainer>
                              )}
                            </>
                          );
                        }}
                      />
                    </Spacing>
                  </>
                )}

                {transcriptShape === 'STOP_AND_THINK' && (
                  <Spacing>
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="hasInput"
                      render={({ field }) => (
                        <ToggleSwitch
                          additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
                          id="id_has_stop_and_think_input"
                          isChecked={field.value}
                          labelText="Has input?"
                          isInline
                          isLabelSideBySide
                          {...field}
                        />
                      )}
                    />
                  </Spacing>
                )}
                
                {transcriptShape === 'CHAT' && (
                  <Spacing>
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="chatTailType"
                      render={({ field, fieldState }) => (
                        <Select
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_chat_tail_type"
                          labelText="Chat type"
                          options={[
                            {
                              id: 'sent',
                              name: 'Sent',
                              value: 'sent',
                            },
                            {
                              id: 'received',
                              name: 'Received',
                              value: 'received',
                            }
                          ]}
                          isLabelSideBySide
                          {...field}
                        />
                      )}
                    />
                  </Spacing>
                )}
              </>
            )
          },
          {
            id: 'id_transcript_quiz_configuration',
            label: 'Location',
            isOpenOnLoad: true,
            children: (
              <>
                <TranscriptPositionPicker 
                  control={control as Control<FormFieldProps>}
                  defaultValues={defaultValues}
                  imageUrl={imageUrl}
                  setValue={setValue}
                  transcriptId={selectedTranscript?.id}
                  transcriptItems={currentTranscriptItems}
                  watch={watch}
                />
              </>
            )
          },
          {
            id: 'id_transcript_quiz_answers',
            label: 'Answers',
            isVisible: transcriptShape === 'QUIZ',
            isOpenOnLoad: true,
            children: (
              <Controller
                control={control as Control<FormFieldProps>}
                name="character"
                render={({ fieldState }) => {
                  const errorMessage = getFormFieldErrorMessage(fieldState);
                  return (
                    <>
                      <TranscriptQuizAnswers 
                        backToTranscriptListOnClick={onCancel}
                        getValues={getValues}
                        initialTranscriptAnswers={defaultValues?.answers || []}
                        isCreateTranscript={!selectedTranscript} 
                        setValue={setValue} 
                        watch={watch}                
                      />
                      {Boolean(errorMessage) && <Message id={`id_answers_error`} text={errorMessage} type="ERROR" />}
                    </>
                  );
                }
              }/>
            )
          },
          {
            id: 'id_advanced_details',
            label: 'Advanced',
            isOpenOnLoad: transcriptShape === 'QUIZ',
            children: (
              <>
                <TranscriptCharacterPicker 
                  control={control as Control<FormFieldProps>}
                  initialCharacterId={defaultValues?.characterId}
                  projectId={projectId}
                  setValue={setValue}
                />

                {transcriptShape === 'QUIZ' && (
                  <TranscriptQuizConfiguration
                    control={control as Control<FormFieldProps>}
                    initialAnswers={defaultValues?.answers || []}
                    watch={watch}
                  />
                )}
              </>
            )
          },
        ]}
        />
        
        <SidebarButtons
          additionalButton={!selectedTranscript ? (
            <Controller
              control={control as Control<FormFieldProps>}
              name="isCreateNew"
              render={({ field }) => (
                <SingleCheckbox
                  hasPadding={false}
                  id="id_is_create_new"
                  isChecked={field.value}
                  labelText="Create another snippet"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          ) : undefined
          }
          onCancel={handleCancel}
          onDelete={selectedTranscript ? () => onDelete(selectedTranscript.id) : undefined}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </div>
  );
};


export { UpsertTranscript };
