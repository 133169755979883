import { useContext, useState, useEffect } from 'react';

import Link from 'next/link';

import { CachingEntitiesContext } from '../../../../../context';
import { DashboardListTemplatePage } from '../../../../Shared';

const AppDashboardPage = () => {
  const { project } = useContext(CachingEntitiesContext);

  const [projectName, setProjectName] = useState<string>(project?.name ?? '');
  const [projectId, setProjectId] = useState<string>(project?.id ?? '');
  const [organisationKey, setOrganisationKey] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name, organisationId, id } = project;

    setProjectId(id);
    setProjectName(name);
    setOrganisationKey(organisationId);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name, project?.organisationId, project?.id]);

  return (
    <DashboardListTemplatePage
      activePage="library"
      activeSubPage="app"
      breadcrumbItems={[
        {
          key: '0',
          content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}`}>Dashboard</Link>,
        },
        {
          key: '1',
          content: 'Libraries',
        },
      ]}
      description={`App dashboard for ${projectName || 'the project'}`}
      informationBoxMessage={`Manage your project and marketplace apps`}
      isLoading={isLoading}
      logoUrl={project?.logo?.presignedUrl}
      pageId="app"
      pageTitle={`${projectName ? projectName + ' app' : 'App'} dashboard`}
      projectName={projectName}
    />

  );
};

export { AppDashboardPage };
