
import { ILogAccessTableData, ILogAccessTableDataParams } from "./LogAccessPage.interfaces";


const getLogAccessTableData = ({ items }: ILogAccessTableDataParams): ILogAccessTableData[] => {
  return items.map(({
    date = '',
    firstName = '',
    id,
    lastName = '',
   }) => {
    return {
      date,
      firstName,
      id,
      lastName,
    };
  });
};

export { getLogAccessTableData };
