import React, { useState, useEffect } from 'react';

import Link from 'next/link';

import { ReportOptionSelectorProps } from './ReportOptionSelector.interfaces';

export const ReportOptionSelector = ({ baseUrl, selectedOption, reportOptions }: ReportOptionSelectorProps) => {
  const [currentOption, setCurrentOption] = useState<string>();

  useEffect(() => {
    
    setCurrentOption(selectedOption ?? 'all')
  }, [selectedOption]);


  return (
    <ul className="c-report-selector" >
      <li><Link className={`c-report-selector__item ${currentOption === 'all' ? 'is-active': ''}`} href={`${baseUrl}/reporting`}><span>All</span></Link></li>
      {reportOptions.map(({ id, name}) => <li key={id}><Link className={`c-report-selector__item ${currentOption === id ? 'is-active': ''}`} href={`${baseUrl}/reporting/${id}`}><span>{name}</span></Link></li>)}
      
    </ul>
  );
};

ReportOptionSelector.displayName = 'ReportOptionSelector';
