import { Column } from 'react-table';

import { ILogAccessTableData } from './LogAccessPage.interfaces';



const LOG_ACCESS_COLUMNS: ReadonlyArray<Column<ILogAccessTableData>> = [
  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Id</div>,
  },
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">First name</div>,
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Last name</div>,
  },
  
  {
    accessor: 'date',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Date</span>,
  },
];

export { LOG_ACCESS_COLUMNS };
