import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetPostLogsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { POST_LOGS_COLUMNS } from './PostLogsReportPage.constants';
import { getPostLogsTableData } from './PostLogsReportPage.helpers';
import { PostLogsCsvRowData, IPostLogsItem, IPostLogsTableData } from './PostLogsReportPage.interfaces';



const PostLogsReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [postLogsItems, setPostLogsItems] = useState<IPostLogsItem[]>();
  const [allpostLogsItems, setAllPostLogsItems] = useState<IPostLogsItem[]>([]);
  const [postLogsTableData, setPostLogsTableData] = useState<IPostLogsTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>();
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const {
    handleGetPostLogsReport,
    isLoading: isGetPostLogsLoading = false,
  } = useGetPostLogsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<PostLogsCsvRowData>(String(res.data), {
            header: true,
            skipEmptyLines: true,
          });

          const formattedItems = data.map((row: PostLogsCsvRowData) => ({
            id: Number(row['Author id']),
            postId: String(row['Post id']),
            groupName: String(row['Group name']),
            userType: String(row['User type']),
            post: String(row['Post']),
            postDate: String(row['Post date']),
            postDateUtc: String(row['Post date (utc)']),
            action: String(row['Action']),
          }));
          
          setTotalCount(data.length);
          setAllPostLogsItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setPostLogsItems(paginatedItems);
          setLastItemIndex(pageSize);

        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });

  const generateReport = () => {
    void handleGetPostLogsReport({
      dateFrom: dateRange && dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange && dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: 'CSV'
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setPostLogsItems(allpostLogsItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setPostLogsItems(allpostLogsItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!postLogsItems) {
      return;
    }

    setPostLogsTableData(
      getPostLogsTableData({
        items: postLogsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postLogsItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetPostLogsLoading;

  return (
    <ReportTable<IPostLogsTableData>
      activePath={['reporting', 'reporting-social', 'export-post-logs']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Post logs',
      }]}
      columns={POST_LOGS_COLUMNS}
      csvUrl={csvUrl}
      data={postLogsTableData}
      description={`Post logs reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports post logs based on the selected filters'}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { PostLogsReportPage };
