
import { IReportedPostsTableData, IReportedPostsTableDataParams } from "./ReportedPostsReportPage.interfaces";


const getReportedPostsTableData = ({ items }: IReportedPostsTableDataParams): IReportedPostsTableData[] => {
  return items.map(({
    email = '',
    feeling = '',
    firstName = '',
    groupName = '',
    id,
    lastName = '',
    likes = '',
    post = '',
    postDate = '',
    postDateUtc = '',
    postId = '',
    userType = '',
   }) => {
    return {
      email,
      feeling,
      firstName,
      groupName,
      id,
      lastName,
      likes,
      post,
      postDate,
      postDateUtc,
      postId,
      userType,
    };
  });
};

export { getReportedPostsTableData };
