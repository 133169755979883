import { useContext, useEffect, useRef, useState } from 'react';

import { DBContentGroupTypeType } from '@netfront/ekardo-content-library';
import {
  BinIcon,
  Button,
  CloseIcon,
  ConditionIcon,
  Container,
  ContentBuilderNavigationRef,
  Dialog,
  EmptyState,
  EnterIcon,
  EyeHideIcon,
  FilterIcon,
  FormIcon,
  PlusIcon,
  SettingsIcon,
  Spinner,
} from '@netfront/ui-library';
import { ActionsSidebarView, ContainerSidebarView, ContentBuilderBulkActionDialog, ContentBuilderPreview, ContentBuilderTemplatePage, PageSidebarView, SectionGroupSidebarView, SectionSidebarView, SnippetSidebarView } from 'components';
import { ContentBuilderContext, ContentBuilderProvider, DashboardContext, IBulkActionEntity } from 'context';
import { useToast } from 'hooks';
import { IEntityType } from 'interfaces';
import capitalize from 'lodash.capitalize';
import { useRouter } from 'next/router';

import { ContentBuilderPageProps } from './ContentBuilderPage.interfaces';


const ContentBuilderPageInner = () => {

  const {
    contentType,
    contentGroupId,
    contentPage,
    isLoading = false,
    isEmptyPage = false,
    firstSectionGroupId,
    openDeleteEntityDialog,
    openUpdateEntitySidebar,
    openBulkActionDialog,
    addItemToEntity,
    openActionsSidebar,
    handleEnterFormsMode,
    handleLeaveFormsMode,
    pageOptions,
    navigationItems,
    pageUrl,
    openCreateEntitySidebar,
    deleteEntityDetails,
    closeDeleteEntityDialog,
    handleDeleteEntity,
    bulkActionDetails,
    baseUrl,
    pageId,
    openPageSidebar,
    isFormsMode = false,
    closePageSidebar,
    handleCreatePage,
    handleDeletePage,
    handleUpdatePage,
    isPageSidebarOpen = false,
    pageSidebarDetails,
    projectId,
    exitEditMode,
    isActionsSidebarOpen = false,
    isSnippetSidebarOpen = false,
    isSectionSidebarOpen = false,
    isContainerSidebarOpen = false,
    isSectionGroupSidebarOpen = false,
    onDrop
  } = useContext(ContentBuilderContext);
  const { push } = useRouter();
  const { handleToastError } = useToast();
  const { dashboardLink } = useContext(DashboardContext);
  const navigationSidebarRef = useRef<ContentBuilderNavigationRef>(null);
  const [contentBuilderPageUrl, setContentBuilderPageUrl] = useState('');

  const { entityId, type: entityType, isOpen = false } = deleteEntityDetails ?? {};
  const { isOpen: isBulkActionOpen = false } = bulkActionDetails ?? {};



  const handleSelectPage = (value: string) => {
    if (!(value && contentBuilderPageUrl)) return;
    push(`${contentBuilderPageUrl}/${value}/edit`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };

  useEffect(() => {
    if (!baseUrl) return;

    setContentBuilderPageUrl(baseUrl);

  }, [baseUrl]);

  useEffect(() => {
    if (navigationSidebarRef.current) {
      navigationSidebarRef.current.handleToggleSidebar(
        !isPageSidebarOpen &&
        !isActionsSidebarOpen &&
        !isSnippetSidebarOpen &&
        !isSectionSidebarOpen &&
        !isContainerSidebarOpen &&
        !isSectionGroupSidebarOpen
      );
    }

  }, [
    isPageSidebarOpen, 
    isActionsSidebarOpen,
    isSnippetSidebarOpen,
    isSectionSidebarOpen,
    isContainerSidebarOpen,
    isSectionGroupSidebarOpen,
  ]);

  return (
    <>
      <Spinner isLoading={isLoading}/>
      <ContentBuilderTemplatePage
        ref={navigationSidebarRef}
        acceptsMap={{
          root: ['sectionGroup'],
          sectionGroup: ['section'],
          section: ['container'],
          container: ['container', 'targetSnippet'],
          snippet: [],
        }}
        activePageId=""
        additionalClassNames=""
        additionalToolbarItems={
          <Button
            additionalClassNames="c-content-builder__edit-button is-active"
            icon={CloseIcon}
            size="xs" 
            text="Exit"
            type="button"
            variant="primary-inverse"
            onClick={exitEditMode}
          />
        }
        contentGroupId={contentGroupId}
        contentType={contentType}
        dashboardLink={`${String(dashboardLink)}/alpha-content/${String(contentType).toLowerCase()}`}
        description="Content builder"
        navigationItems={navigationItems}
        pageDropdownOptions={!isFormsMode ? [
          {
            id: 'id_edit_page',
            icon: SettingsIcon,
            label: 'Edit',
            isHidden: false,
            onClick: () => openPageSidebar(Number(pageId)),
          },
          {
            id: 'id_create_page',
            icon: PlusIcon,
            label: 'Create page',
            isHidden: false,
            onClick: () => openPageSidebar(),
          },
          {
            id: 'id_page_actions',
            icon: ConditionIcon,
            label: 'Actions',
            isHidden: false,
            onClick: () => openActionsSidebar(Number(pageId), 'page'),
          },
        ]: undefined}
        pageTitle={`${capitalize(contentType)} content builder`}
        selectedValue={String(pageUrl)}
        selectOptions={pageOptions}
        settingItems={[
          {
            id: 'id_edit_item',
            icon: SettingsIcon,
            label: 'Edit',
            isBulkOption: false,
            isHidden: false,
            onClick: ({ id: selectedEntityId, type: selectedEntityType }) => openUpdateEntitySidebar(Number(selectedEntityId), selectedEntityType as IEntityType)
          },
          {
            id: 'id_add_new_item',
            icon: EnterIcon,
            label: 'Add new content',
            isVisibleByTypeArray: ['section', 'container', 'sectionGroup'],
            onClick: ({ id: selectedEntityId, type: selectedEntityType }) => addItemToEntity(Number(selectedEntityId), selectedEntityType as IEntityType),
          },
          {
            id: 'id_entity_actions',
            icon: ConditionIcon,
            label: 'Actions',
            isHidden: false,
            onClick: ({ id: selectedEntityId, type: selectedEntityType }) => openActionsSidebar(Number(selectedEntityId), selectedEntityType as IEntityType),
          },
          // {
          //   id: 'id_duplicate_item',
          //   icon: CopyIcon,
          //   label: 'Duplicate',
          //   isVisibleByTypeArray: ['targetSnippet'],
          //   isBulkOption: false,
          //   onClick: () => alert('Duplicate')
          // },
          {
            id: 'id_enter_form_builder_item',
            icon: FormIcon,
            label: 'Enter form builder',
            isVisibleByTypeArray: ['targetSnippet'],
            isBulkOption: false,
            onClick: ({ id: selectedEntityId })  => handleEnterFormsMode(Number(selectedEntityId)),
          },
          {
            id: 'id_leave_form_builder_item',
            icon: FormIcon,
            label: 'Leave form builder',
            isVisibleByTypeArray: ['targetSnippet'],
            isBulkOption: false,
            onClick: ()  => handleLeaveFormsMode(),
          },
          {
            id: 'id_validation_item',
            icon: FilterIcon,
            label: 'Update validation',
            isBulkOption: true,
            isVisibleByTypeArray: ['bulk'],
            onBulkClick: (selectedOptions: { id: string; type?: string; }[]) => openBulkActionDialog({ selectedEntities: selectedOptions.map(({id, type }) => ({id, type: type === 'targetSnippet' ? 'SNIPPET' : type?.toUpperCase() })) as IBulkActionEntity[], type: 'updateValidation' }),
            onClick: ({ id: selectedEntityId, type }) => openDeleteEntityDialog({ id: Number(selectedEntityId), type: type as IEntityType}),
          },
          {
            id: 'id_visibility_item',
            icon: EyeHideIcon,
            label: 'Update visibility',
            isHidden: false,
            isBulkOption: true,
            onBulkClick: (selectedOptions: { id: string; type?: string; }[]) => openBulkActionDialog({ selectedEntities: selectedOptions.map(({id, type }) => ({id, type: type === 'targetSnippet' ? 'SNIPPET' : type?.toUpperCase() })) as IBulkActionEntity[], type: 'updateVisibility' }),
            onClick: ({ id: selectedEntityId, type }) => openBulkActionDialog({ selectedEntities: [{ type: type === 'targetSnippet' ? 'SNIPPET' : type?.toUpperCase(), id: selectedEntityId }] as IBulkActionEntity[], type: 'updateVisibility', isIndividualEntity: true }),
          },
          {
            id: 'id_delete_item',
            icon: BinIcon,
            label: 'Delete',
            isBulkOption: true,
            isHidden: false,
            onBulkClick: (selectedOptions: { id: string; type?: string; }[]) => openBulkActionDialog({ selectedEntities: selectedOptions.map(({id, type }) => ({id, type: type === 'targetSnippet' ? 'SNIPPET' : type?.toUpperCase() })) as IBulkActionEntity[], type: 'delete' }),
            onClick: ({ id: selectedEntityId, type }) => openDeleteEntityDialog({ id: Number(selectedEntityId), type: type as IEntityType}),
          },
        ]}
        hasScrollToContainer
        onAddClick={(selectedId: string | number, type: string) => openCreateEntitySidebar(Number(selectedId), type as IEntityType)}
        onDrop={onDrop}
        onSelectChange={({target : { value }}: { target: { value: string }}) => handleSelectPage(value)}
      >
        <>
          <Container additionalClassNames="c-content-builder-container" alignment="left" size="2x-large">
            {isEmptyPage && firstSectionGroupId && (
              <EmptyState
                buttonText="New section"
                imageUrl="/images/content-landing.svg"
                size="large"
                text="Get building by hitting new section below. Need some help? Check out our help guide."
                title="Get started!"
                onClick={() => addItemToEntity(firstSectionGroupId, 'sectionGroup')} />
              )}
            {contentPage && !isEmptyPage && (
              <ContentBuilderPreview contentPage={contentPage}/>
            )}
            
          </Container>
          <ActionsSidebarView />
          <SnippetSidebarView />
          <SectionSidebarView />
          <ContainerSidebarView />
          <SectionGroupSidebarView />
          <PageSidebarView 
            closePageSidebar={closePageSidebar}
            contentType={contentType as DBContentGroupTypeType}
            handleCreatePage={handleCreatePage}
            handleDeletePage={handleDeletePage}
            handleUpdatePage={handleUpdatePage}
            isPageSidebarOpen={isPageSidebarOpen}
            pageSidebarDetails={pageSidebarDetails}
            projectId={String(projectId)}
          />
          { entityType && entityId && (
            <Dialog
              isOpen={isOpen}
              title={`Delete ${entityType  === 'targetSnippet' ? 'snippet' : entityType}?`}
              isNarrow
              onCancel={closeDeleteEntityDialog}
              onClose={closeDeleteEntityDialog}
              onConfirm={() => handleDeleteEntity({ id: entityId, type: entityType })}
            />
          )}
          { isBulkActionOpen && (
            <ContentBuilderBulkActionDialog
              isOpen={isBulkActionOpen}
            />
          )}
        </>
      </ContentBuilderTemplatePage>
    </>
  );
};


const ContentBuilderPage = ({ contentType, contentGroupUrl, pageUrl, isFormBuilder }: ContentBuilderPageProps) => {
  return (
    <ContentBuilderProvider contentGroupUrl={contentGroupUrl} contentType={contentType} isForm={isFormBuilder} pageUrl={pageUrl}>
      <ContentBuilderPageInner/>
    </ContentBuilderProvider>
  )
}

export { ContentBuilderPage };
