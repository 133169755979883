import { gql } from '@apollo/client';

const UPDATE_CONTAINER_SORT = gql`
  mutation sortContainer($containerId: Int!, $sectionId: Int!, $sort: Int!) {
    contentSection {
      sortContainer(
        containerId: $containerId
        sectionId: $sectionId
        sort: $sort
      ) {
        id
        sort
      }
    }
  }
`;

export { UPDATE_CONTAINER_SORT };
