
import { CREATE_COMMUNITY_MUTATION } from './useCreateCommunity.graphql';
import {
  ICreateCommunityMutationGraphQLResponse,
  ICreateCommunityMutationVariables,
  IHandleCreateCommunityParams,
  IUseCreateCommunityOptions,
  IUseCreateCommunity,
} from './useCreateCommunity.interfaces';

import { useBonoboMutation } from '../../useBonoboMutation';

const useCreateCommunity = (options?: IUseCreateCommunityOptions): IUseCreateCommunity => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseCreateCommunityOptions);

  const [executeCreateCommunity, { loading: isLoading }] = useBonoboMutation<
    ICreateCommunityMutationGraphQLResponse,
    ICreateCommunityMutationVariables
  >({
    mutation: mutation ?? CREATE_COMMUNITY_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          community: { create: communityConnection },
        } = data;

        onCompleted({ communityConnection });
      },
      onError,
    },
    token,
  });

  const handleCreateCommunity = async ({
    inputType: { 
      categoryId, 
      description, 
      title, 
      privacy, 
      status 
    },
  }: IHandleCreateCommunityParams) => {
    await executeCreateCommunity({
      variables: {
        inputType: {
          categoryId,
          description,
          privacy,
          status,
          title,
        },
      },
    });
  };

  return {
    handleCreateCommunity,
    isLoading,
  };
};

export { useCreateCommunity };
