import React, { useEffect, useState } from 'react';


import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, ToggleSwitch, Input, Select, Textarea } from '@netfront/ui-library';
import { ITranscriptAnswer } from 'components';
import { Controller } from 'react-hook-form';

import { TranscriptQuizConfigurationProps } from './TranscriptQuizConfiguration.interfaces';



const TranscriptQuizConfiguration = ({ control, watch, initialAnswers = [], initialIsMultiResponse = false }: TranscriptQuizConfigurationProps) => {
  const [correctAnswerOptions, setCorrectAnswerOptions] = useState<ITranscriptAnswer[]>([]);
  const [isMultiResponse, setIsMultiResponse] = useState<boolean>(false);

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  useEffect(() => {
    // We need to do this in order to stop watch from re-rendering everything from within the parent container on every change
    const subscription = watch((value) => {
      const { answers: updatedAnswers = [] } = value;
      if (updatedAnswers !== correctAnswerOptions) {
        setCorrectAnswerOptions(updatedAnswers as ITranscriptAnswer[]);
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    setCorrectAnswerOptions(initialAnswers);
  }, [initialAnswers])

  useEffect(() => {
    setIsMultiResponse(initialIsMultiResponse);
  }, [initialIsMultiResponse])


  return (
    <>
      {!isMultiResponse && (
        <Spacing>
          <Controller
            control={control}
            name="correctAnswer"
            render={({ field, fieldState }) => (
              <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_correct_answer"
              labelText="Correct answer"
              options={correctAnswerOptions.map(({ tempId, text}) => ({
                id: tempId,
                name: text,
                value: tempId,
              }))}
              isLabelSideBySide
              isRequired
              {...field}
              />
            )}
            />
        </Spacing>
      )}
      
      <Spacing>
        <Controller
          control={control}
          name="correctResponseText"
          render={({ field, fieldState }) => (
            <Textarea
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_transcript_correct_response"
              labelText="Correct response"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
      
      <Spacing>
        <Controller
          control={control}
          name="incorrectResponseText"
          render={({ field, fieldState }) => (
            <Textarea
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_transcript_incorrect_response"
              labelText="Incorrect response"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="isMultiResponse"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
              id="id_is_multi_response"
              inlineLabelPosition="start"
              isChecked={field.value}
              labelText="Is multi response"
              tooltipText="Select if one or more options are correct answers"
              isInline
              {...field}
              onChange={(event) => {
                const { target: { checked: isChecked } } = event;
                field.onChange(event);
                setIsMultiResponse(isChecked);
              }}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="isInformation"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
              id="id_is_information"
              inlineLabelPosition="start"
              isChecked={field.value}
              labelText="Is information"
              isInline
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="questionNumber"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_transcript_question_number"
              labelText="Question number"
              type="number"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      
      
      <Spacing>
        <Controller
          control={control}
          name="answersX"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_transcript_answer_width"
              labelText="Answers X axis / %"
              type="number"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="answersY"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_transcript_answer_width"
              labelText="Answers Y axis / %"
              type="number"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="answersWidth"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_transcript_answer_width"
              labelText="Answers width / %"
              type="number"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
    </>
  );
};


export { TranscriptQuizConfiguration };
