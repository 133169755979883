import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, Textarea, ToggleSwitch } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Controller } from 'react-hook-form';

import { UpsertPageNavigationTabProps } from './UpsertPageNavigationTab.interfaces';



const UpsertPageNavigationTab = ({ control }: UpsertPageNavigationTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <SidebarContainer>
      <Spacing>
        <Controller
          control={control}
          name="isEnableBackButton"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
              id="id_enable_back_button"
              isChecked={field.value}
              labelText="Back button"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="backButtonToolTip"
          render={({ field, fieldState }) => (
            <Textarea
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_page_back_button_tooltip"
              labelText="Tooltip text"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isEnableNextButton"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
              id="id_enable_next_button"
              isChecked={field.value}
              labelText="Next button"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="nextButtonToolTip"
          render={({ field, fieldState }) => (
            <Textarea
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_page_next_button_tooltip"
              labelText="Tooltip text"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
    </SidebarContainer>
  );
};

export { UpsertPageNavigationTab };
