import { useContext } from 'react';

import { PageTemplate } from '@netfront/ui-library';
import { useAppHeaderHeight } from 'utils';

import { DashboardContext, formatDashboardItems } from 'context/DashboardContext';

import { BasicPageProps } from './BasicPageTemplate.types';

import { PageLayout } from '../../PageLayout';

const BasicPageTemplate = ({
  additionalClassNames = '',
  breadcrumbItems,
  description,
  informationBoxMessage,
  isLoading = false,
  logoUrl,
  pageTitle,
  projectName,
  children,
  activePage,
  activeSubPage,
}: BasicPageProps) => {

  const { dashboardItems, dashboardLink } = useContext(DashboardContext);
  const headerHeight = useAppHeaderHeight();

  return (
    <PageLayout
      additionalClassNames={`c-basic-page-layout ${additionalClassNames}`}
      meta={{ description: description }}
      title={pageTitle}
      hasPrivateLayout
    >
      <PageTemplate
        activePage={activePage}
        activeSubPage={activeSubPage}
        breadcrumbItems={breadcrumbItems}
        dashboardLink={dashboardLink}
        hasLeftSidebar={true}
        hasToolbar={false}
        informationBoxMessage={informationBoxMessage}
        isLoading={isLoading}
        logoUrl={logoUrl}
        navigationItems={formatDashboardItems(dashboardItems)}
        offsetPositioning={headerHeight}
        size={logoUrl ? 'small': 'medium'}
        title={String(projectName)}
      >
        <div style={{width: '100%'}}>{children}</div>
      </PageTemplate>
    </PageLayout>
  );
};

export { BasicPageTemplate };
