import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DUPLICATE_CONTENT_PAGE } from './useDuplicatePage.graphql';
import {
  IDuplicatePageMutationGraphQLResponse,
  IDuplicatePageMutationVariables,
  IHandleDuplicatePageParams,
  IUseDuplicatePageOptions,
  IUseDuplicatePage,
} from './useDuplicatePage.interfaces';

const useDuplicatePage = (options?: IUseDuplicatePageOptions): IUseDuplicatePage => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDuplicatePageOptions);

  const [executeDuplicatePage, { loading: isLoading }] = useEkardoMutation<IDuplicatePageMutationGraphQLResponse, IDuplicatePageMutationVariables>({
    mutation: mutation ?? DUPLICATE_CONTENT_PAGE,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { copyContentPage: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDuplicatePage = async ({ contentPageId }: IHandleDuplicatePageParams) => {
    await executeDuplicatePage({
      variables: {
        contentPageId,
      },
    });
  };

  return {
    handleDuplicatePage,
    isLoading,
  };
};

export { useDuplicatePage };
