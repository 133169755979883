import { useEffect, useState } from 'react';

import { IContentPage, CMSCartoonSlide, DBContentGroupTypeType } from '@netfront/ekardo-content-library';
import { Spinner } from '@netfront/ui-library';
import { ContentBuilderPreview } from 'components';
import { useGetContentPages, useToast } from 'hooks';


import { flattenCartoonContentPages } from './ContentPdfPage.helpers';
import { ContentPdfPageProps } from './ContentPdfPage.interfaces';


const ContentPdfPage = ({ contentGroupUrl, projectId }: ContentPdfPageProps) => {
  const { handleToastError } = useToast();

  const [contentPages, setContentPages] = useState<IContentPage[]>([]);
  const [contentType, setContentType] = useState<DBContentGroupTypeType>();

  
  const { handleGetContentPages, isLoading: isGetContentPagesLoading = false } = useGetContentPages({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentGroup }) => {
      const { type } = contentGroup;

      setContentType(type);
      const { contentPages: returnedContentPages = [] } = contentGroup;
      setContentPages(type === 'CARTOON' ? flattenCartoonContentPages(returnedContentPages): returnedContentPages);

    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });


  useEffect(() => {
    if (!(projectId && contentGroupUrl)) return;

    handleGetContentPages({
      url: contentGroupUrl,
      projectId: String(projectId),
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentGroupUrl, projectId]);

  return (
    <>
      <Spinner isLoading={isGetContentPagesLoading} />
      <>
      {contentType === 'CARTOON' && contentPages.map((contentPage) => {
        const { asset } = contentPage;

        if (!asset) return <></>;

        return <CMSCartoonSlide key={contentPage.url} contentPage={contentPage} isLargerDevice={true} />
      })}
      
      {contentType !== 'CARTOON' && contentPages.map((contentPage) => <ContentBuilderPreview key={contentPage.url} contentPage={contentPage} isEditMode={false}/>)}
      </>
      
    </>
  )
}

export { ContentPdfPage };
