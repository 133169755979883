import React, { useContext, useState } from 'react';

import { Dialog, GeneralTabIcon, SideBarTabSet } from '@netfront/ui-library';
import { UpsertActionsTab } from 'components';
import { ContentBuilderContext } from 'context';
import capitalize from 'lodash.capitalize';

const ActionsSidebarView = () => {
  const { actionsSidebarDetails, isActionsSidebarOpen = false, closeActionsSidebar, handleDeleteEntity } = useContext(ContentBuilderContext);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const { type, id } = actionsSidebarDetails ?? {};

  const handleDelete = () => {
    setIsDeleteDialogOpen(false);
    closeActionsSidebar();
    if (id && type) handleDeleteEntity({ id, type });
  };

  return (
    <>
      <SideBarTabSet
        defaultActiveTabId="id_actions_tab"
        handleOpenCloseSideBar={() => {
          closeActionsSidebar();
        }}
        hasViewPadding={false}
        isSideBarOpen={isActionsSidebarOpen}
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_actions_tab',
            label: 'Actions',
            view: () => isActionsSidebarOpen ? (
              <UpsertActionsTab
                onDelete={()=> setIsDeleteDialogOpen(true)}
              />
            ) : <></>,

          },
        ]}
        hideSideBarButtons
      />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete ${capitalize(type === 'targetSnippet' ? 'snippet': type)}?`}
        isNarrow
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};

export { ActionsSidebarView };
