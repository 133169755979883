import React, { ReactNode, useEffect, useState } from 'react';

import { Spacing, Table, ButtonIconOnly, FlexContainer, InputFieldWrapper, LogoAvatar, BinIcon, CaretDownIcon, BlastIcon, ChatIcon, FactCheckIcon, InputIcon, InteractiveRegionsIcon, LabelIcon, OvalIcon, ParaIcon, QuizIcon, RectangleIcon, StopAndThinkIcon, ThoughtIcon, TitleIcon, PlusIcon } from '@netfront/ui-library';
import { TranscriptShapeType, ITranscript } from 'interfaces';

import { getTranscriptText } from './TranscriptsOverview.helpers';
import { TranscriptsOverviewProps } from './TranscriptsOverview.interfaces';



const TranscriptsOverview = ({ onEditClick, onDeleteClick, transcriptItems = [] }: TranscriptsOverviewProps) => {
  const [items, setItems] = useState<ITranscript[]>([]);

  const TRANSCRIPT_SHAPE_ICONS: { [key: string]: ReactNode } = {
    'OVAL': <OvalIcon className="c-transcript-overview__item" />,
    'BLAST': <BlastIcon className="c-transcript-overview__item" />,
    'THINK': <ThoughtIcon className="c-transcript-overview__item" />,
    'PARA': <ParaIcon className="c-transcript-overview__item" />,
    'CHAT_TO': <ChatIcon className="c-transcript-overview__item" />,
    'INPUT': <InputIcon className="c-transcript-overview__item" />,
    'QUIZ': <QuizIcon className="c-transcript-overview__item" />,
    'REGIONS': <InteractiveRegionsIcon className="c-transcript-overview__item" />,
    'TITLE': <TitleIcon className="c-transcript-overview__item" />,
    'CHAT_FROM': <ChatIcon className="c-transcript-overview__item" />,
    'RECTANGLE': <RectangleIcon className="c-transcript-overview__item" />,
    'STOP_AND_THINK': <StopAndThinkIcon className="c-transcript-overview__item" />,
    'FACT_CHECK': <FactCheckIcon className="c-transcript-overview__item" />,
    'LABEL': <LabelIcon className="c-transcript-overview__item" />,
    'STOP_AND_THINK_NO_INPUT': <StopAndThinkIcon className="c-transcript-overview__item" />,
  };

  useEffect(() => {
    setItems(transcriptItems);
  }, [transcriptItems]);

  return (
    <>
      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              {
                accessor: 'avatar',
                Cell: ({ value: { presignedUrl, name } }: { value: { name?: string; presignedUrl?: string } }) => (
                  <span>
                    {!presignedUrl && !name ? (
                      <span>-</span>
                    ): (
                      <LogoAvatar
                        logoUrl={presignedUrl}
                        size="x-small"
                        title={name}
                      />
                    )}
                    
                  </span>
                ),
                Header: () => <div>Avatar</div>,
                width: '10%',
              },
              {
                accessor: 'character',
                Cell: ({ value }: { value?: string }) => <span>{value ? value : '-'}</span>,
                Header: () => <div>Character</div>,
                width: '10%',
              },
              {
                accessor: 'type',
                Cell: ({ value }: { value: ReactNode }) => <span className="c-transcript-overview__item">{value}</span>,
                Header: () => <div>Type</div>,
                width: '5%',
              },
              {
                accessor: 'text',
                Cell: ({ value }: { value: string }) => <span>{value.replace(/<\/?[^>]+(>|$)/g, "")}</span>,
                Header: () => <div>Text</div>,
                width: '70%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleDelete,
                    handleEdit,
                    id,
                  }
                }: {
                  value: {
                    handleDelete: (id: number) => void;
                    handleEdit: (id: number) => void;
                    id: number;
                  }
                }) => (
                  <FlexContainer gap="2x-small" justifyContent="end">
                    <ButtonIconOnly
                      icon={BinIcon}
                      isIconBorderVisible={false}
                      text="Delete transcript"
                      variant="danger--tertiary"
                      onClick={() => handleDelete(id)}
                    />
                    <ButtonIconOnly
                      additionalClassNames="h-rotate-right"
                      icon={CaretDownIcon}
                      isIconBorderVisible={false}
                      text="Edit transcript"
                      onClick={() => handleEdit(id)}
                    />
                  </FlexContainer>
                ),
              },
            ]}
            data={items.map((item) => {
              const { character } = item;

              const { name = '', image } = character ?? {};

              const { presignedUrl = '' } = image ?? {};

              return {
                avatar: {
                  name,
                  presignedUrl,
                },
                character: name,
                type: TRANSCRIPT_SHAPE_ICONS[String(item.shape)],
                text: getTranscriptText(item.text, item.shape as TranscriptShapeType),
                id: item.id,
                settingsButtonData: {
                  id: item.id,
                  handleEdit: onEditClick,
                  handleDelete: onDeleteClick,
                },
              };
            })}
          />
        </Spacing>
      )}

      <Spacing>
        <InputFieldWrapper
          id="id_add_transcript"
          label="Add transcript"
          labelType="span"
          type="custom"
          isLabelSideBySide
        >
          <FlexContainer justifyContent="flex-end" isFullWidth>
            <ButtonIconOnly icon={PlusIcon} text="Add transcript" onClick={() => onEditClick(undefined)} />
          </FlexContainer>
        </InputFieldWrapper>
      </Spacing>
    </>
  );
};


export { TranscriptsOverview };
