import { gql } from '@apollo/client';

const UPDATE_SNIPPET_SORT = gql`
  mutation updateQuestionContentSnippet(
    $snippetId: Int!
    $containerId: Int!
    $sort: Int!
    $visible: Boolean!
  ) {
    contentSnippet {
      updateContentSnippetBase(
        snippet: {
          id: $snippetId
          containerId: $containerId
          sort: $sort
          visible: $visible
        }
      ) {
        id
        containerId
        sort
        visible
      }
    }
  }
`;

export { UPDATE_SNIPPET_SORT };
