import { ContentPageType } from "interfaces";

const getInitialView = ({cartoonType, isOpenedFromEmptyState, pageId }: { cartoonType?: ContentPageType; isOpenedFromEmptyState?: boolean; pageId?: number; }) => {
  if (!cartoonType && !pageId && isOpenedFromEmptyState) return 'selector';
  if (cartoonType === 'slide' && !pageId && !isOpenedFromEmptyState) return 'slide_type_selector';

  if (cartoonType) return cartoonType;

  return 'selector';
};


export { getInitialView };