import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { AssetType, FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getQuickAddEventValues, getUpsertContentEventStatus, getContentStyleDetails, getFormattedContentStyles } from "components";

const getEmbedSnippetDefaultValues = ({ assetType, snippet } : {assetType: AssetType; snippet?: IContentSnippet;}): FormFieldProps => {
  const {
    title,
    contentEvent,
    embed,
    embedProvider,
    visible: isVisible = true,
    embedAssetType,
    isSensitive = false,
    isAutoPlay = false,
    isAuthorVisible = false,
  } = snippet ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);
  const styleDetails = getContentStyleDetails({ entity: snippet });

  return {
    title: title ?? 'Embed snippet',
    code: embed ?? '',
    type: embedAssetType ?? assetType,
    embedProvider: embedProvider ?? '',
    isVisible,
    isAuthorVisible,
    isAutoPlay,
    isSensitive,
    isCreateNew: false,
    ...eventDetails,
    ...animationDetails,
    ...styleDetails,
  };
};

const getEmbedSnippetCommonVariables = ({ eventAssetId, item }: { eventAssetId?: IDBAsset['assetId']; item: FormFieldProps; }) => {
  const {
    title = 'Embed snippet',
    code,
    isVisible,
    type,
    // isSensitive,
    isAuthorVisible,
    isAutoPlay,
    embedProvider,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);
  const { baseVariables, customTheme } = getFormattedContentStyles({ item });

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
      // isSensitive,
      ...baseVariables,
    },
    embedSnippet: {
      code,
      title,
      type: type,
      isAuthorVisible,
      isAutoPlay,
      embedProvider,
    },
    type: 'embed',
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    customTheme,
  };

  return sharedVariables;

};

const setQuickAddEmbedValues = (values: FormFieldProps): FormFieldProps => {
  return {
    ...getQuickAddEventValues(values),
    title: 'Embed snippet',
    code: '',
  };
};

export { getEmbedSnippetDefaultValues, getEmbedSnippetCommonVariables, setQuickAddEmbedValues };
