import { StatusPillProps } from './StatusPill.interfaces';

const StatusPill = ({ status, isHighlighted, color = '' }: StatusPillProps) => {
  return (
    <div className={`c-status-pill ${isHighlighted ? 'c-status-pill--highlighted': ''} ${color ? `c-status-pill--${color}`: ''}`}>
      <div className="c-status-indicator__status">{status.toLowerCase()}</div>
    </div>
  );
};

export { StatusPill };
