import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { BULK_DELETE_ASSETS_MUTATION } from './useBulkDeleteAssets.graphql';
import {
  IBulkDeleteAssetsMutationGraphQLResponse,
  IBulkDeleteAssetsMutationVariables,
  IHandleBulkDeleteAssetsParams,
  IUseBulkDeleteAssetsOptions,
  IUseBulkDeleteAssets,
} from './useBulkDeleteAssets.interfaces';

const useBulkDeleteAssets = (options?: IUseBulkDeleteAssetsOptions): IUseBulkDeleteAssets => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseBulkDeleteAssetsOptions);

  const [executeBulkDeleteAssets, { loading: isLoading }] = useEkardoMutation<
    IBulkDeleteAssetsMutationGraphQLResponse,
    IBulkDeleteAssetsMutationVariables
  >({
    mutation: mutation ?? BULK_DELETE_ASSETS_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { deleteAssets: isCompleted },
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkDeleteAssets = ({ assetIds }: IHandleBulkDeleteAssetsParams) => {
    void executeBulkDeleteAssets({
      variables: {
        assetIds,
      },
    });
  };

  return {
    handleBulkDeleteAssets,
    isLoading,
  };
};

export { useBulkDeleteAssets };
