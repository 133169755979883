import { OperationVariables, QueryTuple } from '@apollo/client';
import { getApolloLink, useApolloLazyQuery, useCookie } from '@netfront/common-library';

import { IUseHowlerLazyQueryOptions } from './useHowlerLazyQuery.interfaces';

const useHowlerLazyQuery = <TData = unknown, TVariables extends OperationVariables = OperationVariables>({
  apolloLink,
  options,
  product,
  projectId,
  query,
  token,
}: IUseHowlerLazyQueryOptions<TData, TVariables>): QueryTuple<TData, TVariables> => {
  const { getAccessTokenCookie, getTemporaryTokenCookie } = useCookie();
  const accessToken = getAccessTokenCookie();
  const temporaryToken = getTemporaryTokenCookie();

  const authenticationLink = getApolloLink({
    apiName: 'howler',
    product,
    projectId,
    token: token ?? accessToken ?? temporaryToken,
  });

  return useApolloLazyQuery({
    apiName: 'howler',
    apolloLink: apolloLink ?? authenticationLink,
    options,
    query,
  });
};

export { useHowlerLazyQuery };
