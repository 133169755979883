import { useEkardoMutation } from '@netfront/ekardo-content-library';

import {
  UPDATE_CONDITION_CHECKBOX,
  UPDATE_CONDITION_RADIO,
  UPDATE_CONDITION_SLIDER,
  UPDATE_CONDITION_TEXT,
  UPDATE_CONDITION_DATE,
} from './useUpdateCondition.graphql';
import { IUpdateConditionMutationGraphQLResponse, IUpdateConditionMutationVariables, IHandleUpdateConditionParams, IUseUpdateCondition, IUseUpdateConditionOptions } from './useUpdateCondition.interfaces';

const useUpdateCondition = (options?: IUseUpdateConditionOptions): IUseUpdateCondition => {
  const { mutation, onCompleted, onError, token, conditionType } = options ?? ({} as IUseUpdateConditionOptions);

  const addConditionMutationQueryMap = {
    checkbox: UPDATE_CONDITION_CHECKBOX,
    radio: UPDATE_CONDITION_RADIO,
    slider: UPDATE_CONDITION_SLIDER,
    text: UPDATE_CONDITION_TEXT,
    calendar: UPDATE_CONDITION_DATE,
  };

  const [executeUpdateCondition, { loading: isLoading }] = useEkardoMutation<IUpdateConditionMutationGraphQLResponse, IUpdateConditionMutationVariables>({
    mutation: mutation ?? addConditionMutationQueryMap[conditionType],
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { updateCondition: condition },
        } = data;

        onCompleted({
          condition,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateCondition = (variables: IHandleUpdateConditionParams) => {

    void executeUpdateCondition({
      variables,
    });
  };

  return {
    handleUpdateCondition,
    isLoading,
  };
};

export { useUpdateCondition };
