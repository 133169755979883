import React, { useContext, useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ColorPickerWrapper, IOption, InformationBox, Select, Spacing, Spinner, ToggleSwitch } from '@netfront/ui-library';
import { ContentBuilderTabWrapper, Uploader } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetCssStyleOptions, useToast } from 'hooks';
import { Controller } from 'react-hook-form';

import { StyleTabProps } from './StyleTab.interfaces';

import { responseTypeToUnderScoredConst } from '../../../../constants';

const StyleTab = ({ control, onDeleteAsset, onDrop, entityType, initialQuestionType = '', watch, hasLinkStyling = false}: StyleTabProps) => {
  const { project } = useContext(CachingEntitiesContext);
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { handleToastError } = useToast();
  const [cssStyleOptions, setCssStyleOptions] = useState<IOption[]>([]);
  const [hasCssStyleOptionsBeenFetched, setHasCssStyleOptionsBeenFetched] = useState<boolean>(false);
  const [questionType, setQuestionType] = useState<string>('');

  const { handleGetCssStyleOptions, isLoading: isGetCssStyleOptionsLoading = false } = useGetCssStyleOptions({
    fetchPolicy: 'no-cache',
    onCompleted: ({ options }) => {
      const formattedOptions: IOption[] = options.map(({ id , name }) => ({
        id,
        value: id,
        name,
      })) as IOption[];

      setHasCssStyleOptionsBeenFetched(true);
      setCssStyleOptions(formattedOptions);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (hasCssStyleOptionsBeenFetched) return;
    if (!project) return;
    handleGetCssStyleOptions({
      projectId: project.id,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCssStyleOptionsBeenFetched, project]);

  useEffect(() => {
    if (!watch) return;
    // We need to do this in order to stop watch from re-rendering everything from within the parent container on every change
    const subscription = watch((value) => {
      const { resType: updatedRestType } = value;
      if (updatedRestType !== questionType) {
        setQuestionType(String(updatedRestType));
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

  useEffect(() => {
    if (!initialQuestionType) return;
    setQuestionType(initialQuestionType);
  }, [initialQuestionType]);


  return (
    <ContentBuilderTabWrapper isSnippet={entityType === 'targetSnippet'}>
      <Spinner isLoading={isGetCssStyleOptionsLoading} />
      <Spacing>
        <InformationBox message="Edit your event configuration." />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="styleId"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_entity_theme"
              labelText="Custom theme"
              options={cssStyleOptions}
              {...field}
              isLabelSideBySide
            />
          )}
        />
      </Spacing>

      {entityType !== 'page' && (
        <>
          <Spacing>
            <Controller
              control={control}
              name="entityBorderColor"
              render={({ field, fieldState }) => (
                <ColorPickerWrapper
                  color={field.value}
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_border_colour"
                  label="Border colour"
                  pickerPosition="left"
                  type="hex"
                  isLabelSideBySide
                  onHexChange={field.onChange}
                />
              )}
            />
          </Spacing>
          
          <Spacing>
            <Controller
              control={control}
              name="entityBackgroundColor"
              render={({ field, fieldState }) => (
                <ColorPickerWrapper
                  color={field.value}
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_background_colour"
                  label="Background colour"
                  pickerPosition="left"
                  type="hex"
                  isLabelSideBySide
                  onHexChange={field.onChange}
                />
              )}
            />
          </Spacing>
        </>
      )}
      
      { [responseTypeToUnderScoredConst.Radio, responseTypeToUnderScoredConst.Checkbox].includes(questionType) && (
        <Spacing>
          <Controller
            control={control}
            name="entityDisplayStyle"
            render={({ field, fieldState }) => (
              <Select
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_snippet_display_style"
                labelText="Display style"
                options={[
                  {
                    id: 0,
                    value: 'DEFAULT',
                    name: 'Default',
                  },
                  {
                    id: 1,
                    value: 'CARD',
                    name: 'Cards',
                  },
                ]}
                {...field}
                isLabelSideBySide
              />
            )}
          />
        </Spacing>
      )}

      { hasLinkStyling && (
        <>
          <Spacing>
            <Controller
              control={control}
              name="entityLinkColor"
              render={({ field, fieldState }) => (
                <ColorPickerWrapper
                  color={field.value}
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_link_colour"
                  label="Link colour"
                  pickerPosition="left"
                  type="hex"
                  isLabelSideBySide
                  onHexChange={field.onChange}
                />
              )}
            />
          </Spacing>
          
          <Spacing>
            <Controller
              control={control}
              name="entityListIconColor"
              render={({ field, fieldState }) => (
                <ColorPickerWrapper
                  color={field.value}
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_list_icon_colour"
                  label="List icon colour"
                  pickerPosition="left"
                  type="hex"
                  isLabelSideBySide
                  onHexChange={field.onChange}
                />
              )}
            />
          </Spacing>
        </>
      )}

      {entityType === 'section' && (
        <>
          <Spacing>
            <Controller
              control={control}
              name="isFullStretch"
              render={({ field }) => (
                <ToggleSwitch
                  additionalClassNames={`c-sidebar-toggle `}
                  id="id_is_full_stretch"
                  inlineLabelPosition="start"
                  isChecked={field.value}
                  labelText="Full stretch"
                  isInline
                  {...field}
                />
              )}
            />
          </Spacing>
          {onDrop && onDeleteAsset && (
            <Spacing>
              <Controller
                control={control}
                name="entityBackgroundImage"
                render={({ field }) => (
                  <Uploader
                    assetType="image"
                    initialUrl={field.value}
                    isRequired={false}
                    labelText="Background image"
                    isLabelSideBySide
                    onDelete={onDeleteAsset}
                    onDrop={onDrop}
                    {...field}
                  />
                )}
              />
            </Spacing>
          )}
        </>
      )}
    </ContentBuilderTabWrapper>
  );
};

export { StyleTab };
