import { GET_COMMUNITY } from './useGetCommunity.graphql';
import {
  IGetCommunityQueryGraphQLResponse,
  IGetCommunityQueryVariables,
  IHandleGetCommunityParams,
  IUseGetCommunity,
  IUseGetCommunityOptions,
} from './useGetCommunity.interfaces';

import { useBonoboLazyQuery } from '../../useBonoboLazyQuery';

const useGetCommunity = (options?: IUseGetCommunityOptions): IUseGetCommunity => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: bonoboProjectId } = options ?? ({} as IUseGetCommunityOptions);
  const [executeGetCommunity, { loading: isLoading }] = useBonoboLazyQuery<
    IGetCommunityQueryGraphQLResponse,
    IGetCommunityQueryVariables
  >({
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }
        const {
          community: { get: community },
        } = data;
        void onCompleted({
          community,
        });
      },
      onError,
    },
    query: query ?? GET_COMMUNITY,
    token,
  });
  const handleGetCommunity = ({ communityId }: IHandleGetCommunityParams) => {
    void executeGetCommunity({
      variables: {
        communityId,
      },
    });
  };
  return {
    handleGetCommunity,
    isLoading,
  };
};
export { useGetCommunity };