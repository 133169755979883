
const USER_BULK_ACTION_DIALOG_TITLE_MAP: { [key: string]: string } = Object.freeze({
  connect: 'Bulk add users to a community',
  remove: 'Bulk remove users from a community',
  assign: 'Bulk assign users to a community role',
});


const USER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP: { [key: string]: string } = Object.freeze({
  connect: 'Add',
  remove: 'Remove',
  assign: 'Assign',
});

export { USER_BULK_ACTION_DIALOG_TITLE_MAP, USER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP };
