/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { generateRandomId } from "utils";

import { ITranscriptAnswer } from "../../TranscriptTab.interfaces";



const getTranscriptAnswerDefaultValues = (answer?: ITranscriptAnswer): FormFieldProps => {

  const { tempId, text, isForceCorrect = false } = answer ?? {};


  return {
    tempId: tempId || generateRandomId(),
    text: text || '',
    isForceCorrect: isForceCorrect || false,
  };
};


export { getTranscriptAnswerDefaultValues };
