import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_APP_COMPONENT } from './useCreateAppComponent.graphql';
import {
  ICreateAppComponentMutationGraphQLResponse,
  ICreateAppComponentMutationVariables,
  IHandleCreateAppComponentParams,
  IUseCreateAppComponentOptions,
  IUseCreateAppComponent,
} from './useCreateAppComponent.interfaces';

const useCreateAppComponent = (options?: IUseCreateAppComponentOptions): IUseCreateAppComponent => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateAppComponentOptions);

  const [executeCreateAppComponent, { loading: isLoading }] = useEkardoMutation<
  ICreateAppComponentMutationGraphQLResponse,
  ICreateAppComponentMutationVariables
  >({
    mutation: mutation ?? CREATE_APP_COMPONENT,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            appVersionMutation: {
              appVersionComponentMutation: {
                addComponentInVersion: component
              }
            }
          }
        } = data;
        
        void onCompleted({ component });
      },
      onError,
    },
    token,
  });

  const handleCreateAppComponent = ({
    appIdentifier,
    appVersion,
    title,
  }: IHandleCreateAppComponentParams) => {
    void executeCreateAppComponent({
      variables: {
        inputType: {
          identifier: appIdentifier,
          version: appVersion,
          componentTitle: title,
        },
      },
    });
  };

  return {
    handleCreateAppComponent,
    isLoading,
  };
};

export { useCreateAppComponent };
