import React, { useState, useEffect, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, Input, Select, Spinner, SelectWithSearch, ISearchList, useControlledForm, FormFieldProps, ControlledForm, SidebarButtons, InputFieldWrapper, SidebarDialog, Breadcrumbs, FlexContainer, ButtonIconOnly, FieldsetAccordionGroup, HomeIcon, PlusIcon } from '@netfront/ui-library';
import { CreateResponseSet, SidebarContainer } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetResponseSetSuggestions, useToast, useUpsertSummaryFeedbackConfigMultiLine, useUpsertSummaryFeedbackConfigRadio, useUpsertSummaryFeedbackConfigSingleLine } from 'hooks';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';


import { getFeedbackDefaultValues } from './UpsertFeedback.helpers';
import { UpsertFeedbackProps } from './UpsertFeedback.interfaces';

import { responseTypeToUnderScoredConst, responseTypeToFriendly } from '../../../../../../../../constants';


const UpsertFeedback = ({ onSave, onCancel, feedbackConfig, onDelete, snippetId }: UpsertFeedbackProps) => {
  const { project } = useContext(CachingEntitiesContext);
  const { handleToastError, handleToastSuccess } = useToast();

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [projectId, setProjectId] = useState<string>();

  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);

  const [type, setType] = useState<string>('');
  const [responseTitle, setResponseTitle] = useState<string>();
  const [searchSuggestions, setSearchSuggestions] = useState<ISearchList[]>([]);
  const [isCreateResponseSetDialogOpen, setIsCreateResponseSetDialogOpen] = useState<boolean>(false);

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        label: yup.string().label('Title').required(),
        type: yup.string().label('Type').required(),
        responseSetId: yup.number().when('type', {
          is: (responseType: string) => responseType && responseType === 'RADIO',
          then: yup.number().label('Response').required(),
          otherwise: yup.number().nullable(),
        }),
      }),
    ),
  });


  const { handleGetResponseSetSuggestions, isLoading: isGetResponseSetSuggestionsLoading = false } = useGetResponseSetSuggestions({
    onCompleted: ({ searchResponseSet }) => {
      const suggestions = searchResponseSet.map(({ id, title }) => ({
          id,
          label: String(title),
        }));
      setSearchSuggestions(suggestions);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  // NOTE: Commented out for now as we dont have a checkbox summary feedback api yet.
  // const { handleUpsertSummaryFeedbackConfigCheckbox, isLoading: isSummaryCheckboxLoading = false } = useUpsertSummaryFeedbackConfigCheckbox(
  //   {
  //     onCompleted: () => {
  //       onSave();
  //       handleToastSuccess({
  //         message: `Summary feedback successfully created`,
  //       });
  //     },
  //     onError: (error) => {
  //       handleToastError({
  //         error,
  //         shouldUseFriendlyErrorMessage: true,
  //       });
  //     },
  //   },
  // );

  const { handleUpsertSummaryFeedbackConfigRadio, isLoading: isSummaryRadioLoading = false } = useUpsertSummaryFeedbackConfigRadio({
    onCompleted: () => {
      reset();
      onSave();
      handleToastSuccess({
        message: `Summary feedback successfully created`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpsertSummaryFeedbackConfigMultiLine, isLoading: isSummaryMultiLineLoading = false } =
    useUpsertSummaryFeedbackConfigMultiLine({
      onCompleted: () => {
        reset();
        onSave();
        handleToastSuccess({
          message: `Summary feedback successfully created`,
        });
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const { handleUpsertSummaryFeedbackConfigSingleLine, isLoading: isSummarySingleLineLoading = false } =
    useUpsertSummaryFeedbackConfigSingleLine({
      onCompleted: () => {
        reset();
        onSave();
        handleToastSuccess({
          message: `Summary feedback successfully created`,
        });
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });


  const saveMap: { [key: string]: (params: { contentSnippetSummaryId: number; label: string; questionResponseSetId: number }) => void; } = {
    // NOTE: Commented out for now as we dont have a checkbox summary feedback api yet.
    // CHECKBOX: handleUpsertSummaryFeedbackConfigCheckbox,
    RADIO: handleUpsertSummaryFeedbackConfigRadio,
    SINGLE_TEXT: handleUpsertSummaryFeedbackConfigSingleLine,
    MULTI_TEXT: handleUpsertSummaryFeedbackConfigMultiLine,
  };

  const handleSaveFeedback = (item: FormFieldProps) => {
    const { label, responseSetId, type: feedbackType } = item;
    const typedSave = saveMap[String(feedbackType)];

    typedSave({
      contentSnippetSummaryId: snippetId,
      questionResponseSetId: Number(responseSetId),
      label: String(label),
    });
  };

  const handleSaveResponse = ({ title, id }: {id: number; title: string }) => {
    const updatedSuggestions = [
      ...searchSuggestions,
      {
        label: title,
        id,
      },
    ];
    setValue('responseSetId', id);
    setResponseTitle(title);
    setSearchSuggestions(updatedSuggestions);
    setIsCreateResponseSetDialogOpen(false);
  };



  const handleSearchItemClick = (selectedId: string | number) => {
    setValue('responseSetId', Number(selectedId));
    setSearchSuggestions([]);
  };

  const handleCloseResponseDialog = () => setIsCreateResponseSetDialogOpen(false);
  const handleOpenResponseDialog = () => setIsCreateResponseSetDialogOpen(true);

  useEffect(() => {

    setDefaultValues(getFeedbackDefaultValues(feedbackConfig));
    const { responseSet } = feedbackConfig ?? {};

    const { title } = responseSet ?? {};
    setResponseTitle(title);
  }, [feedbackConfig]);

  useEffect(() => {
    if (!project) return;

    setProjectId(project.id);
  }, [project]);

  useEffect(() => {
    if (!projectId) return;
    if (!['RADIO', 'CHECKBOX'].includes(String(type))) return;

    void handleGetResponseSetSuggestions({
      projectId,
      title: responseTitle,
      type: String(type),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const isLoading = isSummarySingleLineLoading || isSummaryMultiLineLoading || isSummaryRadioLoading || isGetResponseSetSuggestionsLoading;

  if (!projectId) return <></>;

  return (
    <>
      <SidebarContainer hasPaddingEnds={false}>
        {isLoading && <Spinner isLoading={isLoading} />}
        <Breadcrumbs
          itemDivider="chevron"
          items={[
            {
              key: 'summary-snippet',
              onClick: onCancel,
              content: 'Summary',
              icon: HomeIcon,
            },
            {
              key: 'upsert-feedback',
              content: <span className="c-breadcrumb-item__link">{feedbackConfig ? 'Add' : 'Edit'} feedback</span> ,
            }
          ]}
          isContained
        />
      </SidebarContainer>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSaveFeedback(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        <SidebarContainer hasPaddingEnds={false}>
          <Spacing>
            <Controller
              control={control as Control<FormFieldProps>}
              name="label"
              render={({ field, fieldState }) => (
                <Input
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_feedback_title"
                  labelText="Title"
                  type="text"
                  isLabelSideBySide
                  isRequired
                  {...field}
                />
              )}
            />

          </Spacing>
          <Spacing size="2x-large">
            <Controller
              control={control as Control<FormFieldProps>}
              name="type"
              render={({ field, fieldState }) => (
                <Select
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_question_type"
                  labelText="Type"
                  options={[
                    {
                      id: responseTypeToUnderScoredConst.SingleText,
                      value: responseTypeToUnderScoredConst.SingleText,
                      name: responseTypeToFriendly.SingleText,
                    },
                    {
                      id: responseTypeToUnderScoredConst.MultiText,
                      value: responseTypeToUnderScoredConst.MultiText,
                      name: responseTypeToFriendly.MultiText,
                    },
                    {
                      id: responseTypeToUnderScoredConst.Radio,
                      value: responseTypeToUnderScoredConst.Radio,
                      name: responseTypeToFriendly.Radio,
                    },
                    // NOTE: Commented out for now as we dont have a checkbox summary feedback api yet.
                    // {
                    //   id: 'CHECKBOX',
                    //   value: 'CHECKBOX',
                    //   name: 'Multi select',
                    // },
                  ]}
                  isLabelSideBySide
                  isRequired
                  {...field}
                  onChange={(event) => {
                    const { target: { value }} = event;
                    field.onChange(event);
                    setType(value);
                    setValue('responseSetId', null);
                  }}
                />
              )}
            />

          </Spacing>
        </SidebarContainer>
        {['RADIO', 'CHECKBOX'].includes(String(type)) && (
          <FieldsetAccordionGroup
            data={[
              {
                id: 'id_responses_accordion',
                label: 'Responses',
                isOpenOnLoad: true,
                children: (
                  <>
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="responseSetId"
                      render={({ fieldState }) => (
                        <SelectWithSearch
                          buttonText="Search existing response sets"
                          countText="Response sets"
                          defaultValue={responseTitle}
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_response_set_select"
                          isDisplaySearchContent={isSearchContentVisible}
                          labelText="Search"
                          searchList={searchSuggestions.map(({id, label: searchSuggestionLabel }) => ({
                            id,
                            label: searchSuggestionLabel,
                          }))}
                          isAvatarVisible
                          isLabelSideBySide
                          isRequired
                          onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
                          onSearchItemClick={handleSearchItemClick}
                        />
                      )}
                    />
                    <InputFieldWrapper
                      id="id_create_response_set"
                      label="Create"
                      labelType="span"
                      type="custom"
                      isLabelSideBySide
                    >
                      <FlexContainer justifyContent="flex-end" isFullWidth>
                        <ButtonIconOnly icon={PlusIcon} text="Add response set" onClick={handleOpenResponseDialog} />
                      </FlexContainer>
                    </InputFieldWrapper>
                  </>
                )
              }
            ]
          }/>
        )}
        <SidebarButtons
          onCancel={onCancel}
          onDelete={feedbackConfig ? () => onDelete(Number(feedbackConfig.contentSnippetSummaryId)) : undefined}
          onSaveButtonType="submit"
        />
      </ControlledForm>
      <SidebarDialog
        isOpen={isCreateResponseSetDialogOpen}
        onClose={handleCloseResponseDialog}
      >
        <CreateResponseSet
          additionalBreadcrumbs={[
            {
              key: 'summary-snippet',
              onClick: () => {
                onCancel();
                handleCloseResponseDialog();
              },
              content: 'Summary',
              icon: HomeIcon,
            },
            {
              key: 'upsert_feedback',
              onClick: handleCloseResponseDialog,
              content: 'Feedback',
            },
            {
              key: 'response_set',
              content: <span className="c-breadcrumb-item__link">New response</span>,
            }
          ]}
          projectId={projectId}
          responseType={String(type)}
          onCancel={handleCloseResponseDialog}
          onSave={handleSaveResponse}
        />
      </SidebarDialog>

    </>
  );
};

export { UpsertFeedback };
