import { ApolloQueryResult } from '@apollo/client';
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { SEARCH_PAGINATED_APPS_QUERY } from './useSearchPaginatedApps.graphql';
import {
  ISearchPaginatedAppsQueryGraphQLResponse,
  ISearchPaginatedAppsQueryVariables,
  IHandleFetchMorePaginatedAppsParams,
  IHandleSearchPaginatedAppsParams,
  IUseSearchPaginatedApps,
  IUseSearchPaginatedAppsOptions,
} from './useSearchPaginatedApps.interfaces';

const useSearchPaginatedApps = (options?: IUseSearchPaginatedAppsOptions): IUseSearchPaginatedApps => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseSearchPaginatedAppsOptions);

  const [executeSearchPaginatedApps, { fetchMore, loading: isLoading }] = useEkardoLazyQuery<
    ISearchPaginatedAppsQueryGraphQLResponse,
    ISearchPaginatedAppsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: { searchApps: appConnection },
        } = data;

        void onCompleted({
          appConnection,
        });
      },
      onError,
    },
    query: query ?? SEARCH_PAGINATED_APPS_QUERY,
    token,
  });

  const handleFetchMorePaginatedApps = ({
    after,
    first,
    projectId,
    sorting,
    title,
    status,
  }: IHandleFetchMorePaginatedAppsParams): Promise<ApolloQueryResult<ISearchPaginatedAppsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          app: { searchApps: newlyFetchedAppConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedAppConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          app: { searchApps: previouslyFetchedAppConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedAppConnection;

        return {
          ...previousQueryResult,
          app: {
            ...previouslyFetchedAppConnection,
            searchApps: {
              ...newlyFetchedAppConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        projectId,
        sorting,
        title,
        status,
      },
    });
  };

  const handleSearchPaginatedApps = ({
    after,
    first,
    projectId,
    sorting,
    title,
    status,
  }: IHandleSearchPaginatedAppsParams) => {
    void executeSearchPaginatedApps({
      variables: {
        after,
        first,
        projectId,
        sorting,
        title,
        status,
      },
    });
  };

  return {
    handleFetchMorePaginatedApps,
    handleSearchPaginatedApps,
    isLoading,
  };
};

export { useSearchPaginatedApps };
