const createSlideTitle = (fileName: string): string => {
  // Remove the file extension by replacing everything after the last dot
  const nameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
  
  // Replace hyphens and underscores with spaces
  const userFriendlyTitle = nameWithoutExtension.replace(/[-_]/g, " ");
  
  return userFriendlyTitle;
};

const createSlideUrl = (fileName: string, id: string): string => {
  const formattedTitle = createSlideTitle(fileName);

  return `${formattedTitle.replace(/[. ]/g, "-")}-${id}`;
};

export { createSlideTitle, createSlideUrl };
