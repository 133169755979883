import { FormEvent, useState } from 'react';

import { ButtonIconOnly, FlexContainer, XlsxIcon, InputFieldWrapper, SidebarButtons, Spacing, Spinner, Table, CloseIcon } from '@netfront/ui-library';
import { SidebarContainer, Uploader } from 'components';
import ExcelJS from 'exceljs';
import { useToast, useBulkUpsertLanguageFilter } from 'hooks';
import { DBLanguageFilterType } from 'interfaces';


import { ILanguageFilterItem, LanguageFilterSidebarBulkUploadViewProps } from './LanguageFilterSidebarBulkUploadView.interfaces';

const LanguageFilterSidebarBulkUploadView = ({ onClose, onUpdate, projectId }: LanguageFilterSidebarBulkUploadViewProps) => {
  const { handleToastError, handleToastSuccess, handleToastCustomError } = useToast();

  const [newLanguageFilterItems, setNewLanguageFilterItems] = useState<ILanguageFilterItem[]>([]);


  const { handleBulkUpsertLanguageFilter, isLoading: isBulkUpsertLanguageFiltersLoading = false } = useBulkUpsertLanguageFilter({
    onCompleted: () => {
      handleToastSuccess({
        message: `Language filters successfully created`,
      });
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleCreateBulkInvitations = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (newLanguageFilterItems.find((r) => r.content === '')) {
      handleToastCustomError({ message: 'All the excel fields must be mapped-out' });
      return;
    }

    const itemsWithoutIds = newLanguageFilterItems.map(({content, type, isEmbargoingContent, isNegative }) => {
      return {
        content,
        type,
        isEmbargoingContent,
        isNegative,
        projectId: String(projectId),
      };
    });

    handleBulkUpsertLanguageFilter({
      items: itemsWithoutIds,
    });
  };

  const handleReadExcel = (input: File) => {
    const reader = new FileReader();
  
    reader.onload = async (event) => {
      const { target } = event;
  
      if (!target?.result) return;
  
      const buffer = target.result as ArrayBuffer;
  
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);
  
      const [ sheet ] = workbook.worksheets;
  
      const rows: ILanguageFilterItem[] = [];
      sheet.eachRow((row, rowIndex) => {
        if (rowIndex === 1) return; // Skip header row
        const values = (row.values as (string | boolean | null | undefined)[]);
        const [content, type, isEmbargoingContent, isNegative ] = values.slice(1);
        rows.push({
          content: String(content),
          type: type as DBLanguageFilterType,
          isEmbargoingContent: isEmbargoingContent as boolean,
          isNegative: isNegative as boolean,
          id: (Math.random() + 1).toString(36).substring(7)
        });
      });
    
      setNewLanguageFilterItems(rows);
    };
  
    reader.readAsArrayBuffer(input);
  };

  const handleClearItems = () => {
    setNewLanguageFilterItems([]);
  };

  const handleFileDrop = (uploadedFile: File | undefined) => {
    if (uploadedFile) handleReadExcel(uploadedFile);
  };

  const isLoading = isBulkUpsertLanguageFiltersLoading;

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />

      <form
        onSubmit={handleCreateBulkInvitations}
      >


        <Spacing>
          {Boolean(newLanguageFilterItems.length) && (

            <InputFieldWrapper id="" label="Uploaded items" labelType={'span'} isLabelSideBySide>
              <FlexContainer justifyContent="flex-end" isFullWidth>
                <ButtonIconOnly
                  icon={CloseIcon}
                  text="close sidebar"
                  onClick={handleClearItems}
                />
              </FlexContainer>
            </InputFieldWrapper>
          )}
          {Boolean(newLanguageFilterItems.length) && (
            <>
              <Table
                columns={[
                  {
                    accessor: 'content',
                    Cell: ({ value }: { value: string }) => <div>{value}</div>,
                    Header: () => <div>Content</div>,
                  },
                  {
                    accessor: 'type',
                    Cell: ({ value }: { value: string }) => <div>{value}</div>,
                    Header: <div>Type</div>,
                  },
                  {
                    accessor: 'isEmbargoingContent',
                    Cell: ({ value }: { value: boolean }) => <div>{value ? 'True': 'False'}</div>,
                    Header: <div>Embargoing content</div>,
                  },
                  {
                    accessor: 'isNegative',
                    Cell: ({ value }: { value: boolean }) => <div>{value ? 'True': 'False'}</div>,
                    Header: <div>Show additional content</div>,
                  },
                ]}
                data={newLanguageFilterItems}
              />
            </>
          )}
        </Spacing>

        <Spacing size="2x-large">

          <InputFieldWrapper id="" label="Download template" labelType={'span'} tooltipText="Download our predefined excel template to add your language filter items to" isLabelSideBySide>
            <FlexContainer justifyContent="flex-end" isFullWidth>
              <a
                href="https://ekardo.s3.ap-southeast-2.amazonaws.com/file-templates/language_filter_template.xlsx"
                id="download-template"
                download
              >
                <XlsxIcon className="c-icon c-icon--large"/>
              </a>
            </FlexContainer>
          </InputFieldWrapper>
        </Spacing>

        <Uploader
          assetType={'document'}
          labelText={'Upload region'}
          tooltipText={'Upload multiple language filters at once by using an excel document'}
          isLabelSideBySide
          onDelete={handleClearItems}
          onDrop={handleFileDrop}
        />

        <SidebarButtons
          buttonSize="xs"
          isSaveButtonDisabled={Boolean(!newLanguageFilterItems.length)}
          submitButtonText="Save"
          onCancel={onClose}
          onSaveButtonType="submit"
        />

      </form>
    </SidebarContainer>
  );
};

export { LanguageFilterSidebarBulkUploadView };
