import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CSS_STYLE_OPTIONS } from './useGetCssStyleOptions.graphql';
import {
  IGetCssStyleOptionsQueryGraphQLResponse,
  IGetCssStyleOptionsQueryVariables,
  IHandleGetCssStyleOptionsParams,
  IUseGetCssStyleOptions,
  IUseGetCssStyleOptionsOptions,
} from './useGetCssStyleOptions.interfaces';

const useGetCssStyleOptions = (options?: IUseGetCssStyleOptionsOptions): IUseGetCssStyleOptions => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetCssStyleOptionsOptions);

  const [executeGetCssStyleOptions, { loading: isLoading }] = useEkardoLazyQuery<
  IGetCssStyleOptionsQueryGraphQLResponse,
  IGetCssStyleOptionsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          cssStyle: { getPerProject: cssStyleOptions },
        } = data;

        void onCompleted({
          options: cssStyleOptions,
        });
      },
      onError,
    },
    query: query ?? GET_CSS_STYLE_OPTIONS,
    token,
  });

  const handleGetCssStyleOptions = ({ projectId }: IHandleGetCssStyleOptionsParams)  => {
    void executeGetCssStyleOptions({
      variables: {
        projectId,
      }
    });
  };

  return {
    handleGetCssStyleOptions,
    isLoading,
  };
};

export { useGetCssStyleOptions };
