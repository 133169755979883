import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { ATTACH_CSS_STYLE_TO_CONTAINER } from './useAttachContainerCssStyle.graphql';
import {
  IAttachContainerCssStyleMutationGraphQLResponse,
  IAttachContainerCssStyleMutationVariables,
  IHandleAttachContainerCssStyleParams,
  IUseAttachContainerCssStyleOptions,
  IUseAttachContainerCssStyle,
} from './useAttachContainerCssStyle.interfaces';

const useAttachContainerCssStyle = (options?: IUseAttachContainerCssStyleOptions): IUseAttachContainerCssStyle => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseAttachContainerCssStyleOptions);

  const [executeAttachContainerCssStyle, { loading: isLoading }] = useEkardoMutation<IAttachContainerCssStyleMutationGraphQLResponse, IAttachContainerCssStyleMutationVariables>({
    mutation: mutation ?? ATTACH_CSS_STYLE_TO_CONTAINER,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          cssStyle: { attachStyleToContainer: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleAttachContainerCssStyle = async ({
    containerId,
    styleId,
  }: IHandleAttachContainerCssStyleParams) => {
    await executeAttachContainerCssStyle({
      variables: {
        containerId,
        styleId,
      }
    });
  };

  return {
    handleAttachContainerCssStyle,
    isLoading,
  };
};

export { useAttachContainerCssStyle };
