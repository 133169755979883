
import { GET_TOPIC } from './useGetTopic.graphql';
import {
  IGetTopicQueryGraphQLResponse,
  IGetTopicQueryVariables,
  IHandleGetTopicParams,
  IUseGetTopic,
  IUseGetTopicOptions,
} from './useGetTopic.interfaces';

import { useBonoboLazyQuery } from '../../useBonoboLazyQuery';

const useGetTopic = (options?: IUseGetTopicOptions): IUseGetTopic => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: bonoboProjectId } = options ?? ({} as IUseGetTopicOptions);

  const [executeGetTopic, { loading: isLoading }] = useBonoboLazyQuery<
    IGetTopicQueryGraphQLResponse,
    IGetTopicQueryVariables
  >({
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          topic: { getTopic: topic },
        } = data;

        void onCompleted({
          topic,
        });
      },
      onError,
    },
    query: query ?? GET_TOPIC,
    token,
  });

  const handleGetTopic = ({ topicId }: IHandleGetTopicParams) => {
    void executeGetTopic({
      variables: {
        topicId,
      },
    });
  };

  return {
    handleGetTopic,
    isLoading,
  };
};

export { useGetTopic };
