import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledForm, Dialog, FormFieldProps, GeneralTabIcon, SideBarTabSet, Spinner, StyleTabIcon, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { CommunitySidebarGeneralTab, CommunitySidebarThemeTab } from 'components';
import { useDeleteCommunity, useGetCommunity, useToast, useUpsertCommunity } from 'hooks';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';


import { getCommunityDefaultValues } from './CommunitySidebarView.helpers';
import { CommunitySidebarViewProps } from './CommunitySidebarView.interfaces';


const CommunitySidebarView = ({
  handleSideBarClose,
  isSideBarOpen,
  onUpdate,
  projectId,
  selectedCommunityId,
}: CommunitySidebarViewProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const uploadedImageRef = useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const uploadedBannerRef = useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalBannerRef = useRef<{value: boolean }>({ value: false });

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);


  const { control, handleSubmit, reset} = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required().label('Title'),
        description: yup.string().required().label('Description'),
        status: yup.string().required().label('Description'),
        privacy: yup.string().required().label('Description'),
      }),
    ),
  });

  const { handleGetCommunity, isLoading: isGetCommunityLoading = false } = useGetCommunity({
    onCompleted: ({ community }) => {
      setDefaultValues(getCommunityDefaultValues({ community }))
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId,
  });


  const { handleDeleteCommunity, isLoading: isDeleteAssetLoading = false } = useDeleteCommunity({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      uploadedImageRef.current.value = undefined;
      hasDeletedOriginalImageRef.current.value = false;
      uploadedBannerRef.current.value = undefined;
      hasDeletedOriginalBannerRef.current.value = false;
      reset();

      handleToastSuccess({ message: `Community successfully deleted`});

      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId,
  });


  const { handleUpsertCommunity, isLoading: isUpsertCommunityLoading = false} = useUpsertCommunity({
    onUpdate: () => {
      reset();
      uploadedImageRef.current.value = undefined;
      hasDeletedOriginalImageRef.current.value = false;
      uploadedBannerRef.current.value = undefined;
      hasDeletedOriginalBannerRef.current.value = false;
      onUpdate();
    },
    projectId, 
  })

  const handleUploadImage = (uploadedFile?: File) => {
    uploadedImageRef.current.value = uploadedFile;
  };

  const handleRemoveUploadedImage = () => {
    hasDeletedOriginalImageRef.current.value = true;
    uploadedImageRef.current.value = undefined;
  }

  const handleUploadBanner = (uploadedFile?: File) => {
    uploadedBannerRef.current.value = uploadedFile;
  };

  const handleRemoveUploadedBanner = () => {
    hasDeletedOriginalBannerRef.current.value = true;
    uploadedBannerRef.current.value = undefined;
  }

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const handleSaveCommunity = (item: FormFieldProps) => {
    const { description, title, status, privacy } = item;

    handleUpsertCommunity({
      communityId: selectedCommunityId,
      description,
      privacy,
      status,
      title,
      uploadedBanner: uploadedBannerRef.current.value,
      uploadedImage: uploadedImageRef.current.value,
      deletedBannerId: hasDeletedOriginalBannerRef.current.value && defaultValues.bannerId ? defaultValues.bannerId: undefined,
      deletedImageId: hasDeletedOriginalImageRef.current.value && defaultValues.imageId ? defaultValues.imageId: undefined,
    });
  };

  useEffect(() => {
    if (selectedCommunityId) {
      handleGetCommunity({
        communityId: selectedCommunityId,
      });
    }
    setDefaultValues(getCommunityDefaultValues({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId, isSideBarOpen]);

  const isLoading =  isDeleteAssetLoading || isUpsertCommunityLoading || isGetCommunityLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete ${String(defaultValues.title ?? '')}?`}
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedCommunityId) {
            return;
          }

          handleDeleteCommunity({
            communityId: selectedCommunityId,
          });
        }}
      />

      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSaveCommunity(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >

        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={handleSideBarClose}
          hasViewPadding={false}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => (
                <CommunitySidebarGeneralTab
                  control={control as Control<FormFieldProps>}
                  selectedCommunityId={selectedCommunityId}
                />
              ),
            },
            {
              icon: StyleTabIcon,
              id: 'id_style_tab',
              label: 'Theme',
              view: () => (
                <CommunitySidebarThemeTab 
                  control={control as Control<FormFieldProps>}
                  onRemoveBanner={handleRemoveUploadedBanner}
                  onRemoveImage={handleRemoveUploadedImage}
                  onUploadBanner={handleUploadBanner}
                  onUploadImage={handleUploadImage}
                />
              ),
            },
          ]}
          onDelete={selectedCommunityId ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"

        />
      </ControlledForm>
    </>
  );
};

export { CommunitySidebarView };
