import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_APP_DETAILS_QUERY } from './useGetAppDetails.graphql';
import {
  IGetAppDetailsQueryGraphQLResponse,
  IGetAppDetailsQueryVariables,
  IHandleGetAppDetailsParams,
  IUseGetAppDetails,
  IUseGetAppDetailsOptions,
} from './useGetAppDetails.interfaces';

const useGetAppDetails = (options?: IUseGetAppDetailsOptions): IUseGetAppDetails => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetAppDetailsOptions);

  const [executeGetAppDetails, { loading: isLoading }] = useEkardoLazyQuery<IGetAppDetailsQueryGraphQLResponse, IGetAppDetailsQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            getAppDetails: app
          }
        } = data;

        void onCompleted({ app });
      },
      onError,
    },
    query: query ?? GET_APP_DETAILS_QUERY,
    token,
  });

  const handleGetAppDetails = ({ appId }: IHandleGetAppDetailsParams) => {
    void executeGetAppDetails({
      variables: {
        appId,
      },
    });
  };

  return {
    handleGetAppDetails,
    isLoading,
  };
};

export { useGetAppDetails };
