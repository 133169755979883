
import { ReactNode } from "react";

import { NavigationItemProps } from "@netfront/ui-library";
import { IDashboardItemsList } from "context";
import Link from "next/link";


const findObjectById = (array: IDashboardItemsList[], id: string): IDashboardItemsList[] | null =>  {
  for (const item of array) {
    if (item.id === id) {
      return item.subItems ?? [];
    }
    if (item.subItems && item.subItems.length > 0) {
      const subItem = findObjectById(item.subItems, id);
      if (subItem) {
        return subItem;
      }
    }
  }
  return null;
};

const filterVisibleItems = (items: IDashboardItemsList[]): IDashboardItemsList[] => {
  return items
    .filter(item => item.isVisible ?? true)
    .map(item => ({
      ...item,
      subItems: filterVisibleItems(item.subItems ?? [])
    }));
};

const formatDashboardItems = (items: IDashboardItemsList[]): NavigationItemProps[] => {
  return items.map((item) => {
    const { icon: itemIcon, title: itemTitle, id, href, subItems, isInternal = true } = item;
    return {
      icon: itemIcon,
      label: itemTitle,
      id,
      href: !isInternal ? href: undefined,
      wrapperComponent: isInternal ? ({ children }: { children: ReactNode }) => <Link href={href}>{children}</Link>: undefined,
      subItems: subItems && subItems.length > 0 ? formatDashboardItems(subItems) : undefined
    };
  }) as NavigationItemProps[];
};

export { findObjectById, filterVisibleItems, formatDashboardItems };
