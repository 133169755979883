import React, { useEffect, useState } from 'react';

import { IMapCoordinates, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, Input, ToggleSwitch, InputFieldWrapper, GoogleMap, Spinner } from '@netfront/ui-library';
import { ContentBuilderTabWrapper, defaultLatitude, defaultLongitude, defaultZoom } from 'components';
import { useToast } from 'hooks';
import { Controller } from 'react-hook-form';

import { UpsertMapSnippetGeneralTabProps } from './UpsertMapSnippetGeneralTab.interfaces';


const UpsertMapSnippetGeneralTab = ({ control, defaultValues, setValue, getValues }: UpsertMapSnippetGeneralTabProps) => {

  const { handleToastCustomError } = useToast();
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const key = process.env.REACT_APP_GOOGLE_LOCATION_API_KEY;

  const [location, setLocation] = useState<IMapCoordinates>({
    latitude: defaultLatitude,
    longitude: defaultLongitude,
  });
  const [zoom, setZoom] = useState<number>(defaultZoom);
  const [isDraggable, setIsDraggable] = useState<boolean>(true);
  const [isGrayscale, setIsGreyscale] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAddressOnBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    event.persist();
    const { address = '' } = getValues();
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(String(address))}&key=${String(key)}`;
  
    setIsLoading(true);
    try {
      const response = await fetch(url);
      const data = await response.json();
  
      const { results, error_message: errorMessage, status } = data;
  
      if (errorMessage) {
        handleToastCustomError({
          message: `${String(status)} - ${String(errorMessage)}`,
        });
        setIsLoading(false);
        return;
      }
  
      const [firstResult] = results ?? [];
  
      if (firstResult) {
        const {
          formatted_address: returnedAddress,
          geometry: { location: { lng, lat } },
        } = firstResult;
        setLocation({
          latitude: lat,
          longitude: lng,
        });
  
        setValue('latitude', lat);
        setValue('longitude', lng);
        setValue('address', returnedAddress);
      }
    } catch (error) {
      handleToastCustomError({
        message: `Error fetching geocoding data: ${String(error)}`,
      });
      console.error("Error fetching geocoding data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!defaultValues) return;

    const { 
      longitude, 
      latitude,
      isGrayscale: initialIsGreyscale,
      isDraggable: initialIsDraggable,
      zoom: initialZoom,
    } = defaultValues;

    setLocation({
      latitude,
      longitude,
    });
    setZoom(Number(initialZoom));
    setIsDraggable(initialIsDraggable as boolean)
    setIsGreyscale(initialIsGreyscale as boolean);

  }, [defaultValues]);

  return (
    <ContentBuilderTabWrapper>
      <Spinner isLoading={isLoading} />
      <Spacing>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_quote_snippet_title"
              labelText="Title"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="address"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_map_address"
              labelText="Address"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
              onBlur={(event) => {
                void handleAddressOnBlur(event);
              }}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <InputFieldWrapper
          id="id_map_preview"
          label="Preview"
          labelFontWeight="light"
          labelType="span"
          tooltipPosition="start"
          type="custom"
          isLabelSideBySide
        >
          <div style={{height: '200px', width: '100%'}}>
            <GoogleMap 
              isDraggable={isDraggable}
              isGrayscale={isGrayscale}
              isVisible={true}
              location={location}
              markers={[
                {
                  lat: location.latitude,
                  lng: location.longitude,
                },
              ]}
              zoomScale={zoom}
            />
          </div>
        </InputFieldWrapper>
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="isDraggable"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_draggable"
              isChecked={field.value}
              labelText="Enable drag"
              isInline
              isLabelSideBySide
              {...field}
              onChange={(event) => {
                const { target: { checked: isChecked } } = event;
                field.onChange(event);
                setIsDraggable(isChecked);
              }}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isGrayscale"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_grayscale"
              isChecked={field.value}
              labelText="Greyscale"
              isInline
              isLabelSideBySide
              {...field}
              onChange={(event) => {
                const { target: { checked: isChecked } } = event;
                field.onChange(event);
                setIsGreyscale(isChecked);
              }}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="zoom"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_zoom_level"
              labelText="Zoom level"
              type="number"
              isLabelSideBySide
              isRequired
              {...field}
              onChange={(event) => {
                const { target: { value } } = event;
                field.onChange(event);
                setZoom(Number(value));
              }}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isSensitive"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_sensitive_content"
              isChecked={field.value}
              labelText="Sensitive"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isVisible"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_visible"
              isChecked={field.value}
              labelText="Is visible"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
    </ContentBuilderTabWrapper>
  );
};

export { UpsertMapSnippetGeneralTab };
