import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { ATTACH_CSS_STYLE_TO_SECTION } from './useAttachSectionCssStyle.graphql';
import {
  IAttachSectionCssStyleMutationGraphQLResponse,
  IAttachSectionCssStyleMutationVariables,
  IHandleAttachSectionCssStyleParams,
  IUseAttachSectionCssStyleOptions,
  IUseAttachSectionCssStyle,
} from './useAttachSectionCssStyle.interfaces';

const useAttachSectionCssStyle = (options?: IUseAttachSectionCssStyleOptions): IUseAttachSectionCssStyle => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseAttachSectionCssStyleOptions);

  const [executeAttachSectionCssStyle, { loading: isLoading }] = useEkardoMutation<IAttachSectionCssStyleMutationGraphQLResponse, IAttachSectionCssStyleMutationVariables>({
    mutation: mutation ?? ATTACH_CSS_STYLE_TO_SECTION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          cssStyle: { attachStyleToContentSection: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleAttachSectionCssStyle = async ({
    contentSectionId,
    styleId,
  }: IHandleAttachSectionCssStyleParams) => {
    await executeAttachSectionCssStyle({
      variables: {
        contentSectionId,
        styleId,
      }
    });
  };

  return {
    handleAttachSectionCssStyle,
    isLoading,
  };
};

export { useAttachSectionCssStyle };
