import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetLoginsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { LOGINS_COLUMNS } from './LoginsPage.constants';
import { getLoginsTableData } from './LoginsPage.helpers';
import { LoginsCsvRowData, ILoginsItem, ILoginsTableData } from './LoginsPage.interfaces';



const LoginsPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [loginsItems, setLoginsItems] = useState<ILoginsItem[]>();
  const [allLoginsItems, setAllLoginsItems] = useState<ILoginsItem[]>([]);
  const [loginsTableData, setLoginsTableData] = useState<ILoginsTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>();
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const {
    handleGetLoginsReport,
    isLoading: isGetLoginsLoading = false,
  } = useGetLoginsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<LoginsCsvRowData>(String(res.data), {
            header: true,
          skipEmptyLines: true,
          });

          const formattedItems = data.map((row: LoginsCsvRowData) => ({
            id: Number(row['User id']),
            firstName: String(row['First name']),
            lastName: String(row['Last name']),
            email: String(row['Email']),
            groupName: String(row['Group name']),
            loginDate: String(row['Login date']),
            accessType: String(row['Access type'])
          }));

          
          setTotalCount(data.length);
          setAllLoginsItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setLoginsItems(paginatedItems);
          setLastItemIndex(pageSize);
          
        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    if (!(dateRange && projectId)) {
      return;
    }

    if (!dateRange[1] || !dateRange[0]) return;

    void handleGetLoginsReport({
      dateFrom: dateRange[0],
      dateTo: dateRange[1],
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fileType: 'CSV',
      projectId,
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setLoginsItems(allLoginsItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setLoginsItems(allLoginsItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!loginsItems) {
      return;
    }

    setLoginsTableData(
      getLoginsTableData({
        items: loginsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginsItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetLoginsLoading;

  return (
    <ReportTable<ILoginsTableData>
      activePath={['reporting', 'reporting-users', 'export-logins']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Logins',
      }]}
      columns={LOGINS_COLUMNS}
      csvUrl={csvUrl}
      data={loginsTableData}
      description={`Login reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports logins based on the selected filters'}
      isGenerateButtonDisabled={!dateRange || !dateRange[0] || !dateRange[1]}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { LoginsPage };
