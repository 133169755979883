
import { UPDATE_COMMUNITY_MUTATION } from './useUpdateCommunity.graphql';
import {
  IUpdateCommunityMutationGraphQLResponse,
  IUpdateCommunityMutationVariables,
  IHandleUpdateCommunityParams,
  IUseUpdateCommunityOptions,
  IUseUpdateCommunity,
} from './useUpdateCommunity.interfaces';

import { useBonoboMutation } from '../../useBonoboMutation';

const useUpdateCommunity = (options?: IUseUpdateCommunityOptions): IUseUpdateCommunity => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseUpdateCommunityOptions);

  const [executeUpdateCommunity, { loading: isLoading }] = useBonoboMutation<
    IUpdateCommunityMutationGraphQLResponse,
    IUpdateCommunityMutationVariables
  >({
    mutation: mutation ?? UPDATE_COMMUNITY_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          community: { update: communityConnection },
        } = data;

        onCompleted({ communityConnection });
      },
      onError,
    },
    token,
  });

  const handleUpdateCommunity = async ({
    inputType: { categoryId, description, status, title, privacy, id },
  }: IHandleUpdateCommunityParams) => {
    await executeUpdateCommunity({
      variables: {
        inputType: {
          categoryId,
          description,
          privacy,
          status,
          title,
          id,
        },
      },
    });
  };

  return {
    handleUpdateCommunity,
    isLoading,
  };
};

export { useUpdateCommunity };
