import { gql } from '@apollo/client';

const UPDATE_APP_MUTATION = gql`
  mutation updateApp($inputType: UpdateAppDetailsInputType!) {
    app {
      updateAppDetails(
        inputType: $inputType
      ) {
        id
        description
        identifier
        title
        # logo {
        #  presignedUrl
        # }
        logoId
        averageRateValue
      }
    }
  }
`;

export { UPDATE_APP_MUTATION };
