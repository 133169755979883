import React, { useEffect, useState } from 'react';


import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, FormFieldProps, ControlledForm, SidebarButtons, useControlledForm,  Breadcrumbs, Textarea, ToggleSwitch } from '@netfront/ui-library';
import { ITranscriptAnswer, SidebarContainer } from 'components';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { getTranscriptAnswerDefaultValues } from './UpsertTranscriptQuizAnswer.helpers';
import { UpsertTranscriptQuizAnswerProps } from './UpsertTranscriptQuizAnswer.interfaces';



const UpsertTranscriptQuizAnswer = ({ 
  breadcrumbItems = [],
  isMultiResponse = false,
  onUpdate,
  onCancel,
  onDelete,
  selectedAnswer,
}: UpsertTranscriptQuizAnswerProps) => {
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { reset, control, handleSubmit } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        text: yup.string().label('Transcript text').required(),
      }),
    ),
  });


  const handleSaveTranscript = (item: FormFieldProps) => {
    onUpdate(item as ITranscriptAnswer);
    reset();
  };

  useEffect(() => {
    setDefaultValues(getTranscriptAnswerDefaultValues(selectedAnswer));
  }, [selectedAnswer]);

  return (
    <SidebarContainer hasPaddingEnds={false}>
      <Spacing size="2x-small">
        <Breadcrumbs
          itemDivider="chevron"
          items={breadcrumbItems}
          isContained
        />
      </Spacing>
    
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSaveTranscript(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >

        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="text"
            render={({ field, fieldState }) => (
              <Textarea
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_transcript_answer_text"
                labelText="Answer text"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>

        {isMultiResponse && (
          <Spacing>
            <Controller
              control={control as Control<FormFieldProps>}
              name="isForceCorrect"
              render={({ field }) => (
                <ToggleSwitch
                  additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
                  id="id_is_correct"
                  isChecked={field.value}
                  labelText="Is correct answer"
                  isInline
                  isLabelSideBySide
                  {...field}
                />
              )}
            />
          </Spacing>
        )}
                
        <SidebarButtons
          onCancel={onCancel}
          onDelete={selectedAnswer ? () => onDelete(selectedAnswer.tempId) : undefined}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </SidebarContainer>
  );
};


export { UpsertTranscriptQuizAnswer };
