import React, { useEffect, useState } from 'react';

import { IDBAsset } from '@netfront/common-library';
import { IDirectory } from '@netfront/ekardo-content-library';
import { ITagSelectorTag, SidebarButtons, Spacing, Spinner, TagListSelector } from '@netfront/ui-library';
import { getFormattedDirectory , ICreatedTagsMap } from 'components';
import { useCreateTag, useDeleteTag, useSearchPaginatedTags, useToast } from 'hooks';

import { DirectoryTagsTabProps } from './DirectoryTagsTab.interfaces';


const DirectoryTagsTab = ({ onChange, selectedDirectory, onCancel, onSave, onDelete, projectId}: DirectoryTagsTabProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [activeTags, setActiveTags] = useState<IDirectory['linkedTags']>([]);
  const [existingTagList, setExistingTagList] = useState<ITagSelectorTag[]>();
  const [createdTags, setCreatedTags] = useState<ICreatedTagsMap[]>([]);
  const [allTags, setAllTags] = useState<IDirectory['linkedTags']>([]);
  const [newCurrentTagId, setCurrentTagId] = useState<string>();

  const { handleCreateTag: executeCreateTag, isLoading: isCreateTagLoading = false } = useCreateTag({
    onCompleted: ({ id, name }) => {

      setCreatedTags([
        ...createdTags,
        {
          id,
          newId: String(newCurrentTagId)
        }
      ]);

      setCurrentTagId(undefined);

      const updatedTagList = [
        ...allTags ?? [],
        {
          id,
          name,
        },
      ] as IDBAsset['tagList'];
      setAllTags(updatedTagList);
      onChange?.([
        ...activeTags ?? [],
        {
          id,
          name,
          projectId,
          sort: 1
        }
      ]);

      handleToastSuccess({ message: 'Tag created successfully' });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteTag: executeDeleteTag, isLoading: isDeleteTagLoading = false } = useDeleteTag({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        const createdTag = createdTags.find(({ newId }) => newId === newCurrentTagId);

        setCreatedTags(createdTags.filter(({newId}) => newId !== newCurrentTagId));
        const updatedTags = activeTags?.filter(({ id }) => id !== createdTag?.id);

        setActiveTags(updatedTags)
        onChange?.(updatedTags);
        handleToastSuccess({ message: 'Tag deleted successfully' });
      }

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleSearchPaginatedTags: executeSearchPaginatedTags, isLoading: isSearchPaginatedTagsLoading = false } = useSearchPaginatedTags(
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ edges }) => {
        const tags = edges.flatMap(({ node }) => (node ? node : []));
        const formattedTags = tags.map(({id, name}: { id: number; name: string}) => {
          return {
            id: String(id),
            name: String(name),
          };
        });

        setExistingTagList(formattedTags);
        setAllTags(tags);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
  );

  const handleCreateTag = (value: string, id: string) => {
    if (!value) return;
    setCurrentTagId(id);
    executeCreateTag({
      projectId,
      tagName: value,
    });
  };

  const handleDeleteTag = (id: string) => {
    const mappedId = createdTags.find(({newId}) => id === newId)?.id;

    if (mappedId) {
      setCurrentTagId(id);
      executeDeleteTag({
        tagId: Number(mappedId)
      });
    }
  }

  const handleSelectTags = (existingTags: string[], newTags: string[]) => {
    const existingSelectedTags: IDirectory['linkedTags'] = allTags?.filter((tag) => existingTags.includes(String(tag.id))) ?? [];
    const newSelectedCreatedTag: IDirectory['linkedTags'] = createdTags.reduce((acc = [], tag) => {
      if (newTags.includes(tag.newId)) {
        const savedTag = allTags?.find(({ id }) => id === tag.id );

        if (savedTag) acc.push(savedTag);
      }
      return acc;
    }, [] as IDirectory['linkedTags']) ?? [];

    const newSelectedTags = [
      ...existingSelectedTags,
      ...newSelectedCreatedTag
    ];

    setActiveTags(newSelectedTags);
    onChange?.(newSelectedTags);
  }


  useEffect(() => {
    if (!projectId) return;
    executeSearchPaginatedTags({
      projectId,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  useEffect(() => {
    if (!selectedDirectory) return;
    const { linkedTags: directoryTags } = getFormattedDirectory(selectedDirectory);
    setActiveTags(directoryTags);
  }, [selectedDirectory])

  const isLoading = isCreateTagLoading || isSearchPaginatedTagsLoading || isDeleteTagLoading;


  return (
    <>
      <Spacing>
        <Spinner isLoading={isLoading} />
        <TagListSelector
          initialSelectedTags={activeTags?.map(({id}) => String(id))}
          tagList={existingTagList}
          isLabelSideBySide
          onCreateTag={handleCreateTag}
          onDeleteTag={handleDeleteTag}
          onSelect={handleSelectTags}
        />
      </Spacing>
      <SidebarButtons
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={onSave}
      />
    </>
  );
};

export { DirectoryTagsTab };

