import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_NEW_APP_VERSION } from './useCreateNewAppVersion.graphql';
import {
  ICreateNewAppVersionMutationGraphQLResponse,
  ICreateNewAppVersionMutationVariables,
  IHandleCreateNewAppVersionParams,
  IUseCreateNewAppVersionOptions,
  IUseCreateNewAppVersion,
} from './useCreateNewAppVersion.interfaces';

const useCreateNewAppVersion = (options?: IUseCreateNewAppVersionOptions): IUseCreateNewAppVersion => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateNewAppVersionOptions);

  const [executeCreateNewAppVersion, { loading: isLoading }] = useEkardoMutation<
  ICreateNewAppVersionMutationGraphQLResponse,
  ICreateNewAppVersionMutationVariables
  >({
    mutation: mutation ?? CREATE_NEW_APP_VERSION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: {
            appVersionMutation: { createAppVersion: version },
          },
        } = data;
        
        void onCompleted({ version });
      },
      onError,
    },
    token,
  });

  const handleCreateNewAppVersion = ({
    identifier,
    type,
    version,
  }: IHandleCreateNewAppVersionParams) => {
    void executeCreateNewAppVersion({
      variables: {
        inputType: {
          identifier,
          type,
          version,
        },
      },
    });
  };

  return {
    handleCreateNewAppVersion,
    isLoading,
  };
};

export { useCreateNewAppVersion };
