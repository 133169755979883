import React, { useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, SelectWithSearch, SidebarDialog, ISearchList, CircleAddIcon } from '@netfront/ui-library';
import { CharacterSidebarView } from 'components';
import { useGetCharactersByProject, useToast } from 'hooks';
import { ICharacter } from 'interfaces';
import { Controller } from 'react-hook-form';

import { TranscriptCharacterPickerProps } from './TranscriptCharacterPicker.interfaces';


const TranscriptCharacterPicker = ({ control, setValue, projectId, initialCharacterId }: TranscriptCharacterPickerProps) => {

  const { handleToastError } = useToast();
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  
  
  const [isAddCharacterSidebarOpen, setIsAddCharacterSidebarOpen] = useState<boolean>(false);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [characterId, setCharacterId] = useState<number>();
  const [responseTitle, setResponseTitle] = useState<string | undefined>(undefined);
  const [searchSuggestions, setSearchSuggestions] = useState<ISearchList[]>([]);


  const { handleGetCharactersByProject } = useGetCharactersByProject({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ characters: returnedCharacters  }) => {

      const formattedCharacters = returnedCharacters.map(({ id, name, image  }) => {

        const { presignedUrl = '' } = image ?? {};
        return {
          id,
          label: String(name),
          logoUrl: presignedUrl,
        };
      });

      setResponseTitle(formattedCharacters.find(({ id }) => id === characterId)?.label ?? undefined);

      setSearchSuggestions(formattedCharacters.sort((a, b) => a.label.localeCompare(b.label)));

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleCreateCharacter = (character?: ICharacter) => {
    setValue('characterId', character?.id);
    setCharacterId(character?.id);
    setIsAddCharacterSidebarOpen(false);
    void handleGetCharactersByProject({
      projectId,
    });
  };

  const handleSearchItemClick = (selectedId: string | number) => {
    setValue('characterId', Number(selectedId));
    setResponseTitle(searchSuggestions.find(({ id }) => id === selectedId)?.label ?? '')
  };

  useEffect(() => {
    if (!projectId) return;
    void handleGetCharactersByProject({
      projectId,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setCharacterId(initialCharacterId);

  }, [initialCharacterId])

  return (
    <>
      <Spacing>
        <Controller
          control={control}
          name="characterId"
          render={({ fieldState }) => (
            <SelectWithSearch
              additionalClassNames="c-character-picker"
              buttonText={`Search existing characters`}
              countText={`characters`}
              customButtonIcon={CircleAddIcon}
              customButtonText="Add new character"
              defaultValue={responseTitle}
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_content_group"
              isDisplaySearchContent={isSearchContentVisible}
              labelText="Search"
              searchList={searchSuggestions}
              totalCount={searchSuggestions.length}
              isAvatarVisible
              isLabelSideBySide
              isRequired
              onCustomButtonClick={() => setIsAddCharacterSidebarOpen(true)}
              onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
              onSearchItemClick={handleSearchItemClick}
            />
          )}
        />
        </Spacing>

        <SidebarDialog
          isOpen={isAddCharacterSidebarOpen}
          hasCloseButton
          isPositionedAtTop
          onClose={() => setIsAddCharacterSidebarOpen(false)}
        >
        <>
          <CharacterSidebarView
            handleSideBarClose={() => setIsAddCharacterSidebarOpen(false)}
            isSideBarOpen={isAddCharacterSidebarOpen}
            projectId={String(projectId)}
            isNestedInSidebar
            onUpdate={handleCreateCharacter}
          />
        </>
      </SidebarDialog>
    </>
  );
};


export { TranscriptCharacterPicker };
