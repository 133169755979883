import { TranscriptShapeType } from "interfaces";


const PARTS_DELIMITER = '_::N::_';
const QUESTION_DELIMITER = '_::_';
const RESPONSE_OPTIONS_DELIMITER = '_::Q::_';

const getTranscriptText = (text: string, shape: TranscriptShapeType): string => {
  if (shape !== 'QUIZ') return text; 

  const [questionResponse] = text.split(PARTS_DELIMITER);

  const [questionConfig] = questionResponse.split(RESPONSE_OPTIONS_DELIMITER);

  const [, questionText] = questionConfig.split(QUESTION_DELIMITER);


  return questionText;
};


export { getTranscriptText };