import { useState, useRef, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCookie, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, Dialog, FormFieldProps, Input, Select, SidebarButtons, Spacing, Spinner, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { SidebarContainer, Uploader } from 'components';
import { useToast, useDeleteTopic, useGetTopic, useUpsertTopic } from 'hooks';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getTopicDefaultValues } from './TopicsSidebarGeneralView.helpers';
import { TopicsSidebarGeneralViewProps } from './TopicsSidebarGeneralView.interfaces';

const TopicsSidebarGeneralView = ({
  onClose,
  onCreated,
  onDeleted,
  onUpdated,
  selectedTopicId,
  projectId,
}: TopicsSidebarGeneralViewProps) => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { handleToastError, handleToastSuccess } = useToast();
  
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const uploadedFileRef = useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalFileRef = useRef<{value: boolean }>({ value: false });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);


  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required().label('Title'),
        status: yup.string().required().label('Status'),
      }),
    ),
  });


  const { handleGetTopic, isLoading: isGetTopicLoading = false } = useGetTopic({
    onCompleted: ({ topic }) => {
      setDefaultValues(getTopicDefaultValues({ topic }));
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId,
  });

  const { handleDeleteTopic: executeDeleteTopic, isLoading: isDeleteTopicLoading = false } = useDeleteTopic({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      handleToastSuccess({
        message: 'Topic successfully deleted',
      });

      onDeleted();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId,
  });

  
  const { handleUpsertTopic, isLoading: isUpsertTopicLoading } = useUpsertTopic({
    onCreate: () => {
      reset();
      onCreated();
    },
    onUpdate: () => {
      reset();
      onUpdated();
    },
    projectId,
  });

  const handleDropFile = (uploadedFile?: File) => {
    uploadedFileRef.current.value = uploadedFile;
  };

  const handleRemoveFile = () => {
    hasDeletedOriginalFileRef.current.value = true;
  };


  const handleSave = (item: FormFieldProps) => {
    const { title, status } = item;
    handleUpsertTopic({
      title,
      status,
      topicId: selectedTopicId,
      uploadedFile: uploadedFileRef.current.value,
      deletedFileId: hasDeletedOriginalFileRef.current.value ? defaultValues.assetId : undefined,
    });
  }

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const isLoading = isGetTopicLoading || isDeleteTopicLoading || isUpsertTopicLoading;

  useEffect(() => {

    if (selectedTopicId) {
      handleGetTopic({
        topicId: selectedTopicId,
      });
    } else {
      setDefaultValues(getTopicDefaultValues({}));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopicId]);

  return (
    <SidebarContainer>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
        {isLoading && <Spinner isLoading={isLoading} />}
        <Controller
          control={control as Control<FormFieldProps>}
          name="title"
          render={({ field, fieldState }) => (
            <Spacing size="large">
              <Input 
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_topic_title" 
                labelText="Title" 
                tooltipText="Topic title" 
                type="text" 
                isLabelSideBySide 
                isRequired
                {...field}
              />
            </Spacing>
          )}
        />
  

        <Controller
          control={control as Control<FormFieldProps>}
          name="status"
          render={({ field, fieldState }) => (
            <Spacing size="large">
              <Select
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="Status"
                labelText="Select a question"
                options={[
                  {
                    id: 'ACTIVE',
                    name: 'Active',
                    value: 'ACTIVE'
                  },
                  {
                    id: 'UNPUBLISHED',
                    name: 'Unpublished',
                    value: 'UNPUBLISHED'
                  },
                  {
                    id: 'DELETED',
                    name: 'Deleted',
                    value: 'DELETED'
                  },
                ]}
                isLabelSideBySide
                isRequired
                {...field}
              />
            </Spacing>
          )}
        />

        <Controller
          control={control as Control<FormFieldProps>}
          name="presignedUrl"
          render={({ field }) => {
            return (
              <Spacing size="large">
                <Uploader
                  assetType="image"
                  hasRemoveButton={Boolean(defaultValues.assetId)}
                  initialUrl={field.value}
                  isRequired={false}
                  name={field.name}
                  isLabelSideBySide
                  onDelete={handleRemoveFile}
                  onDrop={handleDropFile}
                />
              </Spacing>
            );
          }}
        />

        <SidebarButtons
          onCancel={onClose}
          onDelete={selectedTopicId ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"
        />
      </ControlledForm>
      {selectedTopicId && (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete topic?`}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => {
            if (!selectedTopicId) {
              return;
            }

            executeDeleteTopic({
              topicId: selectedTopicId,
            });
          }}
        />
      )}
    </SidebarContainer>
  );
};

export { TopicsSidebarGeneralView };
