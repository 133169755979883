import React, { useState, useRef, useEffect } from 'react';

import { CaretDownIcon, CoverButton, FlexContainer } from '@netfront/ui-library';
import cx from 'classnames';

import { ReportOptionAccordionProps } from './ReportOptionAccordion.interfaces';

export const ReportOptionAccordion = ({ id, label, children, isOpenOnLoad = true, icon }: ReportOptionAccordionProps) => {
  const [isOpen, setIsOpen] = useState(isOpenOnLoad);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpenOnLoad && contentRef.current) setContentHeight(contentRef.current.scrollHeight);
  }, [isOpenOnLoad]);

  const toggleAccordion = () => {
    setIsOpen((prevIsOpen: boolean) => !prevIsOpen);

    if (!isOpen && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  };

  const Icon = icon;

  return (
    <div className={cx('c-report-option-accordion', { 'is-open': isOpen })} data-testid={`qa-accordion-${id}`}>
      <div className="c-report-option-accordion__header">
        {children && (
          <CoverButton
            additionalAttributes={{
              'aria-controls': id,
              'aria-expanded': isOpen,
              'data-testid': `qa-accordion-trigger-${id}`,
            }}
            additionalClassNames="c-report-option-accordion__trigger"
            supportiveText="toggle accordion"
            onClick={toggleAccordion}
          />
        )}

        <FlexContainer alignItems="center" gap="small" justifyContent="flex-start" isFullWidth>
          <Icon className="c-report-option-accordion__icon"/>
          <h3 className="c-report-option-accordion__title">{label}</h3>
        </FlexContainer>
        <CaretDownIcon className="c-report-option-accordion__trigger-icon" />
      </div>

      <div
        ref={contentRef}
        aria-hidden={!isOpen}
        className={cx('c-report-option-accordion__content-container', { 'is-open': isOpen })}
        data-testid={`qa-accordion-content-container-${id}`}
        id={id}
        role="region"
        style={
          isOpen
            ? {
                maxHeight: `${contentHeight}px`,
                visibility: 'visible',
              }
            : {
                maxHeight: 0,
                visibility: 'hidden',
              }
        }
      >
        <div className="c-report-option-accordion__content">{children}</div>
      </div>
    </div>
  );
};

ReportOptionAccordion.displayName = 'ReportOptionAccordion';
