import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_PAGE } from './useUpdatePage.graphql';
import {
  IUpdatePageMutationGraphQLResponse,
  IUpdatePageMutationVariables,
  IUseUpdatePageOptions,
  IUseUpdatePage,
  IUpdatePageMutationParams,
} from './useUpdatePage.interfaces';

const useUpdatePage = (options?: IUseUpdatePageOptions): IUseUpdatePage => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdatePageOptions);

  const [executeUpdatePage, { loading: isLoading }] = useEkardoMutation<IUpdatePageMutationGraphQLResponse, IUpdatePageMutationVariables>({
    mutation: mutation ?? UPDATE_PAGE,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { updateContentPage: contentPage },
        } = data;

        onCompleted({
          contentPage,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdatePage = async ({
    assetId,
    // audioId,
    contentPageId,
    parentId,
    sensitiveContent,
    seoDescription,
    seoTitle,
    sort,
    tags,
    title,
    url,
    styleId,
    shouldAttachCustomTheme,
    shouldDetachCustomTheme,
    contentPageConfigurationNavigation,
    releaseDate,
    minimumExposureTime,
    mode,
    contentPageType,
    // status,
  }: IUpdatePageMutationParams) => {
    await executeUpdatePage({
      variables: {
        contentPage: {
          assetId,
          // audioId,
          contentPageId,
          parentId,
          sensitiveContent,
          seoDescription,
          seoTitle,
          sort,
          tags,
          title,
          url,
          releaseDate,
          minimumExposureTime,
          mode,
          contentPageType,
          contentPageConfigurationNavigation,
          // status,
        },
        contentPageId,
        styleId,
        shouldAttachCustomTheme,
        shouldDetachCustomTheme,
      }
    });
  };

  return {
    handleUpdatePage,
    isLoading,
  };
};

export { useUpdatePage };
