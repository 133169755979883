import React, { useState, useContext, useEffect } from 'react';

import { IQuestionAction } from '@netfront/ekardo-content-library';
import { Dialog, Spinner } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { ContentBuilderContext } from 'context';
import { useToast, useGetActions, useDeleteAction } from 'hooks';

import { ActionsOverview, UpsertAction } from './Components';
import { WRAPPER_TYPE_ACTION_KEY_MAP } from './UpsertActionsTab.constants';
import { UpsertActionsTabProps } from './UpsertActionsTab.interfaces';


const UpsertActionsTab = ({ onDelete }: UpsertActionsTabProps) => {
  const { actionsSidebarDetails, pageId: activePageId } = useContext(ContentBuilderContext);
  const { handleToastError } = useToast();

  const [allActions, setAllActions] = useState<IQuestionAction[]>([]);
  const [selectedActionId, setSelectedActionId] = useState<number | undefined>(undefined);
  const [isUpsertMode, setIsUpsertMode] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [deleteActionId, setDeleteActionId] = useState<number>();
  const [contentEventId, setContentEventId] = useState<number>();

  const { type = 'targetSnippet', id, contentEventId: actionContentEventId, hasContentEvent = false } = actionsSidebarDetails ?? {};
  const actionKey = WRAPPER_TYPE_ACTION_KEY_MAP[type];

  const { handleGetActions, isLoading: isGetActionsLoading = false } = useGetActions({
    fetchPolicy: 'no-cache',
    onCompleted: ({actions}) => {
      setAllActions(actions);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    }
  });

  const handleDeleteActionOnCompleted = () => {
    setSelectedActionId(undefined);
    setIsUpsertMode(false);
    handleGetActions({
      contentPageId: Number(activePageId),
    });
  };

  const { handleDeleteAction: executeDeleteAction, isLoading: isDeleteActionLoading = false } = useDeleteAction({
    onCompleted: handleDeleteActionOnCompleted,
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    }
  });

  const handleCancelUpsertMode = () => {
    setSelectedActionId(undefined);
    setIsUpsertMode(false);
  };

  const handleEditAction = (actionId?: number) => {
    setSelectedActionId(actionId);
    setIsUpsertMode(true);
  };

  const handleUpdate = () => {
    handleCancelUpsertMode();
    handleGetActions({
      contentPageId: Number(activePageId),
    });
  };

  const handleDeleteAction = (actionId: number) => {
    executeDeleteAction({
      actionId: Number(actionId),
    });
    setIsDeleteDialogOpen(false);
    setDeleteActionId(undefined);
  };

  const cancelDeleteAction = () => {
    setIsDeleteDialogOpen(false);
    setDeleteActionId(undefined);
  };

  useEffect(() => {
    if (!activePageId) {
      return;
    }

    handleGetActions({
      contentPageId: activePageId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePageId]);

  // Determine whether the wrapper has an event attached to it.
  useEffect(() => {
    if (selectedActionId) return;
    if (!hasContentEvent && actionContentEventId) return;
    setContentEventId(actionContentEventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, hasContentEvent, selectedActionId, actionContentEventId]);

  const isLoading = isGetActionsLoading || isDeleteActionLoading;

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
      <>
        {isUpsertMode ? (
          <UpsertAction
            activePageId={activePageId}
            selectedActionId={selectedActionId}
            onCancel={handleCancelUpsertMode}
            onDelete={(actionId: number) => {
              setIsDeleteDialogOpen(true);
              setDeleteActionId(actionId);
            }}
            onUpdate={handleUpdate}
          />
        ) : (
          <ActionsOverview
            actionItems={allActions.filter(
              (item) => item[actionKey as keyof IQuestionAction] === id || (Boolean(contentEventId) && item.contentEventId === contentEventId),
            )}
            onDelete={onDelete}
            onDeleteClick={(actionId: number) => {
              setIsDeleteDialogOpen(true);
              setDeleteActionId(actionId);
            }}
            onEditClick={handleEditAction}
          />
        )}

        <Dialog
          isOpen={isDeleteDialogOpen}
          title="Delete action?"
          isNarrow
          onCancel={cancelDeleteAction}
          onClose={cancelDeleteAction}
          onConfirm={() => handleDeleteAction(Number(deleteActionId))}
        />
      </>
    </SidebarContainer>
  );
};

export { UpsertActionsTab };
