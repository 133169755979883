import { useContext, useEffect, useState } from 'react';

import { IUser, useBackofficeSiteUrls, useDomain, useIdentitySiteUrls, useUser } from '@netfront/gelada-identity-library';
import { Dropdown, EnterIcon, IDropdownNavigationItem, LogoAvatar, LogoutIcon, PlansIcon, UserNavigation, UserNavigationItemProps } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';

const UserAccountMenu = () => {
  const { getUser } = useUser();

  const { project } = useContext(CachingEntitiesContext);
  const { isDomainReady } = useDomain();
  const { getLogoutUrl, getProfileUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const { getBaseUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });

  const [dashboardUrl, setDashboardUrl] = useState<string>('');
  const [footerConfig, setFooterConfig] = useState<IDropdownNavigationItem>();
  const [navigationList, setNavigationList] = useState<UserNavigationItemProps[]>([]);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>();
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [loggedUser, setLoggedUser] = useState<IUser>();
  const [profileUrl, setProfileUrl] = useState<string>();

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setDashboardUrl(getBaseUrl());

    setLogoutUrl(getLogoutUrl());
    setLoggedUser(getUser());
    setProfileUrl(getProfileUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!(dashboardUrl && loggedUser && project)) {
      return;
    }

    const {firstName = '', lastName = '', email, pictures } = loggedUser;

    const { id: projectId, organisation } = project;

    const { originalPicture, profilePicture } = pictures ?? {};

    const image = !profilePicture?.presignedUrl ? originalPicture?.presignedUrl ?? '' : profilePicture.presignedUrl;

    const ekardoBaseUrl = `/dashboard/${String(organisation.key)}/${String(projectId)}`;

    setProfileImageUrl(String(decodeURIComponent(image)));

    const dropdownNavigationItems: UserNavigationItemProps[] = [
      {
        header: {
          title: `${String(firstName)} ${String(lastName)}`,
          name: String(email),
          icon: EnterIcon,
          href: String(profileUrl),
          linkText: 'View profile'
        },
        options: [
          {
            icon: PlansIcon,
            href: `${ekardoBaseUrl}/library/apps`,
            label: 'My apps',
          },
        ],
      },
    ];

    setNavigationList(dropdownNavigationItems);

    setFooterConfig({
      label: 'Logout',
      href: logoutUrl,
      icon: LogoutIcon,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardUrl, loggedUser, project]);

  return (
    <div className="c-user-account-menu">
      {loggedUser && (
        <Dropdown
          dropdownId="profile-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<LogoAvatar avatarImageSrc={String(profileImageUrl)} size="small" title={ `${String(loggedUser.firstName)} ${String(loggedUser.lastName)}`} />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <UserNavigation
            dropdownNavigationItems={navigationList}
            footerConfig={footerConfig}
          />
        </Dropdown>
      )}
    </div>
  );
};

export { UserAccountMenu };
