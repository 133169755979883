import { IDBAsset } from "@netfront/common-library";
import { FormFieldProps } from "@netfront/ui-library";
import { UseFormSetValue } from "react-hook-form";

const handleSelectAsset = ({asset, setValue, assetIdKey } : { asset: IDBAsset; assetIdKey: string; setValue: UseFormSetValue<FormFieldProps> }) => {
  const {
    alt,
    assetId,
    contentType,
    description,
    fileName,
    isPublic,
    presignedUrl,
    publicUrl,
    tagList,
    title,
    user,
  } = asset;

  const { firstName, lastName} = user ?? {};

  setValue(assetIdKey, assetId);
  setValue('alt', alt);
  setValue('contentType', contentType);
  setValue('description', description);
  setValue('fileName', fileName);
  setValue('isPublic', isPublic);
  setValue('presignedUrl', presignedUrl);
  setValue('publicUrl', publicUrl);
  setValue('tagList', tagList);
  setValue('title', title);
  setValue('author', `${String(firstName ?? '')} ${String(lastName ?? '')}`);
  setValue('asset', asset);

};

const resetAssetValues = (values: FormFieldProps, assetIdKey: string): FormFieldProps => {
  
  return {
    ...values,
    [`${assetIdKey}`]: undefined,
    alt: undefined,
    contentType: undefined,
    description: undefined,
    fileName: undefined,
    isPublic: undefined,
    presignedUrl: undefined,
    publicUrl: undefined,
    tagList: undefined,
    title: undefined,
    author: undefined,
    asset: undefined,
  };
}

export { handleSelectAsset, resetAssetValues };
