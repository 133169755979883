import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_TRANSCRIPT_MUTATION } from './useDeleteTranscript.graphql';
import {
  IDeleteTranscriptMutationGraphQLResponse,
  IDeleteTranscriptMutationVariables,
  IHandleDeleteTranscriptParams,
  IUseDeleteTranscriptOptions,
  IUseDeleteTranscript,
} from './useDeleteTranscript.interfaces';

const useDeleteTranscript = (options?: IUseDeleteTranscriptOptions): IUseDeleteTranscript => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteTranscriptOptions);

  const [executeDeleteTranscript, { loading: isLoading }] = useEkardoMutation<IDeleteTranscriptMutationGraphQLResponse, IDeleteTranscriptMutationVariables>({
    mutation: mutation ?? DELETE_TRANSCRIPT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          bubbleText: {
            remove: isCompleted,
          },
        } = data;

        void onCompleted({
          isCompleted
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteTranscript = async ({ transcriptId }: IHandleDeleteTranscriptParams) => {
    await executeDeleteTranscript({
      variables: {
        transcriptId,
      },
    });
  };

  return {
    handleDeleteTranscript,
    isLoading,
  };
};

export { useDeleteTranscript };
