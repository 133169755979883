import { gql } from '@apollo/client';

const DELETE_TOPIC_ASSET_MUTATION = gql`
  mutation deleteTopicAsset($assetId: String!) {
    asset {
      deleteAsset(assetId: $assetId)
    }
  }
`;

export { DELETE_TOPIC_ASSET_MUTATION };
