
import { IDBAppVersion } from 'interfaces';

import { IAppVersionTableData, IAppVersionTableDataParams } from './AppVersionsPage.interfaces';


const getAppVersionTableData = ({ appVersionItems, onViewCodeOnClick, onSubmitForApprovalOnClick }: IAppVersionTableDataParams): IAppVersionTableData[] => {
  return appVersionItems.map((item) => {
    const { version, usageCount, uploadedDateTime, components = [], status, id} = item;
    return {
      id,
      version,
      components: `${components.length} components`,
      usageCount,
      uploadedDateTime,
      status,
      settingsButtonData: {
        item,
        onViewCodeOnClick,
        onSubmitForApprovalOnClick,
      },
    };
  });
};

const incrementVersion = (version: string): string => {
  // Split the version string into an array of numbers
  const parts = version.split('.').map(Number);

  // Check if all parts are valid numbers
  if (parts.some(isNaN)) {
    throw new Error('Invalid version format');
  }

  // Increment the last number
  parts[parts.length - 1] += 1;

  // Join the parts back into a string and return
  return parts.join('.');
};

const sortVersionsAscending = (versions: IDBAppVersion[]): IDBAppVersion[]  => {
  // Function to compare two versions
  function compareVersions(a: string, b: string): number {
    const aParts = a.split('.').map(Number);
    const bParts = b.split('.').map(Number);

    // Compare each part of the version
    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = aParts[i] || 0; // Default to 0 if part is missing
      const bPart = bParts[i] || 0; // Default to 0 if part is missing

      if (aPart > bPart) return 1;
      if (aPart < bPart) return -1;
    }
    return 0; // Versions are equal
  }

  // Sort the array of versions using the compareVersions function
  return versions.sort((a, b) => compareVersions(a.version, b.version));
}


const getGreatestVersion = (currentVersions: IDBAppVersion[]): string => {
  // Function to compare two versions
  function compareVersions(a: string, b: string): number {
    const aParts = a.split('.').map(Number);
    const bParts = b.split('.').map(Number);

    // Compare each part of the version
    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = aParts[i] || 0; // Default to 0 if part is missing
      const bPart = bParts[i] || 0; // Default to 0 if part is missing

      if (aPart > bPart) return 1;
      if (aPart < bPart) return -1;
    }
    return 0; // Versions are equal
  }

  const greatestVersion = currentVersions.reduce((greatest, current) =>
    compareVersions(current.version, greatest.version) > 0 ? current : greatest
  );

  return greatestVersion.version;
}

export { getAppVersionTableData, incrementVersion, getGreatestVersion, sortVersionsAscending};
