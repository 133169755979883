import React, { useEffect, useState } from 'react';

import { HollowDownloadIcon, HomeIcon, IBreadcrumbItem, IconRadioGroup, LibraryIcon, SceneIcon, SidebarDialog, SlideIcon } from '@netfront/ui-library';
import { BulkUploadCartoonSlides, ContentBuilderTabWrapper, UpsertPageGeneralTab } from 'components';
import { ContentPageType } from 'interfaces';


import { getInitialView } from './CartoonPageTypeSelector.helpers';
import { CartoonPageTypeSelectorProps } from './CartoonPageTypeSelector.interfaces';


const CartoonPageTypeSelector = ({
  contentType,
  control,
  projectId,
  setValue,
  onDeleteAsset,
  onDrop,
  pageSidebarDetails,
  setPageSidebarDetails,
  isFromCartoonTab = false,
  closePageSidebar,
  onBulkUpload,
  getValues,
  initialTitle = ''
}: CartoonPageTypeSelectorProps) => { 

  const { pageId, cartoonType, isOpenedFromEmptyState = false, contentGroupId, parentId } = pageSidebarDetails ?? {};

  const [view, setView] = useState<string>(getInitialView({ cartoonType, isOpenedFromEmptyState, pageId }));
  const [isBulkUploadSlides, setIsBulkUploadSlides] = useState<boolean>(false);

  const isCreateNew = !pageId;

  const cancelBulkUpload = () => {
    setView('slide_type_selector');
    setIsBulkUploadSlides(false);
  };

  let breadcrumbItems: IBreadcrumbItem[] = [];

  if (isFromCartoonTab && isCreateNew && ['selector', 'slide_type_selector'].includes(view)) {
    breadcrumbItems = [
      {
        key: 'cartoon-type-selector',
        onClick:() => {
          closePageSidebar();
          setView(isOpenedFromEmptyState ? 'selector': 'slide_type_selector');
          setIsBulkUploadSlides(false);
        },
        content: isOpenedFromEmptyState ? 'Select cartoon page type': 'Select slide upload type',
        icon: HomeIcon
      },
      {
        key: 'view',
        content: <span className="c-breadcrumb-item__link">Upload type</span>,
      }
    ]
  }

  if (isCreateNew && !['selector', 'slide_type_selector'].includes(view)) {
    breadcrumbItems = [
      {
        key: 'cartoon-type-selector',
        onClick: isFromCartoonTab ? () => {
          closePageSidebar();
          setView(isOpenedFromEmptyState ? 'selector': 'slide_type_selector');
          setIsBulkUploadSlides(false);
        } : () => {
          setView(isOpenedFromEmptyState ? 'selector': 'slide_type_selector');
          setIsBulkUploadSlides(false);
        },
        content: isOpenedFromEmptyState ? 'Select cartoon page type': 'Select slide upload type',
        icon: HomeIcon
      },
      {
        key: 'view',
        content: <span className="c-breadcrumb-item__link">New {cartoonType}</span>,
        onClick: (!isOpenedFromEmptyState && view === 'bulk_upload_slide' ) || (cartoonType === 'slide' && isFromCartoonTab) ? () => {
          setView('slide_type_selector');
          setIsBulkUploadSlides(false);
        }: undefined,
      }
    ]
  }

  if (view === 'bulk_upload_slide' ) {
    breadcrumbItems.push(
      {
        key: 'bulk upload',
        content: <span className="c-breadcrumb-item__link">Bulk upload</span>,
      }
    );
  }

  useEffect(() => {
    if (view !== 'bulk_upload_slide') return;
    setIsBulkUploadSlides(true);
  }, [view]);


  return (
    <ContentBuilderTabWrapper
      additionalBreadcrumbs={breadcrumbItems}
      hasContainerWrapper={['selector', 'slide_type_selector'].includes(view)}
      hasSnippetTypeBreadcrumb={false}
      isSnippet={false}
    >
      { view === 'selector' && (
        
        <IconRadioGroup
          id="new_cartoon_page_type_selector"
          items={[
            {
              icon: SceneIcon,
              label: 'New scene',
              value: 'scene',
            },
            {
              icon: SlideIcon,
              label: 'New slide',
              value: 'slide',
            },
          ]}
          label="Type"
          name="new_cartoon_page_type_selector"
          selectedValue={view}
          hasLabels
          isLabelHidden
          isOutlined
          onChange={(value) => {
            setView(value);
            setPageSidebarDetails({
              ...pageSidebarDetails ?? {},
              cartoonType: value as ContentPageType,
            })
          }}
        />

      )}
      { view === 'slide_type_selector' && (
        
        <IconRadioGroup
          id="new_cartoon_page_slide_type_selector"
          items={[
            {
              icon: HollowDownloadIcon,
              label: 'Single slide',
              value: 'slide',
            },
            {
              icon: LibraryIcon,
              label: 'Bulk upload',
              value: 'bulk_upload_slide',
            },
          ]}
          label="Slide type"
          name="new_cartoon_page_slide_type_selector"
          selectedValue={view}
          hasLabels
          isLabelHidden
          isOutlined
          onChange={(value) => {
            setView(value);
          }}
        />

      )}
      { cartoonType && ['scene', 'slide'].includes(view) && (
        <UpsertPageGeneralTab
          cartoonType={cartoonType}
          contentType={contentType}
          control={control}
          getValues={getValues}
          hasImageUpload={cartoonType === 'slide'}
          initialTitle={initialTitle}
          pageId={pageId}
          projectId={projectId}
          setValue={setValue}
          onDeleteAsset={onDeleteAsset}
          onDrop={onDrop}
        />
      )}

      {cartoonType === 'slide' && view === 'bulk_upload_slide' && (
        <SidebarDialog
          isOpen={isBulkUploadSlides}
          onClose={cancelBulkUpload}
        >
        {isBulkUploadSlides && (
          <BulkUploadCartoonSlides
            additionalBreadcrumbs={breadcrumbItems}
            contentGroupId={Number(contentGroupId)}
            parentId={Number(parentId)}
            projectId={projectId}
            hasContainer
            onCancel={cancelBulkUpload}
            onSave={onBulkUpload}
          />
        )}
      </SidebarDialog>

      )}

    </ContentBuilderTabWrapper>
  );
};

export { CartoonPageTypeSelector };
