import React, { useState, useRef, useEffect, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { ControlledForm, FilmIcon, FormFieldProps, GeneralTabIcon, SidebarButtons, SingleCheckbox, Spinner, StyleTabIcon, TabSet, TabSetImperativeHandleRef, UsageIcon, useControlledForm } from '@netfront/ui-library';
import { AnimationTab, EventTab, UpsertTextSnippetGeneralTab, animationTabValidation, eventTabValidation, StyleTab } from 'components';
import { CachingEntitiesContext } from 'context';
import { useUpsertAssetWrapper, useUpsertSnippet } from 'hooks';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getTextSnippetCommonVariables, getTextSnippetDefaultValues, setQuickAddTextValues } from './UpsertTextSnippetSidebarView.helpers';
import { UpsertTextSnippetSidebarViewProps } from './UpsertTextSnippetSidebarView.interfaces';

const UpsertTextSnippetSidebarView = ({ containerId, onClose, onCreate, onDelete, onUpdate, snippet, sort, isLoading: isSnippetLoading = false, handleUpdateSortValue }: UpsertTextSnippetSidebarViewProps) => {

  const { project } = useContext(CachingEntitiesContext);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const droppedFileEventAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalEventImageRef = useRef<{value: boolean }>({ value: false });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { control, handleSubmit, reset, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        text: yup.string().label('Text').required(),
        ...eventTabValidation,
        ...animationTabValidation,
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();

  const { handleUpsertSnippet, isLoading: isUpsertSnippetLoading = false } = useUpsertSnippet({
    onCreate: () => {
      const { isCreateNew = false } = getValues();

      if (isCreateNew) {
        droppedFileEventAssetRef.current.value = undefined;
        hasDeletedOriginalEventImageRef.current.value = false;
        handleUpdateSortValue(sort + 1);
        setDefaultValues(setQuickAddTextValues(getValues()));
      } else {
        reset();
      }
      onCreate(isCreateNew as boolean);
    },
    onUpdate: (returnedSnippet) => {
      reset();
      onUpdate(returnedSnippet);
    }
  });

  const handleDropEventFile = (uploadedFile?: File) => {
    droppedFileEventAssetRef.current.value = uploadedFile;
  };

  const handleRemoveEventAsset = () => {
    hasDeletedOriginalEventImageRef.current.value = true;
  };



  const handleSave = (item: FormFieldProps) => {
    const { assetId } = item;

    handleUpsertAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(project?.id),
      uploadedFile: droppedFileEventAssetRef.current.value,
      deletedFileId: hasDeletedOriginalEventImageRef.current.value && assetId ? assetId : undefined,
      callBack: (asset?: IDBAsset) => {
        const commonVariables = getTextSnippetCommonVariables({
          item,
          eventAssetId: asset ? String(asset.assetId): undefined,
        });

        handleUpsertSnippet({
          containerId,
          snippetId: snippet? Number(snippet.id): undefined,
          sort,
          variables: commonVariables,
        });
      }
    });
  };

  useEffect(() => {
    setDefaultValues(getTextSnippetDefaultValues({ snippet }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet]);

  const isLoading =
    isUpsertSnippetLoading ||
    isUpsertAssetLoading ||
    isSnippetLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
        <TabSet
          ref={tabsetRef}
          defaultActiveTabId="id_general_tab"
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => isLoading ? <></> : (
                <UpsertTextSnippetGeneralTab
                  control={control as Control<FormFieldProps>}
                />
              ),
            },
            {
              icon: UsageIcon,
              id: 'id_event_tab',
              label: 'Event',
              view: () => isLoading ? <></> : (
                <EventTab
                  control={control as Control<FormFieldProps>}
                  initialEvent={defaultValues.event}
                  onDeleteAsset={handleRemoveEventAsset}
                  onDrop={handleDropEventFile}
                />
              ),
            },
            {
              icon: FilmIcon,
              id: 'id_animation_tab',
              label: 'Animation',
              view: () => isLoading ? <></> : (
                <AnimationTab
                  animationType={defaultValues.animation ?? ''}
                  control={control as Control<FormFieldProps>}
                />
              ),
            },
            {
              icon: StyleTabIcon,
              id: 'id_style_tab',
              label: 'Style',
              view: () => isLoading ? <></> : (
                <StyleTab
                  control={control as Control<FormFieldProps>}
                  entityType="targetSnippet"
                  hasLinkStyling
                />
              ),
            },
          ]}
        />

        <SidebarButtons
          additionalButton={!snippet ? (
            <Controller
              control={control as Control<FormFieldProps>}
              name="isCreateNew"
              render={({ field }) => (
                <SingleCheckbox
                  hasPadding={false}
                  id="id_is_create_new"
                  isChecked={field.value}
                  labelText="Create another snippet"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          ) : undefined
          }
          onCancel={onClose}
          onDelete={onDelete}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </>
  );
};


export { UpsertTextSnippetSidebarView };
