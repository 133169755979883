import { useEffect, useRef } from 'react';

import { Card } from '@netfront/ui-library';

import { AppComponentPreviewProps } from './AppComponentPreview.interfaces';

const AppComponentPreview = ({ componentFileDetails  }: AppComponentPreviewProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    let document: Document | null = null;
    if (iframeRef.current) {
      document = iframeRef.current.contentDocument;
    }

    if (document) {
      const documentContents = componentFileDetails.htmlContent
        ? `
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>Document</title>
          <style>
            ${componentFileDetails.cssContent}
          </style>
          <script>
            ${componentFileDetails.jsContent}
          </script>
        </head>
        <body>
            ${componentFileDetails.htmlContent}
        </body>
        </html>
      `
      : '<html lang="en"></html>';


      document.open();
      document.write(documentContents);
      document.close();
    }

  }, [componentFileDetails]);

  return (
    <Card 
      additionalClassNames="c-app-component-preview"
      padding="small"
      hasNoHover
      isFullWidth
    >
      <iframe ref={iframeRef} className=" border-none" title="result" />
    </Card>
  );
};

export { AppComponentPreview };
