import { gql } from '@apollo/client';

const CREATE_ASSET_MUTATION = gql`
  mutation CreateAsset(
    $projectId: String!
    $description: String
    $tagList: [String]
    $type: EAssetType!
    $fileName: String!
    $contentType: String!
    $alt: String!
    $fileSizeInBytes: Int!
    $title: String
    $xAxisFocus: Int
    $yAxisFocus: Int
    $imageSize: EImageSize
    $isAnimatedImage: Boolean
  ) {
    asset {
      createAsset(
        asset: {
          projectId: $projectId
          description: $description
          type: $type
          title: $title
          fileName: $fileName
          contentType: $contentType
          fileSizeInBytes: $fileSizeInBytes
          alt: $alt
          tagList: $tagList
          xAxisFocus: $xAxisFocus
          yAxisFocus: $yAxisFocus
          isAnimatedImage: $isAnimatedImage
        }
      ) {
        signedUrl
        asset {
          alt
          assetId
          contentType
          description
          fileName
          presignedUrl(size: $imageSize)
          tagList {
            id
            name
          }
          title
          user {
            firstName
            lastName
          }
          xAxisFocus
          yAxisFocus
        }
      }
    }
  }
`;

export { CREATE_ASSET_MUTATION };
