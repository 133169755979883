import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { SUBMIT_APP_FOR_APPROVAL } from './useSubmitAppForApproval.graphql';
import {
  ISubmitAppForApprovalMutationGraphQLResponse,
  ISubmitAppForApprovalMutationVariables,
  IHandleSubmitAppForApprovalParams,
  IUseSubmitAppForApprovalOptions,
  IUseSubmitAppForApproval,
} from './useSubmitAppForApproval.interfaces';

const useSubmitAppForApproval = (options?: IUseSubmitAppForApprovalOptions): IUseSubmitAppForApproval => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseSubmitAppForApprovalOptions);

  const [executeSubmitAppForApproval, { loading: isLoading }] = useEkardoMutation<
    ISubmitAppForApprovalMutationGraphQLResponse,
    ISubmitAppForApprovalMutationVariables
  >({
    mutation: mutation ?? SUBMIT_APP_FOR_APPROVAL,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: { appVersionMutation: { submitNewVersion: isCompleted}  },
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleSubmitAppForApproval = ({ inputType }: IHandleSubmitAppForApprovalParams) => {
    void executeSubmitAppForApproval({
      variables: {
        inputType,
      },
    });
  };

  return {
    handleSubmitAppForApproval,
    isLoading,
  };
};

export { useSubmitAppForApproval };
