import React, { useEffect, useState } from 'react';

import { Spacing, Table, ButtonIconOnly, BinIcon } from '@netfront/ui-library';
import { IUploadedCartoonPage } from 'components';

import { BulkUploadOverviewProps } from './BulkUploadOverview.interfaces';


const BulkUploadOverview = ({ onDelete, watch }: BulkUploadOverviewProps) => {

  const [items, setItems] = useState<IUploadedCartoonPage[]>([]);

  useEffect(() => {
    const subscription = watch((value) => {
      const { uploadedFiles = [] } = value;
      setItems(uploadedFiles as IUploadedCartoonPage[]);
    });
    return () => subscription.unsubscribe();

  }, [watch]);

  return (
    <>
      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              {
                accessor: 'previewUrl',
                Cell: ({ value }: { value: string }) => <span><img alt="uploaded slide" className="c-bulk-upload__image" src={value}/></span>,
                Header: () => <div>Image</div>,
                width: '10%',
              },
              {
                accessor: 'title',
                Cell: ({ value }: { value: string}) => <span>{value}</span>,
                Header: () => <div>Title</div>,
                width: '45%',
              },
              {
                accessor: 'url',
                Cell: ({ value }: { value: string}) => <span>{value}</span>,
                Header: () => <div>Url</div>,
                width: '40%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleDelete,
                    id,
                  }
                }: {
                  value: {
                    handleDelete: (id: string) => void
                    id: string;
                  }
                }) => (
                  <ButtonIconOnly
                    additionalClassNames=""
                    icon={BinIcon}
                    isIconBorderVisible={false}
                    text="Remove slide"
                    onClick={() => handleDelete(id)}
                  />
                ),
              },
            ]}
            data={items.map(({ id, previewUrl, title, url}) => ({
              id,
              previewUrl,
              url,
              title,
              settingsButtonData: {
                id,
                handleDelete: onDelete,
              },
            }))}
          />
        </Spacing>
      )}
      
    </>
  );
};




export { BulkUploadOverview };
