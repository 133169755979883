import { ChangeEvent, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { getAccessTokenCookie } from '@netfront/common-library';
import { Dialog, Select, Spacing, Spinner } from '@netfront/ui-library';
import { useBulkAddUserInCommunity, useBulkRemoveMembersFromCommunity, useBulkUpdateCommunityRole, useToast } from 'hooks';
import { DBUserConnectionRole } from 'interfaces';

import { USER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP, USER_BULK_ACTION_DIALOG_TITLE_MAP } from './UserBulkActionDialog.constants';
import { UserBulkActionDialogProps } from './UserBulkActionDialog.types';


const UserBulkActionDialog = ({
  communities,
  projectId,
  bulkActionType,
  isOpen = false,
  onSave,
  handleCloseDialog,
  selectedIds,
}: UserBulkActionDialogProps) => {
  const { handleToastSuccess, handleToastError } = useToast();
  const token = getAccessTokenCookie();

  const [selectedCommunityId, setSelectedCommunityId] = useState<number | undefined>();
  const [userCommunityRole, setUserCommunityRole] = useState<DBUserConnectionRole>();



  const { handleBulkRemoveMembersFromCommunity, isLoading: isRemoveMemberByAuthorityLoading = false } =
  useBulkRemoveMembersFromCommunity({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) {
        return;
      }

      onSave();

      handleToastSuccess({
        message: 'Users successfully from community',
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId: String(projectId),
  });

  const { handleBulkUpdateCommunityRole, isLoading: isUpdateCommunityRoleLoading = false } = useBulkUpdateCommunityRole(
    {
      onCompleted: () => {
        onSave();

        handleToastSuccess({
          message: 'User role updated successfully',
        });
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
      projectId: String(projectId),
    },
  );

  const { handleBulkAddUserInCommunity, isLoading: isAddUserInCommunityLoading = false } = useBulkAddUserInCommunity({
    onCompleted: () => {
      onSave();

      handleToastSuccess({
        message: 'Users added to the community successfully',
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId: String(projectId),
  });

  const updateRole = () => {
    if (!userCommunityRole) return;
    handleBulkUpdateCommunityRole({
      communityId: Number(selectedCommunityId),
      userIds: selectedIds?.map((id) => Number(id)) ?? [],
      role: userCommunityRole,
    });
  }

  const connectUsers = () => {
    if (!userCommunityRole) return;
    handleBulkAddUserInCommunity({
      communityId: Number(selectedCommunityId),
      role: userCommunityRole,
      userIds: selectedIds?.map((id) => Number(id)) ?? [],
    });
  }

  const removeUsers = () => {
    handleBulkRemoveMembersFromCommunity({
      communityId: Number(selectedCommunityId),
      userIds: selectedIds?.map((id) => Number(id)) ?? [],
    });
  }

  const saveMap: { [key: string]: () => void} = {
    assign: updateRole,
    connect: connectUsers,
    remove: removeUsers
  }


  const handleSave = () => {
    const bulkHandler = saveMap[bulkActionType];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    bulkHandler();
  }

  const handleUpdateCommunityRoleInput = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    setUserCommunityRole(value as DBUserConnectionRole);
  };

  const handleUpdateCommunityInput = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    setSelectedCommunityId(Number(value));
  };

  const isLoading = isAddUserInCommunityLoading || isUpdateCommunityRoleLoading || isRemoveMemberByAuthorityLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <Dialog
        confirmText={USER_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[bulkActionType]}
        isOpen={isOpen}
        title={USER_BULK_ACTION_DIALOG_TITLE_MAP[bulkActionType]}
        onCancel={handleCloseDialog}
        onClose={handleCloseDialog}
        onConfirm={handleSave}
      >
        <Spacing size="x-large">
          <Select
            id="communities"
            labelText="Communities"
            name="communties"
            options={communities}
            value={selectedCommunityId}
            isRequired
            onChange={handleUpdateCommunityInput}
          />
        </Spacing>
        {bulkActionType !== 'remove' && (
          <Spacing size="large">
            <Select
              id="role"
              labelText="Role"
              name="role"
              options={[
                {
                  name: 'Owner',
                  value: 'OWNER',
                  id: 1,
                },
                {
                  name: 'Moderator',
                  value: 'MODERATOR',
                  id: 2,
                },
                {
                  name: 'Member',
                  value: 'MEMBER',
                  id: 3,
                },
              ]}
              tooltipText="User role in the community"
              value={userCommunityRole}
              isRequired
              onChange={handleUpdateCommunityRoleInput}
            />
          </Spacing>
        )}
      </Dialog>
    </>
  );
};

export { UserBulkActionDialog };
