import { useContext, useState } from 'react';

import { Button, Card, CustomRating, FlexContainer, SettingsButton, Skeleton, Spacing, Spinner, Toolbar } from '@netfront/ui-library';
import { AddAppToProjectSidebarView, AppCommentsSidebarView, AppTemplatePage } from 'components';
import { AppDetailsContext, DashboardContext } from 'context';
import { IDBAppRating } from 'interfaces';
import Link from 'next/link';


const AppDetailsPage = () => {

  const { appDetails, isLoading = false, latestVersionUrl = '', onUpdateApp } = useContext(AppDetailsContext);
  const { dashboardLink } = useContext(DashboardContext);
  const [isCommentSidebarOpen, setIsCommentSidebarOpen] = useState<boolean>(false);
  const [isAddToProjectSidebarOpen, setIsAddToProjectSidebarOpen] = useState<boolean>(false);
  const [selectedComment, setSelectedComment] = useState<IDBAppRating>()

  const { id, title = '', logo, description = '', averageRateValue = 0, rates = [], identifier = ''} = appDetails ?? {};
  const { presignedUrl} = logo ?? {}; 

  const handleSelectComment = (comment?: IDBAppRating) => {
    setIsCommentSidebarOpen(true);
    setSelectedComment(comment);
  };

  const handleUpdate = () => {
    setSelectedComment(undefined);
    setIsCommentSidebarOpen(false);
    setIsAddToProjectSidebarOpen(false);
    onUpdateApp();
  }

  return (
    <AppTemplatePage 
      activePageId="details"
      breadcrumbItems={[
        {
          key: '0',
          content: <Link href={`${String(dashboardLink)}/library/apps/marketplace`}>Market place</Link>,
        },
        {
          key: '1',
          content: 'App details',
        },
      ]}
      dashboardLink={`${String(dashboardLink)}/library/apps/marketplace`}
      dashboardLinkText="Marketplace"
      description={`App details for ${title}`}
      informationBoxMessage={
        <FlexContainer justifyContent="space-between">
          <span>App details for <strong>{title}</strong></span>
          <FlexContainer><strong>Overall rating</strong>: <CustomRating iconCount={1} name="overall-rating" rateValue={averageRateValue > 0 ? 1 : 0} isReadOnly/> {averageRateValue}</FlexContainer>
        </FlexContainer>
      }
      isLoading={isLoading}
      logoUrl={presignedUrl}
      pageTitle="App details" 
      size={presignedUrl ? 'small' : 'medium'} 
      title={String(title)}
    >
      <div style={{width: '100%'}}>
        <Spinner isLoading={isLoading} />
        <Spacing size="2x-large">
          <Toolbar
            childrenEnd={
              latestVersionUrl ? (
                <Link href={latestVersionUrl} passHref>
                  <Button
                    size="xs"
                    text="View code"
                    variant="secondary"
                    onClick={() => { return; }}
                  />
                </Link>
              ): undefined
            }
            childrenStart={(
              <>
                <Button
                  size="xs"
                  text="Add app to project"
                  onClick={() => setIsAddToProjectSidebarOpen(true)}
                />
                <Button
                  size="xs"
                  text="Add comment"
                  onClick={() => handleSelectComment(undefined)}
                />
              </>
            )}
          />
        </Spacing>
        {isLoading ? (
            <Skeleton height="13.625rem" />
        ): (
          <>
            <Spacing size="2x-large">
              <Card additionalClassNames="c-app-details-card" isLoading={isLoading} padding="small" title="Description" hasNoHover isFullWidth>
                <p>{description}</p>
              </Card>
            </Spacing>
            <div className="c-app-details__content">
              <div>
                <Spacing size="2x-small"><FlexContainer justifyContent="flex-start"><strong>Overall rating</strong>: <CustomRating iconCount={1} name="overall-rating" rateValue={averageRateValue > 0 ? 1 : 0} isReadOnly/> {averageRateValue}</FlexContainer></Spacing>
                <Spacing size="2x-small"><CustomRating name="overall-rating-5" rateValue={5} isReadOnly/></Spacing>
                <Spacing size="2x-small"><CustomRating name="overall-rating-4" rateValue={4} isReadOnly/></Spacing>
                <Spacing size="2x-small"><CustomRating name="overall-rating-3" rateValue={3} isReadOnly/></Spacing>
                <Spacing size="2x-small"><CustomRating name="overall-rating-2" rateValue={2} isReadOnly/></Spacing>
                <Spacing size="2x-small"><CustomRating name="overall-rating-1" rateValue={1} isReadOnly/></Spacing>
              </div>
              <div>
                {rates.length > 0 
                  ? rates.map((rateItem) => {
                    const { id: rateId, title: rateTitle, comment, rate } = rateItem;
                    return (
                      <Card 
                        key={rateId}
                        additionalClassNames="c-app-details-card" 
                        isLoading={isLoading} 
                        padding="small" 
                        settingsButton={
                          <FlexContainer gap="small">
                            <CustomRating name={`overall-rating-${rateId}`} rateValue={rate} isReadOnly/>
                            <SettingsButton supportiveText="Update comment" onClick={() => handleSelectComment(rateItem)} />
                          </FlexContainer>
                        } 
                        title={rateTitle} 
                        hasNoHover
                        isFullWidth
                      >
                        <p>{comment}</p>
                      </Card>
                    );
                  }) : (
                    <Card  padding="large" hasNoHover  isContentCentered isFullWidth>
                      <span>No comments available</span>
                    </Card>
                  )}
                
              </div>
            </div>
          </>
        )}
        
      </div>
      <AppCommentsSidebarView 
        appIdentifier={identifier}
        isSideBarOpen={isCommentSidebarOpen} 
        selectedComment={selectedComment}
        onCancel={() => {
          setIsCommentSidebarOpen(false);
          setSelectedComment(undefined);
        }} 
        onUpdate={handleUpdate} 
      />
      <AddAppToProjectSidebarView 
        appId={Number(id)}
        appIdentifier={identifier}
        isSideBarOpen={isAddToProjectSidebarOpen} 
        onCancel={() => setIsAddToProjectSidebarOpen(false)} 
        onUpdate={handleUpdate} 
      />
    </AppTemplatePage>
  );
};

export { AppDetailsPage };
