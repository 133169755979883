import { gql } from '@apollo/client';

const DELETE_APP_COMMENT_MUTATION = gql`
  mutation deleteAppComment($rateId: Int!) {
    app {
      deleteRate(rateId: $rateId)
    }
  }
`;


export { DELETE_APP_COMMENT_MUTATION };
