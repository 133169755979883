import { gql } from '@apollo/client';

const GET_TOPICS_QUERY = gql`
  query getPaginatedTopics(
    $after: String
    $first: Int
    $filter: String
    $projectId: String!
    $status: ETopicStatus
  ) {
    topic {
      getTopics(after: $after, first: $first, filter: $filter, projectId: $projectId, status: $status) {
        edges {
          cursor
          node {
            id
            title
            description
            status
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_TOPICS_QUERY };
