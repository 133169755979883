

import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_TRANSCRIPTS } from './useGetTranscripts.graphql';
import {
  IGetTranscriptsQueryGraphQLResponse,
  IGetTranscriptsQueryVariables,
  IHandleGetTranscriptsParams,
  IUseTranscripts,
  IUseTranscriptsOptions,
} from './useGetTranscripts.interfaces';



const useGetTranscripts = (options?: IUseTranscriptsOptions): IUseTranscripts => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseTranscriptsOptions);

  const [executeGetTranscripts, { loading: isLoading }] = useEkardoLazyQuery<
    IGetTranscriptsQueryGraphQLResponse,
    IGetTranscriptsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: { getContentPage: {
            bubbleTexts = []
          } },
        } = data;


        onCompleted({
          bubbleTexts,
        });
      },
      onError,
    },
    product,
    query: query ?? GET_TRANSCRIPTS,
    token,
  });

  const handleGetTranscripts = async ({
    entityId,
    type,
  }: IHandleGetTranscriptsParams) => {
    await executeGetTranscripts({
      variables: {
        entityId,
        type,
      },
    });
  };

  return {
    handleGetTranscripts,
    isLoading,
  };
};

export { useGetTranscripts };
