import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { useLinkEntitiesToApp, useToast, useUnlinkEntityFromApp } from 'hooks';



const useUpdateAppLinkedProjects = ({ onUpdate }: { onUpdate: () => void }) => {
  const chainedRequestsCompletedCountRef = useRef({ value: 0 });
  const chainedRequestsCountRef = useRef({ value: 0 });
  const unlinkProjectIdsRef = useRef<{ value: string[] }>({ value: []});
  const appIdentifierRef = useRef<{ value: string }>({ value: ''});
  const { handleToastError } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleUnlinkEntityFromAppOnCompleted = () => {
    chainedRequestsCompletedCountRef.current.value += 1;

    if (chainedRequestsCompletedCountRef.current.value === chainedRequestsCountRef.current.value) {
      onUpdate();
      chainedRequestsCompletedCountRef.current.value = 0;
      chainedRequestsCountRef.current.value = 0;
    }
  };

  const { handleLinkEntitiesToApp, isLoading: isLinkEntitiesToAppLoading = false } = useLinkEntitiesToApp({
    onCompleted: () => {
      if (unlinkProjectIdsRef.current.value.length > 0) {
        unlinkProjectIdsRef.current.value.forEach((id) => void handleUnlinkEntityFromApp({
            inputType: {
              appIdentifier: appIdentifierRef.current.value,
              entity: {
                entityType: 'PROJECT',
                entityId: id,
              }
            }
          })
        );
      } else {
        onUpdate();
      }
    },
    onError: handleGetError,
  });

  const { handleUnlinkEntityFromApp, isLoading: isUnlinkEntityFromAppLoading = false } = useUnlinkEntityFromApp({
    onCompleted: handleUnlinkEntityFromAppOnCompleted,
    onError: handleGetError,
  });

  

  const handleUpdateAppLinkedProjects = ({ appIdentifier, linkProjectIds = [], unlinkProjectIds = [] }: { appIdentifier: string; linkProjectIds?: string[], unlinkProjectIds?: string[] }) => {
    chainedRequestsCountRef.current.value = unlinkProjectIds.length;
    unlinkProjectIdsRef.current.value = unlinkProjectIds;
    appIdentifierRef.current.value = appIdentifier;

    if (linkProjectIds.length > 0) {
      handleLinkEntitiesToApp({
        inputType: {
          appIdentifier,
          entities: linkProjectIds.map((id) => ({
            entityId: id,
            entityType: 'PROJECT',
          })),
        }, 
      });
    } else {
      if (unlinkProjectIds.length > 0) {
        unlinkProjectIds.forEach((id) => void handleUnlinkEntityFromApp({
            inputType: {
              appIdentifier,
              entity: {
                entityType: 'PROJECT',
                entityId: id,
              }
            }
          })
        );
      } else {
        onUpdate();
      }
    }
  };

  return {
    isLoading:
      isLinkEntitiesToAppLoading ||
      isUnlinkEntityFromAppLoading,
    handleUpdateAppLinkedProjects,
  };
};
export { useUpdateAppLinkedProjects };
