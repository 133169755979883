import { AssetType, HollowDownloadIcon, IconRadioItemProps } from "@netfront/ui-library";

const getEmbedTypeOptions = (assetType: AssetType): IconRadioItemProps[] => {
  const options = [
    {
      icon: HollowDownloadIcon,
      label: 'Soundcloud',
      value: 'SOUNDCLOUD',
      isVisible: assetType === 'audio',
    },
    {
      icon: HollowDownloadIcon,
      label: 'Spotify',
      value: 'SPOTIFY',
      isVisible: assetType === 'audio',
    },
    // {
    //   icon: HollowDownloadIcon,
    //   label: 'iTunes',
    //   value: 'ITUNES',
    //   isVisible: assetType === 'audio',
    // },
    {
      icon: HollowDownloadIcon,
      label: 'YouTube',
      value: 'YOUTUBE',
      isVisible: assetType === 'video',
    },
    {
      icon: HollowDownloadIcon,
      label: 'Vimeo',
      value: 'VIMEO',
      isVisible: assetType === 'video',
    },
  ];

  return options.filter(({ isVisible }) => isVisible) as IconRadioItemProps[];
};

export { getEmbedTypeOptions };
