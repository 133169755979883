import React, { useEffect, useState } from 'react';

import { Spacing, Table, ButtonIconOnly, FlexContainer, InputFieldWrapper, BinIcon, CaretDownIcon, PlusIcon } from '@netfront/ui-library';
import { ITranscriptAnswer } from 'components';

import { TranscriptAnswersOverviewProps } from './TranscriptAnswersOverview.interfaces';




const TranscriptAnswersOverview = ({ onEditClick, onDeleteClick, transcriptAnswerItems = [] }: TranscriptAnswersOverviewProps) => {
  const [items, setItems] = useState<ITranscriptAnswer[]>([]);

  useEffect(() => {
    setItems(transcriptAnswerItems);
  }, [transcriptAnswerItems]);

  return (
    <>
      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              {
                accessor: 'text',
                Cell: ({ value }: { value: string }) => <span>{value}</span>,
                Header: () => <div>Text</div>,
                width: '70%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleDelete,
                    handleEdit,
                    id,
                  }
                }: {
                  value: {
                    handleDelete: (id: string) => void;
                    handleEdit: (id: string) => void;
                    id: string;
                  }
                }) => (
                  <FlexContainer gap="2x-small" justifyContent="end">
                    <ButtonIconOnly
                      additionalClassNames=""
                      icon={BinIcon}
                      isIconBorderVisible={false}
                      text="Delete transcript"
                      onClick={() => handleDelete(id)}
                    />
                    <ButtonIconOnly
                      additionalClassNames="h-rotate-right"
                      icon={CaretDownIcon}
                      isIconBorderVisible={false}
                      text="Edit transcript"
                      onClick={() => handleEdit(id)}
                    />
                  </FlexContainer>
                ),
              },
            ]}
            data={items.map((item) => ({
              text: item.text,
              id: item.tempId,
              settingsButtonData: {
                id: item.tempId,
                handleEdit: onEditClick,
                handleDelete: onDeleteClick,
              },
            }))}
          />
        </Spacing>
      )}

      <Spacing>
        <InputFieldWrapper
          id="id_add_answer"
          label="Add answer"
          labelType="span"
          type="custom"
          isLabelSideBySide
        >
          <FlexContainer justifyContent="flex-end" isFullWidth>
            <ButtonIconOnly icon={PlusIcon} text="Add answer" onClick={() => onEditClick(undefined)} />
          </FlexContainer>
        </InputFieldWrapper>
      </Spacing>
    </>
  );
};


export { TranscriptAnswersOverview };
