/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { IAppComponentFileDetails } from "components";
import { IDBAppComponent } from "interfaces";



const getAppComponentDetailsDefaultValues = (componentDetails: IDBAppComponent, componentFileDetails: IAppComponentFileDetails): FormFieldProps => {
  const { cssContent, htmlContent, jsContent } = componentFileDetails;
  const { title = '' } = componentDetails;

  return {
    title,
    cssContent, 
    htmlContent, 
    jsContent
  };
};


export { getAppComponentDetailsDefaultValues };