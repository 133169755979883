import { createContext } from 'react';

import { IAppDetailsContext } from './AppDetailsContext.interfaces';


export const AppDetailsContext = createContext<IAppDetailsContext>({
  appDetails: undefined,  
  appPageBaseUrl: '',
  isLoading: true,
  getNavigationItems: () => [],
  latestVersionUrl: '/',
  onUpdateApp: () => undefined,
  getAppVersion: () => undefined,
});

// eslint-disable-next-line import/no-default-export
export default AppDetailsContext;
