import { gql } from '@apollo/client';

const CREATE_TOPIC_ASSET_MUTATION = gql`
  mutation CreateAsset(
    $topicId: Int!
    $type: EAssetType!
    $fileName: String!
    $contentType: String!
    $alt: String
    $fileSizeInBytes: Int!
  ) {
    asset {
      createTopicAsset(
        asset: {
          topicId: $topicId
          type: $type
          fileName: $fileName
          contentType: $contentType
          fileSizeInBytes: $fileSizeInBytes
          alt: $alt
        }
      ) {
        signedUrl
        asset {
          assetId
        }
      }
    }
  }
`;

export { CREATE_TOPIC_ASSET_MUTATION };
