import { useContext, useState, useEffect } from 'react';

import { IApolloConnectionEdge, useCookie } from '@netfront/common-library';
import { IDropDownListOption } from '@netfront/ekardo-content-library';
import { IDBGroup, IDBUser, IUser, convertDBUser, useProtectedRoute, useSearchGroups } from '@netfront/gelada-identity-library';
import { KeyList, SelectWithSearch, ToggleSwitch } from '@netfront/ui-library';
import { ProgressTrackingUserDetailsSidebarView , TablePageTemplate } from 'components';
import last from 'lodash.last';
import { useRouter } from 'next/router';

// import { ProgressTrackingFilterTab } from 'components';

import { ProgressTrackingSubTable } from './Components';
import { PROGRESS_TRACKING_PAGE_SIZE, PROGRESS_TRACKING_TABLE_COLUMNS } from './ProgressTrackingPage.constants';
import { getProgressTrackingTableData, getProgressTrackingKeyItems } from './ProgressTrackingPage.helpers';
import { IProgressTrackingFilterOptions, IProgressTrackingTableData } from './ProgressTrackingPage.interfaces';

import { CachingEntitiesContext } from '../../../context';
import { useGetPaginatedProjectUsers, useGetUserProgressTracking, useToast } from '../../../hooks';
import { IDBProgressTracker } from '../../../interfaces';

const ProgressTrackingPage = () => {
  const { getAccessTokenCookie } = useCookie();
  const { isAuthenticated } = useProtectedRoute();
  const {
    query: { projectId: queryProjectId, userId: queryUserId },
  } = useRouter();
  const { handleToastError } = useToast();

  const { project } = useContext(CachingEntitiesContext);

  const [allProgressTrackings, setAllProgressTrackings] = useState<IDBProgressTracker[]>([]);
  const [communitiesTableData, setProgressTrackingTableData] = useState<IProgressTrackingTableData[]>([]);
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(PROGRESS_TRACKING_PAGE_SIZE);
  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [totalProgressTrackings, setTotalProgressTrackings] = useState<number>(0);
  // const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState<boolean>(false);
  const [isUserDetailsSidebarOpen, setIsUserDetailsSidebarOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [allUsers, setAllUsers] = useState<IDBUser[]>([]);
  const [groupDropdownListOptions, setGroupsDropdownItems] = useState<IDropDownListOption[]>();
  const [isGroupSearchContentVisible, setIsGroupSearchContentVisible] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<IProgressTrackingFilterOptions>({
    status: 'ACTIVE',
    groupId: undefined,
  });

  const token = getAccessTokenCookie();


  const { handleSearchGroups, isLoading: isSearchGroupsLoading = false } = useSearchGroups({
    onCompleted: ({ groups }) => {
      const groupsSelectSearchList = groups.map(
        ({ id, name }): IDropDownListOption => ({
          id: String(id),
          label: name,
          value: String(id),
        }),
      );

      setGroupsDropdownItems(
          [
              {
                id: '0',
                label: 'All groups',
                value: '0',
              },
              ...groupsSelectSearchList,
          ]

      );
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });


  const {
    handleGetUserProgressTracking,
    isLoading: isGetUserProgressTrackingLoading = false,
  } = useGetUserProgressTracking({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ progressTrackings }) => {

      const formattedProgressTrackings = progressTrackings.map((item) => {
        const { activeGroups, isProjectAdmin = false, permission } = allUsers.find(({id: userId})=> userId === item.id) ?? {};

        const groups: string = activeGroups?.edges.map((r: IApolloConnectionEdge<IDBGroup>) => r.node.name).join(',') ?? '';
        return {
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          isProjectAdmin,
          emailCount: 0,
          group: isProjectAdmin ? 'Admin, All groups' : groups,
          email: item.email,
          dateRegistered: item.created,
          permission,
          userFlowProgress: item.userFlowProgress ?? []
        };
      });

      setAllProgressTrackings(formattedProgressTrackings);
    },
    token,
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const {
    handleFetchMorePaginatedProjectUsers,
    handleGetPaginatedProjectUsers,
    isLoading: isGetPaginatedProjectUsersLoading = false,
  } = useGetPaginatedProjectUsers({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ users: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const users = edges.map(({ node }) => node);

      const userIds = users.map(({id}) => id);

      handleGetUserProgressTracking({
        userIds
      });

      setAllUsers(users);
      setIsLoadMoreDisabled(users.length >= totalCount || totalCount <= pageSize);
      setTotalProgressTrackings(totalCount);
    },
    token,
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };


  const handleFilterSearch = (value: string) => {
    // void handleGetPaginatedProjectUsers({
    //   filter: value,
    //   projectId: String(projectId),
    //   first: pageSize,
    //   ...filterOptions
    // });

    setFilter(value);
  };

  // const handleSideBarClose = () => {
  //   setIsFilterSidebarOpen(false);
  // };

  const handleUpdateFilters = (updatedFilters: IProgressTrackingFilterOptions) => {
    // handleSideBarClose();
    void handleGetPaginatedProjectUsers({
      filter,
      first: pageSize,
      projectId: String(projectId),
      ...updatedFilters,
    });
    setFilterOptions(updatedFilters);
  };

  const handleViewUser = (id: number) => {
    const user = allUsers.find(({id: userId}) => userId === id);
    if (user) {
      setSelectedUser(convertDBUser(user));
      setIsUserDetailsSidebarOpen(true);
    }
  };

  const handleCloseUserDetailsSidebar = () => {
    setSelectedUser(undefined);
    setIsUserDetailsSidebarOpen(false);
  };

  useEffect(() => {
    if (!(isAuthenticated && projectId)) {
      return;
    }

    void handleSearchGroups({
      projectId: String(projectId),
    });

    void handleGetPaginatedProjectUsers({
      filter,
      first: pageSize,
      projectId,
      ...filterOptions,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, projectId, pageSize, filter]);

  useEffect(() => {
    if (!(isAuthenticated && projectId)) {
      return;
    }

    void handleSearchGroups({
      projectId: String(projectId),
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isAuthenticated]);

  useEffect(() => {
    setProgressTrackingTableData(
      getProgressTrackingTableData({
        progressTrackings: allProgressTrackings,
        onViewUserClick: handleViewUser
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProgressTrackings]);

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;

    setProjectName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    if (queryUserId) {
      setFilter(queryUserId as string);
    }
  }, [queryUserId]);

  const isLoading = isGetPaginatedProjectUsersLoading || isGetUserProgressTrackingLoading || isSearchGroupsLoading;

  const {
    status,
    groupId,
  } = filterOptions;

  if (projectName)
    return (
      <>
        <TablePageTemplate<IProgressTrackingTableData>
          activePage="progress-tracking"
          activeSubPage="progress-tracking"
          additionalBreadcrumbItems={[
            {
              key: '1',
              content: 'Progress tracking',
            },
          ]}
          childrenEnd={
            <ToggleSwitch
              id="user-status-filter"
              isChecked={status === 'INACTIVE'}
              labelText="Show inactive"
              isInline
              onChange={() =>
                handleUpdateFilters({
                  ...filterOptions,
                  status: status === 'ACTIVE' ? 'INACTIVE': 'ACTIVE'
                })
              }
            />
            // <ButtonIconOnly
            //   icon={ThreeDotsIcon}
            //   text="Filter"
            //   onClick={() => setIsFilterSidebarOpen(true)}
            // />
          }
          childrenMiddle={
            <SelectWithSearch
              additionalClassNames="c-select-with-search__users-table"
              buttonText={groupDropdownListOptions?.find(({ id }) => Number(id) === Number(groupId))?.label ?? 'All groups'}
              hasPadding={false}
              id="id_progress_tracking"
              isDisplaySearchContent={isGroupSearchContentVisible}
              labelText="Select Group"
              searchList={groupDropdownListOptions ?? []}
              isAvatarVisible
              isLabelHidden
              onDisplaySearchContent={() => setIsGroupSearchContentVisible(!isGroupSearchContentVisible)}
              onSearchItemClick={(id: string | number) =>
                handleUpdateFilters({
                  ...filterOptions,
                  groupId: Number(id),
                })
              }
            />
          }
          columns={PROGRESS_TRACKING_TABLE_COLUMNS}
          data={communitiesTableData}
          defaultActiveTabId="id_general_tab"
          description={`Progress tracking for ${projectName} project`}
          handleOnPageSizeChange={handlePageSizeChange}
          handleOnPaginate={async () => {
            await handleFetchMorePaginatedProjectUsers({
              after: lastCursor,
              first: pageSize,
              filter,
              projectId: String(projectId),
              ...filterOptions,
            });
          }}
          handleSearch={handleFilterSearch}
          informationBoxMessage={<>
            <p style={{margin: '0'}}>Manage <strong>{projectName}</strong> progress tracking</p>
            <KeyList items={getProgressTrackingKeyItems()}/>
          </>}
          initialSearchValue={filter}
          isLoading={isLoading}
          isPaginationDisabled={isLoadMoreDisabled}
          pageSize={pageSize}
          pageTitle={projectName}
          renderSubComponent={({ row }) => <ProgressTrackingSubTable data={row.values as IProgressTrackingTableData} />}
          searchPlaceholder="Name"
          // isSideBarOpen={isFilterSidebarOpen}
          tableType="progress tracking"
          // tabs={[
          //   {
          //     icon: GeneralTabIcon,
          //     id: 'id_general_tab',
          //     label: 'Filters',
          //     view: () => (
          //       <ProgressTrackingFilterTab filterOptions={filterOptions} isSidebarOpen={isFilterSidebarOpen} onCancel={handleSideBarClose}  onUpdate={handleUpdateFilters}/>
          //     ),
          //   },
          // ]}
          title={`${projectName} progress tracking dashboard`}
          // toggleIsSideBarOpen={handleSideBarClose}
          totalItems={totalProgressTrackings}
        />
        <ProgressTrackingUserDetailsSidebarView isSidebarOpen={isUserDetailsSidebarOpen} user={selectedUser} onClose={handleCloseUserDetailsSidebar}/>
      </>
    );

  return <></>;
};

export { ProgressTrackingPage };
