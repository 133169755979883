import { useContext, useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { useProtectedRoute } from '@netfront/gelada-identity-library';
import noop from 'lodash.noop';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { ORDERS_TABLE_COLUMNS } from './OrdersPage.constants';
import { getOrdersTableData } from './OrdersPage.helpers';
import { IOrdersTableData } from './OrdersPage.interfaces';

import { TablePageTemplate } from '../../../../components';
import { CachingEntitiesContext, DashboardContext } from '../../../../context';
import { useGetCompletedOrdersForProject, useToast } from '../../../../hooks';
import { IDBOrder } from '../../../../interfaces';

const OrdersPage = () => {
  const { getAccessTokenCookie } = useCookie();
  const { isAuthenticated } = useProtectedRoute();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const { dashboardLink } = useContext(DashboardContext);
  const { project } = useContext(CachingEntitiesContext);

  const [allOrders, setAllOrders] = useState<IDBOrder[]>([]);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [ordersTableData, setOrdersTableData] = useState<IOrdersTableData[]>([]);
  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [, setSelectedOrder] = useState<IDBOrder>();

  const token = getAccessTokenCookie();

  const { handleGetCompletedOrdersForProject, isLoading: isCompletedOrdersForProjectLoading = false } = useGetCompletedOrdersForProject({
    onCompleted: ({ ordersConnection: orders }) => {
      setAllOrders(orders);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    void handleGetCompletedOrdersForProject({
      projectGuid: String(projectId),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, projectId]);

  useEffect(() => {
    setOrdersTableData(
      getOrdersTableData({
        orders: allOrders,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedOrder(allOrders.find(({ id: orderId }) => id === orderId));
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrders]);

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;

    setProjectName(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <TablePageTemplate<IOrdersTableData>
      activePage="subscriptions"
      activeSubPage="orders"
      additionalBreadcrumbItems={[
        {
          key: '1',
          content: <Link href={`${String(dashboardLink)}/subscriptions`}><span>Subscriptions</span></Link>,
        },
        {
          key: '2',
          content: 'Orders',
        },
      ]}
      columns={ORDERS_TABLE_COLUMNS}
      data={ordersTableData}
      description={`Orders for ${projectName} project`}
      handleSearch={noop}
      informationBoxMessage={`Manage ${projectName} orders`}
      isLoading={isCompletedOrdersForProjectLoading}
      isSideBarOpen={isSideBarOpen}
      pageTitle={projectName}
      tableType="orders"
      title={`${projectName} orders page`}
      toggleIsSideBarOpen={noop}
    />
  );
};

export { OrdersPage };
