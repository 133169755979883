import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, SidebarButtons, FormFieldProps, Spacing, Spinner, useControlledForm, IBreadcrumbItem, DropzoneFileUpload, InputFieldWrapper } from '@netfront/ui-library';
import { BulkUploadOverview, ContentBuilderTabWrapper, SidebarContainer } from 'components';
import { useUpsertAssetWrapper, useUpsertPage } from 'hooks';
import { Control, Controller } from 'react-hook-form';
import { generateRandomId } from 'utils';
import * as yup from 'yup';

import { createSlideTitle, createSlideUrl } from './BulkUploadCartoonSlides.helpers';
import { BulkUploadCartoonSlidesProps, IUploadedCartoonPage } from './BulkUploadCartoonSlides.interfaces';


const Wrapper = ({additionalBreadcrumbs = [], children, hasContainer = false} : { additionalBreadcrumbs?: IBreadcrumbItem[]; children: ReactNode; hasContainer?: boolean }) => {
  if (hasContainer) return (
    <ContentBuilderTabWrapper additionalBreadcrumbs={additionalBreadcrumbs} hasSnippetTypeBreadcrumb={false} isSnippet={false}>
      {children}
    </ContentBuilderTabWrapper>
  );

  return <SidebarContainer>{children}</SidebarContainer>;
}

const BulkUploadCartoonSlides = ({ onCancel, onSave, projectId, additionalBreadcrumbs = [], hasContainer = false, contentGroupId, parentId }: BulkUploadCartoonSlidesProps) => {
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({
    uploadedFiles: [],
  });

  const uploadedFilesRef = useRef<{ value: number}>({ value: 0});

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset, getValues, setValue, watch} = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        uploadedFiles: yup.array().required().min(1, 'Please provide at least one slide'),
      }),
    ),
  });

  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();


  const { handleUpsertPage, isLoading: isUpsertPageLoading = false } = useUpsertPage({
    onCreate: (returnedContentPage) => {
      const { uploadedFiles = [] } = getValues();

      uploadedFilesRef.current.value += 1;

      const nextUpload = uploadedFiles[uploadedFilesRef.current.value]

      if (nextUpload) {
        const { title, url, uploadedFile } = nextUpload;
        handleUpsertAsset({
          assetType: 'image',
          isCreate: true,
          projectId: String(projectId),
          uploadedFile: uploadedFile,
          isAnimatedImage: false,
          callBack: (imageAsset?: IDBAsset) => {
            handleUpsertPage({
              contentGroupId,
              variables: {
                parentId,
                title,
                url,
                assetId: imageAsset ? String(imageAsset.assetId): undefined,
                sort: uploadedFilesRef.current.value,
              },
            });
    
          }
        });
      } else {
        reset();
        onCancel();
        onSave(returnedContentPage);
      }      
    },
    onUpdate: () => { return; }
  });

  const handleDrop = (uploadedFiles: File[]) => {
    const formattedFiles: IUploadedCartoonPage[] = uploadedFiles.map((uploadedFile) => {
      const { name } = uploadedFile;
      const id = generateRandomId();
      return {
        id: generateRandomId(),
        title: createSlideTitle(name),
        url: createSlideUrl(name, id),
        uploadedFile,
        previewUrl: URL.createObjectURL(uploadedFile),
      };
    });

    const { uploadedFiles: currentUploadedFiles = []} = getValues();

    const updatedFiles = [
      ...currentUploadedFiles,
      ...formattedFiles,
    ];
    setValue('uploadedFiles', updatedFiles);
  };


  const removePage = (id: string) => {
    const { uploadedFiles: currentUploadedFiles = []} = getValues();

    const updatedFiles = (currentUploadedFiles  as IUploadedCartoonPage[]).filter(({id: fileId }) => fileId !== id);

    setValue('uploadedFiles', updatedFiles);
  };

  const handleSave = (item: FormFieldProps) => {

    const { uploadedFiles = [] } = item;

    uploadedFilesRef.current.value = 0;

    // eslint-disable-next-line prefer-destructuring
    const { uploadedFile, title, url } = uploadedFiles[0];
    handleUpsertAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(projectId),
      uploadedFile: uploadedFile,
      isAnimatedImage: false,
      callBack: (imageAsset?: IDBAsset) => {
        handleUpsertPage({
          contentGroupId,
          variables: {
            parentId,
            title,
            url,
            assetId: imageAsset ? String(imageAsset.assetId): undefined,
            sort: 0,
          },
        });

      }
    });

  };

  useEffect(() => {
    setDefaultValues({
      uploadedFiles: []
    });

    // setLocalUploadedFiles([]);
  }, [parentId, contentGroupId])

  const isLoading = isUpsertAssetLoading || isUpsertPageLoading;

  return (
    <Wrapper additionalBreadcrumbs={additionalBreadcrumbs} hasContainer={hasContainer}>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        <Spinner isLoading={isLoading} />
        <Spacing size="small">
          <Controller
            control={control as Control<FormFieldProps>}
            name="uploadedFiles"
            render={({ field, fieldState }) => (
              <InputFieldWrapper 
                id="id_bulk_upload_slides" 
                label="Upload" 
                labelType="span"
                message={{ success: '', error: getFormFieldErrorMessage(fieldState)}}
                isLabelSideBySide
              >
                <DropzoneFileUpload
                  fileType="image"
                  labelText="Upload"
                  name={field.name}
                  tooltipText="Upload your slides"
                  isAcceptMultipleFiles
                  isLabelHidden
                  onDrop={handleDrop}
                />
              </InputFieldWrapper>
            )}
          />
        </Spacing>

        <BulkUploadOverview watch={watch} onDelete={removePage}/>
          
        <SidebarButtons
          onCancel={onCancel}
          onSaveButtonType="submit"
        />


      </ControlledForm>
    </Wrapper>
  );
};

export { BulkUploadCartoonSlides };
