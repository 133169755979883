import { useContext, useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { useProtectedRoute } from '@netfront/gelada-identity-library';
import last from 'lodash.last';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { CommunitySidebarView } from 'components/SidebarViews/CommunitySidebarView';

import { COMMUNITIES_PAGE_SIZE, COMMUNITIES_TABLE_COLUMNS } from './CommunitiesPage.constants';
import { getCommunitiesTableData } from './CommunitiesPage.helpers';
import { ICommunitiesTableData } from './CommunitiesPage.interfaces';

import { TablePageTemplate } from '../../../../components';
import { CachingEntitiesContext, DashboardContext } from '../../../../context';
import { useGetPaginatedProjectCommunities, useToast } from '../../../../hooks';
import { IDBCommunity } from '../../../../interfaces';

const CommunitiesPage = () => {
  const { getAccessTokenCookie } = useCookie();
  const { isAuthenticated } = useProtectedRoute();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const { project } = useContext(CachingEntitiesContext);
  const { dashboardLink } = useContext(DashboardContext);

  const [allCommunities, setAllCommunities] = useState<IDBCommunity[]>([]);
  const [communitiesTableData, setCommunitiesTableData] = useState<ICommunitiesTableData[]>([]);
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(COMMUNITIES_PAGE_SIZE);
  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [selectedCommunityId, setSelectedCommunityId] = useState<number>();
  const [totalCommunities, setTotalCommunities] = useState<number>(0);

  const token = getAccessTokenCookie();

  const {
    handleFetchMorePaginatedProjectCommunities,
    handleGetPaginatedProjectCommunities,
    isLoading: isGetPaginatedProjectCommunitiesLoading = false,
  } = useGetPaginatedProjectCommunities({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ communityConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const communities = edges.map(({ node }) => node);

      setAllCommunities(communities as unknown as IDBCommunity[]);
      setIsLoadMoreDisabled(communities.length >= totalCount || totalCount <= pageSize);
      setTotalCommunities(totalCount);
    },
    projectId: String(projectId),
    token,
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleAddNewCommunityClick = () => {
    setSelectedCommunityId(undefined);
    setIsSideBarOpen(true);
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };


  const handleFilterSearch = (value: string) => {
    void handleGetPaginatedProjectCommunities({
      first: pageSize,
      filter: value,
      projectId: String(projectId),
    });

    setFilter(value);
  };

  const handleSideBarClose = () => {
    setSelectedCommunityId(undefined);
    setIsSideBarOpen(false);
  };

  const handleUpdate = () => {
    handleSideBarClose();
    void handleGetPaginatedProjectCommunities({
      filter,
      first: pageSize,
      projectId: String(projectId),
    });
  };

  useEffect(() => {
    if (!(isAuthenticated && projectId)) {
      return;
    }

    void handleGetPaginatedProjectCommunities({
      filter,
      first: pageSize,
      projectId: String(projectId),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, projectId, pageSize]);

  useEffect(() => {
    setCommunitiesTableData(
      getCommunitiesTableData({
        communities: allCommunities,
        onSettingsButtonClick: (id) => {
          setSelectedCommunityId(id);
          setIsSideBarOpen(true);
        },
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCommunities]);

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;

    setProjectName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  if (projectName)
    return (
      <>
        <TablePageTemplate<ICommunitiesTableData>
          activePage="social"
          activeSubPage="communities"
          additionalBreadcrumbItems={[
            {
              key: '1',
              content: <Link href={`${String(dashboardLink)}/social`}><span>Social</span></Link>,
            },
            {
              key: '2',
              content: 'Communities',
            },
          ]}
          columns={COMMUNITIES_TABLE_COLUMNS}
          data={communitiesTableData}
          defaultActiveTabId="id_general_tab"
          description={`Communities for ${projectName} project`}
          handleAddNewClick={handleAddNewCommunityClick}
          handleOnPageSizeChange={handlePageSizeChange}
          handleOnPaginate={async () => {
            await handleFetchMorePaginatedProjectCommunities({
              after: lastCursor,
              first: pageSize,
              filter,
              projectId: String(projectId),
            });
          }}
          handleSearch={handleFilterSearch}
          informationBoxMessage={`Manage ${projectName} communities`}
          isLoading={isGetPaginatedProjectCommunitiesLoading}
          isPaginationDisabled={isLoadMoreDisabled}
          isSideBarOpen={isSideBarOpen}
          pageSize={pageSize}
          pageTitle={projectName}
          tableType="communities"
          title={`${projectName} communities dashboard`}
          totalItems={totalCommunities}
        />
        <CommunitySidebarView
          handleSideBarClose={handleSideBarClose}
          isSideBarOpen={isSideBarOpen}
          projectId={projectId}
          selectedCommunityId={selectedCommunityId}
          onUpdate={handleUpdate}
        />
      </>
    );

  return <></>;
};

export { CommunitiesPage };
