import { createContext } from 'react';

import { IGeladaProject, IGeladaOrganisation } from '@netfront/gelada-identity-library';
import { IBonoboProject } from 'interfaces';

export interface ICachingEntitiesContext {
  bonoboProject: IBonoboProject | undefined;
  contentGroupTypes: string[] | undefined;
  isLoading?: boolean;
  organisation: IGeladaOrganisation | undefined;
  project: IGeladaProject | undefined;
}

export const CachingEntitiesContext = createContext<ICachingEntitiesContext>({
  contentGroupTypes: undefined,
  organisation: undefined,
  project: undefined,
  bonoboProject: undefined,
  isLoading: true,
});

// eslint-disable-next-line import/no-default-export
export default CachingEntitiesContext;
