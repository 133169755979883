import React, { ReactNode } from 'react';

import { DBContentGroupTypeType } from '@netfront/ekardo-content-library';
import { Button, CloseIcon, EnterIcon } from '@netfront/ui-library';
import { ContentBuilderBetaToggle } from 'components';
import Link from 'next/link';


const ContentBuilderToolbar = ({ additionalToolbarItems, contentGroupId, contentType, dashboardLink, handleTestMode, isTestMode = false }: {additionalToolbarItems?: ReactNode; contentGroupId?: number; contentType?: DBContentGroupTypeType;  dashboardLink: string; handleTestMode?: () => void; isTestMode?: boolean }) => {
  return (
    <div className="c-content-builder-toolbar">
      <Link href={dashboardLink} passHref>
        <span className="c-content-builder-toolbar__dashboard-link">
            <span className="c-content-builder-toolbar__icon-container">
              <EnterIcon className="c-icon c-content-builder-toolbar__icon"/>
            </span>
            <span className="c-content-builder-toolbar__text">Dashboard</span>
        </span>
      </Link>
      <div className="c-content-builder-toolbar__items">
        <div className="c-content-builder-toolbar__item">
          {additionalToolbarItems}
        </div>
        <div className="c-content-builder-toolbar__item">
          <span className="h-hide-visually">View selector goes here</span>
        </div>
        <div className="c-content-builder-toolbar__item">
          
          <ContentBuilderBetaToggle contentGroupId={contentGroupId} contentType={contentType} />
          {contentType === 'CARTOON' && handleTestMode && (
            <Button
              additionalClassNames={`c-content-builder__edit-button ${isTestMode ? 'is-active': ''}`}
              icon={isTestMode ? CloseIcon : EnterIcon}
              size="xs" 
              text={`${isTestMode ? 'Exit test': 'Test'} mode`}
              type="button"
              variant="primary-inverse"
              onClick={handleTestMode}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { ContentBuilderToolbar };
