import { gql } from '@apollo/client';

const GET_LINKED_PROJECTS = gql`
  query getAppLinkedProjects($appId: Int!) {
    app {
      authorisedProjects(appId: $appId) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export { GET_LINKED_PROJECTS };
