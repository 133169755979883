import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { IContentPage } from '@netfront/ekardo-content-library';
import { AudioTabIcon, ControlledForm, Dialog, FormFieldProps, GeneralTabIcon, NavigationTabIcon, RelatedTabIcon, SeoTabIcon, SideBar, SidebarButtons, SlideIcon, Spinner, StyleTabIcon, TabSet, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { AssetTagsTab, CartoonPageTypeSelector, TranscriptTab, UpsertPageSeoTab, UpsertPageAudioTab, handleSelectAsset, UpsertPageNavigationTab, SidebarContainer, UpsertCartoonPageSlidesTab, StyleTab, hasAssetBeenUpdated } from 'components';
import { useUpdatePageStatus, useGetContentPageDetails, useToast, useUpsertAssetWrapper as useUpsertImageAssetWrapper, useUpsertAssetWrapper as useUpsertAudioAssetWrapper, useUpsertPage } from 'hooks';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getCartoonPageCommonVariables, getCartoonPageDefaultValues } from './CartoonPageSidebarView.helpers';
import { ICartoonPageSidebarProps } from './CartoonPageSidebarView.interfaces';


const Wrapper = ({children, isFromCartoonTab = false, isOpen = false, onClose }: { children: ReactNode; isFromCartoonTab?: boolean; isOpen?: boolean; onClose: () => void; }) => {

  if (isFromCartoonTab) return <>{children}</>;

  return (
    <SideBar
      isSideBarOpen={isOpen}
      isCloseButtonVisible
      onClose={onClose}
    >
      {children}
    </SideBar>
  )

}

const CartoonPageSidebarView = ({ 
  projectId,
  isPageSidebarOpen = false,
  pageSidebarDetails,
  handleDeletePage,
  handleUpdatePage,
  handleCreatePage,
  closePageSidebar,
  contentType,
  handleRefreshPage,
  setPageSidebarDetails,
  isFromCartoonTab = false,
  idPrefix = 'main',
}: ICartoonPageSidebarProps) => {
  
  const { handleToastError } = useToast();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const droppedFileAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const droppedFileAudioAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);
  const [isResetState, setIsResetState] = useState<boolean>(true);
  const [isCreateSceneSlide, setIsCreateSceneSlide] = useState<boolean>(false);
  const [hasCreatedSceneSlide, setHasCreatedSceneSlide] = useState<boolean>(false);
  const [slides, setSlides] = useState<IContentPage[]>([]);

  const { pageId, sort, contentGroupId, cartoonType, parentId } = pageSidebarDetails ?? {};

  const { control, handleSubmit, reset, setValue, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        pageTitle: yup.string().label('Title').required(),
        url: yup.string().label('Url').required(),
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetContentPageDetails, isLoading: isGetContentPageDetailsLoading = false } = useGetContentPageDetails({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentPage }) => {

      const { contentPageChildren = []} = contentPage;

      setSlides(contentPageChildren);

      if (isResetState) setDefaultValues(getCartoonPageDefaultValues({ page: contentPage, cartoonType }));

      setIsResetState(false);

    },
    onError: handleGetError,
  });

  const { handleUpsertPage, isLoading: isUpsertPageLoading = false } = useUpsertPage({
    onCreate: (returnedContentPage) => {
      reset();
      if (cartoonType === 'scene' && isCreateSceneSlide) {
        setIsCreateSceneSlide(false);
        setHasCreatedSceneSlide(true);
        const { id, title, url, seoDescription, seoTitle } = returnedContentPage;
        handleUpsertPage({
          contentGroupId,
          variables: {
            parentId: id,
            title: `${title} slide`,
            url: `${url}-slide`,
            seoDescription,
            seoTitle,
          },
        });
      } else {
        // keep sidebar open if they've uploaded an image for the first time or they've first created a scene
        if ((droppedFileAssetRef.current.value || hasCreatedSceneSlide) && !pageId) {
          setHasCreatedSceneSlide(false);
          setPageSidebarDetails({
            ...pageSidebarDetails,
            pageId: returnedContentPage.id,
          });
          droppedFileAssetRef.current.value = undefined;
        } else {
          closePageSidebar();
        }
        handleCreatePage(returnedContentPage);
      }
      
    },
    onUpdate: (returnedContentPage) => {
      if (droppedFileAssetRef.current.value && !defaultValues.imagePresignedUrl) {
        setDefaultValues(getCartoonPageDefaultValues({ page: returnedContentPage, cartoonType }));
      } else {
        reset();
        closePageSidebar();
      }
      handleUpdatePage(returnedContentPage);
    }
  });

  const { handleUpdatePageStatus, isLoading: isUpdatePageStatusLoading = false } = useUpdatePageStatus({
    onCompleted: () => {
      closePageSidebar();
      setIsDeleteDialogOpen(false);
      handleDeletePage(Number(pageId));
    },
    onError: handleGetError,
  });

  const { handleUpsertAsset: handleUpsertImageAsset, isLoading: isUpsertImageAssetLoading = false } = useUpsertImageAssetWrapper();
  
  const { handleUpsertAsset: handleUpsertAudioAsset, isLoading: isUpsertAudioAssetLoading = false } = useUpsertAudioAssetWrapper();

  const onUpdateCartoonSlides = () => {
    handleRefreshPage?.();
    setIsResetState(false);
    void handleGetContentPageDetails({
      contentPageId: Number(pageId)
    });
  }

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };

  const handleDropAudioFile = (uploadedFile?: File) => {
    droppedFileAudioAssetRef.current.value = uploadedFile;
  };

  const handleSelectAssetFromLibrary = ({ asset }: {asset: IDBAsset }) => {
    handleSelectAsset({asset, assetIdKey: 'audioAssetId', setValue });
    droppedFileAudioAssetRef.current.value = undefined;
  };

  const handleDelete = () => {
    if (!pageId) return;
    handleUpdatePageStatus({
      contentPageId: Number(pageId),
      status: 'DELETED',
    });
  };

  const handleSave = (item: FormFieldProps) => {

    const { imageAssetId, isAnimatedImage = false } = item;

    setIsCreateSceneSlide(!pageId && cartoonType === 'scene');

    handleUpsertImageAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(projectId),
      uploadedFile: droppedFileAssetRef.current.value,
      deletedFileId: hasDeletedOriginalImageRef.current.value && imageAssetId ? imageAssetId : undefined,
      isAnimatedImage,
      callBack: (imageAsset?: IDBAsset) => {

        handleUpsertAudioAsset({
          assetType: 'audio',
          isCreate: Boolean(droppedFileAudioAssetRef.current.value),
          projectId: String(projectId),
          uploadedFile: droppedFileAudioAssetRef.current.value,
          assetIdKey: 'audioAssetId',
          hasPublicToggle: true,
          isPublic: item.isPublic,
          isInitiallyPublic: defaultValues.isPublic,
          updatedAsset: hasAssetBeenUpdated({ assetIdKey: 'audioAssetId', initialValues: defaultValues, currentValues: item }) ? item : undefined,
          callBack: (audioAsset?: IDBAsset) => {
            const commonVariables = getCartoonPageCommonVariables({
              uploadedAssetId: imageAsset ? String(imageAsset.assetId): undefined,
              uploadedAudioAssetId: audioAsset ? String(audioAsset.assetId): undefined,
              sort: Number(sort ?? 0),
              item,
              parentId,
            });

            handleUpsertPage({
              contentPageId: pageId,
              contentGroupId,
              variables: commonVariables,
            });
          }
        });
      }
    });
  };


  useEffect(() => {
    if (!pageId) {
      setDefaultValues(getCartoonPageDefaultValues({ cartoonType }));
    } else {
      setIsResetState(true);
      void handleGetContentPageDetails({
        contentPageId: pageId
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, pageSidebarDetails]);


  const isLoading = isUpsertImageAssetLoading || isUpsertAudioAssetLoading || isUpsertPageLoading || isGetContentPageDetailsLoading || isUpdatePageStatusLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation={isFromCartoonTab}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >

        <Wrapper 
          isFromCartoonTab={isFromCartoonTab}
          isOpen={isPageSidebarOpen}
          onClose={() => {
            reset();
            closePageSidebar();
          }}
        >
          {isPageSidebarOpen && (
            <TabSet
              ref={tabsetRef}
              defaultActiveTabId={`id_general_${idPrefix}_tab`}
              hasViewPadding={false}
              tabs={[
                {
                  icon: GeneralTabIcon,
                  id: `id_general_${idPrefix}_tab`,
                  label: 'General',
                  view: () => (
                    <CartoonPageTypeSelector
                      closePageSidebar={closePageSidebar}
                      contentType={contentType}
                      control={control as Control<FormFieldProps>}
                      getValues={getValues}
                      initialTitle={defaultValues.pageTitle}
                      isFromCartoonTab={isFromCartoonTab}
                      pageSidebarDetails={pageSidebarDetails}
                      projectId={projectId}
                      setPageSidebarDetails={setPageSidebarDetails}
                      setValue={setValue}
                      onBulkUpload={isFromCartoonTab ? onUpdateCartoonSlides:  (returnedContentPage: IContentPage) => {
                        reset();
                        closePageSidebar();
                        handleCreatePage(returnedContentPage);
                      }}
                      onDeleteAsset={handleRemoveAsset}
                      onDrop={handleDropFile}
                    />
                  ),
                },
                {
                  icon: SlideIcon,
                  id: `id_cartoons_${idPrefix}_tab`,
                  label: 'Cartoons',
                  isHidden: !pageId || cartoonType !== 'scene',
                  view: () => cartoonType === 'scene' ? (
                    <UpsertCartoonPageSlidesTab 
                      contentGroupId={Number(contentGroupId)} 
                      pageId={Number(pageId)} 
                      projectId={projectId} 
                      slides={slides}
                      onUpdate={onUpdateCartoonSlides}                
                    />
                  ) : <></>,
                },
                {
                  icon: SeoTabIcon,
                  id: `id_seo_${idPrefix}_tab`,
                  label: 'SEO',
                  view: () => (
                    <UpsertPageSeoTab
                      control={control as Control<FormFieldProps>}
                    />
                  ),
                },
                {
                  icon: AudioTabIcon,
                  id: `id_audio_${idPrefix}_tab`,
                  label: 'Audio',
                  view: () => (
                    <UpsertPageAudioTab
                      control={control as Control<FormFieldProps>}
                      defaultValues={defaultValues}
                      initialView={defaultValues.view}
                      isCreate={!pageId}
                      projectId={projectId}
                      setValue={setValue}
                      onChooseFromLibrary={handleSelectAssetFromLibrary}
                      onDrop={handleDropAudioFile}
                    />
                  ),
                },
                {
                  icon: RelatedTabIcon,
                  id: `id_transcript_${idPrefix}_tab`,
                  label: 'Transcript',
                  isHidden: (!pageId || !defaultValues.imageAssetId) || (!cartoonType || cartoonType === 'scene'),
                  view: () => (
                    <TranscriptTab
                      assetId={defaultValues.imageAssetId}
                      entityId={Number(pageId)}
                      handleRefreshPage={handleRefreshPage}
                      imageUrl={defaultValues.imagePresignedUrl}
                      projectId={String(projectId)}
                      type="page"
                    />
                  ),
                },
                {
                  icon: NavigationTabIcon,
                  id: `id_navigation_${idPrefix}_tab`,
                  label: 'Navigation',
                  view: () => (
                    <UpsertPageNavigationTab
                      control={control as Control<FormFieldProps>}
                    />
                  ),
                },
                {
                  icon: RelatedTabIcon,
                  id: `id_tag_${idPrefix}_tab`,
                  label: 'Tags',
                  view: () => (
                    <SidebarContainer>
                      <AssetTagsTab
                        initialTagList={defaultValues.tagList ?? []}
                        projectId={String(projectId)}
                        onUpdateSelectedTags={(tags: IDBAsset['tagList']) => setValue('tagList', tags)}
                      />
                    </SidebarContainer>
                  ),
                },
                {
                  icon: StyleTabIcon,
                  id: 'id_style_tab',
                  label: 'Style',
                  view: () =>(
                    <StyleTab
                      control={control as Control<FormFieldProps>}
                      entityType="page"
                    />
                  ),
                },
              ]}
            />

          )}
          
          <SidebarButtons
            buttonSize="xs"
            onCancel={() => {
              reset();
              closePageSidebar();
            }}
            onDelete={pageId ? () => setIsDeleteDialogOpen(true): undefined }
            onSaveButtonType="submit"
          />
        </Wrapper>
      </ControlledForm>

      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete ${String(cartoonType)}?`}
        isNarrow
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};


export { CartoonPageSidebarView };
