import { gql } from '@apollo/client';

const GET_TRANSCRIPT_SHAPE_TYPES = gql`
  query getShapes {
    __type(name: "EBubbleShape") {
      enumValues {
        name
      }
    }
  }
`;

export { GET_TRANSCRIPT_SHAPE_TYPES };
