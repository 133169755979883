/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";

const getAppAddAppToProjectDefaultValues = (projectIds: string[]): FormFieldProps => {
  return {
    projectIds,
    initialProjectIds: projectIds,
  };
};


export { getAppAddAppToProjectDefaultValues };