import { gql } from '@apollo/client';

const BULK_DELETE_ASSETS_MUTATION = gql`
  mutation bulkDeleteAssets($assetIds: [String]!) {
    asset {
      deleteAssets(assetIds: $assetIds)
    }
  }
`;

export { BULK_DELETE_ASSETS_MUTATION };
