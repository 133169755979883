
import { ICustomEventsTableData, ICustomEventsTableDataParams } from "./CustomEventsReportPage.interfaces";


const getCustomEventsTableData = ({ items }: ICustomEventsTableDataParams): ICustomEventsTableData[] => {
  return items.map(({
    date = '',
    device = '',
    email = '',
    firstName = '',
    id,
    lastName = '',
    metadata = '',
    startSession = '',
   }) => {
    return {
      date,
      device,
      email,
      firstName,
      id,
      lastName,
      metadata,
      startSession,
    };
  });
};

export { getCustomEventsTableData };
