import { IDBAsset } from "@netfront/common-library";
import { AssetType, FormFieldProps } from "@netfront/ui-library";

const getAssetDefaultValues = ({ asset, assetIdKey = 'assetId', assetType, isSensitive, title  }: { asset?: IDBAsset; assetIdKey?: string; assetType: AssetType; isSensitive?: boolean; title?: string }): FormFieldProps => {
  const {
    alt,
    assetId,
    contentType,
    description,
    fileName,
    isPublic,
    presignedUrl,
    publicUrl,
    tagList,
    title: assetTitle,
    user,
    // isSensitive: isAssetSensitive,
  } = asset ?? {};

  const { firstName, lastName} = user ?? {};
  return {
    alt: alt ?? '',
    author: `${String(firstName ?? '')} ${String(lastName ?? '')}`,
    [`${assetIdKey}`]: assetId ?? '',
    contentType,
    description: description ?? '',
    fileName: fileName ?? '',
    isDownloadAvailable: false,
    isPublic: isPublic ?? ['image', 'video'].includes(assetType),
    presignedUrl: presignedUrl ?? '',
    publicUrl: publicUrl ?? '',
    tagList: tagList ?? [],
    title: title ?? assetTitle ?? fileName ?? '',
    asset,
    isSensitive: isSensitive ?? false
  }
};


const hasAssetBeenUpdated = ({ assetIdKey, initialValues, currentValues } : { assetIdKey: string; currentValues: FormFieldProps; initialValues: FormFieldProps; }): boolean => {
  const {
    alt,
    author,
    [`${assetIdKey}`]: assetId,
    contentType,
    description,
    fileName,
    isDownloadAvailable,
    isPublic,
    presignedUrl,
    publicUrl,
    title,
    isSensitive,
  } = initialValues;

  const {
    alt: currentAlt,
    author: currentAuthor,
    [`${assetIdKey}`]: currentAssetId,
    contentType: currentContentType,
    description: currentDescription,
    fileName: currentFileName,
    isDownloadAvailable: currentIsDownloadAvailable,
    isPublic: currentIsPublic,
    presignedUrl: currentPresignedUrl,
    publicUrl: currentPublicUrl,
    title: currentTitle,
    isSensitive: currentIsSensitive,
  } = currentValues;

  return (
    alt !== currentAlt ||
    author !== currentAuthor ||
    assetId !== currentAssetId ||
    contentType !== currentContentType ||
    description !== currentDescription ||
    fileName !== currentFileName ||
    isDownloadAvailable !== currentIsDownloadAvailable ||
    isPublic !== currentIsPublic ||
    presignedUrl !== currentPresignedUrl ||
    publicUrl !== currentPublicUrl ||
    title !== currentTitle ||
    isSensitive !== currentIsSensitive
  );
};

export { getAssetDefaultValues, hasAssetBeenUpdated };
