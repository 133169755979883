
import { IFormsTableData, IFormsTableDataParams } from "./LibraryFormsPage.interfaces";


const getFormsTableData = ({ formItems, onSettingsButtonClick, baseUrl, isBetaVersion = false}: IFormsTableDataParams): IFormsTableData[] => {
  return formItems.map(({ description, id, title, sort, status, url, firstPage }) => {
    const { url: pageUrl = ''} = firstPage ?? {};

    return {
      description,
      title,
      id,
      sort,
      status: status === 'PUBLISHED' ? 'Active': 'Inactive',
      settingsButtonData: {
        url: isBetaVersion ? `${baseUrl}/${url}/${pageUrl}` : `${baseUrl}/${id}?library=true`,
        id,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getFormsTableData };
