/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { IDBAppComponent } from "interfaces";

const getAppComponentDefaultValues = (selectedComponent?: IDBAppComponent): FormFieldProps => {
  const { title } = selectedComponent ?? {};

  return {
    title: title || '',
  };
};


export { getAppComponentDefaultValues };