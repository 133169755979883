import { ChangeEvent, useState, useRef, useEffect, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie, IDBAsset } from '@netfront/common-library';
import { IDBGeladaProject } from '@netfront/gelada-identity-library';
import {
  ButtonIconOnly,
  Dialog,
  DropzoneFileUpload,
  InformationBox,
  Label,
  Preloader,
  Select,
  IOption,
  SettingsButton,
  Spacing,
  Textarea,
  Tooltip,
  SidebarButtons,
  RectangleAddIcon,
  CloseIcon,
  TooltipIcon,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { CachingEntitiesContext } from 'context';
import {
  useGetTopicsPerProject,
  useCreatePost,
  useDeletePost,
  useUpdatePost,
  useToast,
  useCreatePostAsset,
  useUpdatePostTopic,
} from 'hooks';
import { IDBComment, IDBPost } from 'interfaces';
import { pushImageToAws } from 'utils';

import { AddPostCommentView } from '../AddPostCommentView';

import { postSidebarGeneralViewConstants } from './PostSidebarGeneralView.constants';
import { PostSidebarGeneralViewProps } from './PostSidebarGeneralView.interfaces';

const PostSidebarGeneralView = ({
  onClose,
  onCreated,
  onCreatedComment,
  onDeleted,
  onDeletedComment,
  onUpdated,
  onUpdatedComment,
  projectId,
  selectedPost,
}: PostSidebarGeneralViewProps) => {
  const uploadedImageRef = useRef<HTMLImageElement>(null);

  const { project } = useContext(CachingEntitiesContext);

  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { maxPostDescriptionLength, maxPostImageFileSize } = postSidebarGeneralViewConstants;

  const { handleToastError, handleToastSuccess } = useToast();

  const [currentPost, setCurrentPost] = useState<IDBPost>(
    selectedPost ??
      ({
        id: 0,
        message: '',
        topicId: 0,
        image: '',
        comments: [],
      } as unknown as IDBPost),
  );

  const [allTopics, setAllTopics] = useState<IOption[]>([]);
  const [currentImage, setCurrentImage] = useState(selectedPost?.assets?.[0]);
  const [droppedFile, setDroppedFile] = useState<File>();
  const [isImageUploadToAwsLoading, setIsImageUploadToAwsLoading] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isDroppedFile, setIsDroppedFile] = useState<boolean>(false);
  const [isRemoveUploadedImage, setIsRemoveUploadedImage] = useState<boolean>(false);
  const [isOpenAddCommentView, setIsOpenAddCommentView] = useState<boolean>(false);
  const [selectedComment, setSelectedComment] = useState<IDBComment>();
  const [projectFeatures, setProjectFeatures] = useState<IDBGeladaProject['features']>([]);

  const { id: postId, message: postMessage = '', topic: postTopic } = currentPost;

  const { handleGetTopicsPerProject, isLoading: isGetTopicsForConnectedUserLoading = false } = useGetTopicsPerProject({
    onCompleted: ({ topicsPerProjectConnection: topics }) => {
      const topicsList = topics.map(({ id, title }) => ({
        id,
        value: Number(id),
        name: title,
      }));

      setAllTopics(topicsList);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId: String(projectId),
  });

  const { handleCreatePost: executeCreatePost, isLoading: isCreatePostLoading = false } = useCreatePost({
    onCompleted: ({ postsConnection: post }) => {
      onCreated(post);

      if (!postTopic?.id) {
        return;
      }

      void executeUpdatePostTopic({
        postId: post.id,
        topicId: Number(postTopic.id),
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId: String(projectId),
  });

  const { handleDeletePost: executeDeletePost, isLoading: isDeletePostLoading = false } = useDeletePost({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);

      onDeleted(currentPost.id);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });

  const { handleUpdatePost: executeUpdatePost, isLoading: isUpdatePostLoading } = useUpdatePost({
    onCompleted: ({ postsConnection }) => {
      onUpdated(postsConnection);

      if (!postTopic?.id) {
        return;
      }

      void executeUpdatePostTopic({
        postId,
        topicId: Number(postTopic.id),
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId,
  });

  const { handleUpdatePostTopic: executeUpdatePostTopic } = useUpdatePostTopic({
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId,
  });

  const { handleCreatePostAsset: executeCreatePostAsset, isLoading: isCreatePostAssetLoading = false } = useCreatePostAsset({
    onCompletedAsync: async ({ signedUrl, presignedUrl, assetId }) => {
      setIsImageUploadToAwsLoading(true);

      await pushImageToAws(signedUrl, droppedFile, () => {
        setIsImageUploadToAwsLoading(false);

        setCurrentImage({
          ...currentImage,
          assetId,
          fileName: droppedFile?.name,
          fileSizeInBytes: droppedFile?.size,
          contentType: droppedFile?.type,
          presignedUrl,
        } as IDBAsset);

        handleToastSuccess({
          message: 'Post image uploaded successfully',
        });
      }).catch((error) => {
        handleToastError({
          error,
        });
      });

    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    projectId: String(projectId),
  });

  const handleDropFile = (acceptedFile: File[]) => {
    const [file] = acceptedFile;

    const { name, type, size } = file;

    setDroppedFile(file);
    setIsRemoveUploadedImage(!isRemoveUploadedImage);
    setIsDroppedFile(true);

    void executeCreatePostAsset({
      asset: {
        contentType: type,
        fileName: name,
        fileSizeInBytes: size,
        type: 'IMAGE',
        alt: 'Post image',
        postId,
      },
    });
  };

  const handleRemoveUploadedImage = () => {
    setCurrentImage((image) => {
      if (!image) {
        return;
      }

      return {
        ...image,
        presignedUrl: '',
      };
    });

    setIsRemoveUploadedImage(false);
    setIsDroppedFile(false);
  };

  const handleClickUpdateComment = (comment: IDBComment) => {
    setSelectedComment(comment);
    setIsOpenAddCommentView(true);
  };

  const handleUpdatePostInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = event;

    setCurrentPost(
      (currentState) =>
        ({
          ...currentState,
          [name]: name === 'topic' ? { id: value } : value,
        } as IDBPost),
    );
  };

  const handleCreatePost = () => {
    void executeCreatePost({
      topicId: Number(postTopic?.id),
      message: postMessage,
    });
  };

  const handleUpdatePost = () => {
    void executeUpdatePost({
      postId: Number(postId),
      message: postMessage,
    });
  };

  const isLoading =
    isCreatePostAssetLoading ||
    isImageUploadToAwsLoading ||
    isCreatePostLoading ||
    isGetTopicsForConnectedUserLoading ||
    isDeletePostLoading ||
    isUpdatePostLoading;

  useEffect(() => {
    handleGetTopicsPerProject({
      projectId: String(projectId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!project) {
      return;
    }

    setProjectFeatures(project.features);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.features]);

  useEffect(() => {
    if (!isDroppedFile && selectedPost) {
      return;
    }

    if (uploadedImageRef.current === null) {
      return;
    }

    uploadedImageRef.current.src = URL.createObjectURL(droppedFile as Blob);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDroppedFile]);

  const isUploadedImageVisible = currentImage?.presignedUrl && selectedPost;
  const isDropzoneVisible = !currentImage?.presignedUrl && selectedPost;

  return (
    <SidebarContainer>
      {!isOpenAddCommentView ? (
        <div className="c-post-sidebar-general-view">
          {isLoading && <Preloader isLoading={isLoading} />}

          <Spacing size="large">
            <Textarea
              id="message"
              labelText="Post"
              maxLength={maxPostDescriptionLength}
              name="message"
              tooltipText="Post message"
              value={postMessage}
              isLabelSideBySide
              onChange={handleUpdatePostInput}
            />
          </Spacing>

          {projectFeatures?.includes('HAS_BONOBO_TOPIC') && (
            <Spacing size="large">
              <Select
                id="topic"
                labelText="Topic"
                name="topic"
                options={allTopics}
                tooltipText="Select post topic"
                value={postTopic?.id}
                isLabelSideBySide
                onChange={handleUpdatePostInput}
              />
            </Spacing>
          )}

          {isUploadedImageVisible && (
            <>
              <Spacing size="2x-large">
                <section className="c-sidebar-section c-sidebar-section--aligned">
                  <Label forId="image" labelText="Image upload" />

                  <Tooltip
                    additionalClassNames="c-sidebar-section__tooltip"
                    bubbleTheme="dark"
                    icon={TooltipIcon}
                    placement="left"
                    text="Upload your image"
                  />
                </section>

                <div className="c-image-sidebar-general-view__uploaded-image-container">
                  <div className="c-image-sidebar-general-view__uploaded-image">
                    <img ref={uploadedImageRef} alt="Post image" src={currentImage.presignedUrl} />

                    <div className="c-remove-asset-button">
                      <ButtonIconOnly icon={CloseIcon} text="" onClick={handleRemoveUploadedImage} />
                    </div>
                  </div>
                </div>
              </Spacing>
            </>
          )}

          {isDropzoneVisible && (
            <Spacing size="2x-large">
              <DropzoneFileUpload fileType="image" labelText="Image upload" maxFileSize={maxPostImageFileSize} tooltipText="Upload your image" onDrop={handleDropFile} />
            </Spacing>
          )}

          {selectedPost && (
            <>
              <Spacing>
                <section className="c-post-sidebar-general-view__add-section">
                  <Label forId="post-comments" labelText="Comments" tooltipText="Post comments" />

                  {currentPost.commentCount > 0 ? (
                    <Spacing size="large">
                      <div className="c-post-sidebar-general-view__posts">
                        <ul className="c-post-sidebar-general-view__posts-list">
                          {currentPost.comments.edges.map(({ node: currentComment }) => (
                            // <li key={id} className="c-post-sidebar-general-view__posts-list-item">
                            //   <span className="c-post-sidebar-general-view__posts-list-item-message">{message}</span>
                            // </li>
                            <li
                              key={`${currentComment.id}-${currentComment.message}`}
                              className="c-user-communities-sidebar-view__community-item"
                            >
                              {currentComment.message}
                              <SettingsButton supportiveText="Open community" onClick={() => handleClickUpdateComment(currentComment)} />
                            </li>
                          ))}
                        </ul>
                        {currentPost.commentCount > 5 && (
                          <div className="c-post-sidebar-general-view__posts-list-item-count">{currentPost.commentCount} comments</div>
                        )}
                      </div>
                    </Spacing>
                  ) : (
                    <Spacing size="large">
                      <InformationBox message={`Post does not have any comments.`} />
                    </Spacing>
                  )}
                </section>
              </Spacing>

              <Spacing size="large">
                <section className="c-post-sidebar-general-view__add-container">
                  <button
                    className="c-post-sidebar-general-view__add"
                    id="add-comment"
                    title="Add comment"
                    type="button"
                    onClick={() => setIsOpenAddCommentView(true)}
                  >
                    <RectangleAddIcon className="c-icon c-post-sidebar-general-view__add-icon" />
                  </button>
                </section>
              </Spacing>

              <Dialog
                isOpen={isDeleteDialogOpen}
                title={`Do you want to delete the post?`}
                onCancel={() => setIsDeleteDialogOpen(false)}
                onClose={() => setIsDeleteDialogOpen(false)}
                onConfirm={() => {
                  if (!postId) {
                    return;
                  }

                  void executeDeletePost({
                    postId,
                  });
                }}
              />
            </>
          )}

          <SidebarButtons
            onCancel={onClose}
            onDelete={selectedPost? () => setIsDeleteDialogOpen(true) : undefined}
            onSave={!selectedPost ? handleCreatePost : handleUpdatePost}
            onSaveButtonType="button"
          />
        </div>
      ) : (
        <AddPostCommentView
          post={currentPost}
          projectId={projectId}
          selectedComment={selectedComment}
          onClose={onClose}
          onCreated={onCreatedComment}
          onDeleted={onDeletedComment}
          onOpenAddCommentView={() => {
            setIsOpenAddCommentView(false);
            setSelectedComment(undefined);
          }}
          onUpdated={onUpdatedComment}
        />
      )}
    </SidebarContainer>
  );
};

export { PostSidebarGeneralView };
