import { useContext, useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { getAccessTokenCookie } from '@netfront/common-library';
import { CircleAddIcon, GeneralTabIcon, GroupsTabIcon, IOption, ISearchList, ITab, SelectWithSearch } from '@netfront/ui-library';
import { UserBulkActionDialog, TablePageTemplate , UserSidebarCommunitiesView, UserSidebarGeneralView, UserSidebarUserView } from 'components';
import { CachingEntitiesContext, DashboardContext } from 'context';
import { useGetPaginatedProjectCommunities, useGetPaginatedUsers, useToast } from 'hooks';
import { IDBBonoboUser } from 'interfaces';
import last from 'lodash.last';
import Link from 'next/link';
import { useRouter } from 'next/router';


import { USERS_TABLE_COLUMNS, USERS_TABLE_COLUMNS_WITH_COMMUNITIES, USER_PAGE_SIZE } from './UserPages.constants';
import { getUsersTableData } from './UsersPage.helpers';
import { IUsersTableData } from './UsersPage.interfaces';



const UsersPage = () => {
  const { query: { projectId: queryProjectId } } = useRouter();
  const { dashboardLink } = useContext(DashboardContext);
  const { project } = useContext(CachingEntitiesContext);
  const { handleToastError } = useToast();
  const token = getAccessTokenCookie();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [allUsers, setAllUsers] = useState<IDBBonoboUser[]>();
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [hasCommunity, setHasCommunity] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [usersTableData, setUsersTableData] = useState<IUsersTableData[]>();
  const [selectedUser, setSelectedUser] = useState<IDBBonoboUser>();
  const [pageSize, setPageSize] = useState<number>(USER_PAGE_SIZE);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [selectedCommunityId, setSelectedCommunityId] = useState<number>();
  const [selectedCommunityName, setSelectedCommunityName] = useState<string>();
  const [isCommunitySelectorOpen, setIsCommunitySelectorOpen] = useState<boolean>(false);
  const [userCommunities, setUserCommunities] = useState<IOption[]>([]);


  // bulk actions
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [bulkActionType, setBulkActionType] = useState<string>('add');
  const [isBulkActionDialogOpen, setIsBulkActionDialogOpen] = useState<boolean>(false);


  const { handleGetPaginatedProjectCommunities } =
    useGetPaginatedProjectCommunities({
      fetchPolicy: 'cache-and-network',
      projectId: String(projectId),
      onCompleted: ({ communityConnection: { edges } }) => {
        const communities = edges.map(({ node }) => node);

        setUserCommunities(
          communities.map(({ id, title }) => ({
            id: String(id),
            name: title,
            value: String(id),
          })),
        );
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
    });

  const {
    handleFetchMorePaginatedUsers,
    handleGetPaginatedUsers,
    isLoading: isGetPaginatedUsersLoading = false,
  } = useGetPaginatedUsers({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ usersConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const Users = edges.map(({ node }) => node);

      setSelectedRows([]);
      setAllUsers(Users);
      setIsLoadMoreDisabled(Users.length >= totalCount || totalCount <= pageSize);
      setTotalUsers(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });

  const handleFilterSearch = (value: string) => {
    handleGetPaginatedUsers({
      filter: value,
      first: pageSize,
      communityId: selectedCommunityId,
    });

    setFilter(value);
  };

  const handleSelectedRows = (selectedIds: string[]) => {
    setSelectedRows(selectedIds);
  }

  const onSelectedUserUpdate = (user: IDBBonoboUser) => {
    if (!allUsers) {
      return;
    }
    // Find the index of the user to update based on a unique identifier like user ID
    const userIndex = allUsers.findIndex((u) => u.id === user.id);

    if (userIndex !== -1) {
      // Create a new array with the updated user object
      const updatedUsers = [...allUsers];
      updatedUsers[userIndex] = user;

      // Update the state with the modified array
      setAllUsers(updatedUsers);
      setSelectedUser(user);
    } else {
      console.error('User not found in allUsers array.');
    }
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handleSideBarClose = () => {
    setIsSideBarOpen(false);
    setSelectedUser(undefined);
  };

  const handleSwitchCommunity = (id: number | string) => {
    if (id === '0') {
      setSelectedCommunityId(undefined);
      setSelectedCommunityName('');
    } else {
      setSelectedCommunityId(Number(id));
      setSelectedCommunityName(userCommunities.find((community) => community.id === id)?.name);
    }
  };


  // bulk actions
  const handleOpenBulkActionDialog = (type: string) => {
    setBulkActionType(type);
    setIsBulkActionDialogOpen(true);
  };

  const handleCloseBulkActionDialog = () => {
    setBulkActionType('');
    setIsBulkActionDialogOpen(false);
  };

  const handleBulkActionSave = () => {
    handleCloseBulkActionDialog();
    void handleGetPaginatedUsers({
      first: pageSize,
      filter,
      communityId: selectedCommunityId,
    });
  }

  useEffect(() => {
    if (!projectId) {
      return;
    }

    handleGetPaginatedUsers({
      first: pageSize,
      filter,
      communityId: selectedCommunityId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId, selectedCommunityId]);

  useEffect(() => {
    if (!allUsers) {
      return;
    }

    setUsersTableData(
      getUsersTableData({
        users: allUsers,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedUser(allUsers.find(({ id: userId }) => id === userId));
        },
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers]);


  useEffect(() => {
    if (!project) {
      return;
    }

    const { name, features } = project;

    setHasCommunity(features?.includes('HAS_BONOBO_COMMUNITY') ?? false);

    setProjectName(name);
    handleGetPaginatedProjectCommunities({
      filter: '',
      projectId: project.id,
    });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const tabs: ITab[] = [
    {
      icon: GeneralTabIcon,
      id: 'id_user_tab',
      label: 'User',
      isHidden: !hasCommunity,
      view: () => (
        <UserSidebarUserView selectedUser={selectedUser} onUserUpdate={onSelectedUserUpdate} />
      ),
    },
    {
      icon: GeneralTabIcon,
      id: 'id_posts_tab',
      label: 'Posts',
      view: () => <UserSidebarGeneralView selectedUser={selectedUser} />,
    },
    {
      icon: GroupsTabIcon,
      id: 'id_communities_tab',
      label: 'Communities',
      isHidden: !hasCommunity,
      view: () => (
        <UserSidebarCommunitiesView
          projectId={String(projectId)}
          selectedUserId={selectedUser?.id}
        />
      ),
    },
  ];

  const isLoading = isGetPaginatedUsersLoading;

  return (
    <>
      <TablePageTemplate<IUsersTableData>
        activePage="social"
        activeSubPage="users"
        additionalBreadcrumbItems={[
          {
            key: '1',
            content: <Link href={`${String(dashboardLink)}/social`}><span>Social</span></Link>,
          },
          {
            key: '2',
            content: 'Users',
          },
        ]}
        bulkActions={hasCommunity ? [
          { id: 0, label: 'Connect to community', action: () => handleOpenBulkActionDialog('connect') },
          { id: 1, label: 'Remove', action: () => handleOpenBulkActionDialog('remove') },
          { id: 2, label: 'Assign role', action: () => handleOpenBulkActionDialog('assign') },
        ]: undefined}
        childrenMiddle={
          <SelectWithSearch
            customButtonIcon={CircleAddIcon}
            customButtonText="Add new account"
            defaultValue={selectedCommunityId ? selectedCommunityName: 'All communities'}
            hasPadding={false}
            id="id_select_account"
            isDisplaySearchContent={isCommunitySelectorOpen}
            isLoading={isLoading}
            labelText="Select account"
            searchList={[
              {
                id: '0',
                label: 'All communities',
                size: 'x-small',
              },
              ...userCommunities.map((community) => {
                return {
                  id: community.id,
                  label: community.name,
                  size: 'x-small',
                } as ISearchList;
              })
            ]}
            isAvatarVisible
            isLabelHidden
            onDisplaySearchContent={() => setIsCommunitySelectorOpen(!isCommunitySelectorOpen)}
            onSearchItemClick={handleSwitchCommunity}
          />
        }
        columns={hasCommunity ? USERS_TABLE_COLUMNS_WITH_COMMUNITIES : USERS_TABLE_COLUMNS}
        data={usersTableData ?? []}
        defaultActiveTabId="id_user_tab"
        description={`Moderation social content for ${projectName} project`}
        handleOnPageSizeChange={handlePageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedUsers({
            after: lastCursor,
            first: pageSize,
            filter,
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage={`Moderate ${projectName} social content`}
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        isSideBarOpen={isSideBarOpen}
        pageSize={pageSize}
        pageTitle={`${projectName} community users dashboard`}
        tableType="users"
        tabs={tabs}
        title={`${projectName} community users`}
        toggleIsSideBarOpen={handleSideBarClose}
        totalItems={totalUsers}
        onSelectRows={handleSelectedRows}
      />
      {projectId && hasCommunity && (
        <UserBulkActionDialog
          bulkActionType={bulkActionType}
          communities={userCommunities}
          handleCloseDialog={handleCloseBulkActionDialog}
          isOpen={isBulkActionDialogOpen}
          projectId={String(projectId)}
          selectedIds={selectedRows}
          onSave={handleBulkActionSave}
        />
      )}

    </>
  );
};

export { UsersPage };
