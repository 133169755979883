import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_ASSET_MUTATION } from './useUpdateAsset.graphql';
import {
  IUpdateAssetMutationGraphQLResponse,
  IUpdateAssetMutationVariables,
  IHandleUpdateAssetParams,
  IUseUpdateAssetOptions,
  IUseUpdateAsset,
} from './useUpdateAsset.interfaces';

const useUpdateAsset = (options?: IUseUpdateAssetOptions): IUseUpdateAsset => {
  const { mutation, onCompleted, onCompletedAsync, onError, token } = options ?? ({} as IUseUpdateAssetOptions);

  const [executeUpdateAsset, { loading: isLoading }] = useEkardoMutation<
    IUpdateAssetMutationGraphQLResponse,
    IUpdateAssetMutationVariables
  >({
    mutation: mutation ?? UPDATE_ASSET_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {

        const {
          asset: { updateAsset: assetConnection },
        } = data;

        if (onCompleted) {
          void onCompleted(assetConnection);
        }

        if (onCompletedAsync) {
          void onCompletedAsync(assetConnection);
        }
      },
      onError,
    },
    token,
  });

  const handleUpdateAsset = ({
    alt,
    assetId,
    contentType,
    description,
    fileName,
    fileSizeInBytes,
    imageSize,
    tagIds,
    title,
    xAxisFocus,
    yAxisFocus,
    isAnimatedImage = false
  }: IHandleUpdateAssetParams) => {
    void executeUpdateAsset({
      variables: {
        alt,
        assetId,
        contentType,
        description,
        fileName,
        fileSizeInBytes,
        imageSize,
        tagIds,
        title,
        xAxisFocus,
        yAxisFocus,
        isAnimatedImage,
      },
    });
  };

  return {
    handleUpdateAsset,
    isLoading,
  };
};

export { useUpdateAsset };
