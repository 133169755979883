import { gql } from '@apollo/client';

const SEARCH_PAGINATED_ASSETS_QUERY = gql`
  query SearchPaginatedAssets(
    $projectId: String!
    $favorite: Boolean
    $filter: String
    $first: Int
    $after: String
    $tags: [Int]
    $types: [EAssetType!]!
    $sort: ESorting
  ) {
    asset {
      search(
        after: $after
        first: $first
        projectId: $projectId
        tags: $tags
        types: $types
        favorite: $favorite
        filter: $filter
        sort: $sort
      ) {
        totalCount
        edges {
          cursor
          node {
            alt
            assetId
            contentType
            description
            fileName
            isFavorite
            isPublic
            tagList {
              id
              name
            }
            presignedUrl
            projectId
            publicS3Key
            publicUrl
            s3Key
            title
            transcript
            type
            uploaded
            user {
              firstName
              lastName
            }
            xAxisFocus
            yAxisFocus
          }
        }
      }
    }
  }
`;

export { SEARCH_PAGINATED_ASSETS_QUERY };
