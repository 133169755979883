import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Input, Spacing, Textarea } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Controller } from 'react-hook-form';

import { UpsertPageSeoTabProps } from './UpsertPageSeoTab.interfaces';



const UpsertPageSeoTab = ({ control }: UpsertPageSeoTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <SidebarContainer>
      <Spacing>
        <Controller
          control={control}
          name="seoTitle"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_page_seo_title"
              labelText="SEO title"
              type="text"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="seoDescription"
          render={({ field, fieldState }) => (
            <Textarea
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_page_seo_description"
              labelText="SEO description"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
    </SidebarContainer>
  );
};

export { UpsertPageSeoTab };
