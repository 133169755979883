import { gql } from '@apollo/client';

const SUBMIT_APP_FOR_APPROVAL = gql`
  mutation submitMyAppForApproval($inputType: SubmitNewVersionInputType!) {
    app {
      appVersionMutation {
        submitNewVersion(
          inputType: $inputType
        )
      }
    }
  }
`;


export { SUBMIT_APP_FOR_APPROVAL };
