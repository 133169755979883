import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { INotificationsTableData } from './NotificationsReportPage.interfaces';



const NOTIFICATIONS_COLUMNS: ReadonlyArray<Column<INotificationsTableData>> = [
  {
    accessor: 'receiver',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Receiver</div>,
  },
  {
    accessor: 'userId',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">User id</span>,
    width: '5%',
  },
  {
    accessor: 'subject',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Subject</span>,
  },
  {
    accessor: 'templateName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Template Name</span>,
  },
  {
    accessor: 'type',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Type</span>,
  },
  {
    accessor: 'sendDate',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Sending date</span>,
  },
  {
    accessor: 'sentOn',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Sent on</span>,
  },
  {
    accessor: 'status',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Status</span>,
    width: '7%',
  },
  {
    accessor: 'events',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Events</span>,
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { item, onClick } }) => <SettingsButton supportiveText="View template" onClick={() => onClick(item)} />,
    Header: () => <span className="h-hide-visually">Settings</span>,
    width: '5%',
  },
];

export { NOTIFICATIONS_COLUMNS };
