import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetMobileAccessReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { MOBILE_ACCESS_COLUMNS } from './MobileAccessReportPage.constants';
import { getMobileAccessTableData } from './MobileAccessReportPage.helpers';
import { MobileAccessCsvRowData, IMobileAccessItem, IMobileAccessTableData } from './MobileAccessReportPage.interfaces';



const MobileAccessReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [mobileAccessItems, setMobileAccessItems] = useState<IMobileAccessItem[]>();
  const [allMobileAccessItems, setAllMobileAccessItems] = useState<IMobileAccessItem[]>([]);
  const [mobileAccessTableData, setMobileAccessTableData] = useState<IMobileAccessTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>();
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const {
    handleGetMobileAccessReport,
    isLoading: isGetMobileAccessLoading = false,
  } = useGetMobileAccessReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<MobileAccessCsvRowData>(String(res.data), {
            header: true,
          skipEmptyLines: true,
          });

          const formattedItems = data.map((row: MobileAccessCsvRowData) => ({
            id: Number(row['User id']),
            appVersion: String(row['App version']),
            dateOpened: String(row['Date opened']),
            email: String(row['Email']),
            firstName: String(row['First name']),
            lastName: String(row['Last name']),
            lastDateOpened: String(row['Last date opened']),
            schoolCode: String(row['School code']),
            totalOpen: String(row['Total open']),
          }));

          setTotalCount(data.length);
          setAllMobileAccessItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setMobileAccessItems(paginatedItems);
          setLastItemIndex(pageSize);
        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    void handleGetMobileAccessReport({
      dateFrom: dateRange && dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange && dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setMobileAccessItems(allMobileAccessItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setMobileAccessItems(allMobileAccessItems.slice(0, updateIndex));
      resolve();
    });
  };
  
  useEffect(() => {
    if (!mobileAccessItems) {
      return;
    }

    setMobileAccessTableData(
      getMobileAccessTableData({
        items: mobileAccessItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileAccessItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetMobileAccessLoading;

  return (
    <ReportTable<IMobileAccessTableData>
      activePath={['reporting', 'reporting-users', 'export-mobile-app-access']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Mobile access',
      }]}
      columns={MOBILE_ACCESS_COLUMNS}
      csvUrl={csvUrl}
      data={mobileAccessTableData}
      description={`Mobile access reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports mobile app access based on the selected filters'}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { MobileAccessReportPage };
