
import { DELETE_TOPIC_ASSET_MUTATION } from './useDeleteTopicAsset.graphql';
import {
  IDeleteTopicAssetMutationGraphQLResponse,
  IDeleteTopicAssetMutationVariables,
  IHandleDeleteTopicAssetParams,
  IUseDeleteTopicAssetOptions,
  IUseDeleteTopicAsset,
} from './useDeleteTopicAsset.interfaces';

import { useBonoboMutation } from '../../useBonoboMutation';

const useDeleteTopicAsset = (options?: IUseDeleteTopicAssetOptions): IUseDeleteTopicAsset => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseDeleteTopicAssetOptions);

  const [executeDeleteTopicAsset, { loading: isLoading }] = useBonoboMutation<
    IDeleteTopicAssetMutationGraphQLResponse,
    IDeleteTopicAssetMutationVariables
  >({
    mutation: mutation ?? DELETE_TOPIC_ASSET_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { deleteAsset: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteTopicAsset = ({ assetId }: IHandleDeleteTopicAssetParams) => {
    void executeDeleteTopicAsset({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleDeleteTopicAsset,
    isLoading,
  };
};

export { useDeleteTopicAsset };
