import { Column } from 'react-table';

import { ILoginsTableData } from './LoginsPage.interfaces';



const LOGINS_COLUMNS: ReadonlyArray<Column<ILoginsTableData>> = [

  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User id</div>,
    width: '7%',
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Email</div>,
    width: '30%',
  },
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">First name</span>,
    width: '10%',
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Last name</span>,
    width: '10%',
  },
  {
    accessor: 'accessType',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Access type</span>,
    width: '5%',
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group name</span>,

  },
  {
    accessor: 'loginDate',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Login date</span>,
  },
];

export { LOGINS_COLUMNS };
