import { ApolloQueryResult } from '@apollo/client';
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_LINKED_PROJECTS } from './useGetAppLinkedProjects.graphql';
import {
  IGetAppLinkedProjectsQueryGraphQLResponse,
  IGetAppLinkedProjectsQueryVariables,
  IHandleFetchMorePaginatedAppLinkedProjectsParams,
  IHandleGetAppLinkedProjectsParams,
  IUseGetAppLinkedProjects,
  IUseGetAppLinkedProjectsOptions,
} from './useGetAppLinkedProjects.interfaces';

const useGetAppLinkedProjects = (options?: IUseGetAppLinkedProjectsOptions): IUseGetAppLinkedProjects => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetAppLinkedProjectsOptions);

  const [executeGetAppLinkedProjects, { fetchMore, loading: isLoading }] = useEkardoLazyQuery<
  IGetAppLinkedProjectsQueryGraphQLResponse,
  IGetAppLinkedProjectsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          app: { authorisedProjects: projects },
        } = data;

        void onCompleted({
          projects,
        });
      },
      onError,
    },
    query: query ?? GET_LINKED_PROJECTS,
    token,
  });

  const handleFetchMorePaginatedAppLinkedProjects = ({
    after,
    appId,
    first,
  }: IHandleFetchMorePaginatedAppLinkedProjectsParams): Promise<ApolloQueryResult<IGetAppLinkedProjectsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          app: { authorisedProjects: newlyFetchedScenarioConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedScenarioConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          app: { authorisedProjects: previouslyFetchedScenarioConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedScenarioConnection;

        return {
          ...previousQueryResult,
          app: {
            ...previouslyFetchedScenarioConnection,
            authorisedProjects: {
              ...newlyFetchedScenarioConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        appId,
      },
    });
  };

  const handleGetAppLinkedProjects = ({
    after,
    first,
    appId,
  }: IHandleGetAppLinkedProjectsParams) => {
    void executeGetAppLinkedProjects({
      variables: {
        after,
        first,
        appId,
      },
    });
  };

  return {
    handleFetchMorePaginatedAppLinkedProjects,
    handleGetAppLinkedProjects,
    isLoading,
  };
};

export { useGetAppLinkedProjects };
