import { useContext, useEffect, useState } from 'react';

import { IDBGroup, useGetPaginatedGroups } from '@netfront/gelada-identity-library';
import { GeneralTabIcon } from '@netfront/ui-library';
import { TablePageTemplate , UserFlowGroupAccessSidebarView } from 'components';
import { CachingEntitiesContext } from 'context';
import { useToast } from 'hooks';
import last from 'lodash.last';
import { useRouter } from 'next/router';



import { GROUPS_TABLE_COLUMNS } from './GroupAccessPage.constants';
import { getGroupsTableData } from './GroupAccessPage.helpers';
import { IUserGroupTableData } from './GroupAccessPage.interfaces';


const GroupAccessPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError } = useToast();
  const defaultPageSize = 10;

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [allGroups, setAllGroups] = useState<IDBGroup[]>();
  const [filter, setFilter] = useState<string>();
  const [groupId, setGroupId] = useState<number>();
  const [groupsTableData, setGroupsTableData] = useState<IUserGroupTableData[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [totalGroups, setTotalGroups] = useState(0);

  const {
    handleFetchMorePaginatedGroups,
    handleGetPaginatedGroups,
    isLoading: isGetPaginatedGroupsLoading = false,
  } = useGetPaginatedGroups({
    fetchPolicy: 'network-only',
    onCompleted: ({ groupConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const groups = edges.map(({ node }) => node);
      setAllGroups(groups);
      setIsLoadMoreDisabled(groups.length >= totalCount || totalCount <= pageSize);
      setTotalGroups(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const handleFilterSearch = (value: string) => {
    void handleGetPaginatedGroups({
      first: pageSize,
      projectId: String(projectId),
      status: 'ACTIVE',
      filter: value,
    });

    setFilter(value);
  };

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setGroupId(undefined);
  };



  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleGetPaginatedGroups({
      first: pageSize,
      projectId: String(projectId),
      status: 'ACTIVE',
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId]);

  useEffect(() => {
    if (!allGroups) {
      return;
    }

    setGroupsTableData(
      getGroupsTableData({
        groups: allGroups,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setGroupId(id);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <TablePageTemplate<IUserGroupTableData>
      activePage="group-access"
      activeSubPage="group-access"
      additionalBreadcrumbItems={[{
        key: '1',
        content: 'Group access',
      }]}
      columns={GROUPS_TABLE_COLUMNS}
      data={groupsTableData}
      defaultActiveTabId="id_general_tab"
      description={`Library dashboard for ${String(projectName)}`}
      handleOnPageSizeChange={handleOnPageSizeChange}
      handleOnPaginate={async () => {
        await handleFetchMorePaginatedGroups({
          after: lastCursor,
          first: pageSize,
          projectId: String(projectId),
          status: 'ACTIVE',
        });
      }}
      handleSearch={handleFilterSearch}
      informationBoxMessage={`${String(projectName)} project`}
      isLoading={isGetPaginatedGroupsLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      isSideBarOpen={isSideBarOpen}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      size={project?.logo?.presignedUrl ? 'small': 'medium'}
      tableType="Access"
      tabs={[
        {
          icon: GeneralTabIcon,
          id: 'id_general_tab',
          label: 'General',
          view: () =>
            groupId ? <UserFlowGroupAccessSidebarView groupId={groupId} projectId={String(projectId)} onClose={handleCloseSidebar} /> : <></>,
        },
      ]}
      title={`${String(projectName)} group access dashboard`}
      toggleIsSideBarOpen={handleCloseSidebar}
      totalItems={totalGroups}
      hideSideBarButtons
    />
  );
};

export { GroupAccessPage };
