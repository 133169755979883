
const ASSET_BULK_ACTION_DIALOG_TITLE_MAP: {[key: string]: string } = Object.freeze({
  updateType: 'Bulk update asset tags',
  delete: 'Bulk delete assets',
  toggleIsPublic: 'Bulk update publicity',
});


const ASSET_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP: {[key: string]: string } = Object.freeze({
  updateTags: 'Update',
  delete: 'Delete',
  toggleIsPublic: 'Update',
});

export { ASSET_BULK_ACTION_DIALOG_TITLE_MAP, ASSET_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP };
