import { gql } from '@apollo/client';

export const GET_NOTIFICATION = gql`
  query getNotification($notificationId: String!) {
    notification {
      get(notificationId: $notificationId) {
        content
        id
      }
    }
  }
`;