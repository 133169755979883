const cssStyle = `
.c-transcript-picker {
  .c-cartoon-label {
    border-radius: calc(6px * aspectRatio) !important;
    padding: calc(1rem * aspectRatio) !important;

  }

  .c-cartoon-label__content {
    font-size: calc(1.1rem * aspectRatio) !important;
  }

  .c-bubble-text {
    font-size: calc(1rem * aspectRatio) !important;

    * {
      font-size: calc(1rem * aspectRatio) !important;
      margin: 0 !important;
    }
  }

  .c-cartoon-ellipsis {
    padding: calc(0.75rem * aspectRatio) 0 !important;
  }

  .c-cartoon-ellipsis__inner {
    width: calc(100% + calc(2rem * aspectRatio)) !important;
    margin: calc(24px * aspectRatio) !important;
  }

  .c-cartoon-blast-wrapper {
    padding: calc(2px * aspectRatio) !important;
  }

  .c-cartoon-blast {
    padding: calc(1rem * aspectRation) !important;
  }

  .c-cartoon-blast__text {
    font-size: calc(0.7rem * aspectRatio) !important;
    margin: calc(1.5rem * aspectRatio) calc(2rem * aspectRatio) !important;
  }

  .c-cartoon-blast__tail {
    border-top: calc(112px * aspectRatio) solid #fff !important;
    border-right: calc(12px * aspectRatio) solid transparent !important;
    border-left: calc(12px * aspectRatio) solid transparent !important;
  }

  .c-cartoon-chat {
    padding: calc(0.25rem * aspectRatio) calc(0.75rem * aspectRatio) calc(0.5rem * aspectRatio) !important;
  }

  .c-cartoon-chat::before {
    border-width: 0 calc(20px * aspectRatio) calc(24px * aspectRatio) 0 !important;
    right: calc(-12px * aspectRatio) !important;
    top: calc(9px * aspectRatio) !important;
  }

  .c-cartoon-chat::after {
    border-width: 0 calc(18px * aspectRatio) calc(24px * aspectRatio) 0 !important;
    right: calc(-11px * aspectRatio) !important;
    top: calc(9px * aspectRatio) !important;
  }


  .c-cartoon-chat--left-aligned::before {
    border-width: 0 0 calc(24px * aspectRatio) calc(20px * aspectRatio) !important;
    left: calc(-2px * aspectRatio) !important;
    top: calc(-3px * aspectRatio) !important;
  }

  .c-cartoon-chat--left-aligned::after {
    border-width: 0 0 calc(23px * aspectRatio) calc(20px * aspectRatio) !important;
    top: calc(-1.5px * aspectRatio) !important;
  }

  .c-cartoon-chat__text {
    font-size: calc(1rem * aspectRatio) !important;
    margin-bottom: calc(0.5rem * aspectRatio) !important;

    * {
      font-size: calc(1rem * aspectRatio) !important;
      margin: 0 !important;
    }
  }


  .c-cartoon-input {
    border-radius: calc(0.25rem * aspectRatio) !important;
  }

  .c-cartoon-input__textarea {
    border-radius: calc(0.25rem * aspectRatio) !important;
    font-family: inherit;
    font-size: calc(1rem * aspectRatio) !important;
    min-height: calc(15rem * aspectRatio) !important;
    padding: calc(0.75rem * aspectRatio) !important;
  }

  .c-cartoon__rectangle {
    border-radius: calc(24px  * aspectRatio) !important;;
    padding: calc(2rem * aspectRatio) !important;;
  }

  .c-cartoon-parallelogram {
    box-shadow: 0 0 calc(15px * aspectRatio) 1px rgb(0 0 0 / 100%) !important;
    padding: calc(0.5rem * aspectRatio) calc(1rem * aspectRatio) !important;
  }

  .c-cartoon-parallelogram__text {
    font-size: calc(1.25rem * aspectRatio) !important;

    * {
      font-size: calc(1.25rem * aspectRatio) !important;
      margin: 0 !important
    }
  }

  .c-cartoon__bubble {
    font-size: calc(0.9rem * aspectRatio) !important;
    padding: calc(1rem * aspectRatio) !important;
  }

  .c-cartoon__image {
    width: calc(100% + calc(2rem * aspectRatio));
  }

  .c-cartoon-title__text {
    font-size: calc(3rem * aspectRatio) !important;

    * {
      font-size: calc(3rem * aspectRatio) !important;
      margin: 0 !important;
    }
  }



  .c-quiz-feedback {
    padding: calc(2rem * aspectRatio) calc(1.5rem * aspectRatio) calc(2rem * aspectRatio) calc(2.5rem* aspectRatio) !important;
  }

  .c-quiz-feedback__icon {
    border-radius: 50%;
    height: calc(3rem * aspectRatio) !important;
    margin-right: calc(1rem * aspectRatio) !important;
    min-width: calc(3rem * aspectRatio) !important;
    padding: calc(0.8rem * aspectRatio) !important;
    width: calc(3rem * aspectRatio) !important;
  }

  .c-quiz-button {
    border-radius: calc(40px * aspectRatio) 0 0 calc(40px * aspectRatio) !important;
    margin-bottom: calc(1rem * aspectRatio) !important;
  }


  .c-quiz-button__index {
    border-radius: calc(50% * aspectRatio) !important;
    height: calc(3.5rem * aspectRatio) !important;
    margin-right: calc(1rem * aspectRatio) !important;
    width: calc(3.5rem * aspectRatio) !important;
  }



  .c-factcheck__startburst {
    bottom: calc(-2.5rem * aspectRatio) !important;
    left: calc(-2.5rem * aspectRatio) !important;
    width: calc(230px * aspectRatio) !important;
    padding: calc(3rem * aspectRatio) !important;
  }

  .c-factcheck__title {
    line-height: 1.3;
  }

  .c-factcheck__icon {
    height: calc(16rem * aspectRatio) !important;
    width: calc(16rem * aspectRatio) !important;
  }


  .c-factcheck__content {
    height: calc(8.5rem * aspectRatio) !important;
    padding: calc(1rem * aspectRatio) calc(1rem * aspectRatio) calc(1rem * aspectRatio) calc(200px * aspectRatio) !important;
  }

  .c-factcheck__body {
    font-size: calc(1.5rem * aspectRatio) !important;

    * {
      font-size: calc(1.5rem * aspectRatio) !important;
      margin: 0 !important;
    }
  }

  .c-stop-think {
    left: calc(32% * aspectRatio) !important;
    max-width: calc(68% * aspectRatio) !important;
    padding: calc(2rem * aspectRatio) calc(4rem * aspectRatio) calc(2rem * aspectRatio) calc(3rem * aspectRatio) !important;
    
  }

  .c-stop-think__textarea {
    border-radius: calc(8px * aspectRatio) !important;
    margin-top: calc(2rem * aspectRatio) !important;
    min-height: calc(10rem * aspectRatio) !important;
    padding: calc(1rem * aspectRatio) !important;
  }

}`;

export { cssStyle };