import { ITranscript } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";


const formatCurrentTranscript = (defaultValues?: FormFieldProps): ITranscript => {

  const {
    id,
    angle,
    tailType,
    text,
    width,
    xAxis,
    yAxis,
    hasShadow,
    shape,
    hasInput,
    chatTailType
  } = defaultValues ?? {};

  let formattedShape = shape;

  if (shape === 'CHAT') {
    if (chatTailType === 'sent') {
      formattedShape = 'CHAT_TO';
    } else {
      formattedShape = 'CHAT_FROM'
    }
  }

  if (shape === 'STOP_AND_THINK' && !hasInput) {
    formattedShape = 'STOP_AND_THINK_NO_INPUT';
  }

  return {
    id: id ?? 0,
    tailType,
    angle,
    text,
    w: width,
    x: xAxis,
    y: yAxis,
    hasShadow,
    sort: 0,
    shape: formattedShape,
    isTailVisible: ['DEFAULT', 'THINK'].includes(String(tailType)),
  };
};

export { formatCurrentTranscript };