import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_CHECKBOX_CONFIGURATION } from './useCreateCheckboxQuestionConfig.graphql';
import {
  ICreateCheckboxQuestionConfigMutationGraphQLResponse,
  ICreateCheckboxQuestionConfigMutationVariables,
  IHandleCreateCheckboxQuestionConfigParams,
  IUseCreateCheckboxQuestionConfigOptions,
  IUseCreateCheckboxQuestionConfig,
} from './useCreateCheckboxQuestionConfig.interfaces';

const useCreateCheckboxQuestionConfig = (options?: IUseCreateCheckboxQuestionConfigOptions): IUseCreateCheckboxQuestionConfig => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateCheckboxQuestionConfigOptions);

  const [executeCreateCheckboxQuestionConfig, { loading: isLoading }] = useEkardoMutation<ICreateCheckboxQuestionConfigMutationGraphQLResponse, ICreateCheckboxQuestionConfigMutationVariables>({
    mutation: mutation ?? CREATE_CHECKBOX_CONFIGURATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionConfiguration: { createQuestionConfiguration: configuration },
        } = data;

        onCompleted({
          configuration,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateCheckboxQuestionConfig = async ({
    allowUserGeneratedResponses,
    description,
    maxCheckedResponse,
    projectId,
    title,
    style,
  }: IHandleCreateCheckboxQuestionConfigParams) => {
    await executeCreateCheckboxQuestionConfig({
      variables: {
        allowUserGeneratedResponses,
        description,
        maxCheckedResponse,
        projectId,
        title,
        style,
      }
    });
  };

  return {
    handleCreateCheckboxQuestionConfig,
    isLoading,
  };
};

export { useCreateCheckboxQuestionConfig };
