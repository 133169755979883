import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { SORT_SECTION } from './useSortSection.graphql';
import {
  ISortSectionMutationGraphQLResponse,
  ISortSectionMutationVariables,
  IUseSortSectionOptions,
  IUseSortSection,
  ISortSectionMutationParams,
} from './useSortSection.interfaces';

const useSortSection = (options?: IUseSortSectionOptions): IUseSortSection => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseSortSectionOptions);

  const [executeSortSection, { loading: isLoading }] = useEkardoMutation<ISortSectionMutationGraphQLResponse, ISortSectionMutationVariables>({
    mutation: mutation ?? SORT_SECTION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSection: { sortSection: section },
        } = data;

        onCompleted({
          section,
        });
      },
      onError,
    },
    token,
  });

  const handleSortSection = async ({
    contentSectionId,
    sort,
    sectionGroupId,

  }: ISortSectionMutationParams) => {
    await executeSortSection({
      variables: {
        contentSectionId,
        sort,
        sectionGroupId,
      }
    });
  };

  return {
    handleSortSection,
    isLoading,
  };
};

export { useSortSection };
