
import { DELETE_TOPIC_MUTATION } from './useDeleteTopic.graphql';
import {
  IDeleteTopicMutationGraphQLResponse,
  IDeleteTopicMutationVariables,
  IHandleDeleteTopicParams,
  IUseDeleteTopicOptions,
  IUseDeleteTopic,
} from './useDeleteTopic.interfaces';

import { useBonoboMutation } from '../../useBonoboMutation';

const useDeleteTopic = (options?: IUseDeleteTopicOptions): IUseDeleteTopic => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseDeleteTopicOptions);

  const [executeDeleteTopic, { loading: isLoading }] = useBonoboMutation<
    IDeleteTopicMutationGraphQLResponse,
    IDeleteTopicMutationVariables
  >({
    mutation: mutation ?? DELETE_TOPIC_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          topic: { removeTopic: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteTopic = ({ topicId }: IHandleDeleteTopicParams) => {
    void executeDeleteTopic({
      variables: {
        topicId,
      },
    });
  };

  return {
    handleDeleteTopic,
    isLoading,
  };
};

export { useDeleteTopic };
