import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_CONTENT_SNIPPET } from './useCreateSnippet.graphql';
import {
  ICreateSnippetMutationGraphQLResponse,
  ICreateSnippetMutationVariables,
  IHandleCreateSnippetParams,
  IUseCreateSnippetOptions,
  IUseCreateSnippet,
} from './useCreateSnippet.interfaces';

const useCreateSnippet = (options?: IUseCreateSnippetOptions): IUseCreateSnippet => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateSnippetOptions);

  const [executeCreateSnippet, { loading: isLoading }] = useEkardoMutation<ICreateSnippetMutationGraphQLResponse, ICreateSnippetMutationVariables>({
    mutation: mutation ?? CREATE_CONTENT_SNIPPET,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { createContentSnippet: snippet },
        } = data;

        onCompleted({
          snippet,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateSnippet = async ({
    audioSnippet,
    baseSnippet,
    buttonSnippet,
    codeSnippet,
    documentSnippet,
    embedSnippet,
    formSnippet,
    imageSnippet,
    mapSnippet,
    textSnippet,
    videoSnippet,
    questionSnippet,
    quoteSnippet,
    summarySnippet,
  }: IHandleCreateSnippetParams) => {
    await executeCreateSnippet({
      variables: {
        audioSnippet,
        baseSnippet,
        buttonSnippet,
        codeSnippet,
        documentSnippet,
        embedSnippet,
        formSnippet,
        imageSnippet,
        mapSnippet,
        textSnippet,
        videoSnippet,
        quoteSnippet,
        summarySnippet,
        questionSnippet,
      }
    });
  };

  return {
    handleCreateSnippet,
    isLoading,
  };
};

export { useCreateSnippet };
