import { gql } from '@apollo/client';

const CREATE_APP_COMPONENT = gql`
  mutation createAppComponentRequest($inputType: AddComponentInVersionInputType!) {
    app {
      appVersionMutation {
        appVersionComponentMutation {
          addComponentInVersion(
            inputType: $inputType
          ) {
            id
            title
          }
        }
      }
    }
  }
`;

export { CREATE_APP_COMPONENT };
