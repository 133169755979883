import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetBookmarksReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { BOOKMARK_COLUMNS } from './BookmarksPage.constants';
import { getBookmarkTableData } from './BookmarksPage.helpers';
import { BookmarkCsvRowData, IBookmarkItem, IBookmarkTableData } from './BookmarksPage.interfaces';

const BookmarksPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { handleToastError, handleToastCustomError } = useToast();
  const { query: { projectId: queryProjectId } } = useRouter();

  const [projectName, setProjectName] = useState<string>();
  const [bookmarkItems, setBookmarkItems] = useState<IBookmarkItem[]>();
  const [allBookmarkItems, setAllBookmarkItems] = useState<IBookmarkItem[]>([]);
  const [bookmarkTableData, setBookmarksTableData] = useState<IBookmarkTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>();
  const [csvUrl, setCsvUrl] = useState<string>();
  const [projectId, setProjectId] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const {
    handleGetBookmarksReport,
    isLoading: isGetBookmarksLoading = false,
  } = useGetBookmarksReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<BookmarkCsvRowData>(String(res.data), {
            header: true,
          skipEmptyLines: true,
          });

          const formattedItems = data.map((row: BookmarkCsvRowData) => ({
            id: Number(row['Author id']),
            groupName: String(row['Group name']),
            createdOn: String(row['Creation date']),
            createdOnUtc: String(row['Creation date (utc)']),
            moduleName: String(row['Module name']),
            userType: String(row['User type']),
            pageUrl: String(row['Page url']),
          }));

          setTotalCount(data.length);
          setAllBookmarkItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setBookmarkItems(paginatedItems);
          setLastItemIndex(pageSize);
        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    if (!projectId) return;

    void handleGetBookmarksReport({
      dateFrom: dateRange && dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange && dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fileType: 'CSV',
      projectId,
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setAllBookmarkItems(allBookmarkItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setAllBookmarkItems(allBookmarkItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!bookmarkItems) {
      return;
    }

    setBookmarksTableData(
      getBookmarkTableData({
        items: bookmarkItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarkItems]);


  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    if (!queryProjectId) return;
    setProjectId(queryProjectId as string);
  }, [queryProjectId])

  const isLoading = isGetBookmarksLoading;

  return (
    <ReportTable<IBookmarkTableData>
      activePath={['reporting', 'reporting-content', 'export-bookmarks']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Bookmarks',
      }]}
      columns={BOOKMARK_COLUMNS}
      csvUrl={csvUrl}
      data={bookmarkTableData}
      description={`All bookmark reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports user bookmarks based on the selected filters'}
      isGenerateButtonDisabled={!projectId}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { BookmarksPage };
