import { ApolloQueryResult } from '@apollo/client';
import { useBonoboLazyQuery } from 'hooks';

import { GET_TOPICS_QUERY } from './useGetPaginatedTopics.graphql';
import {
  IGetPaginatedTopicsQueryGraphQLResponse,
  IGetPaginatedTopicsQueryVariables,
  IHandleFetchMorePaginatedTopicsParams,
  IHandleGetPaginatedTopicsParams,
  IUseGetPaginatedTopics,
  IUseGetPaginatedTopicsOptions,
} from './useGetPaginatedTopics.interfaces';

const useGetPaginatedTopics = (options?: IUseGetPaginatedTopicsOptions): IUseGetPaginatedTopics => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: bonoboProjectId } = options ?? ({} as IUseGetPaginatedTopicsOptions);

  const [executeGetPaginatedTopics, { fetchMore, loading: isLoading }] = useBonoboLazyQuery<
  IGetPaginatedTopicsQueryGraphQLResponse,
  IGetPaginatedTopicsQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          topic: { getTopics: topicConnection },
        } = data;

        onCompleted({
          topicConnection,
        });
      },
      onError,
    },
    query: query ?? GET_TOPICS_QUERY,
    token,
  });

  const handleFetchMorePaginatedTopics = ({
    after,
    filter,
    projectId,
    status,
    first,
  }: IHandleFetchMorePaginatedTopicsParams): Promise<ApolloQueryResult<IGetPaginatedTopicsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          topic: { getTopics: newlyFetchedTopicConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedTopicConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          topic: { getTopics: previouslyFetchedTopicConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedTopicConnection;

        return {
          ...previousQueryResult,
          topic: {
            ...previouslyFetchedTopicConnection,
            getTopics: {
              ...newlyFetchedTopicConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        filter,
        projectId,
        status,
        first,
      },
    });
  };

  const handleGetPaginatedTopics = ({
    after,
    filter,
    projectId,
    status,
    first,
  }: IHandleGetPaginatedTopicsParams) => {
    void executeGetPaginatedTopics({
      variables: {
        after,
        filter,
        projectId,
        status,
        first,
      },
    });
  };

  return {
    handleFetchMorePaginatedTopics,
    handleGetPaginatedTopics,
    isLoading,
  };
};

export { useGetPaginatedTopics };
