import { gql } from '@apollo/client';

const ATTACH_CSS_STYLE_TO_SECTION = gql`
  mutation($sectionId: Int!, $styleId: Int!) {
    cssStyle {
      attachStyleToContentSection(contentSectionId: $sectionId, styleId: $styleId)
    }
  }
`;


export { ATTACH_CSS_STYLE_TO_SECTION };
