import React, { useState, useRef, useEffect, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { IContentGroup } from '@netfront/ekardo-content-library';
import { ControlledForm, FilmIcon, FormFieldProps, GeneralTabIcon, SidebarButtons, SingleCheckbox, Spinner, StyleTabIcon, TabSet, TabSetImperativeHandleRef, UsageIcon, useControlledForm } from '@netfront/ui-library';
import { AnimationTab, EventTab, UpsertFormSnippetGeneralTab, animationTabValidation, eventTabValidation, handleSelectContentGroup, StyleTab } from 'components';
import { CachingEntitiesContext } from 'context';
import { useUpsertAssetWrapper, useUpsertForm, useUpsertSnippet } from 'hooks';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getFormSnippetCommonVariables, getFormSnippetDefaultValues, setQuickAddFormValues } from './UpsertFormSnippetSidebarView.helpers';
import { UpsertFormSnippetSidebarViewProps } from './UpsertFormSnippetSidebarView.interfaces';


const UpsertFormSnippetSidebarView = ({ containerId, onClose, onCreate, onDelete, onUpdate, snippet, sort, isLoading: isSnippetLoading = false, handleUpdateSortValue }: UpsertFormSnippetSidebarViewProps) => {

  const { project } = useContext(CachingEntitiesContext);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const droppedFileEventAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalEventImageRef = useRef<{value: boolean }>({ value: false });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { control, handleSubmit, reset, setValue, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        ...eventTabValidation,
        ...animationTabValidation,
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const { handleUpsertAsset: handleUpsertEventAsset, isLoading: isUpsertEventAssetLoading = false } = useUpsertAssetWrapper();

  const { handleUpsertForm, isLoading: isUpsertFormLoading = false } = useUpsertForm();

  const { handleUpsertSnippet, isLoading: isUpsertSnippetLoading = false } = useUpsertSnippet({
    onCreate: () => {
      const { isCreateNew = false } = getValues();

      if (isCreateNew) {
        droppedFileEventAssetRef.current.value = undefined;
        hasDeletedOriginalEventImageRef.current.value = false;
        handleUpdateSortValue(sort + 1);
        setDefaultValues(setQuickAddFormValues(getValues()));
      } else {
        reset();
      }
      onCreate(isCreateNew as boolean);
    },
    onUpdate: (returnedSnippet) => {
      reset();
      onUpdate(returnedSnippet);
    }
  });


  const handleDropFile = (uploadedFile?: File) => {
    droppedFileEventAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalEventImageRef.current.value = true;
  };

  const handleSelectFormFromLibrary = ({ contentGroup }: {contentGroup: IContentGroup }) => {
    handleSelectContentGroup({contentGroup, setValue });
  };


  const handleSave = (item: FormFieldProps) => {
    handleUpsertEventAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(project?.id),
      uploadedFile: droppedFileEventAssetRef.current.value,
      deletedFileId: hasDeletedOriginalEventImageRef.current.value && defaultValues.assetId ? defaultValues.assetId : undefined,
      callBack: (asset?: IDBAsset) => {

        const { hasChosenFromLibrary = false, title } = item;

        handleUpsertForm({
          projectId: String(project?.id),
          hasUpsert: !hasChosenFromLibrary,
          isCreate: !snippet,
          item: {
            ...item,
            title: !snippet && !hasChosenFromLibrary ? title : defaultValues.formTitle,
          },
          callBack: ({ contentGroup }: { contentGroup?: IContentGroup }) => {
            const { id } = contentGroup ?? {};
            const commonVariables = getFormSnippetCommonVariables({
              eventAssetId: asset ? String(asset.assetId): undefined,
              item,
              contentSnippetFormId: id,
            });

            handleUpsertSnippet({
              containerId,
              snippetId: snippet? Number(snippet.id): undefined,
              sort,
              variables: commonVariables,
            });
          }
        });


      }
    });
  };

  useEffect(() => {
    setDefaultValues(getFormSnippetDefaultValues({ snippet }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet]);


  const isLoading =
    isUpsertSnippetLoading ||
    isUpsertEventAssetLoading ||
    isSnippetLoading ||
    isUpsertFormLoading;
  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
        <TabSet
          ref={tabsetRef}
          defaultActiveTabId="id_general_tab"
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => isLoading ? <></> : (
                <UpsertFormSnippetGeneralTab
                  control={control as Control<FormFieldProps>}
                  initialView={defaultValues.view}
                  isCreate={!snippet}
                  projectId={String(project?.id)}
                  setValue={setValue}
                  onChooseFromLibrary={handleSelectFormFromLibrary}
                />
              ),
            },
            {
              icon: UsageIcon,
              id: 'id_event_tab',
              label: 'Event',
              view: () => isLoading ? <></> : (
                <EventTab
                  control={control as Control<FormFieldProps>}
                  initialEvent={defaultValues.event}
                  onDeleteAsset={handleRemoveAsset}
                  onDrop={handleDropFile}
                />
              ),
            },
            {
              icon: FilmIcon,
              id: 'id_animation_tab',
              label: 'Animation',
              view: () => isLoading ? <></> : (
                <AnimationTab
                  animationType={defaultValues.animation ?? ''}
                  control={control as Control<FormFieldProps>}
                />
              ),
            },
            {
              icon: StyleTabIcon,
              id: 'id_style_tab',
              label: 'Style',
              view: () => isLoading ? <></> : (
                <StyleTab
                  control={control as Control<FormFieldProps>}
                  entityType="targetSnippet"
                />
              ),
            },
          ]}
        />
        <SidebarButtons
          additionalButton={!snippet ? (
            <Controller
              control={control as Control<FormFieldProps>}
              name="isCreateNew"
              render={({ field }) => (
                <SingleCheckbox
                  hasPadding={false}
                  id="id_is_create_new"
                  isChecked={field.value}
                  labelText="Create another snippet"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          ) : undefined
          }
          onCancel={onClose}
          onDelete={onDelete}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </>
  );
};


export { UpsertFormSnippetSidebarView };
