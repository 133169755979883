import { useContext, useEffect, useState } from 'react';


import { ApolloError } from '@apollo/client';
import { useGetGeladaProjectsForConnectedUser } from '@netfront/gelada-identity-library';
import { CheckboxGroup, ControlledForm, FormFieldProps, GeneralTabIcon, ICheckboxItem, Input, SidebarButtons, SideBarTabSet,  Spacing,  Spinner, useControlledForm } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetAppLinkedProjects, useToast, useUpdateAppLinkedProjects } from 'hooks';
import { Control, Controller } from 'react-hook-form';
import { sortObjectArrayAlphabetically } from 'utils';

import { getAppAddAppToProjectDefaultValues } from './AddAppToProjectSidebarView.helpers';
import { AddAppToProjectSidebarViewProps } from './AddAppToProjectSidebarView.interfaces';


const AddAppToProjectSidebarView = ({
  isSideBarOpen = false,
  onCancel,
  onUpdate,
  appIdentifier,
  appId,
}: AddAppToProjectSidebarViewProps) => {

  const { handleToastError, handleToastSuccess } = useToast();
  const { organisation }= useContext(CachingEntitiesContext);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({ name: '', image: ''});
  const [projectOptions, setProjectOptions] = useState<ICheckboxItem[]>([]);
  const [organisationProjectIds, setOrganisationProjectIds] = useState<string[]>([]);

  const { control, handleSubmit, reset } = useControlledForm({ defaultValues });

  const handleError = (error: ApolloError) => {
    handleToastError({
      error,
    });
  };

  const { handleGetAppLinkedProjects, isLoading: isGetAppLinkedProjectsLoading = false } = useGetAppLinkedProjects({
    onCompleted: ({ projects: { edges} }) => {
      const projectIds: string[] = edges.map(({ node: { id } }) => id);
      const filteredProjectIds = projectIds.filter((projectId) => organisationProjectIds.includes(projectId));
      setDefaultValues(getAppAddAppToProjectDefaultValues(filteredProjectIds));
    },
    onError: handleError,
  });
  
  const { handleGetGeladaProjectsForConnectedUser, isLoading: isGetGeladaProjectsLoading = false } =
  useGetGeladaProjectsForConnectedUser({
    onCompleted: ({ geladaProjects = [] }) => {
      const sortedOptions = sortObjectArrayAlphabetically(geladaProjects.map(({ id, name }) => ({
        id: String(id),
        labelText: name,
        value: id.toString(),
      })), 'labelText');
      setProjectOptions(sortedOptions.slice(10, 20));

      setOrganisationProjectIds(geladaProjects.map(({id}) => id));

      handleGetAppLinkedProjects({
        appId,
      }) 
    },
    onError: handleError,
  });




  const { handleUpdateAppLinkedProjects, isLoading: isUpdateAppLinkedProjectsLoading = false } = useUpdateAppLinkedProjects({
    onUpdate: () => {
      handleToastSuccess({ message: 'Entity linking successfully updated'});
      reset();
      onUpdate();

    },
  });


  const handleOnCancel = () => {
    reset();
    onCancel();
  };

  const handleSave = (item: FormFieldProps) => {
    const { projectIds = [], initialProjectIds = [] } = item;

    handleUpdateAppLinkedProjects({
      appIdentifier,
      linkProjectIds: (projectIds as string[]).filter((id) => !(initialProjectIds as string[]).includes(id)),
      unlinkProjectIds: (initialProjectIds as string[]).filter((id) => !(projectIds as string[]).includes(id)),
    });
  };


  useEffect(() => {
    if (!(organisation && isSideBarOpen)) return;
    void handleGetGeladaProjectsForConnectedUser({
      organisationId: organisation.id,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSideBarOpen, organisation])

  const isLoading = isGetGeladaProjectsLoading || isGetAppLinkedProjectsLoading || isUpdateAppLinkedProjectsLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
      >


        <SideBarTabSet
          defaultActiveTabId="id_comment_general_tab"
          handleOpenCloseSideBar={handleOnCancel}
          hasViewPadding={false}
          hideSideBarButtons={true}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_comment_general_tab',
              label: 'General',
              view: () =>
                <SidebarContainer>
                  <Spacing>
                    <Input
                      id="id_organisation_name"
                      labelText="Title"
                      name="organisation_name"
                      type="text"
                      value={organisation?.name}
                      isDisabled
                      isLabelSideBySide
                      isReadOnly
                      onChange={() => { return; }}                      
                    />
                  </Spacing>
                  <Spacing>
                  <Controller
                    control={control as Control<FormFieldProps>}
                    name="projectIds"
                    render={({ field }) => (

                      <CheckboxGroup
                        items={projectOptions}
                        legendText="Projects"
                        values={field.value}
                        isLabelSideBySide
                        {...field}
                      />

                    )}
                  />
                  </Spacing>
                  <SidebarButtons
                    buttonSize="xs"
                    isDeleteButtonDisabled={isLoading}
                    isSaveButtonDisabled={isLoading}
                    onCancel={handleOnCancel}
                    onSaveButtonType="submit"
                  />
                </SidebarContainer>
            },
          ]}

        />
      </ControlledForm>
    </>
  );
};

export { AddAppToProjectSidebarView };
