import React, { ChangeEvent, useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, Input, Message, Select, SelectWithSearch, Spinner, ISearchList, InputFieldWrapper, ToggleSwitch, FieldsetAccordionGroup, ButtonIconOnly, FlexContainer, SidebarDialog, SingleDatePicker, PlusIcon } from '@netfront/ui-library';
import { ContentBuilderTabWrapper, CreateResponseSet, SidebarContainer, TextEditor } from 'components';
import { useGetResponseSetSuggestions, useToast } from 'hooks';
import { useRouter } from 'next/router';
import { Controller } from 'react-hook-form';
import { createSyntheticEvent } from 'utils';

import { getQuestionResponseTypeOptions } from './UpsertQuestionSnippetGeneralTab.helpers';
import { UpsertQuestionSnippetGeneralTabProps } from './UpsertQuestionSnippetGeneralTab.interfaces';

import { responseTypeToUnderScoredConst } from '../../../../../../constants';


const UpsertQuestionSnippetGeneralTab = ({ control, isNewQuestion = true, setValue, responseType, isFormsMode = false }: UpsertQuestionSnippetGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError } = useToast();
  const [resType, setResType] = useState<string>();
  const [responseSetTitle, setResponseSetTitle] = useState<string>();
  const [responseSetId, setResponseSetId] = useState<number>();

  const [projectId, setProjectId ] = useState<string>('');

  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [isCreateResponseSetDialogOpen, setIsCreateResponseSetDialogOpen] = useState<boolean>(false);

  const [searchSuggestions, setSearchSuggestions] = useState<ISearchList[]>([]);

  const { handleGetResponseSetSuggestions, isLoading: isGetResponseSetSuggestionsLoading = false } = useGetResponseSetSuggestions({
    onCompleted: ({ searchResponseSet }) => {
      const suggestions = !responseSetId
        ? searchResponseSet.map(({ id, title }) => ({
          id,
          label: String(title),
        }))
        : [] as ISearchList[];
      setSearchSuggestions(suggestions);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleSearchItemClick = (selectedId: string | number) => {
    setValue('responseSetId', Number(selectedId));
    setResponseSetId(Number(selectedId));
    const selectedTitle = searchSuggestions.find(({ id }) => id === Number(selectedId))?.label ?? '';
    setResponseSetTitle(selectedTitle);
  };

  const handleCloseResponseDialog = () => setIsCreateResponseSetDialogOpen(false);
  const handleOpenResponseDialog = () => setIsCreateResponseSetDialogOpen(true);

  const handleSaveResponse = ({ title, id }: {id: number; title: string }) => {
    const updatedSuggestions = [
      ...searchSuggestions,
      {
        label: title,
        id,
      },
    ];

    setValue('responseSetId', id);
    setValue('responseSetTitle', title);
    setResponseSetId(id)
    setResponseSetTitle(title);

    setSearchSuggestions(updatedSuggestions);
    handleCloseResponseDialog();
  };


  useEffect(() => {
    if (!isNewQuestion) return;
    if (!(resType && projectId)) return;

    if (
      ![
        responseTypeToUnderScoredConst.DropDownList,
        responseTypeToUnderScoredConst.Match,
        responseTypeToUnderScoredConst.Radio,
        responseTypeToUnderScoredConst.Checkbox
      ].includes(resType)
    ) return;


    void handleGetResponseSetSuggestions({
      projectId,
      type: String(resType),
      title: '',
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resType, projectId, isNewQuestion]);

  useEffect(() => {
    if (!queryProjectId) return
    setProjectId(queryProjectId as string);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProjectId]);

  useEffect(() => {
    setResType(responseType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseType]);

  return (
    <ContentBuilderTabWrapper
      hasContainerWrapper={false}
    >
      <Spinner isLoading={isGetResponseSetSuggestionsLoading} />
        {isNewQuestion && !isFormsMode && (
          <SidebarContainer hasPaddingEnds={false}>
            <Controller
              control={control}
              name="isCreateForm"
              render={({ field }) => (
                <ToggleSwitch
                  additionalClassNames={`c-sidebar-toggle`}
                  id="id_is_create_form"
                  inlineLabelPosition="start"
                  isChecked={field.value}
                  labelText="Create a re-usable form"
                  isInline
                  {...field}
                />
              )}
            />
          </SidebarContainer>
        )}

      <FieldsetAccordionGroup
        data={[
          {
            id: 'id_question_accordion',
            label: 'Question',
            isOpenOnLoad: true,
            children: (
              <>
                <Spacing>
                  <Controller
                    control={control}
                    name="resType"
                    render={({ field, fieldState }) => (
                      <Select
                        errorMessage={getFormFieldErrorMessage(fieldState)}
                        id="id_response_type"
                        isDisabled={!isNewQuestion}
                        labelText="Type"
                        options={getQuestionResponseTypeOptions(['Rating', 'Slider', 'Match'])}
                        tooltipPosition="start"
                        tooltipText="Select what type of response you would like to use"
                        isLabelSideBySide
                        isRequired
                        {...field}
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                          const { target: { value }} = event;
                          setResType(value);
                          if (![responseTypeToUnderScoredConst.Radio, responseTypeToUnderScoredConst.Checkbox].includes(value)) setValue('allowUserGeneratedResponses', false);
                          field.onChange(event);
                        }}
                      />
                    )}
                  />
                </Spacing>

                <Spacing>
                  <Controller
                    control={control}
                    name="question"
                    render={({ field, fieldState }) =>  (
                      <TextEditor
                        errorMessage={getFormFieldErrorMessage(fieldState)}
                        id="id_question_text"
                        initialValue={field.value}
                        label="Question"
                        isRequired
                        onInputChange={(content: string) => {
                          field.onChange(createSyntheticEvent('text', content))
                        }}
                      />
                    )}
                  />
                </Spacing>

                <Spacing>
                  <Controller
                    control={control}
                    name="behavior"
                    render={({ field, fieldState }) => (
                        <Select
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_behavior"
                          labelText="Behavior"
                          options={[
                            {
                              name: 'Mandatory',
                              value: 'MANDATORY',
                              id: 1,
                            },
                            {
                              name: 'Not required',
                              value: 'NOT_REQUIRED',
                              id: 2,
                            },
                            {
                              name: 'Prompt',
                              value: 'PROMPT',
                              id: 3,
                            },
                          ]}
                          tooltipPosition="end"
                          tooltipText="Is the question optional or must it be answered"
                          isLabelSideBySide
                          isRequired
                          {...field}
                        />
                      )
                    }
                  />
                </Spacing>
              </>
            ),
          },
          {
            id: 'id_responses_accordion',
            label: 'Responses',
            isOpenOnLoad: true,
            isVisible: [
              responseTypeToUnderScoredConst.DropDownList,
              responseTypeToUnderScoredConst.Match,
              responseTypeToUnderScoredConst.Radio,
              responseTypeToUnderScoredConst.Checkbox,
              responseTypeToUnderScoredConst.Slider,
            ].includes(resType as string),
            children: (
              <Spacing>
                {!isNewQuestion &&
                  [
                    responseTypeToUnderScoredConst.DropDownList,
                    responseTypeToUnderScoredConst.Match,
                    responseTypeToUnderScoredConst.Radio,
                    responseTypeToUnderScoredConst.Checkbox,
                    responseTypeToUnderScoredConst.Slider,
                  ].includes(resType as string) && (
                    <Controller
                      control={control}
                      name="responseSetTitle"
                      render={({ field, fieldState }) => (
                        <Input
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_response_set_title"
                          labelText="Responses"
                          type="text"
                          isLabelSideBySide
                          {...field}
                          isDisabled
                        />
                      )}
                  />
                  )
                }

                {isNewQuestion && [
                  responseTypeToUnderScoredConst.DropDownList,
                  responseTypeToUnderScoredConst.Match,
                  responseTypeToUnderScoredConst.Radio,
                  responseTypeToUnderScoredConst.Checkbox,
                ].includes(resType as string) && (
                  <SelectWithSearch
                    buttonText="Search existing response sets"
                    countText="Response sets"
                    defaultValue={responseSetTitle}
                    id="id_response_set_select"
                    isDisplaySearchContent={isSearchContentVisible}
                    labelText="Search"
                    searchList={searchSuggestions.map(({id, label: searchSuggestionLabel }) => ({
                      id,
                      label: searchSuggestionLabel,
                    }))}
                    isAvatarVisible
                    isLabelSideBySide
                    onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
                    onSearchItemClick={handleSearchItemClick}
                  />
                )}

                {isNewQuestion && [responseTypeToUnderScoredConst.Slider].includes(resType as string) && (
                  <SelectWithSearch
                    buttonText="Search existing response sets"
                    countText="Response sets"
                    defaultValue={responseSetTitle}
                    id="id_slider_response_set_select"
                    isDisplaySearchContent={isSearchContentVisible}
                    labelText="Search"
                    searchList={searchSuggestions.map(({id, label: searchSuggestionLabel }) => ({
                      id,
                      label: searchSuggestionLabel,
                    }))}
                    isAvatarVisible
                    isLabelSideBySide
                    onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
                    onSearchItemClick={handleSearchItemClick}
                  />
                )}

                {isNewQuestion && [
                  responseTypeToUnderScoredConst.DropDownList,
                  responseTypeToUnderScoredConst.Match,
                  responseTypeToUnderScoredConst.Radio,
                  responseTypeToUnderScoredConst.Checkbox,
                ].includes(resType as string) && (
                  <InputFieldWrapper
                    id="id_create_response_set"
                    label="Create"
                    labelType="span"
                    type="custom"
                    isLabelSideBySide
                  >
                    <FlexContainer justifyContent="flex-end" isFullWidth>
                      <ButtonIconOnly icon={PlusIcon} text="Add response set" onClick={handleOpenResponseDialog} />
                    </FlexContainer>
                  </InputFieldWrapper>
                )}
              </Spacing>
            ),
          },
          {
            id: 'id_advanced_accordion',
            label: 'Advanced',
            isOpenOnLoad: true,
            children: (
              <Spacing>
                {[responseTypeToUnderScoredConst.Radio, responseTypeToUnderScoredConst.Checkbox].includes(resType as string) && (
                  <Spacing>
                    <Controller
                      control={control}
                      name="allowUserGeneratedResponses"
                      render={({ field, fieldState }) => {
                        const errorMessage = getFormFieldErrorMessage(fieldState);
                        return (
                          <>
                            <ToggleSwitch
                              additionalClassNames={`c-sidebar-toggle`}
                              id="id_allow_generated_responses"
                              inlineLabelPosition="start"
                              isChecked={field.value}
                              labelText="Allow customer user responses"
                              isInline
                              {...field}
                            />
                            {Boolean(errorMessage) && <Message id={`id_allow_user_generated_responses_error`} text={errorMessage} type="ERROR" />}
                          </>
                        );
                      }}
                    />
                  </Spacing>
                )}
                <Spacing>
                  <Controller
                    control={control}
                    name="majorNumber"
                    render={({ field, fieldState }) => (
                      <Input
                        errorMessage={getFormFieldErrorMessage(fieldState)}
                        id="id_question_number"
                        labelText="Question number"
                        tooltipPosition="start"
                        tooltipText="If this question should have a custom number other than its order"
                        type="text"
                        isLabelSideBySide
                        {...field}
                      />
                    )}
                  />
                </Spacing>

                <Spacing>
                  <Controller
                    control={control}
                    name="identifier"
                    render={({ field, fieldState }) => (
                      <Input
                        errorMessage={getFormFieldErrorMessage(fieldState)}
                        id="id_question_identifier"
                        labelText="Identifier"
                        tooltipPosition="start"
                        tooltipText="If used, this identifier will be exported as the column header in all project reports"
                        type="text"
                        isLabelSideBySide
                        {...field}
                      />
                    )}
                  />
                </Spacing>

                <Spacing>
                  <Controller
                    control={control}
                    name="isVisible"
                    render={({ field, fieldState }) => {
                      const errorMessage = getFormFieldErrorMessage(fieldState);
                      return (
                        <>
                          <ToggleSwitch
                            additionalClassNames={`c-sidebar-toggle`}
                            id="id_is_visible"
                            inlineLabelPosition="start"
                            isChecked={field.value}
                            labelText="Visible on load"
                            tooltipPosition="start"
                            tooltipText="Determine whether the form is visible by default"
                            isInline
                            {...field}
                          />
                          {Boolean(errorMessage) && <Message id={`id_is_visible_error`} text={errorMessage} type="ERROR" />}
                        </>
                      );
                    }}
                  />
                </Spacing>

                { [responseTypeToUnderScoredConst.SingleText, responseTypeToUnderScoredConst.MultiText].includes(resType as string) && (
                  <>
                    <Spacing>
                      <Controller
                        control={control}
                        name="minLength"
                        render={({ field, fieldState }) => (
                          <Input
                            errorMessage={getFormFieldErrorMessage(fieldState)}
                            id="id_min_length"
                            labelText="Minimum length"
                            type="number"
                            isLabelSideBySide
                            isRequired
                            {...field}
                          />
                        )}
                      />
                    </Spacing>
                    <Spacing>
                      <Controller
                        control={control}
                        name="maxLength"
                        render={({ field, fieldState }) => (
                          <Input
                            errorMessage={getFormFieldErrorMessage(fieldState)}
                            id="id_max_length"
                            labelText="Maximum length"
                            type="number"
                            isLabelSideBySide
                            isRequired
                            {...field}
                          />
                        )}
                      />
                    </Spacing>
                    <Spacing>
                      <Controller
                          control={control}
                          name="regex"
                          render={({ field, fieldState }) => (
                            <Input
                              errorMessage={getFormFieldErrorMessage(fieldState)}
                              id="id_regex"
                              labelText="Regex"
                              type="text"
                              isLabelSideBySide
                              {...field}
                            />
                          )}
                        />
                    </Spacing>
                  </>
                )}

                {resType === responseTypeToUnderScoredConst.Checkbox && (
                  <Spacing>
                    <Controller
                      control={control}
                      name="maxCheckedResponse"
                      render={({ field, fieldState }) => (
                        <Input
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_max_checked_responses"
                          labelText="Max checked responses"
                          type="number"
                          isLabelSideBySide
                          isRequired
                          {...field}
                        />
                      )}
                    />
                  </Spacing>
                )}

                {resType === responseTypeToUnderScoredConst.Number && (
                  <>
                    <Spacing>
                      <Controller
                        control={control}
                        name="minValue"
                        render={({ field, fieldState }) => (
                          <Input
                            errorMessage={getFormFieldErrorMessage(fieldState)}
                            id="id_min_value"
                            labelText="Minimum value"
                            type="number"
                            isLabelSideBySide
                            isRequired
                            {...field}
                          />
                        )}
                      />
                    </Spacing>
                    <Spacing>
                      <Controller
                        control={control}
                        name="maxValue"
                        render={({ field, fieldState }) => (
                          <Input
                            errorMessage={getFormFieldErrorMessage(fieldState)}
                            id="id_max_value"
                            labelText="Maximum value"
                            type="number"
                            isLabelSideBySide
                            isRequired
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="defaultValue"
                        render={({ field, fieldState }) => (
                          <Input
                            errorMessage={getFormFieldErrorMessage(fieldState)}
                            id="id_default_value"
                            labelText="Default value"
                            type="number"
                            isLabelSideBySide
                            {...field}
                          />
                        )}
                      />
                    </Spacing>
                  </>
                )}

                {resType === responseTypeToUnderScoredConst.Calendar && (
                  <>
                    <Spacing>
                      <Controller
                        control={control}
                        name="minDate"
                        render={({ field, fieldState }) => (
                          <SingleDatePicker 
                            dateInputProps={{
                              labelText: 'Minimum date',
                              errorMessage: getFormFieldErrorMessage(fieldState),
                              id: 'id_min_date',
                              name: field.name,
                              type: 'text',
                              isLabelSideBySide: true,
                            }}
                            {...field}
                            selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
                            onSelected={(dates) => setValue('minDate', dates[0])} 
                          />
                        )}
                      />
                    </Spacing>
                    <Spacing>
                      <Controller
                        control={control}
                        name="maxDate"
                        render={({ field, fieldState }) => (
                          <SingleDatePicker 
                            dateInputProps={{
                              labelText: 'Maximum date',
                              errorMessage: getFormFieldErrorMessage(fieldState),
                              id: 'id_max_date',
                              name: field.name,
                              type: 'text',
                              isLabelSideBySide: true,
                            }}
                            {...field}
                            selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
                            onSelected={(dates) => setValue('maxDate', dates[0])} 
                          />
                        )}
                      />
                    </Spacing>
                    <Spacing>
                      <Controller
                        control={control}
                        name="defaultDate"
                        render={({ field, fieldState }) => (
                          <SingleDatePicker 
                            dateInputProps={{
                              labelText: 'Default date',
                              errorMessage: getFormFieldErrorMessage(fieldState),
                              id: 'id_default_date',
                              name: field.name,
                              type: 'text',
                              isLabelSideBySide: true,
                            }}
                            {...field}
                            selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
                            onSelected={(dates) => setValue('defaultDate', dates[0])} 
                          />
                        )}
                      />
                    </Spacing>
                  </>
                )}

                {resType === responseTypeToUnderScoredConst.MultiResponseText && (
                  <>
                    <Spacing>
                      <Controller
                        control={control}
                        name="minResponse"
                        render={({ field, fieldState }) => (
                          <Input
                            errorMessage={getFormFieldErrorMessage(fieldState)}
                            id="id_min_responses"
                            labelText="Minimum responses"
                            type="number"
                            isLabelSideBySide
                            {...field}
                          />
                        )}
                      />
                    </Spacing>

                    <Spacing>
                      <Controller
                        control={control}
                        name="maxResponse"
                        render={({ field, fieldState }) => (
                          <Input
                            errorMessage={getFormFieldErrorMessage(fieldState)}
                            id="id_max_responses"
                            labelText="Maximum responses"
                            type="number"
                            isLabelSideBySide
                            {...field}
                          />
                        )}
                      />
                    </Spacing>
                  </>
                )}
              </Spacing>
            ),
          },
        ]}
      />

      <SidebarDialog
        isOpen={isCreateResponseSetDialogOpen}
        onClose={handleCloseResponseDialog}
      >
        <>
          {[
            responseTypeToUnderScoredConst.DropDownList,
            responseTypeToUnderScoredConst.Radio,
            responseTypeToUnderScoredConst.Checkbox,
          ].includes(resType as string) && (
            <CreateResponseSet
              additionalBreadcrumbs={[
                {
                  key: 'question',
                  content: 'Add question',
                  onClick: handleCloseResponseDialog
                },
                {
                  key: 'response_set',
                  content: <span className="c-breadcrumb-item__link">New response</span> ,
                }
              ]}
              projectId={projectId}
              responseType={String(resType)}
              onCancel={handleCloseResponseDialog}
              onSave={handleSaveResponse}
            />
          )}
          {/* {[responseTypeToUnderScoredConst.Match].includes(resType as string) && (
            <CreateMatchResponseSet
              projectId={projectId}
              onCancel={handleCloseResponseDialog}
              onSave={handleSaveResponse}
            />
          )}

          {[responseTypeToUnderScoredConst.Slider].includes(resType as string) && (
            <CreateSliderResponseSet
              projectId={projectId}
              responseType={String(resType)}
              onCancel={handleCloseResponseDialog}
              onSave={handleSaveResponse}
            />
          )} */}
        </>
      </SidebarDialog>
  </ContentBuilderTabWrapper>
  );
};

export { UpsertQuestionSnippetGeneralTab };
