import { gql } from '@apollo/client';

const DUPLICATE_CONTENT_PAGE = gql`
  mutation CopyContentPage($contentPageId: Int!) {
    contentPage {
      copyContentPage(contentPageId: $contentPageId)
    }
  }
`;


export { DUPLICATE_CONTENT_PAGE };
