import { useContext, useEffect, useState } from 'react';


import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, CssIcon, Dialog, FormFieldProps, GeneralTabIcon, HtmlIcon, Input, JsIcon, SideBarTabSet,  Spacing,  Spinner, Textarea, useControlledForm } from '@netfront/ui-library';
import { convertToFile, SidebarContainer } from 'components';
import { AppDetailsContext } from 'context';
import {  
  useDeleteAppComponent, 
  useToast, 
  useUpdateAppComponentDetails, 
  useUpsertAssetWrapper as useUpsertHtmlAssetWrapper,
  useUpsertAssetWrapper as useUpsertCssAssetWrapper,
  useUpsertAssetWrapper as useUpsertJsAssetWrapper, 
} from 'hooks';
import { useRouter } from 'next/router';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';


import { getAppComponentDetailsDefaultValues } from './UpsertComponentCodeSidebarView.helpers';
import { UpsertComponentCodeSidebarViewProps } from './UpsertComponentCodeSidebarView.interfaces';


const UpsertComponentCodeSidebarView = ({
  currentComponent,
  isReadOnly = false,
  componentFileDetails,
  onCancel,
  isSideBarOpen = false,
  onUpdate,
  projectId,
}: UpsertComponentCodeSidebarViewProps) => {

  const { handleToastError, handleToastSuccess } = useToast();
  const { push } = useRouter();
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { appPageBaseUrl } = useContext(AppDetailsContext);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [cssAssetId, setCssAssetId] = useState<string>();
  const [htmlAssetId, setHtmlAssetId] = useState<string>();
  const [jsAssetId, setJsAssetId] = useState<string>();

  const { control, handleSubmit, reset, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        htmlContent: yup.string().label('Html').required(),
      }),
    ),
  });

  const handleError = (error: ApolloError) => {
    handleToastError({
      error,
    });
  };

  const { handleDeleteAppComponent, isLoading: isDeleteAppComponentLoading = false } = useDeleteAppComponent({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      handleToastSuccess({ message: 'Component successfully deleted'});
      reset();
      onUpdate();
      push(`${appPageBaseUrl}/${String(currentComponent.appVersionId)}/components`).catch((error) =>
        handleToastError({
          error,
        }),
      );

    },
    onError: handleError,
  });


  const { handleUpdateAppComponentDetails, isLoading: isUpdateAppComponentDetailsLoading = false } = useUpdateAppComponentDetails({
    onCompleted: () => {
      handleToastSuccess({ message: 'Component successfully updated'});
      reset();
      setCssAssetId('');
      setHtmlAssetId('');
      setJsAssetId('');
      onUpdate();

    },
    onError: handleError,
  });

  const { handleUpsertAsset: handleUpsertHtmlAsset, isLoading: isUpsertHtmlAssetLoading = false } = useUpsertHtmlAssetWrapper();
  const { handleUpsertAsset: handleUpsertCssAsset, isLoading: isUpsertCssAssetLoading = false } = useUpsertCssAssetWrapper();
  const { handleUpsertAsset: handleUpsertJsAsset, isLoading: isUpsertJsAssetLoading = false } = useUpsertJsAssetWrapper();

  const handleOnCancel = () => {
    reset();
    onCancel();
  };

  const handleSave = (item: FormFieldProps) => {
    const { htmlContent = '', cssContent = '', jsContent = '' } = item;

    const htmlFile = convertToFile(String(htmlContent), 'html');
    const cssFile = convertToFile(String(cssContent), 'css');
    const jsFile = convertToFile(String(jsContent), 'js');

    handleUpsertHtmlAsset({
      assetType: 'document',
      isCreate: true,
      projectId: String(projectId),
      uploadedFile: htmlFile,
      callBack: (asset?: IDBAsset) => {
        setHtmlAssetId(asset?.assetId);
      }
    });

    handleUpsertCssAsset({
      assetType: 'document',
      isCreate: true,
      projectId: String(projectId),
      uploadedFile: cssFile,
      callBack: (asset?: IDBAsset) => {
        setCssAssetId(asset?.assetId);
      }
    });

    handleUpsertJsAsset({
      assetType: 'document',
      isCreate: true,
      projectId: String(projectId),
      uploadedFile: jsFile,
      callBack: (asset?: IDBAsset) => {
        setJsAssetId(asset?.assetId);
      }
    });

  };

  useEffect(() => {
    if (!(cssAssetId && jsAssetId && htmlAssetId)) return;

    const { title = '' } = getValues();

    handleUpdateAppComponentDetails({
      componentId: currentComponent.id,
      files: [
        {
          locationId: cssAssetId,
          type: 'CSS',
        },
        {
          locationId: htmlAssetId,
          type: 'HTML',
        },
        {
          locationId: jsAssetId,
          type: 'JAVASCRIPT',
        }
      ],
      title,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cssAssetId, jsAssetId, htmlAssetId]);

  useEffect(() => {
    setDefaultValues(getAppComponentDetailsDefaultValues(currentComponent, componentFileDetails));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentFileDetails, isSideBarOpen]);

  const isLoading = isDeleteAppComponentLoading || isUpdateAppComponentDetailsLoading || isUpsertHtmlAssetLoading || isUpsertCssAssetLoading || isUpsertJsAssetLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete Component?"
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          handleDeleteAppComponent({
            componentId: currentComponent.id,
          });
        }}
      />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
      >


        <SideBarTabSet
          defaultActiveTabId="id_component_general_tab"
          handleOpenCloseSideBar={handleOnCancel}
          hasViewPadding={false}
          hideSideBarButtons={false}
          isDeleteButtonDisabled={isReadOnly}
          isSaveButtonDisabled={isReadOnly}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_component_general_tab',
              label: 'General',
              view: () => (
                <SidebarContainer>
                  <Spacing>
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="title"
                      render={({ field, fieldState }) => (
                        <Input
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_app_component_title"
                          isDisabled={isReadOnly}
                          labelText="Title"
                          type="text"
                          isLabelSideBySide
                          isRequired
                          {...field}
                        />
                      )}
                    />
                  </Spacing>
                </SidebarContainer>
              )
            },
            {
              icon: HtmlIcon,
              id: 'id_app_html',
              label: 'HTML',
              view: () => (
                <SidebarContainer>
                  <Spacing>
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="htmlContent"
                      render={({ field, fieldState }) => (
                        <Textarea 
                          additionalClassNames="c-app-component__textarea"
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_app_component_html"
                          isDisabled={isReadOnly}
                          labelText="Html"
                          isLabelSideBySide
                          isRequired
                          {...field}
                        />
                      )}
                    />
                  </Spacing>
                </SidebarContainer>
              )
            },
            {
              icon: CssIcon,
              id: 'id_app_css',
              label: 'CSS',
              view: () => (
                <SidebarContainer>
                  <Spacing>
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="cssContent"
                      render={({ field, fieldState }) => (
                        <Textarea
                          additionalClassNames="c-app-component__textarea"
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_app_component_title"
                          isDisabled={isReadOnly}
                          labelText="Css"
                          isLabelSideBySide
                          isRequired
                          {...field}
                        />
                      )}
                    />
                  </Spacing>
                </SidebarContainer>
              )
            },
            {
              icon: JsIcon,
              id: 'id_app_javascript',
              label: 'Javascript',
              view: () => (
                <SidebarContainer>
                  <Spacing>
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="jsContent"
                      render={({ field, fieldState }) => (
                        <Textarea
                          additionalClassNames="c-app-component__textarea"
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          id="id_app_component_javascript"
                          labelText="Javascript"
                          isLabelSideBySide
                          isRequired
                          {...field}
                        />
                      )}
                    />
                  </Spacing>
                </SidebarContainer>
              )
            },
          ]}
          onDelete={() => setIsDeleteDialogOpen(true)}
          onSaveButtonType="submit"

        />
      </ControlledForm>
    </>
  );
};

export { UpsertComponentCodeSidebarView };
