import React, { useEffect, useRef, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { getQuizData, ITranscript, TranscriptItems, TranscriptQuiz } from '@netfront/ekardo-content-library';
import { Spacing, Input, InputFieldWrapper, FormFieldProps, IconRadioGroup, PencilIcon, InteractiveRegionsIcon } from '@netfront/ui-library';
import { RegionPicker } from 'components';
import { Controller } from 'react-hook-form';

import { cssStyle } from './TranscriptPositionPicker.constants';
import { formatCurrentTranscript } from './TranscriptPositionPicker.helpers';
import { TranscriptPositionPickerProps } from './TranscriptPositionPicker.interfaces';


const TranscriptPositionPicker = ({ control, watch, imageUrl, setValue, transcriptItems = [], transcriptId, defaultValues }: TranscriptPositionPickerProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const [items, setItems] = useState<ITranscript[]>([]);
  const [currentTranscript, setCurrentTranscript] = useState<ITranscript>();
  const [currentTranscriptId, setCurrentTranscriptId] = useState<number>();
  const previewImageRef = useRef<HTMLDivElement>(null);

  const [isManualEntry, setIsManualEntry] = useState<boolean>(false);

  const quizData = getQuizData({ transcripts: transcriptItems });

  const handlePositionClick = ({ left, top}: { left: number; top: number }) => {
    setValue('xAxis', left);
    setValue('yAxis', top);
    setValue('width', 0);
  };


  const handleImageLoad = () => {
    const fullPageimage = document.getElementById('cartoon-image');
    const previewImageWidth = previewImageRef.current?.offsetWidth ?? 0;


    const rect = fullPageimage?.getBoundingClientRect();

    const fullPageWidth = rect?.width ?? 0;
    const fullPageHeight = rect?.height ?? 0;


    if (fullPageWidth > 0 && fullPageHeight > 0 && previewImageWidth > 0) {
      // Calculate the aspect ratio (width/height) of the larger element
      const aspectRatio = fullPageWidth / fullPageHeight;

      // Calculate the height for the smaller element based on the aspect ratio
      const calculatedHeight = previewImageWidth / aspectRatio;

      const difference = (calculatedHeight / fullPageHeight).toFixed(2); 

      const formattedCss = cssStyle.replace(/aspectRatio/g, difference);

      const existingCssFile = document.getElementById('id_transcript_picker_css');

      if (existingCssFile) document.head.removeChild(existingCssFile);

      const styleTag = document.createElement('style');
      styleTag.type = 'text/css';
      styleTag.id = 'id_transcript_picker_css';

  
      // Add the CSS content to the <style> tag
      styleTag.appendChild(document.createTextNode(formattedCss));
  
      document.head.appendChild(styleTag);
    }
  };

  useEffect(() => {
    // We need to do this in order to stop watch from re-rendering everything from within the parent container on every change
    const subscription = watch((value) => {
      setCurrentTranscript(formatCurrentTranscript(value as FormFieldProps));
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

  useEffect(() => {
    const filteredTranscriptItems = transcriptItems.filter(({ id}) => id !== transcriptId);

    if (transcriptId) {
      setCurrentTranscriptId(transcriptId);
    } else {
      setCurrentTranscriptId(0);
    }

    const formattedTranscript = formatCurrentTranscript(defaultValues);

    setCurrentTranscript(formattedTranscript);

    setItems(filteredTranscriptItems);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptItems, transcriptId, defaultValues]);

  useEffect(() => {
    if (!currentTranscript) return;
    const filteredTranscriptItems = transcriptItems.filter(({ id}) => id !== currentTranscriptId);

    setItems([...filteredTranscriptItems, currentTranscript ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTranscript]);


  return (
    <>
      <Spacing>
        <div className="c-transcript-picker__selector"> 
          <IconRadioGroup

            id="id_position_picker_type"
            items={[
              {
                icon: PencilIcon,
                label: 'Manual',
                value: 'manual',
              },
              {
                icon: InteractiveRegionsIcon,
                label: 'Picker',
                value: 'picker',
              },
            ]}
            label="Position"
            name="new_position_picker_type"
            selectedValue={isManualEntry ? 'manual': 'picker'}
            isLabelSideBySide
            isOutlined
            onChange={(value: string) => {
              setIsManualEntry(value === 'manual');
            }}
          />
        </div>
      </Spacing>
    {isManualEntry && (
      <>
        <Spacing>
          <Controller
            control={control}
            name="xAxis"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_transcript_x_axis"
                labelText="X axis / %"
                type="number"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control}
            name="yAxis"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_transcript_y_axis"
                labelText="Y axis / %"
                type="number"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control}
            name="width"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_transcript_width"
                labelText="Width / %"
                type="number"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
      </>
    )}

    <InputFieldWrapper id="id_position_picker" label="Position" labelType="span" isLabelHidden isLabelSideBySide>
      <div ref={previewImageRef}>
        <RegionPicker imageUrl={imageUrl} onClick={handlePositionClick} onImageLoad={handleImageLoad}> 
          <div className="c-transcript-picker">
            {!quizData && (
              <TranscriptItems isLargerDevice={true} transcripts={items} />
            )}

          {quizData && (
            <TranscriptQuiz quizData={quizData} />
          )}
          </div>
        </RegionPicker> 
      </div>
    </InputFieldWrapper>

    
      
    </>
  );
};


export { TranscriptPositionPicker };
